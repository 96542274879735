import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicator from "../../../LoadingIndicator"
import EngagementChart from "../../AccountInsights/EngagementChart"

export default function EngagementChartWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { profile } = useSelector(({ socialProfile }) => socialProfile)
  if (profile === "loading" || profile === "init") {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (profile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <EngagementChart socialProfile={ profile.payload.socialAccount } />
  )
}

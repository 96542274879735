import React from "react"
import { useTranslation } from "react-i18next"
import "./network-statistic-card.sass"
import { Network } from "../../graphql"
import NetworkIcon from "../NetworkIcon"

type Props = {
  id?: string
  profileCount: number
  network: Network
  totalProfiles: number
}

export default function NetworkStatisticCard({
  id, profileCount, network, totalProfiles,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.NetworkStatisticCard" })

  return (
    <div id={ id } className="cp_component-network-statistic-card">
      <div className="statistic-container">
        <h4>{ profileCount.toLocaleString() }</h4>
        <p id="cp_component_profile-total">
          { `/${ totalProfiles.toLocaleString() } ${ translate("Total Profiles") }` }
        </p>
      </div>
      <NetworkIcon additionalClasses="network-icon" network={ network } isActive={ true } />
    </div>
  )
}

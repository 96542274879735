import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import Campaigns from "../../component/Dashboard/Campaigns"
import PageShell from "../../component/PageShell"
import ToastController from "../../controller/Toast"
import { Scope } from "../../util/types"
import { useSelector } from "../../state/hooks"

import "./style.sass"
import DashboardOverview from "../../component/DashboardOverview"
import ComingSoonSplash from "../../component/ComingSoonSplash"

export default function Dashboard(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Dashboard" })
  const { scopes } = useSelector(({ user }) => user)

  return (
    <div className="cp_page_dashboard">
      <ToastController />
      <PageShell title={ translate("Dashboard") } />
      <div className="cp_page_dashboard-container">
        { scopes.includes(Scope.FEATURE_INFLUENCERS) && (<DashboardOverview />) }
        { scopes.includes(Scope.FEATURE_CAMPAIGN) ? (
          <Campaigns />
        ) : (
          <div className="cp_page_splash_container">
            <ComingSoonSplash />
          </div>
        ) }
      </div>
    </div>
  )
}

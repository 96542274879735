import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import "./style.sass"

import { useDispatch, useSelector } from "../../state/hooks"
import { fetchList } from "../../state/listSlice"
import LoadingIndicator from "../LoadingIndicator"
import ListManagementTable from "./ListManagementTable"
import ListManagementToolbar from "./ListManagementToolbar"
import CategoryManagementToolbar from "./CategoryManagementToolbar"
import ModalRenameCategory from "../ModalEditCategory"
import ModalRemoveListAccount from "./ModalRemoveListAccount"
import ModalRemoveCategory from "./ModalRemoveCategory"
import ModalCreateFeedback from "./ModalCreateFeedback"

export default function ListManagement() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListManagement" })
  const { listID } = useParams()
  const dispatch = useDispatch()
  const fetchedList = useSelector(({ list }) => list.list)

  useEffect(
    () => {
      if (!listID) return
      dispatch(fetchList(listID))
    },
    [ listID ],
  )

  if (fetchedList === "init" || fetchedList === "loading") {
    return (
      <div className="cp_list-management_component-loading">
        <LoadingIndicator size={ 50 } />
      </div>
    )
  }

  if (fetchedList.status === "error") {
    return (
      <div className="cp_list-management_component">
        <p>
          { translate("Unable to fetch list.") }
        </p>
      </div>
    )
  }

  return (
    <div className="cp_list-management_component">
      {
        fetchedList
          .payload
          .suggestionListById
          .suggestionListCategories
          .map((category, i, categories) => (
            <div key={ `category-table-${ category.id }` }>
              {
                category.name === "Uncategorized"
                  ? <ListManagementToolbar />
                  : (
                    <CategoryManagementToolbar
                      category={ category }
                      index={ i }
                      categories={ categories }
                    />
                  )
              }
              <ListManagementTable
                currentCategory={ category }
                categories={ categories }
              />
            </div>
          ))
      }
      <ModalCreateFeedback />
      <ModalRenameCategory />
      <ModalRemoveCategory />
      <ModalRemoveListAccount />
    </div>
  )
}

/* eslint-disable max-len */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import {
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  getGridBooleanOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro"

import { Avatar, InputAdornment } from "@mui/material"
import { Search as SearchIcon, Delete } from "@mui/icons-material"

import "./campaign-deliverables-table.sass"
import { useSelector, useDispatch } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import { shorthandNumber } from "../../../util/miscHelper"
import { openNewDeliverableModal } from "../../../state/campaignDeliverableModalSlice"
import Pill from "../../Pill"
import DataGrid from "../../DataGrid"
import EntityInfoRow from "../../EntityInfoRow"
import ContextMenu from "./CampaignDeliverableRowContextMenu"
import Button from "../../Button"
import Input from "../../Input"
import LoadingIndicator from "../../LoadingIndicator"
import EmptySplash from "../../EmptySplash"
import { isError } from "../../../util/apiClient"
import {
  fetchCampaign,
  refreshCampaign,
  setCampaignDeliverablesStartsWithFilter,
} from "../../../state/campaignSlice"
import { Scope } from "../../../util/types"
import IconButton from "../../IconButton"
import Toggle from "../../Toggle"
import { openDeliverableContentModal } from "../../../state/campaignDeliverableContentModalSlice"
import StatusDeliverable from "../../StatusDeliverable"

const statusOrder = {
  [GraphQL.DeliverableStatus.AwaitingContent]: 0,
  [GraphQL.DeliverableStatus.Pending]: 1,
  [GraphQL.DeliverableStatus.Finalized]: 2,
  [GraphQL.DeliverableStatus.Uploaded]: 3,
  [GraphQL.DeliverableStatus.Live]: 4,
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      { ...props }
      slots={ {
        columnMenuColumnsItem: null,
        columnMenuPinningItem: null,
      } }
    />
  )
}

interface AccountsStartsWith {
  currValue: string,
  handleValueChange: (e: string) => void
}

function AccountStartsWith({ currValue, handleValueChange }: AccountsStartsWith) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchResults" })
  return (
    <Input
      className="cp_component_search-results-search-input"
      placeholder={ translate("Search") }
      value={ currValue }
      onChange={ (e) => { handleValueChange(e.target.value) } }
      InputProps={ {
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon
              className="cp_component_search-results-search-icon"
            />
          </InputAdornment>
        ),
      } }
    />
  )
}

export default function CampaignDeliverablesTable() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignDeliverablesTab" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const {
    campaign,
    campaignDeliverablesStartsWithFilter: startsWithFilter,
  } = useSelector(({ campaignPage }) => campaignPage)
  const { scopes } = useSelector(({ user }) => user)
  const [ deliverables, setDeliverables ] = useState<GraphQL.DeliverableFragment[]>([])
  const [ filter, setFilter ] = useState("account")

  useEffect(() => {
    if (API.isSuccess(campaign)) {
      setDeliverables(campaign.payload.campaign.deliverables)
    }
  }, [ campaign ])

  useEffect(() => {
    if (!API.isSuccess(campaign)) {
      setDeliverables([])
      return
    }
    if (!startsWithFilter.length) {
      setDeliverables(campaign.payload.campaign.deliverables)
      return
    }
    const filteredDeliverables = campaign.payload.campaign.deliverables.filter((deliverable) => {
      if (filter === "account") {
        return deliverable
          .campaignNetworkAccount
          .socialAccount
          .userName?.toLowerCase()
          .includes(startsWithFilter.toLowerCase())
      }
      return deliverable.name.toLowerCase().includes(startsWithFilter)
    })
    setDeliverables(filteredDeliverables)
  }, [ startsWithFilter, filter ])

  React.useEffect(() => {
    if (!searchParams.has("deliverableId")) return
    if (campaign === "loading" || campaign === "init" || isError(campaign)) return
    const idToFind = searchParams.get("deliverableId")
    const deliverable = deliverables.find((_deliverable) => _deliverable.id === idToFind)
    dispatch(openDeliverableContentModal({
      deliverable: deliverable as GraphQL.DeliverableFragment,
      campaign: campaign.payload.campaign,
      onClose: async (edited: boolean) => {
        searchParams.delete("deliverableId")
        setSearchParams(searchParams)
        if (edited) await dispatch(refreshCampaign())
      },
    }))
  }, [ deliverables, searchParams ])

  const NoDeliverablesOverlay = React.useCallback(() => (
    <div className="cp_campaign_component-tabs-content-controls empty">
      <EmptySplash
        headlineText={ translate("No deliverables") }
      />
    </div>
  ), [ translate ])

  const COLUMNS: GridColDef[] = [
    {
      field: "__check__",
      headerName: "",
      sortable: false,
      filterable: false,
      renderHeader: () => "",
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
      maxWidth: 50,
      align: "center",
    },
    {
      field: "account",
      headerName: translate("Account"),
      sortable: true,
      filterable: true,
      filterOperators: getGridStringOperators(),
      align: "left",
      headerClassName: "account-header",
      valueGetter(params) {
        return params.row.account
      },
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          avatarSrc={ params.row.profilePictureUrl }
          network={ params.row.network }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "network",
      headerName: translate("Network"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: [
        {
          label: translate("Facebook"),
          value: GraphQL.Network.Facebook,
        },
        {
          label: translate("Instagram"),
          value: GraphQL.Network.Instagram,
        },
        {
          label: translate("Snapchat"),
          value: GraphQL.Network.Snapchat,
        },
        {
          label: translate("TikTok"),
          value: GraphQL.Network.Tiktok,
        },
        {
          label: translate("Youtube"),
          value: GraphQL.Network.Youtube,
        },
      ],
      filterOperators: getGridSingleSelectOperators(),
      valueGetter(params) {
        return params.row.network
      },
      renderCell: (params) => (
        params.row.network
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "title",
      headerName: translate("Title"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      type: "string",
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.name
      },
      renderCell: (params) => (
        <div className="cp_campaign-deliverables-table_component-table-title">
          <p className="cp_campaign-deliverables-table_component-table-title-content">
            { params.row.name }
          </p>
        </div>
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "caption",
      headerName: translate("Post Caption"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="cp_campaign-deliverables-table_component-table-caption">
          <p className="cp_campaign-deliverables-table_component-table-caption-content">
            { params.row.caption }
          </p>
        </div>
      ),
      flex: 0.7,
      maxWidth: 250,
      align: "left",
    },
    {
      field: "media",
      headerName: translate("Media"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const media = params.row.media && params.row.media.length > 0 ? params.row.media[0] : undefined
        if (!media) return null
        return (
          <Avatar
            className="cp_campaign-deliverables-table_component-table-media"
            variant="rounded"
            src={ media?.media?.url?.address || "" }
          />
        )
      },
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "feedback",
      headerName: translate("Feedback"),
      sortable: true,
      filterable: true,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.feedback || 0
      },
      disableColumnMenu: false,
      renderCell: (params) => (
        <Pill label={ Math.round(params.row.feedback) } />
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "status",
      headerName: translate("Status"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      sortComparator: (v1: GraphQL.DeliverableStatus, v2: GraphQL.DeliverableStatus) => statusOrder[v1] - statusOrder[v2],
      valueOptions: [
        GraphQL.DeliverableStatus.AwaitingContent,
        GraphQL.DeliverableStatus.Pending,
        GraphQL.DeliverableStatus.Finalized,
        GraphQL.DeliverableStatus.Live,
        GraphQL.DeliverableStatus.Uploaded,
      ],
      filterOperators: getGridSingleSelectOperators(),
      renderCell: (params) => (
        <StatusDeliverable
          deliverableStatus={ params.row.status }
        />
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "tiktok",
      headerName: translate("TCM Campaign"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.tiktok?.tiktokTcmCampaign?.name || translate("NOT LINKED")
      },
      renderCell: (params) => (
        <p className="cp_campaign-deliverables-table_component-table-ttcm-content">
          { params.row.network === GraphQL.Network.Tiktok
            ? (params.row.tiktok?.tiktokTcmCampaign?.name || translate("NOT LINKED"))
            : ""
          }
        </p>
      ),
      flex: 0.7,
      maxWidth: 170,
      align: "left",
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className="cp_campaign-accounts-table_component-column-context">
          <ContextMenu deliverable={ params.row.deliverable } />
        </div>
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ]

  const handleSearchUpdate = (inputValue: string) => {
    dispatch(setCampaignDeliverablesStartsWithFilter(inputValue))
  }
  const availableAccounts = React.useMemo(() => {
    if (campaign === "loading" || campaign === "init" || isError(campaign)) return []
    return campaign.payload.campaign.campaignNetworkAccounts
  }, [ campaign ])

  const filteredColumns = React.useMemo(() => {
    if (campaign === "loading" || campaign === "init" || isError(campaign)) return COLUMNS
    if (
      !campaign.payload.campaign.enableTiktok
      || !scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    ) return COLUMNS.filter((column) => column.field !== "tiktok")
    return COLUMNS
  }, [ campaign, scopes ])

  if (campaign === "init" || campaign === "loading") {
    return (
      <div className="cp_campaign_component-tabs-content-controls loading">
        <LoadingIndicator size={ 50 } />
      </div>
    )
  }
  if (API.isError(campaign)) {
    return (
      <div className="cp_campaign_component-tabs-content-controls empty">
        <EmptySplash
          headlineText={ translateCommon("No results found") }
          bodyText={ translateCommon("An unexpected error occurred!") }
        />
      </div>
    )
  }

  return (
    <div className="cp_campaign-deliverables-table_component-container">
      <div className="cp_campaign_component-tabs-content-controls deliverables">
        <div className="cp_campaign_component-tabs-content-controls-left">
          <AccountStartsWith currValue={ startsWithFilter } handleValueChange={ handleSearchUpdate } />
          <Toggle
            toggleOptions={ [
              {
                value: "account",
                label: translate("Account"),
                keyId: "account",
              },
              {
                value: "title",
                label: translate("Title"),
                keyId: "title",
              },
            ] }
            value={ filter }
            setValue={ setFilter }
          />
        </div>
        {
          scopes.includes(Scope.CAMPAIGN_MANAGEMENT) ? (
            <div className="cp_campaign_component-tabs-content-controls-right">
              <IconButton
                variant="filled"
              >
                <Delete />
              </IconButton>
              <Button
                label={ translate("Add Deliverable") }
                onClick={ () => {
                  dispatch(openNewDeliverableModal({
                    socialAccounts: availableAccounts,
                    createCallback: () => dispatch(fetchCampaign({ campaignId: campaign.payload.campaign.id })),
                  }))
                } }
              />
            </div>
          ) : null }
      </div>
      <DataGrid
        className="cp_campaign-deliverables-table_component-table"
        columnVisibilityModel={ {
          network: false,
        } }
        getRowId={ (row) => row.id }
        slots={ {
          columnMenu: CustomColumnMenu,
          noRowsOverlay: NoDeliverablesOverlay,
        } }
        checkboxSelection={ true }
        disableRowSelectionOnClick={ true }
        rowHeight={ 90 }
        columnHeaderHeight={ 40 }
        columns={ filteredColumns }
        disableColumnReorder={ true }
        hideFooter={ true }
        pinnedColumns={
          {
            left: [ "__check__", "account" ],
            right: [ "ellipsisMenu" ],
          }
        }
        rows={ deliverables.map(({
          id,
          deliverableCaption,
          name,
          deliverableMedia,
          campaignNetworkAccount,
          tiktokTcmOrder,
          contentStatus,
        }, i) => ({
          account: campaignNetworkAccount.socialAccount.userName,
          profilePictureUrl: campaignNetworkAccount.socialAccount.profilePictureUrl,
          followers: campaignNetworkAccount.socialAccount.socialAccountStatistics.followers,
          network: campaignNetworkAccount.socialAccount.network,
          id,
          name,
          caption: deliverableCaption?.text || "",
          media: deliverableMedia,
          status: contentStatus,
          deliverable: deliverables[i],
          // eslint-disable-next-line max-len
          feedback: (deliverableMedia || []).reduce((previous, media) => previous + (media?.feedback?.length || 0), 0) + (deliverableCaption?.feedback || []).length,
          tiktok: tiktokTcmOrder,
        })) || [] }
        loading={ false }
        onRowClick={ (params) => {
          setSearchParams({ deliverableId: params.row.deliverable.id })
          dispatch(openDeliverableContentModal({
            deliverable: params.row.deliverable,
            campaign: campaign.payload.campaign,
            onClose: async () => {
              searchParams.delete("deliverableId")
              setSearchParams(searchParams)
              await dispatch(refreshCampaign())
            },
          }))
        } }
        scrollEndThreshold={ 200 }
      />
    </div>
  )
}

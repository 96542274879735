import React from "react"
import { Container } from "@mui/material"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import "./style.sass"
import SearchBar from "../SearchBar"
import LoadingIndicator from "../LoadingIndicator"

type Props = {
    title: string
    subtitle: string
    selectedEntityCount: number
    open: boolean
    closeAction: () => void
    primaryAction: () => void
    fetchData: (e: React.ChangeEvent<HTMLInputElement>) => void
    children: React.JSX.Element | React.JSX.Element[]
    searchPlaceholderText?: string
    primaryLabel?: string
    disabled?: boolean
    submitLoading?: boolean
    customSearchElement?: React.JSX.Element
}

export default function ModalAddTo({
  title,
  subtitle,
  selectedEntityCount,
  open,
  closeAction,
  primaryAction,
  fetchData,
  children,
  searchPlaceholderText,
  primaryLabel,
  disabled,
  submitLoading = false,
  customSearchElement,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalAddTo" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })

  const renderPrimaryLabel = () => {
    if (submitLoading) {
      return <LoadingIndicator />
    }
    return primaryLabel || `${ translate("Add") } (${ selectedEntityCount })`
  }

  return (
    <Modal
      className="cp_component_modal-add-to"
      title={ title }
      subtitle={ subtitle }
      primaryAction={ primaryAction }
      secondaryAction={ closeAction }
      closeAction={ closeAction }
      primaryLabel={ renderPrimaryLabel() }
      secondaryLabel={ translateCommon("Cancel") }
      disabled={ disabled || submitLoading }
      disablePortal={ true }
      hasSecondaryButton={ true }
      open={ open }
    >
      {
        customSearchElement || <SearchBar onChange={ fetchData } searchPlaceholderText={ searchPlaceholderText } />
      }
      <Container className="cp_component_modal-add-to-container">
        { /* Note: styles are contained in ModalAddTo/styles.sass but children are defined
         in each specific use of the component in order to avoid transforming data shapes and fields */ }
        { children }
      </Container>
    </Modal>
  )
}

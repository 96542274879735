import React, { useEffect, useState } from "react"
import {
  Autocomplete,
  debounce,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import Input, { Props as InputProps } from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"

import "./roles-autocomplete.sass"

type Role = GraphQL.RoleFragment

interface Props {
  setSelectedRoles: (roles: Role[]) => void
  selectedRoles: Role[],
}

function RoleAutoComplete({
  setSelectedRoles,
  selectedRoles,
}: Props) {
  const [ roles, setRoles ] = useState<Role[]>([])
  const [ positionsLoading, setPositionsLoading ] = useState(false)
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.RoleAutoComplete" })

  const searchRoles = async (startsWith: string) => {
    const result = await API.searchRoles({
      startsWith,
      page: 1,
      limit: 100,
    })

    if (API.isSuccess(result)) {
      const results = result.payload.searchRole.rows
      setRoles(results)
    } else {
      setRoles([])
    }
  }

  const debouncedSearchRoles = debounce(async (e, inputValue) => {
    setPositionsLoading(true)
    await searchRoles(inputValue)
    setPositionsLoading(false)
  }, DEFAULT_DEBOUNCE_WAIT)

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    changeRoles: Array<string | Role>,
  ) => {
    const cleanedRoles = changeRoles.filter((role) => typeof role !== "string") as typeof roles

    setSelectedRoles(cleanedRoles)
  }

  const filter = createFilterOptions<Role>()

  const autoCompleteFilter = (
    autoCompleteRoles: Role[],
    params: FilterOptionsState<Role>,
  ) => {
    const filtered = filter(autoCompleteRoles, params)
      .filter((role) => !selectedRoles.some((selectedRole) => role.id === selectedRole.id))

    return filtered
  }

  useEffect(() => {
    searchRoles("")
  }, [])

  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedRoles }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") return option
        return option.display
      } }
      className="cp_component_autocomplete_roles"
      ListboxProps={ {
        className: "cp_component_autocomplete_roles",
      } }
      onInputChange={ debouncedSearchRoles }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      filterOptions={ autoCompleteFilter }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="autocomplete-roles"
      options={ roles.sort() }
      openOnFocus={ true }
      loading={ positionsLoading }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, role, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
              // eslint-disable-next-line react/prop-types
              className={ `${ props.className || "" } cp_component_autocomplete_roles_loading` }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ role.id }
          >
            <p>
              { role.display }
            </p>
          </li>
        )
      } }
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          placeholder={ translate("Select Role") }
        />
      )
}
    />
  )
}

export default RoleAutoComplete

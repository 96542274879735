import React, { JSX } from "react"

import PostStats from "../PostStats"
import * as GraphQL from "../../graphql"

import "./postDetailsCellStyle.sass"

type Props = {
  isReel: boolean,
  isStory: boolean,
  isLens: boolean,
  isSpotlightContent: boolean,
  network: GraphQL.Network,
  postComments: number,
  postContent: string,
  postExits: number,
  postImpressions: number,
  postLikes: number,
  postPlays: number,
  postReach: number,
  postReachEstimated: number,
  postSaves: number,
  postShares: number,
  postScreenshots: number,
  postFavorites: number,
  postTapsBackward: number,
  postTapsForward: number,
  postViews: number,
  postSwipeUps: number,
  postSaved: boolean,
}

export default function PostDetailsCell(props: Props): JSX.Element {
  return (
    <div className="cp_component_search-results-post-details-cell">
      <p>{ props.postContent }</p>
      <PostStats { ...props } isSaved={ props.postSaved } isSearchResultDisplay={ true } />
    </div>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicator from "../../../LoadingIndicator"
import Brands, { FetchBrandParams } from "../../AccountInsights/Brands"
import {
  fetchDetectedBrands,
  fetchMentionedBrands,
  fetchWorkedWithBrands,
  getMentionedPosts,
  getMoreMentionedPosts,
} from "../../../../state/listSocialProfileSlice"

import { FetchMentionedPostParams } from "../../AccountInsights/ModalBrandDetails"

type Props = {
  displayBrandsMentionedWorked: boolean
  displayBrandsDetected: boolean
}

export default function BrandsWrapper({ displayBrandsDetected, displayBrandsMentionedWorked }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const dispatch = useDispatch()
  const {
    listProfile,
    detectedBrands,
    mentionedBrands,
    workedWithBrands,
    mentionedPostsContent,
    mentionedPostsStatus,
  } = useSelector(({ listSocialProfile }) => listSocialProfile)
  if (listProfile === "loading" || listProfile === "init") {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (listProfile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <Brands
      socialProfile={ listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount }
      detectedBrands={ detectedBrands }
      mentionedBrands={ mentionedBrands }
      workedWithBrands={ workedWithBrands }
      fetchDetectedBrands={ (params: FetchBrandParams) => dispatch(fetchDetectedBrands(params)) }
      fetchMentionedBrands={ (params: FetchBrandParams) => dispatch(fetchMentionedBrands(params)) }
      fetchWorkedWithBrands={ (params: FetchBrandParams) => dispatch(fetchWorkedWithBrands(params)) }
      mentionedPostsStatus={ mentionedPostsStatus }
      mentionedPostsContent={ mentionedPostsContent }
      displayBrandsDetected={ displayBrandsDetected }
      displayBrandsMentionedWorked={ displayBrandsMentionedWorked }
      getMentionedPosts={ (params: FetchMentionedPostParams) => dispatch(getMentionedPosts(params)) }
      getMoreMentionedPosts={ (params: FetchMentionedPostParams) => dispatch(getMoreMentionedPosts(params)) }
    />
  )
}

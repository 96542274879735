import React from "react"
import "./style.sass"
import NetworkIcon from "../NetworkIcon"
import { Network } from "../../graphql"

interface ButtonProps {
  network: Network;
  isActive: boolean
  additionalClasses?: string;
  onClick: (network: string) => void;
  isButtonDisabled?: boolean;
  id?: string;
}

export default function NetworkIconButton(props: ButtonProps) {
  const {
    network,
    isActive,
    additionalClasses,
    onClick,
    isButtonDisabled,
    id,
  } = props

  const buttonClasses: string = [
    "cp-network-button",
    isButtonDisabled ? "cp-network-button_disabled" : "",
    additionalClasses,
  ].filter(Boolean).join(" ")

  const handleSelectChange = () => {
    onClick(network)
  }

  return (
    <button
      id={ id }
      type="button"
      aria-label="Network Button"
      className={ buttonClasses }
      onClick={ handleSelectChange }
      disabled={ isButtonDisabled }
    >
      <NetworkIcon
        network={ network }
        isActive={ isActive }
        additionalClasses="cp-network-button__icon"
      />
    </button>
  )
}

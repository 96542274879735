import React, {
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import * as GraphQL from "../../../../graphql"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import ModalPostDetails from "../../../ModalPostDetails"
import Post from "./Post"
import { getSocialAvatarProps } from "../../../../util/componentHelper"
import { getTopPosts } from "../../../../state/publicListSlice"
import { isError } from "../../../../util/apiClient"
import { prettyPrintDecimal } from "../../../../util/miscHelper"
import { useDispatch, useSelector } from "../../../../state/hooks"

import "./top-posts.sass"

export default function TopPosts() {
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const postsListRef = useRef<HTMLUListElement>(null)
  const [ selectedPost, selectPost ] = useState<GraphQL.SuggestionsPostFragment | undefined>()
  const { topPosts, selectedListSocialAccount } = useSelector(({ publicList }) => publicList)
  const { listCode } = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (listCode && selectedListSocialAccount) {
      dispatch(getTopPosts({
        code: listCode,
        socialAccountId: selectedListSocialAccount.socialAccount.id,
      }))
    }
  }, [ listCode, selectedListSocialAccount ])

  if (topPosts === "init" || topPosts === "loading") {
    return <LoadingIndicatorCard />
  }

  if (isError(topPosts)) {
    return (
      <p>
        { translateCommon("An unexpected error occurred!") }
      </p>
    )
  }

  const posts = topPosts.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId.cippusFeatured

  return (
    <div className="cp_list_public-list-content-top-posts">
      <ul ref={ postsListRef } className="cp_list_top-post-list overview-display-on">
        { posts.map(({ post }) => ((
          <Post post={ post } selectPost={ selectPost } />
        ))) }
      </ul>
      { selectedListSocialAccount && (
      <ModalPostDetails
        closeAction={ () => selectPost(undefined) }
        engagementRate={ `${ prettyPrintDecimal(selectedPost?.engagementRate, 2) }%` || "" }
        isReel={ Boolean(selectedPost?.isReel) }
        open={ Boolean(selectedPost) }
        isStory={ Boolean(selectedPost?.isStory) }
        media={ selectedPost?.postMedia || [] }
        permalink={ selectedPost?.permalink || "" }
        postComments={ selectedPost?.postComments || 0 }
        postContent={ selectedPost?.postContent || "" }
        postDate={ selectedPost?.created || 0 }
        postExits={ selectedPost?.postExits || 0 }
        postImpressions={ selectedPost?.postImpressions || 0 }
        postLikes={ selectedPost?.postLikes || 0 }
        postPlays={ selectedPost?.postPlays || 0 }
        postReach={ selectedPost?.postReach || 0 }
        postReachEstimated={ selectedPost?.estimatedPostReach?.value || 0 }
        postSaves={ selectedPost?.postSaves || 0 }
        postShares={ selectedPost?.postShares || 0 }
        postScreenshots={ selectedPost?.screenshots || 0 }
        postSwipeUps={ selectedPost?.swipeUps || 0 }
        postHighlighted={ Boolean(selectedPost?.highlighted) }
        postSaved={ Boolean(selectedPost?.saved) }
        postTapsBackward={ selectedPost?.postTapsBackward || 0 }
        postTapsForward={ selectedPost?.postTapsForward || 0 }
        postViews={ selectedPost?.postViews || 0 }
        socialAvatarProps={ getSocialAvatarProps(selectedListSocialAccount.socialAccount as GraphQL.SocialAccount) }
      />
      ) }
    </div>
  )
}

/* eslint-disable react/jsx-closing-tag-location */
import React from "react"

import { useTranslation } from "react-i18next"

import * as GraphQL from "../../graphql"
import NetworkAvatar from "../NetworkAvatar"
import IconButtonClose from "../IconButtonClose"
import { Network } from "../../graphql"
import { useSelector, useDispatch } from "../../state/hooks"
import { setConnection, setProfileImage } from "../../state/profileSlice"
import { Scope } from "../../util/types"

interface ConnectionProps {
  network: Network.Facebook | Network.Instagram | Network.Snapchat | Network.Tiktok | Network.Youtube
}

export function Connection({
  network,
}: ConnectionProps) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalProfile" })
  const { connections, profileImage } = useSelector(({ profile: profileSlice }) => profileSlice)
  const { scopes } = useSelector(({ user }) => user)
  const dispatch = useDispatch()

  const connection = connections[network]

  const hasScopeToRemoveConnection = React.useMemo(() => {
    const scopeMap = {
      [GraphQL.Network.Facebook]: Scope.SOCIAL_ACCOUNT_FACEBOOK_DELETE,
      [GraphQL.Network.Instagram]: Scope.SOCIAL_ACCOUNT_INSTAGRAM_DELETE,
      [GraphQL.Network.Snapchat]: Scope.SOCIAL_ACCOUNT_SNAPCHAT_DELETE,
      [GraphQL.Network.Tiktok]: Scope.SOCIAL_ACCOUNT_TIKTOK_DELETE,
      [GraphQL.Network.Youtube]: Scope.SOCIAL_ACCOUNT_YOUTUBE_DELETE,
      [GraphQL.Network.Twitter]: "",
    }
    return scopes.includes(scopeMap[network])
  }, [ scopes ])

  if (!connection) return null

  return (
    <div className="cp_component_modal-add-profile_row cp_component_modal-add-profile_connection">
      <div className="cp_component_modal-add-profile_network">
        <NetworkAvatar
          onClick={ () => dispatch(setProfileImage(connection.profilePictureUrl)) }
          src={ connection.profilePictureUrl }
          network={ network }
          size="lg"
        />
      </div>
      <div className="cp_component_modal-add-profile_connection-description">
        <div>
          <p className="cp_component_modal-add-profile_connection-network label_small-caps-semibold">
            { translate(network) }
            { " " }
            { translate("Account") }
          </p>
          <p className="cp_component_modal-add-profile_connection-account label_large">
            { connection.userName }
          </p>
        </div>
      </div>
      <div>
        { hasScopeToRemoveConnection ? (
          <IconButtonClose
            onClick={ async () => {
              if (connection.profilePictureUrl === profileImage) await dispatch(setProfileImage(""))
              dispatch(setConnection({ network, connection: undefined }))
            } }
          />
        ) : null
        }
      </div>
    </div>
  )
}

export default Connection

import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicator from "../../../LoadingIndicator"
import PersonalityTraits from "../../AccountInsights/PersonalityTraits"

export default function PersonalityTraitsWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { listProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)
  if (listProfile === "loading" || listProfile === "init") {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (listProfile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <PersonalityTraits socialProfile={ listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount } />
  )
}

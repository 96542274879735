/* eslint-disable no-underscore-dangle */
import React from "react"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import AddBoxIcon from "@mui/icons-material/AddBox"
import AdjustIcon from "@mui/icons-material/Adjust"
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CheckIcon from "@mui/icons-material/Check"
import EditIcon from "@mui/icons-material/Edit"
import PersonIcon from "@mui/icons-material/Person"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import {
  TimelineItem,
  TimelineDot,
  TimelineConnector,
  TimelineSeparator,
  TimelineContent,
} from "@mui/lab"

import * as Constant from "../../util/constant"
import Avatar from "../Avatar"
import HistoryEventPayload from "./HistoryEventPayload"
import { DeliverableHistoryEventType, HistoryEventFragment } from "../../graphql"

import "./history-event.sass"

const ICON_HELPERS = {
  [DeliverableHistoryEventType.DeliverableLive]: <AdjustIcon fontSize="inherit" className="live" />,
  [DeliverableHistoryEventType.DeliverableFinalized]: <CheckCircleIcon fontSize="inherit" className="finalized" />,
  [DeliverableHistoryEventType.DeliverableCreated]: <AddBoxIcon fontSize="inherit" className="created" />,
  [DeliverableHistoryEventType.DeliverableUnfinalized]: <CancelPresentationIcon fontSize="inherit" className="unfinalized" />,
  [DeliverableHistoryEventType.TextAccepted]: <CheckIcon fontSize="inherit" className="approved-text" />,
  [DeliverableHistoryEventType.TextDeleted]: <RemoveCircleIcon fontSize="inherit" className="caption-removed" />,
  [DeliverableHistoryEventType.TextCreated]: <EditIcon fontSize="inherit" className="caption-created" />,
  [DeliverableHistoryEventType.TextFeedback]: <QuestionAnswerIcon fontSize="inherit" className="caption-feedback" />,
  [DeliverableHistoryEventType.MediaAccepted]: <CheckIcon fontSize="inherit" className="approved-media" />,
  [DeliverableHistoryEventType.MediaCreated]: <AddAPhotoIcon fontSize="inherit" className="media-added" />,
  [DeliverableHistoryEventType.MediaDeleted]: <RemoveCircleIcon fontSize="inherit" className="media-removed" />,
  [DeliverableHistoryEventType.MediaFeedback]: <QuestionAnswerIcon fontSize="inherit" className="media-feedback" />,
}

const TEXT_HELPERS = {
  [DeliverableHistoryEventType.DeliverableLive]: "updated the live status",
  [DeliverableHistoryEventType.DeliverableFinalized]: "finalized this deliverable",
  [DeliverableHistoryEventType.DeliverableCreated]: "created this deliverable",
  [DeliverableHistoryEventType.DeliverableUnfinalized]: "unfinalized this deliverable",
  [DeliverableHistoryEventType.TextAccepted]: "approved the post caption",
  [DeliverableHistoryEventType.TextDeleted]: "removed the post caption",
  [DeliverableHistoryEventType.TextCreated]: "updated the post caption",
  [DeliverableHistoryEventType.TextFeedback]: "left feedback on the post caption",
  [DeliverableHistoryEventType.MediaAccepted]: "approved post media",
  [DeliverableHistoryEventType.MediaCreated]: "added post media",
  [DeliverableHistoryEventType.MediaDeleted]: "removed post media",
  [DeliverableHistoryEventType.MediaFeedback]: "left feedback on post media",
}

interface HistoryEventProps {
  event: HistoryEventFragment
  showDivider?: boolean
}

function HistoryEvent({ event, showDivider }: HistoryEventProps): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableHistoryTab" })

  return (
    <div className="cp_modal-campaign-deliverable-history-event_component">
      <TimelineItem>
        { showDivider ? (
          <TimelineSeparator>
            <TimelineDot>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
        ) : (
          <TimelineDot>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </TimelineDot>
        ) }
        <TimelineContent>
          <div className="cp_modal-campaign-deliverable-history-event_component-content">
            <p className="cp_modal-campaign-deliverable-history-event_component-content-timestamp">
              { dayjs(event.created * 1000).format(Constant.LONGFORM_DATE_TIME) }
            </p>
            <p className="cp_modal-campaign-deliverable-history-event_component-content-header">
              <span>{ ICON_HELPERS[event.type] }</span>
              <strong>{ event.reviewer.contact.name }</strong>
              { ` ${ translate("from") } ` }
              <strong>
                { event.reviewer.customer.company?.name }
              </strong>
              { ` ${ translate(TEXT_HELPERS[event.type]) } ` }
            </p>
            <div className="cp_modal-campaign-deliverable-history-event_component-content-body">
              <HistoryEventPayload event={ event } />
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
    </div>
  )
}

export default HistoryEvent

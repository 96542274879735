import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import {
  setCampaignSearchInput,
  setCampaignStatus,
  setCampaignsContent,
  setFetchingCampaignId,
} from "."
import { RootState } from "../store"
// Campaign Interface and Initial State
export interface CampaignState {
  campaignsStatus: Status<GraphQL.SearchCampaignsExtendedQuery>
  fetchingCampaignId: string
  campaignsContent: Array<GraphQL.CampaignPageFragment>
  campaignSearchFilter: GraphQL.SearchCampaignFilter
}

export const initialState: CampaignState = {
  campaignsStatus: "init",
  campaignsContent: [],
  campaignSearchFilter: GraphQL.SearchCampaignFilter.Standard,
  fetchingCampaignId: "",
}

// Reducer actions
export const reducers = {
  setCampaignStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchCampaignsExtendedQuery>>) => ({
    ...state,
    campaignsStatus: action.payload,
  }),
  setCampaignsContent: (state: any, action: PayloadAction<Array<GraphQL.CampaignPageFragment>>) => ({
    ...state,
    campaignsContent: action.payload,
  }),
  setCampaignSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    campaignSearchInput: action.payload,
  }),
  setCampaignSearchFilter: (state: any, action: PayloadAction<GraphQL.SearchCampaignFilter>) => ({
    ...state,
    campaignSearchFilter: action.payload,
  }),
  setFetchingCampaignId: (state: any, action: PayloadAction<string>) => ({
    ...state,
    fetchingCampaignId: action.payload,
  }),
}

export const getCampaigns = (
  searchInput: string,
  filter: GraphQL.SearchCampaignFilter,
  page: number,
) => async (dispatch: Dispatch) => {
  dispatch(setCampaignStatus("loading"))
  const result = await API.fetchCampaignsExtended({
    startsWith: searchInput,
    column: GraphQL.SearchCampaignSort.CampaignCreated,
    direction: GraphQL.SortDirection.Desc,
    filter,
    page,
    limit: 20,
  })

  dispatch(setCampaignStatus(result))
  if (API.isSuccess(result)) {
    dispatch(setCampaignsContent(result.payload.searchCampaign.rows))
  }
  dispatch(setCampaignSearchInput(searchInput))
}

export const getMoreCampaigns = (
  searchInput: string,
  filter: GraphQL.SearchCampaignFilter,
  page: number,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { dashboard: { campaignsStatus: currentCampaignStatus, campaignsContent: currentCampaignContent } } = getState()

  const result = await API.fetchCampaignsExtended({
    startsWith: searchInput,
    column: GraphQL.SearchCampaignSort.CampaignCreated,
    direction: GraphQL.SortDirection.Desc,
    filter,
    page,
    limit: 50,
  })

  if (API.isSuccess(currentCampaignStatus) && API.isSuccess(result)) {
    dispatch(setCampaignsContent([
      ...currentCampaignContent,
      ...result.payload.searchCampaign.rows,
    ]))
  } else {
    dispatch(setCampaignStatus({ status: "error", message: "Something went wrong with the request!" }))
  }

  dispatch(setCampaignSearchInput(searchInput))
}

export const getCampaign = (
  params: GraphQL.CampaignQueryVariables,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { dashboard: { campaignsContent: currentCampaignContent } } = getState()
  const indexToUpdate = currentCampaignContent.findIndex((c) => c.id === params.campaignId)
  if (indexToUpdate === -1) return
  dispatch(setFetchingCampaignId(params.campaignId))

  const campaign = await API.fetchCampaign(params)

  dispatch(setFetchingCampaignId(""))

  if (API.isSuccess(campaign)) {
    const newContent = [ ...currentCampaignContent ]
    newContent[indexToUpdate] = campaign.payload.campaign

    dispatch(setCampaignsContent(newContent))
  }
}

import React from "react"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import "./style.sass"

interface Props extends IconButtonProps {

}

function IconButtonClose(props: Props): React.JSX.Element {
  const {
    ...muiButtonProps
  } = props

  return (
    <IconButton
      aria-label="close"
      { ...muiButtonProps }
    >
      <CloseIcon />
    </IconButton>
  )
}

export default IconButtonClose

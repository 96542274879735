import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "./edit-url-card.sass"
import Input from "../Input"
import Button from "../Button"
import { useDispatch } from "../../state/hooks"
import {
  refreshDeliverable,
  setEdittingUrl,
  updateDeliverableUrl,
} from "../../state/campaignDeliverableContentModalSlice"
import { refreshCampaign } from "../../state/campaignSlice"
import LoadingIndicator from "../LoadingIndicator"
import { pushToast } from "../../state/toastSlice"

type Props = {
  deliverableId: string
  loadedUrl?: string
}

export default function EditUrlCard({
  loadedUrl = "", deliverableId,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.DeliverableEditUrlCard" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })

  const dispatch = useDispatch()

  const [ input, setInput ] = useState(loadedUrl)
  const [ submitLoading, setSubmitLoading ] = useState(false)

  const onSubmit = async () => {
    setSubmitLoading(true)
    await dispatch(updateDeliverableUrl({
      variables: {
        deliverableId,
        livePostUrl: input,
      },
      onSuccess: async () => {
        await dispatch(refreshCampaign())
        await dispatch(refreshDeliverable({ deliverableId }))
        dispatch(setEdittingUrl(false))
        setSubmitLoading(false)
      },
      onError: () => {
        dispatch(pushToast({
          message: translate("There was an error! Please make sure the url matches the correct format."),
          type: "error",
        }))
        setSubmitLoading(false)
      },
    }))
  }

  return (
    <div className="cp_component_edit-url-card">
      <div className="inner-container">
        <Input
          className="input"
          label={ translate("Live Post Url") }
          value={ input }
          onChange={ (e) => setInput(e.target.value) }
          fullWidth={ true }
        />
        <div className="cta-button-container">
          <Button
            className="cta-button"
            disabled={ submitLoading }
            label={ submitLoading ? <LoadingIndicator /> : translate("Update") }
            isPrimary={ true }
            onClick={ onSubmit }
          />
          <Button
            className="cta-button"
            disabled={ submitLoading }
            label={ translateCommon("Cancel") }
            isPrimary={ false }
            onClick={ () => dispatch(setEdittingUrl(false)) }
          />
        </div>
      </div>
    </div>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import {
  Err,
  ProfileConnection,
  Status,
  Success,
} from "../../util/types"

// Profile Slice Interface and Initial State
export interface ProfileState {
  profile: Status<GraphQL.GetProfileQuery>,
  profileImage: string
  openModal: boolean
  isEditMode: boolean
  connections: {
    [GraphQL.Network.Facebook]?: ProfileConnection
    [GraphQL.Network.Tiktok]?: ProfileConnection
    [GraphQL.Network.Instagram]?: ProfileConnection
    [GraphQL.Network.Snapchat]?: ProfileConnection
    [GraphQL.Network.Youtube]?: ProfileConnection
  }
  loadingVerticals: boolean
  verticals: GraphQL.VerticalFragment[]
}

const initialState: ProfileState = {
  profile: "init",
  profileImage: "",
  isEditMode: false,
  openModal: false,
  loadingVerticals: false,
  verticals: [],
  connections: {},
}

// Profile Slice
export const ProfileSlice = createSlice({
  name: "ProfileSlice",
  initialState,
  reducers: {
    setProfile: (
      state,
      action: PayloadAction<Status<GraphQL.GetProfileQuery>>,
    ) => ({
      ...state,
      profile: action.payload,
    }),
    setLoadingVerticals: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      loadingVerticals: action.payload,
    }),
    setVerticals: (
      state,
      action: PayloadAction<GraphQL.VerticalFragment[]>,
    ) => ({
      ...state,
      verticals: action.payload,
    }),
    setOpenModal: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      openModal: action.payload,
    }),
    setEditMode: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isEditMode: action.payload,
    }),
    setProfileImage: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      profileImage: action.payload,
    }),
    setConnection: (
      state,
      { payload }: PayloadAction<{network: GraphQL.Network, connection: ProfileConnection | undefined }>,
    ) => ({
      ...state,
      connections: {
        ...state.connections,
        [payload.network]: payload.connection,
      },
    }),
  },
})

export const {
  setProfile,
  setLoadingVerticals,
  setVerticals,
  setOpenModal,
  setEditMode,
  setConnection,
  setProfileImage,
} = ProfileSlice.actions
export default ProfileSlice.reducer

// Profile Slice Thunks
export const fetchProfile = (
  id: string,
) => async (
  dispatch: Dispatch,
): Promise<Err | Success<GraphQL.GetProfileQuery>> => {
  dispatch(setProfile("loading"))

  const profileResult = await API.query<
    GraphQL.GetProfileQuery,
    GraphQL.GetProfileQueryVariables
  >(GraphQL.GetProfileDocument, { id })

  dispatch(setProfile(profileResult))

  return profileResult
}

export const searchVerticals = (
  params: GraphQL.SearchVerticalsQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setLoadingVerticals(true))

  const result = await API.query<
      GraphQL.SearchVerticalsQuery,
      GraphQL.SearchVerticalsQueryVariables
      >(GraphQL.SearchVerticalsDocument, params)
  if (API.isSuccess(result)) {
    dispatch(setVerticals(result.payload.searchVerticals.rows))
  }
  dispatch(setLoadingVerticals(false))
}

export const createProfile = (
  params: GraphQL.CreateProfileMutationVariables,
) => async (): Promise<string | undefined> => {
  const result = await API.mutate<GraphQL.CreateProfileMutation, GraphQL.CreateProfileMutationVariables>(
    GraphQL.CreateProfileDocument,
    params,
  )
  if (API.isSuccess(result)) {
    return result.payload?.createPersonality.id
  }
  return undefined
}

export const updateProfile = (
  params: GraphQL.EditProfileMutationVariables,
) => async (): Promise<boolean> => {
  const result = await API.mutate<GraphQL.EditProfileMutation, GraphQL.EditProfileMutationVariables>(
    GraphQL.EditProfileDocument,
    params,
  )
  return API.isSuccess(result)
}

export const createPlaceholderAccount = (
  { username, network }: GraphQL.CreatePlaceholderSocialAccountMutationMutationVariables,
) => async (): Promise<Err | Success<GraphQL.CreatePlaceholderSocialAccountMutationMutation | null>> => {
  const result = await API.mutate<
  GraphQL.CreatePlaceholderSocialAccountMutationMutation,
  GraphQL.CreatePlaceholderSocialAccountMutationMutationVariables
  >(
    GraphQL.CreatePlaceholderSocialAccountMutationDocument,
    // https://influential-team.atlassian.net/browse/ID-1392
    { username: username.trim(), network },
  )
  return result
}

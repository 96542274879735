import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useParams } from "react-router-dom"

import { Add } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import * as API from "../../util/apiClient"
import LoadingIndicator from "../../component/LoadingIndicator"
import PageShell from "../../component/PageShell"
import SocialTrackerInfo from "../../component/SocialTracker/SocialTrackerInfo"
import SocialTrackerRelevantPosts from "../../component/SocialTracker/SocialTrackerRelevantPosts"
import EmptySplash from "../../component/EmptySplash"
import { setModalNewTrackerOpen } from "../../state/ModalNewTrackerSlice"
import Button from "../../component/Button"
import ToastController from "../../controller/Toast"
import { fetchSocialTracker } from "../../state/socialTrackerPage"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export default function SocialTracker(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.SocialTracker" })
  const { socialTracker } = useSelector(({ socialTrackerPage }) => socialTrackerPage)
  const { trackerID } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (trackerID) {
      dispatch(fetchSocialTracker(trackerID))
    }
  }, [ trackerID ])

  const handleCreateTracker = () => {
    dispatch(setModalNewTrackerOpen(true))
  }

  const title = useMemo(() => {
    if (API.isSuccess(socialTracker)) {
      const { getContentAlerts } = socialTracker.payload
      const currentSocialTracker = getContentAlerts.filter((alert) => alert.xref === trackerID)
      if (currentSocialTracker.length !== 0) {
        return currentSocialTracker[0].name
      }
      return translate("Social Tracker")
    }
    return <LoadingIndicator />
  }, [ socialTracker ])

  const renderPage = () => {
    if (socialTracker === "loading" || socialTracker === "init") {
      return (
        <div className="cp_social_tracker_page-empty">
          <LoadingIndicator size={ 80 } />
        </div>
      )
    }
    if (socialTracker.status === "error") {
      return (
        <EmptySplash headlineText={ translate("There was an error loading your social tracker!") } />
      )
    }

    const { getContentAlerts } = socialTracker.payload
    const currentSocialTracker = getContentAlerts.filter((alert) => alert.xref === trackerID)

    if (!currentSocialTracker.length || !currentSocialTracker[0].params) {
      return (
        <div className="cp_social_tracker_page-not-found">
          <EmptySplash bodyText={ translate("Social Tracker not found.") } />
          <Button
            className="new-tracker-button"
            label={ (
              <div className="label-container">
                <Add />
                { translate("New Tracker") }
              </div>
                ) }
            isPrimary={ false }
            onClick={ handleCreateTracker }
          />
        </div>
      )
    }

    return (
      <div className="cp_social_tracker_page-container">
        <section className="cp_social_tracker_page-overview">
          <SocialTrackerInfo />
        </section>
        <section className="cp_social_tracker_page-relevant-posts">
          <SocialTrackerRelevantPosts />
        </section>
      </div>
    )
  }

  return (
    <div className="cp_social_tracker_page">
      <ToastController />
      <PageShell title={ title } />
      { renderPage() }
    </div>
  )
}

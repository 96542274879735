import React, { useMemo } from "react"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"

import * as GraphQL from "../../graphql"
import EntityInfoRow from "../EntityInfoRow"
import NetworkIcon from "../NetworkIcon"
import Pill from "../Pill"
import { generateDemographicRangeString, shorthandNumber } from "../../util/miscHelper"
import { setLeftPanelStatus } from "../../state/publicListSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./overview-card.sass"

type Props = {
    listName: string
    description?: string | null
    minAge?: number | null
    maxAge?: number | null
    sexes: Array<string>
    numberOfAccounts: number
    reach: number
    platform: GraphQL.Network
    isCampaignMode: boolean
    className?: string
    avatarSrc?: string
    avatarSize?: "sm" | "md" | "lg"
    approved: number
}

export default function OverviewCard({
  listName,
  minAge,
  maxAge,
  sexes,
  description,
  numberOfAccounts,
  platform,
  reach,
  className,
  avatarSrc,
  approved,
  isCampaignMode,
  avatarSize = "sm",
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.OverviewCard" })
  const demographicString = useMemo(() => (generateDemographicRangeString(sexes, minAge, maxAge)), [ sexes, minAge, maxAge ])
  const leftPanelStatus = useSelector(({ publicList }) => publicList.leftPanelStatus)
  const dispatch = useDispatch()
  const classes = [ "cp_component_public-list-overview-card" ]
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")

  return (
    <div className={ newClasses }>
      <div className="cp_component_public-list-overview-card-main">
        <div className="cp_component_public-list-overview-card-main-entity-row">
          <EntityInfoRow
            avatarSize={ avatarSize }
            avatarSrc={ avatarSrc }
            className="cp_component_public-list-overview-card-main-entity-row-info"
            name={ listName }
          />
          { isCampaignMode && (
          <IconButton>
            { leftPanelStatus === "expanded"
              ? <ChevronLeft onClick={ () => dispatch(setLeftPanelStatus("default")) } />
              : <ChevronRight onClick={ () => dispatch(setLeftPanelStatus("expanded")) } /> }
          </IconButton>
          ) }
        </div>
        <p className="cp_component_public-list-overview-card-main-description">{ description }</p>
        <div className="cp_component_public-list-overview-card-main-demographic">
          { demographicString && (
          <>
            <p className="cp_component_public-list-overview-card-main-demographic-text">{ translate("Demographic") }</p>
            <div className="cp_component_public-list-overview-card-main-demographic-pill">
              <Pill label={ demographicString } />
            </div>
          </>
          ) }
        </div>
      </div>

      <div className="cp_component_public-list-overview-card-stats">
        <div className="cp_component_public-list-overview-card-stats-info">
          <p className="cp_component_public-list-overview-card-stats-info-title">{ translate("Network") }</p>
          <NetworkIcon
            additionalClasses="cp_component_public-list-overview-card-stats-info-network"
            isActive={ true }
            network={ platform }
          />
        </div>
        <div className="cp_component_public-list-overview-card-stats-info">
          <p className="cp_component_public-list-overview-card-stats-info-title">{ translate("Accounts") }</p>
          <h4>{ numberOfAccounts }</h4>
        </div>
        <div className="cp_component_public-list-overview-card-stats-info">
          <p className="cp_component_public-list-overview-card-stats-info-title">{ translate("Followers") }</p>
          <h4>{ shorthandNumber(reach) }</h4>
        </div>
        { leftPanelStatus === "expanded" && (
        <div className="cp_component_public-list-overview-card-stats-info">
          <p className="cp_component_public-list-overview-card-stats-info-title">{ translate("Approved") }</p>
          <h4>{ approved }</h4>
        </div>
        ) }
      </div>
    </div>
  )
}

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { GridColDef } from "@mui/x-data-grid"
import DataGrid from "../../../../DataGrid"
import { DeliverableFragment } from "../../../../../graphql"
import EmptyElement from "../../../../EmptyElement"

// Separate import needed since this table exists in a Modal
import "./campaign-deliverables-table.sass"
import EmptySplash from "../../../../EmptySplash"

type Props = {
  rows: DeliverableFragment[]
  setSelectedId: (adId: string) => void
  selectedId: string | undefined
}

export default function CampaignDeliverablesTable({
  rows, setSelectedId, selectedId,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignDeliverablesTable" })

  const COLUMNS: GridColDef[] = useMemo(() => [
    // Overriding __check__ header to disable the add / remove all ability
    {
      field: "__check__",
      sortable: false,
      resizable: false,
      renderHeader: () => null,
      maxWidth: 30,
    },
    {
      field: "network",
      headerName: translate("Network"),
      sortable: false,
      renderCell: (params) => (
        <p className="capitalize-text">{ params.row.network?.toLowerCase() }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "account",
      headerName: translate("Account"),
      sortable: false,
      renderCell: (params) => (
        <p>{ params.row.userName }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "post-type",
      headerName: translate("Post Type"),
      sortable: false,
      renderCell: (params) => {
        const postType = params.row.postType?.toLowerCase().split("_").join(" ")
        return (
          <p className="capitalize-text">
            { postType }
          </p>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "title",
      headerName: translate("Title"),
      sortable: false,
      renderCell: (params) => (
        <p>{ params.row.title }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
  ], [ translate ])

  // Had to do a bit of a hack around MUI Datagrid. Datagrid does not offer an option
  // for single checkbox selection.
  const handleCheckboxSelect = (ids: string[]) => {
    if (!selectedId) {
      setSelectedId(ids[0])
    } else {
      const prevId = selectedId
      // ids should never have more than 2 entries
      const newId = ids[0] === prevId ? ids[1] : ids[0]
      setSelectedId(newId)
    }
  }

  return (
    <>
      <DataGrid
        className="cp_component-campaign-deliverables-table"
        getRowId={ (row) => row.id }
        checkboxSelection={ true }
        onRowSelectionModelChange={ (ids) => handleCheckboxSelect(ids.map((id) => id.toString())) }
        rowSelectionModel={ selectedId ? [ selectedId ] : [] }
        disableRowSelectionOnClick={ true }
        rowHeight={ 60 }
        columnHeaderHeight={ 40 }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        pinnedColumns={
        {
          left: [ "ad-set" ],
        }
      }
        rows={ rows.map((row) => ({
          id: row.id,
          network: row.campaignNetworkAccount.socialAccount.network,
          userName: row.campaignNetworkAccount.socialAccount.userName,
          postType: row.postType || "",
          title: row.name,
        })) }
        slots={ {
          noRowsOverlay: EmptyElement,
          noResultsOverlay: EmptyElement,
        } }
      />
      { rows.length === 0 && <EmptySplash headlineText="No deliverables found!" /> }
    </>
  )
}

import React, { useMemo } from "react"
import HighchartsMap from "highcharts/modules/map"
import HighchartsReact from "highcharts-react-official"
import SearchIcon from "@mui/icons-material/Search"
import pinpointRegionMap from "@highcharts/map-collection/custom/world-highres.topo.json"
import proj4 from "proj4"
import { LinearProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import Highcharts, {
  Options,
  SeriesMapbubbleDataOptions,
  TooltipFormatterContextObject,
} from "highcharts"

import * as GraphQL from "../../../graphql"
import Input from "../../Input"
import Tabs from "../../Tabs"
import Tooltip from "../../Tooltip"
import { AudienceDemographicLocation } from "../../../graphql"
import { LOCATION_MAP_COLORS, LOCATION_CITY_MAP_COLORS } from "../../../util/constant"
import { shorthandNumber, prettyPrintDecimal } from "../../../util/miscHelper"
import { worldMapData, unitedStatesMapData } from "./location-map-data"

import "./location.sass"

declare global {
  interface Window {proj4: typeof proj4}
}

HighchartsMap(Highcharts)

const baseOptions: Options = {
  title: {
    text: "",
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      verticalAlign: "bottom",
    },
  },
  tooltip: {
    borderColor: LOCATION_MAP_COLORS.tooltipBorder,
    formatter(this: TooltipFormatterContextObject) {
      return `
      <p style="font-size: 10px">${ this.point.name } </p>
      <br/>
      <span>Followers: </span>
      <span>${ shorthandNumber(this.point.value || 0) }</span>
      `
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: true,
  },
}

type TabValue = "city" | "country" | "state"

type Props = {
  audienceDemographics: GraphQL.AudienceDemographicsFragment | undefined | null
  socialProfile: GraphQL.SocialProfileFragment
  displayCities?: boolean
  displayCountries?: boolean
  displayStates?: boolean
}

export default function Location({
  audienceDemographics,
  socialProfile,
  displayCities = true,
  displayCountries = true,
  displayStates = true,
}: Props) {
  const [ query, setQuery ] = React.useState("")

  const [ tabIndex, setTabIndex ] = React.useState<number>(0)
  const { t: translate } = useTranslation([], { keyPrefix: "component.Location" })

  const [ tabs, tabValues ] = useMemo(() => {
    const newTabs = []
    const newTabValues: TabValue[] = []
    if (displayCities) {
      newTabs.push({
        label: translate("City"),
      })
      newTabValues.push("city")
    }
    if (displayCountries) {
      newTabs.push({ label: translate("Country") })
      newTabValues.push("country")
    }
    if (displayStates) {
      newTabs.push({ label: translate("State (US)") })
      newTabValues.push("state")
    }
    return [ newTabs, newTabValues ]
  }, [ displayCities, displayCountries, displayStates ])

  const countryOptions = React.useMemo(() => {
    const options = { ...baseOptions }
    options.chart = {
      map: worldMapData,
      backgroundColor: LOCATION_MAP_COLORS.backgroundColor,
      // width: 900,
    }
    options.colorAxis = {
      min: 0,
      stops: LOCATION_MAP_COLORS.stops,
    }
    options.series = []

    const data: [string, number | null][] = audienceDemographics
      ?.countryGroups
      .filter(({ locationCode }) => !!locationCode)
      .map(({ locationCode, value }) => ([
        locationCode?.toLowerCase() || "",
        Math.round(value * socialProfile.socialAccountStatistics.followers),
      ])) || []

    options.series.push({
      name: tabs[tabIndex].label,
      type: "map",
      data,
      states: {
        hover: {
          color: LOCATION_MAP_COLORS.statesHover,
        },
      },
      dataLabels: {
        enabled: false,
        format: "{point.name}",
      },
    })

    return options
  }, [ audienceDemographics ])

  const cityOptions = React.useMemo(() => {
    const options = { ...baseOptions }
    if (typeof window === "undefined") return options

    if (!window.proj4) window.proj4 = proj4

    options.chart = {
      backgroundColor: LOCATION_MAP_COLORS.backgroundColor,
    }

    options.xAxis = {
      crosshair: {
        zIndex: 5,
        dashStyle: "Dot",
        snap: true,
        color: "gray",
      },
    }
    options.yAxis = {
      enabled: true,
      crosshair: {
        zIndex: 5,
        dashStyle: "Dot",
        snap: true,
        color: "gray",
      },
    }
    options.series = []

    const data: SeriesMapbubbleDataOptions[] = audienceDemographics
      ?.cityGroups
      // the following filters out coordinates to prevent
      // the map dependency Proj4 from breaking when it encounters invalid coordinates.
      .filter(({ longitude, latitude }) => typeof longitude === "number"
        && typeof latitude === "number"
        && Number.isFinite(longitude)
        && Number.isFinite(latitude))
      .map(({
        latitude, longitude, value, name,
      }) => ({
        name,
        value: Math.round(value * socialProfile.socialAccountStatistics.followers),
        z: Math.round(value * socialProfile.socialAccountStatistics.followers),
        lat: latitude || 0,
        lon: longitude || 0,
      })) || []

    options.series.push({
      name: tabs[tabIndex].label,
      type: "map",
      mapData: pinpointRegionMap,
      showInLegend: false,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    })
    options.series.push({
      name: tabs[tabIndex].label,
      type: "mapbubble",
      data,
      dataLabels: {
        enabled: false,
        format: "{point.name}",
      },
      states: {
        hover: {
          halo: null,
        },
      },
      marker: {
        lineColor: LOCATION_CITY_MAP_COLORS.marker.lineColor,
        fillColor: LOCATION_CITY_MAP_COLORS.marker.fillColor,
        fillOpacity: 0.5,
      },
      maxSize: "12%",
      showInLegend: false,
    })

    return options
  }, [ audienceDemographics, socialProfile ])

  const stateOptions = React.useMemo(() => {
    const options = { ...baseOptions }
    options.chart = {
      map: unitedStatesMapData,
      backgroundColor: LOCATION_MAP_COLORS.backgroundColor,
    }
    options.colorAxis = {
      min: 0,
      stops: LOCATION_MAP_COLORS.stops,
    }
    options.series = []

    const data: [string, number | null][] = audienceDemographics
      ?.stateGroups
      .filter(({ locationCode }) => !!locationCode)
      .map(({ locationCode, value }) => ([
        `us-${ locationCode?.toLowerCase() || "" }`,
        Math.round(value * socialProfile.socialAccountStatistics.followers),
      ])) || []

    options.series.push({
      name: tabs[tabIndex].label,
      type: "map",
      data,
      states: {
        hover: {
          color: LOCATION_MAP_COLORS.statesHover,
        },
      },
      dataLabels: {
        enabled: false,
        format: "{point.name}",
      },
    })

    return options
  }, [ audienceDemographics ])

  const cityGroups: AudienceDemographicLocation[] = (
    audienceDemographics?.cityGroups
    || []
  ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))

  const countryGroups: AudienceDemographicLocation[] = (
    audienceDemographics?.countryGroups
    || []
  ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))

  const stateGroups: AudienceDemographicLocation[] = (
    audienceDemographics?.stateGroups
    || []
  ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))

  const getDemoGroup = () => {
    if (tabValues[tabIndex] === "country") return countryGroups
    if (tabValues[tabIndex] === "state") return stateGroups
    if (tabValues[tabIndex] === "city") return cityGroups
    return countryGroups
  }

  return (
    <div className="cp_location_component">
      <Tabs
        handleChange={ (index) => {
          setTabIndex(index)
          setQuery("")
        } }
        tabs={ tabs }
        controls={ <div /> }
      />
      <div className="cp_location_component-container">
        <div className="cp_location_component-container-chart">
          { tabValues[tabIndex] === "country" && (
            <HighchartsReact constructorType="mapChart" highcharts={ Highcharts } options={ countryOptions } />
          ) }
          { tabValues[tabIndex] === "state" && (
            <HighchartsReact constructorType="mapChart" highcharts={ Highcharts } options={ stateOptions } />
          ) }
          { tabValues[tabIndex] === "city" && (
            <HighchartsReact constructorType="mapChart" highcharts={ Highcharts } options={ cityOptions } />
          ) }
        </div>
        <div className="cp_location_component-container-search">
          <Input
            className="cp_location_component-container-search-input"
            placeholder={ translate("Search") }
            InputProps={ {
              startAdornment: <SearchIcon />,
            } }
            value={ query }
            onChange={ (e) => setQuery(e.currentTarget.value) }
          />
          <div className="cp_location_component-content">
            {
            getDemoGroup().map(({ name, value }, i) => {
              const followerCount = shorthandNumber(
                Math.floor(value * socialProfile.socialAccountStatistics.followers),
              )
              return (
                <div className="cp_location_component-content-demo" key={ name }>
                  <div className="cp_location_component-content-demo-header">
                    <p className="cp_location_component-content-demo-header-name">
                      { `${ i + 1 }. ${ name }` }
                    </p>
                    <p className="cp_location_component-content-demo-header-value">
                      { `${ prettyPrintDecimal(value, 1) }%` }
                    </p>
                  </div>
                  <Tooltip
                    title={ `${ followerCount } ${ translate("FOLLOWERS") }` }
                    arrow={ true }
                    placement="top"
                  >
                    <LinearProgress value={ value * 100 } variant="determinate" />
                  </Tooltip>
                </div>

              )
            }) }
            {
            getDemoGroup().length === 0 && (
              <p className="cp_location_component-content-demo-no-data">
                { translate("No Data") }
              </p>
            )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import { ArrowDropDown } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import {
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material"

import Button from "../../../Button"
import Dropdown from "../../../Dropdown"
import {
  displayValue,
  findMaxValue,
  getGroupOptions,
  getMetricOptions,
  getPlatformOptions,
  getSectionOptions,
  sortByDescCallback,
} from "./trendshelper"
import {
  CampaignReportGroup,
  CampaignReportInsightFragment,
  CampaignReportToggleSetting,
} from "../../../../graphql"

import "./trends.sass"

type Props = {
  influencerInsights: CampaignReportInsightFragment[]
  deliverableInsights: CampaignReportInsightFragment[]
  objectiveInsights: CampaignReportInsightFragment[]
  audienceInsights: CampaignReportInsightFragment[]
  creativeThemeInsights: CampaignReportInsightFragment[]
  toggles: CampaignReportToggleSetting[]
  template: string
}

export type TrendFilterOption = {
  value: string,
  label: string,
  key: string
}

export default function Trends({
  influencerInsights,
  deliverableInsights,
  audienceInsights,
  objectiveInsights,
  creativeThemeInsights,
  toggles,
  template,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReportingTrends" })

  const [ groupByFilter, setGroupByFilter ] = useState<TrendFilterOption>()
  const [ sectionFilter, setSectionFilter ] = useState<TrendFilterOption>()
  const [ networkFilter, setNetworkFilter ] = useState<TrendFilterOption>()
  const [ metricFilter, setMetricFilter ] = useState<TrendFilterOption>()

  const getSelectedInsight = () => {
    if (groupByFilter) {
      if (groupByFilter.value === CampaignReportGroup.Influencer) {
        return influencerInsights
      }
      if (groupByFilter.value === CampaignReportGroup.Deliverable) {
        return deliverableInsights
      }
      if (groupByFilter.value === CampaignReportGroup.Objective) {
        return objectiveInsights
      }
      if (groupByFilter.value === CampaignReportGroup.Audience) {
        return audienceInsights
      }
      if (groupByFilter.value === CampaignReportGroup.CreativeTheme) {
        return creativeThemeInsights
      }
    }
    return []
  }

  const groups = useMemo(() => getGroupOptions(
    influencerInsights,
    deliverableInsights,
    audienceInsights,
    objectiveInsights,
    creativeThemeInsights,
    toggles,
  ), [ toggles ])

  useEffect(() => {
    if (groups.length) {
      setGroupByFilter(groups[0])
    }
  }, [ groups ])

  const selectedInsight = getSelectedInsight()

  const sections = useMemo(() => getSectionOptions(selectedInsight, toggles, template), [ groupByFilter, toggles, template ])
  const networks = useMemo(
    () => getPlatformOptions(selectedInsight, toggles, sectionFilter),
    [ groupByFilter, toggles, sectionFilter ],
  )
  const metrics = useMemo(
    () => getMetricOptions(selectedInsight, toggles, networkFilter, sectionFilter),
    [ groupByFilter, toggles, networkFilter, sectionFilter ],
  )

  useEffect(() => {
    if (!sections.some((section) => section.value === sectionFilter?.value)) {
      setSectionFilter(sections[0])
    }
  }, [ sections ])
  useEffect(() => {
    if (!networks.some((network) => network.value === networkFilter?.value)) {
      setNetworkFilter(networks[0])
    }
  }, [ networks ])
  useEffect(() => {
    if (!metrics.some((metric) => metric.value === metricFilter?.value)) {
      setMetricFilter(metrics[0])
    }
  }, [ metrics ])

  const filterInsight = (
    insights: CampaignReportInsightFragment[],
  ) => insights.filter(
    (insight) => insight.section === sectionFilter?.value
      && insight.platform === networkFilter?.value
      && insight.toggle === metricFilter?.value
      && insight.value,
  ).sort(sortByDescCallback)

  const filteredInsights = useMemo(
    () => filterInsight(selectedInsight),
    [ groupByFilter, sectionFilter, networkFilter, metricFilter ],
  )

  const max = findMaxValue(filteredInsights)
  const barCeiling = max * 1.2

  if (groups.length === 0) {
    return null
  }

  return (
    <div className="cp_component_campaign-reporting-trends">
      <div className="dropdown-controls">
        <Dropdown
          buttonType="custom"
          placement="top"
          customButtonChildren={ (
            <Button
              label={ (
                <>
                  { `${ translate("GROUP BY") }: ${ groupByFilter?.label || "" }` }
                  <ArrowDropDown />
                </>
              ) }
              isPrimary={ false }
              variant="outlined"
            />
          ) }
        >
          <List disablePadding={ true }>
            { groups.map((group) => (
              <ListItem
                disablePadding={ true }
                disableGutters={ true }
                key={ group.key }
              >
                <ListItemButton
                  onClick={ () => setGroupByFilter(group) }
                >
                  <ListItemText
                    primary={ group.label }
                    className={ group.value === groupByFilter?.value ? "selected" : "" }
                  />
                </ListItemButton>
              </ListItem>
            )) }
          </List>
        </Dropdown>

        <div className="dropdown-right-controls">
          <Dropdown
            buttonType="custom"
            placement="top"
            customButtonChildren={ (
              <Button
                label={ (
                  <>
                    { `${ translate("SECTION") }: ${ sectionFilter?.label || "" }` }
                    <ArrowDropDown />
                  </>
) }
                isPrimary={ false }
                variant="contained"
              />
            ) }
          >
            <List disablePadding={ true }>
              { sections.map((section) => (
                <ListItem
                  disablePadding={ true }
                  disableGutters={ true }
                  key={ section.key }
                >
                  <ListItemButton
                    onClick={ () => setSectionFilter(section) }
                  >
                    <ListItemText
                      className={ section.value === sectionFilter?.value ? "selected" : "" }
                      primary={ section.label }
                    />
                  </ListItemButton>
                </ListItem>
              ))
              }
            </List>
          </Dropdown>

          <Dropdown
            buttonType="custom"
            placement="top"
            customButtonChildren={ (
              <Button
                label={ (
                  <>
                    { `${ translate("PLATFORM") }: ${ networkFilter?.label || "" }` }
                    <ArrowDropDown />
                  </>
)
               }
                isPrimary={ false }
                variant="outlined"
              />
            ) }
          >
            <List disablePadding={ true }>
              { networks.map((network) => (
                <ListItem
                  disablePadding={ true }
                  disableGutters={ true }
                  key={ network.key }
                >
                  <ListItemButton
                    onClick={ () => setNetworkFilter(network) }
                  >
                    <ListItemText
                      primary={ network.label }
                      className={ network.value === networkFilter?.value ? "selected" : "" }
                    />
                  </ListItemButton>
                </ListItem>
              )) }
            </List>
          </Dropdown>

          <Dropdown
            buttonType="custom"
            placement="top"
            customButtonChildren={ (
              <Button
                label={ (
                  <>
                    { `${ translate("METRIC") }: ${ metricFilter?.label || "" }` }
                    <ArrowDropDown />
                  </>
                ) }
                isPrimary={ false }
                variant="outlined"
              />
            ) }
          >
            <List disablePadding={ true }>
              { metrics.map((metric) => (
                <ListItem
                  disablePadding={ true }
                  disableGutters={ true }
                  key={ metric.key }
                >
                  <ListItemButton
                    onClick={ () => setMetricFilter(metric) }
                  >
                    <ListItemText
                      primary={ translate(metric.label) }
                      className={ metric.value === metricFilter?.value ? "selected" : "" }
                    />
                  </ListItemButton>
                </ListItem>
              ))
              }
            </List>
          </Dropdown>
        </div>
      </div>

      <div className="bar-charts">
        {
          filteredInsights.map((insight) => (
            <div className="bar-chart-container">
              <div className="header-container">
                <p className="name">{ insight.display }</p>
                <p className="metric">{ displayValue(insight.value, metricFilter?.value) }</p>
              </div>
              <LinearProgress
                variant="determinate"
                value={
                insight.value ? (insight.value / barCeiling) * 100 : 0 }
              />
            </div>
          ))
        }
      </div>
    </div>
  )
}

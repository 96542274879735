import React from "react"
import "./style.sass"
import { Badge } from "@mui/material"
import HourglassFullIcon from "@mui/icons-material/HourglassFull"
import NetworkIcon from "../NetworkIcon"
import Avatar, { AvatarProps } from "../Avatar"
import { Network } from "../../graphql"

export interface NetworkAvatarProps extends AvatarProps {
  network: Network
}

export default function NetworkAvatar({
  network, src, onClick, children, ...props
}: NetworkAvatarProps) {
  let avatarClassName = "cp_component_network-avatar"
  if (onClick) avatarClassName += " cp_component_network-avatar-clickable"
  return (
    <Badge
      anchorOrigin={ {
        vertical: "bottom",
        horizontal: "right",
      } }
      overlap="circular"
      badgeContent={
        <NetworkIcon isActive={ true } network={ network } additionalClasses="cp_component_network-badge" />
    }
    >

      <Avatar onClick={ onClick } src={ src } className={ avatarClassName } { ...props }>
        { children || <HourglassFullIcon /> }
      </Avatar>
    </Badge>

  )
}

import React from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../../../../util/apiClient"
import Modal from "../../../../Modal"
import { pushToast } from "../../../../../state/toastSlice"
import { setProfileDeleteModalOpen } from "../../../../../state/slidingPanelSlice"
import { submitDeleteProfile } from "../../../../../state/slidingPanelSlice/profiles"
import { useDispatch, useSelector } from "../../../../../state/hooks"

type Props = {
    open: boolean
}

export default function ModalDeleteProfile({ open }: Props) {
  const dispatch = useDispatch()
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalDeleteProfile" })

  const {
    profileManagementCurrentProfile,
  } = useSelector(({ slidingPanels }) => slidingPanels)

  const handleClose = () => {
    dispatch(setProfileDeleteModalOpen(false))
  }

  const handleSubmit = async () => {
    handleClose()
    let result
    if (profileManagementCurrentProfile) {
      result = await dispatch(submitDeleteProfile(profileManagementCurrentProfile?.id))
    }

    // If result is not defined, profileManagementCurrentProfile is not defined
    // If either is somehow undefined or there's an API error, result in error toast
    if (!result || API.isError(result)) {
      dispatch(pushToast({
        message: translate("There was an error. Please try again."),
        type: "error",
      }))
    } else {
      dispatch(pushToast({
        message: translate("Profile successfully deleted"),
        type: "success",
      }))
    }
  }

  return (
    <Modal
      className="cp_component_modal-delete-profile"
      title={ translate("Are you sure you want to delete this Profile?") }
      primaryAction={ handleSubmit }
      secondaryAction={ handleClose }
      closeAction={ handleClose }
      primaryLabel={ translate("Delete") }
      secondaryLabel={ translate("Cancel") }
      disablePortal={ true }
      open={ open }
    >
      <p>
        { /* eslint-disable-next-line max-len */ }
        { `${ profileManagementCurrentProfile?.name } ${ translate("will be deleted. You will no longer be charged for this Profile. If any balance is due your account will be charged a prorated amount for this billing cycle. If your subscription was annual your account will be refunded a prorated amount. For questions or concerns, please contact support@influential.co.") }` }
      </p>
    </Modal>
  )
}

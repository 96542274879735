import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import StorageUtil from "../../util/localStorage"
import { Theme } from "../../util/types"

export interface ThemeModeState {
  theme: Theme
}

// Initialize theme in localStorage if not set
const isThemeSet: boolean = StorageUtil.checkLocalStorageVal("theme")
if (!isThemeSet) {
  StorageUtil.setLocalStorageVal("theme", "light")
}

// Initialize state
const initialState: ThemeModeState = {
  theme: StorageUtil.getLocalStorageVal("theme", "light"),
}

export const themeModeSlice = createSlice({
  name: "themeMode",
  initialState,
  reducers: {
    setThemeMode: (state, action: PayloadAction<Theme>) => ({
      ...state,
      theme: action.payload,
    }),
    toggleThemeMode: (state) => ({
      ...state,
      theme: state.theme === "dark" ? "light" : "dark",
    }),
  },
})

export const { setThemeMode, toggleThemeMode } = themeModeSlice.actions
export default themeModeSlice.reducer

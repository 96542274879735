import React from "react"

import { useTranslation } from "react-i18next"
import { Avatar } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import "./post-preview.sass"
import IconButton from "../IconButton"
import { useSelector } from "../../state/hooks"
import Button from "../Button"

function PostPreview(): React.JSX.Element {
  const [ mediaIndex, setMediaIndex ] = React.useState(0)
  const [ expanded, setExpanded ] = React.useState(false)

  const {
    deliverable,
  } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  if (!deliverable?.deliverableMedia || deliverable.deliverableMedia.length === 0) return <> </>

  const selectedMedia = deliverable.deliverableMedia[mediaIndex]

  return (
    <div className="cp_component_modal-campaign-deliverable-preview">
      <div className="cp_component_modal-campaign-deliverable-preview-container">
        <div className="cp_component_modal-campaign-deliverable-preview-container-head">
          <p>{ translate("Post Preview") }</p>
          <div className="cp_component_modal-campaign-deliverable-preview-container-head-controls">
            <IconButton
              disabled={ mediaIndex === 0 }
              onClick={ () => setMediaIndex(Math.max(mediaIndex - 1, 0)) }
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              disabled={ mediaIndex === deliverable.deliverableMedia.length - 1 }
              onClick={ () => setMediaIndex(Math.min(mediaIndex + 1, deliverable.deliverableMedia.length - 1)) }
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        </div>
        <div className="cp_component_modal-campaign-deliverable-preview-container-body">
          { !selectedMedia.media.isVideo && <Avatar variant="square" src={ selectedMedia.media.url.address } /> }
          { selectedMedia.media.isVideo && (
          /* eslint-disable jsx-a11y/media-has-caption */
            <video controls={ true } src={ selectedMedia.media.originalUrl?.address } />
          ) }
          { deliverable.deliverableCaption ? (
            <>
              <p className={ `caption ${ expanded ? "expanded" : "compact" }` }>
                { deliverable.deliverableCaption?.text }
              </p>
              <Button
                onClick={ () => {
                  setExpanded(!expanded)
                } }
                isPrimary={ false }
                variant="text"
                label={ expanded ? translate("Read less...") : translate("Read more...") }
              />
            </>
          ) : null }
        </div>
      </div>
    </div>
  )
}

export default PostPreview

import React, { useEffect, useMemo } from "react"
import AddIcon from "@mui/icons-material/Add"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import CloseIcon from "@mui/icons-material/Close"
import FlagCircleIcon from "@mui/icons-material/FlagCircle"
import dayjs, { Dayjs } from "dayjs"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  InputAdornment,
  Badge,
  IconButton,
  ListItem,
  ListItemText,
  Collapse,
  FormControlLabel,
} from "@mui/material"

import * as Constant from "../../util/constant"
import Avatar from "../Avatar"
import AvatarUploader from "../AvatarUploader"
import Checkbox from "../Checkbox"
import DatePicker from "../DatePicker"
import Divider from "../Divider"
import IconButtonClose from "../IconButtonClose"
import Input from "../Input"
import ListsAutoComplete from "./ListsAutocomplete"
import LoadingIndicator from "../LoadingIndicator"
import MenuItem from "../MenuItem"
import Modal from "../Modal"
import NetworkAvatar from "../NetworkAvatar"
import Pill from "../Pill"
import Select from "../Select"
import Switch from "../Switch"
import { FileResponse, Scope } from "../../util/types"
import { createCampaign, isSuccess } from "../../util/apiClient"
import { pushToast } from "../../state/toastSlice"
import { uploadFile, isNumericString } from "../../util/miscHelper"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  closeCampaignModal,
  fetchCampaignManagers,
  updateCampaign,
} from "../../state/campaignModalSlice"
import {
  LinkInput,
  SuggestionListRowFragment,
  CreateCampaignMutationVariables,
  UpdateCampaignMutationVariables,
  SuggestionListMode,
} from "../../graphql"

import "./style.sass"

function ModalCampaign(): React.JSX.Element {
  const [ avatar, setAvatar ] = React.useState<FileResponse | undefined>()
  const [ campaignName, setCampaignName ] = React.useState("")
  const [ campaignDescription, setCampaignDescription ] = React.useState("")
  const [ campaignBudget, setCampaignBudget ] = React.useState("")
  const [ campaignEndDate, setCampaignEndDate ] = React.useState<Dayjs | null>(null)
  const [ campaignStartDate, setCampaignStartDate ] = React.useState<Dayjs | null>(null)
  const [ reportingEndDate, setReportingEndDate ] = React.useState<Dayjs | null>(null)
  const [ reportingStartDate, setReportingStartDate ] = React.useState<Dayjs | null>(null)
  const [ selectedLists, selectLists ] = React.useState<Array<SuggestionListRowFragment>>([])
  const [ linkTitle, setLinkTitle ] = React.useState("")
  const [ linkURL, setLinkURL ] = React.useState("")
  const [ links, setLinks ] = React.useState<LinkInput[]>([])
  const [ loadingPrimaryAction, setLoadingPrimaryAction ] = React.useState(false)
  const [ isTikTok, setIsTikTok ] = React.useState(false)
  const [ loadedCampaignIsTikTok, setLoadedCampaignIsTikTok ] = React.useState(false)
  const [ isTikTokManaged, setIsTikTokManaged ] = React.useState(false)
  const [ brandName, setBrandName ] = React.useState("")
  const [ contactEmail, setContactEmail ] = React.useState("")
  const [ tikTokPublished, setTikTokPublished ] = React.useState(false)

  const {
    openCampaignModal,
    isEditingCampaign,
    campaignToEdit,
    updateCallback,
    campaignManagers,
  } = useSelector(({ campaignModal }) => campaignModal)

  const { user, scopes } = useSelector(({ user: userSlice }) => userSlice)

  const dispatch = useDispatch()
  const { vanity } = useParams()

  const resetState = () => {
    setAvatar(undefined)
    setCampaignName("")
    setCampaignBudget("")
    setCampaignEndDate(null)
    setCampaignStartDate(null)
    setReportingStartDate(null)
    setReportingEndDate(null)
    setLinkTitle("")
    setLinkURL("")
    setLinks([])
    selectLists([])
    setIsTikTok(false)
    setIsTikTokManaged(false)
    setBrandName("")
    setTikTokPublished(false)
    setContactEmail("")
    setLoadedCampaignIsTikTok(false)
  }

  const loadCampaign = () => {
    resetState()
    if (!isEditingCampaign || !campaignToEdit) return
    if (campaignToEdit.thumbnail) setAvatar({ url: campaignToEdit.thumbnail?.url.address, id: campaignToEdit.thumbnail.id })
    setCampaignName(campaignToEdit.name)
    setCampaignDescription(campaignToEdit.description || "")
    setCampaignBudget(campaignToEdit.budget?.toString(10) || "")
    if (campaignToEdit.flightStartDate) setCampaignStartDate(dayjs(campaignToEdit.flightStartDate))
    if (campaignToEdit.flightEndDate) setCampaignEndDate(dayjs(campaignToEdit.flightEndDate))
    if (campaignToEdit.reportStartDate) setReportingStartDate(dayjs(campaignToEdit.reportStartDate))
    if (campaignToEdit.reportEndDate) setReportingEndDate(dayjs(campaignToEdit.reportEndDate))
    setLinks(campaignToEdit.links.map(({ title, url }) => ({ title, url: url.address })))
    selectLists(campaignToEdit.suggestionLists)
    setIsTikTok(campaignToEdit.enableTiktok)
    setLoadedCampaignIsTikTok(campaignToEdit.enableTiktok)
    setIsTikTokManaged(campaignToEdit.tiktokMsp)
    setBrandName(campaignToEdit.brand || "")
    setTikTokPublished(campaignToEdit.tiktokPublished)
    setContactEmail(campaignToEdit.tiktokEmail?.address || "")
  }

  useEffect(loadCampaign, [ openCampaignModal ])

  useEffect(() => { dispatch(fetchCampaignManagers()) }, [])

  const campaignManagersMenuItems = React.useMemo(() => {
    if (!isSuccess(campaignManagers)) return []
    return campaignManagers.payload.getCampaignManagers.map((manager) => {
      const { email } = manager.contact
      return (
        <MenuItem
          key={ email.address }
          onClick={ () => setContactEmail(email.address) }
          value={ email.address }
          className="cp_brands_component-date-item"
        >
          <ListItemText>{ email.address }</ListItemText>
        </MenuItem>
      )
    })
  }, [ campaignManagers ])

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaign" })

  const closeModal = () => {
    resetState()
    dispatch(closeCampaignModal())
  }

  const createCampaignHandler = async () => {
    const params: CreateCampaignMutationVariables = {
      thumbnailId: avatar ? `${ avatar.id }` : null,
      name: campaignName,
      customerIds: [],
      description: campaignDescription,
      budget: parseFloat(campaignBudget),
      flightStartDate: campaignStartDate?.format(Constant.MARIA_DATE_FORMAT),
      flightEndDate: campaignEndDate?.format(Constant.MARIA_DATE_FORMAT),
      reportStartDate: reportingStartDate?.format(Constant.MARIA_DATE_FORMAT),
      reportEndDate: reportingEndDate?.format(Constant.MARIA_DATE_FORMAT),
      enableTiktok: isTikTok,
      tiktokMsp: isTikTokManaged,
      suggestionListIds: selectedLists.map((list) => list.id),
      links: links.map((link) => ({ title: link.title, url: link.url })),
    }
    if (isTikTok) {
      if (!isSuccess(user)) return
      if (!user.payload.currentUser) return
      params.brand = brandName
      params.tiktokBrief = ""
      params.tiktokEmail = contactEmail
      params.tiktokCustomerId = user.payload.currentUser.customer.id
    }
    setLoadingPrimaryAction(true)
    const result = await createCampaign(params)
    setLoadingPrimaryAction(false)
    if (isSuccess(result)) {
      dispatch(pushToast({
        type: "success",
        message: translate("Campaign Created"),
        additionalInfo: result.payload ? (
          <Link
            className="cp-toast-link"
            to={ `/${ vanity }/campaigns/${ result.payload.createCampaign.id }` }
          >
            { translate("VIEW CAMPAIGN") }
          </Link>
        ) : null,
      }))
      dispatch(closeCampaignModal())
    } else {
      dispatch(pushToast({
        type: "error",
        message: translate("Unable to create campaign"),
      }))
    }
  }

  const updateCampaignHandler = async () => {
    if (!campaignToEdit) return
    const params: UpdateCampaignMutationVariables = {
      thumbnailId: avatar ? `${ avatar.id }` : null,
      updateCampaignId: campaignToEdit.id,
      name: campaignName,
      customerIds: campaignToEdit.customers.map((customer) => customer.id),
      description: campaignDescription,
      budget: parseFloat(campaignBudget),
      flightStartDate: campaignStartDate?.format(Constant.MARIA_DATE_FORMAT),
      flightEndDate: campaignEndDate?.format(Constant.MARIA_DATE_FORMAT),
      reportStartDate: reportingStartDate?.format(Constant.MARIA_DATE_FORMAT),
      reportEndDate: reportingEndDate?.format(Constant.MARIA_DATE_FORMAT),
      enableTiktok: isTikTok,
      tiktokMsp: isTikTokManaged,
      suggestionListIds: selectedLists.map((list) => list.id),
      links: links.map((link) => ({ title: link.title, url: link.url })),
    }
    if (isTikTok) {
      if (!isSuccess(user)) return
      if (!user.payload.currentUser) return
      params.brand = brandName
      params.tiktokBrief = ""
      params.tiktokEmail = contactEmail
      params.tiktokCustomerId = user.payload.currentUser.customer.id
    }
    setLoadingPrimaryAction(true)
    const result = await dispatch(updateCampaign(params))
    setLoadingPrimaryAction(false)
    if (isSuccess(result)) {
      dispatch(pushToast({
        type: "success",
        message: translate("Campaign Updated"),
      }))
      dispatch(closeCampaignModal())
      if (updateCallback) updateCallback()
    } else {
      dispatch(pushToast({
        type: "error",
        message: translate("Unable to update campaign"),
      }))
    }
  }

  const isValid = React.useMemo(() => {
    switch (true) {
      case campaignName.length === 0:
      case isTikTok && (brandName.length === 0 || contactEmail.length === 0):
        return false
      default:
        return true
    }
  }, [ campaignName, isTikTok, brandName, contactEmail ])

  const primaryButtonLabel = useMemo(() => {
    if (loadingPrimaryAction) return <LoadingIndicator size={ 20 } />
    return isEditingCampaign ? translate("Save Campaign") : translate("Create Campaign")
  }, [ isEditingCampaign, loadingPrimaryAction ])

  function setDateState(
    d: Dayjs | string | null,
    callback: (dateToSet: Dayjs) => void,
  ): void {
    if (d == null) return

    const date = typeof d === "string" ? dayjs(d) : d
    if (date.isValid()) {
      callback(date)
    }
  }

  const handleClientAvatarUpdate = async (file: File) => {
    const fileStatus = await uploadFile(file)
    if (fileStatus.id) {
      setAvatar({ id: fileStatus.id, url: fileStatus.url })
    }
  }

  return (
    <Modal
      title={ isEditingCampaign ? translate("Edit Campaign") : translate("Create Campaign") }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ openCampaignModal }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ isEditingCampaign ? updateCampaignHandler : createCampaignHandler }
      maxWidth="xl"
      className="cp_component_modal-campaign"
      disabled={ !isValid || loadingPrimaryAction }
    >
      <div className="cp_component_modal-campaign_row cp_component_modal-campaign_panels">
        <div className="cp_component_modal-campaign_column_2">
          <div className="cp_component_modal-campaign-details">
            <p className="cp_component_modal-campaign_panels-title">{ translate("Details") }</p>
            <div className="cp_component_modal-campaign_photo">
              { avatar
                ? (
                  <Badge
                    className="cp_component_modal-campaign_photo-badge"
                    badgeContent={ (
                      <IconButton
                        className="cp_avatar-removal"
                        aria-label="delete"
                        onClick={ () => setAvatar(undefined) }
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    ) }
                    anchorOrigin={ {
                      vertical: "bottom",
                      horizontal: "right",
                    } }
                  >
                    <Avatar src={ avatar.url } />
                  </Badge>
                )
                : (
                  <AvatarUploader
                    customClasses="cp_component_modal-campaign_photo-uploader"
                    title={ translate("Campaign Thumbnail") }
                    infoText={ translate("Upload a campaign thumbnail") }
                    onAvatarSelect={ handleClientAvatarUpdate }
                  />
                )
            }
            </div>
            <div className="cp_component_modal-campaign-details-label">
              <p className="label_small-caps-bold">{ translate("Campaign Name") }</p>
              <p className="label_small-caps-bold counter">
                { campaignName.length }
                /60
              </p>
            </div>
            <Input
              value={ campaignName }
              onChange={ (e) => setCampaignName(e.currentTarget.value) }
              fullWidth={ true }
              InputProps={ {
                inputProps: { maxLength: 60 },
              } }
              placeholder={ translate("Enter Campaign Name") }
            />
            <div className="cp_component_modal-campaign-details-label">
              <p className="label_small-caps-bold">{ translate("Description") }</p>
              <p className="label_small-caps-bold counter">
                { campaignDescription.length }
                /1000
              </p>
            </div>
            <Input
              value={ campaignDescription }
              onChange={ (e) => setCampaignDescription(e.currentTarget.value) }
              fullWidth={ true }
              InputProps={ {
                inputProps: { maxLength: 1000 },
              } }
              placeholder={ translate("Enter Campaign Description") }
            />
            <Input
              className="cp_component_modal-campaign-details-input_budget"
              value={ translate("Format Number", { value: campaignBudget }) }
              onChange={ (e) => {
                const cleanedString = e.currentTarget.value.replace(/,/g, "")
                if (isNumericString(cleanedString)) return
                setCampaignBudget(cleanedString)
              } }
              fullWidth={ true }
              InputProps={ {
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="small" />
                  </InputAdornment>
                ),
              } }
              helperText={ (
                <>
                  { translate("Budget total for this campaign in USD") }
                  <br />
                  { translate("(For internal tracking purposes only)") }
                </>
            ) }
              label={ translate("Budget") }
              placeholder={ translate("Enter Budget") }
            />
            <div className="cp_component_modal-campaign-details-dates-container">
              <p className="label_large-bold">
                { translate("CAMPAIGN DATES") }
              </p>
              <div className="cp_component_modal-campaign-details-dates">
                <div>
                  <p className="label_small-caps-semibold">
                    { translate("Flight Start Date") }
                    <small>
                      { translate("(PST)") }
                    </small>
                  </p>
                  <DatePicker
                    value={ campaignStartDate }
                    onDateChange={ (d) => setDateState(d, setCampaignStartDate) }
                    onAccept={ (d) => setDateState(d, setCampaignStartDate) }
                  />
                </div>
                <Divider className="cp_component_modal-campaign-details-dates-spacer" />
                <div>
                  <p className="label_small-caps-semibold">
                    { translate("Flight End Date") }
                    <small>
                      { translate("(PST, Inclusive)") }
                    </small>
                  </p>
                  <DatePicker
                    minDate={ campaignStartDate || undefined }
                    value={ campaignEndDate }
                    onDateChange={ (d) => setDateState(d, setCampaignEndDate) }
                    onAccept={ (d) => setDateState(d, setCampaignEndDate) }
                  />
                </div>
              </div>
            </div>
            <div className="cp_component_modal-campaign-details-dates-container">
              <p className="label_large-bold">
                { translate("REPORTING DATES") }
              </p>
              <div className="cp_component_modal-campaign-details-dates">
                <div>
                  <p className="label_small-caps-semibold">
                    { translate("Reporting Start Date") }
                    <small>
                      { translate("(PST)") }
                    </small>
                  </p>
                  <DatePicker
                    value={ reportingStartDate }
                    onDateChange={ (d) => setDateState(d, setReportingStartDate) }
                    onAccept={ (d) => setDateState(d, setReportingStartDate) }
                  />
                </div>
                <Divider className="cp_component_modal-campaign-details-dates-spacer" />
                <div>
                  <p className="label_small-caps-semibold">
                    { translate("Reporting End Date") }
                    <small>
                      { translate("(PST, Inclusive)") }
                    </small>
                  </p>
                  <DatePicker
                    minDate={ reportingStartDate || undefined }
                    value={ reportingEndDate }
                    onDateChange={ (d) => setDateState(d, setReportingEndDate) }
                    onAccept={ (d) => setDateState(d, setReportingEndDate) }
                  />
                </div>
              </div>
              <div className="cp_component_modal-campaign-details-links">
                <p className="cp_component_modal-campaign_panels-title">{ translate("Links") }</p>
                <div className="cp_component_modal-campaign-details-links-inputs">
                  <Input
                    fullWidth={ true }
                    placeholder={ translate("Enter Link Title") }
                    label={ translate("Title") }
                    onChange={ (e) => setLinkTitle(e.currentTarget.value) }
                    value={ linkTitle }
                  />
                  <Input
                    fullWidth={ true }
                    placeholder={ translate("Enter URL") }
                    onChange={ (e) => setLinkURL(e.currentTarget.value) }
                    value={ linkURL }
                    label={ translate("URL") }
                    InputProps={ {
                      endAdornment: (
                        <IconButton
                          className="cp_component_modal-campaign-details-links-add"
                          onClick={ () => {
                            setLinks([ ...links, { title: linkTitle.trim(), url: linkURL.trim() } ])
                            setLinkTitle("")
                            setLinkURL("")
                          } }
                          disabled={ linkTitle === "" || linkURL === "" }
                        >
                          <AddIcon />
                        </IconButton>
                      ),
                    } }
                  />
                </div>
                <div className="cp_component_modal-campaign-details-links-chips">
                  { links.map((link, linkIndex) => {
                    const key = `${ link.title }-${ link.url }-${ linkIndex }`
                    return (
                      <Pill
                        key={ key }
                        label={ (
                          <>
                            { link.title }
                            <small>{ link.url }</small>
                          </>
                      ) }
                        deleteIcon={ <CloseIcon /> }
                        onDelete={ () => setLinks(links.filter((_, i) => i !== linkIndex)) }
                      />
                    )
                  }) }
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="cp_component_modal-campaign_column">
          <div className="cp_component_modal-campaign-lists">
            <div className="">
              <h4 className="headline_small">
                { translate("Lists") }
              </h4>
              <p className="label_large-bold">
                { translate("Attach lists to this campaign") }
              </p>
              <p className="body_small">
                {
                // eslint-disable-next-line max-len
                translate("When you attach a list, approved social accounts are imported into the campaign. You can also add or remove lists from inside the campaign detail view.") }
              </p>
            </div>
            <div className="cp_component_modal-campaign-lists-autocomplete-container">
              <ListsAutoComplete
                setSelectedLists={ selectLists }
                selectedLists={ selectedLists }
              />
            </div>
            <div className="cp_component_modal-campaign-lists-selected">
              { selectedLists.map((list) => (
                <ListItem
                  key={ list.id }
                  className="cp_component_modal-campaign-lists-selected-list"
                >
                  <div className="cp_component_modal-campaign-lists-selected-list-left">
                    <NetworkAvatar network={ list.network }>
                      { list.name[0].toUpperCase() }
                    </NetworkAvatar>
                    <div className="cp_component_modal-campaign-lists-selected-list-left-description">
                      <p>
                        { list.name }
                        { " " }
                        { list.suggestionListMode === SuggestionListMode.Campaign ? <FlagCircleIcon /> : null }
                      </p>
                      <strong>
                        {
                        `${ translate("Created by") } ${ list.creator.username } 
                        | ${ dayjs(list.created * 1000).format(Constant.LONGFORM_DATE) }`
                        }
                      </strong>
                    </div>
                  </div>
                  <IconButtonClose onClick={ () => selectLists(selectedLists.filter((l) => l.id !== list.id)) } />
                </ListItem>
              )) }
            </div>
          </div>
          { scopes.includes(Scope.FEATURE_TIKTOK) && (
            <div className="cp_component_modal-campaign-tik-tok">
              <div className="cp_component_modal-campaign-tik-tok-header">
                <h4 className="headline_small">
                  { translate("Enable TikTok") }
                </h4>
                <Switch
                  isChecked={ isTikTok }
                  hasIcon={ true }
                  handleChange={ () => setIsTikTok(!isTikTok) }
                  disabled={ isEditingCampaign && loadedCampaignIsTikTok }
                />
              </div>
              <Collapse in={ isTikTok }>
                <div className="cp_component_modal-campaign-tik-tok-content">
                  <Divider />
                  <p className="label_large-bold">
                    { translate("Enable MSP") }
                  </p>
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ isTikTokManaged }
                        onChange={ () => setIsTikTokManaged(!isTikTokManaged) }
                        disabled={ isEditingCampaign && tikTokPublished }
                      />
                    ) }
                    label={ translate("TikTok Managed Service Provider Campaign") }
                  />
                  <Input
                    value={ brandName }
                    onChange={ (e) => setBrandName(e.currentTarget.value) }
                    fullWidth={ true }
                    placeholder={ translate("Enter Brand Name") }
                    label={ translate("Brand Name*") }
                  />
                  <p className="label_small-caps-bold">
                    { translate("Contact Email*") }
                  </p>
                  <Select
                    onChange={ (e) => setContactEmail(e.target.value) }
                    className="cp_component_modal-campaign-tik-tok-email"
                    fullWidth={ true }
                    label=""
                    value={ contactEmail }
                    id="campaign-tt-contact-email"
                    labelId="campaign-tt-contact-email-label"
                    menuItems={ campaignManagersMenuItems }
                    disabled={ isEditingCampaign && tikTokPublished }
                  />
                </div>
              </Collapse>
            </div>
          ) }
        </div>
      </div>
    </Modal>
  )
}

export default ModalCampaign

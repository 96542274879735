import React, { useEffect, useState } from "react"
import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import "./social-account-autocomplete.sass"

import { CampaignNetworkAccountFragment } from "../../graphql"
import Input, { Props as InputProps } from "../Input"
import NetworkAvatar from "../NetworkAvatar"
import { shorthandNumber } from "../../util/miscHelper"

interface Props {
  selectSocialAccount: (account: CampaignNetworkAccountFragment) => void
  accounts: CampaignNetworkAccountFragment[],
}

function ListsAutocomplete({
  selectSocialAccount,
  accounts,
}: Props) {
  const [ filteredAccounts, setFilteredAccounts ] = useState<CampaignNetworkAccountFragment[]>([])

  useEffect(() => {
    if (accounts.length === filteredAccounts.length) return
    setFilteredAccounts([ ...accounts ])
  }, [ accounts ])

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverable" })

  const searchSocialAccounts = async (e: React.SyntheticEvent, substring: string) => {
    const filtered = accounts.filter((
      account,
    ) => account.socialAccount.userName.includes(substring))
    setFilteredAccounts(filtered)
  }

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    changeAccounts: string | CampaignNetworkAccountFragment,
  ) => {
    if (typeof changeAccounts === "string") return

    selectSocialAccount(changeAccounts)
  }

  const filter = createFilterOptions<CampaignNetworkAccountFragment>()

  const autoCompleteFilter = (
    autoCompleteAccounts: CampaignNetworkAccountFragment[],
    params: FilterOptionsState<CampaignNetworkAccountFragment>,
  ) => {
    const filtered = filter(autoCompleteAccounts, params)

    return filtered
  }

  return (
    <Autocomplete
      filterSelectedOptions={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") return option
        return option.socialAccount.userName
      } }
      className="cp_component_autocomplete_social-accounts"
      ListboxProps={ {
        className: "cp_component_autocomplete_social-accounts",
      } }
      onInputChange={ searchSocialAccounts }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      filterOptions={ autoCompleteFilter }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      openOnFocus={ true }
      id="autocomplete-social-accounts"
      options={ filteredAccounts.sort() }
      renderOption={ (props, account, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        return (
          <li
            { ...props }
            key={ account.id }
          >
            <div className="cp_component_autocomplete_social-account">
              <div className="cp_component_autocomplete_social-account-avatar">
                <NetworkAvatar
                  src={ account.socialAccount.profilePictureUrl }
                  network={ account.socialAccount.network }
                  size="md"
                />
              </div>
              <div className="cp_component_autocomplete_social-account-right">
                <p className="cp_component_autocomplete_social-account-right-name">
                  { account.socialAccount.userName }
                </p>
                <p className="cp_component_autocomplete_social-account-right-followers">
                  { shorthandNumber(account.socialAccount.socialAccountStatistics.followers) }
                  { ` ${ translate("FOLLOWERS") }` }
                </p>
              </div>
            </div>
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          placeholder={ translate("Type Social Account Name") }
        />
      )
}
    />
  )
}

export default ListsAutocomplete

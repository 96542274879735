import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import "./style.sass"
import "../../AccountInsights/account-insights-details.sass"
import Tabs, { TabLabelProps } from "../../../Tabs"
import TopPosts from "./TopPosts"
import RelevantPostsByKeywords from "./RelevantPostsByKeywords"
import RelevantPostsByImageTag from "./RelevantPostsByImageTags"

type Props = {
  displayTopPosts: boolean
  displayImageTagPosts: boolean
  displayKeywordPosts: boolean
}

// eslint-disable-next-line no-shadow
enum TabPathsEnum {
  TOP_POSTS = "top-posts",
  KEYWORDS = "relevant-posts",
  IMAGE_TAGS = "image-tags-posts",
}

interface SuggestionsTabLabelProps extends TabLabelProps {
  path: TabPathsEnum.TOP_POSTS
  | TabPathsEnum.KEYWORDS
  | TabPathsEnum.IMAGE_TAGS
}

export default function AccountContentDetails({
  displayTopPosts, displayImageTagPosts, displayKeywordPosts,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSuggestionSocialContent" })
  const navigate = useNavigate()
  const {
    listCode, tabPath, contentTabPath,
  } = useParams()

  const tabs: SuggestionsTabLabelProps[] = React.useMemo(() => {
    const t = []
    if (displayTopPosts) t.push({ label: translate("Top Posts"), path: TabPathsEnum.TOP_POSTS })
    if (displayKeywordPosts) {
      t.push({ label: translate("Relevant Keywords"), path: TabPathsEnum.KEYWORDS })
    }
    if (displayImageTagPosts) {
      t.push({ label: translate("Relevant Image Tags"), path: TabPathsEnum.IMAGE_TAGS })
    }
    return t
  }, [ translate, displayTopPosts, displayKeywordPosts, displayImageTagPosts ])

  const setSubTab = (tabIndex: number) => {
    if (tabs.length > 0) {
      const newTabPath = tabs[tabIndex].path
      navigate(`/public/list/${ listCode }/${ tabPath }/${ newTabPath }`)
    }
  }

  React.useEffect(() => {
    setSubTab(0)
  }, [])

  const displayDefaultContent = () => {
    if (displayTopPosts) return <TopPosts />
    if (displayKeywordPosts) return <RelevantPostsByKeywords />
    if (displayImageTagPosts) return <RelevantPostsByImageTag />
    return null
  }

  const tabContent = () => {
    switch (contentTabPath) {
      case TabPathsEnum.TOP_POSTS:
        if (displayTopPosts) return <TopPosts />
        return null
      case TabPathsEnum.KEYWORDS:
        if (displayKeywordPosts) return <RelevantPostsByKeywords />
        return null
      case TabPathsEnum.IMAGE_TAGS:
        if (displayImageTagPosts) return <RelevantPostsByImageTag />
        return null
      case undefined:
        return displayDefaultContent()
      default:
        return (
          null
        )
    }
  }

  const tabIndex = React.useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === contentTabPath)
    return foundTab ? tabs.indexOf(foundTab) : 0
  }, [ contentTabPath ])

  return (
    <div className="cp_profile_component-public-list-content-tabs-container">
      { tabs.length > 1
        ? (
          <>
            <Tabs
              handleChange={ (index) => setSubTab(index) }
              tabs={ tabs }
              defaultValue={ tabIndex }
            />
            <div className="cp_profile_component-public-list-content-tabs-content">
              { tabContent() }
            </div>
          </>
        ) : (
          <>
            <h4>{ tabs[0].label }</h4>
            { displayDefaultContent() }
          </>
        )
        }
    </div>
  )
}

import React from "react"
import { useSelector } from "../../state/hooks"
import LoadingIndicator from "../LoadingIndicator"
import * as API from "../../util/apiClient"
import ListGroupManagementTable from "./ListGroupManagementTable"

export default function ListGroupManagement() {
  const { listGroup: fetchedListGroup } = useSelector((state) => state.listGroup)

  const renderContent = () => {
    if (fetchedListGroup === "init" || fetchedListGroup === "loading") {
      return <LoadingIndicator size={ 50 } flexWrapperEnabled={ true } />
    }
    if (API.isSuccess(fetchedListGroup)) {
      return <ListGroupManagementTable listGroup={ fetchedListGroup.payload.suggestionListGroupById } />
    }
    return <p>Error!</p>
  }

  return (
    <div>
      { renderContent() }
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicator from "../../../LoadingIndicator"
import EngagementMap from "../../AccountInsights/EngagementMap"
import * as API from "../../../../util/apiClient"
import { getLocalTZOffset } from "../../../../util/miscHelper"
import { getDailyHourlyEngagementRate } from "../../../../state/publicListSlice"

export default function EngagementMapWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const {
    list, selectedListSocialAccount, dailyEngagementRate,
  } = useSelector(({ publicList }) => publicList)
  const dispatch = useDispatch()
  const { listCode } = useParams()
  const [ timeZone, setTimeZone ] = useState(getLocalTZOffset() || -8)

  useEffect(() => {
    if (API.isSuccess(list) && selectedListSocialAccount && listCode) {
      const variables = {
        suggestionListCode: listCode,
        socialAccountId: selectedListSocialAccount.socialAccount.id,
        timeZoneOffset: timeZone,
        limit: 10,
      }
      dispatch(getDailyHourlyEngagementRate(variables))
    }
  }, [ timeZone, list, selectedListSocialAccount, listCode ])

  if (list === "loading" || list === "init" || dailyEngagementRate === "loading" || dailyEngagementRate === "init") {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (list.status === "error" || dailyEngagementRate.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <EngagementMap
      dailyEngagementRate={ dailyEngagementRate.payload.publicGetDailyHourlyEngagementSummary }
      timeZone={ timeZone }
      setTimeZone={ setTimeZone }
    />
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useSelector } from "../../../state/hooks"
import LoadingIndicator from "../../LoadingIndicator"
import AccountInsights from "../AccountInsights/AccountInsights"

export default function AccountInsightsWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { profile } = useSelector(({ socialProfile }) => socialProfile)
  if (profile === "loading" || profile === "init") {
    return (
      <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
    )
  }
  if (profile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <AccountInsights socialAccount={ profile.payload.socialAccount } />
  )
}

import React from "react"
import dayjs from "dayjs"
import { Divider, Typography } from "@mui/material"
import { Launch } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import * as Constant from "../../../../util/constant"
import * as GraphQL from "../../../../graphql"
import Avatar from "../../../Avatar"
import NetworkIcon from "../../../NetworkIcon"

import "./mention-post-card.sass"

type Props = {
    mention: GraphQL.MentionedPostFragment
}

export default function MentionedPostCard({ mention }: Props) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalBrandDetails" })

  return (
    <div className="cp_component_mentioned-post-card">
      <div className="cp_component_mentioned-post-card-header">
        <NetworkIcon isActive={ true } network={ mention.socialAccount.network } />
        <p className="cp_component_mentioned-post-date-posted">
          { dayjs(mention.postedTime, "X").format(Constant.LONGFORM_DATE_TIME) }
        </p>
        <Typography
          className="cp_component_mentioned-post-network-link"
          onClick={ () => window.open(mention.permalink, "_blank") }
        >
          { `${ translate("View on") } ${ mention.socialAccount.network.toString() }` }
          <Launch />
        </Typography>
      </div>
      <Divider />
      <div className="cp_component_mentioned-post-card-body">
        <Avatar
          src={ mention.postMedia[0].thumbnailUrl }
          variant="square"
          className="cp_component_mentioned-post-avatar"
        />
        <p>{ mention.postContent }</p>
      </div>
    </div>
  )
}

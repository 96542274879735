import React, {
  JSX,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

// TODO: Replace DataGridPro component with our own custom DataGrid component
// and resolve style conflicts thereunto pertaining...
import {
  DataGridPro,
  GridCellParams,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  GridTreeNode,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { ManageSearchRounded, WarningRounded } from "@mui/icons-material"

import * as API from "../../util/apiClient"
import * as AccountsTable from "./AccountsTable"
import * as ContentTable from "./ContentTable"
import { SearchContentEllipsisMenuOptions } from "./ContentEllipsisMenuCell"
import * as GraphQL from "../../graphql"
import * as ListAddAccountState from "../../state/listAddAccount"
import * as SearchHelper from "../../util/searchHelper"
import * as SearchState from "../../state/searchSlice"
import { Scope, ScoreBreakDown } from "../../util/types"
import { fetchSearchResults, setSearchInput } from "../../state/searchSlice"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../state/scoreBreakDownSlice"
import { pushToast } from "../../state/toastSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  NsfwStatus,
  triggerNfswToast,
  markPostNSFW,
  markSocialAcctNSFW,
} from "./nfswHelper"
import { NSFW_CONTENT, NSFW_ACCOUNT } from "../../util/constant"
import { Props as SocialAvatarProps } from "../SocialAvatar"
import * as Misc from "../../util/miscHelper"
import AccountsEmptySplash from "./AccountsEmptySplash"
import ContentEmptySplash from "./ContentEmptySplash"
import ModalPostDetails from "../ModalPostDetails"
import ModalScoreBreakDown from "../ModalScoreBreakDown"

import "./style.sass"

type PostDetailsFromDataGridParams = GridCellParams<
  any, unknown, unknown, GridTreeNode
>

type PostDetailsData = {
  engagementRate: string,
  isReel: boolean,
  isStory: boolean,
  media: GraphQL.PostMedia[],
  permalink: string,
  postComments: number,
  postContent: string,
  postDate: number,
  postExits: number,
  postImpressions: number,
  postLikes: number,
  postPlays: number,
  postReach: number,
  postReachEstimated: number,
  postSaves: number,
  postShares: number
  postScreenshots: number,
  postHighlighted: boolean,
  postSaved: boolean,
  postSwipeUps: number,
  postTapsBackward: number,
  postTapsForward: number,
  postViews: number,
  socialAvatarProps: SocialAvatarProps,
}

type Props = {
  mountContext: SearchHelper.MountContext,
  contentContextMenu: SearchContentEllipsisMenuOptions
  isSelectable?: boolean,
}

export default function SearchResultsTable(
  {
    mountContext, isSelectable = true, contentContextMenu,
  }: Props,
): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchResultsTable" })

  const {
    t: translateCommon,
  } = useTranslation([], { keyPrefix: "common" })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { vanity } = useParams()

  const [ postDetailsModal, setPostDetailsModal ] = useState<
    PostDetailsData | null
  >(null)
  const [ scoreModalOpen, setScoreModalOpen ] = useState(false)

  const {
    searchInput,
    searchResultsAccounts,
    searchResultsContent,
    searchStatus,
    selectedAccounts: searchSelectedAccounts,
  } = useSelector(({ search }) => search)

  const { selectedAccounts: listSelectedAccounts } = useSelector(({ listAddAccount }) => listAddAccount)
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const fetchedList = useSelector(({ list }) => list.list)
  const { resultType } = searchInput

  // On unmount, clean up search state
  useEffect(() => () => {
    dispatch(SearchState.clearSearchState())
  }, [])

  useEffect(() => {
    if (scoreModalOpen === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ scoreModalOpen ])

  const handleScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string, isAdCouncilScore?: boolean) => {
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType, isAdCouncilScore))
    setScoreModalOpen(true)
  }

  const handleNSFWUpdate = async (
    updateID: string,
    updateFrom: string,
  ): Promise<void> => {
    let nsfwChangeStatus: NsfwStatus = null

    if (updateFrom === NSFW_CONTENT) {
      const updatedRows = await markPostNSFW(updateID, searchResultsContent)
      try {
        nsfwChangeStatus = await dispatch(SearchState.markPostNsfw(updateID, updatedRows))
        triggerNfswToast((t) => dispatch(pushToast(t)), translate, false, updateFrom, nsfwChangeStatus)
      } catch (error) {
        triggerNfswToast((t) => dispatch(pushToast(t)), translate, true, updateFrom, nsfwChangeStatus)
      }
    }

    if (updateFrom === NSFW_ACCOUNT) {
      const updatedRows = await markSocialAcctNSFW(updateID, searchResultsAccounts)
      try {
        nsfwChangeStatus = await dispatch(SearchState.markSocialAccountNsfw(updateID, updatedRows))
        triggerNfswToast((t) => dispatch(pushToast(t)), translate, false, updateFrom, nsfwChangeStatus)
      } catch (error) {
        triggerNfswToast((t) => dispatch(pushToast(t)), translate, true, updateFrom, nsfwChangeStatus)
      }
    }
  }

  const cellClickAction = (
    { field, row }: PostDetailsFromDataGridParams,
  ): void => {
    const socialAccountId = searchInput.resultType === GraphQL.GodSearchType.Social
      ? row.id
      : row.account.id

    // NAVIGATE TO ACCOUNT PROFILE
    // If cell is "account" on either table, navigate to profile page
    if (field === "account" && row.account.hasPersonality) {
      navigate(Misc.generateProfileUrl(socialAccountId, vanity || ""))
      return
    }

    // NAVIGATE TO SEARCH SOCIAL ACCOUNT SUBPAGE
    // If cell is "account" on either table, navigate to social account page
    if (field === "account") {
      navigate(Misc.generateSearchSocialAccountUrl(
        socialAccountId,
        vanity || "",
      ))

      return
    }

    // OPEN POST DETAILS MODAL
    // Do nothing if result type is not Content
    if (resultType === GraphQL.GodSearchType.Social) return

    const postDetailsModalColumns = [
      "followers",
      "postDate",
      "media",
      "postDetails",
      "engagementRate",
    ]

    if (!postDetailsModalColumns.includes(field)) return

    setPostDetailsModal({
      engagementRate: row.engagementRate,
      isReel: row.postDetails.isReel,
      isStory: row.postDetails.isStory,
      media: row.media.postMedia,
      permalink: row.media.permalink,
      postComments: row.postDetails.postComments,
      postContent: row.postDetails.postContent,
      postDate: row.postDetails.postDate,
      postExits: row.postDetails.postExits,
      postImpressions: row.postDetails.postImpressions,
      postLikes: row.postDetails.postLikes,
      postPlays: row.postDetails.postPlays,
      postReach: row.postDetails.postReach,
      postReachEstimated: row.postDetails.postReachEstimated,
      postSaves: row.postDetails.postSaves,
      postShares: row.postDetails.postShares,
      postTapsBackward: row.postDetails.postTapsBackward,
      postTapsForward: row.postDetails.postTapsForward,
      postViews: row.postDetails.postViews,
      postScreenshots: row.postDetails.postScreenshots,
      postHighlighted: row.postDetails.postHighlighted,
      postSaved: row.postDetails.postSaved,
      postSwipeUps: row.postDetails.postSwipeUps,
      socialAvatarProps: row.account,
    })
  }

  const tableColumns = useMemo(
    () => resultType === GraphQL.GodSearchType.Social
      ? AccountsTable.generateAccountResultsColumns(
        translate,
        scopes,
        handleScoreModal,
        handleNSFWUpdate,
      )
      : ContentTable.generateContentResultsColumns(
        translate,
        handleNSFWUpdate,
        contentContextMenu,
      ),
    [ searchResultsAccounts, searchResultsContent ],
  )

  const rowGenerationFn = useMemo(
    () => resultType === GraphQL.GodSearchType.Social
      ? (r: { [ k: string ]: any }) => AccountsTable
        .generateAccountResultsRow(r, translateCommon)
      : (r: { [ k: string ]: any }) => ContentTable
        .generateContentResultsRow(r, translateCommon),
    [ searchResultsAccounts, searchResultsContent ],
  )

  const rawRows = resultType === GraphQL.GodSearchType.Social
    ? searchResultsAccounts
    : searchResultsContent

  const tableRows = useMemo(
    () => rawRows
      .map(rowGenerationFn)
      // NOTE: Front-end filtering is an antipattern that has the
      // potential to break infinite scroll, not to mention is
      // a general security risk. This filtering needs to happen
      // on the back end. When it does, the filters below may be
      // removed.
      .filter((r): r is GridValidRowModel => r != null)
      .filter((r) => {
        if (scopes.includes(Scope.FEATURE_TIKTOK)) return true
        return r.account.network !== GraphQL.Network.Tiktok
      })
      .filter((r) => {
        if (scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT)) return true
        return r.account.network !== GraphQL.Network.Snapchat
      }),
    [ searchResultsAccounts, searchResultsContent, scopes ],
  )

  const tableClassName = tableRows.length === 0
    ? "cp_component_search-results-table "
    + "cp_component_search-results-table-empty"
    : "cp_component_search-results-table"

  const alreadyAssociatedAccounts = useMemo(() => {
    const accountIds: string[] = []

    if (mountContext === "campaign" && API.isSuccess(campaign)) {
      campaign.payload.campaign.campaignNetworkAccounts.forEach((c) => accountIds.push(c.socialAccount.id))
    }
    if (mountContext === "list" && API.isSuccess(fetchedList)) {
      fetchedList
        .payload
        .suggestionListById
        .suggestionListCategories
        .forEach((category) => {
          category.suggestionListSocialAccounts.forEach(({
            socialAccount,
          }) => accountIds.push(socialAccount.id))
        })
    }
    return accountIds
  }, [ fetchedList, campaign, mountContext ])

  const handleRowSelectability = (
    params: GridRowParams<any>,
  ): boolean => {
    const selectionContextIsNetworkSpecific = mountContext !== "campaign"

    const rowSelectionModel = mountContext === "list"
      ? listSelectedAccounts
      : searchSelectedAccounts

    let selectedNetwork

    if (GraphQL.GodSearchType.Social === searchInput.resultType) {
      selectedNetwork = searchResultsAccounts
        .find((result) => result.socialAccount.id === rowSelectionModel[0])
        ?.socialAccount.network
    }

    if (GraphQL.GodSearchType.Post === searchInput.resultType) {
      selectedNetwork = searchResultsContent
        .find((result) => result.id === rowSelectionModel[0])
        ?.socialAccount.network
    }

    const isMatchingSelectedNetwork = params.row.account.network === selectedNetwork

    /*
    * If the result type is a Post, the selected account is nested in the row object.
    */
    const selectedAccountId = searchInput.resultType === GraphQL.GodSearchType.Post ? params.row.account.id : params.row.id

    const accountIsAssociated = alreadyAssociatedAccounts.includes(selectedAccountId)

    // If no accounts are selected or if the context is not network specific,
    // the only thing to check is if it has already been added to the list or campaign.
    if (rowSelectionModel.length === 0 || !selectionContextIsNetworkSpecific) return !accountIsAssociated

    // If the context is network specific, we need to check if the account is of the same network
    // and if it has already been added to the list or campaign.
    return isMatchingSelectedNetwork && !accountIsAssociated
  }

  const handleSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
  ): void => {
    const sanitizedRowIds: string[] = rowSelectionModel
      .filter((r: GridRowId): r is string => typeof r === "string")

    if (mountContext === "search" || mountContext === "campaign") {
      dispatch(SearchState.setSelectedAccounts(sanitizedRowIds))
    }

    if (mountContext === "list") {
      dispatch(ListAddAccountState.setSelectedAccounts(sanitizedRowIds))
    }
  }

  const table = useMemo(
    () => (
      <div className={ tableClassName }>
        <DataGridPro
          checkboxSelection={ isSelectable }
          columns={ tableColumns }
          disableRowSelectionOnClick={ true }
          hideFooter={ true }
          isRowSelectable={ handleRowSelectability }
          loading={ searchStatus === "loading" }
          onCellClick={ cellClickAction }
          onRowSelectionModelChange={ handleSelectionModelChange }
          onRowsScrollEnd={ () => {
            if (searchInput.page === 1) return
            dispatch(fetchSearchResults(false))
          } }
          onSortModelChange={ (s) => {
            const newInput = SearchHelper.sortModelToSearchState(s, searchInput)
            dispatch(setSearchInput(newInput))
            dispatch(fetchSearchResults(true))
          } }
          pinnedColumns={ {
            left: [ "__check__", "account" ],
            right: [ "ellipsisMenu" ],
          } }
          rowHeight={ 80 }
          rows={ tableRows }
          rowSelectionModel={
            mountContext === "list"
              ? listSelectedAccounts
              : searchSelectedAccounts
          }
          slots={ {
            loadingOverlay: LinearProgress,
            noResultsOverlay: resultType === GraphQL.GodSearchType.Social
              ? AccountsEmptySplash
              : ContentEmptySplash,
            noRowsOverlay: resultType === GraphQL.GodSearchType.Social
              ? AccountsEmptySplash
              : ContentEmptySplash,
          } }
          sortModel={ SearchHelper.searchStateToSortModel(searchInput) }
          sortingMode="server"
        />
        { postDetailsModal && (
          <ModalPostDetails
            open={ postDetailsModal != null }
            closeAction={ () => setPostDetailsModal(null) }
            { ...postDetailsModal }
          />
        ) }
        <ModalScoreBreakDown isModalScoreOpen={ scoreModalOpen } closeModal={ () => setScoreModalOpen(false) } />
      </div>
    ),
    [ listSelectedAccounts,
      postDetailsModal,
      searchResultsAccounts,
      searchResultsContent,
      searchSelectedAccounts,
      searchStatus,
      scoreModalOpen,
    ],
  )

  if (searchStatus === "init") {
    return (
      <div className="cp_component_search-results-table">
        <aside className="cp_component_search-results-table-placeholder">
          <div>
            <ManageSearchRounded />
            <h3>{ translate("Initiate a search to see results!") }</h3>
          </div>
        </aside>
      </div>
    )
  }

  if (searchStatus === "loading") {
    return table
  }

  if (API.isError(searchStatus)) {
    return (
      <div className="cp_component_search-results-table">
        <aside className="cp_component_search-results-table-placeholder">
          <div>
            <WarningRounded />
            <h3>{ translate("Something went wrong...") }</h3>
          </div>
        </aside>
      </div>
    )
  }

  return table
}

import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import "./style.sass"
import { useTranslation } from "react-i18next"
import PublicListGroupOverview from "../../component/PublicListGroupOverview"
import LogoHeader from "../../component/LogoHeader"
import { useDispatch } from "../../state/hooks"
import { fetchPublicListGroup } from "../../state/publicListGroupSlice"

export default function PublicListGroup() {
  const dispatch = useDispatch()
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListGroup" })
  const { listGroupCode } = useParams()

  useEffect(() => {
    if (!listGroupCode) return
    dispatch(fetchPublicListGroup(listGroupCode))
  }, [ listGroupCode ])

  return (
    <div className="cp_public-list-group_page">
      <LogoHeader header={ translate("Influencer Suggestions") } />
      <div className="cp_public-list-group_page-container">
        <PublicListGroupOverview />
      </div>
    </div>
  )
}

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useNavigate, useParams } from "react-router-dom"

import * as API from "../../../../util/apiClient"
import Button from "../../../Button"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import ErrorHandler from "../../../ErrorHandler"
import {
  fetchCurrentAdCampaign,
  setLinkDeliverableModalOpen,
  setSelectedAdIds,
  setSelectedAdSetIds,
  setUpdateAudienceTargettingModalOpen,
  unlinkDeliverables,
} from "../../../../state/campaignSlice"
import CampaignAdSetsTable from "./Tables/CampaignAdSetsTable"
import CampaignAdsDeliverableTable from "./Tables/CampaignAdsDeliverableTable"
import ReturnToTag from "../../../ReturnToTag"

type Props = {
  adID: string
}

export default function CampaignAdSets({ adID }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CamapignAdSets" })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { campaignID } = useParams()
  const {
    currentAdCampaign, selectedAdSetIds, selectedAdIds,
  } = useSelector(({ campaignPage }) => campaignPage)

  useEffect(() => {
    dispatch(fetchCurrentAdCampaign({ id: adID }))
  }, [ adID ])

  const renderAdSetsAudienceTargettingTable = () => {
    if (currentAdCampaign === "init" || currentAdCampaign === "loading") {
      return <LoadingIndicatorCard />
    }

    if (API.isError(currentAdCampaign)) {
      return <ErrorHandler err={ currentAdCampaign } />
    }

    const {
      adSets,
    } = currentAdCampaign.payload.adCampaign
    return (
      <CampaignAdSetsTable
        rows={ adSets }
        selectedIds={ selectedAdSetIds }
        setSelectedIds={ (adIds) => dispatch(setSelectedAdSetIds(adIds)) }
      />
    )
  }

  const renderAdsDeliverableMappingTable = () => {
    if (currentAdCampaign === "init" || currentAdCampaign === "loading") {
      return <LoadingIndicatorCard />
    }

    if (API.isError(currentAdCampaign)) {
      return <ErrorHandler err={ currentAdCampaign } />
    }

    const {
      ads,
    } = currentAdCampaign.payload.adCampaign

    return (
      <CampaignAdsDeliverableTable
        rows={ ads }
        selectedIds={ selectedAdIds }
        setSelectedIds={ (adIds) => dispatch(setSelectedAdIds(adIds)) }
      />
    )
  }

  const handleUnlinkSubmit = () => {
    if (campaignID) {
      dispatch(unlinkDeliverables({
        variables: {
          adIds: selectedAdIds,
          campaignId: campaignID,
        },
        onSuccess: async () => {
          await dispatch(fetchCurrentAdCampaign({ id: adID }))
          dispatch(setSelectedAdIds([]))
        },
      }))
    }
  }

  return (
    <div className="cp_component-campaign-ads-container">
      <ReturnToTag onClick={ () => navigate("../", { relative: "path" }) } label={ translate("< BACK TO AD CAMPAIGNS") } />
      <div className="table-header-container campaign-ads">
        <h5 className="table-header">{ translate("Ad Sets Audience Targetting") }</h5>
        <div className="action-container">
          <Button
            className="button"
            label={ translate("Update Targetting") }
            disabled={ selectedAdSetIds.length === 0 }
            onClick={ () => dispatch(setUpdateAudienceTargettingModalOpen(true)) }
          />
        </div>
      </div>
      { (renderAdSetsAudienceTargettingTable()) }

      <div className="table-header-container all-ads">
        <h5 className="table-header">{ translate("Ads Deliverable Mapping") }</h5>
        <div className="action-container">
          <Button
            className="button"
            label={ translate("Unlink Deliverable") }
            isPrimary={ false }
            disabled={ selectedAdIds.length === 0 }
            onClick={ handleUnlinkSubmit }
          />
          <Button
            className="button"
            label={ translate("Link Deliverable") }
            variant="contained"
            disabled={ selectedAdIds.length === 0 }
            onClick={ () => dispatch(setLinkDeliverableModalOpen(true)) }
          />
        </div>
      </div>
      { (renderAdsDeliverableMappingTable()) }
    </div>
  )
}

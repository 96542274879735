import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link as LinkIcon } from "@mui/icons-material"
import Modal from "../Modal"
import Input from "../Input"
import "./style.sass"
import Button from "../Button"
import { useDispatch, useSelector } from "../../state/hooks"
import { closeCopyLinkModal } from "../../state/copyLinkModalSlice"

export default function ModalCopyLink() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCopyLink" })
  const dispatch = useDispatch()

  const {
    open,
    link,
    title,
    subtitle,
  } = useSelector(({ copyLinkModal }) => copyLinkModal)

  const [ isCopied, setIsCopied ] = useState(false)

  useEffect(() => {
    if (open) setIsCopied(false)
  }, [ open ])

  const handleClick = async () => {
    if (link) {
      await navigator.clipboard.writeText(link)
      setIsCopied(true)
    }
  }

  return (
    <Modal
      maxWidth="lg"
      className="cp_component-modal-copy-link"
      open={ open }
      title={ title }
      subtitle={ subtitle }
      hasFooter={ false }
      closeAction={ () => dispatch(closeCopyLinkModal()) }
    >
      <div className="cp_component-modal-copy-link-body">
        <Input value={ link } />
        <Button
          onClick={ handleClick }
          startIcon={ !isCopied ? <LinkIcon /> : null }
          label={ !isCopied ? translate("Copy Link") : translate("Link Copied!") }
          isPressed={ isCopied }
        />
      </div>
    </Modal>
  )
}

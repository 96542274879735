import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import { NavigateFunction } from "react-router-dom"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"
import { setLoginAttempt } from "../userSlice"

// Team Slice Interface and Initial State
export interface TeamState {
  team: Status<GraphQL.GetVanityQuery>,
  teamEmail: Status<GraphQL.TeamEmailMutation | null>,
}

const initialState: TeamState = {
  team: "init",
  teamEmail: "init",
}

// Team Slice
export const teamSlice = createSlice({
  name: "Team",
  initialState,
  reducers: {
    setTeam: (
      state,
      action: PayloadAction<Status<GraphQL.GetVanityQuery>>,
    ) => ({
      ...state,
      team: action.payload,
    }),
    setTeamEmail: (
      state,
      action: PayloadAction<Status<GraphQL.TeamEmailMutation | null>>,
    ) => ({
      ...state,
      teamEmail: action.payload,
    }),
  },
})

export const { setTeam, setTeamEmail } = teamSlice.actions
export default teamSlice.reducer

// Team Slice Thunks
export const fetchTeamVanity = (code: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setTeam("loading"))
  const teamResult = await API.fetchVanity(code)
  dispatch(setTeam(teamResult))
  dispatch(setLoginAttempt("init"))
}

export const fetchTeamVanityWithRedirect = (
  code: string,
  navigate: NavigateFunction,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setTeam("loading"))
  const teamResult = await API.fetchVanity(code)
  dispatch(setTeam(teamResult))
  dispatch(setLoginAttempt("init"))

  if (API.isError(teamResult)) {
    navigate("/find-team")
  }
}

export const sendTeamEmail = (email: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setTeamEmail("loading"))
  const response = await API.sendEmailFindTeam({ email })
  dispatch(setTeamEmail(response))
}

/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams, useSearchParams } from "react-router-dom"
import ClearIcon from "@mui/icons-material/Clear"
import { setSearchInput, fetchSearchResults } from "../../../state/searchSlice"
import { useSelector, useDispatch } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import * as SearchHelper from "../../../util/searchHelper"
import RelevantPostsTopControls from "./RelevantPostsTopControls"
import LoadingIndicator from "../../LoadingIndicator"
import { KEYWORDS_POSTS_QTY } from "../../../util/constant"
import ModalPostDetails from "../../ModalPostDetails"
import { fetchSocialProfile } from "../../../state/socialProfileSlice"

import "./relevant-posts-keywords.sass"
import RelevantPostsPreview from "./RelevantPostsPreview"
import { getSocialAvatarProps } from "../../../util/componentHelper"
import {
  getSuggestionListPostsByImageTag,
  setActiveTagId,
  setToggleFetchingImageTags,
} from "../../../state/listSocialProfileSlice"

interface Props {
  topPostsOverviewOn: boolean
}

type PostOrEmptyMessage = GraphQL.PostFragment | { emptyMssg: string, indexId: number }
type RemovePostArgs = {postId: string, keywordId: string} | null

function RelevantPostsByImageTag({ topPostsOverviewOn }:Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSocialSubTabs" })
  const { listID, socialProfileID } = useParams<{ listID: string, socialProfileID: string }>()
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.socialProfile.profile)
  const {
    ImageTagsWithPosts, imageTagPosts, toggleFetchingImageTags,
  } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const { searchInput } = useSelector(({ search }) => search)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ relevantImageTags, setRelevantImageTags ] = useState<GraphQL.SuggestionListImageTag[] | null>(null)
  const [ selectedPost, selectPost ] = useState<GraphQL.PostFragment | undefined>()
  const [ postInRemovalProcess, setPostInRemovalProcess ] = useState<RemovePostArgs>(null)
  const activeKeywordId = useRef("")

  if (!listID || !socialProfileID) return null

  const getRelatedPostsByImagTag = async (suggestionListImageTagId: string) => {
    await dispatch(getSuggestionListPostsByImageTag({
      listId: listID,
      socialAccountId: socialProfileID,
      suggestionListImageTagId,
    }))
  }

  const handleDeleteAllPosts = async (suggestionListImageTagId: string) => {
    dispatch(setToggleFetchingImageTags(true))
    const response = await API.removeListContentImageTagPosts({
      suggestionListId: listID,
      socialAccountId: socialProfileID,
      suggestionListImageTagId,
    })
    if (API.isSuccess(response)) {
      getRelatedPostsByImagTag(suggestionListImageTagId)
    }
  }

  const handleChangeOfImageTag = (keywordId: string) => {
    activeKeywordId.current = keywordId
    dispatch(setActiveTagId(keywordId))
    getRelatedPostsByImagTag(keywordId)
  }

  const handleClickRemovePost = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    postId: string,
    keywordId: string,
  ) => {
    e.stopPropagation()
    setPostInRemovalProcess({ postId, keywordId })
  }

  const removeTopPost = async (
    postId: string,
    keywordId: string,
  ) => {
    if (socialProfileID && listID && keywordId) {
      const input: GraphQL.SuggestionListSocialAccountCippusInput = {
        kind: GraphQL.SuggestionListSocialAccountCippusType.ImageTag,
        postId,
        keywordId,
        socialAccountId: socialProfileID,
        suggestionListId: listID,
      }

      const removedPostId = await API.removeListTopPost({ input })
      if (API.isSuccess(removedPostId)) {
        getRelatedPostsByImagTag(keywordId) // Added to refresh posts after deletion
      }
    }
  }

  const activeTagName = useMemo(() => {
    if (relevantImageTags && relevantImageTags.length) {
      const active = relevantImageTags.find((key) => key.id === activeKeywordId.current)
      if (active) dispatch(setActiveTagId(active.id))
      return active ? active.name : null
    }
    return null
  }, [ relevantImageTags, activeKeywordId.current ])

  function addActiveImageTagToSearch(): void {
    if (activeTagName == null || activeTagName === "") return
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    newInput.contentParams.keywords = []
    newInput.contentParams.optionalImageTags = [ { tags: [ activeTagName ], inclusive: false } ]
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    dispatch(fetchSearchResults(true))
  }

  useEffect(() => {
    if (topPostsOverviewOn && ImageTagsWithPosts.length) {
      const keywordId = ImageTagsWithPosts[0].id
      handleChangeOfImageTag(keywordId)
    }
  }, [ topPostsOverviewOn ])

  useEffect(() => {
    async function fetchImageTags() {
      if (listID && socialProfileID) {
        dispatch(setToggleFetchingImageTags(true))
        const fetchedKeywords = await API.getSuggestionListImageTags({ suggestionListId: listID })
        if (API.isSuccess(fetchedKeywords)) {
          setRelevantImageTags(fetchedKeywords.payload.suggestionListById.imageTags)
          dispatch(setToggleFetchingImageTags(false))
        }
        if (API.isError(fetchedKeywords)) {
          // eslint-disable-next-line no-console
          console.warn("Error fetching keywords: ", fetchedKeywords)
          dispatch(setToggleFetchingImageTags(false))
        }
      }
    }
    fetchImageTags()
  }, [ listID, socialProfileID ])

  useEffect(() => {
    if (Array.isArray(relevantImageTags) && relevantImageTags.length) {
      const keywordId = relevantImageTags[0].id
      activeKeywordId.current = keywordId
      getRelatedPostsByImagTag(keywordId)
    }
  }, [ relevantImageTags ])

  useEffect(() => {
    // after the post has been removed and the
    // updated posts data state is set remove loading component
    if (postInRemovalProcess?.keywordId.length) {
      setPostInRemovalProcess(null)
    }
  }, [ imageTagPosts ])

  useEffect(() => {
    if (postInRemovalProcess?.keywordId.length) {
      removeTopPost(
        postInRemovalProcess.postId,
        postInRemovalProcess.keywordId,
      )
    }
  }, [ postInRemovalProcess ])

  useEffect(() => {
    addActiveImageTagToSearch()
  }, [ activeTagName ])

  useEffect(() => {
    if (socialProfileID) {
      dispatch(fetchSocialProfile(socialProfileID))
    }

    return () => {
      // revert content params back to default
      (async () => {
        const newInput = SearchHelper.cloneSearchInput(searchInput)
        newInput.contentParams = SearchHelper.initialContentInput()
        await dispatch(setSearchInput(newInput))
      })()
    }
  }, [])

  const allPosts = useMemo(() => Array.from({ length: KEYWORDS_POSTS_QTY }, (_, index): PostOrEmptyMessage => {
    const postInfo = imageTagPosts[index] ? imageTagPosts[index] : null
    return postInfo ?? { emptyMssg: "No Post", indexId: index }
  }), [ imageTagPosts ])

  if (relevantImageTags === null || !API.isSuccess(profile) || toggleFetchingImageTags) {
    return (
      <div className="cp_relevant_post-keywords-fetching">
        <LoadingIndicator size={ 40 } />
      </div>
    )
  }

  if (Array.isArray(relevantImageTags) && !relevantImageTags.length) {
    return (
      <div className="cp_component_empty-state">
        <article>
          <div className="cp_component_empty-state-mag" />
        </article>
        <aside>
          <h2>{ translate("No Posts Available!") }</h2>
          <p>{ translate("Relevant posts image tags require image tags and it appears that this list does not have any image tags.") }</p>
        </aside>
      </div>
    )
  }

  if (topPostsOverviewOn) {
    return (
      <RelevantPostsPreview
        classes={ [ "keyword-preview" ] }
        tags={ ImageTagsWithPosts }
        activeTag={ activeKeywordId.current }
        posts={ imageTagPosts }
        dropdownSelectionAction={ handleChangeOfImageTag }
      />
    )
  }

  return (
    <div className="cp_relevant_posts-keywords">
      <RelevantPostsTopControls
        dropdownSelections={ relevantImageTags }
        deleteAllAction={ handleDeleteAllPosts }
        dropdownSelectionAction={ handleChangeOfImageTag }
        activeKeyword={ activeKeywordId.current || "" }
      />
      <div className="cp_relevant_posts-keywords-wrapper">
        <ul className="cp_relevant_posts-keywords-list">
          { allPosts.map((post) => ("id" in post ? (
            <li
              key={ post.id }
              className={ `cp_relevant_posts-keywords-item ${ postInRemovalProcess?.postId ? "removal-in-process" : "" }` }
              onClick={ () => selectPost(post) }
              tabIndex={ 0 }
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              onKeyDown={ (e) => (e.key === "Enter" || e.key === "Space") && selectPost(post) }
              aria-label="Select post"
            >
              {
              (post.id !== postInRemovalProcess?.postId) ? (
                <>
                  <button
                    type="button"
                    aria-label="remove"
                    className="cp_relevant_posts-keywords-item-remove"
                    onClick={ (e) => handleClickRemovePost(e, post.id, activeKeywordId.current) }
                  >
                    <ClearIcon fontSize="small" className="cp_relevant_posts-keywords-item-remove-icon" />
                  </button>
                  <img src={ `${ post.postMedia[0].thumbnailUrl }?auto=format` } alt="Post thumbnail" loading="lazy" />
                </>
              ) : (
                <LoadingIndicator size={ 30 } />
              )
            }
            </li>
          ) : (
            <li key={ post.indexId } className="cp_relevant_posts-keywords-empty">
              <span>{ post.emptyMssg }</span>
            </li>
          ))) }
        </ul>
        { API.isSuccess(profile) && (
          <ModalPostDetails
            closeAction={ () => selectPost(undefined) }
            engagementRate={ `${ selectedPost?.engagementRate }` || "" }
            isReel={ Boolean(selectedPost?.isReel) }
            open={ Boolean(selectedPost) }
            isStory={ Boolean(selectedPost?.isStory) }
            media={ selectedPost?.postMedia || [] }
            permalink={ selectedPost?.permalink || "" }
            postComments={ selectedPost?.postComments || 0 }
            postContent={ selectedPost?.postContent || "" }
            postDate={ selectedPost?.created || 0 }
            postExits={ selectedPost?.postExits || 0 }
            postImpressions={ selectedPost?.postImpressions || 0 }
            postLikes={ selectedPost?.postLikes || 0 }
            postPlays={ selectedPost?.postPlays || 0 }
            postReach={ selectedPost?.postReach || 0 }
            postReachEstimated={ selectedPost?.estimatedPostReach?.value || 0 }
            postSaves={ selectedPost?.postSaves || 0 }
            postShares={ selectedPost?.postShares || 0 }
            postScreenshots={ selectedPost?.screenshots || 0 }
            postSwipeUps={ selectedPost?.swipeUps || 0 }
            postHighlighted={ Boolean(selectedPost?.highlighted) }
            postSaved={ Boolean(selectedPost?.saved) }
            postTapsBackward={ selectedPost?.postTapsBackward || 0 }
            postTapsForward={ selectedPost?.postTapsForward || 0 }
            postViews={ selectedPost?.postViews || 0 }
            socialAvatarProps={ getSocialAvatarProps(profile.payload.socialAccount as GraphQL.SocialAccount) }
          />
        ) }
      </div>
    </div>
  )
}

export default RelevantPostsByImageTag

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import "./style.sass"
import { useSelector, useDispatch } from "../../state/hooks"

import Input from "../Input"
import { closeRenameCategoryModal, renameCategory } from "../../state/listSlice"
import LoadingIndicator from "../LoadingIndicator"

function ModalRenameCategory(): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    renameCategory: category,
  } = useSelector(({ list }) => list)

  const [ name, setName ] = React.useState("")
  const [ loading, setLoading ] = React.useState(false)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalRenameCategory" })

  const closeModal = () => dispatch(closeRenameCategoryModal())

  const renameCategoryHandler = async () => {
    if (!category) return

    setLoading(true)

    await dispatch(renameCategory({
      suggestionListCategoryRenameId: category.id,
      name,
    }))

    setLoading(false)
    closeModal()
  }

  useEffect(() => setName(category?.name || ""), [ category ])

  return (
    <Modal
      title={ translate("Rename Category") }
      subtitle={ translate("Enter the category name below.") }
      primaryLabel={ loading ? <LoadingIndicator /> : translate("Rename") }
      secondaryLabel={ translate("Cancel") }
      disabled={ name === "" || loading }
      open={ Boolean(category) }
      primaryAction={ renameCategoryHandler }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      maxWidth="lg"
    >
      <div className="cp_rename-category_component">
        <Input
          label={ translate("Category Name") }
          placeholder={ translate("Rename Category") }
          value={ name }
          onChange={ ({ currentTarget }) => setName(currentTarget.value) }
        />
      </div>
    </Modal>
  )
}

export default ModalRenameCategory

import React from "react"
import LinkIcon from "@mui/icons-material/Link"
import "./user-list-card.sass"
import {
  Add,
  Check,
  Visibility,
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { Link } from "@mui/material"
import IconButton from "../../../../IconButton"
import EntityInfoRow from "../../../../EntityInfoRow"
import { CampaignPermissionType } from "../../../../../graphql"
import Tooltip from "../../../../Tooltip"

export type UserCardListRow = {
  id: string,
  name: string,
  link?: string,
  logoUrl?: string,
  company?: string,
  permissions?: CampaignPermissionType[]
}

type Props = {
  title: string
  rows: Array<UserCardListRow>
  emptyText: string
  onButtonClick?: () => void
  buttonEnabled?: boolean
}

export default function UserListCard({
  title, rows, emptyText, onButtonClick = () => {}, buttonEnabled = true,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignOverviewDetails" })

  return (
    <div className="cp_component-campaign-overview-details-user-list-card">
      <div className="header">
        <h4>{ title }</h4>
        { buttonEnabled && (
        <IconButton onClick={ onButtonClick } size="small" variant="filled">
          <Add />
        </IconButton>
        ) }
      </div>
      <div className="users">
        {
          rows.length > 0 ? rows.map((row) => {
            if (row.link) {
              return (
                <div className="link-row">
                  <LinkIcon />
                  <Link
                    href={ row.link }
                    target="_blank"
                    rel="noopener"
                    underline="hover"
                  >
                    { row.name }
                  </Link>
                </div>
              )
            }
            return (
              <div className="user-row">
                <EntityInfoRow
                  key={ row.id }
                  name={ row.name }
                  subInfo={ row.company }
                  avatarSrc={ row.logoUrl }
                />
                { (row.permissions && row.permissions.length > 0) && (
                <div className="user-icon-containers">
                  { row.permissions.includes(CampaignPermissionType.View) && (
                  <Tooltip title={ translate("View") }>
                    <Visibility />
                  </Tooltip>
                  ) }
                  { row.permissions.includes(CampaignPermissionType.Approve) && (
                  <Tooltip title={ translate("Approve") }>
                    <Check />
                  </Tooltip>
                  ) }
                </div>
                ) }
              </div>
            )
          }) : (
            <div className="empty-text-container">
              <p className="empty-text">
                { emptyText }
              </p>
            </div>
          )
        }
      </div>
    </div>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

// Verticals Slice Interface and Initial State
export interface VerticalsState {
  verticals: Status<GraphQL.VerticalFragment>,
}

const initialState: VerticalsState = {
  verticals: "init",
}

// Verticals Slice
export const verticalsSlice = createSlice({
  name: "Verticals",
  initialState,
  reducers: {
    setVerticals: (
      state,
      action: PayloadAction<Status<GraphQL.VerticalFragment>>,
    ) => ({
      ...state,
      verticals: action.payload,
    }),
  },
})

export const { setVerticals } = verticalsSlice.actions
export default verticalsSlice.reducer

// Verticals Slice Thunks

import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import "./style.sass"

import { useDispatch, useSelector } from "../../../state/hooks"
import LoadingIndicator from "../../LoadingIndicator"
import CampaignWidget from "./CampaignWidget"
import InfiniteScroll from "../../InfiniteScroll"
import { isSuccess } from "../../../util/apiClient"
import { getMoreCampaigns, getCampaigns } from "../../../state/dashboardSlice/campaigns"
import { SearchCampaignFilter } from "../../../graphql"
import { setCampaignSearchFilter } from "../../../state/dashboardSlice"
import Select from "../../Select"
import MenuItem from "../../MenuItem"

export default function DashboardCampaigns() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.DashboardCampaigns" })
  const [ page, setPage ] = React.useState<number>(1)
  const dispatch = useDispatch()
  const {
    campaignsStatus,
    campaignsContent,
    campaignSearchFilter,
  } = useSelector(({ dashboard }) => dashboard)
  const user = useSelector(({ user: userSlice }) => userSlice.user)

  const filterOptions = useMemo(() => [
    {
      label: translate("My Campaigns"), value: SearchCampaignFilter.MyCampaigns,
    },
    {
      label: translate("All Campaigns"), value: SearchCampaignFilter.Standard,
    },
  ].map(({ label, value }) => (
    <MenuItem
      id={ `cp_dashboard-campaigns_component-header-filter-option-${ value }` }
      key={ label }
      onClick={ async () => {
        dispatch(setCampaignSearchFilter(value))
        dispatch(getCampaigns("", value, 1))
      } }
      value={ value }
      disabled={ campaignSearchFilter === value }
      className="cp_dashboard-campaigns_component-header-filter-option"
    >
      { translate(label) }
    </MenuItem>
  )), [ campaignSearchFilter, translate ])

  useEffect(() => {
    dispatch(getCampaigns("", campaignSearchFilter, page))
  }, [])

  if (campaignsStatus === "init" || campaignsStatus === "loading") {
    return (
      <div className="cp_dashboard-campaigns_component">
        <div className="cp_dashboard-campaigns_component-header">
          <h5 id="cp_dashboard-campaigns_component-header-title" className="cp_dashboard-campaigns_component-header-title">
            { campaignSearchFilter === SearchCampaignFilter.Standard ? translate("All Campaigns") : translate("My Campaigns") }
          </h5>
          <Select
            id="select-dashboard-campaigns"
            label=""
            labelId="select-dashboard-campaigns"
            menuItems={ filterOptions }
            value={ campaignSearchFilter }
            className="cp_dashboard-campaigns_component-header-filter"
          />
        </div>
        <LoadingIndicator id="cp_dashboard-campaigns_component-loading" size={ 50 } />
      </div>
    )
  }

  if (campaignsStatus.status === "error") {
    return (
      <div className="cp_dashboard-campaigns_component">
        <p>
          { translate("Unable to load campaigns.") }
        </p>
      </div>
    )
  }

  if (isSuccess(campaignsStatus) && isSuccess(user)) {
    return (
      <div id="cp_dashboard-campaigns_component" className="cp_dashboard-campaigns_component">
        <div className="cp_dashboard-campaigns_component-header">
          <h5 className="cp_dashboard-campaigns_component-header-title">
            { campaignSearchFilter === SearchCampaignFilter.Standard ? translate("All Campaigns") : translate("My Campaigns") }
          </h5>
          <Select
            id="select-dashboard-campaigns"
            label=""
            labelId="select-dashboard-campaigns"
            menuItems={ filterOptions }
            value={ campaignSearchFilter }
            className="cp_dashboard-campaigns_component-header-filter"
          />
        </div>
        <InfiniteScroll
          dataLength={ campaignsContent.length }
          next={ () => {
            dispatch(getMoreCampaigns("", campaignSearchFilter, page + 1))
            setPage((prev) => prev + 1)
          } }
          hasMore={ campaignsStatus.payload.searchCampaign.totalCount > campaignsContent.length }
        >
          <div className="cp_dashboard-campaigns_component-widgets">
            {
            campaignsContent
              .map((campaign) => (
                <div
                  key={ campaign.id }
                  className="cp_dashboard-campaigns_component-widgets-widget"
                >
                  <CampaignWidget campaign={ campaign } />
                </div>
              ))
        }
          </div>
        </InfiniteScroll>
      </div>
    )
  }

  return (
    <div className="cp_list-management_component">
      {
        campaignsContent
          .map((campaign) => (
            <div key={ `category-table-${ campaign.id }` }>
              <CampaignWidget campaign={ campaign } />
            </div>
          ))
      }
    </div>
  )
}

import React, { useMemo } from "react"

import "./style.sass"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"
import Divider from "../Divider"
import Pill from "../Pill"
import NetworkIcon from "../NetworkIcon"
import { generateDemographicRangeString, shorthandNumber } from "../../util/miscHelper"
import * as GraphQL from "../../graphql"

type Props = {
    listName: string
    description?: string | null
    minAge?: number | null
    maxAge?: number | null
    sexes: Array<string>
    numberOfAccounts: number
    reach: number
    platform: GraphQL.Network
    className?: string
    publicUrl: string
}

export default function ListInfoCard({
  listName,
  minAge,
  maxAge,
  sexes,
  description,
  numberOfAccounts,
  platform,
  reach,
  className,
  publicUrl,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListInfoCard" })
  const demographicString = useMemo(() => (generateDemographicRangeString(sexes, minAge, maxAge)), [ sexes, minAge, maxAge ])
  const classes = [ "cp_component_public-list-info-card" ]
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")

  return (
    <Container
      className={ newClasses }
      onClick={ () => window.open(publicUrl, "_self") }
    >
      <div className="cp_component_public-list-info-card-main">
        <h2 className="cp_component_public-list-info-card-main-name">{ listName }</h2>
        <Divider className="cp_component_public-list-info-card-main-divider" />
        <p className="cp_component_public-list-info-card-main-description">{ description }</p>
        <p className="cp_component_public-list-info-card-main-demographic">{ translate("Demographic") }</p>
        <div className="cp_component_public-list-info-card-main-pill">
          { demographicString && <Pill label={ demographicString } /> }
        </div>
      </div>

      <Divider className="cp_component_public-list-info-card-divider" />
      <div className="cp_component_public-list-info-card-stats">
        <div className="cp_component_public-list-info-card-stats-info">
          <NetworkIcon
            additionalClasses="cp_component_public-list-info-card-stats-info-network"
            isActive={ true }
            network={ platform }
          />
          <p className="cp_component_public-list-info-card-stats-info-title">{ translate("Platform") }</p>
        </div>
        <div className="cp_component_public-list-info-card-stats-info">
          <h4>{ numberOfAccounts }</h4>
          <p className="cp_component_public-list-info-card-stats-info-title">{ translate("Accounts") }</p>
        </div>
        <div className="cp_component_public-list-info-card-stats-info">
          <h4>{ shorthandNumber(reach) }</h4>
          <p className="cp_component_public-list-info-card-stats-info-title">{ translate("Followers") }</p>
        </div>
      </div>
    </Container>
  )
}

import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material"
import EditNoteIcon from "@mui/icons-material/EditNote"
import CheckIcon from "@mui/icons-material/Check"

import "./move-category-menu.sass"
import { useDispatch } from "../../state/hooks"
import { reorderSocialAccount, openRenameCategoryModal } from "../../state/listSlice"
import LoadingIndicator from "../LoadingIndicator"
import { ListCategory } from "../../util/types"

interface MoveCategoryMenuItemProps {
  category: ListCategory
  currentCategory: ListCategory
  socialAccountId: string
}

export default function MoveCategoryMenuItem({
  category, currentCategory, socialAccountId,
}: MoveCategoryMenuItemProps) {
  const dispatch = useDispatch()

  const [ loading, setLoading ] = React.useState(false)

  const moveToCategory = async () => {
    setLoading(true)
    await dispatch(reorderSocialAccount({
      categoryId: category.id,
      suggestionListSocialAccountId: socialAccountId,
      index: 0,
    }))
    setLoading(false)
  }

  const editCategoryName = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    clickedCategory: MoveCategoryMenuItemProps["category"],
  ) => {
    e.stopPropagation()
    dispatch(openRenameCategoryModal(clickedCategory))
  }

  if (loading) {
    return (
      <MenuItem
        className="cp_move-categories-menu-item_component-category_loading"
        disabled={ true }
      >
        <LoadingIndicator size={ 20 } />
      </MenuItem>
    )
  }

  return (
    <div>
      <MenuItem
        className="cp_move-categories-menu-item_component-category"
        onClick={ moveToCategory }
      >
        {
        currentCategory.id === category.id ? (
          <>
            <ListItemIcon className="cp_move-categories-menu-item_component-category_checked">
              <CheckIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="cp_move-categories-menu-item_component-category-name_bold">{ category.name }</ListItemText>
          </>
        )
          : (
            <>
              <ListItemIcon className="cp_move-categories-menu-item_component-category_hidden" />
              <ListItemText className="cp_move-categories-menu-item_component-category-name">{ category.name }</ListItemText>
            </>
          )
        }
        { category.name !== "Uncategorized" && (
          <IconButton onClick={ (e) => editCategoryName(e, category) }>
            <EditNoteIcon fontSize="small" />
          </IconButton>
        ) }
      </MenuItem>
    </div>
  )
}

export function MoveCategoryMenuTitle() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.MoveCategoryMenuTitle" })
  return (
    <ListItemText className="cp_move-categories-menu-item_component-title">
      { translate("Move To Category") }
    </ListItemText>
  )
}

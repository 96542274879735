import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import FolderIcon from "@mui/icons-material/Folder"
import { Check, FlagCircle } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import EmptySplash from "../../EmptySplash"
import InfiniteScroll from "../../InfiniteScroll"
import LoadingIndicator from "../../LoadingIndicator"
import NetworkIcon from "../../NetworkIcon"
import SearchBar from "../../SearchBar"
import SlidingPanel from "../../SlidingPanel"
import { FilterMenuOption } from "../../FilterMenu"
import { RootState } from "../../../state/store"
import { Scope } from "../../../util/types"
import { ToggleMenuValue } from "../../Toggle"
import {
  getLists,
  getListsByTag,
  getMoreLists,
  getMoreListsByTag,
  handleListNetworkFilterChange,
  handleListTypeFilterChange,
} from "../../../state/slidingPanelSlice/lists"
import {
  PanelState,
  setListSearchInput,
  setListSearchToggle,
  setPanelOpen,
} from "../../../state/slidingPanelSlice"
import { useDispatch, useSelector } from "../../../state/hooks"

import "./listsSlidingPanel.sass"

type Props = {
    open: boolean
    onClose: () => void
}

function ListsSlidingPanel({
  open,
  onClose,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })

  const {
    t: translateCommon,
  } = useTranslation([], { keyPrefix: "common" })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    listsStatus, listsByTagStatus, listsContent, listNetworkFilters, listSearchInput, listTypeFilter, listSearchToggle,
  } = useSelector((root: RootState) => root.slidingPanels)

  const { user, scopes } = useSelector(({ user: userSlice }) => userSlice)

  const [ page, setPage ] = React.useState<number>(1)

  useEffect(() => {
    if (open) {
      if (listSearchToggle === "list") {
        dispatch(getLists(listSearchInput, listNetworkFilters, listTypeFilter, 1, scopes))
      } else {
        dispatch(getListsByTag(listSearchInput, listNetworkFilters, listTypeFilter, 1, scopes))
      }
    }
    setPage(1)
  }, [ listSearchInput, listNetworkFilters, listTypeFilter, listSearchToggle, open ])

  const generateNetworkFilters = () => {
    const networkFilters = []

    networkFilters.push({
      label: translate("Facebook"), value: GraphQL.Network.Facebook, keyId: uuidv4(),
    })

    networkFilters.push({
      label: translate("Instagram"), value: GraphQL.Network.Instagram, keyId: uuidv4(),
    })

    if (scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT)) {
      networkFilters.push({
        label: translate("Snapchat"), value: GraphQL.Network.Snapchat, keyId: uuidv4(),
      })
    }

    if (scopes.includes(Scope.FEATURE_TIKTOK)) {
      networkFilters.push({
        label: translate("TikTok"), value: GraphQL.Network.Tiktok, keyId: uuidv4(),
      })
    }

    networkFilters.push({
      label: translate("YouTube"), value: GraphQL.Network.Youtube, keyId: uuidv4(),
    })

    return networkFilters
  }

  const filterOptions: FilterMenuOption[] = useMemo(
    () => [ {
      label: translate("Network"),
      filterCategory: "network",
      values: generateNetworkFilters(),
    }, {
      label: translate("Filter By"),
      filterCategory: "filter-by",
      values: [
        {
          label: translate("My Lists"),
          value: GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.MyLists,
          keyId: uuidv4(),
        },
        {
          label: translate("Groups"),
          value: GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Groups,
          keyId: uuidv4(),
        },
        {
          label: translate("Archived"),
          value: GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Archived,
          keyId: uuidv4(),
        },
        {
          label: translate("Standard"),
          value: GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Standard,
          keyId: uuidv4(),
        },
        {
          label: translate("Campaign"),
          value: GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Campaign,
          keyId: uuidv4(),
        },
        // NOTE: Removing until Vetting is implemented
        // {
        //   label: translate("Vetting Enabled"),
        //   value: GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Vetting,
        //   keyId: uuidv4(),
        // }
      ],
    } ],
    [ translate ],
  )

  const toggleOptions: ToggleMenuValue[] = useMemo(() => [ {
    label: translate("List"),
    keyId: uuidv4(),
    value: "list",
  },
  {
    label: translate("Tag"),
    keyId: uuidv4(),
    value: "tag",
  } ], [ translate ])

  const renderContent = () => {
    if ((listSearchToggle === "list" && (listsStatus === "init" || listsStatus === "loading"))
      || ((listSearchToggle === "tag" && (listsByTagStatus === "init" || listsByTagStatus === "loading")))) {
      return (
        <div className="cp_component_sliding-panels_loading-indicator-container">
          <LoadingIndicator />
        </div>
      )
    }

    if (
      (listSearchToggle === "list" && API.isError<GraphQL.SearchListsAndListGroupsQuery>(listsStatus))
    || (listSearchToggle === "tag" && API.isError<GraphQL.SearchListsAndListGroupsByVerticalQuery>(listsByTagStatus))
    ) {
      return (
        <EmptySplash
          bodyText={ translateCommon("An unexpected error occurred!") }
        />
      )
    }

    if (
      (API.isSuccess<GraphQL.SearchListsAndListGroupsQuery>(listsStatus)
      || API.isSuccess<GraphQL.SearchListsAndListGroupsByVerticalQuery>(listsByTagStatus))
      && API.isSuccess(user)
    ) {
      const vanity = user.payload.currentUser?.customer.vanity
      let next
      let hasMore = false
      if (listSearchToggle === "list") {
        next = () => {
          dispatch(getMoreLists(listSearchInput, listNetworkFilters, listTypeFilter, page + 1, scopes))
          setPage((prev) => prev + 1)
        }
        // Should always be true
        if (API.isSuccess(listsStatus)) {
          hasMore = listsStatus.payload.searchSuggestionListAndSuggestionListGroup.totalCount > listsContent.length
        }
      } else {
        next = () => {
          dispatch(getMoreListsByTag(listSearchInput, listNetworkFilters, listTypeFilter, page + 1, scopes))
          setPage((prev) => prev + 1)
        }
        if (API.isSuccess(listsByTagStatus)) {
          hasMore = listsByTagStatus.payload.searchSuggestionListAndSuggestionListGroupBySuggesitonListVerticalName.totalCount
            > listsContent.length
        }
      }

      if (listsContent.length === 0) {
        return (
          <EmptySplash bodyText={ translate("No lists or groups found!") } />
        )
      }

      return (
        <div className="cp_component_navigation-bar-lists-container">
          <InfiniteScroll
            dataLength={ listsContent.length }
            next={ next }
            hasMore={ hasMore }
          >
            { listsContent.map((row) => (
              <div key={ `list-search-${ row.id }` } className="cp_component_navigation-bar-list-container">
                {
                // eslint-disable-next-line no-underscore-dangle
              row.__typename === "SuggestionList"
                ? (
                  <button
                    onClick={ () => {
                      navigate(`/${ vanity }/lists/${ row.id }`)
                      dispatch(setPanelOpen(PanelState.CLOSED))
                    } }
                    type="button"
                  >
                    <NetworkIcon
                      additionalClasses="cp_component_navigation-bar-list-icon"
                      network={ row.network }
                      isActive={ true }
                    />
                    <Typography className="cp_component_navigation-bar-list-name">{ row.name }</Typography>
                    { row.suggestionListMode === GraphQL.SuggestionListMode.Campaign
                      && (
                        <FlagCircle
                          className="cp_component_navigation-bar-list-icon cp_component_navigation-bar-list-flag-icon"
                        />
                      )
                    }
                  </button>
                )
                : (
                  <button
                    onClick={ () => {
                      navigate(`/${ vanity }/list-groups/${ row.id }`)
                      dispatch(setPanelOpen(PanelState.CLOSED))
                    } }
                    type="button"
                  >
                    <FolderIcon className="cp_component_navigation-bar-list-icon cp_component_navigation-bar-list-folder-icon" />
                    <Typography className="cp_component_navigation-bar-list-name">{ row.name }</Typography>
                  </button>
                )
              }
              </div>
            )) }
          </InfiniteScroll>
        </div>
      )
    }

    return (
      <EmptySplash
        bodyText={ translateCommon("An unexpected error occurred!") }
      />
    )
  }

  return (
    <SlidingPanel
      title={ translate("Lists") }
      open={ open }
      onClose={ onClose }
      toggleOptions={ toggleOptions }
      toggleValue={ listSearchToggle }
      setToggleValue={ (value) => { dispatch(setListSearchToggle(value)) } }
      disablePortal={ true }
    >
      <SearchBar
        onChange={ (e) => dispatch(setListSearchInput(e.target.value)) }
        filterOptions={ filterOptions }
        lastSubmittedSearch={ listSearchInput }
        onChangeDelay={ 600 }
        filterMenuComponent={ (
          <Box>
            <MenuList className="cp_component_filter-menu-list" dense={ true }>
              <ListItemText className="cp_component_filter-menu-title">{ filterOptions[0].label }</ListItemText>
              { filterOptions[0].values.map((v) => {
                const selected = listNetworkFilters.some((f) => f.value === v.value)
                let menuItemClassName = "cp_component_filter-menu-item"
                if (selected) menuItemClassName += " cp_component_menu-item_selected"
                return (
                  <MenuItem
                    className={ menuItemClassName }
                    onClick={ () => dispatch(handleListNetworkFilterChange(v)) }
                    key={ `filter-menu-option-${ v.value }-${ v.keyId }` }
                  >
                    { selected && <ListItemIcon className="cp_component_checked-icon"><Check /></ListItemIcon> }
                    <ListItemText
                      inset={ !selected }
                    >
                      { v.label }
                    </ListItemText>
                  </MenuItem>
                )
              }) }

              <ListItemText className="cp_component_filter-menu-title">{ filterOptions[1].label }</ListItemText>
              { filterOptions[1].values.map((v) => {
                const selected = v.value === listTypeFilter.value
                let menuItemClassName = "cp_component_filter-menu-item"
                if (selected) menuItemClassName += " cp_component_menu-item_selected"
                return (
                  <MenuItem
                    className={ menuItemClassName }
                    onClick={ () => dispatch(handleListTypeFilterChange(v)) }
                    key={ `filter-menu-option-${ v.value }-${ v.keyId }` }
                  >
                    { selected && <ListItemIcon className="cp_component_checked-icon"><Check /></ListItemIcon> }
                    <ListItemText
                      inset={ !selected }
                    >
                      { v.label }
                    </ListItemText>
                  </MenuItem>
                )
              }) }
            </MenuList>
          </Box>
      ) }
      />
      { renderContent() }
    </SlidingPanel>
  )
}

export default ListsSlidingPanel

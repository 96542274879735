import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import { setSocialTrackers } from "."

// Social Tracker Interface and Initial State
export interface SocialTrackersState {
  socialTrackers: Status<GraphQL.SearchContentAlertsQuery>,
  socialTrackerSearchInput: string
}

export const initialState: SocialTrackersState = {
  socialTrackers: "init",
  socialTrackerSearchInput: "",
}

// Reducer actions
export const reducers = {
  setSocialTrackers: (state: any, action: PayloadAction<Status<GraphQL.SearchContentAlertsQuery>>) => ({
    ...state,
    socialTrackers: action.payload,
  }),
  setSocialTrackerSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    socialTrackerSearchInput: action.payload,
  }),
}

export const getSocialTrackers = (contains: string) => async (dispatch: Dispatch) => {
  dispatch(setSocialTrackers("loading"))
  const result = await API.fetchContentAlertsByString({ contains })
  dispatch(setSocialTrackers(result))
}

export const deleteSocialTracker = (
  params: {
    variables: GraphQL.DeleteContentAlertMutationVariables,
    onSuccess: () => void,
    onError: () => void
  },
) => async () => {
  const result = await API.deleteContentAlert(params.variables)
  if (result.status === "success") {
    params.onSuccess()
  } else {
    params.onError()
  }
}

import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "../../../../state/hooks"
import LoadingIndicator from "../../../LoadingIndicator"
import QualityAudienceChart from "../../Audience/QualityAudienceChart"
import Button from "../../../Button"
import ModalScoreBreakDown from "../../../ModalScoreBreakDown"

import * as GraphQL from "../../../../graphql"
import { resetAudienceQualityScore } from "../../../../state/socialProfileSlice"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../../../state/scoreBreakDownSlice"
import { calcGrade } from "../../../../util/miscHelper"
import { scoreModalTypes } from "../../../../util/constant"

import "../../Audience/quality-score-chart.sass"

interface AudienceQualityChartProps {
  socialAccount: any
}

export default function AudienceQualityChartWrapper({ socialAccount }: AudienceQualityChartProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AudienceQualityChart" })
  const [ engagementScoreModal, setEngagementScoreModal ] = React.useState(false)
  const { audienceQualityScore } = useSelector(({ socialProfile }) => socialProfile)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (engagementScoreModal === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ engagementScoreModal ])

  React.useEffect(() => () => {
    dispatch(resetAudienceQualityScore())
  }, [])

  if (audienceQualityScore === "loading" || audienceQualityScore === "init") {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (audienceQualityScore.status === "error") {
    return null
  }
  const accountAQS = audienceQualityScore.payload.socialAccount
  const { botLevel } = accountAQS

  if (!accountAQS.audienceQualityScore || !socialAccount) {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  const [ grade, modifier ] = calcGrade(accountAQS.audienceQualityScore.value)
  const modalType: string = scoreModalTypes.AUDIENCE_QUALITY

  const handleEngagmentScoreModal = () => {
    const scoreBreakDown = {
      socialAccount: socialAccount as GraphQL.SocialAccount,
      scores: [ accountAQS.audienceQualityScore as GraphQL.Score ],
    }
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType))
    setEngagementScoreModal(true)
  }

  return (
    <>
      <div className="cp_audience-quality-charts cp_aqc">
        <div className="cp_aqc-main">
          <div className="cp_aqc-header">
            <h6 className="cp_aqc-header-title">
              { translate("Audience Quality") }
            </h6>
            <Button
              id="cp_aqc-Modal-trigger"
              className="cp_aqc-header-button"
              label={ `${ translate("Score") } | ${ grade } ${ modifier }` }
              onClick={ handleEngagmentScoreModal }
            />
          </div>
          <QualityAudienceChart accountAQS={ accountAQS } />
        </div>
        <div className="cp_aqc-aside">
          <div className="cp_aqc-bot-analysis">
            <div className="cp_aqc-bot-analysis-top">
              <p>
                { translate("Bot Analysis") }
              </p>
              <span>{ botLevel }</span>
            </div>
            <div className="cp_aqc-bot-analysis-message">
              <p className="cp_aqc-bot-analysis-message_title">{ translate("BOT SCORE") }</p>
              <p className="cp_aqc-bot-analysis-message_blurb">{ translate("Bot message") }</p>
            </div>
          </div>
        </div>
      </div>
      <ModalScoreBreakDown isModalScoreOpen={ engagementScoreModal } closeModal={ () => setEngagementScoreModal(false) } />
    </>
  )
}

// socialProfile={ profile.payload.socialAccount }

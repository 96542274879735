import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { GridColDef, GridColumnHeaderTitle } from "@mui/x-data-grid"
import { IconButton, LinearProgress } from "@mui/material"
import dayjs from "dayjs"
import { useNavigate, useParams } from "react-router-dom"
import { UnfoldMore } from "@mui/icons-material"
import DataGrid from "../DataGrid"
import { CustomerFragment, SearchCustomerSort } from "../../graphql"
import EntityInfoRow from "../EntityInfoRow"
import Pill from "../Pill"
import EmptyElement from "../EmptyElement"
import Timestamp from "../DataGrid/RenderCellComponents/Timestamp"
import * as Constant from "../../util/constant"
import "./customer-overview-data-grid.sass"
import CustomersOverviewContextMenu from "./CustomersOverviewContextMenu"
import { toggleColumnSort } from "../../state/customersPageSlice"
import { useDispatch } from "../../state/hooks"

type Props = {
  customers: CustomerFragment[]
  fetchMore: () => void
  isLoading: boolean
}

export default function CustomersOverviewDataGrid({
  customers, fetchMore, isLoading,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomersOverviewDataGrid" })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { vanity } = useParams()

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "name",
      sortable: false,
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Customer") } />
          <IconButton
            className="filter-icon"
            onClick={ () => dispatch(toggleColumnSort(SearchCustomerSort.Name)) }
          >
            <UnfoldMore />
          </IconButton>
        </>
      ),
      renderCell: (params) => (
        <EntityInfoRow
          id="cp_component-customers-overview-data-grid_customer-row"
          avatarSrc={ params.row.url }
          name={ params.row.name }
          subInfo={ params.row.vanity }
          key={ params.row.id }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 4,
    },
    {
      field: "users",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Users") } />
          <IconButton
            className="filter-icon"
            onClick={ () => dispatch(toggleColumnSort(SearchCustomerSort.UserCount)) }
          >
            <UnfoldMore />
          </IconButton>
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <Pill
          id="cp_component-customers-overview-data-grid_users-row"
          label={ params.row.userCount }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "created",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Created") } />
          <IconButton
            className="filter-icon"
            onClick={ () => dispatch(toggleColumnSort(SearchCustomerSort.Created)) }
          >
            <UnfoldMore />
          </IconButton>
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <Timestamp
          id="cp_component-customers-overview-data-grid_created-row"
          time={
          dayjs(params.row.created * 1000)
            .format(Constant.LONGFORM_DATE).toUpperCase()
        }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <CustomersOverviewContextMenu
          customerId={ params.row.id }
          id="cp_component-customers-overview-data-grid_menu-row"
        />
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ], [ translate ])

  return (
    <DataGrid
      className="cp_component_customers-overview-data-grid"
      rowHeight={ 100 }
      columnHeaderHeight={ 40 }
      disableColumnReorder={ true }
      hideFooter={ true }
      columns={ COLUMNS }
      onRowClick={ (params) => navigate(`/${ vanity }/customer-management/${ params.row.id }/details`) }
      rows={ customers.map((customer) => ({
        id: customer.id,
        url: customer.company?.logo?.url.address,
        name: customer.company?.name,
        vanity: customer.vanity,
        userCount: customer.userCount,
        created: customer.created,
      })) }
      loading={ isLoading }
      onRowsScrollEnd={ fetchMore }
      scrollEndThreshold={ 200 }
      slots={ {
        loadingOverlay: LinearProgress,
        noRowsOverlay: EmptyElement,
        noResultsOverlay: EmptyElement,
      } }
    />
  )
}

import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import { ALL_NETWORKS, Status } from "../../util/types"
import * as API from "../../util/apiClient"
import { RootState } from "../store"

// Profile Slice Interface and Initial State
export interface TemplateState {
  templateModalOpen: boolean
  listGroupToEdit?: GraphQL.SuggestionListGroupFragment
  listGroupUpdateCallback?: undefined | (() => Promise<void>)
  listGroupModalOpen: boolean
  listsStatus: Status<GraphQL.SearchListsQuery>
  listsContent: Array<GraphQL.SuggestionListRowFragment>
  listsSearchInput: string
  selectedListIds: Array<string>
}

const initialState: TemplateState = {
  templateModalOpen: false,
  listGroupModalOpen: false,
  listGroupToEdit: undefined,
  listGroupUpdateCallback: undefined,
  listsStatus: "init",
  listsContent: [],
  listsSearchInput: "",
  selectedListIds: [],
}

// Profile Slice
export const TemplateSlice = createSlice({
  name: "Template",
  initialState,
  reducers: {
    setTemplateModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      templateModalOpen: action.payload,
    }),
    setListGroupModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      listGroupModalOpen: action.payload,
    }),
    setListsStatus: (
      state,
      action: PayloadAction<Status<GraphQL.SearchListsQuery>>,
    ) => ({
      ...state,
      listsStatus: action.payload,
    }),
    setListsContent: (
      state,
      action: PayloadAction<Array<GraphQL.SuggestionListRowFragment>>,
    ) => ({
      ...state,
      listsContent: action.payload,
    }),
    setListsSearchInput: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      listsSearchInput: action.payload,
    }),
    setSelectedListIds: (
      state,
      action: PayloadAction<Array<string>>,
    ) => ({
      ...state,
      selectedListIds: action.payload,
    }),
    setListGroupToEdit: (
      state,
      action: PayloadAction<{
        listGroup: GraphQL.SuggestionListGroupFragment | undefined
        listGroupUpdateCallback: (() => Promise<void>) | undefined
      }>,
    ) => ({
      ...state,
      listGroupToEdit: action.payload.listGroup,
      listGroupUpdateCallback: action.payload.listGroupUpdateCallback,
    }),
  },
})

export const {
  setTemplateModalOpen,
  setListGroupModalOpen,
  setListsContent,
  setListsStatus,
  setListsSearchInput,
  setSelectedListIds,
  setListGroupToEdit,
} = TemplateSlice.actions
export default TemplateSlice.reducer

export const getLists = (
  searchInput: string,
  page: number,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setListsStatus("loading"))

  const result = await API.fetchLists(
    {
      startsWith: searchInput,
      networkFilter: ALL_NETWORKS.filter((v) => v !== "SNAPCHAT"),
      page,
      limit: 50,
    },
  )
  dispatch(setListsStatus(result))

  if (API.isSuccess(result)) {
    dispatch(setListsContent(result.payload.searchSuggestionList.rows))
  } else {
    dispatch(setListsStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
  dispatch(setListsSearchInput(searchInput))
}

export const getMoreLists = (
  searchInput: string,
  page: number,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { template: { listsStatus: currentListsStatus, listsContent: currentListsContent } } = getState()

  const result = await API.fetchLists(
    {
      startsWith: searchInput,
      // TODO:
      networkFilter: ALL_NETWORKS.filter((v) => v !== "SNAPCHAT"),
      page,
      limit: 50,
    },
  )
  // At this point, currentListsStatus should already have been successful
  if (API.isSuccess(currentListsStatus) && API.isSuccess(result)) {
    dispatch(setListsContent([ ...currentListsContent, ...result.payload.searchSuggestionList.rows ]))
  } else {
    dispatch(setListsStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
}

export const toggleSelectedList = (id: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const { template: { selectedListIds } } = getState()
  const indexOfID = selectedListIds.indexOf(id)
  // Remove if already selected
  if (indexOfID !== -1) {
    const newIDs = [ ...selectedListIds ]
    newIDs.splice(indexOfID, 1)
    dispatch(setSelectedListIds(newIDs))
  } else {
    dispatch(setSelectedListIds([ ...selectedListIds, id ]))
  }
}

export const submitCreateListGroup = (
  name: string,
  listIds: Array<string>,
) => (API.createSuggestionListGroup({ name, listIds }))

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { GridColDef } from "@mui/x-data-grid"
import DataGrid from "../../../../DataGrid"
import { AdFragment } from "../../../../../graphql"
import EmptyElement from "../../../../EmptyElement"
import { snakeToTitleString, capitalizeEachWord } from "../../../../../util/miscHelper"

type Props = {
  rows: AdFragment[]
  setSelectedIds: (adIds: string[]) => void
  selectedIds: string[]
}

export default function CampaignAdsDeliverableTable({
  rows, setSelectedIds, selectedIds,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignAdsDeliverableTable" })

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "ad-set",
      headerName: translate("Ad Set"),
      sortable: true,
      renderCell: (params) => (
        <p className="ad-set-name">{ params.row.name }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      minWidth: 350,
    },
    {
      field: "matching-creatives",
      headerName: translate("Matching Creatives"),
      sortable: false,
      renderCell: (params) => {
        const { matchingCreative } = params.row
        if (matchingCreative) {
          return (
            <p className="matching-creatives">{ `${ matchingCreative.type } ${ matchingCreative.foreignId }` }</p>
          )
        }
        return null
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "deliverable",
      headerName: translate("Deliverable"),
      sortable: false,
      renderCell: (params) => {
        const { deliverableName } = params.row
        return (
          <p>
            { deliverableName || translate("No Deliverable Linked") }
          </p>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "account",
      headerName: translate("Account"),
      sortable: false,
      renderCell: (params) => {
        const { deliverableSocialAccount } = params.row
        if (deliverableSocialAccount) {
          return (
            <p>
              { `@${ deliverableSocialAccount }` }
            </p>
          )
        }
        return null
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "deliverable-type",
      headerName: translate("Deliverable Type"),
      sortable: false,
      renderCell: (params) => {
        const { deliverableType } = params.row
        if (deliverableType) {
          const dType = capitalizeEachWord(snakeToTitleString(deliverableType))
          return (
            <p>
              { dType }
            </p>
          )
        }
        return null
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
  ], [ translate ])

  return (
    <DataGrid
      className="cp_component-campaign-ads-table"
      getRowId={ (row) => row.id }
      checkboxSelection={ true }
      onRowSelectionModelChange={ (ids) => setSelectedIds(ids.map((id) => id.toString())) }
      rowSelectionModel={ selectedIds }
      disableRowSelectionOnClick={ true }
      rowHeight={ 90 }
      columnHeaderHeight={ 40 }
      columns={ COLUMNS }
      disableColumnReorder={ true }
      hideFooter={ true }
      pinnedColumns={
            {
              left: [ "__check__", "ad-set" ],
            }
        }
      rows={ rows.map((row) => ({
        id: row.id,
        name: row.name,
        matchingCreative: row.creative,
        deliverableSocialAccount: row.deliverable?.campaignNetworkAccount.socialAccount.userName,
        deliverableType: row.deliverable?.postType,
        deliverableName: row.deliverable?.name,
      })) }
      slots={ {
        noRowsOverlay: EmptyElement,
        noResultsOverlay: EmptyElement,
      } }
    />
  )
}

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "../Campaigns/style.sass"
import ModalSelectAccount from "../ModalSelectAccount"
import {
  getCampaigns,
  getCampaignsForAccount,
  getCampaignsForProfile,
  setAddToModalPhase,
  setSelectedCampaignIDs,
  setSelectedSocialAccountID,
  setSelectedSocialAccountNetwork,
  submitSocialAccountsToCampaign,
  toggleSelectedCampaign,
} from "../../../state/listSocialProfileSlice"
import * as GraphQL from "../../../graphql"
import * as API from "../../../util/apiClient"
import { pushToast } from "../../../state/toastSlice"
import { useDispatch, useSelector } from "../../../state/hooks"
import ModalAddToCampaigns from "../Campaigns/ModalAddToCampaigns"
import CampaignsTabContent from "../Campaigns/CampaignsTabContent"
import { ALL_NETWORKS } from "../../../util/types"

export default function Campaigns() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })
  const [ profileNetwork, setProfileNetwork ] = React.useState<GraphQL.Network | undefined>(undefined)

  const {
    addToModalPhase: modalPhase,
    listProfile,
    selectedCampaignIDs,
    selectedSocialAccountID,
    campaignsForProfile,
    campaigns,
    campaignsForAccount,
  } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const dispatch = useDispatch()

  useEffect(() => {
    if (API.isSuccess(listProfile)) {
      const { socialAccount } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
      const accountIds = socialAccount.personality?.socialAccounts.map((a) => a.id)
      || [ socialAccount.id ]
      dispatch(getCampaignsForProfile(accountIds, ALL_NETWORKS))
    }
  }, [ listProfile ])

  const resetFields = () => {
    dispatch(setSelectedCampaignIDs([]))
    dispatch(setSelectedSocialAccountID(""))
    dispatch(setSelectedSocialAccountNetwork(null))
  }

  const openModalFlow = () => {
    if (
      API.isSuccess(listProfile)
    ) {
      resetFields()
      const { socialAccount } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
      const socialAccounts = socialAccount.personality?.socialAccounts
      if (socialAccounts && socialAccounts.length > 1) {
        dispatch(setAddToModalPhase(1))
      } else {
        dispatch(setSelectedSocialAccountID(socialAccount.id))
        dispatch(setSelectedSocialAccountNetwork(socialAccount.network))
        dispatch(setAddToModalPhase(2))
        dispatch(getCampaigns(""))
        dispatch(getCampaignsForAccount([ socialAccount.id ], [ socialAccount.network ]))
        setProfileNetwork(socialAccount.network)
      }
    }
  }

  const onAccountRowClick = (socialAccount: GraphQL.SocialAccountRowFragment) => {
    dispatch(setSelectedSocialAccountID(socialAccount.id))
    dispatch(setSelectedSocialAccountNetwork(socialAccount.network))
    dispatch(getCampaigns(""))
    dispatch(getCampaignsForAccount([ socialAccount.id ], [ socialAccount.network ]))
    dispatch(setAddToModalPhase(2))
  }

  const onSubmit = async () => {
    // TODO: This logic for multiple account submission is temporary until backend allows multiple
    dispatch(setAddToModalPhase(null))
    await submitSocialAccountsToCampaign(
      selectedSocialAccountID,
      selectedCampaignIDs,
      () => {
        dispatch(pushToast({
          message: translate("Account has successfully been added!"),
          type: "success",
        }))
        if (API.isSuccess(listProfile)) {
          const { socialAccount } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
          const accountIds = socialAccount.personality?.socialAccounts.map((a) => a.id)
      || [ socialAccount.id ]
          dispatch(getCampaignsForProfile(accountIds, ALL_NETWORKS))
        }
      },
      () => dispatch(pushToast({
        message: translate("There was an error. Please try again."),
        type: "error",
      })),
    )
    resetFields()
  }

  return (
    <div className="cp_component_profile-tabs_campaigns">
      <CampaignsTabContent
        onButtonClick={ openModalFlow }
        campaignsForProfile={ campaignsForProfile }
      />
      <ModalSelectAccount
        open={ modalPhase === 1 }
        closeAction={ () => dispatch(setAddToModalPhase(null)) }
        onRowClick={ onAccountRowClick }
        title={ translate("Select An Account") }
        subtitle={ translate("Select an account to add to a campaign below.") }
        socialProfile={ API.isSuccess(listProfile)
          ? listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount : null }
      />
      <ModalAddToCampaigns
        onSubmit={ onSubmit }
        modalPhase={ modalPhase }
        campaigns={ campaigns }
        campaignsForAccount={ campaignsForAccount }
        selectedCampaignIDs={ selectedCampaignIDs }
        getCampaigns={ (id) => dispatch(getCampaigns(id)) }
        setAddToModalPhase={ (phase) => dispatch(setAddToModalPhase(phase)) }
        toggleSelectedCampaign={ (id) => dispatch(toggleSelectedCampaign(id)) }
        profileNetwork={ profileNetwork }
      />
    </div>
  )
}

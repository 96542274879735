import React from "react"
import { Navigate, useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"

import LoadingPage from "../page/Loading"

const QUERY = gql`
  query CustomerExistsGuard($vanity: String!) {
    vanity(code: $vanity) {
      name
    }
  }
`

export default function CustomerExistsGuard(props: { children: React.ReactNode }) {
  const { vanity } = useParams()
  const vanity$ = useQuery<{ vanity: { name: string } }>(QUERY, {
    variables: {
      vanity,
    },
    skip: !vanity,
  })

  if (vanity$.loading) {
    return <LoadingPage />
  }

  if (vanity$.data?.vanity) {
    return <>{ props.children }</>
  }

  return <Navigate to="/find-team" replace={ true } />
}

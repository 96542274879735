import React, { useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import { Modal } from "@mui/material"
import { useCookies } from "react-cookie"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import Button from "../../component/Button"
import IconButton from "../../component/IconButton"
import InfluentialLogo from "../../component/InfluentialLogo"
import Input from "../../component/Input"
import LoadingIndicator from "../../component/LoadingIndicator"
import { SuggestionAccessCodeRestriction } from "../../graphql"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  fetchPublicListName,
  setInfoRequiredModalOpen,
  setInfoRequiredSubmitCallback,
  submitVerification,
} from "../../state/publicListSlice"

import "./modal-info-required.sass"

type Props = {
  codeRestriction: SuggestionAccessCodeRestriction | null | undefined
}

export default function ModalInfoRequired({ codeRestriction }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalInfoRequired" })

  const { listCode } = useParams()
  const dispatch = useDispatch()
  const {
    accessCodeCookieKey,
    companyCookieKey,
    nameCookieKey,
    infoRequiredModalOpen,
    listNameForInfoModal,
    infoRequiredSubmitCallback,
  } = useSelector(({ publicList }) => publicList)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ cookies, setCookie ] = useCookies([ accessCodeCookieKey ])
  const [ name, setName ] = useState("")
  const [ company, setCompany ] = useState("")
  const [ accessCode, setAccessCode ] = useState("")
  const [ accessCodeError, setAccessCodeError ] = useState(false)

  useEffect(() => {
    setName("")
    setCompany("")
    setAccessCode("")
    setAccessCodeError(false)
  }, [])

  useEffect(() => {
    if (listCode) {
      dispatch(fetchPublicListName(listCode))
    }
  }, [ listCode ])

  const onSubmit = async () => {
    const isError = await dispatch(submitVerification(listCode || "", accessCode, codeRestriction))
    setAccessCodeError(isError)
    if (!isError) {
      setCookie(accessCodeCookieKey, accessCode, { path: "/" })
      setCookie(companyCookieKey, company, { path: "/" })
      setCookie(nameCookieKey, name, { path: "/" })
      dispatch(setInfoRequiredModalOpen(false))

      infoRequiredSubmitCallback()
      dispatch(setInfoRequiredSubmitCallback(() => {}))
    }
  }

  const renderListName = () => {
    if (listNameForInfoModal === "init" || listNameForInfoModal === "loading") {
      return <LoadingIndicator flexWrapperEnabled={ true } />
    }
    if (API.isError(listNameForInfoModal)) {
      return <p>There was an error!</p>
    }

    const listName = listNameForInfoModal.payload.publicSuggestionListByCode.name

    if (codeRestriction === SuggestionAccessCodeRestriction.RequiredToView) {
      return <h2>{ listName }</h2>
    }
    return (
      <>
        <h2>{ translate("Information Required") }</h2>
        <h6>
          { `${ translate("Hey there! To take an action on") } ${ listName } 
          ${ translate(", we need to verify the information below.") }` }
        </h6>
      </>
    )
  }

  return (
    <Modal open={ infoRequiredModalOpen }>
      <div className="cp_component_public-list-info-modal">
        <InfluentialLogo hasTag={ true } />
        { codeRestriction !== SuggestionAccessCodeRestriction.RequiredToView
        && (
        <IconButton
          className="cp_component_public-list-info-modal-icon-button"
          onClick={ () => dispatch(setInfoRequiredModalOpen(false)) }
          variant="outlined"
        >
          <Close />
        </IconButton>
        ) }
        <div className="cp_component_public-list-info-modal-container">
          { renderListName() }
          { (codeRestriction !== null || undefined)
            && (
            <>
              <Input
                className="cp_component_public-list-input-access-code"
                value={ accessCode }
                onChange={ (e) => setAccessCode(e.target.value) }
                label="Access Code*"
              />
              <p className={ accessCodeError ? "error-text" : "error-text hidden" }>
                { translate("The access code you entered is incorrect. Please try again.") }
              </p>
            </>
            )
          }
          <Input
            onChange={ (e) => setName(e.target.value) }
            value={ name }
            className="cp_component_public-list-input"
            label="Your Name*"
          />
          <Input
            onChange={ (e) => setCompany(e.target.value) }
            value={ company }
            className="cp_component_public-list-input"
            label="Company*"
          />
          <Button
            className="cp_component_public-list-button"
            disabled={ name.length === 0 || company.length === 0 }
            label={ translate("View List") }
            onClick={ onSubmit }
          />
          <p>{ translate("If you need assistance accessing this list, please contact your representative") }</p>
        </div>
      </div>
    </Modal>
  )
}

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useParams } from "react-router-dom"

import * as API from "../../../util/apiClient"
import { useDispatch, useSelector } from "../../../state/hooks"
import LoadingIndicatorCard from "../../LoadingIndicatorCard"
import EmptySplash from "../../EmptySplash"
import ErrorHandler from "../../ErrorHandler"
import { fetchNotificationSettings } from "../../../state/campaignSlice"
import NotificationToggles from "./NotificationToggles"

export default function CampaignNotifications() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignNotifications" })
  const dispatch = useDispatch()

  const { notificationSettings } = useSelector(({ campaignPage }) => campaignPage)
  const { campaignID } = useParams()

  useEffect(() => {
    if (campaignID) {
      dispatch(fetchNotificationSettings({ id: campaignID }))
    }
  }, [ campaignID ])

  if (notificationSettings === "init" || notificationSettings === "loading") {
    return <LoadingIndicatorCard size={ 50 } />
  }

  if (API.isError(notificationSettings)) {
    return <ErrorHandler err={ notificationSettings } />
  }

  if (!notificationSettings.payload.campaignCommunicationSettings) {
    return (
      <EmptySplash
        headlineText={ translate("Settings Not Available") }
        bodyText={ translate("Notification Settings are only available to Brand Managers and Campaign Managers.") }
      />
    )
  }

  const {
    deliverableCreated,
    deliverableFeedback,
    deliverableFinalized,
    deliverableItemAccepted,
    deliverableLive,
    deliverableLiveUrl,
    deliverableUpdated,
  } = notificationSettings.payload.campaignCommunicationSettings

  return (
    <div className="cp_component_campaign-notifications-settings">
      <h4 className="notifications-header">{ translate("Campaign Notification Settings") }</h4>
      <NotificationToggles
        deliverableSettings={ {
          deliverableCreated,
          deliverableFeedback,
          deliverableFinalized,
          deliverableItemAccepted,
          deliverableLive,
          deliverableLiveUrl,
          deliverableUpdated,
        } }
      />
    </div>
  )
}

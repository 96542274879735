/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { useTranslation } from "react-i18next"
import Modal, { ModalProps } from "../Modal"
import "./style.sass"

interface NSFWProps {
  open: ModalProps["open"]
  title: string
  disabled: boolean
  cancelAction: () => void
  closeAction: () => void
  handleAccept: () => void
}

function ModalNSFW({
  cancelAction,
  closeAction,
  handleAccept,
  open,
  title,
  disabled,
}: NSFWProps): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalNSFW" })

  return (
    <Modal
      title={ title }
      primaryLabel={ translate("Yes, Mark NSFW") }
      secondaryLabel={ translate("Cancel") }
      disabled={ disabled }
      open={ open }
      closeAction={ closeAction }
      primaryAction={ handleAccept }
      secondaryAction={ cancelAction }
      maxWidth="lg"
    >
      <p>
        { translate("Description 1") }
      </p>
      <br />
      <p>
        { translate("Description 2") }
      </p>
    </Modal>
  )
}

export default ModalNSFW

import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import CloseIcon from "@mui/icons-material/Close"
import { Badge, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import Avatar from "../Avatar"
import AvatarUploader from "../AvatarUploader"
import Input from "../Input"
import Modal from "../Modal"
import { FieldCharacterMax } from "../../util/constant"
import { FileResponse } from "../../util/types"
import { pushToast } from "../../state/toastSlice"
import { uploadFile } from "../../util/miscHelper"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  createNewCustomer,
  resetCustomer,
  setCreateCustomerModalOpen,
  resetEditCustomer,
  updateCustomer,
} from "../../state/createCustomer"

import "./styles.sass"

interface ImageUploader {
  avatar: FileResponse | undefined
  setAvatar: React.Dispatch<React.SetStateAction<FileResponse | undefined>>
  handleClientAvatarUpdate: (file: File) => Promise<void>
}

function CustomerImageUploader({
  avatar, setAvatar, handleClientAvatarUpdate,
}: ImageUploader) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCreateCustomer" })
  return (
    <div className="cp_create-customers_photo">
      { avatar
        ? (
          <Badge
            className=".cp_create-customers_photo-badge"
            badgeContent={ (
              <IconButton
                className="cp_avatar-removal"
                aria-label="delete"
                onClick={ () => setAvatar(undefined) }
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              ) }
            anchorOrigin={ {
              vertical: "bottom",
              horizontal: "right",
            } }
          >
            <Avatar src={ avatar.url } />
          </Badge>
        )
        : (
          <AvatarUploader
            customClasses="cp_create-customers_photo-uploader"
            title={ translate("Customer Thumbnail") }
            infoText={ translate("Click to upload photo") }
            onAvatarSelect={ handleClientAvatarUpdate }
          />
        )
      }
    </div>
  )
}

function ModalCreateCustomer() {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCreateCustomer" })
  const dispatch = useDispatch()
  const {
    isModalOpen, newCustomer, editCustomer,
  } = useSelector(({ createCustomer }) => createCustomer)
  const isEditMode = editCustomer !== "init"
  const [ customerId, setCustomerId ] = useState("")
  const [ avatar, setAvatar ] = React.useState<FileResponse | undefined>()
  const [ displayName, setDisplayName ] = useState("")
  const [ vanityCode, setVanityCode ] = useState("")

  useEffect(() => {
    if (isEditMode && API.isSuccess(editCustomer)) {
      const customer = editCustomer.payload?.customer
      if (customer && customer.company) {
        setDisplayName(customer.company.name)
        setVanityCode(customer.vanity)
        setCustomerId(customer.id)
        if (customer.company.logo) {
          setAvatar({ id: customer.company.logo.id, url: customer.company.logo.url.address })
        }
      }
    }
  }, [ editCustomer ])

  useEffect(() => {
    if (API.isSuccess(newCustomer)) {
      dispatch(pushToast({
        type: "success",
        message: translate("Customer created successfully"),
      }))
    } else if (API.isError(newCustomer)) {
      dispatch(pushToast({
        type: "error",
        message: translate("Failed to create customer"),
        additionalInfo: translate("Please try again later"),
      }))
    }
  }, [ newCustomer ])

  const resetState = () => {
    setDisplayName("")
    setVanityCode("")
    setAvatar(undefined)
  }

  const closeModal = () => {
    dispatch(setCreateCustomerModalOpen(false))
    dispatch(resetCustomer())
    dispatch(resetEditCustomer())
    resetState()
  }

  const handleClientAvatarUpdate = async (file: File) => {
    const fileStatus = await uploadFile(file)
    if (fileStatus.id) {
      setAvatar({ id: fileStatus.id, url: fileStatus.url })
    }
  }

  const handleCreateCustomer = () => {
    if (avatar && avatar.id) {
      dispatch(createNewCustomer({
        code: vanityCode, companyName: displayName, mediaId: `${ avatar.id }`,
      }))
    } else {
      dispatch(createNewCustomer({ code: vanityCode, companyName: displayName }))
    }
    closeModal()
  }

  const handleUpdateCustomer = () => {
    if (avatar && avatar.id) {
      dispatch(updateCustomer({
        customerId, code: vanityCode, companyName: displayName, mediaId: `${ avatar.id }`,
      }))
    } else {
      dispatch(updateCustomer({
        customerId, code: vanityCode, companyName: displayName,
      }))
    }
    closeModal()
  }

  const handleModalSubmit = () => {
    if (isEditMode) {
      handleUpdateCustomer()
    } else {
      handleCreateCustomer()
    }
  }

  const isSaveDisabled = useMemo(
    () => !!((vanityCode.length && displayName.length && avatar)),
    [ vanityCode, displayName, avatar ],
  )

  return (
    <Modal
      title={ isEditMode ? translate("Edit Customer") : translate("Create Customer") }
      primaryLabel={ translate("SAVE CHANGES") }
      secondaryLabel={ translate("Cancel") }
      open={ isModalOpen }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ handleModalSubmit }
      maxWidth="lg"
      className="cp_component_modal-create-customer"
      disabled={ !isSaveDisabled }
    >
      <div className="cp_create-customers">
        <CustomerImageUploader
          handleClientAvatarUpdate={ handleClientAvatarUpdate }
          avatar={ avatar }
          setAvatar={ setAvatar }
        />
        <div className="cp_create-customers-fields">
          <div className="cp_create-customers-input-wrapper">
            <span className="name-imput-max">{ `${ displayName.length }/${ FieldCharacterMax.SHORT_MAX }` }</span>
            <Input
              className="cp_create-customers-name_input"
              value={ displayName }
              placeholder={ translate("Enter Name") }
              onChange={ (e) => setDisplayName(e.currentTarget.value) }
              type="text"
              fullWidth={ true }
              InputProps={ {
                inputProps: {
                  maxLength: 32,
                },
              } }
              required={ true }
              label={ `${ translate("Company Name") }*` }
            />
          </div>
          <div className="cp_create-customers-input-wrapper">
            <span className="code-imput-max">{ `${ vanityCode.length }/${ FieldCharacterMax.STANDARD_MAX }` }</span>
            <Input
              className="cp_create-customers-vanity-code_input"
              value={ vanityCode }
              placeholder={ translate("Enter Code") }
              onChange={ (e) => setVanityCode(e.currentTarget.value) }
              type="text"
              fullWidth={ true }
              InputProps={ {
                inputProps: {
                  maxLength: 64,
                },
              } }
              required={ true }
              label={ `${ translate("Customer Vanity Code") }*` }
            />
          </div>
          { /* TODO: Will be implemented in the future - waiting for backend support
          <div className="cp_create-customers-white-labeling">
            <div className="switch-wrapper">
              <Switch
                color="primary"
                hasIcon={ true }
                icon={ <CancelIcon /> }
                checkedIcon={ <CheckCircleSharpIcon /> }
                isChecked={ isToggleActive }
                handleChange={ () => { setToggleActive(!isToggleActive) } }
              />
              <p>{ translate("Enable white labeling") }</p>
            </div>
          </div>
          */ }
        </div>
      </div>
    </Modal>
  )
}

export default ModalCreateCustomer

import React from "react"
import ButtonMui, { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import "./style.sass"

// eslint-disable-next-line no-shadow
enum Btn {
  MAIN = "cp-btn",
  ENABLED = "cp-btn_enabled",
  DISABLED = "cp-btn_disabled",
  PRIMARY = "cp-btn_primary",
  SECONDARY = "cp-btn_secondary",
  PRESSED = "cp-btn_pressed",
  HASICON = "cp-btn_icon",
  CONTAINED = "contained",
  OUTLINED = "outlined",
  MEDIUM = "medium",
}

export type onClick = MuiButtonProps["onClick"]
export type ButtonSize = MuiButtonProps["size"]

interface Props extends MuiButtonProps {
  label: string | React.ReactNode,
  isEnabled?: boolean,
  isPrimary?: boolean,
  isPressed?: boolean
  className?: string,
}

function Button(props: Props): React.JSX.Element {
  const {
    isEnabled = true,
    isPrimary = true,
    isPressed = false,
    label,
    startIcon,
    endIcon,
    size,
    className = "",
    ...restProps
  } = props

  const variant = isPrimary ? Btn.CONTAINED : Btn.OUTLINED

  const classes = [
    Btn.MAIN,
    isEnabled ? Btn.ENABLED : Btn.DISABLED,
    isPrimary ? Btn.PRIMARY : Btn.SECONDARY,
    isPressed ? Btn.PRESSED : "",
    startIcon || endIcon ? Btn.HASICON : "",
    size ? `cp-btn_size-${ size }` : "",
  ].join(" ")
    .concat(" ", className)

  return (
    <ButtonMui
      className={ classes }
      size={ size || Btn.MEDIUM }
      startIcon={ startIcon }
      endIcon={ endIcon }
      variant={ variant }
      disabled={ !isEnabled }
      { ...restProps }
    >
      { label }
    </ButtonMui>
  )
}

export default Button

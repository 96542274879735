import React from "react"
import { useTranslation } from "react-i18next"
import UserListCard, { UserCardListRow } from "./UserListCard"
import { useSelector } from "../../../../../state/hooks"
import LoadingIndicatorCard from "../../../../LoadingIndicatorCard"

export default function BrandManagersCard() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignOverviewDetails" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })

  const { campaign } = useSelector(({ campaignPage }) => campaignPage)

  if (campaign === "init" || campaign === "loading") {
    return <LoadingIndicatorCard />
  }

  if (campaign.status === "error") {
    return <p>{ translateCommon("An unexpected error occurred!") }</p>
  }

  const rows: UserCardListRow[] = campaign.payload.campaign.links.map(({
    title, url, id,
  }) => ({
    id,
    name: title,
    link: url.address,
  }))

  return (
    <UserListCard
      rows={ rows }
      title={ translate("Campaign Links") }
      emptyText={ translate("No Campaign Links Added") }
      buttonEnabled={ false }
    />
  )
}

import React from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import * as API from "../../../util/apiClient"
import * as Constant from "../../../util/constant"
import EntityInfoRow from "../../EntityInfoRow"
import LoadingIndicator from "../../LoadingIndicator"
import ModalAddTo from "../../ModalAddTo"
import { useDispatch, useSelector } from "../../../state/hooks"
import {
  getLists,
  setAddToModalPhase,
  toggleSelectedList,
} from "../../../state/socialProfileSlice"

type Props = {
    onSubmit: () => void
}

export default function ModalAddToLists({ onSubmit }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })
  const dispatch = useDispatch()
  const {
    lists, listsForAccount, addToModalPhase: modalPhase, selectedListIDs, selectedSocialAccountNetwork,
  } = useSelector((state) => state.socialProfile)
  const renderContent = () => {
    if (lists === "init" || lists === "loading" || listsForAccount === "init" || listsForAccount === "loading") {
      return <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
    }
    if (API.isSuccess(lists) && API.isSuccess(listsForAccount)) {
      const idsAlreadyAdded = listsForAccount.payload.getSuggestionListsBySocialAccountIds.rows.map((row) => row.id)
      return lists.payload.searchSuggestionList.rows.map((row) => {
        const alreadyAdded = idsAlreadyAdded.includes(row.id)
        const checked = alreadyAdded || selectedListIDs.includes(row.id)
        const disabled = alreadyAdded || (!checked && selectedListIDs.length >= 10)
        return (
          <EntityInfoRow
            key={ row.id }
            onClick={ () => {
              if (!disabled) dispatch(toggleSelectedList(row.id))
            } }
            checkboxEnabled={ true }
            checkboxDisabled={ disabled }
            checkboxValue={ checked }
            network={ row.network }
            name={ row.name }
            avatarSrc={ row.avatar?.url.address }
            subInfo={
              `${ row.creator.username } | ${ dayjs(row.created, "X").format(Constant.LONGFORM_DATE) }`
            }
          />
        )
      })
    }
    return <p className="cp_component-modal-add-to-error">Error!</p>
  }
  return (
    <ModalAddTo
      title={ translate("Add to List") }
      subtitle={ translate("Select up to 10 lists that you would like to add the selected account(s) to.") }
      selectedEntityCount={ selectedListIDs.length }
      open={ modalPhase === 2 }
      closeAction={ () => dispatch(setAddToModalPhase(null)) }
      primaryAction={ onSubmit }
      fetchData={ (e) => dispatch(getLists(e.target.value, selectedSocialAccountNetwork)) }
      searchPlaceholderText={ translate("Search List Name") }
      disabled={ selectedListIDs.length >= 10 }
    >
      <div>
        { renderContent() }
      </div>
    </ModalAddTo>
  )
}

import React, { JSX } from "react"
import { MenuItem } from "@mui/material"

export type RelevancyItem = {
  name: string,
  code: string,
}

export const relevancyItems: RelevancyItem[] = [
  { name: "Art and Entertainment", code: "cont_rel_art_and_entertainment" },
  { name: "Automotive and Vehicles", code: "cont_rel_automotive_and_vehicles" },
  { name: "Business and Industrial", code: "cont_rel_business_and_industrial" },
  { name: "Careers", code: "cont_rel_careers" },
  { name: "Education", code: "cont_rel_education" },
  { name: "Family and Parenting", code: "cont_rel_family_and_parenting" },
  { name: "Finance", code: "cont_rel_finance" },
  { name: "Food and Drink", code: "cont_rel_food_and_drink" },
  { name: "Health and Fitness", code: "cont_rel_health_and_fitness" },
  { name: "Hobbies and Interests", code: "cont_rel_hobbies_and_interests" },
  { name: "Home and Garden", code: "cont_rel_home_and_garden" },
  { name: "Government and Politics", code: "cont_rel_law,_govt_and_politics" },
  { name: "News", code: "cont_rel_news" },
  { name: "Pets", code: "cont_rel_pets" },
  { name: "Real Estate", code: "cont_rel_real_estate" },
  { name: "Religion and Spirituality", code: "cont_rel_religion_and_spirituality" },
  { name: "Science", code: "cont_rel_science" },
  { name: "Shopping", code: "cont_rel_shopping" },
  { name: "Society", code: "cont_rel_society" },
  { name: "Sports", code: "cont_rel_sports" },
  { name: "Style and Fashion", code: "cont_rel_style_and_fashion" },
  { name: "Technology and Computing", code: "cont_rel_technology_and_computing" },
  { name: "Travel", code: "cont_rel_travel" },
]

export function selectionOptions(
  options: RelevancyItem[],
  noPlaceholder?: boolean,
): JSX.Element[] {
  const selectOptions = options.map((option, indx) => {
    if (!noPlaceholder && indx === 0) {
      return (
        <MenuItem key={ option.code } value="" disabled={ true }>
          { option.name }
        </MenuItem>
      )
    }

    return (
      <MenuItem key={ option.code } value={ option.code }>
        { option.name }
      </MenuItem>
    )
  })

  return selectOptions
}

import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Collapse,
  ListItemText,
  MenuItem,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

import "./style.sass"

import Trait from "./Trait"
import Select from "../../../Select"
import Divider from "../../../Divider"
import * as GraphQL from "../../../../graphql"

// eslint-disable-next-line no-shadow
enum SortEnum {
    DEFAULT = "SORT BY",
    SCORE = "SCORE",
    ALPHABETICAL = "ALPHABETICAL",
}

const sortOptions = [
  SortEnum.DEFAULT,
  SortEnum.ALPHABETICAL,
  SortEnum.SCORE,
]

type Props = {
  socialProfile: GraphQL.SocialProfileFragment
}
export function PersonalityTraits({ socialProfile }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PersonalityTraits" })
  const [ selectedSortOption, setSortOption ] = useState(SortEnum.DEFAULT)
  const [ expanded, setExpanded ] = useState(false)

  const sortMenuItems = useMemo(() => sortOptions.map((option) => (
    <MenuItem
      key={ option }
      onClick={ () => setSortOption(option) }
      value={ option }
      disabled={ option === SortEnum.DEFAULT }
      className="cp_engagement-map_component-tz-item"
    >
      <ListItemText>{ translate(option) }</ListItemText>
    </MenuItem>
  )), [])

  const sortedTraits = useMemo(() => {
    const traits = socialProfile.piTraitScores
    switch (selectedSortOption) {
      case SortEnum.SCORE:
        return Array.from(traits).sort((a, b) => (b.score.value || 0) - (a.score.value || 0))

      case SortEnum.ALPHABETICAL:
      case SortEnum.DEFAULT:
      default:
        return traits
    }
  }, [ selectedSortOption, socialProfile ])

  return (
    <div className="cp_personality-traits_component">
      <div className="cp_personality-traits_component-header">
        <h6 className="cp_personality-traits_component-header-title">
          { translate("Personality Traits") }
        </h6>
        <Select
          id="select-traits-sort"
          label=""
          labelId="select-traits-sort"
          menuItems={ sortMenuItems }
          value={ selectedSortOption }
          className="cp_personality-traits_component-header-sort"
        />
      </div>
      <Divider />
      <div className="cp_personality-traits_component-body">
        { sortedTraits.slice(0, 5).map((trait) => <Trait key={ trait.score.display } piTrait={ trait } />) }
        <Collapse timeout="auto" in={ expanded }>
          { sortedTraits.slice(5).map((trait) => <Trait key={ trait.score.display } piTrait={ trait } />) }
        </Collapse>
      </div>
      <div className="cp_personality-traits_component-footer">
        <Button onClick={ () => setExpanded(!expanded) } variant="text">
          { expanded ? translate("Show Less Results") : translate("Show More Results") }
          { expanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
        </Button>
      </div>
    </div>
  )
}

export default PersonalityTraits

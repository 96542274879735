import React, { JSX, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import Error from "../Error"
import LoadingIndicator from "../../component/LoadingIndicator"
import PageShell from "../../component/PageShell"
import ProfileModal from "../../component/ModalProfile"
import ProfileOverview from "../../component/ProfileOverview/SocialProfileOverview"
import ProfileTabs from "../../component/ProfileTabs/SocialProfileTabs"
import ToastController from "../../controller/Toast"
import { Scope } from "../../util/types"
import { fetchProfile } from "../../state/profileSlice"
import { fetchSocialProfile } from "../../state/socialProfileSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export default function SocialProfile(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Profiles" })
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const socialProfile = useSelector(({ socialProfile: socialProfileSlice }) => socialProfileSlice.profile)
  const { user, scopes } = useSelector(({ user: userSlice }) => userSlice)
  const { socialAccountId, vanity } = useParams()

  useEffect(() => {
    if (!socialAccountId) return
    dispatch(fetchSocialProfile(socialAccountId))
  }, [ socialAccountId ])

  useEffect(() => {
    if (
      socialProfile === "init"
      || socialProfile === "loading"
      || API.isError(socialProfile)
      || !socialProfile.payload.socialAccount.personality
    ) {
      return
    }

    dispatch(fetchProfile(socialProfile.payload.socialAccount.personality.id))
  }, [ socialProfile ])

  const pageTitle = (): string | JSX.Element => {
    if (socialProfile === "init" || socialProfile === "loading") {
      return <LoadingIndicator />
    }

    if (
      API.isSuccess(socialProfile)
      && socialProfile.payload.socialAccount.personality == null
    ) {
      return translate("Social Account")
    }

    return translate("Profiles")
  }

  // User state is loading... show minimal page frame without results...
  if (!API.isSuccess(user)) {
    return (
      <div className="cp_profile_page">
        <ToastController />
        <PageShell
          title={ pageTitle() }
          showTopNav={ false }
        />
      </div>
    )
  }

  // NOTE: Twitter support is being deprecated, but until Twitter accounts
  // are removed from the back end, hide them here explicitly.
  if (
    API.isSuccess(socialProfile)
    && socialProfile.payload.socialAccount.network === GraphQL.Network.Twitter
  ) {
    return <Error statusCode={ 404 } />
  }

  // Return 404 page for TikTok profiles when user doesn't have proper scope
  if (
    API.isSuccess(socialProfile)
    && socialProfile.payload.socialAccount.network === GraphQL.Network.Tiktok
    && !scopes.includes(Scope.FEATURE_TIKTOK)
  ) {
    return <Error statusCode={ 404 } />
  }

  // Return 404 page for Snapchat profiles when user doesn't have proper scope
  if (
    API.isSuccess(socialProfile)
    && socialProfile.payload.socialAccount.network === GraphQL.Network.Snapchat
    && !scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT)
  ) {
    return <Error statusCode={ 404 } />
  }

  return (
    <div className="cp_profile_page">
      <ToastController />
      <PageShell title={ pageTitle() } />
      <div className="cp_profile_page-container">
        <section className="cp_profile_page-overview">
          <ProfileOverview />
        </section>
        <section className="cp_profile_page-metrics">
          <ProfileTabs />
        </section>
      </div>
      <ProfileModal
        updateCallback={ async (newProfile: GraphQL.EditProfileMutationVariables) => {
          if (!socialAccountId) return

          // Since the social account that was currently active may have been
          // updated, redirect to social account on profile that is now certain
          // to exist.
          const updatedSocialAccountIds = Array.isArray(newProfile.socialAccounts)
            ? newProfile.socialAccounts.map(({ id }) => id)
            : [ newProfile.socialAccounts.id ]
          if (!updatedSocialAccountIds.includes(socialAccountId)) {
            navigate(`/${ vanity }/profile/${ updatedSocialAccountIds[0] }/insights`)
          } else {
            await dispatch(fetchSocialProfile(socialAccountId))
          }
        } }
      />
    </div>
  )
}

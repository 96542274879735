import React from "react"
import { Folder } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import { shorthandNumber } from "../../util/miscHelper"

import "./style.sass"

type Props = {
    name: string
    numberOfLists: number
    numberOfAccounts: number
    numberOfFollowers: number
}

export default function ListGroupHeaderCard({
  name, numberOfLists, numberOfAccounts, numberOfFollowers,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListGroupOverview" })
  return (
    <div className="cp_list-group-header-card">
      <Folder className="cp_list-group-header-card-folder-icon" />
      <div className="cp_list-group-header-card-info">
        <p className="cp_list-group-header-card-info-title">{ name }</p>
        <p className="cp_list-group-header-card-info-subinfo">
          { `${ numberOfLists } ${ translate("Lists") } | ${ numberOfAccounts } ${ translate("Accounts") }
          | ${ shorthandNumber(numberOfFollowers) } ${ translate("Reach") }` }
        </p>
      </div>
    </div>
  )
}

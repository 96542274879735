import React from "react"

import CampaignModal from "../ModalCampaign"
import CampaignUpdateDeliverableMetricsModal from "../CampaignTabs/Tabs/CampaignUpdateDeliverableMetricsModal"
import ConfirmationModal from "../ConfirmationModal"
import ListFormModal from "../ListFormModal"
import ModalAddToLists from "../ModalAddToLists"
import ModalCampaignDeliverable from "../ModalCampaignDeliverable"
import ModalCampaignDeliverableContent from "../ModalCampaignDeliverableContent"
import ModalCommGroup from "../ModalCommGroup"
import ModalConfirmFinalize from "../ModalCampaignDeliverableContent/ModalConfirm"
import ModalTikTokInvite from "../ModalTikTokInviteLink"
import ModalCopyLink from "../ModalCopyLink"
import ModalProfile from "../ModalProfile"
import ModalLinkDeliverables from "../CampaignTabs/Tabs/CampaignAds/ModalLinkDeliverables"
import ModalEditListProfile from "../ModalEditListProfile"
import ModalManageBrandManagers from "../CampaignTabs/Tabs/CampaignOverviewDetails/UserListCards/Modals/ModalManageBrandManagers"
import ModalManageClients from "../CampaignTabs/Tabs/CampaignOverviewDetails/UserListCards/Modals/ModalManageClients"
import ModalManageManagers from "../CampaignTabs/Tabs/CampaignOverviewDetails/UserListCards/Modals/ModalManageCampaignManagers"
import UpdateAudienceTargettingModal from "../CampaignTabs/Tabs/CampaignAds/UpdateAudienceTargettingModal"
import ModalCreateListGroup from "../ModalCreateListGroup"
import ModalNewTracker from "../ModalNewTracker"
import SocialTrackerDownloadCSVModal from "../NavigationBar/SlidingPanels/SocialTrackerPanel/SocialTrackerDownloadCSVModal"
import SelectCampaignModal from "../ModalSelectCampaign"
import ModalCreateCustomer from "../ModalCreateCustomer"
import ModalCustomerUser from "../ModalCustomerUser"

// Single mount point for ALL modals in Radius
export default function Modals() {
  return (
    <>
      <CampaignModal />
      <CampaignUpdateDeliverableMetricsModal />
      <ConfirmationModal />
      <ListFormModal />
      <ModalAddToLists />
      <ModalCampaignDeliverable />
      <ModalCampaignDeliverableContent />
      <ModalCommGroup />
      <ModalConfirmFinalize />
      <ModalTikTokInvite />
      <ModalCopyLink />
      <ModalLinkDeliverables />
      <ModalManageBrandManagers />
      <ModalManageClients />
      <ModalManageManagers />
      <UpdateAudienceTargettingModal />
      <ModalEditListProfile />
      <ModalProfile />
      <ModalCreateListGroup />
      <ModalNewTracker />
      <SocialTrackerDownloadCSVModal />
      <SelectCampaignModal />
      <ModalCreateCustomer />
      <ModalCustomerUser />
    </>
  )
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { RootState } from "../store"
import { FilterMenuValue } from "../../component/FilterMenu"

// Customer Slice Interface and Initial State
export interface CustomerState {
  customerStatus: Status<GraphQL.GetCustomerQuery>
  teamMembers: Status<GraphQL.SearchTeamMembersForCustomerQuery>
  teamMembersByTitle: Status<GraphQL.SearchTeamMembersForCustomerByTitleQuery>
  teamMembersContent: Array<GraphQL.CustomerTeamMemberFragment>
  teamMembersLoading: boolean
  teamMembersColumnn: GraphQL.TeamMemberSortColumn
  teamMembersByTitleColumn: GraphQL.SearchUserByTitleSortColumn
  teamMembersDirection: GraphQL.SortDirection
  teamMembersSearchToggle: string
  teamMembersSearchInput: string
  teamMembersFilterValue: FilterMenuValue
}

const defaultFilterValue: FilterMenuValue = {
  keyId: "", label: "", value: null,
}

const initialState: CustomerState = {
  customerStatus: "init",
  teamMembers: "init",
  teamMembersByTitle: "init",
  teamMembersContent: [],
  teamMembersLoading: false,
  teamMembersColumnn: GraphQL.TeamMemberSortColumn.Name,
  teamMembersByTitleColumn: GraphQL.SearchUserByTitleSortColumn.ContactName,
  teamMembersDirection: GraphQL.SortDirection.Asc,
  teamMembersSearchToggle: "name",
  teamMembersSearchInput: "",
  teamMembersFilterValue: defaultFilterValue,
}

// Campaign Page Slice
export const CustomersPageSlice = createSlice({
  name: "CustomerSlice",
  initialState,
  reducers: {
    setCustomerStatus: (state, action: PayloadAction<Status<GraphQL.GetCustomerQuery>>) => ({
      ...state,
      customerStatus: action.payload,
    }),
    setTeamMembers: (state, action: PayloadAction<Status<GraphQL.SearchTeamMembersForCustomerQuery>>) => ({
      ...state,
      teamMembers: action.payload,
    }),
    setTeamMembersByTitle: (state, action: PayloadAction<Status<GraphQL.SearchTeamMembersForCustomerByTitleQuery>>) => ({
      ...state,
      teamMembersByTitle: action.payload,
    }),
    setTeamMembersContent: (state, action: PayloadAction<Array<GraphQL.CustomerTeamMemberFragment>>) => ({
      ...state,
      teamMembersContent: action.payload,
    }),
    setTeamMembersLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      teamMembersLoading: action.payload,
    }),
    setTeamMembersSearchVariables: (state, action: PayloadAction<GraphQL.SearchTeamMembersForCustomerQueryVariables>) => ({
      ...state,
      teamMembersSearchVaribles: action.payload,
    }),
    setTeamMembersSearchToggle: (state, action: PayloadAction<string>) => ({
      ...state,
      teamMembersSearchToggle: action.payload,
    }),
    setTeamMembersSearchInput: (state, action: PayloadAction<string>) => ({
      ...state,
      teamMembersSearchInput: action.payload,
    }),
    setTeamMembersColumnn: (state, action: PayloadAction<GraphQL.TeamMemberSortColumn>) => ({
      ...state,
      teamMembersColumnn: action.payload,
    }),
    setTeamMembersByTitleColumn: (state, action: PayloadAction<GraphQL.SearchUserByTitleSortColumn>) => ({
      ...state,
      teamMembersByTitleColumn: action.payload,
    }),
    setTeamMembersDirection: (state, action: PayloadAction<GraphQL.SortDirection>) => ({
      ...state,
      teamMembersDirection: action.payload,
    }),
    setTeamMembersFilterValue: (state, action: PayloadAction<FilterMenuValue>) => ({
      ...state,
      teamMembersFilterValue: action.payload,
    }),
  },
})

export const {
  setCustomerStatus,
  setTeamMembers,
  setTeamMembersContent,
  setTeamMembersLoading,
  setTeamMembersSearchVariables,
  setTeamMembersSearchInput,
  setTeamMembersSearchToggle,
  setTeamMembersByTitle,
  setTeamMembersByTitleColumn,
  setTeamMembersColumnn,
  setTeamMembersDirection,
  setTeamMembersFilterValue,
} = CustomersPageSlice.actions
export default CustomersPageSlice.reducer

export const getCustomer = (
  params: GraphQL.GetCustomerQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCustomerStatus("loading"))

  const result = await API.fetchCustomer(params)

  dispatch(setCustomerStatus(result))
}

export const getTeamMembers = (
  params: GraphQL.SearchTeamMembersForCustomerQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setTeamMembers("loading"))

  const result = await API.searchTeamMembersForCustomer(params)

  dispatch(setTeamMembers(result))
  if (API.isSuccess(result)) {
    dispatch(setTeamMembersContent(result.payload.searchUsers.rows))
  }
}

export const getMoreTeamMembers = (
  params: GraphQL.SearchTeamMembersForCustomerQueryVariables,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const {
    customer: {
      teamMembers: currentTeamMembersStatus,
      teamMembersContent: currentTeamMembersContent,
    },
  } = getState()
  const result = await API.searchTeamMembersForCustomer(params)
  if (API.isSuccess(currentTeamMembersStatus) && API.isSuccess(result)) {
    dispatch(setTeamMembersContent([ ...currentTeamMembersContent, ...result.payload.searchUsers.rows ]))
  } else {
    dispatch(setTeamMembers({ status: "error", message: "Something went wrong with the request!" }))
  }
}

export const getTeamMembersByTitle = (
  params: GraphQL.SearchTeamMembersForCustomerByTitleQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setTeamMembersByTitle("loading"))

  const result = await API.searchTeamMembersByTitleForCustomer(params)

  dispatch(setTeamMembersByTitle(result))
  if (API.isSuccess(result)) {
    dispatch(setTeamMembersContent(result.payload.searchUserByTitle.rows))
  }
}

export const getMoreTeamMembersByTitle = (
  params: GraphQL.SearchTeamMembersForCustomerByTitleQueryVariables,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const {
    customer: {
      teamMembersByTitle: currentTeamMembersStatus,
      teamMembersContent: currentTeamMembersContent,
    },
  } = getState()
  const result = await API.searchTeamMembersByTitleForCustomer(params)
  if (API.isSuccess(currentTeamMembersStatus) && API.isSuccess(result)) {
    dispatch(setTeamMembersContent([ ...currentTeamMembersContent, ...result.payload.searchUserByTitle.rows ]))
  } else {
    dispatch(setTeamMembersByTitle({ status: "error", message: "Something went wrong with the request!" }))
  }
}

export const toggleTeamMemberColumnSort = (
  selectedColumn: GraphQL.TeamMemberSortColumn,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const { customer: { teamMembersColumnn: columnSort, teamMembersDirection: sortDirection } } = getState()

  if (columnSort === selectedColumn) {
    if (sortDirection === GraphQL.SortDirection.Asc) {
      dispatch(setTeamMembersDirection(GraphQL.SortDirection.Desc))
    } else {
      dispatch(setTeamMembersDirection(GraphQL.SortDirection.Asc))
    }
  } else {
    dispatch(setTeamMembersColumnn(selectedColumn))
    dispatch(setTeamMembersDirection(GraphQL.SortDirection.Desc))
  }
}

export const toggleTeamMemberByTitleColumnSort = (
  selectedColumn: GraphQL.SearchUserByTitleSortColumn,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const { customer: { teamMembersByTitleColumn: columnSort, teamMembersDirection: sortDirection } } = getState()

  if (columnSort === selectedColumn) {
    if (sortDirection === GraphQL.SortDirection.Asc) {
      dispatch(setTeamMembersDirection(GraphQL.SortDirection.Desc))
    } else {
      dispatch(setTeamMembersDirection(GraphQL.SortDirection.Asc))
    }
  } else {
    dispatch(setTeamMembersByTitleColumn(selectedColumn))
    dispatch(setTeamMembersDirection(GraphQL.SortDirection.Desc))
  }
}

export const resetAllFields = () => (dispatch: Dispatch) => {
  dispatch(setTeamMembers("init"))
  dispatch(setTeamMembersByTitle("init"))
  dispatch(setTeamMembersContent([]))
  dispatch(setTeamMembersColumnn(GraphQL.TeamMemberSortColumn.Name))
  dispatch(setTeamMembersByTitleColumn(GraphQL.SearchUserByTitleSortColumn.ContactName))
  dispatch(setTeamMembersDirection(GraphQL.SortDirection.Asc))
  dispatch(setTeamMembersSearchToggle("name"))
  dispatch(setTeamMembersSearchInput(""))
  dispatch(setTeamMembersFilterValue(defaultFilterValue))
}

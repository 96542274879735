import React from "react"
import { Badge as MuiBadge, BadgeProps } from "@mui/material"
import "./style.sass"

const classes = [ "cp-badge-component" ]

function Badge(props: BadgeProps): React.JSX.Element {
  const classNamesProp = props.className?.split(" ") || []
  const classNames = classes.concat(classNamesProp).join(" ")

  return (
    <MuiBadge
      { ...props }
      className={ classNames }
    />
  )
}

export default Badge

import React from "react"
import CampaignsSlidingPanel from "./CampaignsSlidingPanel"
import ListsSlidingPanel from "./ListsSlidingPanel"
import CommunicationsSlidingPanel from "./CommunicationsPanel/CommunicationsSlidingPanel"
import ProfileSlidingPanel from "./ProfileSlidingPanel"
import CustomersSlidingPanel from "./CustomersSlidingPanel"
import "./style.sass"
import { useDispatch, useSelector } from "../../../state/hooks"
import { PanelState, togglePanel } from "../../../state/slidingPanelSlice"
import SocialTrackerPanel from "./SocialTrackerPanel"

function SlidingPanels() {
  const panelOpen = useSelector(({ slidingPanels }) => slidingPanels.panelOpen)
  const dispatch = useDispatch()

  const onClose = () => dispatch(togglePanel(PanelState.CLOSED))

  return (
    <div className="cp_component_sliding-panels">
      <CampaignsSlidingPanel
        open={ panelOpen === PanelState.CAMPAIGNS }
        onClose={ onClose }
      />
      <ListsSlidingPanel
        open={ panelOpen === PanelState.LISTS }
        onClose={ onClose }
      />
      <CommunicationsSlidingPanel
        open={ panelOpen === PanelState.COMMUNICATIONS }
        onClose={ onClose }
      />
      <SocialTrackerPanel
        open={ panelOpen === PanelState.SOCIAL_TRACKER }
        onClose={ onClose }
      />
      <ProfileSlidingPanel
        open={ panelOpen === PanelState.PROFILES }
        onClose={ onClose }
      />
      <CustomersSlidingPanel
        open={ panelOpen === PanelState.CUSTOMERS }
        onClose={ onClose }
      />
    </div>
  )
}

export default SlidingPanels

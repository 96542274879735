import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "../../state/hooks"
import Modal from "../Modal"
import { closeConfirmationModal } from "../../state/confirmationModalSlice"
import "./style.sass"

export default function ConfirmationModal() {
  const dispatch = useDispatch()
  const { t: translate } = useTranslation([], { keyPrefix: "common" })
  const {
    confirmationModalOpen: open,
    onConfirm,
    subtext,
    title,
    subtitle,
    primaryLabel,
    secondaryLabel,
  } = useSelector(({ confirmationModal }) => confirmationModal)

  const closeAction = () => dispatch(closeConfirmationModal())

  return (
    <Modal
      className="cp_confirmation-modal_component"
      open={ open }
      primaryAction={ () => {
        if (onConfirm) onConfirm()
        closeAction()
      } }
      title={ title }
      subtitle={ subtitle }
      closeAction={ closeAction }
      secondaryAction={ closeAction }
      primaryLabel={ primaryLabel || translate("Confirm") }
      secondaryLabel={ secondaryLabel || translate("Cancel") }
    >
      <p>{ subtext }</p>
    </Modal>
  )
}

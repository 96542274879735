import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import {
  ButtonGroup,
  ClickAwayListener,
  Tooltip,
} from "@mui/material"
import Button from "../Button"
import Avatar from "../Avatar"

import "./style.sass"

// Lifted from MUI
type Placement = "bottom" | "left" | "right" | "top" | "bottom-end" |
"bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" |
"top-end" | "top-start"

interface DropdownMenuProps {
  avatarUrl?: string,
  buttonEndIcon?: React.ReactNode,
  buttonTitle?: string,
  buttonType: "avatar" | "custom" | "split",
  children: JSX.Element,
  customButtonChildren?: JSX.Element,
  placement?: Placement
  customAnchorEl?: null | HTMLElement
  setCustomAnchorEl?: (el: HTMLElement | null) => void
  disablePortal?: boolean
  id?: string
}

export default function Dropdown({
  avatarUrl,
  buttonEndIcon,
  buttonTitle,
  buttonType,
  children,
  customButtonChildren,
  placement,
  customAnchorEl,
  setCustomAnchorEl,
  disablePortal = true,
  id,
}: DropdownMenuProps): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.Dropdown" })

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const open = setCustomAnchorEl ? Boolean(customAnchorEl) : Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (setCustomAnchorEl) {
      setCustomAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    if (setCustomAnchorEl) {
      setCustomAnchorEl(null)
    } else {
      setAnchorEl(null)
    }
  }

  return (
    <Tooltip
      arrow={ true }
      placement={ placement || "bottom-end" }
      PopperProps={ {
        disablePortal,
      } }
      open={ open }
      disableFocusListener={ true }
      disableHoverListener={ true }
      disableTouchListener={ true }
      title={ (
        <ClickAwayListener
          onClickAway={ handleClose }
        >
          { children }
        </ClickAwayListener>
      ) }
    >
      <div id={ id } className="cp_component_dropdown">
        { buttonType === "avatar" && (
          <button type="button" onClick={ handleClick } aria-label={ translate("Avatar button") }>
            <Avatar src={ avatarUrl } />
          </button>
        ) }
        { buttonType === "custom" && (
          <button type="button" onClick={ handleClick }>
            { customButtonChildren }
          </button>
        ) }
        { buttonType === "split" && (
        <ButtonGroup variant="contained" aria-label="split button">
          <Button
            isEnabled={ true }
            isPrimary={ true }
            label={ buttonTitle }
            aria-controls={ open ? "demo-customized-menu" : undefined }
            aria-haspopup="true"
            aria-expanded={ open ? "true" : undefined }
            variant="contained"
            disableElevation={ true }
            onClick={ handleClick }
          />
          <Button
            size="small"
            isEnabled={ true }
            isPrimary={ true }
            aria-controls={ open ? "split-button-menu" : undefined }
            aria-expanded={ open ? "true" : undefined }
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={ handleClick }
            label={ buttonEndIcon || <ArrowDropDownIcon /> }
            className="dropdown_icon"
          />
        </ButtonGroup>
        ) }
      </div>
    </Tooltip>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import { TFunction } from "i18next"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import { pushToast } from "../toastSlice"
import { RootState } from "../store"

// List Group Slice Interface and Initial State
export interface ListsGroupState {
  listGroup: Status<GraphQL.SearchListGroupByIdQuery>
  copyLinkModalOpen: boolean
  archiveModalOpen: boolean
  isArchiving: boolean

}

const initialState: ListsGroupState = {
  listGroup: "init",
  copyLinkModalOpen: false,
  archiveModalOpen: false,
  isArchiving: false,
}

// List Group Slice
export const ListSlice = createSlice({
  name: "ListGroup",
  initialState,
  reducers: {
    setListGroup: (
      state,
      action: PayloadAction<Status<GraphQL.SearchListGroupByIdQuery>>,
    ) => ({
      ...state,
      listGroup: action.payload,
    }),
    setCopyLinkModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      copyLinkModalOpen: action.payload,
    }),
    setArchiveModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      archiveModalOpen: action.payload,
    }),
    setIsArchiving: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isArchiving: action.payload,
    }),
  },
})

export const {
  setListGroup, setCopyLinkModalOpen, setArchiveModalOpen, setIsArchiving,
} = ListSlice.actions
export default ListSlice.reducer

// List Group Slice Thunks
export const fetchListGroup = (
  id: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setListGroup("loading"))
  const listResultGroup = await API.fetchListGroupById({ id })
  dispatch(setListGroup(listResultGroup))
}

export const refreshListGroup = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const {
    listGroup: { listGroup },
  } = getState()
  if (!API.isSuccess(listGroup)) return

  const {
    id,
  } = listGroup.payload.suggestionListGroupById

  dispatch(setListGroup("loading"))
  const newListGroup = await API.fetchListGroupById({ id })
  dispatch(setListGroup(newListGroup))
}

export const submitArchiveSuggestionListGroup = (
  id: string,
  translate: TFunction,
) => async (dispatch: Dispatch) => {
  dispatch(setIsArchiving(true))
  dispatch(setArchiveModalOpen(false))
  const result = await API.archiveSuggestionListGroup({ suggestionListGroupArchiveId: id })

  if (API.isSuccess(result)) {
    pushToast({
      type: "success",
      message: translate("Archive Group"),
      additionalInfo: translate("The selected list group is successfully archived!"),
    })(dispatch)
    fetchListGroup(id)(dispatch)
  } else {
    pushToast({
      type: "error",
      message: translate("There was an error. Please try again!"),
    })(dispatch)
  }
  dispatch(setIsArchiving(false))
}

export const submitUnarchiveSuggestionListGroup = (
  id: string,
  translate: TFunction,
) => async (dispatch: Dispatch) => {
  dispatch(setIsArchiving(true))
  dispatch(setArchiveModalOpen(false))
  const result = await API.unarchiveSuggestionListGroup({ suggestionListGroupUnArchiveId: id })
  if (API.isSuccess(result)) {
    pushToast({
      type: "success",
      message: translate("Archive Group"),
      additionalInfo: translate("The selected list group is successfully unarchived!"),
    })(dispatch)

    fetchListGroup(id)(dispatch)
  } else {
    pushToast({
      type: "error",
      message: translate("There was an error. Please try again!"),
    })(dispatch)
  }

  dispatch(setIsArchiving(false))
}

export const removeSuggestionListFromGroup = (
  params: {
    variables: GraphQL.SuggestionListRemoveFromGroupMutationVariables
    onSuccess: () => void
  },
) => async (dispatch: Dispatch) => {
  const result = await API.removeSuggestionListFromGroup(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    dispatch(setListGroup({ status: "error" }))
  }
}

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

import "./style.sass"
import "./add-account-list.sass"

import { useSelector, useDispatch } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../util/searchHelper"
import SearchFilter from "../SearchFilter"
import SearchFilterTabs from "../SearchFilterTabs"
import Button from "../Button"
import SearchResults from "../SearchResults"

import { fetchSearchResults, setSearchInput } from "../../state/searchSlice"

export default function AddAccountToList() {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Search" })
  const fetchedList = useSelector(({ list }) => list.list)
  let listNetwork: GraphQL.Network
  if (API.isSuccess(fetchedList)) {
    listNetwork = fetchedList.payload.suggestionListById.network
  }

  const dispatch = useDispatch()
  const searchInput = useSelector(({ search }) => search.searchInput)
  const [ searchParams, setSearchParams ] = useSearchParams()

  const networkUpdate = () => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    newInput.socialNetworks = [ listNetwork ]
    dispatch(setSearchInput(newInput))
    SearchHelper.setSearchInputQueryParams(
      newInput,
      searchParams,
      setSearchParams,
    )
  }

  useEffect(() => {
    networkUpdate()
    dispatch(fetchSearchResults(true))
  }, [])

  return (
    <div className="cp_list_page-list-add-account-search cp_page_search">
      <div className="cp_page_search-container">
        <section className="cp_page_search-form">
          <SearchFilter excludeSection="network" />
          <SearchFilterTabs />
          <aside className="cp_page_search-button">
            <Button
              isEnabled={ true }
              isPrimary={ true }
              label={ translate("Search") }
              onClick={ () => dispatch(fetchSearchResults(true)) }
            />
          </aside>
        </section>
        <section className="cp_page_search-results">
          <SearchResults
            mountContext="list"
            contentContextMenu={ {
              showAddPostButton: true,
              showViewMatchingPostsButton: true,
              showViewProfileButton: true,
            } }
          />
        </section>
      </div>
    </div>
  )
}

/* eslint-disable max-len */
export default {
  TIN: {
    APP: {
      fullStoryOrgId: "o-1Z6XC3-na1",
      graphqlSlug: "/api/graphql",
      loginSlug: "/api/login",
      muiLicenseKey: "44e44999d756b9eb5799d5088e238f86Tz04MTk2OSxFPTE3MzY1NDc1MjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
      platformSignature: "react_v1",
    },
    PRIMM: {
      authCookieName: "x-primm-token",
      url: "http://localhost:49019",
    },
  },
}

import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicator from "../../../LoadingIndicator"
import RecentMedia from "../../AccountInsights/RecentMedia"

export default function RecentMediaWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { list, selectedListSocialAccount } = useSelector(({ publicList }) => publicList)
  if (list === "loading" || list === "init" || !selectedListSocialAccount) {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (list.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <RecentMedia socialProfile={ selectedListSocialAccount.socialAccount } />
  )
}

import { Dispatch } from "redux"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import {
  CampaignState,
  initialState as campaignsInitialState,
  reducers as campaignReducers,
} from "./campaigns"

export type DashboardSliceState = {
  personalitySocialAccountCount: Status<GraphQL.GetPersonalitySocialAccountCountByCustomerQuery>
} & CampaignState

// Sliding panel slice Interface and Initial State
const initialState: DashboardSliceState = {
  personalitySocialAccountCount: "init",
  ...campaignsInitialState,
}

export type DashboardState = CampaignState

// Profile Slice
export const dashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  reducers: {
    setPersonalitySocialAccountCount: (
      state,
      action: PayloadAction<Status<GraphQL.GetPersonalitySocialAccountCountByCustomerQuery>>,
    ) => ({
      ...state,
      personalitySocialAccountCount: action.payload,
    }),
    ...campaignReducers,
  },
})

export const {
  setPersonalitySocialAccountCount,
  setCampaignStatus,
  setCampaignsContent,
  setCampaignSearchInput,
  setCampaignSearchFilter,
  setFetchingCampaignId,
} = dashboardSlice.actions

export const fetchPersonalitySocialAccountCount = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setPersonalitySocialAccountCount("loading"))
  const result = await API.fetchPersonalitySocialAccountCountByCustomer()
  dispatch(setPersonalitySocialAccountCount(result))
}

export default dashboardSlice.reducer

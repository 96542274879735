import React, { useEffect, useState } from "react"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded"
import WebAssetIcon from "@mui/icons-material/WebAsset"
import { Collapse, MenuItem } from "@mui/material"
import { PendingActions } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import Avatar from "../Avatar"
import Button from "../Button"
import Divider from "../Divider"
import IconButton from "../IconButton"
import ListProfileScore from "./ListProfileScore"
import LoadingIndicator from "../LoadingIndicator"
import ModalContactInformation from "./ModalContactInformation"
import Select from "../Select"
import StatusDot from "../StatusDot"
import Tags from "./Tags"
import { Scope } from "../../util/types"
import { pushBreadcrumb, removeBreadcrumb } from "../../state/breadcrumbSlice"
import { setPrimaryEmail } from "../../state/socialProfileSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export function ListProfileOverview(): React.JSX.Element {
  const [ collapsed, setCollapsed ] = useState(false)
  const [ openContactInformation, setOpenContactInformation ] = useState(false)
  const [ isLoading, setIsLoading ] = React.useState(false)
  const { listProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const { scopes } = useSelector(({ user }) => user)

  const { vanity } = useParams()

  const dispatch = useDispatch()

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ProfileOverview" })

  const showContactInfo = React.useMemo(() => scopes.includes(Scope.SOCIAL_ACCOUNT_CONTACT_INFO), [ scopes ])

  useEffect(() => {
    if (API.isSuccess(listProfile) && vanity) {
      const list = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.suggestionList
      const breadcrumb = {
        header: list.name,
        urlpath: `/${ vanity }/lists/${ list.id }`,
      }
      dispatch(pushBreadcrumb(breadcrumb))
      return () => {
        dispatch(removeBreadcrumb(breadcrumb))
      }
    }
    return () => {}
  }, [ listProfile ])

  if (listProfile === "loading" || listProfile === "init") {
    return (
      <div className="cp_profile-overview_component_loading">
        <LoadingIndicator size={ 30 } />
      </div>
    )
  }

  if (listProfile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  const {
    verticals,
    bio: listSocialAccountBio,
    socialAccount: {
      id,
      bio: socialAccountBio,
      emails,
      emailsSourcedFromTeam,
      profilePictureUrl,
      primaryEmail,
      userName,
      websiteUrl,
      isPlaceholder,
    },
  } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId

  const emailOptions = emails.map((
    { address },
  ) => <MenuItem disabled={ address === primaryEmail?.address } key={ address } value={ address }>{ address }</MenuItem>)
    .concat(
      emailsSourcedFromTeam
        .map((
          { address },
        ) => <MenuItem disabled={ address === primaryEmail?.address } key={ address } value={ address }>{ address }</MenuItem>),
    )

  const setPrimaryEmailHandler = async (email: string) => {
    setIsLoading(true)

    await dispatch(setPrimaryEmail({
      networkAccountId: id,
      email,
    }))

    setIsLoading(false)
  }

  return (
    <div className="cp_profile-overview_component">
      <div className="cp_profile-overview_component-avatar">
        <div className="cp_profile-overview_component-avatar-wrapper">
          { !isPlaceholder
            ? <Avatar src={ profilePictureUrl } className="cp_profile-overview_component-avatar-image" />
            : <PendingActions className="cp_profile-overview-placeholder-icon" /> }
          <p className="cp_profile-overview_component-avatar-name">
            { userName }
          </p>
          <p className="cp_profile-overview_component-avatar-email">
            { primaryEmail?.address || translate("No Primary Email") }
          </p>
        </div>
      </div>
      <Divider />
      <ListProfileScore
        listProfile={ listProfile }
      />
      <Divider />
      <div className="cp_profile-overview_component-bio-wrapper">
        <p className="label_small-caps-semibold cp_profile-overview_component-bio-heading">
          { translate("Account Biography") }
        </p>
        <p className="label_small-caps-semibold cp_profile-overview_component-bio-body">
          { listSocialAccountBio || socialAccountBio || translate("Biography Not Available") }
        </p>
      </div>
      { verticals.length > 0 && (
      <>
        <Divider />
        <Tags verticals={ verticals } />
      </>
      )
      }
      { showContactInfo ? (
        <>
          <Divider />
          <div className="cp_profile-overview_component-contact-wrapper">
            <p className="label_small-caps-semibold cp_profile-overview_component-contact-heading">
              { translate("Contact Information") }
              <IconButton onClick={ () => setCollapsed(!collapsed) }>
                { collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
              </IconButton>
            </p>
            <Collapse in={ !collapsed }>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <WebAssetIcon />
                { translate("Websites from Public Profile") }
              </p>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-website">
                { websiteUrl }
              </p>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <MailOutlineRoundedIcon />
                { translate("Emails From Public Profile") }
              </p>
              { emails.map(({ address }) => (
                <p key={ address } className="label_small-caps-semibold cp_profile-overview_component-contact-email">
                  { address }
                </p>
              )) }
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <MailOutlineRoundedIcon />
                { translate("Emails Added By Your Team") }
                <Button
                  className="cp_btn_component_highlight"
                  onClick={ () => setOpenContactInformation(true) }
                  type="button"
                  variant="text"
                  isPrimary={ false }
                  isEnabled={ true }
                  label={ translate("Edit") }
                  size="small"
                />
              </p>
              { emailsSourcedFromTeam.map(({ address }) => (
                <div key={ address } className="cp_profile-overview_component-contact-email-wrapper">
                  <p className="label_small-caps-semibold cp_profile-overview_component-contact-email">
                    { address }
                  </p>
                  <p className="label_small-caps-semibold cp_profile-overview_component-email_status">
                    { primaryEmail?.address === address && <StatusDot status="support" size="sm" /> }
                    { primaryEmail?.address === address && translate("PRIMARY") }
                  </p>
                </div>
              )) }
              <p className="label_small-caps-semibold cp_profile-overview_component-email-select">
                { translate("Choose a primary Email Address") }
              </p>
              <Select
                disabled={ isLoading }
                value={ primaryEmail }
                fullWidth={ true }
                id="cp_profile-overview_select-email"
                label=""
                labelId=""
                menuItems={ emailOptions }
                onChange={ (e) => setPrimaryEmailHandler(e.target.value as string) }
                IconComponent={ isLoading ? LoadingIndicator : undefined }
              />
            </Collapse>
          </div>
        </>
      ) : null }
      <ModalContactInformation
        open={ openContactInformation }
        closeAction={ () => setOpenContactInformation(false) }
        secondaryAction={ () => setOpenContactInformation(false) }
      />
    </div>
  )
}

export default ListProfileOverview

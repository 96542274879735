import React, { useEffect } from "react"

import Modal from "../Modal"
import ScoreBreakDown from "../ScoreBreakDown"

import { fetchHistogramsData } from "../../state/scoreBreakDownSlice"
import { useSelector, useDispatch } from "../../state/hooks"

import "./style.sass"

interface Props {
  isModalScoreOpen: boolean;
  closeModal: () => void
}

export default function ModalScoreBreakDown({ isModalScoreOpen, closeModal }: Props) {
  const { histograms } = useSelector(({ scoreBreakDown }) => scoreBreakDown)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!histograms.length) {
      dispatch(fetchHistogramsData())
    }
  }, [])

  return (
    <Modal
      open={ isModalScoreOpen }
      closeAction={ closeModal }
      hasFooter={ false }
      customTitle={ <p /> }
      maxWidth="xl"
      className="cp_component_modal-engagement-score"
    >
      <ScoreBreakDown />
    </Modal>
  )
}

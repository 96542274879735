import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import Pill from "../Pill"
import { Network } from "../../graphql"

import "./style.sass"

type Props = {
  isReel: boolean,
  isStory: boolean,
  /**
   * This is a property specific to the Snap Network. If it is
   * a spotlight post, it will be highlighted.
  */
  isHighlighted: boolean,
  network: Network,
  showInFeedPill: boolean,
}

export default function PostType({
  isReel,
  isStory,
  network,
  isHighlighted,
  showInFeedPill,
}: Props): JSX.Element | null {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.PostType" })

  if (network === Network.Snapchat) {
    if (isHighlighted) {
      return (
        <Pill
          color="warning"
          label={ translate("Spotlight") }
          handleClick={ () => {} }
        />
      )
    }
    if (isStory) {
      return (
        <div className="cp_component_post-type-cell">
          <Pill
            color="info"
            label={ translate("Story") }
            handleClick={ () => {} }
          />
          <Pill
            color="warning"
            label={ translate("Snap") }
            handleClick={ () => {} }
          />
        </div>
      )
    }
    return (
      <Pill
        color="warning"
        label={ translate("Snap") }
        handleClick={ () => {} }
      />
    )
  }

  if (isReel) {
    return (
      <Pill
        color="warning"
        label={ translate("Reel") }
        handleClick={ () => {} }
      />
    )
  }

  if (isStory) {
    return (
      <Pill
        color="info"
        label={ translate("Story") }
        handleClick={ () => {} }
      />
    )
  }

  if (showInFeedPill) {
    return <Pill label={ translate("In-Feed") } handleClick={ () => {} } />
  }

  return null
}

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"

// List Group Slice Interface and Initial State
export interface PublicListsGroupState {
  listGroup: Status<GraphQL.FetchPublicListGroupByCodeQuery>

}

const initialState: PublicListsGroupState = {
  listGroup: "init",
}

// List Group Slice
export const ListSlice = createSlice({
  name: "ListGroup",
  initialState,
  reducers: {
    setListGroup: (
      state,
      action: PayloadAction<Status<GraphQL.FetchPublicListGroupByCodeQuery>>,
    ) => ({
      ...state,
      listGroup: action.payload,
    }),
  },
})

export const {
  setListGroup,
} = ListSlice.actions
export default ListSlice.reducer

// List Group Slice Thunks
export const fetchPublicListGroup = (
  code: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setListGroup("loading"))
  const listResultGroup = await API.fetchPublicListGroupByCode({ code })
  dispatch(setListGroup(listResultGroup))
}

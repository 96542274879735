import React, { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import Modal from "../../../Modal"
import * as GraphQL from "../../../../graphql"
import LoadingIndicator from "../../../LoadingIndicator"
import * as API from "../../../../util/apiClient"
import "../../AccountInsights/ModalBrandDetails/style.sass"
import MentionedPostCard from "../../AccountInsights/ModalBrandDetails/MentionedPostCard"
import ModalHeader from "../../AccountInsights/ModalBrandDetails/ModalHeader"
import { Status } from "../../../../util/types"

export type FetchMentionedPostParams = {
  socialAccountId: string
  socialProfileId: string
  mentionType: GraphQL.MentionType
  page: number
}

type Props = {
    open: boolean
    onClose: () => void
    mention: GraphQL.MentionFragment | undefined
    mentionType: GraphQL.MentionType
    mentionedPostsStatus: Status<GraphQL.GetPublicMentionedPostsQuery>
    mentionedPostsContent: Array<GraphQL.MentionedPostFragment>
    getMentionedPosts: (params: GraphQL.GetPublicMentionedPostsQueryVariables) => void
    getMoreMentionedPosts: (params: GraphQL.GetPublicMentionedPostsQueryVariables) => void
    socialProfile: GraphQL.SocialProfileFragment
}

export default function PublicModalBrandDetails({
  open,
  onClose,
  mention,
  mentionType,
  mentionedPostsContent,
  mentionedPostsStatus,
  getMentionedPosts,
  getMoreMentionedPosts,
  socialProfile,
}: Props) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalBrandDetails" })

  const [ page, setPage ] = useState(1)
  const { listCode } = useParams()

  useEffect(() => {
    setPage(1)
    if (mention && listCode) {
      getMentionedPosts(
        {
          suggestionListCode: listCode,
          mentionAccountId: mention.socialAccount.id,
          networkAccountId: socialProfile.id,
          type: mentionType,
          page: 1,
          limit: 50,
        },
      )
    }
  }, [ mention, mentionType ])

  let content
  let customTitle

  if (mentionedPostsStatus === "init"
  || mentionedPostsStatus === "loading"
  || !mention) {
    content = (
      <div className="cp_component_modal-loading-box">
        <LoadingIndicator size={ 50 } />
      </div>
    )
  } else if (API.isError(mentionedPostsStatus)) {
    content = <p>error!</p>
  } else {
    if (mentionType === GraphQL.MentionType.Brand) {
      customTitle = (
        <ModalHeader
          avatarUrl={ mention.socialAccount.profilePictureUrl }
          title={ `${ mention.timesMentioned } ${ translate("Mentions of") } ${ mention.socialAccount.userName }` }
          subTitle={ (
            <p>
              { translate("Posts by") }
              <b>{ ` @${ socialProfile.userName } ` }</b>
              { translate("mentioning") }
              { ` ${ mention.socialAccount.userName }.` }
            </p>
          ) }
        />
      )
    } else if (mentionType === GraphQL.MentionType.BrandWorkedWith) {
      customTitle = (
        <ModalHeader
          avatarUrl={ mention.socialAccount.profilePictureUrl }
          title={ `${ mention.timesMentioned } ${ translate("Sponsored Posts for") } ${ mention.socialAccount.userName }` }
          subTitle={ (
            <p>
              { translate("Posts by") }
              <b>{ ` @${ socialProfile.userName } ` }</b>
              { translate("that may have been sponsored by") }
              { ` ${ mention.socialAccount.userName }.` }
            </p>
) }
        />
      )
    }
    content = (
      <div id="modal-brand-details-scroll-target" className="cp_component_modal-brand-details-container">
        <InfiniteScroll
          dataLength={ mentionedPostsContent.length }
          next={ () => {
            if (listCode) {
              getMoreMentionedPosts(
                {
                  mentionAccountId: mention.socialAccount.id,
                  networkAccountId: socialProfile.id,
                  suggestionListCode: listCode,
                  type: mentionType,
                  page: page + 1,
                },
              )
              setPage((prev) => prev + 1)
            }
          }
          }
          hasMore={ mentionedPostsStatus.payload.publicGetMentionedCippus.total > mentionedPostsContent.length }
          loader={ (
            <div className="cp_component_modal-loading-box">
              <LoadingIndicator />
            </div>
          )
        }
          scrollableTarget="modal-brand-details-scroll-target"
          style={ { overflow: "hidden" } }
        >
          {
            mentionedPostsContent.map((row) => (
              <MentionedPostCard mention={ row } />
            ))
        }
        </InfiniteScroll>
      </div>
    )
  }

  return (
    <Modal
      className="cp_component_modal-brand-details"
      customTitle={ customTitle }
      hasPrimaryButton={ false }
      hasSecondaryButton={ false }
      hasFooter={ false }
      open={ open }
      closeAction={ onClose }
      maxWidth="lg"
    >
      { content }
    </Modal>
  )
}

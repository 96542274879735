import React, { JSX, useEffect } from "react"
import { useTranslation } from "react-i18next"

import * as Type from "../../util/types"
import { pushToast } from "../../state/toastSlice"
import { useDispatch } from "../../state/hooks"

type Props = {
  err?: Type.Err,
  fallback?: JSX.Element,
}

export default function ErrorHandler({ err, fallback }: Props): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()

  useEffect(() => {
    const statusCode = err?.statusCode || 500
    const errMessage = err?.message || translate("An unexpected error occurred!")

    dispatch(pushToast({
      type: "error",
      message: statusCode.toString(),
      additionalInfo: errMessage,
      timeout: 10000, // Intentionally longer timeout for unexpected errors
    }))
  }, [])

  if (fallback) return fallback
  return <></>
}

import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

import "./unassigned-context-menu.sass"
import IconButton from "../../../IconButton"

interface Props {
  id?: string
}

export default function UnassignedContentContextMenu({ id }: Props) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.UnassignedContentContextMenu" })

  const onViewClick = () => {
    // Placeholder until edit modal is created
  }

  const onAssignClick = () => {
    // Placeholder until edit modal is created
  }

  const onArchiveClick = () => {
    // Placeholder until edit modal is created
  }

  return (
    <>
      <IconButton
        id={ id }
        className="cp_unassigned-content-context-menu_icon-button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_unassigned-content-context-menu_menu-component"
        transformOrigin={ {
          horizontal: "center",
          vertical: "top",
        } }
      >
        <MenuList
          dense={ true }
          className="menu-list"
        >
          <MenuItem
            className="menu-item"
            onClick={ onViewClick }
          >
            <ListItemText className="menu-item-label">
              { translate("View") }
            </ListItemText>
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={ onAssignClick }
          >
            <ListItemText className="menu-item-label">
              { translate("Assign") }
            </ListItemText>
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={ onArchiveClick }
          >
            <ListItemText className="menu-item-label">
              { translate("Archive") }
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

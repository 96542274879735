/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, ChangeEvent } from "react"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"

import Avatar from "../Avatar"

// Define the props interface
interface ImageUploaderProps {
  onAvatarSelect: (file: File) => void;
  customClasses?: string
  title: string,
  infoText: string,
  allowedTypes?: string;
}

function AvatarUploader({
  onAvatarSelect,
  customClasses,
  title,
  infoText,
  allowedTypes = "image/png, image/jpeg",
}: ImageUploaderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ selectedFile, setSelectedFile ] = useState<File | null>(null)

  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    if (file) {
      setSelectedFile(file)
      onAvatarSelect(file)
    }
  }

  return (
    <div className={ `cp_component_avatar_uploader ${ customClasses }` }>
      <label className="image-upload" htmlFor="image-upload">
        <Avatar>
          <CameraAltOutlinedIcon fontSize="inherit" />
        </Avatar>
      </label>
      <input
        accept={ allowedTypes }
        id="image-upload"
        type="file"
        style={ { display: "none" } }
        onChange={ handleFileInput }
      />
      <div className="logo-info-text">
        <h5 className="title_medium">
          { title }
        </h5>
        <p className="action-info-text">
          { infoText }
        </p>
      </div>
    </div>
  )
}

export default AvatarUploader

import React, {
  JSX,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useTranslation } from "react-i18next"

import LoadingIndicator from "../../component/LoadingIndicator"

export default function PostLogin(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.PostLogin" })

  const [ searchParams ] = useSearchParams()

  const authorizationCode = searchParams.get("code")
  const encodedStateString = searchParams.get("state")

  const initializedRef = useRef(false)

  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ error, setError ] = useState<unknown | undefined>()

  useEffect(() => {
    if (!authorizationCode || !encodedStateString) {
      return
    }

    if (initializedRef.current) {
      return
    }

    initializedRef.current = true

    fetch("/api/login-with-code", {
      method: "POST",
      body: JSON.stringify({
        code: authorizationCode,
        state: encodedStateString,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (result) => {
      if (result.ok) {
        navigate("/", { replace: true })
      } else {
        result.json().then((jsonResult: unknown) => setError(jsonResult))
      }
    })
  }, [ authorizationCode, encodedStateString, navigate ])

  if (!authorizationCode || !encodedStateString) {
    return <Navigate to="/" replace={ true } />
  }

  return (
    <div className="cp_page_login">
      <div>
        <section className="card">
          <article>
            <LoadingIndicator size={ 62 } />
          </article>

          <h1 className="headline">{ translate("Logging you in") }</h1>
        </section>
      </div>
    </div>
  )
}

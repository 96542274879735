import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useParams } from "react-router-dom"
import { CampaignCommunicationSettingType } from "../../../graphql"
import { useDispatch } from "../../../state/hooks"
import { updateNotificationSettings } from "../../../state/campaignSlice"
import "./notification-toggles.sass"
import Switch from "../../Switch"

export type CommunicationSettings = {
  deliverableCreated: CampaignCommunicationSettingType
  deliverableFeedback: CampaignCommunicationSettingType
  deliverableFinalized: CampaignCommunicationSettingType
  deliverableItemAccepted: CampaignCommunicationSettingType
  deliverableLive: CampaignCommunicationSettingType
  deliverableLiveUrl: CampaignCommunicationSettingType
  deliverableUpdated: CampaignCommunicationSettingType
}

type Props = {
  deliverableSettings: CommunicationSettings
}

export default function NotificationToggles({
  deliverableSettings,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignNotifications" })
  const dispatch = useDispatch()

  const { campaignID } = useParams()

  const [ newDeliverableSettings, setNewDeliverableSettings ] = useState<CommunicationSettings>(deliverableSettings)

  useEffect(() => {
    if (campaignID) {
      dispatch(updateNotificationSettings({
        variables: {
          campaignId: campaignID,
          ...newDeliverableSettings,
        },
      }))
    }
  }, [ newDeliverableSettings ])

  return (
    <div className="cp_component_campaign-notification-toggles">
      <div className="toggle-row">
        <div className="row-header">
          <h4>{ translate("Deliverable Created") }</h4>
          <p>{ translate("Notification sent when a new Deliverable has been created.") }</p>
        </div>
        <Switch
          isChecked={ newDeliverableSettings.deliverableCreated === CampaignCommunicationSettingType.Both }
          handleChange={ (e, checked) => {
            const settings = { ...newDeliverableSettings }
            if (checked) {
              settings.deliverableCreated = CampaignCommunicationSettingType.Both
            } else {
              settings.deliverableCreated = CampaignCommunicationSettingType.None
            }
            setNewDeliverableSettings(settings)
          } }
        />
      </div>

      <div className="toggle-row">
        <div className="row-header">
          <h4>{ translate("Deliverable Updated") }</h4>
          <p>{ translate("Notification sent when a Deliverable has media or caption updated.") }</p>
        </div>
        <Switch
          isChecked={ newDeliverableSettings.deliverableUpdated === CampaignCommunicationSettingType.Both }
          handleChange={ (e, checked) => {
            const settings = { ...newDeliverableSettings }
            if (checked) {
              settings.deliverableUpdated = CampaignCommunicationSettingType.Both
            } else {
              settings.deliverableUpdated = CampaignCommunicationSettingType.None
            }
            setNewDeliverableSettings(settings)
          } }
        />
      </div>

      <div className="toggle-row">
        <div className="row-header">
          <h4>{ translate("Deliverable Feedback") }</h4>
          <p>{ translate("Notification sent when feedback has been left on media or caption in a Deliverable.") }</p>
        </div>
        <Switch
          isChecked={ newDeliverableSettings.deliverableFeedback === CampaignCommunicationSettingType.Both }
          handleChange={ (e, checked) => {
            const settings = { ...newDeliverableSettings }
            if (checked) {
              settings.deliverableFeedback = CampaignCommunicationSettingType.Both
            } else {
              settings.deliverableFeedback = CampaignCommunicationSettingType.None
            }
            setNewDeliverableSettings(settings)
          } }
        />
      </div>

      <div className="toggle-row">
        <div className="row-header">
          <h4>{ translate("Deliverable Content Approved") }</h4>
          <p>{ translate("Notification sent when a Deliverable has media or caption approved.") }</p>
        </div>
        <Switch
          isChecked={ newDeliverableSettings.deliverableItemAccepted === CampaignCommunicationSettingType.Both }
          handleChange={ (e, checked) => {
            const settings = { ...newDeliverableSettings }
            if (checked) {
              settings.deliverableItemAccepted = CampaignCommunicationSettingType.Both
            } else {
              settings.deliverableItemAccepted = CampaignCommunicationSettingType.None
            }
            setNewDeliverableSettings(settings)
          } }
        />
      </div>

      <div className="toggle-row">
        <div className="row-header">
          <h4>{ translate("Deliverable Finalized") }</h4>
          <p>{ translate("Notification sent when a Deliverable has been finalized.") }</p>
        </div>
        <Switch
          isChecked={ newDeliverableSettings.deliverableFinalized === CampaignCommunicationSettingType.Both }
          handleChange={ (e, checked) => {
            const settings = { ...newDeliverableSettings }
            if (checked) {
              settings.deliverableFinalized = CampaignCommunicationSettingType.Both
            } else {
              settings.deliverableFinalized = CampaignCommunicationSettingType.None
            }
            setNewDeliverableSettings(settings)
          } }
        />
      </div>

      <div className="toggle-row">
        <div className="row-header">
          <h4>{ translate("Deliverable Live") }</h4>
          <p>{ translate("Notification sent when a Deliverable status changed to live.") }</p>
        </div>
        <Switch
          isChecked={ newDeliverableSettings.deliverableLive === CampaignCommunicationSettingType.Both }
          handleChange={ (e, checked) => {
            const settings = { ...newDeliverableSettings }
            if (checked) {
              settings.deliverableLive = CampaignCommunicationSettingType.Both
            } else {
              settings.deliverableLive = CampaignCommunicationSettingType.None
            }
            setNewDeliverableSettings(settings)
          } }
        />
      </div>

      <div className="toggle-row">
        <div className="row-header">
          <h4>{ translate("Deliverable Post URL Updated") }</h4>
          <p>{ translate("Notification sent when Deliverable's post URL has been updated.") }</p>
        </div>
        <Switch
          isChecked={ newDeliverableSettings.deliverableLiveUrl === CampaignCommunicationSettingType.Both }
          handleChange={ (e, checked) => {
            const settings = { ...newDeliverableSettings }
            if (checked) {
              settings.deliverableLiveUrl = CampaignCommunicationSettingType.Both
            } else {
              settings.deliverableLiveUrl = CampaignCommunicationSettingType.None
            }
            setNewDeliverableSettings(settings)
          } }
        />
      </div>
    </div>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import histogram from "highcharts/modules/histogram-bellcurve"
import { HISTOGRAM_COLORS } from "../../util/constant"

histogram(Highcharts)

interface Count {
  count: number;
  value: number;
}

interface Props {
  data: Count[];
  selected: number;
}

function ScoreHistogram({ data, selected }: Props) {
  if (!data || !data.length || selected === undefined) return null
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ScoreBreakDown" })

  // Process the data
  const processedData = data.map((dataPoint) => ({
    x: dataPoint.value,
    y: dataPoint.count,
    color: dataPoint.value === Math.floor(selected) ? HISTOGRAM_COLORS.selected : HISTOGRAM_COLORS.default,
  }))

  const options: Highcharts.Options = {
    chart: {
      type: "column", // Use column type to easily customize individual columns
    },
    title: {
      text: translate("Percentile 100%"),
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      title: { text: translate("Score") },
      alignTicks: false,
    },
    yAxis: {
      title: { text: translate("Frequency") },
    },
    series: [
      {
        name: "Histogram",
        data: processedData,
        type: "column", // Ensure each unique value has its own bin
      },
    ],
    credits: {
      enabled: false,
    },
  }

  return <HighchartsReact highcharts={ Highcharts } options={ options } />
}

export default ScoreHistogram

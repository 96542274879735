import React from "react"
import "./network-icons.sass"
import { Network } from "../../../../graphql"
import NetworkIcon from "../../../NetworkIcon"

type Props = {
  availableNetworks: Network[]
}

const defaultNetworkOrder = [
  Network.Instagram,
  Network.Tiktok,
  Network.Facebook,
  Network.Snapchat,
  Network.Youtube,
]

export default function NetworkIcons({ availableNetworks }: Props) {
  return (
    <div className="cp_component_social-tracker-network-icons">
      { defaultNetworkOrder.map((network) => availableNetworks.includes(network)
        ? <NetworkIcon additionalClasses="social-tracker-network-icon" isActive={ true } network={ network } />
        : null) }
    </div>
  )
}

/* eslint-disable react/no-this-in-sfc */
import React from "react"
import HighchartsReact from "highcharts-react-official"
import { ListItemText, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import Highcharts, {
  Options,
  PointOptionsObject,
  TooltipFormatterContextObject,
} from "highcharts/highmaps"

import * as GraphQL from "../../../graphql"
import Divider from "../../Divider"
import Select from "../../Select"
import timezones from "../../../util/timezoneOffsets"
import { EngagementMapColors } from "../../../util/constant"
import { HourlyEngagementSummary } from "../../../graphql"
import { prettyPrintDecimal } from "../../../util/miscHelper"
import { useSelector } from "../../../state/hooks"

import "./engagement-map.sass"

const baseOptions: Options = {
  title: {
    text: "",
  },
  legend: {
    align: "right",
    layout: "vertical",
    // y: -80,
  },
  credits: {
    enabled: false,
  },
}

const xCategories = [
  "12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM",
  "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM",
]

type Props = {
  dailyEngagementRate: GraphQL.DailyHourlyEngagementSummaryFragment
  timeZone: number
  setTimeZone: (timeZone: number) => void
}

export default function EngagementMap({
  dailyEngagementRate, timeZone, setTimeZone,
}: Props) {
  const theme = useSelector(({ themeMode }) => themeMode.theme)
  const { t: translate } = useTranslation([], { keyPrefix: "component.EngagementMap" })

  const getPointOptions = (data: HourlyEngagementSummary[], day: string, y: number): PointOptionsObject[] => {
    const flattened: PointOptionsObject[] = data.map(({
      hour, avgEngagementRate, networkAvgEngagementRate,
    }) => ({
      x: hour,
      y,
      value: avgEngagementRate,
      custom: {
        day,
        hourFormat: xCategories[hour],
        networkAvgEngagementRate,
      },
    }))
    return flattened
  }

  const getOptions = () => {
    const {
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
    } = dailyEngagementRate

    const yCategories = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ].map((
      day,
    ) => translate(day))

    baseOptions.yAxis = {
      categories: yCategories,
      title: {
        text: "",
      },
      reversed: true,
    }
    baseOptions.xAxis = {
      title: {
        text: translate("Hours of the Day"),
      },
      categories: xCategories,
      labels: {
        enabled: false,
      },
    }

    baseOptions.tooltip = {
      formatter(this: TooltipFormatterContextObject) {
        return `
        <p class="cp_engagement-map_component-tooltip-day">${ this.point.options.custom?.day } @ ${
  this.point.options.custom?.hourFormat
}</p>
        <br/>
        <span>${ translate("Engagement Rate") }: </span>
        <span>${ prettyPrintDecimal(this.point.value) }%</span>
        <br/>
        <span>${ translate("Network Average") }: </span>
        <span>${ prettyPrintDecimal(this.point.options.custom?.networkAvgEngagementRate) }%</span>
        `
      },
    }

    baseOptions.series = []

    const data: PointOptionsObject[] = [
      ...getPointOptions(sunday, translate("Sunday"), 0),
      ...getPointOptions(monday, translate("Monday"), 1),
      ...getPointOptions(tuesday, translate("Tuesday"), 2),
      ...getPointOptions(wednesday, translate("Wednesday"), 3),
      ...getPointOptions(thursday, translate("Thursday"), 4),
      ...getPointOptions(friday, translate("Friday"), 5),
      ...getPointOptions(saturday, translate("Saturday"), 6),
    ]
    baseOptions.series.push({
      name: translate("Engagement Rate"),
      type: "heatmap",
      data,
      borderWidth: 1,
    })

    baseOptions.colorAxis = {
      maxColor: EngagementMapColors[theme].maxColor,
      minColor: EngagementMapColors[theme].minColor,
      stops: data.every(({ value }) => value === 0) ? [
        [ 0, EngagementMapColors[theme].minColor ],
      ] : [
        [ 0, EngagementMapColors[theme].minColor ],
        [ 0.09999, EngagementMapColors[theme].maxColor ],
        [ 1, EngagementMapColors[theme].maxStop ],
      ],
      labels: {
        enabled: true,
        formatter() {
          return typeof this.value !== "string" ? `${ prettyPrintDecimal(this.value, 1) }` : ""
        },
      },
      height: "350",
    }

    return baseOptions
  }

  const timezoneMenuItems = timezones.map(([ name, offset ]) => (
    <MenuItem
      key={ name }
      onClick={ () => setTimeZone(offset) }
      value={ offset }
      className="cp_engagement-map_component-tz-item"
    >
      <ListItemText>{ name }</ListItemText>
    </MenuItem>
  ))

  return (
    <div className="cp_engagement-map_component">
      <div className="cp_engagement-map_component-header">
        <h6 className="cp_engagement-map_component-title">
          { translate("Engagement Heat Map") }
        </h6>
        { timezoneMenuItems.length && (
        <Select
          id="select-heat-map-tz"
          label=""
          labelId="select-heat-map-tz"
          menuItems={ timezoneMenuItems }
          value={ timeZone }
          className="cp_engagement-map_component-tz"
        />
        )
}
      </div>
      <Divider />
      <div className="cp_engagement-map_component-chart">
        <HighchartsReact highcharts={ Highcharts } options={ getOptions() } />
      </div>
      <div>
        <p className="cp_engagement-map_component-footer-note">
          { translate("Note Data from the past 90 days") }
        </p>
      </div>
    </div>
  )
}

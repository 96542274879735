import React from "react"
import "./style.sass"

type Props = {
    className?: string,
    hasTag?: boolean
}

export default function InfluentialLogo({ className, hasTag }: Props) {
  const classes = [ "cp_component_navigation-bar-logo" ]
  if (hasTag) classes.push("has-logo-tag")
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")
  return (
    <div className={ newClasses } />
  )
}

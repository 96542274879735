import React, { JSX } from "react"
import { Container } from "@mui/material"
import { PendingActions } from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import Avatar from "../Avatar"
import Checkbox from "../Checkbox"
import NetworkAvatar from "../NetworkAvatar"
import NetworkIcon from "../NetworkIcon"

import "./style.sass"

type Props = {
    avatarSize?: "sm" | "md" | "lg"
    avatarSrc?: string
    checkboxDisabled?: boolean
    checkboxEnabled?: boolean
    checkboxValue?: boolean
    className?: string
    name: string
    network?: GraphQL.Network
    networkAvatarOnly?: boolean
    onClick?: () => void
    rowAdornment?: JSX.Element
    subInfo?: string
    extraSubInfo?: string
    isPlaceholder?: boolean
    id?: string
}

export default function EntityInfoRow({
  name = "",
  className,
  avatarSrc,
  avatarSize,
  rowAdornment,
  subInfo,
  extraSubInfo,
  onClick,
  checkboxEnabled = false,
  checkboxValue = false,
  checkboxDisabled = false,
  network,
  networkAvatarOnly = false,
  isPlaceholder,
  id,
}: Props) {
  const classes = [ "cp_component-entity-row-info" ]
  if (!onClick) classes.push("row-disabled")
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")

  const renderAvatar = () => {
    if (isPlaceholder) {
      return (
        <PendingActions className="cp_component-entity-pending-actions-icon" />
      )
    }
    if (!network) {
      return (
        <Avatar size={ avatarSize } src={ avatarSrc }>
          { name.charAt(0) }
        </Avatar>
      )
    }
    if (network && !networkAvatarOnly) {
      return (
        <NetworkAvatar size={ avatarSize } network={ network } src={ avatarSrc }>
          { name.charAt(0) }
        </NetworkAvatar>
      )
    }
    if (networkAvatarOnly) {
      return <NetworkIcon isActive={ true } network={ network } />
    }
    return null
  }

  return (
    <Container
      id={ id }
      className={ newClasses }
      onClick={ onClick }
    >
      { checkboxEnabled && <Checkbox disabled={ checkboxDisabled } checked={ checkboxValue } /> }
      { renderAvatar() }
      <span className="cp_component-entity-info-row-details">
        <div>
          <p className="cp_component-entity-info-row-name">
            { name }
          </p>
          { rowAdornment }
        </div>
        { subInfo && (
        <p className="cp_component-entity-info-row-sub-info">
          { subInfo }
        </p>
        ) }
        { extraSubInfo && (
          <p className="cp_component-entity-info-row-sub-info">
            { extraSubInfo }
          </p>
        ) }
      </span>
    </Container>
  )
}

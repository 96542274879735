import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Scope, Status } from "../../util/types"

// Add Accounts to Lists Modal Slice Interface and Initial State
interface ModalNewTracker {
  isModalOpen: boolean,
  socialAccounts: Status<GraphQL.SearchSocialAccountsQuery>,
  newTrack: Status<GraphQL.CreateContentAlertMutation | null>,
}

const initialState: ModalNewTracker = {
  isModalOpen: false,
  socialAccounts: "init",
  newTrack: "init",
}

// Add Accounts to Lists Modal Slice
export const ModalNewTrackerSlice = createSlice({
  name: "modalNewTracker",
  initialState,
  reducers: {
    setModalNewTrackerOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isModalOpen: action.payload,
    }),
    setSocialAccounts: (
      state,
      action: PayloadAction<Status<GraphQL.SearchSocialAccountsQuery>>,
    ) => ({
      ...state,
      socialAccounts: action.payload,
    }),
    newTrack: (
      state,
      action: PayloadAction<Status<GraphQL.CreateContentAlertMutation | null>>,
    ) => ({
      ...state,
      newTrack: action.payload,
    }),
    setNewTrackerToInitState: () => initialState,
  },
})

export const {
  setModalNewTrackerOpen,
  setSocialAccounts,
  newTrack,
  setNewTrackerToInitState,
} = ModalNewTrackerSlice.actions
export default ModalNewTrackerSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchSocialAccounts = (
  startsWith: string,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setSocialAccounts("loading"))
  const socialAccountsResult = await API.fetchSocialAccounts(startsWith)
  dispatch(setSocialAccounts(socialAccountsResult))
}

export const createNewTrack = (
  contentAlert: GraphQL.CreateContentAlertMutationVariables,
  scopes: string[],
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(newTrack("loading"))

  const sanitizedContentAlert = { ...contentAlert }
  const sanitizedSearchInput = { ...contentAlert.params }
  const sanitizedNetworks = [ ...contentAlert.params.socialNetworks ]
    .filter((n) => {
      if (scopes.includes(Scope.FEATURE_TIKTOK)) return true
      return n !== GraphQL.Network.Tiktok
    })
    .filter((n) => {
      if (scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT)) return true
      return n !== GraphQL.Network.Snapchat
    })

  sanitizedSearchInput.socialNetworks = sanitizedNetworks
  sanitizedContentAlert.params = sanitizedSearchInput

  const socialTracker = await API.createContentAlert(sanitizedContentAlert)
  dispatch(newTrack(socialTracker))
}

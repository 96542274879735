import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "./top-posts.sass"
import { Chip } from "@mui/material"
import { ChatBubbleOutlineRounded, FavoriteBorder } from "@mui/icons-material"
import { SuggestionsPostFragment } from "../../../../graphql"
import {
  postDateFormatter,
  prettyPrintDecimal,
  shorthandNumber,
} from "../../../../util/miscHelper"
import Pill from "../../../Pill"

type Props = {
  post: SuggestionsPostFragment
  selectPost: (post: SuggestionsPostFragment) => void
}

export default function Post({ post, selectPost }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.TopPosts" })
  const [ isThumbnailError, setIsThumbnailError ] = useState(false)
  return (
    <li
      key={ post.id }
      className={ `cp_list_top-post-item${ isThumbnailError ? " no-img" : "" }` }
      onClick={ () => selectPost(post) }
      tabIndex={ 0 }
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      onKeyDown={ (e) => (e.key === "Enter" || e.key === "Space") && selectPost(post) }
      aria-label="Select post"
    >
      <img
        src={ `${ post.postMedia[0]?.thumbnailUrl }?auto=format` }
        alt="Post thumbnail"
        loading="lazy"
        onError={ () => setIsThumbnailError(true) }
      />
      <div className="cp_list_top-post-item-info pif">
        <div className="cp_pif-date">
          <time>{ postDateFormatter(post.postedTime) }</time>
          { (post.isReel && !isThumbnailError) && (<Chip className="cp_pif-reel-chip" label={ translate("REEL") } />) }
        </div>
        <p className="cp_pif-content">{ post.postContent }</p>
        { (post.isReel && isThumbnailError) && (<Chip className="cp_pif-reel-chip" label={ translate("REEL") } />) }
        { !isThumbnailError && (
        <div className="cp_pif-social-data">
          <span className="cp_pif-rate">
            { post.engagementRate && (<Pill label={ `${ prettyPrintDecimal(post.engagementRate, 2) }%` } />) }
          </span>
          <span className="cp_pif-comments">
            { post.postComments && (
              <>
                <ChatBubbleOutlineRounded />
                { `${ shorthandNumber(post.postComments) }` }
              </>
            ) }
          </span>
          <span className="cp_pif-likes">
            { post.postComments && (
            <>
              <FavoriteBorder />
              { `${ shorthandNumber(post.postLikes) }` }
            </>
            ) }
          </span>
        </div>
        ) }
      </div>
    </li>
  )
}

import React from "react"
import StatusDot from "../../StatusDot"
import { NotificationStatus } from "../../../util/types"
import "./status.sass"

type Props = {
    status: NotificationStatus
    label?: string
}

export default function Status({ status, label }: Props) {
  return (
    <div className="cp_data-grid_status">
      <StatusDot
        status={ status }
      />
      { label && (
      <p className="cp_data-grid_status-label">
        { label }
      </p>
      ) }
    </div>
  )
}

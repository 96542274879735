import * as React from "react"
import CircularProgress, { CircularProgressProps as MuiProps } from "@mui/material/CircularProgress"
import "./style.sass"

export interface CircularProgressProps extends MuiProps{
  flexWrapperEnabled?: boolean
}

export default function LoadingIndicator({
  className = "",
  flexWrapperEnabled,
  ...props
}:CircularProgressProps) {
  const defaultClassNames: string[] = [ "cp_component_loading_indicator" ]
  const finalClassNames = defaultClassNames.join(" ").concat(` ${ className }`)
  if (flexWrapperEnabled) {
    return (
      <div className="cp_component_loading_indicator-flex-wrapper">
        <CircularProgress
          className={ finalClassNames }
          size={ props.size || 18 }
          { ...props }
        />
      </div>
    )
  }
  return (
    <CircularProgress
      size={ props.size || 18 }
      className={ finalClassNames }
      { ...props }
    />
  )
}

import React from "react"
import { useNavigate } from "react-router-dom"
import { Typography } from "@mui/material"

import { useSelector } from "../../state/hooks"

import "./breadcrumb-header.sass"

type Props = {
  title: React.ReactNode
}

export default function BreadcrumbHeader({ title }: Props) {
  const { breadcrumbs } = useSelector(({ breadcrumbs: breadcrumbSlice }) => breadcrumbSlice)
  const navigate = useNavigate()

  return (
    <div className="cp-component_top-nav-title">
      { breadcrumbs.map((crumb) => (
        <>
          <Typography onClick={ () => {
            navigate(crumb.urlpath, { relative: "path" })
          } }
          >
            { crumb.header }
          </Typography>
          <p>/</p>
        </>
      )) }
      <h4>
        { title }
      </h4>
    </div>
  )
}

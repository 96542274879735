import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Link,
  useNavigate,
  useParams,
} from "react-router-dom"
import ArrowBack from "@mui/icons-material/ArrowBackIos"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import Button from "../../component/Button"
import Input from "../../component/Input"
import LoadingIndicator from "../../component/LoadingIndicator"
import LoginFlowCustomer from "../../component/LoginFlowCustomer"
import LoginFlowLogo from "../../component/LoginFlowLogo"
import { fetchTeamVanityWithRedirect, setTeam } from "../../state/teamSlice"
import { login } from "../../state/userSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export default function Login(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "page.Login" })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { vanity } = useParams()
  const loginAttempt = useSelector(({ user }) => user.loginAttempt)
  const team = useSelector(({ team: t }) => t.team)

  const [ username, setUsername ] = useState<string>("")
  const [ password, setPassword ] = useState<string>("")

  const loading = !API.isSuccess(team) && !API.isError(team)

  const loginFlows = API.isSuccess(team) ? team.payload.vanity.loginFlows : undefined

  const ssoRedirectUrl = loginFlows?.ssoRedirectUrl
  const hostedUiRedirectUrl = loginFlows?.hostedUiRedirectUrl

  const legacyEnabled = !!loginFlows?.legacyEnabled
  const ssoEnabled = !!ssoRedirectUrl
  const hostedUiEnabled = !!hostedUiRedirectUrl

  const onlySsoEnabled = ssoEnabled && !legacyEnabled && !hostedUiEnabled
  const onlyHostedUiEnabled = hostedUiEnabled && !legacyEnabled && !ssoEnabled

  useEffect(() => {
    if (!API.isSuccess(team)) {
      dispatch(fetchTeamVanityWithRedirect(vanity || "", navigate))
    }
  }, [])

  useEffect(() => {
    if (!loading && onlySsoEnabled) {
      window.location.href = ssoRedirectUrl!
    }
  }, [ loading, onlySsoEnabled, ssoRedirectUrl ])

  useEffect(() => {
    if (!loading && onlyHostedUiEnabled) {
      window.location.href = hostedUiRedirectUrl!
    }
  }, [ loading, onlyHostedUiEnabled, hostedUiRedirectUrl ])

  const handleSignInWithSsoClick = () => {
    window.location.href = ssoRedirectUrl!
  }

  const handleSignInWithHostedUiClick = () => {
    window.location.href = hostedUiRedirectUrl!
  }

  return (
    <div className="cp_page_login">
      <div>
        <section className="card">
          <div className="back">
            <ArrowBack />
            <Link
              className="large"
              id="cp_page_login-enter-team-url-link"
              onClick={ () => dispatch(setTeam("init")) }
              to="/find-team"
            >
              { translate("Enter Team URL") }
            </Link>
          </div>
          <LoginFlowLogo />
          { !loading && legacyEnabled && (
            <>
              <h1 className="headline">{ translate("Welcome Back") }</h1>
              <p className="label_medium">{ translate("Welcome back! Enter your credentials below") }</p>
              <LoginFlowCustomer />
              <Input
                className="username"
                InputProps={ {
                  id: "cp_page_login-username-input",
                  inputProps: { maxLength: Constant.USERNAME_MAX_LENGTH },
                } }
                onChange={ (e) => setUsername(e.target.value) }
                onKeyDown={ (e) => {
                  if (
                    e.key === "Enter"
                    && username.length > 0
                    && password.length > 0
                    && loginAttempt !== "loading"
                  ) {
                    dispatch(login(vanity || "", username, password, navigate))
                  }
                } }
                placeholder={ translate("Email or Username") }
                value={ username }
              />
              <Input
                InputProps={ {
                  id: "cp_page_login-password-input",
                  inputProps: { maxLength: Constant.PASSWORD_MAX_LENGTH },
                } }
                onChange={ (e) => setPassword(e.target.value) }
                onKeyDown={ (e) => {
                  if (
                    e.key === "Enter"
                    && username.length > 0
                    && password.length > 0
                    && loginAttempt !== "loading"
                  ) {
                    dispatch(login(vanity || "", username, password, navigate))
                  }
                } }
                placeholder={ translate("Password") }
                type="password"
                value={ password }
              />
              { API.isError(loginAttempt) && <span>{ translate("Incorrect login information") }</span> }
              <aside>
                <Link
                  id="cp_page_login-forgot-password-link"
                  to={ `/${ vanity }/forgot-password` }
                >
                  { translate("Forgot Password") }
                </Link>
                <Link
                  id="cp_page_login-sign-in-with-email-link"
                  to={ `/${ vanity }/login/email` }
                >
                  { translate("Sign in with Email") }
                </Link>
              </aside>
              <Button
                disabled={ username.length === 0 || password.length === 0 || loginAttempt === "loading" }
                id="cp_page_login-signin-button"
                label={ loginAttempt === "loading" ? <LoadingIndicator size={ 24 } /> : translate("Sign In") }
                onClick={ () => dispatch(login(vanity || "", username, password, navigate)) }
                tabIndex={ 0 }
                type="submit"
              />
            </>
          ) }
          { !loading && ssoEnabled && !onlySsoEnabled && (
            <Button label={ translate("Sign In with SSO") } onClick={ handleSignInWithSsoClick } />
          ) }
          { !loading && hostedUiEnabled && !onlyHostedUiEnabled && (
            <Button
              label={ translate("Sign In with Username and Password") }
              onClick={ handleSignInWithHostedUiClick }
            />
          ) }
        </section>
      </div>
    </div>
  )
}

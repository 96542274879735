/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from "react"
import EditIcon from "@mui/icons-material/Edit"
import dayjs from "dayjs"
import { IconButton } from "@mui/material"
import { Share } from "@mui/icons-material"
import { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../../util/apiClient"
import * as Constant from "../../../util/constant"
import AccountsTable from "./CampaignAccountsTable"
import CampaignAds from "./CampaignAds"
import CampaignDeliverablesTab from "./CampaignDeliverablesTab"
import CampaignNotifications from "../CampaignNotifications"
import CampaignOverviewDetails from "./CampaignOverviewDetails"
import CampaignReporting from "./reporting/CampaignReporting"
import DownloadReportCsvButton from "./reporting/DownloadReportCsvButton"
import Tabs, { TabLabelProps } from "../../Tabs"
import Tooltip from "../../Tooltip"
import { Scope } from "../../../util/types"
import { fetchCampaign } from "../../../state/campaignSlice"
import { isError, isSuccess } from "../../../util/apiClient"
import { openCopyLinkModal } from "../../../state/copyLinkModalSlice"
import { openEditCampaignModal } from "../../../state/campaignModalSlice"
import { useSelector, useDispatch } from "../../../state/hooks"

import "./style.sass"

// eslint-disable-next-line no-shadow
enum TabPathsEnum {
  OVERVIEW = "overview",
  ACCOUNTS = "accounts",
  DELIVERABLES = "deliverables",
  NOTIFICATIONS = "notifications",
  ADS = "ads",
  REPORTING = "reporting",
}
interface CampaignTabLabelProps extends TabLabelProps {
  path: TabPathsEnum.OVERVIEW
  | TabPathsEnum.ACCOUNTS
  | TabPathsEnum.DELIVERABLES
  | TabPathsEnum.NOTIFICATIONS
  | TabPathsEnum.ADS
  | TabPathsEnum.REPORTING
}

export default function CampaignTabs() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignTabs" })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tabPath, adID } = useParams()
  const { campaign, campaignReport } = useSelector(({ campaignPage }) => campaignPage)
  const scopes = useSelector(({ user }) => user.scopes)
  const userIsBrandManager = scopes.includes("feature_campaign")

  const tabs: CampaignTabLabelProps[] = React.useMemo(() => {
    const t = [
      { label: translate("Overview"), path: TabPathsEnum.OVERVIEW },
      { label: translate("Accounts"), path: TabPathsEnum.ACCOUNTS },
      { label: translate("Deliverables"), path: TabPathsEnum.DELIVERABLES },
      { label: translate("Notifications"), path: TabPathsEnum.NOTIFICATIONS },
    ]
    if (scopes.includes(Scope.CAMPAIGN_REPORTING)) {
      t.push({ label: translate("Ads"), path: TabPathsEnum.ADS })
    }
    if (scopes.includes(Scope.CAMPAIGN_REPORTING)
    || (API.isSuccess(campaign) && campaign.payload.campaign.clientReportEnabled && userIsBrandManager)) {
      t.push({ label: translate("Reporting"), path: TabPathsEnum.REPORTING })
    }
    return t
  }, [ translate, scopes, campaign ])

  const setTab = (tabIndex: number) => {
    const newTabPath = tabs[tabIndex].path
    let pathPrefix = tabPath ? "../" : ""
    if (adID) pathPrefix += "../"
    navigate(`${ pathPrefix }${ newTabPath }`, { relative: "path" })
  }

  const tabContent = useMemo(() => {
    switch (tabPath) {
      case TabPathsEnum.OVERVIEW:
      case undefined:
        return null
      case TabPathsEnum.ACCOUNTS:
        return null
      case TabPathsEnum.DELIVERABLES:
        return <CampaignDeliverablesTab />
      case TabPathsEnum.NOTIFICATIONS:
        return null
      case TabPathsEnum.ADS:
        return null
      case TabPathsEnum.REPORTING:
        return null
      default:
        return <p>Error or this tab doesnt exist yet</p>
    }
  }, [ tabPath, scopes, tabs.length ])

  const tabPage = useMemo(() => {
    switch (tabPath) {
      case TabPathsEnum.OVERVIEW:
      case undefined:
        return <CampaignOverviewDetails />
      case TabPathsEnum.ACCOUNTS:
        return <AccountsTable />
      case TabPathsEnum.DELIVERABLES:
        return null
      case TabPathsEnum.NOTIFICATIONS:
        return <CampaignNotifications />
      case TabPathsEnum.ADS:
        if (scopes.includes(Scope.CAMPAIGN_REPORTING)) {
          return <CampaignAds />
        }
        return null
      case TabPathsEnum.REPORTING:
        if (
          scopes.includes(Scope.CAMPAIGN_REPORTING)
          || (API.isSuccess(campaign) && campaign.payload.campaign.clientReportEnabled && userIsBrandManager)
        ) {
          return <CampaignReporting />
        }
        return null
      default:
        return <p>Error or this tab doesnt exist yet</p>
    }
  }, [ tabPath, scopes, tabs.length ])

  const tabIndex = useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === tabPath)
    const index = foundTab ? tabs.indexOf(foundTab) : 0
    return index
  }, [ tabPath, tabs.length, scopes ])

  const createdDate = useMemo(() => {
    if (campaign === "init" || campaign === "loading" || isError(campaign)) return ""
    return dayjs(campaign.payload.campaign.created * 1000).format(Constant.LONGFORM_DATE)
  }, [ campaign ])

  const editCampaignHandler = () => {
    if (campaign === "init" || campaign === "loading" || isError(campaign)) return
    dispatch(openEditCampaignModal({
      campaign: campaign.payload.campaign,
      updateCallback: () => dispatch(fetchCampaign({ campaignId: campaign.payload.campaign.id })),
    }))
  }

  const [ shareReportEnabled, onShareClick ] = useMemo(() => {
    if (campaign === "init" || campaign === "loading" || isError(campaign)) return [ false, () => {} ]
    const {
      publicReportUrl,
    } = campaign.payload.campaign

    if (publicReportUrl) {
      return [
        true,
        () => dispatch(openCopyLinkModal({
          link: publicReportUrl,
          title: translate("Share Report"),
          subtitle: translate("Copy the public url for this report below"),
        })),
      ]
    }
    return [ false, () => {} ]
  }, [ campaign, tabPath ])

  const downloadReportEnabled = tabPath === TabPathsEnum.REPORTING
    && isSuccess(campaignReport)

  return (
    <div className="cp_campaign_component-tabs-container">
      <Tabs
        handleChange={ setTab }
        tabs={ tabs }
        defaultValue={ tabIndex }
        controlledValue={ tabIndex }
        controls={ (
          <>
            <span className="cp_campaign_component-tabs-container-created">
              {
                `${ translate("CREATED") } ${ createdDate }`
                }
            </span>
            { shareReportEnabled && (
              <IconButton onClick={ onShareClick }>
                <Share />
              </IconButton>
            ) }
            {
              downloadReportEnabled
              && <DownloadReportCsvButton />
            }
            { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && scopes.includes(Scope.CAMPAIGN_TYPE_OPTIONS) ? (
              <Tooltip arrow={ true } title={ translate("Edit") }>
                <IconButton
                  onClick={ editCampaignHandler }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : null }
          </>
) }
      />
      <div className="cp_campaign_component-tabs-content">
        { tabContent }
      </div>
      { tabPage }
    </div>
  )
}

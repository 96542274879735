import * as React from "react"
import RadioMui, { RadioProps as RadioPropsMui } from "@mui/material/Radio"

import "./style.sass"

interface Props extends RadioPropsMui {

}

export default function Radio(props: Props) {
  const {
    ...restProps
  } = props

  return (
    <RadioMui
      className="cp-radio"
      inputProps={ { "aria-label": "controlled" } }
      { ...restProps }
    />
  )
}

import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { Autocomplete, debounce } from "@mui/material"
import * as API from "../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import Input, { Props as InputProps } from "../Input"
import LoadingIndicator from "../LoadingIndicator"

export interface Occupations { __typename?: "Tag", id: string, name: string, code: string }

interface Props {
  setSelectedOccupations: (occupations: Occupations[]) => void
  selectedOccupations: Occupations[],
  inputLabel: string,
  notFoundLabel: string,
}

function OccupationsAutocomplete({
  setSelectedOccupations,
  selectedOccupations,
  inputLabel,
  notFoundLabel,
}:Props) {
  const [ occupationTags, setOccupationTags ] = useState<Occupations[]>([])

  // Occupations
  const searchOccupations = async (startsWith: string) => {
    const result = await API.fetchOccupationsForDemographicScore(startsWith)

    if (API.isSuccess(result)) {
      setOccupationTags(result.payload.searchOccupations.rows)
    } else {
      setOccupationTags([ {
        id: "", name: notFoundLabel, code: "",
      } ])
    }
  }

  const debouncedSearchOccupations = useCallback(debounce((e, inputValue) => {
    searchOccupations(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchOccupations ])

  const onAutoCompleteChange = (event: React.SyntheticEvent, occupations: (string | Occupations)[]) => {
    const cleanedOccupations = occupations.map((occupation) => {
      if (typeof occupation === "string") {
        return {
          name: occupation, id: "", code: "",
        }
      }
      return occupation
    })
    setSelectedOccupations(cleanedOccupations)
  }

  useEffect(() => {
    searchOccupations("")
  }, [])

  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedOccupations }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (occupation) => {
      // Value selected with enter, right from the input
        if (typeof occupation === "string") return occupation
        return occupation.name
      } }
      ListboxProps={ {
        className: "cp_component_autocomplete_tags-list",
      } }
      onInputChange={ debouncedSearchOccupations }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="autocomplete-tags"
      options={ [ ...occupationTags ].sort() }
      loading={ false }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, { name, id }, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ id }
          >
            { name }
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          label={ inputLabel }
        />
      )
}
    />
  )
}

export default OccupationsAutocomplete

import React from "react"
import { TFunction } from "i18next"
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro"
import moment from "moment"

import * as Constant from "../../util/constant"
import * as MiscHelper from "../../util/miscHelper"
import * as SearchHelper from "../../util/searchHelper"
import * as TableHelper from "./tableHelper"
import ContentEllipsisMenuCell, { SearchContentEllipsisMenuOptions } from "./ContentEllipsisMenuCell"
import MediaCell from "./MediaCell"
import PostDetailsCell from "./PostDetailsCell"
import PostType from "../PostType"
import SocialAvatar from "../SocialAvatar"
import { Network } from "../../graphql"

export function generateContentResultsColumns(
  translate: TFunction,
  handleNSFWUpdate: (
    updateID: string,
    updateFrom: string,
  ) => Promise<void>,
  contextMenu: SearchContentEllipsisMenuOptions,
): GridColDef[] {
  return [
    {
      field: SearchHelper.SearchColumn.Account,
      headerName: translate("Account"),
      sortable: false,
      renderCell: (params) => (
        <SocialAvatar { ...params.row.account } />
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 300,
    },
    {
      field: SearchHelper.SearchColumn.Followers,
      description: translate("Tooltip Followers"),
      headerName: translate("Followers"),
      sortable: true,
      renderCell: (params) => (
        <p>{ params.row.followers }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.PostDate,
      headerName: translate("Post Date"),
      sortable: true,
      renderCell: (params) => (
        <p>{ params.row.postDate }</p>
      ),
      disableColumnMenu: true,
      width: 200,
    },
    {
      field: SearchHelper.SearchColumn.PostType,
      description: translate("Tooltip Post Type"),
      headerName: translate("Post Type"),
      sortable: false,
      renderCell: (params) => (
        <div className="cp_component_search-results-post-details-cell">
          <PostType
            isReel={ params.row.postType.isReel }
            isStory={ params.row.postType.isStory }
            network={ params.row.account.network }
            isHighlighted={ params.row.postType.isHighlighted }
            showInFeedPill={ true }
          />
        </div>
      ),
      disableColumnMenu: true,
      width: 135,
    },
    {
      field: SearchHelper.SearchColumn.Media,
      headerName: translate("Media"),
      sortable: false,
      renderCell: (params) => (
        // NOTE: Media cell uses data from other columns for Post Details modal
        <MediaCell
          media={ params.row.media.postMedia }
          socialAvatarProps={ params.row.account }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
    },
    {
      field: SearchHelper.SearchColumn.PostDetails,
      headerName: translate("Post Details"),
      sortable: false,
      renderCell: (params) => (
        <PostDetailsCell { ...params.row.postDetails } />
      ),
      disableColumnMenu: true,
      width: 360,
    },
    {
      field: SearchHelper.SearchColumn.EngagementRate,
      description: translate("Tooltip Engagement Rate"),
      headerName: translate("Engagement Rate"),
      sortable: true,
      renderCell: (params) => {
        const { isStory } = params.row.postType
        const { network } = params.row.account
        if (isStory && network === Network.Instagram) {
          return (
            <p className="inactive">--</p>
          )
        }
        return (
          <p>{ params.row.engagementRate }</p>
        )
      },
      disableColumnMenu: true,
      minWidth: 140,
    },
    {
      field: SearchHelper.SearchColumn.EllipsisMenu,
      headerName: "", // This column should be pinned and doesn't need a header
      sortable: false,
      renderCell: (params) => (
        <ContentEllipsisMenuCell
          handleNSFWUpdate={ handleNSFWUpdate }
          hasPersonality={ params.row.account.hasPersonality }
          network={ params.row.account.network }
          postId={ params.row.id }
          socialAcctId={ params.row.account.id }
          sponsored={ params.row.ellipsisMenu }
          showOptions={ contextMenu }
          postDetailsProps={ {
            engagementRate: params.row.engagementRate,
            isReel: params.row.postDetails.isReel,
            isStory: params.row.postDetails.isStory,
            media: params.row.media.postMedia,
            permalink: params.row.media.permalink,
            postComments: params.row.postDetails.postComments,
            postContent: params.row.postDetails.postContent,
            postDate: params.row.postDetails.postDate,
            postExits: params.row.postDetails.postExits,
            postImpressions: params.row.postDetails.postImpressions,
            postLikes: params.row.postDetails.postLikes,
            postPlays: params.row.postDetails.postPlays,
            postReach: params.row.postDetails.postReach,
            postReachEstimated: params.row.postDetails.postReachEstimated,
            postSaves: params.row.postDetails.postSaves,
            postShares: params.row.postDetails.postShares,
            postScreenshots: params.row.postDetails.postScreenshots,
            postHighlighted: params.row.postDetails.postHighlighted,
            postSaved: params.row.postDetails.postSaved,
            postSwipeUps: params.row.postDetails.postSwipeUps,
            postTapsBackward: params.row.postDetails.postTapsBackward,
            postTapsForward: params.row.postDetails.postTapsForward,
            postViews: params.row.postDetails.postViews,
            socialAvatarProps: params.row.account,
          } }
        />
      ),
      disableColumnMenu: true,
      width: 50,
    },
  ]
}

export function generateContentResultsRow(
  row: { [ k: string ]: any },
  translateCommon: TFunction,
): GridValidRowModel | null {
  if (!TableHelper.isPost(row)) return null
  const { socialAccount } = row
  const { socialAccountStatistics: stats } = socialAccount

  return {
    id: row.id,
    account: {
      id: socialAccount.id,
      followers: stats.followers,
      fullName: socialAccount.name || "",
      isBlacklisted: socialAccount.personality?.blacklist || false,
      isNSFW: row.isNsfw,
      isPlaceholder: socialAccount.isPlaceholder,
      isUnsubscribed: [
        ...socialAccount.emails,
        ...socialAccount.emailsSourcedFromTeam,
      ].some(({ unsubscribedTags }) => unsubscribedTags.length > 0),
      lastPostedDate: socialAccount.lastPostedDate,
      network: socialAccount.network,
      profilePictureUrl: socialAccount.profilePictureUrl,
      username: socialAccount.userName,
      hasPersonality: socialAccount.personality != null,
      oauthed: socialAccount.oauthed,
    },
    postDate: moment.unix(row.postedTime)
      .format(Constant.LONGFORM_DATE_TIME),
    followers: translateCommon("intlNumber", { val: stats.followers }),
    postType: {
      isReel: row.isReel,
      isStory: row.isStory,
      isHighlighted: row.highlighted,
    },
    media: {
      permalink: row.permalink,
      postMedia: row.postMedia,
    },
    postDetails: {
      isReel: row.isReel,
      isStory: row.isStory,
      network: row.socialAccount.network,
      postComments: row.postComments,
      postContent: row.postContent,
      postDate: row.postedTime || 0,
      postExits: row.postExits || 0,
      postImpressions: row.postImpressions || 0,
      postLikes: row.postLikes,
      postPlays: row.postPlays || 0,
      postReach: row.postReach || 0,
      postReachEstimated: row.estimatedPostReach?.value || 0,
      postSaves: row.postSaves || 0,
      postShares: row.postShares,
      postTapsBackward: row.postTapsBackward || 0,
      postTapsForward: row.postTapsForward || 0,
      postScreenshots: row.screenshots || 0,
      postHighlighted: Boolean(row.highlighted),
      postSaved: Boolean(row.saved),
      postSwipeUps: row.swipeUps || 0,
      postViews: row.postViews,
    },
    // eslint-disable-next-line max-len
    engagementRate: `${ row.engagementRate >= 1 ? 100 : MiscHelper.prettyPrintDecimal(row.engagementRate) }%${ row.engagementRate >= 1 ? "+" : "" }`,
    ellipsisMenu: row.sponsored,
  }
}

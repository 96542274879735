import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { DialogActions, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import Button from "../Button"
import LoadingIndicator from "../LoadingIndicator"

import "./success-dialog.sass"

interface Props {
  isOpen: boolean
  handleClose: () => void
  dialogAction?: () => void
  dialogTitle?: string
  dialogContextText?: string
  buttonLabel?: string
}

export default function SuccessDialog({
  isOpen,
  handleClose,
  dialogAction,
  dialogTitle,
  dialogContextText,
  buttonLabel,
}: Props) {
  const [ loading, setLoading ] = React.useState(false)
  const handleDialogActionClick = () => {
    setLoading(true)
    if (dialogAction) {
      dialogAction()
    }
  }

  return (
    <Dialog
      className="cp_component_modal-create-list-success-dialog"
      open={ isOpen }
      onClose={ handleClose }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        { dialogTitle }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={ handleClose }
        sx={ {
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        } }
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={ true }>
        <DialogContentText id="alert-dialog-description">
          { dialogContextText }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ handleDialogActionClick }
          label={ loading ? <LoadingIndicator /> : buttonLabel }
          isEnabled={ true }
          isPrimary={ true }
        />
      </DialogActions>
    </Dialog>
  )
}

import React, { JSX } from "react"
import { connect } from "react-redux"

import DefaultRender from "./defaultRender"
import { pushToast, InternalToast } from "../../state/toastSlice"
import { RootState } from "../../state/store"
import { Toast as ToastType } from "../../util/types"

type ToastControllerState = {
  toasts: InternalToast[],
  pushToast: (toast: ToastType) => void,
}

type Props = {
  render?: (state: ToastControllerState) => JSX.Element,
  toasts: InternalToast[],
  pushToast: (toast: ToastType) => void,
}

type State = {}

class Toast extends React.Component<Props, State> {
  render(): JSX.Element {
    if (this.props.render != null) {
      return this.props.render({
        toasts: this.props.toasts,
        pushToast: this.props.pushToast,
      })
    }

    return <DefaultRender toasts={ this.props.toasts } />
  }
}

const mapStateToProps = (state: RootState) => ({
  toasts: state.toasts.toasts,
})

export default connect(mapStateToProps, { pushToast })(Toast)

/* eslint-disable no-underscore-dangle */
import React from "react"

import { Link } from "react-router-dom"
import { Avatar } from "@mui/material"

import { DeliverableHistoryEventType, HistoryEventFragment } from "../../graphql"

interface HistoryEventProps {
  event: HistoryEventFragment
}

function HistoryEventPayload({ event }: HistoryEventProps): React.JSX.Element {
  if (
    (event.type === DeliverableHistoryEventType.MediaCreated
    || event.type === DeliverableHistoryEventType.MediaDeleted
    || event.type === DeliverableHistoryEventType.MediaAccepted
    )
  && event.payload.__typename === "DeliverableMedia") {
    return (
      <Avatar variant="square" src={ event.payload.media.url.address } />
    )
  }
  if (
    (event.type === DeliverableHistoryEventType.MediaFeedback)
      && event.payload.__typename === "DeliverableContentFeedback") {
    return (
      <p>
        <Avatar variant="square" src={ event.payload.deliverableMediaContentFeedback?.media.url.address } />
        { event.payload.text }
      </p>
    )
  }
  if (event.type === DeliverableHistoryEventType.DeliverableLive && event.payload.__typename === "Url") {
    return (
      <Link target="_blank" to={ event.payload.address }>
        { event.payload.address }
      </Link>
    )
  }
  if (
    (event.type === DeliverableHistoryEventType.TextFeedback)
    && event.payload.__typename === "DeliverableContentFeedback") {
    return (
      <p>
        { event.payload.text }
      </p>
    )
  }
  if (
    (event.type === DeliverableHistoryEventType.TextAccepted
      || event.type === DeliverableHistoryEventType.TextCreated
      || event.type === DeliverableHistoryEventType.TextDeleted
    )
    && event.payload.__typename === "DeliverableCaption") {
    return (
      <p>
        { event.payload.text }
      </p>
    )
  }
  return <> </>
}

export default HistoryEventPayload

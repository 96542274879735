import * as React from "react"
import CheckboxMui, { CheckboxProps } from "@mui/material/Checkbox"

import "./style.sass"

interface Props extends CheckboxProps {}

export default function Checkbox(props: Props) {
  return (
    <CheckboxMui
      className="cp-checkbox"
      inputProps={ { "aria-label": "controlled" } }
      { ...props }
    />
  )
}

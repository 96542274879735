import React, { JSX } from "react"

import TargetFilter from "./TargetFilter"
import NetworkFilter from "./NetworkFilter"
import { Scope } from "../../util/types"
import { useSelector } from "../../state/hooks"

import "./style.sass"
import { Network } from "../../graphql"

type Props = {
  excludeSection?: string
  disabledNetworks?: Network[]
  hideDisabled?: boolean
}

export default function SearchFilter({
  excludeSection, disabledNetworks, hideDisabled = false,
}: Props): JSX.Element {
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)

  switch (excludeSection) {
    case "network":
      return (
        <div className="cp_component_search-filter excluded-network">
          {
            (scopes.includes(Scope.FEATURE_SEARCH_TIN_SOCIAL_ACCOUNTS)
            || scopes.includes(Scope.FEATURE_SEARCH_CUS_SOCIAL_ACCOUNTS))
            && <TargetFilter />
          }
        </div>
      )
    case "target":
      return (
        <div className="cp_component_search-filter excluded-target">
          <NetworkFilter disabledNetworks={ disabledNetworks } hideDisabled={ hideDisabled } />
        </div>
      )
    default:
      return (
        <div className="cp_component_search-filter">
          {
            (scopes.includes(Scope.FEATURE_SEARCH_TIN_SOCIAL_ACCOUNTS)
            || scopes.includes(Scope.FEATURE_SEARCH_CUS_SOCIAL_ACCOUNTS))
            && <TargetFilter />
          }
          <NetworkFilter disabledNetworks={ disabledNetworks } hideDisabled={ hideDisabled } />
        </div>
      )
  }
}

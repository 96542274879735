import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

// Add Accounts to Lists Modal Slice Interface and Initial State
interface SocialTracker {
  socialTracker: Status<GraphQL.GetContentAlertsAndSearchContentViaContentAlertQuery>,
}

const initialState: SocialTracker = {
  socialTracker: "init",
}

// Add Accounts to Lists Modal Slice
export const SocialTrackerPage = createSlice({
  name: "socialTrackerPage",
  initialState,
  reducers: {
    setSocialTracker: (
      state,
      action: PayloadAction<Status<GraphQL.GetContentAlertsAndSearchContentViaContentAlertQuery>>,
    ) => ({
      ...state,
      socialTracker: action.payload,
    }),
    setSocialTrackerToInitState: () => initialState,
  },
})

export const {
  setSocialTracker,
  setSocialTrackerToInitState,
} = SocialTrackerPage.actions
export default SocialTrackerPage.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchSocialTracker = (
  xref: string,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setSocialTracker("loading"))
  const socialTracker = await API.fetchSocialTrackerById(xref, 1, 20)
  dispatch(setSocialTracker(socialTracker))
}

/* eslint-disable react/no-this-in-sfc */
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react"
import { useTranslation } from "react-i18next"

import "./brands.sass"
import {
  ListItemText,
  MenuItem,
  debounce,
} from "@mui/material"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../util/constant"
import * as API from "../../../util/apiClient"
import Select from "../../Select"
import Tabs, { TabLabelProps } from "../../Tabs"
import AvatarBrand from "../../AvatarBrand"
import * as GraphQL from "../../../graphql"
import LoadingIndicator from "../../LoadingIndicator"
import { Status } from "../../../util/types"
import Input from "../../Input"
import ModalBrandDetails, { FetchMentionedPostParams } from "./ModalBrandDetails"

const allTime = undefined
const oneYearAgo = `${ dayjs().subtract(1, "year").valueOf() }`
const sixMonthsAgo = `${ dayjs().subtract(6, "month").valueOf() }`

const brandsContentHelper = (
  brands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  translate: TFunction,
  onClick: (mention: GraphQL.MentionFragment
) => void,
) => {
  if (brands === "init" || brands === "loading") {
    return <LoadingIndicator className="cp_brands_component-content-loading" size={ 30 } />
  }
  if (API.isError(brands) || brands.payload.getMentionedSocialAccounts.rows.length === 0) {
    return (
      <p className="cp_brands_component-content-no-results">
        { translate("There are no results to display") }
      </p>
    )
  }
  return brands.payload.getMentionedSocialAccounts.rows.map((mention) => (
    <div key={ mention.brandId } className="cp_brands_component-content-brand-grid">
      <div key={ mention.brandId } className="cp_brands_component-content-brand">
        <AvatarBrand
          onClick={ () => { onClick(mention) } }
          pictureUrl={ mention.socialAccount.profilePictureUrl }
          timesMentioned={ mention.timesMentioned }
        />
        <p className="cp_brands_component-content-brand-name">
          { mention.socialAccount.name }
        </p>
      </div>
    </div>
  ))
}

export type FetchBrandParams = {
  networkAccountId: string,
  limit: number,
  startTimestamp?: string,
  filter?: string
}

type Props = {
  socialProfile: GraphQL.SocialProfileFragment,
  mentionedBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  workedWithBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  detectedBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  fetchMentionedBrands: (params: FetchBrandParams) => Promise<void>,
  fetchWorkedWithBrands: (params: FetchBrandParams) => Promise<void>,
  fetchDetectedBrands: (params: FetchBrandParams) => Promise<void>,
  mentionedPostsStatus: Status<GraphQL.GetMentionedPostsQuery>,
  mentionedPostsContent: Array<GraphQL.MentionedPostFragment>,
  getMentionedPosts: (params: FetchMentionedPostParams) => void
  getMoreMentionedPosts: (params: FetchMentionedPostParams) => void
  displayBrandsMentionedWorked?: boolean
  displayBrandsDetected?: boolean
}

export default function Brands({
  socialProfile,
  mentionedBrands,
  workedWithBrands,
  detectedBrands,
  fetchDetectedBrands,
  fetchMentionedBrands,
  fetchWorkedWithBrands,
  mentionedPostsContent,
  mentionedPostsStatus,
  getMentionedPosts,
  getMoreMentionedPosts,
  displayBrandsDetected = true,
  displayBrandsMentionedWorked = true,
}: Props) {
  const [ timestamp, setTimestamp ] = useState<undefined | string>(allTime)
  const [ filter, setFilter ] = useState<undefined | string>()

  const [ selectedTab, selectTab ] = useState(GraphQL.MentionType.Brand)
  const [ selectedMention, setSelectedMention ] = useState<GraphQL.MentionFragment>()
  const [ brandDetailsModalOpen, setBrandDetailsModalOpen ] = useState<boolean>(false)

  const { t: translate } = useTranslation([], { keyPrefix: "component.Brands" })

  const onAvatarClick = (mention: GraphQL.MentionFragment) => {
    setSelectedMention(mention)
    setBrandDetailsModalOpen(true)
  }

  const fetchBrands = async (filterVal?: string) => {
    const params = {
      networkAccountId: socialProfile.id,
      limit: 100,
      startTimestamp: timestamp,
      filter: filterVal || filter,
    }

    switch (selectedTab) {
      case GraphQL.MentionType.Brand:
        await fetchMentionedBrands(params)
        break
      case GraphQL.MentionType.BrandWorkedWith:
        await fetchWorkedWithBrands(params)
        break
      case GraphQL.MentionType.BrandLogoDetection:
        await fetchDetectedBrands(params)
        break
      default:
    }
  }

  useEffect(() => {
    fetchBrands()
  }, [ socialProfile, timestamp, selectedTab ])

  const mentionedBrandAvatars = brandsContentHelper(mentionedBrands, translate, onAvatarClick)

  const workedWithBrandAvatars = brandsContentHelper(workedWithBrands, translate, onAvatarClick)

  const detectedBrandAvatars = brandsContentHelper(detectedBrands, translate, onAvatarClick)

  const [ tabs, mentionTypes ]: [TabLabelProps[], GraphQL.MentionType[]] = useMemo(
    () => {
      const newTabs = []
      const newMentionTypes = []
      if (displayBrandsMentionedWorked) {
        newTabs.push({ label: translate("Mentioned") })
        newTabs.push({ label: translate("Worked With") })
        newMentionTypes.push(GraphQL.MentionType.Brand)
        newMentionTypes.push(GraphQL.MentionType.BrandWorkedWith)
      }
      if (displayBrandsDetected) {
        newTabs.push({ label: translate("Detected") })
        newMentionTypes.push(GraphQL.MentionType.BrandLogoDetection)
      }
      return [ newTabs, newMentionTypes ]
    },
    [ translate ],
  )

  const timestampOptions = useMemo(() => {
    const options = [ {
      name: translate("All Time"),
      value: allTime,
    }, {
      name: translate("Last Year"),
      value: oneYearAgo,
    }, {
      name: translate("Last 6 Months"),
      value: sixMonthsAgo,
    } ]
    return options.map(({ name, value }) => (
      <MenuItem
        key={ name }
        onClick={ () => setTimestamp(value) }
        value={ value }
        className="cp_brands_component-date-item"
      >
        <ListItemText>{ name }</ListItemText>
      </MenuItem>
    ))
  }, [])

  const handleSelectTab = (tabIndex: number) => {
    selectTab(mentionTypes[tabIndex])
  }

  const debouncedFilterChange = useCallback(
    debounce((nextValue) => fetchBrands(nextValue), DEFAULT_DEBOUNCE_WAIT),
    [],
  )

  const handleFilterChange = (value: string) => {
    setFilter(value)
    debouncedFilterChange(value)
  }

  return (
    <div className="cp_brands_component">
      <div className="cp_brands_component-tabs-container">
        <Tabs
          handleChange={ handleSelectTab }
          tabs={ tabs }
        />
        <div className="cp_brands_component-tabs-controls">
          <Select
            id="select-brands-date"
            label=""
            labelId="select-brands-date"
            menuItems={ timestampOptions }
            value={ timestamp }
            displayEmpty={ true }
            className="cp_brands_component-date"
          />
          <Input
            placeholder="Search"
            className="cp_brands_component-filter"
            value={ filter }
            onChange={ (e) => handleFilterChange(e.currentTarget.value) }
          />
        </div>
        <div className="cp_brands_component-content">
          { selectedTab === GraphQL.MentionType.Brand && mentionedBrandAvatars }
          { selectedTab === GraphQL.MentionType.BrandWorkedWith && workedWithBrandAvatars }
          { selectedTab === GraphQL.MentionType.BrandLogoDetection && detectedBrandAvatars }
        </div>
      </div>

      <ModalBrandDetails
        open={ brandDetailsModalOpen }
        onClose={ () => setBrandDetailsModalOpen(false) }
        mention={ selectedMention }
        mentionType={ selectedTab }
        mentionedPostsContent={ mentionedPostsContent }
        mentionedPostsStatus={ mentionedPostsStatus }
        socialProfile={ socialProfile }
        getMentionedPosts={ getMentionedPosts }
        getMoreMentionedPosts={ getMoreMentionedPosts }
      />
    </div>
  )
}

/* eslint-disable no-param-reassign */
import { TFunction } from "i18next"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import {
  Status,
  Toast,
  Err,
  Success,
} from "../../util/types"
import { NSFW_CONTENT } from "../../util/constant"
import { isPost, isSocialAccount } from "./tableHelper"

export type ModalType = "account" | "content" | null
export type NsfwUpdateResolvers =
Promise<Status<GraphQL.PostMarkNsfwMutationMutation | null>> |
Promise<Status<GraphQL.SocialAccountMarkNsfwMutationMutation | null>>

export type NsfwStatus =
Status<GraphQL.PostMarkNsfwMutationMutation | null>
| Status<GraphQL.SocialAccountMarkNsfwMutationMutation | null>
| null

export type NsfwUpdate = Err | Success<GraphQL.PostMarkNsfwMutationMutation | null>

export interface ToastArgs {
  translation: TFunction;
  isError: boolean;
  pushToast: (toast: Toast) => void;
  updateType: string
}

export function triggerNfswToast(
  pushToast: (toast: Toast) => void,
  t: TFunction,
  isError: boolean,
  updateType: string,
  nsfwChangeStatus: NsfwStatus,
): void {
  let toast: Toast
  const errorMessage = updateType === NSFW_CONTENT ? "NSFW Toast post error" : "NSFW Toast account error"
  const successMessage = updateType === NSFW_CONTENT ? "NSFW Toast post success" : "NSFW Toast account success"

  if (isError) {
    toast = {
      message: t(errorMessage),
      type: "error",
    }
    pushToast(toast)
  }

  if (API.isError(nsfwChangeStatus as NsfwUpdate)) {
    toast = {
      message: t(errorMessage),
      type: "error",
    }
  } else {
    toast = {
      message: t(successMessage),
      type: "success",
    }
  }
  pushToast(toast)
}

export const parseSearchResultsAccounts = (
  content: GraphQL.GodSearchSocialAccount[],
): GraphQL.GodSearchSocialAccount[] => (
  JSON.parse(JSON.stringify(content))
)

export const parseSearchResultsContent = (
  content: GraphQL.Post[],
): GraphQL.Post[] => (
  JSON.parse(JSON.stringify(content))
)

export const markItemsAsNSFW = async <T>(
  updateID: string,
  items: T[],
  predicate: (item: T, id: string) => T,
): Promise<T[]> => {
  const updatedItems = items.map((item) => predicate(item, updateID))
  return updatedItems
}

const markPostPredicate = (post: GraphQL.Post, updateID: string): GraphQL.Post => {
  if (post.id === updateID) {
    post.isNsfw = true
  }
  return post
}

type SocialAcct = GraphQL.GodSearchSocialAccount
const markSocialAcctPredicate = (account: SocialAcct, updateID: string): SocialAcct => {
  if (account.socialAccount.id === updateID) {
    account.socialAccount.isNsfw = true
  }
  return account
}

export async function markPostNSFW(
  updateID: string,
  posts: GraphQL.Post[],
) {
  const searchResultsPosts: GraphQL.Post[] = parseSearchResultsContent(posts)
  if (searchResultsPosts.length && isPost(searchResultsPosts[0])) {
    const updatedPosts = await markItemsAsNSFW(updateID, searchResultsPosts, markPostPredicate)
    return updatedPosts
  }
  return searchResultsPosts
}

export async function markSocialAcctNSFW(
  updateID: string,
  accounts: GraphQL.GodSearchSocialAccount[],
) {
  const searchResultsAccounts: SocialAcct[] = parseSearchResultsAccounts(accounts)
  if (searchResultsAccounts.length && isSocialAccount(searchResultsAccounts[0])) {
    const updatedAccounts = await markItemsAsNSFW(updateID, searchResultsAccounts, markSocialAcctPredicate)
    return updatedAccounts
  }
  return searchResultsAccounts
}

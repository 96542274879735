import * as React from "react"
import { useTranslation } from "react-i18next"
import "../style.sass"
import { useSelector } from "../../../../state/hooks"
import "../../AccountInsights/account-insights-details.sass"
import Divider from "../../../Divider"
import { Network } from "../../../../graphql"
import EngagementChartWrapper from "./EngagementChartWrapper"
import EngagementTableWrapper from "./EngagementTableWrapper"
import EngagementMapWrapper from "./EngagementMapWrapper"
import ImageAnalysisWrapper from "./ImageAnalysisWrapper"
import BrandsWrapper from "./BrandsWrapper"
import RecentMediaWrapper from "./RecentMediaWrapper"
import PersonalityTraitsWrapper from "./PersonalityTraitsWrapper"
import { ListTogglesType } from "../../../../util/types"

type Props = {
  toggles: ListTogglesType
}

export default function AccountInsightsDetails({ toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const {
    selectedListSocialAccount,
  } = useSelector(({ publicList }) => publicList)

  const shouldDisplayImageAnalysis = React.useMemo(() => {
    if (selectedListSocialAccount) {
      const { socialAccount: { network } } = selectedListSocialAccount
      return network === Network.Instagram || network === Network.Facebook
    }
    return false
  }, [ selectedListSocialAccount ])

  return (
    <div className="cp_account-insights-details_component">
      { toggles.displayInsightsEngagementRateOverTime
        && (
        <div className="cp_account-insights-details_component-widget">
          <EngagementChartWrapper />
        </div>
        ) }

      { (toggles.displayInsightsEngagementsByPostType || toggles.displayInsightsEngagementsByPostTypeReel)
      && (
      <div className="cp_account-insights-details_component-widget">
        <div>
          <h6 className="cp_account-insights-details_component-widget-title">
            { translate("Engagement by Post Type") }
          </h6>
          <Divider />
          <EngagementTableWrapper
            displayPostType={ toggles.displayInsightsEngagementsByPostType }
            displayPostTypeReel={ toggles.displayInsightsEngagementsByPostTypeReel }
          />
        </div>
      </div>
      ) }
      { toggles.displayInsightsEngagementsHeatMap && (
        <div className="cp_account-insights-details_component-widget">
          <EngagementMapWrapper />
        </div>
      ) }
      { toggles.displayInsightsRecentMediaAIImageAnalysis && (
      <div className="cp_account-insights-details_component-row">
        { shouldDisplayImageAnalysis && (
        <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-2">
          <div>
            <h6 className="cp_account-insights-details_component-widget-title">
              { translate("AI Image Analysis") }
            </h6>
            <Divider />
            <ImageAnalysisWrapper />
          </div>
        </div>
        ) }
        <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-1">
          <div>
            <h6 className="cp_account-insights-details_component-widget-title">
              { translate("Recent Media") }
            </h6>
            <Divider />
            <RecentMediaWrapper />
          </div>
        </div>
      </div>
      ) }
      { (toggles.displayInsightsBrandsMentionedBrandsWorkedWith || toggles.displayInsightsBrandsDetected) && (
      <div className="cp_account-insights-details_component-widget">
        <h6 className="cp_account-insights-details_component-widget-title">
          { translate("Brands") }
        </h6>
        <Divider />
        <BrandsWrapper
          displayBrandsMentionedWorked={ toggles.displayInsightsBrandsMentionedBrandsWorkedWith }
          displayBrandsDetected={ toggles.displayInsightsBrandsDetected }
        />
      </div>
      ) }
      { toggles.displayInsightsPITraits && (
      <div className="cp_account-insights-details_component-widget">
        <PersonalityTraitsWrapper />
      </div>
      ) }
    </div>
  )
}

import React from "react"
import { CheckCircleOutline, Close } from "@mui/icons-material"
import { useCookies } from "react-cookie"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import Button from "../../component/Button"
import IconButton from "../../component/IconButton"
import { SuggestionAccessCodeRestriction } from "../../graphql"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  approvePublicList,
  fetchPublicListSocialProfile,
  setInfoRequiredModalOpen,
  setInfoRequiredSubmitCallback,
  setLeftPanelStatus,
  setRightPanelStatus,
  userCanEdit,
} from "../../state/publicListSlice"

import "./approve-panel.sass"

type Props = {
  accessCodeRestriction: SuggestionAccessCodeRestriction | undefined | null
  userIsValid: boolean
}

export default function ApprovePanel({ accessCodeRestriction, userIsValid }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ApprovePanel" })
  const dispatch = useDispatch()

  const {
    selectedListSocialAccount, list, nameCookieKey, companyCookieKey,
  } = useSelector(({ publicList }) => publicList)

  const { listCode } = useParams()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ cookies, setCookie ] = useCookies([ nameCookieKey, companyCookieKey ])

  const onClose = () => {
    dispatch(setRightPanelStatus("closed"))
    dispatch(setLeftPanelStatus("default"))
  }

  const approveListCallback = (suggestionListCode: string, selectedListSocialAccountId: string, socialAccountId: string) => {
    dispatch(
      approvePublicList({
        suggestionListCode,
        suggestionListSocialAccountId: selectedListSocialAccountId,
        onSuccess: () => dispatch(fetchPublicListSocialProfile({
          listId: suggestionListCode,
          socialAccountId,
        })),
      }),
    )
  }

  const handleSubmit = async () => {
    if (API.isSuccess(list) && listCode && selectedListSocialAccount) {
      if (cookies[nameCookieKey] && cookies[companyCookieKey] && userCanEdit(accessCodeRestriction, userIsValid)) {
        approveListCallback(listCode, selectedListSocialAccount.id, selectedListSocialAccount.socialAccount.id)
        onClose()
      } else {
        dispatch(setInfoRequiredSubmitCallback(() => {
          approveListCallback(listCode, selectedListSocialAccount.id, selectedListSocialAccount.socialAccount.id)
          onClose()
        }))
        dispatch(setInfoRequiredModalOpen(true))
      }
    }
  }

  return (
    <div className="cp_component_approve-panel">
      <IconButton onClick={ onClose } variant="outlined">
        <Close />
      </IconButton>

      <CheckCircleOutline className="cp_component_approve-panel-icon" />
      <h5>{ translate("Are you sure you want to approve this account for your campaign?") }</h5>
      <Button onClick={ handleSubmit } label={ translate("Yes, I approve") } />
      <Button onClick={ onClose } label={ translate("Cancel") } isPrimary={ false } />
    </div>
  )
}

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { DeliverableStatus } from "../../graphql"

export interface StatusDeliverableProps extends
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  deliverableStatus: DeliverableStatus
  /**
   * @default 'md'
   */
  size?: "sm" | "md"
  hideLabel?: boolean
}

export default function StatusDeliverable({
  deliverableStatus,
  size = "md",
  className = "",
  hideLabel = false,
  ...props
}: StatusDeliverableProps) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.StatusDeliverable" })
  const status = useMemo(() => {
    switch (deliverableStatus) {
      case DeliverableStatus.Pending:
        return "pending"
      case DeliverableStatus.AwaitingContent:
        return "awaiting-content"
      case DeliverableStatus.Live:
        return "live"
      case DeliverableStatus.Uploaded:
        return "uploaded"
      case DeliverableStatus.Finalized:
        return "finalized"
      default:
        return "pending"
    }
  }, [ deliverableStatus ])
  const classes = [ "cp_component_deliverable-status",
    `cp_component_deliverable-status_${ status }`,
    `cp_component_deliverable-status_${ size }`,
  ]
  if (className) classes.push(className)
  const finalClassName = classes.join(" ")
  return (
    <div className="cp_component_deliverable-status">
      <span className={ finalClassName } { ...props } />
      {
        hideLabel ? null : (
          <span className="cp_component_deliverable-status-label">
            { translate(deliverableStatus) }
          </span>
        )
      }
    </div>
  )
}

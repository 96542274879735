import React from "react"
import { DividerProps, Divider as MuiDivider } from "@mui/material"
import "./style.sass"

const classes = [ "cp_component_divider" ]

function Divider(props: DividerProps): React.JSX.Element {
  const classNamesProp = props.className?.split(" ") || []
  const classNames = classes.concat(classNamesProp).join(" ")

  return (
    <MuiDivider
      { ...props }
      className={ classNames }
    />
  )
}

export default Divider

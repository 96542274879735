import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { FilterMenuValue } from "../../component/FilterMenu"
import { CAMPAIGN_SEARCH_FILTERS, Status } from "../../util/types"
import {
  setCampaignSearchInput,
  setCampaignStatus,
  setCampaignsContent,
} from "."
import { RootState } from "../store"
// Campaign Interface and Initial State
export interface CampaignState {
  campaignsStatus: Status<GraphQL.SearchCampaignsQuery>
  campaignsContent: Array<GraphQL.CampaignRowFragment>
  campaignSearchInput: string
  campaignSearchFilter: FilterMenuValue
}

export const initialState: CampaignState = {
  campaignsStatus: "init",
  campaignsContent: [],
  campaignSearchInput: "",
  campaignSearchFilter: {
    keyId: "", label: "", value: null,
  },
}

// Reducer actions
export const reducers = {
  setCampaignStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchCampaignsQuery>>) => ({
    ...state,
    campaignsStatus: action.payload,
  }),
  setCampaignsContent: (state: any, action: PayloadAction<Array<GraphQL.CampaignRowFragment>>) => ({
    ...state,
    campaignsContent: action.payload,
  }),
  setCampaignSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    campaignSearchInput: action.payload,
  }),
  setCampaignSearchFilter: (state: any, action: PayloadAction<FilterMenuValue>) => ({
    ...state,
    campaignSearchFilter: action.payload,
  }),
}

export const getCampaigns = (
  searchInput: string,
  filter: FilterMenuValue,
  page: number,
) => async (dispatch: Dispatch) => {
  dispatch(setCampaignStatus("loading"))

  const i = CAMPAIGN_SEARCH_FILTERS.findIndex((f) => f === filter.value)
  if (i === -1 && filter.value !== null) {
    dispatch(setCampaignStatus({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchCampaigns({
      startsWith: searchInput,
      column: GraphQL.SearchCampaignSort.CampaignCreated,
      direction: GraphQL.SortDirection.Desc,
      filter: filter.value ? CAMPAIGN_SEARCH_FILTERS[i] : null,
      page,
      limit: 50,
    })

    dispatch(setCampaignStatus(result))
    if (API.isSuccess(result)) {
      dispatch(setCampaignsContent(result.payload.searchCampaign.rows))
    }
    dispatch(setCampaignSearchInput(searchInput))
  }
}

export const getMoreCampaigns = (
  searchInput: string,
  filter: FilterMenuValue,
  page: number,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { campaignsStatus: currentCampaignStatus, campaignsContent: currentCampaignContent } } = getState()

  const i = CAMPAIGN_SEARCH_FILTERS.findIndex((f) => f === filter.value)
  if (i === -1 && filter.value !== null) {
    dispatch(setCampaignStatus({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchCampaigns({
      startsWith: searchInput,
      column: GraphQL.SearchCampaignSort.CampaignCreated,
      direction: GraphQL.SortDirection.Desc,
      filter: filter.value ? CAMPAIGN_SEARCH_FILTERS[i] : null,
      page,
      limit: 50,
    })

    if (API.isSuccess(currentCampaignStatus) && API.isSuccess(result)) {
      dispatch(setCampaignsContent([ ...currentCampaignContent, ...result.payload.searchCampaign.rows ]))
    } else {
      dispatch(setCampaignStatus({ status: "error", message: "Something went wrong with the request!" }))
    }

    dispatch(setCampaignSearchInput(searchInput))
  }
}

import React, { JSX } from "react"

import * as API from "../../util/apiClient"
import LoadingIndicator from "../LoadingIndicator"
import { useSelector } from "../../state/hooks"

import "./style.sass"

export default function LoginFlowLogo(): JSX.Element {
  const team = useSelector(({ team: t }) => t.team)

  if (!API.isSuccess(team)) {
    return (
      <article className="cp_component_login-flow-logo">
        <LoadingIndicator size={ 62 } />
      </article>
    )
  }

  const whitelabeledLogo = team.payload.vanity.customerLogo?.url.address

  if (whitelabeledLogo != null) {
    return (
      <article
        className="cp_component_login-flow-logo whitelabeled"
        style={ {
          backgroundImage: `url("${ whitelabeledLogo }")`,
        } }
      />
    )
  }

  return (
    <article className="cp_component_login-flow-logo default" />
  )
}

import { createSlice } from "@reduxjs/toolkit"
import type { Dispatch, PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"

// Campaign Modal Slice Interface and Initial State
export interface SelectCampaignModalState {
  openSelectDeliverableModal: boolean
  submitLoading: boolean
  campaignsQuery: Status<GraphQL.SearchCampaignsQuery>
  selectedCampaigns: GraphQL.CampaignRowFragment[]
  /**
   * This callback should be defined on the page that is opening the Select Campaign Modal.
   * It is invoked when the user clicks the close or cancel button of the modal.
   */
  closeCallback?: () => Promise<void>
  /**
   * This callback should be defined on the page that is opening the Select Campaign Modal.
   * It is invoked when the user clicks the CTA button of the modal.
   */
  submitCallback?: (selectedCampaigns: GraphQL.CampaignRowFragment[]) => Promise<void>
}

const initialState: SelectCampaignModalState = {
  openSelectDeliverableModal: false,
  closeCallback: undefined,
  campaignsQuery: "init",
  selectedCampaigns: [],
  submitLoading: false,
}

// Deliverable Modal Slice
export const SelectCampaignModalSlice = createSlice({
  name: "SelectCampaignModalSlice",
  initialState,
  reducers: {
    openSelectCampaignModal: (
      state,
      action: PayloadAction<{
        submitCallback?: () => Promise<void>,
        closeCallback?: () => Promise<void>,
      }>,
    ) => ({
      ...state,
      openSelectDeliverableModal: true,
      createCallback: action.payload.submitCallback,
      closeCallback: action.payload.closeCallback,
    }),
    setCampaignsQuery: (
      state,
      action: PayloadAction<Status<GraphQL.SearchCampaignsQuery>>,
    ) => ({
      ...state,
      campaignsQuery: action.payload,
    }),
    setSubmitLoading: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      submitLoading: action.payload,
    }),
    setSelectedCampaigns: (
      state,
      action: PayloadAction<GraphQL.CampaignRowFragment[]>,
    ) => ({
      ...state,
      selectedCampaigns: action.payload,
    }),
    toggleSelectedCampaign: (
      state,
      action: PayloadAction<GraphQL.CampaignRowFragment>,
    ) => {
      const foundCampaign = state.selectedCampaigns.find((campaign) => campaign.id === action.payload.id)
      const newSelectedCampaigns = foundCampaign
        ? [ ...state.selectedCampaigns ].filter((campaign) => campaign.id !== action.payload.id)
        : [ ...state.selectedCampaigns, action.payload ]
      return {
        ...state,
        selectedCampaigns: newSelectedCampaigns,
      }
    },
    closeSelectCampaignModal: (state) => {
      if (state.closeCallback) state.closeCallback()
      return {
        ...initialState,
      }
    },
  },
})

export const {
  openSelectCampaignModal,
  closeSelectCampaignModal,
  setCampaignsQuery,
  toggleSelectedCampaign,
  setSubmitLoading,
} = SelectCampaignModalSlice.actions
export default SelectCampaignModalSlice.reducer

// Select Campaign Slice Thunks
export const getCampaigns = (searchInput: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCampaignsQuery("loading"))

  const campaignsResults = await API.fetchCampaigns({
    startsWith: searchInput,
    column: GraphQL.SearchCampaignSort.CampaignName,
    direction: GraphQL.SortDirection.Desc,
    limit: 100,
  })

  dispatch(setCampaignsQuery(campaignsResults))
}

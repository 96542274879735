import * as GraphQL from "../../../graphql"

// eslint-disable-next-line no-shadow
export enum DeliverableMetricType {
  IG_IN_FEED_STATIC_CAROUSEL = "ig-in-feed-static-carousel",
  IG_IN_FEED_VIDEO = "ig-in-feed-video",
  IG_STORY = "ig-story",
  TIKTOK = "tiktok",
  FACEBOOK_IN_FEED_STATIC_CAROUSEL = "facebook-in-feed-static-carousel",
  FACEBOOK_IN_FEED_VIDEO = "facebook-in-feed-video",
  YOUTUBE = "youtube"
}

export const getDeliverableMetricType = (
  deliverableNetwork: GraphQL.Network,
  postType: GraphQL.DeliverablePostType,
  postFormat: GraphQL.DeliverablePostFormat,
) => {
  if (deliverableNetwork === GraphQL.Network.Instagram) {
    if (
      (postType === GraphQL.DeliverablePostType.InFeed && postFormat === GraphQL.DeliverablePostFormat.Static)
      || (postType === GraphQL.DeliverablePostType.Carousel)) {
      return DeliverableMetricType.IG_IN_FEED_STATIC_CAROUSEL
    }
    if (postType === GraphQL.DeliverablePostType.InFeed && postFormat === GraphQL.DeliverablePostFormat.Video) {
      return DeliverableMetricType.IG_IN_FEED_VIDEO
    }
    if (postType === GraphQL.DeliverablePostType.Story) {
      return DeliverableMetricType.IG_STORY
    }
  } else if (deliverableNetwork === GraphQL.Network.Tiktok) {
    return DeliverableMetricType.TIKTOK
  } else if (deliverableNetwork === GraphQL.Network.Facebook) {
    if (
      (postType === GraphQL.DeliverablePostType.InFeed && postFormat === GraphQL.DeliverablePostFormat.Static)
      || (postType === GraphQL.DeliverablePostType.Carousel && postFormat)
    ) {
      return DeliverableMetricType.FACEBOOK_IN_FEED_STATIC_CAROUSEL
    }
    if (postType === GraphQL.DeliverablePostType.InFeed && postFormat === GraphQL.DeliverablePostFormat.Video) {
      return DeliverableMetricType.FACEBOOK_IN_FEED_VIDEO
    }
  } else if (deliverableNetwork === GraphQL.Network.Youtube) {
    return DeliverableMetricType.YOUTUBE
  }
  return null
}

// eslint-disable-next-line no-shadow
export enum MetricKey {
  POST_DATE = "post-date",
  COMMENTS = "comments",
  STICKER_TAPS = "sticker-taps",
  REPLIES = "replies",
  LIKES = "likes",
  SAVES = "saves",
  SHARES = "shares",
  LINK_CLICKS = "link-clicks",
  VIEWS = "views",
  ACTUAL_REACH = "actual-reach",
  VIDEO_VIEWS = "video-views",
  TRUE_IMPRESSIONS = "true-impressions",
  GROSS_IMPRESSIONS = "gross-impressions"
}

export const getMetricInputFieldKeys = (
  deliverableMetricType: DeliverableMetricType,
) => {
  switch (deliverableMetricType) {
    case DeliverableMetricType.IG_IN_FEED_STATIC_CAROUSEL:
      return [
        MetricKey.POST_DATE,
        MetricKey.COMMENTS,
        MetricKey.LIKES,
        MetricKey.SAVES,
        MetricKey.SHARES,
        MetricKey.ACTUAL_REACH,
        MetricKey.TRUE_IMPRESSIONS,
        MetricKey.GROSS_IMPRESSIONS,
      ]
    case DeliverableMetricType.IG_IN_FEED_VIDEO:
      return [
        MetricKey.POST_DATE,
        MetricKey.COMMENTS,
        MetricKey.LIKES,
        MetricKey.SAVES,
        MetricKey.SHARES,
        MetricKey.ACTUAL_REACH,
        MetricKey.VIDEO_VIEWS,
        MetricKey.GROSS_IMPRESSIONS,
      ]
    case DeliverableMetricType.IG_STORY:
      return [
        MetricKey.POST_DATE,
        MetricKey.STICKER_TAPS,
        MetricKey.REPLIES,
        MetricKey.SHARES,
        MetricKey.LINK_CLICKS,
        MetricKey.LIKES,
        MetricKey.VIEWS,
        MetricKey.ACTUAL_REACH,
        MetricKey.GROSS_IMPRESSIONS,
      ]
    case DeliverableMetricType.FACEBOOK_IN_FEED_STATIC_CAROUSEL:
      return [
        MetricKey.POST_DATE,
        MetricKey.COMMENTS,
        MetricKey.LIKES,
        MetricKey.SHARES,
        MetricKey.TRUE_IMPRESSIONS,
        MetricKey.ACTUAL_REACH,
      ]
    case DeliverableMetricType.FACEBOOK_IN_FEED_VIDEO:
      return [
        MetricKey.POST_DATE,
        MetricKey.COMMENTS,
        MetricKey.LIKES,
        MetricKey.SHARES,
        MetricKey.VIDEO_VIEWS,
        MetricKey.ACTUAL_REACH,
      ]
    case DeliverableMetricType.TIKTOK:
      return [
        MetricKey.POST_DATE,
        MetricKey.COMMENTS,
        MetricKey.LIKES,
        MetricKey.SHARES,
        MetricKey.ACTUAL_REACH,
        MetricKey.VIEWS,
        MetricKey.GROSS_IMPRESSIONS,
      ]
    case DeliverableMetricType.YOUTUBE:
      return [
        MetricKey.POST_DATE,
        MetricKey.COMMENTS,
        MetricKey.LIKES,
        MetricKey.VIDEO_VIEWS,
        MetricKey.GROSS_IMPRESSIONS,
      ]
    default:
      return []
  }
}

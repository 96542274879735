import React from "react"
import { useTranslation } from "react-i18next"
import { FormControlLabel } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"

import "./ModalContactInformation.sass"
import Modal, { ModalProps } from "../Modal"
import Button from "../Button"
import Input from "../Input"
import Radio from "../Radio"
import IconButtonClose from "../IconButtonClose"
import { useDispatch, useSelector } from "../../state/hooks"
import { fetchSocialProfile } from "../../state/socialProfileSlice"
import { pushToast } from "../../state/toastSlice"
import * as API from "../../util/apiClient"
import { Toast } from "../../util/types"
import LoadingIndicator from "../LoadingIndicator"

type Email = string
type InputChangeEvent = React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>

interface ModalContactInformationProps extends Pick<ModalProps, "open" | "closeAction" | "secondaryAction"> {
}

export default function ModalContactInformation({
  open,
  secondaryAction,
  closeAction,
}: ModalContactInformationProps): React.JSX.Element {
  const profile = useSelector(({ socialProfile }) => socialProfile.profile)

  const dispatch = useDispatch()

  const [ emails, setEmails ] = React.useState<Email[]>([])
  const [ isLoading, setIsLoading ] = React.useState(false)
  const [ primaryEmailIndex, setPrimaryEmailIndex ] = React.useState(0)

  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalContactInformation" })

  React.useEffect(() => {
    if (profile === "init" || profile === "loading" || API.isError(profile)) return

    const sourcedEmailAddresses = profile.payload.socialAccount.emailsSourcedFromTeam.map(({ address }) => address)
    setEmails([ ...sourcedEmailAddresses ])
    if (profile.payload.socialAccount.primaryEmail) {
      const index = sourcedEmailAddresses.indexOf(profile.payload.socialAccount.primaryEmail.address)
      setPrimaryEmailIndex(index)
    }
  }, [ open ])

  const handleChangeEmail = (e: InputChangeEvent, i: number) => {
    const newSecondaryEmails = [ ...emails ]
    newSecondaryEmails[i] = e.currentTarget.value
    setEmails(newSecondaryEmails)
  }

  const removeEmail = (i: number) => {
    const newEmails = emails.filter((_, index) => index !== i)
    if (i < primaryEmailIndex) setPrimaryEmailIndex(primaryEmailIndex - 1)
    if (i === primaryEmailIndex) setPrimaryEmailIndex(0)
    setEmails(newEmails)
  }

  const saveChanges = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (profile === "init" || profile === "loading" || API.isError(profile)) return

    setIsLoading(true)
    const sourcedEmailAddresses = profile.payload.socialAccount.emailsSourcedFromTeam
    const networkAccountId = profile.payload.socialAccount.id

    const emailsToAdd = emails.filter((_email) => !sourcedEmailAddresses.find(({ address }) => address === _email))

    const emailsToRemove = sourcedEmailAddresses.filter(({ address }) => !emails.includes(address))

    const promises: Promise<any>[] = []

    emailsToAdd.forEach((emailToAdd) => {
      const promise = API.createNetworkAccountEmail({
        emailAddress: emailToAdd,
        networkAccountId,
      })
      promises.push(promise)
    })

    emailsToRemove.forEach(({ id }) => {
      const promise = API.deleteNetworkAccountEmail({
        emailId: id,
        networkAccountId,
      })
      promises.push(promise)
    })

    await Promise.all(promises)

    // this request should wait for all additions and removal of emails before setting a primary email
    if (emails[primaryEmailIndex]) {
      await API.setPrimaryNetworkAccountEmail(
        {
          networkAccountId,
          email: emails[primaryEmailIndex],
        },
      )
    }

    const successToast: Toast = {
      type: "success",
      message: translate("Contact Information Saved"),
    }
    dispatch(pushToast(successToast))

    dispatch(fetchSocialProfile(networkAccountId))
    setIsLoading(false)
    if (closeAction) closeAction(e)
  }

  return (
    <Modal
      title={ translate("Contact Information") }
      subtitle={ translate("Added by your team") }
      primaryLabel={ isLoading ? <LoadingIndicator size={ 18 } /> : translate("Save") }
      disabled={ isLoading }
      secondaryLabel={ translate("Cancel") }
      open={ open }
      closeAction={ closeAction }
      primaryAction={ saveChanges }
      secondaryAction={ secondaryAction }
      maxWidth="lg"
    >
      <div className="cp_modal-contact_component-wrapper">
        { emails.map((email, i) => {
          const id = `secondary-email-${ i }`
          return (
            <div className="cp_modal-contact_component-row" key={ id }>
              <Input
                label={ i === 0 && translate("Email") }
                autoFocus={ true }
                value={ email }
                onChange={ (e) => handleChangeEmail(e, i) }
                fullWidth={ true }
                type="email"
                className="cp_modal-contact_component-input-email"
              />
              <FormControlLabel
                labelPlacement="end"
                value={ i }
                label={ translate("Primary Email") }
                control={ (
                  <Radio
                    title={ translate("Primary Email") }
                    value={ i }
                    checked={ i === primaryEmailIndex }
                    onChange={ () => setPrimaryEmailIndex(i) }
                  />
                  ) }
              />
              <div
                className={ i === primaryEmailIndex
                  ? "cp_component_modal-add-profile-hidden" : ""
                    }
              >
                <IconButtonClose onClick={ () => removeEmail(i) } />
              </div>
            </div>
          )
        }) }
        <div className="cp_modal-contact_component-add">
          <Button
            className="cp_modal-contact_component-add-btn"
            isEnabled={ true }
            label={ translate("Add Email") }
            isPrimary={ false }
            startIcon={ <AddIcon /> }
            onClick={ () => setEmails(emails.concat("")) }
          />
        </div>
      </div>
    </Modal>
  )
}

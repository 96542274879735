import React from "react"
import { LinearProgress } from "@mui/material"

import "./trait.sass"
import { PiTraitScoreFragment } from "../../../../graphql"
import { prettyPrintDecimal } from "../../../../util/miscHelper"

interface TraitProps {
    piTrait: PiTraitScoreFragment
}

export function Trait({ piTrait }: TraitProps) {
  const progress = 200 - (0.5 - (piTrait.score.value || 0)) * 200
  return (
    <div className="cp_trait_component">
      <div className="cp_trait_component-equator" />
      <div className="cp_trait_component-header">
        <p className="cp_trait_component-header-description">
          { piTrait.trait.description }
        </p>
        <p className="cp_trait_component-header-score">
          { prettyPrintDecimal(piTrait.score.value, 0) }
          %
        </p>
      </div>
      <div className="cp_trait_component-progress">
        <div className="cp_trait_component-progress-left">
          <LinearProgress value={ progress <= 200 ? progress : 0 } variant="determinate" />
        </div>
        <div className="cp_trait_component-progress-right">
          <LinearProgress value={ progress > 200 ? progress - 200 : 0 } variant="determinate" />
        </div>
      </div>
      <div className="cp_trait_component-labels">
        <p>
          { piTrait.trait.descriptorLow }
        </p>
        <p>
          { piTrait.trait.descriptorHigh }
        </p>
      </div>
      <div className="cp_trait_component-definition">
        <p>
          { piTrait.score.value && piTrait.score.value > 0.5 ? piTrait.trait.definitionHigh : piTrait.trait.definitionLow }
        </p>
      </div>
    </div>
  )
}

export default Trait

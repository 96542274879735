import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicator from "../../../LoadingIndicator"
import EngagementMap from "../../AccountInsights/EngagementMap"
import * as API from "../../../../util/apiClient"
import { getLocalTZOffset } from "../../../../util/miscHelper"
import { getDailyHourlyEngagementRate } from "../../../../state/socialProfileSlice"

export default function EngagementMapWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { profile, dailyEngagementRate } = useSelector(({ socialProfile }) => socialProfile)
  const dispatch = useDispatch()
  const [ timeZone, setTimeZone ] = useState(getLocalTZOffset() || -8)

  useEffect(() => {
    if (API.isSuccess(profile)) {
      const variables = {
        socialAccountId: profile.payload.socialAccount.id,
        timeZoneOffset: timeZone,
        limit: 10,
      }
      dispatch(getDailyHourlyEngagementRate(variables))
    }
  }, [ timeZone, profile ])

  if (profile === "loading" || profile === "init" || dailyEngagementRate === "loading" || dailyEngagementRate === "init") {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (profile.status === "error" || dailyEngagementRate.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <EngagementMap
      dailyEngagementRate={ dailyEngagementRate.payload.getDailyHourlyEngagementSummary }
      timeZone={ timeZone }
      setTimeZone={ setTimeZone }
    />
  )
}

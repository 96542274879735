import { MoreHoriz } from "@mui/icons-material"
import { IconButton, Menu } from "@mui/material"
import React from "react"
import "./context-menu-button.sass"

type Props = {
    children: React.JSX.Element | React.JSX.Element[]
    className?: string
    onClick?: () => void
    anchorOrigin?: {
        vertical: "bottom" | "center" | "top" | number,
        horizontal: "center" | "left" | "right" | number
    }
    transformOrigin?: {
        vertical: "bottom" | "center" | "top" | number,
        horizontal: "center" | "left" | "right" | number
    }
    menuProps?: any
    iconButtonProps?: any
}

export default function ContextMenuButton({
  children,
  className,
  onClick = () => {},
  anchorOrigin,
  transformOrigin,
  menuProps,
  iconButtonProps,
}: Props) {
  const classes = [ "cp_component-context-menu-buttton" ]

  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    onClick()
  }

  return (
    <div className={ newClasses }>
      <IconButton
        onClick={ handleClick }
        { ...iconButtonProps }
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        className="cp_component-context-menu-button-menu"
        { ...menuProps }
        open={ open }
        anchorEl={ anchorEl }
        onClose={ () => setAnchorEl(null) }
        anchorOrigin={ anchorOrigin }
        transformOrigin={ transformOrigin }
        disablePortal={ false }
      >
        { children }
      </Menu>
    </div>
  )
}

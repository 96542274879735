import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { IconButton } from "@mui/material"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import Input from "../Input"
import Modal, { ModalProps } from "../Modal"
import OptionsList from "./OptionsList"
import "./style.sass"
import Button from "../Button"

interface ModalAdvancedOptionsProps extends Omit<ModalProps, "children" | "primaryAction"> {
  onSave: (exclusive: string[], inclusive: string[]) => void
  inputLabel: string
  inputTitle: string
  contentCloseLink?: () => void
  contentCloseLabel?: string
}

function ModalAdvancedOptions({
  onSave,
  inputLabel,
  inputTitle,
  contentCloseLink,
  contentCloseLabel,
  ...props
}: ModalAdvancedOptionsProps): React.JSX.Element {
  const [ exclusive, setExclusive ] = useState<Set<string>>(new Set())
  const [ input, setInput ] = useState<string>("")
  const [ inclusive, setInclusive ] = useState<Set<string>>(new Set())
  const [ option, setOption ] = useState<string>("")

  const isAddButtonDisabled = !option || inclusive.has(option)
  const isRemoveButtonDisabled = !option || !inclusive.has(option)

  const addExclusive = (o: string) => {
    exclusive.add(o)
    inclusive.delete(o)
    setExclusive(exclusive)
    setInclusive(inclusive)
    setOption("")
  }

  const addSelection = (o: string) => {
    inclusive.add(o)
    exclusive.delete(o)
    setInclusive(inclusive)
    setExclusive(exclusive)
    setOption("")
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key !== "Enter") return
    addExclusive(input)
    setInput("")
  }

  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalAdvancedOptions" })

  return (
    <Modal
      { ...props }
      primaryAction={ () => onSave(Array.from(exclusive), Array.from(inclusive)) }
      maxWidth="lg"
    >
      { contentCloseLabel && (
        <Button
          className="cp_component_options-conten-link"
          variant="text"
          isPrimary={ false }
          label={ contentCloseLabel }
          onClick={ contentCloseLink }
          startIcon={ <ChevronLeft /> }
        />
      ) }
      <p className="cp_component_options-input-title label_small-caps-semibold">{ inputTitle }</p>
      <Input
        fullWidth={ true }
        value={ input }
        placeholder={ inputLabel }
        onChange={ ({ currentTarget }) => setInput(currentTarget.value) }
        onKeyDown={ handleKeyDown }
      />

      <div className="cp_component_advanced-options-lists">
        <OptionsList
          options={ Array.from(exclusive) }
          onClick={ setOption }
          selectedOption={ option }
          label={ translate("Any of these words") }
        />

        <div className="cp_component_advanced-options-list-buttons">
          <IconButton
            onClick={ () => addExclusive(option) }
            disabled={ isRemoveButtonDisabled }
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            disabled={ isAddButtonDisabled }
            onClick={ () => addSelection(option) }
          >
            <ChevronRight />
          </IconButton>
        </div>

        <OptionsList
          options={ Array.from(inclusive) }
          onClick={ setOption }
          selectedOption={ option }
          label={ translate("All of these words") }
        />
      </div>
    </Modal>
  )
}

export interface KeywordsSearchProps {
  onSave: (options: string[], inclusiveOptions: string[]) => void
  open: ModalAdvancedOptionsProps["open"]
  closeAction: () => void
  contentCloseLink?: () => void
  contentCloseLabel?: string
  classes?: string
}

export function KeywordsSearch({
  onSave,
  classes,
  ...props
}: KeywordsSearchProps): React.JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "component.KeywordsSearch" })
  return (
    <ModalAdvancedOptions
      { ...props }
      inputLabel={ translate("Add a term, @mention, or #tag") }
      inputTitle={ translate("Keywords") }
      title={ translate("Advanced Options") }
      subtitle={ translate("Advanced options for keywords.") }
      primaryLabel={ translate("Save") }
      secondaryLabel={ translate("Close") }
      onSave={ onSave }
      secondaryAction={ props.closeAction }
      className={ classes }
    />
  )
}

export function ImageTagsSearch({
  onSave,
  classes,
  ...props
}: KeywordsSearchProps): React.JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ImageTagsSearch" })
  return (
    <ModalAdvancedOptions
      { ...props }
      inputLabel={ translate("Add an image tag") }
      inputTitle={ translate("IMAGE TAGS") }
      title={ translate("Advanced Options") }
      subtitle={ translate("Advanced options for image tags.") }
      primaryLabel={ translate("Save") }
      secondaryLabel={ translate("Close") }
      onSave={ onSave }
      secondaryAction={ props.closeAction }
      className={ classes }
    />
  )
}

export default KeywordsSearch

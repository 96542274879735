import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { DialogActions } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "../../IconButton"
import Button from "../../Button"
import LoadingIndicator from "../../LoadingIndicator"

interface Props {
  isOpen: boolean
  handleClose: () => void
  dialogAction?: () => void
  dialogTitle?: string
  dialogContextText?: string
  buttonLabel?: string
  secondaryButtonLabel?: string
}

export default function AccountDialog({
  isOpen,
  handleClose,
  dialogAction,
  dialogTitle,
  dialogContextText,
  buttonLabel,
  secondaryButtonLabel,
}: Props) {
  const [ loading, setLoading ] = React.useState(false)
  const handleDialogActionClick = () => {
    setLoading(true)
    if (dialogAction) {
      dialogAction()
    }
  }

  return (
    <Dialog
      className="cp_component_modal-campaign-dialog"
      open={ isOpen }
      onClose={ handleClose }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        { dialogTitle }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={ handleClose }
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={ true }>
        <DialogContentText id="alert-dialog-description">
          { dialogContextText }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ handleClose }
          label={ secondaryButtonLabel }
          isEnabled={ true }
          isPrimary={ false }
        />
        <Button
          onClick={ handleDialogActionClick }
          label={ loading ? <LoadingIndicator /> : buttonLabel }
          isEnabled={ !loading }
          isPrimary={ true }
        />
      </DialogActions>
    </Dialog>
  )
}

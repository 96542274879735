import React from "react"

import { useTranslation } from "react-i18next"
import * as GraphQL from "../../../../graphql"
import { CAMPAIGN_REPORTING_VISUALS } from "../../../../util/constant"
import { groupBy } from "./reportingHelper"
import PostCard from "./PostCard"

import "./native-posts.sass"

interface Props {
  deliverables: GraphQL.DeliverableOverviewFragment[]
  toggles: GraphQL.CampaignReportToggleSetting[]
}

interface CardsProps {
  deliverables: GraphQL.DeliverableOverviewFragment[]
  network: string
  toggles: GraphQL.CampaignReportToggleSetting[]
}

function NativePostsCards({
  deliverables, network, toggles,
}: CardsProps) {
  return (
    <div className="cards-sections">
      { (deliverables && deliverables.length !== 0) && (
        deliverables.map(
          (deliverable) => (
            <PostCard
              key={ deliverable.id }
              deliverable={ deliverable }
              network={ network }
              toggles={ toggles }
            />
          ),
        )
      ) }
    </div>
  )
}

export default function CampaignNativePostsReport({ deliverables, toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReporting" })
  const filteredDeliverables = deliverables.filter((deliverable) => (
    deliverable.contentStatus === GraphQL.DeliverableStatus.Live
      && deliverable.postType !== GraphQL.DeliverablePostType.Story
      && deliverable.nativeInsights
  ))

  const groupedByNetwork = groupBy<GraphQL.DeliverableOverviewFragment, string>(
    filteredDeliverables,
    (deliverable) => deliverable.campaignNetworkAccount.socialAccount.network,
  )
  const togglesByNetwork = groupBy<GraphQL.CampaignReportToggleSetting, string>(
    toggles,
    (setting) => setting.platform,
  )
  const networksAvailable = Object.keys(groupedByNetwork) || []
  const filteredByShowPostEnabled = networksAvailable.filter((network) => {
    // find show_posts toggle
    // return the enable value per network
    const currNetwork = network.toLowerCase()
    const currentNetworkToggles = togglesByNetwork[currNetwork] || []
    if (!currentNetworkToggles.length) return false
    const showPostToggle = currentNetworkToggles.filter((setting) => (
      setting.section === CAMPAIGN_REPORTING_VISUALS.MEDIA_POSTS_NATIVE
        && setting.toggle === CAMPAIGN_REPORTING_VISUALS.SHOW_POSTS
    ))
    return showPostToggle.length ? showPostToggle[0].enabled : false
  })

  return (
    <div className="cp_report-native-posts">
      { filteredByShowPostEnabled.length !== 0 && (
        filteredByShowPostEnabled.map((network) => {
          const capitalizedNetwork = network.charAt(0).toUpperCase() + network.slice(1).toLowerCase()
          const uniqueId: string = `${ network }-${ Date.now() }`
          return (
            <div key={ `${ uniqueId } ` } className="section">
              <h2 className="section-heading">{ `${ translate("Native Posts") } ${ capitalizedNetwork }` }</h2>
              <NativePostsCards deliverables={ groupedByNetwork[network] } toggles={ toggles } network={ network } />
            </div>
          )
        })
      ) }
    </div>
  )
}

import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Navigate, useParams } from "react-router-dom"

import LoadingPage from "../page/Loading"

const QUERY = gql`
  query UserIsAuthenticatedGuard {
    currentUser {
      id
    }
  }
`

export default function UserIsAuthenticatedGuard(props: { children:React.ReactNode}) {
  const { vanity } = useParams()
  const user$ = useQuery(QUERY)

  if (user$.loading) {
    return <LoadingPage />
  }

  if (!user$.data?.currentUser) {
    if (vanity) {
      return <Navigate to={ `/${ vanity }/login` } replace={ true } />
    }

    return <Navigate to="/find-team" replace={ true } />
  }

  return <>{ props.children }</>
}

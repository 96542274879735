import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export interface CreateCustomer {
  isModalOpen: boolean,
  newCustomer: Status<GraphQL.CreateCustomerMutation | null>,
  editCustomer: Status<GraphQL.GetCustomerQuery | null>,
  editedCustomerStatus: Status<GraphQL.UpdateCustomerMutation | null>,
}

const initialState: CreateCustomer = {
  isModalOpen: false,
  newCustomer: "init",
  editCustomer: "init",
  editedCustomerStatus: "init",
}

// Create Customer Slice
export const createCustomerSlice = createSlice({
  name: "CreateCustomer",
  initialState,
  reducers: {
    setCreateCustomerModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isModalOpen: action.payload,
    }),
    newCustomer: (
      state,
      action: PayloadAction<Status<GraphQL.CreateCustomerMutation | null>>,
    ) => ({
      ...state,
      newCustomer: action.payload,
    }),
    resetCustomer: (
      state,
    ) => ({
      ...state,
      newCustomer: "init",
    }),
    setEditCustomer: (
      state,
      action: PayloadAction<Status<GraphQL.GetCustomerQuery | null>>,
    ) => ({
      ...state,
      editCustomer: action.payload,
    }),
    resetEditCustomer: (
      state,
    ) => ({
      ...state,
      editCustomer: "init",
    }),
    setEditedCustomerStatus: (
      state,
      action: PayloadAction<Status<GraphQL.UpdateCustomerMutation | null>>,
    ) => ({
      ...state,
      editedCustomerStatus: action.payload,
    }),
    resetEditedCustomerStatus: (
      state,
    ) => ({
      ...state,
      editedCustomerStatus: "init",
    }),
  },
})

export const {
  setCreateCustomerModalOpen,
  newCustomer,
  resetCustomer,
  setEditCustomer,
  resetEditCustomer,
  setEditedCustomerStatus,
  resetEditedCustomerStatus,
} = createCustomerSlice.actions
export default createCustomerSlice.reducer

// Thunks
export const createNewCustomer = (
  {
    code,
    companyName,
    mediaId,
  }: GraphQL.CreateCustomerMutationVariables,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(newCustomer("loading"))
  const customerInfo = await API.createCustomer({
    code, companyName, mediaId,
  })
  dispatch(newCustomer(customerInfo))
}

export const getCustomer = (
  params: GraphQL.GetCustomerQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setEditCustomer("loading"))

  const result = await API.fetchCustomer(params)
  dispatch(setEditCustomer(result))
  dispatch(setCreateCustomerModalOpen(true))
}

export const updateCustomer = (
  {
    customerId,
    code,
    companyName,
    mediaId,
  }: GraphQL.UpdateCustomerMutationVariables,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setEditedCustomerStatus("loading"))
  const customerInfo = await API.updateCustomer({
    customerId, code, companyName, mediaId,
  })
  dispatch(setEditedCustomerStatus(customerInfo))
}

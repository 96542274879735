import React from "react"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import "./style.sass"
import { useSelector, useDispatch } from "../../state/hooks"
import { isError } from "../../util/apiClient"
import Input from "../Input"
import {
  toggleCreateCategoryModal,
  addCategory,
  reorderSocialAccount,
  setSelectedSocialAccounts,
} from "../../state/listSlice"
import Avatar from "../Avatar"
import LoadingIndicator from "../LoadingIndicator"

function ModalCreateCategory(): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    selectedSocialAccounts, openCreateCategoryModal, list: currentList,
  } = useSelector(({ list }) => list)
  const [ name, setName ] = React.useState("")
  const [ loading, setLoading ] = React.useState(false)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCreateCategory" })

  const addCategoryHandler = async () => {
    if (currentList === "loading" || currentList === "init" || isError(currentList)) return

    setLoading(true)

    const newCategoryID = await dispatch(addCategory({
      suggestionListCategoryAddId: currentList.payload.suggestionListById.id,
      name,
    }))

    if (newCategoryID) {
      // RUNNING REQUESTS IN PARALLEL RESULTS IN DEADLOCK DATABASE ERRORS

      // eslint-disable-next-line no-restricted-syntax
      for (const socialAccount of selectedSocialAccounts) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(reorderSocialAccount({
          categoryId: newCategoryID,
          suggestionListSocialAccountId: socialAccount.id,
          index: 0,
        }))
      }
    }
    setLoading(false)
    setName("")
    dispatch(setSelectedSocialAccounts([]))
    dispatch(toggleCreateCategoryModal())
  }

  const AccountList = React.useMemo(() => (
    <div>
      <p className="cp_create-category_component-accounts-title">
        { translate("ACCOUNTS SELECTED FOR THIS CATEGORY") }
        :
      </p>
      <div className="cp_create-category_component-accounts">
        { selectedSocialAccounts.map(({ socialAccount, id }) => (
          <div key={ id } className="cp_create-category_component-accounts-container">
            <Avatar src={ socialAccount.profilePictureUrl } />
            <p className="cp_create-category_component-accounts-username">
              @
              { socialAccount.userName }
            </p>
          </div>
        )) }
      </div>
    </div>
  ), [ selectedSocialAccounts ])

  return (
    <Modal
      title={ translate("Create Category") }
      subtitle={ translate("Enter the category name below.") }
      primaryLabel={ loading ? <LoadingIndicator /> : translate("Create") }
      secondaryLabel={ translate("Cancel") }
      disabled={ name === "" || loading }
      open={ openCreateCategoryModal }
      primaryAction={ addCategoryHandler }
      closeAction={ () => dispatch(toggleCreateCategoryModal()) }
      secondaryAction={ () => dispatch(toggleCreateCategoryModal()) }
      maxWidth="lg"
    >
      <div className="cp_create-category_component">
        <Input
          label={ translate("Category Name") }
          placeholder={ translate("Add Category Name") }
          value={ name }
          onChange={ ({ currentTarget }) => setName(currentTarget.value) }
        />
        { selectedSocialAccounts.length > 0 && AccountList }
      </div>
    </Modal>
  )
}

export default ModalCreateCategory

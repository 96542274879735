import React, { useEffect } from "react"
import { FullStory } from "@fullstory/browser"
import dayjs from "dayjs"

import * as API from "../util/apiClient"
import * as Constant from "../util/constant"
import { fetchCurrentUser } from "../state/userSlice"
import { useDispatch, useSelector } from "../state/hooks"

export default function CurrentUserLoaderGuard(props: { children: React.ReactNode }) {
  const { children } = props

  const dispatch = useDispatch()
  const { user: currentUser } = useSelector(({ user }) => user)

  useEffect(() => {
    if (currentUser === "init") {
      dispatch(fetchCurrentUser())
    }

    if (API.isSuccess(currentUser)) {
      FullStory("setIdentity", {
        uid: currentUser.payload.currentUser?.id,
        properties: {
          username: currentUser.payload.currentUser?.username,
          date: dayjs().format(Constant.LONGFORM_DATE_TIME),
        },
      })
    }
  }, [ currentUser ])

  return <>{ children }</>
}

import * as React from "react"
import DoDisturbIcon from "@mui/icons-material/DoDisturb"
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe"
import moment from "moment"
import {
  Block,
  LockOutlined,
  PendingActions,
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import * as Constant from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import NSFWIcon from "../../IconNSFW"
import NetworkIcon from "../../NetworkIcon"
import Tooltip from "../../Tooltip"
import { Scope } from "../../../util/types"
import { useSelector } from "../../../state/hooks"

import "./account-insights.sass"

type Props = {
  socialAccount: GraphQL.SocialProfileFragment
}

export default function AccountInsights({ socialAccount }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { scopes } = useSelector(({ user }) => user)

  const {
    created,
    isNsfw,
    lastScannedDate,
    network,
    personality,
    socialAccountStatistics,
    userName,
    emails,
    emailsSourcedFromTeam,
    isPlaceholder,
    oauthed,
  } = socialAccount

  const isMuted = [
    ...emails,
    ...emailsSourcedFromTeam,
  ].some(({ unsubscribedTags }) => unsubscribedTags.length > 0)

  const formattedFollowers = translate("Formatted Followers Number", { followers: socialAccountStatistics.followers })
  const lastUpdatedFormatted = moment(lastScannedDate * 1000).format(Constant.LONGFORM_DATE)
  const dateAddedFormatted = moment(created * 1000).format(Constant.LONGFORM_DATE)

  return (
    <div className="cp_profile_component-insights">
      <div className="cp_profile_component-insights-overview">
        <div>
          <NetworkIcon isActive={ true } network={ network } additionalClasses="cp_profile_component-insights-network" />
        </div>
        <div>
          <p className="cp_profile_component-insights-username">
            @
            { userName }
          </p>
          <div className="cp_profile_component-insights-icons">
            { Boolean(personality?.blacklist)
              && <Block fontSize="inherit" className="cp_profile_component-insights-icons_blocklisted" />
            }
            { isPlaceholder
              && (
                <Tooltip title={ translate("This is a Placeholder Account") }>
                  <PendingActions fontSize="inherit" className="cp_profile_component-insights-icons_placeholder" />
                </Tooltip>
              )
            }
            { oauthed
              && (
                <Tooltip title={ translate("Influencer is OAuth") }>
                  <LockOutlined
                    id="cp_profile_component-insights-icons_oauthed"
                    fontSize="inherit"
                    className="cp_profile_component-insights-icons_oauthed"
                  />
                </Tooltip>
              )
            }
            { isMuted && <UnsubscribeIcon className="cp_profile_component-insights-icons_unsub" /> }
            { isNsfw && <NSFWIcon /> }
          </div>
          <div>
            { personality?.blacklistReason
              && (
                <p className="cp_profile_component-insights-blocklisted-description">
                  <DoDisturbIcon fontSize="small" />
                  { personality.blacklistReason }
                </p>
              )
            }
          </div>
        </div>
      </div>
      <div className="cp_profile_component-insights-metrics">
        { scopes.includes(Scope.SOCIAL_ACCOUNT_ADDED) && (
          <p>{ `${ translate("Account Added") }: ${ dateAddedFormatted }` }</p>
        ) }
        { scopes.includes(Scope.SOCIAL_ACCOUNT_LAST_UPDATED) && (
          <p>{ `${ translate("Last Updated") }: ${ lastUpdatedFormatted }` }</p>
        ) }
        <p>{ `${ translate("Account Followers") }: ${ formattedFollowers }` }</p>
      </div>
    </div>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import EthnicityChart from "../../Audience/EthnicityChart"

export default function EthnicityChartWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.EthnicityChart" })
  const { audience } = useSelector(({ listSocialProfile }) => listSocialProfile)
  if (audience === "loading" || audience === "init") {
    return (
      <LoadingIndicatorCard />
    )
  }
  if (audience.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <EthnicityChart audienceDemographics={ audience.payload.socialAccount.audienceDemographics } />
  )
}

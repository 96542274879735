import React from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import StatCard from "./StatCard"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import ClientsCard from "./UserListCards/ClientsCard"
import CampaignManagersCard from "./UserListCards/CampaignManagersCard"
import BrandManagersCard from "./UserListCards/BrandManagersCard"
import CampaignLinksCard from "./UserListCards/CampaignLinksCard"
import Button from "../../../Button"
import { Scope } from "../../../../util/types"
import RecentDeliverablesTable from "./RecentDeliverablesTable"
import { openNewDeliverableModal } from "../../../../state/campaignDeliverableModalSlice"
import { fetchCampaign } from "../../../../state/campaignSlice"

export default function CampaignOverviewDetails() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignOverviewDetails" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const { scopes } = useSelector(({ user }) => user)

  const dispatch = useDispatch()

  if (campaign === "init" || campaign === "loading") {
    return <LoadingIndicatorCard size={ 50 } />
  }

  if (campaign.status === "error") {
    return <p>{ translateCommon("An unexpected error occurred!") }</p>
  }

  const {
    id: campaignId,
    numberOfCampaignNetworkAccounts,
    numberOfAllDeliverables,
    numberOfDeliverablesWithoutContent,
    numberOfFinalizedDeliverables,
    numberOfLiveDeliverables,
    numberOfPendingDeliverables,
    description,
    deliverables,
    campaignNetworkAccounts,
  } = campaign.payload.campaign

  return (
    <div className="cp_component-campaign-overview-details">
      <div className="stat-container">
        <StatCard stat={ numberOfCampaignNetworkAccounts } title={ translate("Accounts") } />
        <StatCard stat={ numberOfAllDeliverables } title={ translate("Deliverables") } />
        <StatCard stat={ numberOfDeliverablesWithoutContent } title={ translate("Incoming") } />
        <StatCard stat={ numberOfPendingDeliverables } title={ translate("Pending") } />
        <StatCard stat={ numberOfFinalizedDeliverables } title={ translate("Finalized") } />
        <StatCard stat={ numberOfLiveDeliverables } title={ translate("Live") } />
      </div>
      { description && (
      <div className="description-container">
        <h5 className="description-header">{ translate("Campaign Description") }</h5>
        <p className="description">
          { description }
        </p>
      </div>
      ) }
      <div className="user-cards-container">
        <ClientsCard />
        <CampaignManagersCard />
        <BrandManagersCard />
        <CampaignLinksCard />
      </div>
      <div className="recent-deliverables-container">
        <div className="header-container">
          <h5 className="header">{ translate("Recent Deliverables") }</h5>
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <Button
            label={ translate("Add Deliverable") }
            onClick={ () => {
              dispatch(openNewDeliverableModal({
                socialAccounts: campaignNetworkAccounts,
                createCallback: () => dispatch(fetchCampaign({ campaignId })),
              }))
            } }
          />
          ) }
        </div>
        <RecentDeliverablesTable deliverables={ deliverables } campaign={ campaign } />
      </div>
    </div>
  )
}

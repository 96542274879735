/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, ChangeEvent } from "react"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"
import { Avatar } from "@mui/material"
import { useTranslation } from "react-i18next"

import "./style.sass"

// Define the props interface
interface ImageUploaderProps {
  onAvatarSelect: (file: File) => void;
  customClasses?: string
  allowedTypes?: string;
  children?: React.ReactNode
  disabled?: boolean
}

function DeliverableUploader({
  onAvatarSelect,
  customClasses,
  allowedTypes = "image/png, image/jpeg, .webm, .mp4, .mov",
  children,
  disabled = false,
}: ImageUploaderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ selectedFile, setSelectedFile ] = useState<File | null>(null)
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverable" })

  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    if (file) {
      setSelectedFile(file)
      onAvatarSelect(file)
    }
  }

  return (
    <div className={ `cp_component_deliverable_uploader ${ customClasses || "" }` }>
      <label className="image-upload" htmlFor="image-upload">
        <Avatar variant="rounded">
          <CameraAltOutlinedIcon fontSize="inherit" />
          <p>{ translate("Upload") }</p>
        </Avatar>
      </label>
      <input
        disabled={ disabled }
        accept={ allowedTypes }
        id="image-upload"
        type="file"
        style={ { display: "none" } }
        onChange={ handleFileInput }
      />
      { children }
    </div>
  )
}

export default DeliverableUploader

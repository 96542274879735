import React from "react"
import { Badge } from "@mui/material"

import "./style.sass"
import Avatar from "../Avatar"

const classes = [ "cp-avatar" ]

export interface AvatarBrandProps {
  pictureUrl: string
  timesMentioned?: number
  className?: string
  onClick?: () => void
}

function AvatarBrand({
  pictureUrl, timesMentioned, className, onClick,
}: AvatarBrandProps): React.JSX.Element {
  const inputClassName = className?.split(" ") || []
  const classNames = classes.concat(inputClassName).join(" ")

  return (
    <Badge
      className={ `cp_avatar-brand_component-badge ${ classNames }` }
      anchorOrigin={ {
        vertical: "bottom",
        horizontal: "right",
      } }
      overlap="circular"
      badgeContent={
      timesMentioned
    }
    >
      <Avatar onClick={ onClick } className="cp_avatar-brand_component-avatar" src={ pictureUrl } />
    </Badge>
  )
}

export default AvatarBrand

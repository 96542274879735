import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface CommGroupState {
  commGroupModalOpen: boolean,
}

// Initialize state
const initialState: CommGroupState = {
  commGroupModalOpen: false,
}

export const commGroupSlice = createSlice({
  name: "commGroup",
  initialState,
  reducers: {
    setCommGroupModalOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      commGroupModalOpen: action.payload,
    }),
  },
})

export const { setCommGroupModalOpen } = commGroupSlice.actions
export default commGroupSlice.reducer

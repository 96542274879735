import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"

// List Group Slice Interface and Initial State
export interface PublicCampaignReportState {
  report: Status<GraphQL.GetPublicCampaignReportQuery>
}

const initialState: PublicCampaignReportState = {
  report: "init",
}

export const PublicCampaignReport = createSlice({
  name: "PublicCampaignReport",
  initialState,
  reducers: {
    setReport: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicCampaignReportQuery>>,
    ) => ({
      ...state,
      report: action.payload,
    }),
  },
})

export const {
  setReport,
} = PublicCampaignReport.actions
export default PublicCampaignReport.reducer

export const fetchPublicCampaignReport = (
  params: GraphQL.GetPublicCampaignReportQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setReport("loading"))
  const reportResult = await API.fetchPublicCampaignReport(params)
  dispatch(setReport(reportResult))
}

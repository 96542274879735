import React from "react"
import Highcharts, { Options, Point } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import * as Constant from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import { CHART_BASE_COLORS } from "../../../util/constant"
import { useSelector } from "../../../state/hooks"

import "./followers-chart.sass"

const baseOptions: Options = {
  title: {
    text: "",
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
}

type Props = {
  socialProfile: GraphQL.SocialProfileFragment
}

export default function FollowersChart({ socialProfile }: Props) {
  const theme = useSelector(({ themeMode }) => themeMode.theme)
  const { t: translate } = useTranslation([], { keyPrefix: "component.FollowersChart" })

  const { followerCountOverTime } = socialProfile

  const getOptions = () => {
    baseOptions.xAxis = {
      categories: followerCountOverTime.map(({ date }) => dayjs(date * 1000)
        .format(Constant.LONGFORM_DATE)),
    }
    baseOptions.tooltip = {
      pointFormatter(this: Point) {
        // eslint-disable-next-line max-len, react/no-this-in-sfc
        return `<span style="color:${ this.color }">\u25CF</span> ${ this.series.name }: <b>${ translate("Format Number", { value: this.y }) }</b><br/>`
      },
    }

    baseOptions.yAxis = {
      title: {
        text: "",
      },
      floor: 0,
      gridLineDashStyle: "Dash",
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          // eslint-disable-next-line react/no-this-in-sfc
          return translate("Format Number", { value: this.value })
        },
      },
    }

    baseOptions.series = []
    baseOptions.series.push({
      type: "line",
      data: followerCountOverTime.map(({ followers }) => followers),
      // color: FollowersOverTimeChartColors[theme],
      color: CHART_BASE_COLORS[theme][0],
      name: `@${ socialProfile.userName }`,
    })
    return baseOptions
  }

  const changeInCount = followerCountOverTime.length >= 2
    ? followerCountOverTime[followerCountOverTime.length - 1].followers - followerCountOverTime[0].followers
    : 0
  const daysSinceChange = followerCountOverTime.length >= 2
    ? dayjs(followerCountOverTime[followerCountOverTime.length - 1].date * 1000)
      .diff(followerCountOverTime[0].date * 1000, "days")
    : 0
  const totalFollowers = socialProfile.socialAccountStatistics.followers

  return (
    <div className="cp_followers-chart_component">
      <div className="cp_followers-chart_component-title-container">
        <h6 className="cp_followers-chart_component-title">
          { translate("Followers Over Time") }
        </h6>
        <div className="cp_followers-chart_component-title-metrics">
          <div className="cp_followers-chart_component-title-metric">
            <p className="cp_followers-chart_component-title-metric-value">
              { translate("Format Number", { value: Math.round(changeInCount) }) }
            </p>
            <p className="cp_followers-chart_component-title-metric-name">
              { translate("Change Past Days", { days: daysSinceChange }) }
            </p>
          </div>
          <div>
            <p className="cp_followers-chart_component-title-metric-value">
              { translate("Format Number", { value: Math.round(totalFollowers) }) }
            </p>
            <p className="cp_followers-chart_component-title-metric-name">
              { translate("Total Followers") }
            </p>
          </div>
        </div>
      </div>
      <div className="cp_followers-chart_component-chart">
        <HighchartsReact highcharts={ Highcharts } options={ getOptions() } updateArgs={ [ true ] } />
      </div>
    </div>
  )
}

import React from "react"
import "./style.sass"
import {
  DrawerProps,
  Drawer,
  Container,
} from "@mui/material"
import Toggle, { ToggleMenuValue } from "../Toggle"

const classes = [ "cp-sliding-panel" ]

export interface SlidingPanelProps extends DrawerProps {
    title: string
    toggleOptions?: ToggleMenuValue[]
    toggleValue?: string
    setToggleValue?: (value: string) => void
}

function SlidingPanel({
  title,
  toggleOptions = [],
  toggleValue = "",
  setToggleValue = () => {},
  className,
  ...drawerProps
}: SlidingPanelProps): React.JSX.Element {
  const classNamesProp = className?.split(" ") || []
  const classNames = classes.concat(classNamesProp).join(" ")
  return (
    <Drawer
      { ...drawerProps }
      className={ classNames }
      slotProps={ {
        backdrop: {
          className: "cp_component_sliding-panel-backdrop",
        },
      } }
    >
      <div className="cp_component_sliding-panel-container">
        <Container className="cp_component_sliding-panel-header-container">
          <h2>{ title }</h2>
          {
            toggleOptions
            && (
              <Toggle
                setValue={ (value) => setToggleValue(value) }
                toggleOptions={ toggleOptions }
                value={ toggleValue }
              />
            )
          }
        </Container>
        <div className="cp_component_sliding-panel-children-container">
          { drawerProps.children }
        </div>
      </div>
    </Drawer>
  )
}

export default SlidingPanel

import React, { useMemo } from "react"
import ReactInfiniteScroll from "react-infinite-scroll-component"
import { v4 as uuidv4 } from "uuid"
import LoadingIndicator from "../LoadingIndicator"
import "./style.sass"

type Props = {
    dataLength: number,
    next: () => void,
    hasMore: boolean,
    children: React.JSX.Element[] | React.JSX.Element
    /**
     * The infinite scroll component needs a static height in order to properly cut off and
     * trigger loading the next page. The height prop provides options at 100, 300, and 500px,
     * but it is better to just make sure to set the height of the parent element or use flex-grow
     */
    height?: "sm" | "md" | "lg"
    className?: string
}

export default function InfiniteScroll({
  children,
  dataLength,
  hasMore,
  next,
  height,
  className,
}: Props) {
  const targetId = useMemo(() => `list-container-scroll-target-${ uuidv4() }`, [])
  const classes = [ "cp_component_infinite-scroll-container" ]
  if (height) classes.push(`cp_component_infinite-scroll-container-${ height }`)
  if (className) className.split(" ").forEach((name) => classes.push(name))
  const newClasses = classes.join(" ")

  return (
    <div id={ targetId } className={ newClasses }>
      <ReactInfiniteScroll
        dataLength={ dataLength }
        next={ next }
        hasMore={ hasMore }
        loader={ (
          <div className="cp_component_infinite-scroll-loading-indicator-container">
            <LoadingIndicator />
          </div>
              )
            }
        scrollableTarget={ targetId }
      >
        { children }
      </ReactInfiniteScroll>
    </div>
  )
}

import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Link,
  useNavigate,
  useParams,
} from "react-router-dom"
import ArrowBack from "@mui/icons-material/ArrowBackIos"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import * as MiscHelper from "../../util/miscHelper"
import Button from "../../component/Button"
import Input from "../../component/Input"
import LoadingIndicator from "../../component/LoadingIndicator"
import LoginFlowLogo from "../../component/LoginFlowLogo"
import LoginFlowCustomer from "../../component/LoginFlowCustomer"
import { fetchTeamVanityWithRedirect } from "../../state/teamSlice"
import { sendForgotPasswordEmail, setForgotPasswordEmailAttempt } from "../../state/userSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export default function ForgotPassword(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "page.ForgotPassword" })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { vanity } = useParams()
  const forgotPasswordEmailAttempt = useSelector(({ user }) => user.forgotPasswordEmailAttempt)
  const team = useSelector(({ team: t }) => t.team)

  const [ email, setEmail ] = useState<string>("")

  useEffect(() => {
    if (!API.isSuccess(team)) {
      dispatch(fetchTeamVanityWithRedirect(vanity || "", navigate))
    }
  }, [])

  if (API.isSuccess(forgotPasswordEmailAttempt)) {
    return (
      <div className="cp_page_forgot-password">
        <div>
          <section className="card">
            <div className="back">
              <ArrowBack />
              <Link
                className="large"
                id="cp_page_forgot_password-return-to-sign-in-link"
                onClick={ () => {
                  dispatch(setForgotPasswordEmailAttempt("init"))
                } }
                to={ `/${ vanity }/login` }
              >
                { translate("Return to Sign In") }
              </Link>
            </div>
            <LoginFlowLogo />
            <h1 className="headline">
              { translate("Password reset link sent") }
            </h1>
            <p className="label_medium success">
              { translate("If an account exists", { email }) }
            </p>
          </section>
        </div>
      </div>
    )
  }

  return (
    <div className="cp_page_forgot-password">
      <div>
        <section className="card">
          <div className="back">
            <ArrowBack />
            <Link
              className="large"
              id="cp_page_forgot-password-account-sign-in-link"
              to={ `/${ vanity }/login` }
            >
              { translate("Account Sign In") }
            </Link>
          </div>
          <LoginFlowLogo />
          <h1 className="headline">
            { translate("Reset Password") }
          </h1>
          <p className="label_medium">
            { translate("To request a reset password link") }
          </p>
          <LoginFlowCustomer />
          <Input
            error={ API.isError(forgotPasswordEmailAttempt) }
            InputProps={ {
              id: "cp_page_forgot-password-email-input",
              inputProps: { maxLength: Constant.USERNAME_MAX_LENGTH },
            } }
            onChange={ (e) => setEmail(e.target.value) }
            onKeyDown={ (e) => {
              if (
                e.key === "Enter"
                && MiscHelper.emailRegex.test(email)
                && forgotPasswordEmailAttempt !== "loading"
              ) {
                dispatch(sendForgotPasswordEmail(vanity || "", email))
              }
            } }
            placeholder={ translate("Email") }
            value={ email }
          />
          { API.isError(forgotPasswordEmailAttempt) && (
            <span>
              { translate("There was an error sending an email to that address") }
            </span>
          ) }
          <aside>
            <Link
              id="cp_page_forgot-password-sign-in-with-account-link"
              to={ `/${ vanity }/login` }
            >
              { translate("Sign In With Account") }
            </Link>
            <Link
              id="cp_page_forgot-password-sign-in-with-email-link"
              to={ `/${ vanity }/login/email` }
            >
              { translate("Sign In With Email") }
            </Link>
          </aside>
          <Button
            disabled={
              email.length === 0
              || !MiscHelper.emailRegex.test(email)
              || forgotPasswordEmailAttempt === "loading"
            }
            id="cp_page_forgot-password-submit-button"
            label={
              forgotPasswordEmailAttempt === "loading"
                ? <LoadingIndicator size={ 24 } />
                : translate("Submit")
            }
            onClick={ () => dispatch(sendForgotPasswordEmail(vanity || "", email)) }
            tabIndex={ 0 }
            type="submit"
          />
        </section>
      </div>
    </div>
  )
}

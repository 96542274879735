import React from "react"
import "./timestamp.sass"

type Props = {
    time: string,
    subInfo?: string
    className?: string
    id?: string
}

export default function Timestamp({
  time, subInfo, className, id,
}: Props) {
  const classes = [ "cp_data-grid_activity-timestamp" ]
  if (className) classes.push(className)
  return (
    <div id={ id } className={ classes.join(" ") }>
      <p className="cp_data-grid_activity-timestamp-date">
        { time }
      </p>
      <p className="cp_data-grid_activity-timestamp-subInfo">
        { subInfo }
      </p>
    </div>
  )
}

import React from "react"
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material"

export interface OptionsListProps {
    options: string[],
    onClick: (option: string) => void
    selectedOption: string
    label: string
}

const selectedClassName = "cp_component_options-list-item_selected"

function OptionsList({
  options,
  onClick,
  selectedOption,
  label,
}: OptionsListProps) {
  return (
    <div>
      <p className="cp_component_options-list-label label_small-caps-semibold">
        { label }
        :
      </p>
      <List dense={ true } component="div" role="list" className="cp_component_options-list">
        { options.map((option: string) => {
          const labelId = `transfer-list-item-${ option }-label`

          const className = selectedOption === option ? selectedClassName : ""

          return (
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
              key={ option }
              role="listitem"
              className={ className }
              id={ option }
              onClick={ () => onClick(option) }
            >
              <ListItemButton>
                <ListItemText
                  id={ labelId }
                  primary={ option }
                />
              </ListItemButton>
            </ListItem>
          )
        }) }
      </List>
    </div>
  )
}

export default OptionsList

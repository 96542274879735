import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import LanguagesChart from "../../Audience/LanguagesChart"
import "../../Audience/index.sass"

export default function LanguagesChartWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.LanguagesChart" })
  const {
    audience,
  } = useSelector(({ publicList }) => publicList)
  if (audience === "loading" || audience === "init") {
    return (
      <LoadingIndicatorCard />
    )
  }
  if (audience.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <LanguagesChart audienceDemographics={ audience.payload.publicSocialAccount.audienceDemographics } />
  )
}

import React from "react"
import { useTranslation } from "react-i18next"

import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import Vocation from "../../Audience/Vocation"
import { useSelector } from "../../../../state/hooks"

export default function VocationWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.Vocation" })
  const { audience, profile } = useSelector(({ socialProfile }) => socialProfile)
  if (audience === "loading" || audience === "init" || profile === "loading" || profile === "init") {
    return (
      <LoadingIndicatorCard />
    )
  }
  if (audience.status === "error" || profile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <Vocation
      audienceDemographics={ audience.payload.socialAccount.audienceDemographics }
      socialProfile={ profile.payload.socialAccount }
    />
  )
}

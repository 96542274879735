import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Notification } from "../../util/types"

export interface NotificationState {
  hasNotifications: boolean
  notifications: Notification[]
}

// Initialize state
const initialState: NotificationState = {
  hasNotifications: true,
  notifications: [],
}

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>): NotificationState => ({
      ...state,
      hasNotifications: true,
      notifications: [ ...state.notifications, action.payload ],
    }),
    removeAllNotifications: (state): NotificationState => ({
      ...state,
      hasNotifications: false,
      notifications: [],
    }),
  },
})

export const { addNotification, removeAllNotifications } = notificationSlice.actions
export default notificationSlice.reducer

import React, {
  JSX,
  useEffect,
  useRef,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import LoadingIndicator from "../../component/LoadingIndicator"

export default function Logout(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "page.Logout" })

  const initializedRef = useRef(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (initializedRef.current) {
      return
    }

    initializedRef.current = true

    fetch("/api/logout").then(async (result) => {
      if (result.ok) {
        const { redirectUrl } = await result.json()

        window.location.href = redirectUrl
        return
      }

      // In the event API returns an error during logout, simply redirect to
      // Find Your Team page
      navigate("/find-team")
    })
  }, [])

  return (
    <div className="cp_page_login">
      <div>
        <section className="card">
          <article>
            <LoadingIndicator size={ 62 } />
          </article>

          <h1 className="headline">
            { translate("Logging you out") }
          </h1>
        </section>
      </div>
    </div>
  )
}

import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  GridColDef,
  GridColumnHeaderTitle,
  GridRowParams,
} from "@mui/x-data-grid"
import DataGrid from "../../../../DataGrid"
import EntityInfoRow from "../../../../EntityInfoRow"
import { AdCampaignFragment } from "../../../../../graphql"
import EmptyElement from "../../../../EmptyElement"
import NetworkFilterMenu from "../NetworkFilterMenu"

type Props = {
  rows: AdCampaignFragment[]
  setSelectedIds: (adIds: string[]) => void
  selectedIds: string[]
  onRowClick?: (row: GridRowParams) => void
}

export default function CampaignAdsTable({
  rows, setSelectedIds, selectedIds, onRowClick,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignAdsTable" })

  const [ selectedNetwork, setSelectedNetwork ] = useState<null | string>(null)

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "platform",
      sortable: false,
      filterable: false,
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle label={ translate("Platform") } columnWidth={ 60 } />
          <NetworkFilterMenu setSelectedNetwork={ setSelectedNetwork } />
        </>
      ),
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          name={ params.row.network }
          network={ params.row.network }
          networkAvatarOnly={ true }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "ad-campaign",
      headerName: translate("Ad Campaign"),
      sortable: false,
      renderCell: (params) => (
        <p>{ params.row.name }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 5,
    },
  ], [ translate ])

  const filteredRows = rows.map((row) => ({
    id: row.id,
    name: row.name,
    // TODO: At least on dev env data, the backend doesn't seem to uniformly return
    // "Facebook" or "Meta" for the platform so the network icon isn't properly rendering
    network: row.platform,
  })).filter((row) => selectedNetwork === null || row.network === selectedNetwork)

  return (
    <DataGrid
      className="cp_component-campaign-ads-table"
      getRowId={ (row) => row.id }
      checkboxSelection={ true }
      onRowSelectionModelChange={ (ids) => setSelectedIds(ids.map((id) => id.toString())) }
      onRowClick={ onRowClick }
      rowSelectionModel={ selectedIds }
      disableRowSelectionOnClick={ true }
      rowHeight={ 90 }
      columnHeaderHeight={ 40 }
      columns={ COLUMNS }
      disableColumnReorder={ true }
      hideFooter={ true }
      pinnedColumns={
            {
              left: [ "__check__", "platform" ],
            }
        }
      rows={ filteredRows }
      slots={ {
        noRowsOverlay: EmptyElement,
        noResultsOverlay: EmptyElement,
      } }
    />
  )
}

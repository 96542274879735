// Type declaration for localStorage
// NOTE: Using `any` due to unresolved type issues, possibly
// environment-specific. Code includes checks to ensure localStorage
// availability before usage.
declare const localStorage: any

export default class StorageUtil {
  /**
   * Retrieves a value from localStorage.
   * @param key - The key to look for in localStorage.
   * @param defaultValue - The value to return if the key does not exist.
   * @returns The retrieved value or the default value.
   */
  static getLocalStorageVal<T>(key: string, defaultValue: T): T {
    // Check if localStorage is supported
    if (typeof localStorage === "undefined") {
      return defaultValue
    }

    const storedValue = localStorage.getItem(key)

    // Return default value if key is not found
    if (storedValue === null) {
      return defaultValue
    }

    try {
      // Attempt to parse the stored JSON value
      return JSON.parse(storedValue)
    } catch (e) {
      // Return default value if parsing fails
      return defaultValue
    }
  }

  /**
   * Stores a value in localStorage.
   * @param key - The key to use for storing the value.
   * @param value - The value to store.
   */
  static setLocalStorageVal<T>(key: string, value: T): void {
    // Check if localStorage is supported
    if (typeof localStorage === "undefined") {
      return
    }

    try {
      // Attempt to store the JSON stringified value
      localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      // Handle QuotaExceededError and other exceptions
      // eslint-disable-next-line no-console
      console.error(`Error setting localStorage key "${ key }":`, e)
    }
  }

  /**
 * Checks if a value exists in localStorage.
 * @param key - The key to use for storing the value.
 */
  static checkLocalStorageVal(key: string): boolean {
    // Check if localStorage is supported
    if (typeof localStorage === "undefined") {
      return false
    }

    const storedValue = localStorage.getItem(key)

    // Return false if key is not found
    if (storedValue === null) {
      return false
    }
    return true
  }
}

import React from "react"
import ReactDOM from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { init as initFullStory } from "@fullstory/browser"
import { CookiesProvider } from "react-cookie"

// This import comes from MUI and should not to be explicitly listed
// in package.json per MUI documentation
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from "@mui/x-license-pro"

import AppApolloProvider from "./app-providers/app-apollo-provider"
import Config from "./config/defaults"
import Router from "./route"
import { store } from "./state/store"

import "./i18n/config"
import "./index.sass"

LicenseInfo.setLicenseKey(Config.TIN.APP.muiLicenseKey)

initFullStory({
  orgId: Config.TIN.APP.fullStoryOrgId,
  devMode: process.env.NODE_ENV !== "production",
})

// eslint-disable-next-line no-undef
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AppApolloProvider>
      <ReduxProvider store={ store }>
        <CookiesProvider>
          <Router />
        </CookiesProvider>
      </ReduxProvider>
    </AppApolloProvider>
  </React.StrictMode>,
)

import * as React from "react"
import { useTranslation } from "react-i18next"
import "./campaign-widget-accounts.sass"

import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useMemo } from "react"
import { CampaignPageFragment } from "../../../graphql"
import { useSelector } from "../../../state/hooks"
import EntityInfoRow from "../../EntityInfoRow"
import { shorthandNumber } from "../../../util/miscHelper"
import LoadingIndicator from "../../LoadingIndicator"

interface CampaignWidgetAccountsProps {
  campaign: CampaignPageFragment
}

function NoAccountsOverlay() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.DashboardCampaignWidget" })
  return (
    <div className="cp_campaign-widget-accounts_component-no-results">
      { translate("No Accounts Added") }
    </div>
  )
}

export default function CampaignWidgetDeliverables({ campaign }: CampaignWidgetAccountsProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.DashboardCampaignWidget" })
  const { fetchingCampaignId } = useSelector(({ dashboard }) => dashboard)

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "account",
      headerName: translate("Account"),
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          avatarSrc={ params.row.profilePictureUrl }
          network={ params.row.network }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 230,
    },
  ], [ translate ])

  if (fetchingCampaignId === campaign.id) {
    return (
      <div className="cp_campaign-widget-accounts_component loading">
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <DataGrid
      className="cp_campaign-widget-accounts_component"
      disableRowSelectionOnClick={ true }
      rowHeight={ 90 }
      columns={ COLUMNS }
      hideFooter={ true }
      slots={ {
        noResultsOverlay: NoAccountsOverlay,
        noRowsOverlay: NoAccountsOverlay,
      } }
      rows={ campaign.campaignNetworkAccounts.map(({
        id,
        socialAccount,
      }, i, networkAccounts) => ({
        account: socialAccount.userName,
        profilePictureUrl: socialAccount.profilePictureUrl,
        followers: socialAccount.socialAccountStatistics.followers,
        id,
        network: socialAccount.network,
        networkAccount: networkAccounts[i],
      })) }
    />
  )
}

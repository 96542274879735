import React, { JSX } from "react"
import { useSearchParams } from "react-router-dom"
import Container from "@mui/material/Container"
import { useTranslation } from "react-i18next"

import * as GraphQL from "../../graphql"
import * as Misc from "../../util/miscHelper"
import * as SearchHelper from "../../util/searchHelper"
import { setSearchInput } from "../../state/searchSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import NetworkIconButton from "../NetworkButton"

interface Props {
  disabledNetworks?: GraphQL.Network[]
  hideDisabled?: boolean
}

export default function NetworkFilter({ disabledNetworks = [], hideDisabled = false }: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "controller.SearchFilter" })
  const dispatch = useDispatch()
  const searchInput = useSelector(({ search }) => search.searchInput)
  const { scopes } = useSelector(({ user: userSlice }) => userSlice)
  const [ searchParams, setSearchParams ] = useSearchParams()

  const handleSelectChange = (network: GraphQL.Network, setActive: boolean) => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newNetworks = [ ...searchInput.socialNetworks ]

    if (setActive) {
      newNetworks.push(network)
      newInput.socialNetworks = newNetworks
    } else {
      const filteredNetworks = newNetworks.filter((n) => n !== network)
      newInput.socialNetworks = filteredNetworks
    }

    dispatch(setSearchInput(newInput))
    SearchHelper.setSearchInputQueryParams(
      newInput,
      searchParams,
      setSearchParams,
    )
  }

  return (
    <div className="cp_component_search-filter-network">
      <p className="label_small-caps-bold">{ translate("Network") }</p>
      <Container className="cp_component_search-filter-network-container">
        { Misc.buildNetworkListByScope(scopes).map((currNetwork) => {
          if (hideDisabled && disabledNetworks.includes(currNetwork)) {
            return null
          }
          return (
            <NetworkIconButton
              key={ currNetwork }
              network={ currNetwork }
              isButtonDisabled={ disabledNetworks.includes(currNetwork) }
              isActive={
              searchInput.socialNetworks.some((n) => n === currNetwork)
              && !disabledNetworks.includes(currNetwork)
            }
              onClick={ () => {
                const networkIsActive = searchInput.socialNetworks
                  .some((n) => n === currNetwork)

                handleSelectChange(currNetwork, !networkIsActive)
              } }
              additionalClasses="cp_component_search-filter-network-filter-button"
            />
          )
        }) }
      </Container>
    </div>
  )
}

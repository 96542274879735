import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import { IconButton } from "@mui/material"
import {
  Add,
  Circle,
  NotificationsNone,
  Search,
} from "@mui/icons-material"

import Input from "../Input"
import Modal from "../Modal"
import { setCommGroupModalOpen } from "../../state/commGroupSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export default function ModalCommGroup(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCommGroup" })

  const dispatch = useDispatch()
  const commGroupModalOpen = useSelector(({ commGroup }) => commGroup.commGroupModalOpen)

  return (
    <Modal
      title={ translate("Create Communication Group") }
      primaryLabel={ translate("Create Group") }
      secondaryLabel={ translate("Cancel") }
      open={ commGroupModalOpen }
      closeAction={ () => dispatch(setCommGroupModalOpen(false)) }
      secondaryAction={ () => dispatch(setCommGroupModalOpen(false)) }
      primaryAction={ () => {} }
      maxWidth="xl"
      className="cp_component_modal-comm-group"
    >
      <section className="cp_component_modal-comm-group-form">
        <h5>{ translate("Details") }</h5>
        <Input
          className="cp_component_modal-comm-group-input"
          fullWidth={ true }
          label={ translate("Name") }
          placeholder={ translate("Enter Group Name") }
        />
        <h5>{ translate("Labels") }</h5>
        <Input
          label={ translate("Custom Label") }
          placeholder={ translate("Type Vertical Name") }
          InputProps={ {
            endAdornment: (
              <IconButton>
                <Add />
              </IconButton>
            ),
          } }
        />
        <p className="body_small input_description">
          { translate("Default labels are listed below") }
        </p>
        <h5>{ translate("Lists") }</h5>
        <p>
          { translate("Attach lists to this communication group") }
        </p>
        <p className="body_small">
          { translate("When you attach a list") }
        </p>
        <Input
          className="cp_component_modal-comm-group-list-input"
          fullWidth={ true }
          placeholder={ translate("Enter List Name") }
          InputProps={ {
            startAdornment: <Search />,
          } }
        />
      </section>
      <section className="cp_component_modal-comm-side-options">
        <article>
          <h5>{ translate("Campaign") }</h5>
          <p>
            { translate("Attach a campaign to this communication group") }
          </p>
          <p className="body_small">
            { translate("When you attach a campaign") }
          </p>
          <Input
            className="cp_component_modal-comm-group-input"
            fullWidth={ true }
            placeholder={ translate("Enter Campaign Name") }
            InputProps={ {
              startAdornment: <Search />,
            } }
          />
        </article>
        <article>
          <aside>
            <NotificationsNone />
            <h5>{ translate("Notifications") }</h5>
          </aside>
          <div className="cp_component_modal-comm-group-notifications-info">
            <p>
              { translate("Notify team members when there is activity") }
            </p>
            <ul>
              <li>
                <Circle />
                { translate("New conversation started") }
              </li>
              <li>
                <Circle />
                { translate("Replied to conversation") }
              </li>
              <li>
                <Circle />
                { translate("Content uploaded") }
              </li>
              <li>
                <Circle />
                { translate("Deliverable created") }
              </li>
            </ul>
          </div>
          <Input
            className="cp_component_modal-comm-group-list-input"
            fullWidth={ true }
            label={ translate("Subscribe Team Members") }
            placeholder={ translate("Type Team Member Name") }
            InputProps={ {
              startAdornment: <Search />,
            } }
          />
        </article>
      </section>
    </Modal>
  )
}

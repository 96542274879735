import { PersonalitySocialAccountInput } from "../../graphql"
import { ProfileConnection } from "../../util/types"

export const mapConnectionToProfileInput = (connection: ProfileConnection): PersonalitySocialAccountInput => ({
  foreignUserId: connection.foreignUserId,
  id: connection.id,
  network: connection.network,
  userName: connection.userName,
})

export default mapConnectionToProfileInput

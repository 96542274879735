export async function fetchListCsv(
  suggestionListId: string,
) {
  try {
    const response = await fetch("/api/suggestion-list/csv/social-accounts-as-columns", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        suggestion_list_id: suggestionListId,
      }),
    })

    return {
      status: "success",
      payload: response,
    }
  } catch (err: any) {
    return {
      status: "error",
      message: err.message,
    }
  }
}

export async function fetchSocialTrackerCsv(params: {
  postStart: number,
  postEnd: number,
  xref: string,
}) {
  const response = await fetch("/api/feed/latest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      limit: 1000,
      minTime: params.postStart,
      maxTime: params.postEnd,
      xref: params.xref,
    }),
  })
  return response
}

import React from "react"
import { useTranslation } from "react-i18next"
import UserListCard, { UserCardListRow } from "./UserListCard"
import { useDispatch, useSelector } from "../../../../../state/hooks"
import LoadingIndicatorCard from "../../../../LoadingIndicatorCard"
import { setManageModalCustomersOpen } from "../../../../../state/campaignSlice"
import { Scope } from "../../../../../util/types"

export default function ClientsCard() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignOverviewDetails" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()

  const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const { scopes } = useSelector(({ user }) => user)

  if (campaign === "init" || campaign === "loading") {
    return <LoadingIndicatorCard />
  }

  if (campaign.status === "error") {
    return <p>{ translateCommon("An unexpected error occurred!") }</p>
  }

  const {
    customers,
  } = campaign.payload.campaign

  const rows: UserCardListRow[] = customers.map((customer) => ({
    id: customer.id,
    name: customer.company?.name || "",
    logoUrl: customer.company?.logo?.url.address,
  }))

  return (
    <UserListCard
      rows={ rows }
      title={ translate("Clients") }
      emptyText={ translate("No Clients Added") }
      onButtonClick={ () => dispatch(setManageModalCustomersOpen(true)) }
      buttonEnabled={ scopes.includes(Scope.CAMPAIGN_MANAGEMENT) }
    />
  )
}

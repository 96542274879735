import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

import "./customers-overview-context-menu.sass"
import { useNavigate, useParams } from "react-router-dom"
import IconButton from "../IconButton"

interface Props {
  customerId: string
  id?: string
}

export default function CustomersOverviewContextMenu({ customerId, id }: Props) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomersOverviewContextMenu" })
  const { vanity } = useParams()
  const navigate = useNavigate()

  const onViewClick = () => {
    navigate(`/${ vanity }/customer-management/${ customerId }`)
  }

  const onEditClick = () => {
    // Placeholder until edit modal is created
  }

  return (
    <>
      <IconButton
        id={ id }
        className="cp_customers-overview-context-menu_icon-button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_customers-overview-context-menu_menu-component"
        transformOrigin={ {
          horizontal: "center",
          vertical: "top",
        } }
      >
        <MenuList
          dense={ true }
          className="menu-list"
        >
          <MenuItem
            className="menu-item"
            onClick={ onViewClick }
          >
            <ListItemText className="menu-item-label">
              { translate("View") }
            </ListItemText>
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={ onEditClick }
          >
            <ListItemText className="menu-item-label">
              { translate("Edit") }
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

import * as React from "react"
import TooltipMui, { TooltipProps } from "@mui/material/Tooltip"
import "./style.sass"

export default function Tooltip(props: TooltipProps) {
  const {
    title,
    children,
    ...restProps
  } = props

  return (
    <TooltipMui
      classes={ { tooltip: "cp-tooltip" } }
      title={ title }
      { ...restProps }
    >
      { children }
    </TooltipMui>
  )
}

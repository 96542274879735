import React from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useSelector } from "../../../state/hooks"
import LoadingIndicator from "../../LoadingIndicator"
import AccountInsights from "../AccountInsights/AccountInsights"

export default function AccountInsightsWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { listProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)
  if (listProfile === "loading" || listProfile === "init") {
    return (
      <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
    )
  }
  if (listProfile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <AccountInsights socialAccount={ listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount } />
  )
}

import React, { useEffect, useMemo } from "react"
import "./style.sass"
import { Network } from "../../graphql"
import NetworkStatisticCard from "./NetworkStatisticCard"
import { useDispatch, useSelector } from "../../state/hooks"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import ErrorHandler from "../ErrorHandler"
import { fetchPersonalitySocialAccountCount } from "../../state/dashboardSlice"
import { Scope } from "../../util/types"
import { isSuccess } from "../../util/apiClient"

export default function DashboardOverview() {
  const { personalitySocialAccountCount } = useSelector(({ dashboard }) => dashboard)
  const { scopes, user: currentUser } = useSelector(({ user }) => user)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPersonalitySocialAccountCount())
  }, [])

  const totalProfiles = useMemo(() => {
    if (isSuccess(currentUser)) {
      return currentUser.payload.currentUser?.customer.totalPersonalities || 0
    }
    return 0
  }, [ currentUser ])

  if (personalitySocialAccountCount === "init" || personalitySocialAccountCount === "loading") {
    return <LoadingIndicatorCard size={ 50 } />
  }

  if (personalitySocialAccountCount.status === "error") {
    return <ErrorHandler err={ personalitySocialAccountCount } />
  }

  const countByNetwork = personalitySocialAccountCount.payload.getPersonalitySocialAccountCountByCustomer

  return (
    <div className="cp_component_dashboard">
      <div className="network-overview-container">
        <NetworkStatisticCard
          id={ `cp_component_network-statistic-card-${ Network.Instagram.toLowerCase() }` }
          network={ Network.Instagram }
          profileCount={
            countByNetwork.find((item) => item.networkType === Network.Instagram.toLowerCase())?.personalityCount || 0
          }
          totalProfiles={ totalProfiles }
        />
        <NetworkStatisticCard
          id={ `cp_component_network-statistic-card-${ Network.Facebook.toLowerCase() }` }
          network={ Network.Facebook }
          profileCount={
            countByNetwork.find((item) => item.networkType === Network.Facebook.toLowerCase())?.personalityCount || 0
          }
          totalProfiles={ totalProfiles }
        />
        { scopes.includes(Scope.FEATURE_TIKTOK) && (
        <NetworkStatisticCard
          id={ `cp_component_network-statistic-card-${ Network.Tiktok.toLowerCase() }` }
          network={ Network.Tiktok }
          profileCount={
            countByNetwork.find((item) => item.networkType === Network.Tiktok.toLowerCase())?.personalityCount || 0
          }
          totalProfiles={ totalProfiles }
        />
        ) }
        <NetworkStatisticCard
          id={ `cp_component_network-statistic-card-${ Network.Youtube.toLowerCase() }` }
          network={ Network.Youtube }
          profileCount={
            countByNetwork.find((item) => item.networkType === Network.Youtube.toLowerCase())?.personalityCount || 0
          }
          totalProfiles={ totalProfiles }
        />
        { scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT) && (
        <NetworkStatisticCard
          id={ `cp_component_network-statistic-card-${ Network.Snapchat.toLowerCase() }` }
          network={ Network.Snapchat }
          profileCount={
            countByNetwork.find((item) => item.networkType === Network.Snapchat.toLowerCase())?.personalityCount || 0
          }
          totalProfiles={ totalProfiles }
        />
        ) }
      </div>
    </div>
  )
}

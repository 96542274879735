import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import "./customer-header.sass"
import { EditOutlined } from "@mui/icons-material"
import dayjs from "dayjs"
import { IconButton } from "@mui/material"

import * as API from "../../util/apiClient"
import { CustomerFragment } from "../../graphql"
import { getCustomer, resetEditedCustomerStatus } from "../../state/createCustomer"
import { getCustomer as refreshCustomer } from "../../state/customerSlice"
import { useSelector, useDispatch } from "../../state/hooks"
import Avatar from "../Avatar"
import Tooltip from "../Tooltip"
import * as Constant from "../../util/constant"

type Props = {
  customer: CustomerFragment
}

export default function CustomerHeader({ customer }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerHeader" })
  const { editCustomer, editedCustomerStatus } = useSelector(({ createCustomer }) => createCustomer)
  const dispatch = useDispatch()
  const { customerID } = useParams()

  const openEditCustomer = () => {
    if (customerID && editCustomer === "init") {
      dispatch(getCustomer({ customerId: customerID }))
    }
  }

  useEffect(() => {
    if (API.isSuccess(editedCustomerStatus) && customerID) {
      dispatch(resetEditedCustomerStatus())
      dispatch(refreshCustomer({ customerId: customerID }))
    }
  }, [ editedCustomerStatus ])

  return (
    <div className="cp_customer-overview-header">
      <div className="cp_customer-overview-header_info-container">
        <div className="cp_customer-overview-header_avatar-container">
          <Avatar size="lg" src={ customer.company?.logo?.url.address } className="cp_customer-overview-header_avatar">
            { customer.company?.name[0] }
          </Avatar>
        </div>
        <div className="cp_customer-overview-header_details">
          <p className="cp_customer-overview-header_group-name">
            { customer.company?.name }
          </p>
          <p className="cp_customer-overview-header_created">
            { `${ translate("Created") } ${ dayjs(customer.created * 1000)
              .format(Constant.MONTH_DAY_ABBR_FORMAT) }` }
          </p>
        </div>
      </div>
      <div className="cp_customer-overview-header_stats">
        <div className="cp_customer-overview-header_user-count-container">
          <h4>{ translate("USERS") }</h4>
          <h5>{ customer.userCount }</h5>
        </div>
        <Tooltip title={ translate("Edit") }>
          <IconButton
            id="cp_customer-overview-header_update-customer"
            size="small"
            onClick={ openEditCustomer }
          >
            <EditOutlined />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import ToastController from "../../controller/Toast"

import "./style.sass"

import CampaignTabs from "../../component/CampaignTabs/Tabs"
import PageShell from "../../component/PageShell"
import { fetchCampaign, setCampaignDeliverablesStartsWithFilter } from "../../state/campaignSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import { isError } from "../../util/apiClient"
import LoadingIndicator from "../../component/LoadingIndicator"

export default function Campaign() {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Campaign" })
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)

  const dispatch = useDispatch()
  const { campaignID } = useParams()

  useEffect(() => {
    if (!campaignID) return
    dispatch(fetchCampaign({ campaignId: campaignID }))
    // Included here to prevent previous searches being carried to other campaign pages
    dispatch(setCampaignDeliverablesStartsWithFilter(""))
  }, [ campaignID ])

  const campaignName: React.ReactNode = useMemo(() => {
    if (campaign === "init" || campaign === "loading" || isError(campaign)) return <LoadingIndicator size={ 20 } />
    if (isError(campaign)) return translate("Unable to load campaign name")
    return campaign.payload?.campaign?.name || ""
  }, [ campaign ])

  return (
    <div className="cp_campaign_page">
      <ToastController />
      <PageShell title={ campaignName } />
      <div className="cp_campaign_page-container">
        <section className="cp_campaign_page-tabs">
          <CampaignTabs />
        </section>
      </div>
    </div>
  )
}

import React, { JSX } from "react"
import {
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom"

import * as API from "../../util/apiClient"
import Modals from "./Modals"
import NavigationBar from "../NavigationBar"
import TopNavigation from "../TopNavigation"
import { SUPPORT_PAGE_URL } from "../../util/constant"
import { Scope } from "../../util/types"
import { logout } from "../../state/userSlice"
import { removeAllNotifications } from "../../state/notificationSlice"
import { useDispatch, useSelector } from "../../state/hooks"

type Props = {
  avoidRedirect?: boolean,
  showSideNav?: boolean,
  showTopNav?: boolean,
  title: React.ReactNode,
}

export default function PageShell({
  avoidRedirect = false,
  showSideNav = true,
  showTopNav = true,
  title,
}: Props): JSX.Element | null {
  const navigate = useNavigate()
  const { vanity } = useParams()
  const dispatch = useDispatch()
  const notifications = useSelector(({ notifications: notificationsSlice }) => notificationsSlice)
  const { theme } = useSelector(({ themeMode: themeModeSlice }) => themeModeSlice)
  const { user: currentUser, scopes } = useSelector(({ user }) => user)

  // If the URL vanity parameter does not match the user's company, redirect
  // to login flow.
  if (
    API.isSuccess(currentUser)
    && currentUser.payload.currentUser?.customer.vanity !== vanity
    && !avoidRedirect
  ) {
    return <Navigate to="/find-team" replace={ true } />
  }

  // If current user exists but scopes do not include REACT_PLATFORM, redirect
  // to 404 page.
  if (
    API.isSuccess(currentUser)
    && !scopes.includes(Scope.REACT_PLATFORM)
  ) {
    const navigableVanity = currentUser.payload.currentUser?.customer.vanity || vanity || ""
    return <Navigate to={ `/${ navigableVanity }/404` } replace={ true } />
  }

  if (!API.isSuccess(currentUser)) return null

  return (
    <>
      { showSideNav && (
        <NavigationBar navigate={ navigate } />
      ) }
      { showTopNav && (
        <TopNavigation
          title={ title }
          hasNotifications={ notifications.hasNotifications }
          theme={ theme }
          logout={ () => dispatch(logout(navigate)) }
          onNotificationsClick={ () => dispatch(removeAllNotifications()) }
          onSupportClick={ () => window.open(SUPPORT_PAGE_URL, "_blank") }
          onSettingsClick={ () => {} }
        />
      ) }

      { /* Single Mount point for Modals */ }
      <Modals />
    </>
  )
}

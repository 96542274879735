import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  FlagCircle,
  Share,
  Archive,
  Edit,
  Download,
} from "@mui/icons-material"
import { IconButton } from "@mui/material"
import dayjs from "dayjs"

import * as Constant from "../../util/constant"
import Avatar from "../Avatar"
import LoadingIndicator from "../LoadingIndicator"
import NetworkIcon from "../NetworkIcon"
import Pill from "../Pill"
import Tooltip from "../Tooltip"
import { SuggestionListMode } from "../../graphql"
import { openConfirmationModal } from "../../state/confirmationModalSlice"
import { openCopyLinkModal } from "../../state/copyLinkModalSlice"
import { pushToast } from "../../state/toastSlice"
import { setListModalMode, setListModalOpen } from "../../state/listModalFormSlice"
import { shorthandNumber } from "../../util/miscHelper"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  fetchList,
  getListCsv,
  setIsArchiving,
  setIsDownloading,
  submitArchiveSuggestionList,
  submitUnarchiveSuggestionList,
} from "../../state/listSlice"

import "./metrics.sass"
import "./modals.sass"
import "./overview.sass"

export default function ListOverview() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListOverview" })

  const {
    list: fetchedList, isDownloading, isArchiving,
  } = useSelector(({ list }) => list)

  const dispatch = useDispatch()

  const numberOfApprovedAccounts = React.useMemo(() => {
    let total = 0
    if (fetchedList === "init" || fetchedList === "loading" || fetchedList.status === "error") return total
    const {
      suggestionListCategories,
    } = fetchedList.payload.suggestionListById

    suggestionListCategories.forEach(({ suggestionListSocialAccounts }) => {
      suggestionListSocialAccounts.forEach(({ approvalStatus }) => {
        if (approvalStatus?.value === true) total += 1
      })
    })
    return total
  }, [ fetchedList ])

  const demographicTarget = React.useMemo(() => {
    if (fetchedList === "init" || fetchedList === "loading" || fetchedList.status === "error") return ""
    const {
      sexes,
      minAge,
      maxAge,
    } = fetchedList.payload.suggestionListById

    const demographics = []
    const ages = []

    if (minAge) ages.push(minAge)
    if (maxAge) ages.push(maxAge)

    const ageDemographic = ages.join(" - ")
    const sexDemographic = sexes.join(", ")

    if (ageDemographic) demographics.push(ageDemographic)
    if (sexDemographic) demographics.push(sexDemographic)

    return demographics.join(" | ")
  }, [ fetchedList ])

  if (fetchedList === "init" || fetchedList === "loading") {
    return (
      <div className="cp_list-overview_component-loading">
        <LoadingIndicator size={ 50 } />
      </div>
    )
  }
  if (fetchedList.status === "error") {
    return (
      <div className="cp_list-overview_component-error">
        <p>
          { translate("Unable to fetch list.") }
        </p>
      </div>
    )
  }

  const {
    id,
    network,
    avatar,
    name,
    description,
    suggestionListGroup,
    creator,
    suggestionListMode,
    numberOfLinkedSocialAccounts,
    reach,
    created,
    archived,
    publicUrl,
    public: isPubliclyAvailable,
  } = fetchedList.payload.suggestionListById

  const createdDate = dayjs((created * 1000)).format(Constant.LONGFORM_DATE)

  const handleDownloadClick = async () => {
    dispatch(setIsDownloading(true))
    dispatch(getListCsv({
      variables: {
        id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Success!"),
          additionalInfo: translate("Your download is complete"),
        }))
        dispatch(setIsDownloading(false))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: translate("There was an error. Please try again!"),
        }))
        dispatch(setIsDownloading(false))
      },
    }))
  }

  const handleArchiveSubmit = async () => {
    dispatch(setIsArchiving(true))
    await dispatch(submitArchiveSuggestionList({
      variables: {
        id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Archive List"),
          additionalInfo: translate("The selected list is successfully archived!"),
        }))
        dispatch(fetchList(id))
      },
      onError: () => dispatch(pushToast({
        type: "error",
        message: translate("There was an error. Please try again!"),
      })),
    }))
    dispatch(setIsArchiving(false))
  }

  const handleUnarchiveSubmit = async () => {
    dispatch(setIsArchiving(true))
    await dispatch(submitUnarchiveSuggestionList({
      variables: {
        id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Unarchive List"),
          additionalInfo: translate("The selected list is successfully unarchived!"),
        }))
        dispatch(fetchList(id))
      },
      onError: () => dispatch(pushToast({
        type: "error",
        message: translate("There was an error. Please try again!"),
      })),
    }))
    dispatch(setIsArchiving(false))
  }

  const handleArchiveModalOpen = () => {
    dispatch(openConfirmationModal({
      title: translate("Archive List"),
      subtitle: translate("Archived lists can be found using the filter in the Lists panel."),
      subtext: `${ translate("Are you sure you want to archive") } ${ name }?`,
      primaryLabel: translate("Archive"),
      onConfirm: handleArchiveSubmit,
    }))
  }

  const handleUnarchiveModalOpen = () => {
    dispatch(openConfirmationModal({
      title: translate("Unarchive List"),
      subtext: `${ translate("Are you sure you want to unarchive") } ${ name }?`,
      primaryLabel: translate("Unarchive"),
      onConfirm: handleUnarchiveSubmit,
    }))
  }

  return (
    <div className="cp_list-overview_component">
      <div className="cp_list-overview_component-info">
        <div className="cp_list-overview_component-info-flex">
          <div className="cp_list-overview_component-info-avatar-container">
            <Avatar size="lg" src={ avatar?.url.address } className="cp_list-overview_component-info-avatar">
              { name[0] }
            </Avatar>
          </div>
          <div className="cp_list-overview_component-info-name-container">
            { suggestionListGroup && (
              <p className="cp_list-overview_component-info-group-name">
                { suggestionListGroup.name }
              </p>
            ) }
            <p className="cp_list-overview_component-info-name">
              { suggestionListMode === SuggestionListMode.Campaign && (
                <FlagCircle className="cp_list-overview_component-info-name-icon" />
              ) }
              { name }
            </p>
            <p className="cp_list-overview_component-info-creator-name">
              { `${ translate("Created by") } ${ creator.username }` }
            </p>
            <p className="cp_list-overview_component-info-created-date">
              { `${ translate("on") } ${ createdDate }` }
            </p>
          </div>
        </div>
      </div>
      <div className="cp_list-overview_component-right-column">
        <div className="cp_list-overview_component-metrics-wrapper">
          <div className="cp_list-overview_component-metrics">
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-label">
                { translate("NETWORK") }
              </p>
              <p className="cp_list-overview_component-metric-value">
                <NetworkIcon
                  network={ network }
                  isActive={ true }
                />
              </p>
            </div>
            { demographicTarget && (
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-label">
                { translate("DEMOGRAPHIC") }
              </p>
              <Pill label={ demographicTarget } />
            </div>
            ) }
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-label">
                { translate("ACCOUNTS") }
              </p>
              <p className="cp_list-overview_component-metric-value">
                { numberOfLinkedSocialAccounts }
              </p>
            </div>
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-label">
                { translate("REACH") }
              </p>
              <p className="cp_list-overview_component-metric-value">
                { shorthandNumber(parseInt(reach, 10)) }
              </p>
            </div>
            { suggestionListMode === SuggestionListMode.Campaign && (
            <div className="cp_list-overview_component-metric">
              <p className="cp_list-overview_component-metric-label">
                { translate("APPROVED") }
              </p>
              <p className="cp_list-overview_component-metric-value">
                { shorthandNumber(numberOfApprovedAccounts) }
              </p>
            </div>
            ) }
          </div>
          <p>
            { description }
          </p>
        </div>
        <div className="cp_list-overview_component-controls">
          <Tooltip title={ archived ? translate("Unarchive") : translate("Archive") }>
            <IconButton onClick={ () => archived ? handleUnarchiveModalOpen() : handleArchiveModalOpen() } size="small">
              { isArchiving ? <LoadingIndicator /> : <Archive /> }
            </IconButton>
          </Tooltip>
          <Tooltip title={ translate("Share") }>
            <IconButton
              disabled={ !isPubliclyAvailable }
              onClick={ () => dispatch(openCopyLinkModal({
                link: publicUrl,
                title: translate("Share"),
                subtitle: translate("Copy the public URL for this suggestion list below."),
              }))
               }
              size="small"
            >
              <Share />
            </IconButton>
          </Tooltip>
          <Tooltip
            onClick={ () => {
              dispatch(setListModalMode({ isEditMode: true, listId: id }))
              dispatch(setListModalOpen(true))
            } }
            title={ translate("Edit")
            }
          >
            <IconButton size="small">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={ translate("Download") }>
            <IconButton onClick={ handleDownloadClick } size="small">
              { isDownloading ? <LoadingIndicator /> : <Download /> }
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { Delete, Download } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import IconButton from "../IconButton"
import NetworkIconButton from "../NetworkButton"
import Pill from "../Pill"
import { deleteSocialTracker, getSocialTrackers } from "../../state/slidingPanelSlice/socialTracker"
import { formatPercent } from "../../util/miscHelper"
import { openConfirmationModal } from "../../state/confirmationModalSlice"
import { openSocialTrackerDownloadCSVModal } from "../../state/socialTrackerDownloadCSVModalSlice"
import { setSocialTrackerSearchInput, setSocialTrackers } from "../../state/slidingPanelSlice"
import { useSelector, useDispatch } from "../../state/hooks"

import "./style.sass"
import { fetchSocialTracker } from "../../state/socialTrackerPage"

export default function SocialTrackerInfo() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.SocialTrackerInfo" })
  const { socialTracker } = useSelector(({ socialTrackerPage }) => socialTrackerPage)
  const dispatch = useDispatch()
  const { trackerID } = useParams()

  if (!API.isSuccess(socialTracker)) return null

  const { getContentAlerts } = socialTracker.payload
  const currentSocialTracker = getContentAlerts.filter((alert) => alert.xref === trackerID)
  if (!currentSocialTracker.length || !currentSocialTracker[0].params) return null
  const { contentParams, socialNetworks } = currentSocialTracker[0].params
  const {
    keywords = [],
    optionalImageTags = [],
    minPostEngagement = null,
    sponsored = null,
  } = contentParams || {}

  const activeImageTags: string[] = optionalImageTags.flatMap((tagObj) => tagObj.tags)

  const onSuccess = () => {
    dispatch(setSocialTrackerSearchInput(""))
    dispatch(getSocialTrackers(""))
    if (trackerID) {
      dispatch(fetchSocialTracker(trackerID))
    }
  }

  return (
    <div className="cp_social_tracker_sidepanel">
      { socialNetworks.length > 0 && (
        <div className="cp_social_tracker_sidepanel-sections networks">
          <p className="section-title">{ translate("Network") }</p>
          { socialNetworks.map((currNetwork) => (
            <NetworkIconButton
              key={ currNetwork }
              network={ currNetwork }
              isButtonDisabled={ true }
              isActive={ true }
              additionalClasses="cp_social_tracker_sidepanel-networks-button"
              onClick={ () => {} }
            />
          )) }
        </div>
      ) }
      <div className="cp_social_tracker_sidepanel-sections keywords">
        <p className="section-title">{ translate("Keywords") }</p>
        { keywords.length
          ? (
            <div className="pill-container">
              { keywords.map((keyword) => (
                <Pill
                  label={ keyword.tags[0] }
                />
              )) }
            </div>
          )
          : (
            <p className="missing-info-message">
              { translate("No Keywords Added") }
            </p>
          )
        }
      </div>
      <div className="cp_social_tracker_sidepanel-sections image-tags">
        <p className="section-title">{ translate("Image Tags") }</p>
        { activeImageTags.length
          ? (
            <div className="pill-container">
              { activeImageTags.map((tag) => (
                <Pill
                  label={ tag }
                />
              )) }
            </div>
          )
          : (
            <p className="missing-info-message">
              { translate("No Image Tags Added") }
            </p>
          )
        }
      </div>
      <div className="cp_social_tracker_sidepanel-sections eng-rate">
        <p className="section-title">{ translate("Minimum Engagement Rate") }</p>
        { minPostEngagement
          ? (
            <div className="pill-container">
              <Pill label={ formatPercent(0, minPostEngagement) } />
            </div>
          )
          : (
            <p className="missing-info-message">
              { translate("No Min Engagement Rate Added") }
            </p>
          )
        }
      </div>
      <div className="cp_social_tracker_sidepanel-sections sponsored">
        <p className="section-title">{ translate("Sponsored Content Only") }</p>
        <p className="sponsor-value">
          { sponsored ? `${ translate("ON") }` : `${ translate("OFF") }` }
        </p>
      </div>
      <div className="cp_social_tracker_sidepanel-sections actions">
        <p className="actions-wrapper">
          <IconButton
            className="action-button"
            id="cp_component_social-tracker-action-button-download"
            onClick={ () => {
              dispatch(openSocialTrackerDownloadCSVModal(currentSocialTracker[0]))
            } }
          >
            <Download />
          </IconButton>
          <IconButton
            className="action-button"
            id="cp_component_social-tracker-action-button-delete"
            onClick={ (() => {
              const activeSocialTracker = currentSocialTracker[0]
              dispatch(openConfirmationModal({
                title: translate("Delete Tracker"),
                subtext: (
                  <p>
                    { translate("Are you sure you want to delete") }
                    { " " }
                    <b>{ activeSocialTracker.name }</b>
                    ?
                  </p>),
                primaryLabel: translate("Delete"),
                secondaryLabel: translate("Cancel"),
                onConfirm: () => {
                  dispatch(deleteSocialTracker({
                    variables: { id: activeSocialTracker.id },
                    onSuccess,
                    onError: () => dispatch(setSocialTrackers({ status: "error" })),
                  }))
                },
              }))
            }
            ) }
          >
            <Delete />
          </IconButton>
        </p>
      </div>
    </div>
  )
}

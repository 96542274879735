import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  Share,
  Archive,
  Edit,
} from "@mui/icons-material"
import { IconButton } from "@mui/material"

import "./overview.sass"
import "./modals.sass"

import * as API from "../../util/apiClient"
import { useDispatch, useSelector } from "../../state/hooks"
import LoadingIndicator from "../LoadingIndicator"
import Tooltip from "../Tooltip"
import {
  fetchListGroup,
  setArchiveModalOpen,
  submitArchiveSuggestionListGroup,
  submitUnarchiveSuggestionListGroup,
} from "../../state/listGroupSlice"
import { setListGroupModalOpen, setListGroupToEdit } from "../../state/templateSlice"
import Modal from "../Modal"
import ListGroupHeaderCard from "../ListGroupHeaderCard"
import { openCopyLinkModal } from "../../state/copyLinkModalSlice"

export default function ListGroupOverview() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListGroupOverview" })
  const {
    listGroup: fetchedListGroup, archiveModalOpen, isArchiving,
  } = useSelector(({ listGroup }) => listGroup)

  const dispatch = useDispatch()

  const [ numberOfLists, numberOfAccounts, numberOfFollowers ] = React.useMemo(() => {
    let totalLists = 0
    let totalAccounts = 0
    let totalFollowers = 0

    if (
      fetchedListGroup === "init"
      || fetchedListGroup === "loading"
      || fetchedListGroup.status === "error"
    ) {
      return [ totalAccounts, totalAccounts, totalFollowers ]
    }

    const {
      suggestionLists,
    } = fetchedListGroup.payload.suggestionListGroupById

    suggestionLists.forEach((l) => {
      totalLists += 1
      totalAccounts += l.numberOfLinkedSocialAccounts
      totalFollowers += parseInt(l.reach, 10)
    })
    return [ totalLists, totalAccounts, totalFollowers ]
  }, [ fetchedListGroup ])

  if (fetchedListGroup === "init" || fetchedListGroup === "loading") {
    return (
      <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
    )
  }
  if (API.isError(fetchedListGroup)) {
    return (
      <div className="cp_list-overview_component-error">
        <p>
          { translate("Unable to fetch list group.") }
        </p>
      </div>
    )
  }

  const {
    id,
    archived,
    name,
    publicUrl,
  } = fetchedListGroup.payload.suggestionListGroupById

  return (
    <>
      <div className="cp_list-group-overview_component">
        <ListGroupHeaderCard
          name={ name }
          numberOfAccounts={ numberOfAccounts }
          numberOfFollowers={ numberOfFollowers }
          numberOfLists={ numberOfLists }
        />
        <div className="cp_list-group-overview_component-right-column">
          <div className="cp_list-group-overview_component-controls">
            <Tooltip title={ !archived ? translate("Archive") : translate("Archived") }>
              <IconButton onClick={ () => dispatch(setArchiveModalOpen(true)) } size="small">
                { isArchiving ? <LoadingIndicator /> : <Archive /> }
              </IconButton>
            </Tooltip>
            <Tooltip title={ translate("Share") }>
              <IconButton
                onClick={ () => dispatch(openCopyLinkModal({
                  link: publicUrl,
                  title: translate("Share Group"),
                  subtitle: translate("Copy the public URL for this suggestion list group below."),
                }))
               }
                size="small"
              >
                <Share />
              </IconButton>
            </Tooltip>
            <Tooltip title={ translate("Edit") }>
              <IconButton
                size="small"
                onClick={ () => {
                  dispatch(setListGroupModalOpen(true))
                  dispatch(setListGroupToEdit({
                    listGroup: fetchedListGroup.payload.suggestionListGroupById,
                    listGroupUpdateCallback: () => dispatch(fetchListGroup(id)),
                  }))
                } }
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      { !archived
        ? (
          <Modal
            className="cp_list-group-overview_modal-archive"
            open={ archiveModalOpen }
            title={ translate("Archive Group") }
            subtitle={ `${ name } ${ translate("and all of its lists will be archived") }` }
            primaryLabel={ translate("Archive") }
            secondaryLabel={ translate("Cancel") }
            primaryAction={ () => dispatch(submitArchiveSuggestionListGroup(id, translate)) }
            secondaryAction={ () => dispatch(setArchiveModalOpen(false)) }
            closeAction={ () => dispatch(setArchiveModalOpen(false)) }
          >
            <p>{ `${ translate("Are you sure you want to archive this group?") }` }</p>
          </Modal>
        )
        : (
          <Modal
            className="cp_list-group-overview_modal-unarchive"
            open={ archiveModalOpen }
            title={ translate("Unarchive Group") }
            primaryLabel={ translate("Unarchive") }
            secondaryLabel={ translate("Cancel") }
            primaryAction={ () => dispatch(submitUnarchiveSuggestionListGroup(id, translate)) }
            secondaryAction={ () => dispatch(setArchiveModalOpen(false)) }
            closeAction={ () => dispatch(setArchiveModalOpen(false)) }
          >
            <p>{ `${ translate("Are you sure you want to unarchive") } ${ name }?` }</p>
          </Modal>
        )
      }
    </>
  )
}

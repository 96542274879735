import React from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import * as Constant from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import NoteCard from "./NoteCard"
import SquareChip from "../../SquareChip"

import "./notes-content.sass"

type Props = {
  personalityNotes: GraphQL.PersonalityNoteFragment[]
}

export default function NotesContent({ personalityNotes }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.NotesContent" })

  return (
    <div className="cp_component_profile-tabs_notes-content">
      <div className="cp_component_profile-tabs_notes-content-header">
        <h6>{ translate("Notes") }</h6>
        <SquareChip label={ personalityNotes.length } />
      </div>
      <div className="cp_component_profile-tabs_notes-content-body">
        {
          personalityNotes.map((note) => (
            <NoteCard
              key={ `${ note.author.id }-${ note.created }` }
              title={ `${ note.author.username } ${ translate("left a note") }` }
              body={ note.text }
              time={ dayjs(note.created, "X").format(Constant.LONGFORM_DATE_TIME) }
            />
          ))
        }
      </div>
    </div>
  )
}

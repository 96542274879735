import { v4 as uuidv4 } from "uuid"

import { CAMPAIGN_REPORTING_TEMPLATES, CAMPAIGN_REPORTING_TRENDS_GROUPS } from "../../../../util/constant"
import { TrendFilterOption } from "./Trends"
import { groupBy, mapAllToggles } from "./reportingHelper"
import {
  numberWithCommas,
  prettyPrintDecimal,
  snakeToTitleString,
} from "../../../../util/miscHelper"
import {
  CampaignReportGroup,
  CampaignReportInsightFragment,
  CampaignReportToggleSetting,
  Network,
} from "../../../../graphql"

// eslint-disable-next-line no-shadow
export const enum GroupByOptions {
  INFLUENCER = "influencer",
  DELIVERABLE = "deliverable",
  OBJECTIVE = "objective",
  AUDIENCE = "audience",
  CREATIVE_THEME = "creative-theme"
}

// eslint-disable-next-line no-shadow
export const enum SectionOptions {
  CONVERSION = "metrics_conversion",
  NATIVE = "metrics_native",
  PAID = "metrics_paid"
}

// NOTE: Elm has a static list that filters the backend metric values.
// For parity sake, we'll keep the filter on and possibly remove it later
const validMetrics = [
  "total_impressions",
  "total_impressions__gross",
  "total_impressions__true",
  "total_engagements",
  "engagement_rate",
  "view_engagement_rate",
  "engagement_rate__static",
  "story_views",
  "story_click_through_rate",
  "video_views",
  "video_views__paid_3_sec",
  "video_views__paid_2_sec",
  "video_views__paid_6_sec",
  "video_views__paid_30_sec",
  "video_views__paid",
  "video_views__native",
  "view_through_rate__paid_2_sec",
  "view_through_rate__paid_3_sec",
  "view_through_rate__paid_6_sec",
  "view_through_rate__paid_30_sec",
  "link_clicks",
  "click_through_rate",
  "purchases",
  "purchase_rate",
  "purchase_revenue",
  "subscription",
  "subscription_rate",
  "registration_completed",
  "registration_completed_rate",
  "add_to_cart",
  "add_to_cart_rate",
  "landing_page_views",
  "landing_page_view_rate",
  "trials_started",
  "trials_started_rate",
  "app_installs",
  "app_install_rate",
  "checkout_initiated",
  "checkout_initiated_rate",
  "leads",
  "roas",
  "cpa",
  "video_completion_rate",
  "view_content",
  "view_content_rate",
  "complete_payment",
  "complete_payment_rate",
]

// NOTE: Backend is not uniformly returning facebook / meta. For now
// mirroring elm which treats meta as a separate network filter
export const META = "meta"

export const getGroupOptions = (
  influencerInsights: CampaignReportInsightFragment[],
  deliverableInsights: CampaignReportInsightFragment[],
  objectiveInsights: CampaignReportInsightFragment[],
  audienceInsights: CampaignReportInsightFragment[],
  creativeThemeInsights: CampaignReportInsightFragment[],
  toggles: CampaignReportToggleSetting[],
) => {
  const groupOptions: TrendFilterOption[] = []
  if (
    toggles.some((toggle) => toggle.section === CAMPAIGN_REPORTING_TRENDS_GROUPS.TRENDS_BY_INFLUENCER && toggle.enabled)
    && influencerInsights.length > 0
  ) {
    groupOptions.push({
      key: uuidv4(), value: CampaignReportGroup.Influencer, label: "Influencer",
    })
  }
  if (
    toggles.some((toggle) => toggle.section === CAMPAIGN_REPORTING_TRENDS_GROUPS.TRENDS_BY_DELIVERABLE && toggle.enabled)
    && deliverableInsights.length > 0
  ) {
    groupOptions.push({
      key: uuidv4(), value: CampaignReportGroup.Deliverable, label: "Deliverable",
    })
  }
  if (
    toggles.some((toggle) => toggle.section === CAMPAIGN_REPORTING_TRENDS_GROUPS.TRENDS_BY_OBJECTIVE && toggle.enabled)
    && objectiveInsights.length > 0
  ) {
    groupOptions.push({
      key: uuidv4(), value: CampaignReportGroup.Objective, label: "Objective",
    })
  }
  if (
    toggles.some((toggle) => toggle.section === CAMPAIGN_REPORTING_TRENDS_GROUPS.TRENDS_BY_AUDIENCE_TARGETING && toggle.enabled)
    && audienceInsights.length > 0
  ) {
    groupOptions.push({
      key: uuidv4(), value: CampaignReportGroup.Audience, label: "Audience",
    })
  }
  if (
    toggles.some((toggle) => toggle.section === CAMPAIGN_REPORTING_TRENDS_GROUPS.TRENDS_BY_CREATIVE_THEME && toggle.enabled)
    && creativeThemeInsights.length > 0
  ) {
    groupOptions.push({
      key: uuidv4(), value: CampaignReportGroup.CreativeTheme, label: "Creative Theme",
    })
  }
  return groupOptions
}

export const getSectionOptions = (
  insights: CampaignReportInsightFragment[],
  toggles: CampaignReportToggleSetting[],
  template: string,
) => {
  const sectionOptions: TrendFilterOption[] = []
  const insightsBySection = groupBy<CampaignReportInsightFragment, string>(
    insights,
    (insight) => insight.section,
  )
  // NOTE: Unavailable toggles in settings on frontend are defaulted to TRUE on backend
  // For now, manually filtering for only toggles that are available on the frontend for calculations
  const togglesMapped = mapAllToggles(template)

  const filteredToggles = toggles.filter(
    (toggle) => togglesMapped[`${ toggle.platform }-${ toggle.section }-${ toggle.toggle }`] !== undefined,
  )

  const togglesBySection = groupBy<CampaignReportToggleSetting, string>(
    filteredToggles,
    (toggle) => toggle.section,
  )

  if (
    template === CAMPAIGN_REPORTING_TEMPLATES.CONVERSION
    && insightsBySection[SectionOptions.CONVERSION]
    && togglesBySection[SectionOptions.CONVERSION].some((toggle) => toggle.enabled)
  ) {
    sectionOptions.push({
      key: uuidv4(), value: SectionOptions.CONVERSION, label: "Metrics Conversion",
    })
  }
  if (
    (template === CAMPAIGN_REPORTING_TEMPLATES.NATIVE
    || template === CAMPAIGN_REPORTING_TEMPLATES.AWARENESS
    || template === CAMPAIGN_REPORTING_TEMPLATES.CONVERSION)
    && insightsBySection[SectionOptions.NATIVE]
    && togglesBySection[SectionOptions.NATIVE].some((toggle) => toggle.enabled)) {
    sectionOptions.push({
      key: uuidv4(), value: SectionOptions.NATIVE, label: "Metrics Native",
    })
  }
  if (
    (template === CAMPAIGN_REPORTING_TEMPLATES.AWARENESS
    || template === CAMPAIGN_REPORTING_TEMPLATES.CONVERSION)
    && insightsBySection[SectionOptions.PAID]
    && togglesBySection[SectionOptions.PAID].some((toggle) => toggle.enabled)) {
    sectionOptions.push({
      key: uuidv4(), value: SectionOptions.PAID, label: "Metrics Paid",
    })
  }
  return sectionOptions
}

export const getMetricOptions = (
  insights: CampaignReportInsightFragment[],
  toggles: CampaignReportToggleSetting[],
  networkFilter: TrendFilterOption | undefined,
  sectionFilter: TrendFilterOption | undefined,
) => {
  if (!networkFilter || !sectionFilter) return []
  const metricOptions: TrendFilterOption[] = []
  const togglesByPlatform = groupBy<CampaignReportToggleSetting, string>(
    toggles,
    (toggle) => toggle.platform,
  )

  const validInsights = insights
    .filter(
      (insight) => insight.value
      && insight.platform === networkFilter.value
      && insight.section === sectionFilter.value
      && togglesByPlatform[networkFilter.value].some((toggle) => toggle.toggle === insight.toggle)
      && validMetrics.includes(insight.toggle),
    )
  const validInsightsByToggle = groupBy<CampaignReportInsightFragment, string>(
    validInsights,
    (insight) => insight.toggle,
  )
  Object.keys(validInsightsByToggle).forEach((toggle) => metricOptions.push({
    key: uuidv4(), value: toggle, label: snakeToTitleString(toggle),
  }))

  return metricOptions
}

const checkValidPlatform = (
  networkKey: string,
  togglesByPlatform: Record<string, CampaignReportToggleSetting[]>,
  insightsByPlatform: Record<string, CampaignReportInsightFragment[]>,
  sectionFilterValue: string,
) => {
  if (
    (togglesByPlatform[networkKey] && insightsByPlatform[networkKey])
    && (togglesByPlatform[networkKey].some(
      (toggle) => insightsByPlatform[networkKey].some(
        (insight) => (
          insight.toggle === toggle.toggle
          && insight.section === sectionFilterValue
          && insight.value
          && validMetrics.includes(insight.toggle)
        ),
      ),
    ))) {
    return true
  }
  return false
}

export const getPlatformOptions = (
  insights: CampaignReportInsightFragment[],
  toggles: CampaignReportToggleSetting[],
  sectionFilter: TrendFilterOption | undefined,
) => {
  if (!sectionFilter) return []
  const platformOptions: TrendFilterOption[] = []
  const togglesByPlatform = groupBy<CampaignReportToggleSetting, string>(
    toggles,
    (setting) => setting.platform,
  )
  const insightsByPlatform = groupBy<CampaignReportInsightFragment, string>(
    insights,
    (insight) => insight.platform,
  )
  if (checkValidPlatform(Network.Facebook.toLowerCase(), togglesByPlatform, insightsByPlatform, sectionFilter.value)) {
    platformOptions.push({
      key: uuidv4(), value: Network.Facebook.toLowerCase(), label: "Facebook",
    })
  }
  if (checkValidPlatform(META, togglesByPlatform, insightsByPlatform, sectionFilter.value)) {
    platformOptions.push({
      key: uuidv4(), value: META, label: "Meta",
    })
  }
  if (checkValidPlatform(Network.Instagram.toLowerCase(), togglesByPlatform, insightsByPlatform, sectionFilter.value)) {
    platformOptions.push({
      key: uuidv4(), value: Network.Instagram.toLowerCase(), label: "Instagram",
    })
  }
  if (checkValidPlatform(Network.Youtube.toLowerCase(), togglesByPlatform, insightsByPlatform, sectionFilter.value)) {
    platformOptions.push({
      key: uuidv4(), value: Network.Youtube.toLowerCase(), label: "Youtube",
    })
  }
  if (checkValidPlatform(Network.Snapchat.toLowerCase(), togglesByPlatform, insightsByPlatform, sectionFilter.value)) {
    platformOptions.push({
      key: uuidv4(), value: Network.Snapchat.toLowerCase(), label: "Snapchat",
    })
  }
  if (checkValidPlatform(Network.Tiktok.toLowerCase(), togglesByPlatform, insightsByPlatform, sectionFilter.value)
  ) {
    platformOptions.push({
      key: uuidv4(), value: Network.Tiktok.toLowerCase(), label: "Tiktok",
    })
  }
  return platformOptions
}

export const sortByDescCallback = (a: CampaignReportInsightFragment, b: CampaignReportInsightFragment) => {
  if (a.value && b.value) {
    return b.value - a.value
  }
  if (a.value) return -1
  if (b.value) return 1
  return 0
}

export const findMaxValue = (insights: CampaignReportInsightFragment[]) => {
  let max = 0
  insights.forEach((insight) => {
    if (insight.value && insight.value > max) max = insight.value
  })
  return max
}

export const displayValue = (value: number | undefined | null, metric: string | undefined) => {
  if (!value || !metric) {
    return ""
  }
  if (metric.includes("rate")) {
    return `${ prettyPrintDecimal(value, 2) }%`
  }
  if (!Number.isInteger(value)) {
    return numberWithCommas(value.toFixed(2).toString())
  }
  return numberWithCommas(value)
}

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import { TFunction } from "i18next"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status, CampaignReportUpdate } from "../../util/types"
import { CAMPAIGN_REPORTING_TEMPLATES } from "../../util/constant"
import { mapAllToggles, updateToggleSettings } from "../../component/CampaignTabs/Tabs/reporting/reportingHelper"
import { pushToast } from "../toastSlice"
import { RootState } from "../store"

// Campaign Page Slice Interface and Initial State
export interface CampaignPageState {
  campaignAdCampaigns: Status<GraphQL.SearchAdCampaignsQuery>
  allAdCampaigns: Status<GraphQL.SearchAdCampaignsQuery>
  campaign: Status<GraphQL.CampaignQuery>
  campaignNetworkAccounts: Status<GraphQL.CreateCampaignNetworkAccountsMutation | null>
  deleteNetworkAccount: Status<GraphQL.DeleteCampaignNetworkAccountMutation | null>
  manageModalCustomers: Status<GraphQL.SearchCustomersQuery>
  manageModalCustomersOpen: boolean
  manageModalCampaignManagersOpen: boolean
  manageModalCampaignManagers: Status<GraphQL.SearchTeamMembersQuery>
  manageModalBrandManagersOpen: boolean
  manageModalBrandManagers: Status<GraphQL.SearchCampaignUsersQuery>
  manageCampaignReportSettings: Status<GraphQL.UpdateCampaignReportMutation | null>
  campaignReportSettingsUpdates: CampaignReportUpdate
  campaignReport: Status<GraphQL.GetCampaignForReportQuery | null>
  currentAdCampaign: Status<GraphQL.GetAdCampaignQuery>
  updateAudienceTargettingModalOpen: boolean
  linkDeliverableModalOpen: boolean
  selectedAdIds: string[]
  selectedAdSetIds: string[]
  notificationSettings: Status<GraphQL.GetCampaignCommunicationSettingsQuery>
  campaignDeliverablesStartsWithFilter: string
}

const initialState: CampaignPageState = {
  campaignAdCampaigns: "init",
  allAdCampaigns: "init",
  campaign: "init",
  campaignNetworkAccounts: "init",
  deleteNetworkAccount: "init",
  manageModalCustomers: "init",
  manageModalCampaignManagers: "init",
  manageModalBrandManagers: "init",
  manageModalCustomersOpen: false,
  manageModalCampaignManagersOpen: false,
  manageModalBrandManagersOpen: false,
  manageCampaignReportSettings: "init",
  campaignReportSettingsUpdates: {
    campaignId: "",
    template: CAMPAIGN_REPORTING_TEMPLATES.AWARENESS,
    name: "",
    enabled: false,
    clientEnabled: false,
    clientBoosted: false,
    toggleSettings: mapAllToggles(CAMPAIGN_REPORTING_TEMPLATES.AWARENESS),
  },
  campaignReport: "init",
  currentAdCampaign: "init",
  updateAudienceTargettingModalOpen: false,
  linkDeliverableModalOpen: false,
  selectedAdIds: [],
  selectedAdSetIds: [],
  notificationSettings: "init",
  campaignDeliverablesStartsWithFilter: "",
}

// Campaign Page Slice
export const CampaignModalSlice = createSlice({
  name: "CampaignSlice",
  initialState,
  reducers: {
    setCampaignAdCampaigns: (
      state,
      action: PayloadAction<Status<GraphQL.SearchAdCampaignsQuery>>,
    ) => ({
      ...state,
      campaignAdCampaigns: action.payload,
    }),
    setAllAdCampaigns: (
      state,
      action: PayloadAction<Status<GraphQL.SearchAdCampaignsQuery>>,
    ) => ({
      ...state,
      allAdCampaigns: action.payload,
    }),
    setCampaign: (
      state,
      action: PayloadAction<Status<GraphQL.CampaignQuery>>,
    ) => ({
      ...state,
      campaign: action.payload,
    }),
    setCampaignNetworkAccounts: (
      state,
      action: PayloadAction<Status<GraphQL.CreateCampaignNetworkAccountsMutation | null>>,
    ) => ({
      ...state,
      campaignNetworkAccounts: action.payload,
    }),
    deleteCampaignNetworkAccount: (
      state,
      action: PayloadAction<Status<GraphQL.DeleteCampaignNetworkAccountMutation | null>>,
    ) => ({
      ...state,
      deleteNetworkAccount: action.payload,
    }),
    setInitDeleteNetworkAccount: (
      state,
    ) => ({
      ...state,
      deleteNetworkAccount: "init",
    }),
    setManageModalCustomers: (
      state,
      action: PayloadAction<Status<GraphQL.SearchCustomersQuery>>,
    ) => ({
      ...state,
      manageModalCustomers: action.payload,
    }),
    setManageModalCustomersOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      manageModalCustomersOpen: action.payload,
    }),
    setManageModalCampaignManagersOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      manageModalCampaignManagersOpen: action.payload,
    }),
    setManageModalCampaignManagers: (
      state,
      action: PayloadAction<Status<GraphQL.SearchTeamMembersQuery>>,
    ) => ({
      ...state,
      manageModalCampaignManagers: action.payload,
    }),
    setManageModalBrandManagersOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      manageModalBrandManagersOpen: action.payload,
    }),
    setManageModalBrandManagers: (
      state,
      action: PayloadAction<Status<GraphQL.SearchCampaignUsersQuery>>,
    ) => ({
      ...state,
      manageModalBrandManagers: action.payload,
    }),
    setCampaignReportSettings: (
      state,
      action: PayloadAction<Status<GraphQL.UpdateCampaignReportMutation | null>>,
    ) => ({
      ...state,
      manageCampaignReportSettings: action.payload,
    }),
    setInitialCampaignReportSettings: (
      state,
    ) => ({
      ...state,
      manageCampaignReportSettings: initialState.manageCampaignReportSettings,
    }),
    setCampaignReportUpdates: (
      state,
      action: PayloadAction<CampaignReportUpdate>,
    ) => ({
      ...state,
      campaignReportSettingsUpdates: action.payload,
    }),
    setIntialCampaignReportUpdates: (
      state,
    ) => ({
      ...state,
      campaignReportSettingsUpdates: initialState.campaignReportSettingsUpdates,
    }),
    setCampaignReport: (
      state,
      action: PayloadAction<Status<GraphQL.GetCampaignForReportQuery | null>>,
    ) => ({
      ...state,
      campaignReport: action.payload,
    }),
    setCurrentAdCampaign: (
      state,
      action: PayloadAction<Status<GraphQL.GetAdCampaignQuery>>,
    ) => ({
      ...state,
      currentAdCampaign: action.payload,
    }),
    setUpdateAudienceTargettingModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      updateAudienceTargettingModalOpen: action.payload,
    }),
    setLinkDeliverableModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      linkDeliverableModalOpen: action.payload,
    }),
    setSelectedAdIds: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedAdIds: action.payload,
    }),
    setSelectedAdSetIds: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedAdSetIds: action.payload,
    }),
    setNotificationSettings: (
      state,
      action: PayloadAction<Status<GraphQL.GetCampaignCommunicationSettingsQuery>>,
    ) => ({
      ...state,
      notificationSettings: action.payload,
    }),
    setCampaignDeliverablesStartsWithFilter: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      campaignDeliverablesStartsWithFilter: action.payload,
    }),
  },
})

export const {
  setCampaign,
  setCampaignNetworkAccounts,
  deleteCampaignNetworkAccount,
  setInitDeleteNetworkAccount,
  setManageModalCustomers,
  setManageModalCustomersOpen,
  setManageModalCampaignManagersOpen,
  setManageModalCampaignManagers,
  setManageModalBrandManagersOpen,
  setManageModalBrandManagers,
  setCampaignReportSettings,
  setInitialCampaignReportSettings,
  setCampaignReportUpdates,
  setCampaignReport,
  setIntialCampaignReportUpdates,
  setAllAdCampaigns,
  setCampaignAdCampaigns,
  setCurrentAdCampaign,
  setUpdateAudienceTargettingModalOpen,
  setLinkDeliverableModalOpen,
  setSelectedAdIds,
  setSelectedAdSetIds,
  setNotificationSettings,
  setCampaignDeliverablesStartsWithFilter,
} = CampaignModalSlice.actions
export default CampaignModalSlice.reducer

// Campaign Slice Thunks
export const fetchCampaign = (
  params: GraphQL.CampaignQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCampaign("loading"))
  const campaign = await API.fetchCampaign(params)
  dispatch(setCampaign(campaign))
}

export const refreshCampaign = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const {
    campaignPage: { campaign },
  } = getState()
  if (!API.isSuccess(campaign)) return

  const {
    id,
  } = campaign.payload.campaign

  dispatch(setCampaign("loading"))
  const newCampaign = await API.fetchCampaign({ campaignId: id })
  dispatch(setCampaign(newCampaign))
}

export const addCampaignNetworkAccounts = (
  params: GraphQL.CreateCampaignNetworkAccountsMutationVariables,
  translate: TFunction,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCampaignNetworkAccounts("loading"))

  const response = await API.addCampaignNetworkAccounts(params)
  dispatch(setCampaignNetworkAccounts(response))
  // TODO: Add to tech debt list - exportable function to manage this common pattern
  if (API.isSuccess(response)) {
    pushToast({
      type: "success",
      message: translate("Success!"),
      additionalInfo: translate("The selected account(s) were added to the campaign!"),
    })(dispatch)
  } else if (API.isError(response)) {
    pushToast({
      type: "error",
      message: translate("Error"),
      additionalInfo: translate("The selected account(s) have not been added to the campaign!"),
    })(dispatch)
  }
}

export const removeCampaignNetworkAccount = (
  params: GraphQL.DeleteCampaignNetworkAccountMutationVariables,
  translate: TFunction,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(deleteCampaignNetworkAccount("loading"))

  const response = await API.deleteCampaignNetworkAccounts(params)
  dispatch(deleteCampaignNetworkAccount(response))
  if (API.isError(response)) {
    pushToast({
      type: "error",
      message: translate("Error"),
      additionalInfo: translate("The selected account has not been delete from the campaign!"),
    })(dispatch)
  }
}

export const fetchCustomersForManageModal = (
  startsWith: string,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setManageModalCustomers("loading"))
  const response = await API.fetchCustomers({
    startsWith,
    page: 1,
    limit: 1000,
  })
  dispatch(setManageModalCustomers(response))
}

export const fetchCampaignManagersForManageModal = (
  startsWith: string,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setManageModalCampaignManagers("loading"))
  const response = await API.fetchTeamMembers({
    startsWith,
    limit: 1000,
  })
  dispatch(setManageModalCampaignManagers(response))
}

export const fetchBrandManagersForManageModal = (
  startsWith: string,
  campaignId: string,
  customerId: string,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setManageModalBrandManagers("loading"))
  const response = await API.fetchCampaignUsers({
    startsWith,
    campaignId,
    customerId,
  })
  dispatch(setManageModalBrandManagers(response))
}

export const fetchCampaignAdCampaigns = (params: {
  campaignId: string
}) => async (
  dispatch: Dispatch,
) => {
  dispatch(setCampaignAdCampaigns("loading"))
  const response = await API.fetchAdCampaigns(params)
  dispatch(setCampaignAdCampaigns(response))
}

export const fetchAllAdCampaigns = (params: {
  contains: string
}) => async (
  dispatch: Dispatch,
) => {
  dispatch(setAllAdCampaigns("loading"))
  const response = await API.fetchAdCampaigns(params)
  dispatch(setAllAdCampaigns(response))
}

export const fetchCurrentAdCampaign = (
  params: GraphQL.GetAdCampaignQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCurrentAdCampaign("loading"))
  const adCampaign = await API.fetchAdCampaign(params)
  dispatch(setCurrentAdCampaign(adCampaign))
}

export const fetchNotificationSettings = (
  params: GraphQL.GetCampaignCommunicationSettingsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setNotificationSettings("loading"))
  const result = await API.fetchCampaignCommunicationSettings(params)
  dispatch(setNotificationSettings(result))
}

export const updateClients = (
  customerIds: Array<string>,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    campaignPage: { campaign },
  } = getState()
  if (API.isSuccess(campaign)) {
    const {
      id: campaignId,
      name,
      suggestionLists,
      links,
    } = campaign.payload.campaign

    const response = await API.updateCampaign({
      customerIds,
      updateCampaignId: campaignId,
      links: links.map((link) => ({
        title: link.title,
        url: link.url.address,
      })),
      name,
      suggestionListIds: suggestionLists.map(({ id }) => id),
    })

    if (API.isSuccess(response)) {
      await fetchCampaign({ campaignId })(dispatch)
    }
  }
}

export const updateCampaignManagers = (
  campaignManagerIds: Array<string>,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    campaignPage: { campaign },
  } = getState()

  if (API.isSuccess(campaign)) {
    const {
      id: campaignId,
    } = campaign.payload.campaign

    const response = await API.updateCampaignSubscriptions({
      campaignId,
      userIds: campaignManagerIds,
    })

    if (API.isSuccess(response)) {
      await fetchCampaign({ campaignId })(dispatch)
    }
  }
}

export const updateBrandManagers = (
  userPermissions: GraphQL.UserPermissionInput[],
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    campaignPage: { campaign },
  } = getState()
  if (API.isSuccess(campaign)) {
    const {
      id: campaignId,
    } = campaign.payload.campaign
    const response = await API.updateCampaignBrandManagers({
      campaignId,
      userPermissions,
    })
    if (API.isSuccess(response)) {
      await fetchCampaign({ campaignId })(dispatch)
    }
  }
}

export const fetchCampaignReportSettings = (
  id: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  const campaignReport = await API.fetchCampaignReportSettings(id)
  if (API.isSuccess(campaignReport) && campaignReport.payload) {
    const { campaign } = campaignReport.payload
    if (campaign && campaign.report) {
      const activeToggleSettings = campaign.report.toggleSettings
      const toggleSettings = activeToggleSettings.map(({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        __typename, template, ...rest
      }) => rest)
      const reportSettings = {
        campaignId: campaign.id,
        template: campaign.report.template,
        name: campaign.report.name || campaign.name,
        enabled: campaign.report.enabled,
        clientEnabled: campaign.clientReportEnabled,
        clientBoosted: campaign.report.clientBoosted,
        toggleSettings: updateToggleSettings(toggleSettings, initialState.campaignReportSettingsUpdates.toggleSettings),
      }
      dispatch(setCampaignReportUpdates(reportSettings))
    }
  }
}

export const fetchCampaignReport = (
  id: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCampaignReport("loading"))
  const campaignReport = await API.fetchCampaignForReporting(id)
  dispatch(setCampaignReport(campaignReport))
}

export const updateCampaignReport = (
  params: GraphQL.UpdateCampaignReportMutationVariables,
  translate: TFunction,
  onSuccess: () => void,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCampaignReportSettings("loading"))
  const campaignReport = await API.updateCampaignReport(params)
  dispatch(setCampaignReportSettings(campaignReport))
  if (API.isSuccess(campaignReport)) {
    onSuccess()
    pushToast({
      type: "success",
      message: translate("Report Saved"),
      additionalInfo: translate("Report configuration has been saved"),
    })(dispatch)
  } else if (API.isError(campaignReport)) {
    pushToast({
      type: "error",
      message: translate("Error"),
      additionalInfo: translate("The Report was not created, try again later"),
    })(dispatch)
  }
}

export const updateAdSet = (
  params: {
    variables: {
      adIds: string[],
      audienceTargeting: string
    },
    onSuccess: () => void
  },
) => async (
  dispatch: Dispatch,
) => {
  // TODO: Post parity, enable backend to handle multiple unlink ad ids
  const response = await Promise.all(params.variables.adIds.map((id) => API.updateAdSet({
    id,
    audienceTargeting: params.variables.audienceTargeting,
  })))

  if (response.every((promise) => API.isSuccess(promise))) {
    params.onSuccess()
  } else {
    dispatch(setCurrentAdCampaign({ status: "error" }))
  }
}

export const linkAdCampaignToCampaign = (params: {
  variables: GraphQL.LinkAdCampaignsToCampaignMutationVariables
  onSuccess: () => void
}) => async (
  dispatch: Dispatch,
) => {
  const response = await API.linkAdCampaignsToCampaign(params.variables)

  if (API.isSuccess(response)) {
    params.onSuccess()
  } else {
    dispatch(setCampaign({ status: "error" }))
    dispatch(setAllAdCampaigns({ status: "error" }))
    dispatch(setCampaignAdCampaigns({ status: "error" }))
  }
}

export const unlinkAdCampaignsToCampaign = (params: {
  variables: {
    campaignId: string
    adCampaignIds: string[]
  }
  onSuccess: () => void
}) => async (
  dispatch: Dispatch,
) => {
  // TODO: Post parity, enable backend to handle multiple unlink ad ids
  const response = await Promise.all(params.variables.adCampaignIds.map((id) => API.unlinkAdCampaignToCampaign({
    campaignId: params.variables.campaignId,
    adCampaignId: id,
  })))

  if (response.every((promise) => API.isSuccess(promise))) {
    params.onSuccess()
  } else {
    dispatch(setCampaign({ status: "error" }))
    dispatch(setAllAdCampaigns({ status: "error" }))
    dispatch(setCampaignAdCampaigns({ status: "error" }))
  }
}

export const updateNotificationSettings = (
  params: {
    variables: GraphQL.UpdateCampaignCommunicationSettingsMutationVariables,
    onSuccess?: () => void
  },
) => async (
  dispatch: Dispatch,
) => {
  const response = await API.updateCampaignCommunicationSettings(params.variables)
  if (API.isSuccess(response)) {
    if (params.onSuccess) params.onSuccess()
  } else {
    dispatch(setNotificationSettings({ status: "error" }))
  }
}

export const linkDeliverables = (
  params: {
    variables: GraphQL.LinkAdsToDeliverableMutationVariables,
    onSuccess: () => void
  },
) => async (
  dispatch: Dispatch,
) => {
  const response = await API.linkAdsToDeliverable({
    deliverableId: params.variables.deliverableId,
    adIds: params.variables.adIds,
  })
  if (API.isSuccess(response)) {
    params.onSuccess()
  } else {
    dispatch(setCurrentAdCampaign({ status: "error" }))
  }
}

export const unlinkDeliverables = (
  params: {
    variables: GraphQL.UnlinkAdsFromDeliverableMutationVariables,
    onSuccess: () => void
  },
) => async (
  dispatch: Dispatch,
) => {
  const response = await API.unlinkAdsToDeliverable(params.variables)
  if (API.isSuccess(response)) {
    params.onSuccess()
  } else {
    dispatch(setCurrentAdCampaign({ status: "error" }))
  }
}

export const deleteDeliverable = (
  params: {
    variables: GraphQL.DeleteDeliverableMutationVariables,
    onSuccess: () => void
  },
) => async (
  dispatch: Dispatch,
) => {
  const response = await API.deleteDeliverable(params.variables)
  if (API.isSuccess(response)) {
    params.onSuccess()
  } else {
    dispatch(setCampaign({ status: "error" }))
  }
}

import React, { useEffect } from "react"
import { Autocomplete, createFilterOptions } from "@mui/material"
import { useTranslation } from "react-i18next"

import Input, { Props as InputProps } from "../Input"

import "./positions-autocomplete.sass"
import { getCustomerPositions, isSuccess } from "../../util/apiClient"

type Position = string
const filter = createFilterOptions<Position>()

interface Props {
  setPositionHandler: (position: Position) => void
  selectedPosition?: Position,
  customerId: string,
}

function PositionAutoComplete({
  setPositionHandler,
  selectedPosition,
  customerId,
}: Props) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.PositionAutoComplete" })

  const [ positionsAvailable, setPositionsAvailable ] = React.useState<Position[]>([])

  const getPositions = async () => {
    const result = await getCustomerPositions({ customerId })
    if (isSuccess(result)) {
      const titles = result.payload.customer.users.map((teamMember) => teamMember.contact?.title)
        .filter((title) => title !== null && title !== undefined)
      const uniqueTitles = Array.from(new Set(titles)) as string[]

      setPositionsAvailable(uniqueTitles)
    }
  }

  useEffect(() => {
    getPositions()
  }, [])

  const handleInputChange = (event: React.SyntheticEvent, startsWith: string) => {
    setPositionHandler(startsWith)
  }

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    changePosition: Position,
  ) => {
    if (typeof changePosition === "string") {
      setPositionHandler(changePosition)
    }
  }

  return (
    <Autocomplete
      value={ selectedPosition }
      multiple={ false }
      filterOptions={ (options, params) => {
        const filtered = filter(options, params)

        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.includes(inputValue)
        if (inputValue !== "" && !isExisting) {
          filtered.push(inputValue)
        }

        return filtered
      } }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (option) => option }
      className="cp_component_autocomplete_positions"
      ListboxProps={ {
        className: "cp_component_autocomplete_positions",
      } }
      onInputChange={ handleInputChange }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="autocomplete-positions"
      options={ positionsAvailable }
      openOnFocus={ true }
      renderOption={ (props, _position) => (
        <li
          { ...props }
          key={ _position }
        >
          <p>
            { _position }
          </p>
        </li>
      ) }
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          placeholder={ translate("Select Position") }
        />
      )
}
    />
  )
}

export default PositionAutoComplete

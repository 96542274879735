import React from "react"
import { AvatarProps as MuiProps, Avatar as MuiAvatar } from "@mui/material"
import "./style.sass"

const classes = [ "cp-avatar" ]

export interface AvatarProps extends MuiProps {
  size?: "sm" | "md" | "lg" | "xl"
}

function Avatar({
  size = "sm",
  ...props
}: AvatarProps): React.JSX.Element {
  const inputClassName = props.className?.split(" ") || []
  if (props.children) inputClassName.push(`color_avatar-gradient-${ (props.children.toString().charCodeAt(0) % 8) + 1 }`)
  inputClassName.push(`cp-avatar-${ size }`)
  const classNames = classes.concat(inputClassName).join(" ")

  return (
    <MuiAvatar
      { ...props }
      className={ classNames }
    />
  )
}

export default Avatar

import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"

// Customer User Modal Slice Interface and Initial State
export interface CustomerUserModalState {
  isEditingUser: boolean
  customerId: string
  openCustomerUserModal: boolean
  userIdToEdit?: string
  userToEdit?: Status<GraphQL.CustomerUserQuery>
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  updateCallback?: (newUser: GraphQL.CustomerUserFragment | undefined) => Promise<void>
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  createCallback?: (user: GraphQL.CustomerUserFragment | undefined) => Promise<void>
}

const initialState: CustomerUserModalState = {
  customerId: "",
  isEditingUser: false,
  openCustomerUserModal: false,
  userIdToEdit: undefined,
  userToEdit: "init",
  updateCallback: undefined,
  createCallback: undefined,
}

// Customer User Modal Slice
export const CustomerUserModalSlice = createSlice({
  name: "CustomerUserModalSlice",
  initialState,
  reducers: {
    setUserIdToEdit: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      customerIdToEdit: action.payload,
    }),
    openNewCustomerUserModal: (
      state,
      action: PayloadAction<{
        createCallback?: () => Promise<void>,
        customerId: string,
      }>,
    ) => ({
      ...state,
      customerId: action.payload.customerId,
      isEditingUser: false,
      openCustomerUserModal: true,
      createCallback: action.payload.createCallback,
    }),
    openEditCustomerUserModal: (
      state,
      action: PayloadAction<{
        userIdToEdit: string,
        customerId: string,
        updateCallback?: (newUser: GraphQL.CustomerUserFragment | undefined) => Promise<void>,
      }>,
    ) => ({
      ...state,
      userIdToEdit: action.payload.userIdToEdit,
      customerId: action.payload.customerId,
      updateCallback: action.payload.updateCallback,
      isEditingUser: true,
      openCustomerUserModal: true,
    }),
    closeCustomerUserModal: () => ({
      ...initialState,
    }),
    setUser: (
      state,
      action: PayloadAction<Status<GraphQL.CustomerUserQuery>>,
    ) => ({
      ...state,
      userToEdit: action.payload,
    }),
  },
})

export const {
  setUserIdToEdit,
  openNewCustomerUserModal,
  openEditCustomerUserModal,
  closeCustomerUserModal,
  setUser,
} = CustomerUserModalSlice.actions
export default CustomerUserModalSlice.reducer

// Profile Slice Thunks
export const fetchUserToEdit = (
  params: GraphQL.CustomerUserQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setUser("loading"))
  const result = await API.fetchCustomerUser(params)
  dispatch(setUser(result))
}

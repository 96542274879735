import { Download } from "@mui/icons-material"
import { IconButton, Menu } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import MenuItem from "../../../MenuItem"
import { getDownloadReportCsvUrl } from "../../../../util/miscHelper"
import { useSelector } from "../../../../state/hooks"
import { isSuccess } from "../../../../util/apiClient"
import "./download-report-csv-button.sass"
import { Scope } from "../../../../util/types"

export default function DownloadReportCsvButton() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignDownloadReportCsvButton" })

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const { campaignReport } = useSelector(({ campaignPage }) => campaignPage)
  const { scopes } = useSelector(({ user }) => user)

  const onClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const onInternalDownloadClick = () => {
    if (isSuccess(campaignReport)) {
      const reportCode = campaignReport.payload?.campaign.report?.code
      if (reportCode) {
        window.open(getDownloadReportCsvUrl({
          reportCode,
          filterLevel: 0,
        }), "_blank")
      }
    }
    onClose()
  }

  const onExternalDownloadClick = () => {
    if (isSuccess(campaignReport)) {
      const reportCode = campaignReport.payload?.campaign.report?.code
      if (reportCode) {
        window.open(getDownloadReportCsvUrl({
          reportCode,
          filterLevel: 1,
        }), "_blank")
      }
    }
    onClose()
  }

  return (
    <span className="cp_component_download-report-csv-button">
      <IconButton onClick={ handleClick }>
        <Download />
      </IconButton>
      <Menu
        className="download-menu"
        open={ open }
        anchorEl={ anchorEl }
        onClose={ onClose }
        disablePortal={ true }
        anchorOrigin={ {
          horizontal: "center",
          vertical: "center",
        } }
        transformOrigin={ {
          horizontal: "right",
          vertical: "top",
        } }

      >
        { scopes.includes(Scope.CAMPAIGN_REPORTING)
        && (
        <MenuItem
          onClick={ onInternalDownloadClick }
        >
          { translate("Internal") }
        </MenuItem>
        )
        }
        <MenuItem
          onClick={ onExternalDownloadClick }
        >
          { translate("External (Filtered)") }
        </MenuItem>
      </Menu>
    </span>
  )
}

import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { NSFW_ACCOUNT } from "../../util/constant"
import ModalNSFW from "../ModalNSFW"
import { ModalType } from "./nfswHelper"

interface NFSFWModal {
  modalType: ModalType,
  isModalOpen: boolean,
  closeModal: () => void,
  handlePostNsfwModalAction: () => void,
}

// NSFW Account modal
export default function NSFWModalWrapper({
  modalType,
  isModalOpen,
  closeModal,
  handlePostNsfwModalAction,
}: NFSFWModal) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalNSFW" })
  const [ ctaIsActive, setCtaActive ] = useState(false)

  const modalTitle = modalType === NSFW_ACCOUNT ? translate("Nsfw account title") : translate("Nsfw post title")

  const handleNsfwAction = () => {
    setCtaActive(!ctaIsActive)
    handlePostNsfwModalAction()
  }

  return (
    <ModalNSFW
      open={ isModalOpen }
      disabled={ ctaIsActive }
      cancelAction={ closeModal }
      closeAction={ closeModal }
      handleAccept={ handleNsfwAction }
      title={ modalTitle }
    />
  )
}

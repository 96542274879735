/* eslint-disable max-len */
import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import { Tooltip } from "@mui/material"

import "./style.sass"

interface NSFWIconProps {
    isActive?: boolean
    className?: string
}
const classes = [ "cp_component_nsfw-icon" ]
export default function IconNSFW({
  className = "",
  isActive = true,
}: NSFWIconProps) {
  if (isActive) classes.push("cp_component_nsfw-icon_active")
  const classNames = `${ classes.join(" ") } ${ className }`
  return (
    <svg className={ classNames } width="31" height="7" viewBox="0 0 31 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.43731 0.0931118V6.90356H5.03185L1.68355 2.6171H1.62718V6.90356H0V0.0931118H1.42801L4.75001 4.37625H4.81765V0.0931118H6.43731Z" fill="#FF4165" />
      <path d="M11.9568 2.05178C11.9267 1.78353 11.7977 1.57514 11.5697 1.4266C11.3417 1.27807 11.0323 1.2038 10.6415 1.2038C10.3759 1.2038 10.1517 1.23705 9.96882 1.30356C9.78593 1.36785 9.64563 1.45764 9.54793 1.57292C9.45273 1.6882 9.40513 1.819 9.40513 1.96532C9.40012 2.08725 9.42893 2.19367 9.49156 2.28456C9.5567 2.37546 9.64563 2.45416 9.75837 2.52067C9.87111 2.58496 10.0014 2.64149 10.1492 2.69026C10.297 2.73682 10.4548 2.77672 10.6227 2.80998L11.3142 2.95629C11.6499 3.0228 11.958 3.11148 12.2386 3.22233C12.5192 3.33318 12.7622 3.46952 12.9676 3.63135C13.1731 3.79319 13.3322 3.98385 13.4449 4.20333C13.5601 4.4228 13.619 4.67443 13.6215 4.9582C13.619 5.37498 13.4988 5.73634 13.2608 6.04228C13.0253 6.346 12.6845 6.58211 12.2386 6.75059C11.7952 6.91687 11.2603 7 10.634 7C10.0127 7 9.47152 6.91576 9.01055 6.74727C8.55208 6.57878 8.19383 6.32938 7.93578 5.99905C7.68024 5.66651 7.54621 5.25527 7.53369 4.76532H9.10825C9.12579 4.99367 9.19969 5.18432 9.32997 5.33729C9.46275 5.48804 9.63937 5.60222 9.85984 5.67981C10.0828 5.75519 10.3346 5.79287 10.6152 5.79287C10.8908 5.79287 11.13 5.7574 11.3329 5.68646C11.5384 5.61552 11.6975 5.51686 11.8102 5.3905C11.9229 5.26413 11.9793 5.11892 11.9793 4.95487C11.9793 4.8019 11.9279 4.67332 11.8252 4.56912C11.725 4.46492 11.5772 4.37625 11.3818 4.30309C11.1889 4.22993 10.9521 4.16342 10.6715 4.10356L9.83353 3.91734C9.18466 3.77767 8.67233 3.5593 8.29654 3.26223C7.92075 2.96516 7.73411 2.565 7.73661 2.06176C7.73411 1.64941 7.85812 1.28915 8.10865 0.980998C8.36168 0.672843 8.70866 0.432304 9.14959 0.259383C9.59052 0.0864611 10.0916 0 10.6528 0C11.224 0 11.7225 0.0864611 12.1484 0.259383C12.5768 0.432304 12.91 0.672843 13.148 0.980998C13.386 1.28915 13.5088 1.64608 13.5163 2.05178H11.9568Z" fill="#FF4165" />
      <path d="M14.716 6.90356V0.0931118H19.8117V1.28029H16.3432V2.90309H19.4735V4.09026H16.3432V6.90356H14.716Z" fill="#FF4165" />
      <path d="M22.6386 6.90356L20.4365 0.0931118H22.214L23.4879 4.82518H23.5518L24.9573 0.0931118H26.4792L27.8809 4.83515H27.9486L29.2225 0.0931118H31L28.7979 6.90356H27.212L25.7464 2.45083H25.6863L24.2245 6.90356H22.6386Z" fill="#FF4165" />
    </svg>

  )
}

interface NSFWLabelWTooltipProps {
  isNSFW: boolean;
  labelClass: string;
}

export function NSFWLabelWTooltip({ isNSFW, labelClass }: NSFWLabelWTooltipProps): JSX.Element | null {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountCell" })

  if (!isNSFW) return null

  return (
    <Tooltip
      arrow={ true }
      placement="top"
      title={ (
        <>
          <h2 className="cp_component_nsfw-icon-tooltip_title">{ translate("NSFW heading") }</h2>
          <p className="cp_component_nsfw-icon-tooltip_mssg">{ translate("NSFW tooltip message") }</p>
        </>
      ) }
    >
      <span className={ `cp_component_nsfw-label-${ labelClass }` } />
    </Tooltip>
  )
}

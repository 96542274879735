import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import ApprovePanel from "./ApprovePanel"
import FeedbackPanel from "./FeedbackPanel"
import ModalInfoRequired from "./ModalInfoRequired"
import PublicListOverview from "../../component/PublicListOverview"
import PublicListTabs from "../../component/ProfileTabs/PublicListTabs"
import { SuggestionAccessCodeRestriction } from "../../graphql"
import { isSuccess } from "../../util/apiClient"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  fetchPublicList,
  fetchPublicListSocialProfile,
  getInitialPostImageTags,
  getInitialPostKeywords,
  setInfoRequiredModalOpen,
  userCanViewList,
} from "../../state/publicListSlice"

import "./style.sass"

type Props = {
  userIsValid: boolean
  accessCodeRestriction: SuggestionAccessCodeRestriction | undefined | null
}

export default function PublicListAuthShell({ userIsValid, accessCodeRestriction }: Props) {
  const { listCode } = useParams()
  const dispatch = useDispatch()

  const {
    rightPanelStatus,
    selectedListSocialAccount,
    loadedListSocialAccount,
    list,
  } = useSelector(({ publicList }) => publicList)

  useEffect(() => {
    if (listCode) {
      if (userCanViewList(accessCodeRestriction, userIsValid)) {
        dispatch(fetchPublicList(listCode))
      } else {
        dispatch(setInfoRequiredModalOpen(true))
      }
    }
  }, [ listCode, accessCodeRestriction, userIsValid ])

  useEffect(() => {
    if (
      userCanViewList(accessCodeRestriction, userIsValid)
      && listCode
      && selectedListSocialAccount
      && loadedListSocialAccount === "init"
    ) {
      dispatch(fetchPublicListSocialProfile({
        // The field that GraphQL expects implies an ID, but it actually reads a code.
        listId: listCode,
        socialAccountId: selectedListSocialAccount.socialAccount.id,
      }))
    }
  }, [ selectedListSocialAccount ])

  useEffect(() => {
    if (isSuccess(list) && listCode && selectedListSocialAccount) {
      const { imageTags } = list.payload.publicSuggestionListByCode
      const { keywords } = list.payload.publicSuggestionListByCode
      const alphabetizedImageTags = imageTags.length ? [ ...imageTags ].sort((a, b) => a.name.localeCompare(b.name)) : []
      const alphabetizedKeywordTags = keywords.length ? [ ...keywords ].sort((a, b) => a.name.localeCompare(b.name)) : []
      if (alphabetizedImageTags.length > 0) {
        dispatch(getInitialPostImageTags({
          code: listCode,
          socialAccountId: selectedListSocialAccount.socialAccount.id,
          suggestionListImageTagId: alphabetizedImageTags[0].id,
        }))
      }
      if (alphabetizedKeywordTags.length > 0) {
        dispatch(getInitialPostKeywords({
          code: listCode,
          socialAccountId: selectedListSocialAccount.socialAccount.id,
          keywordId: alphabetizedKeywordTags[0].id,
        }))
      }
    }
  }, [ selectedListSocialAccount, list ])

  return (
    <div className="cp_public-list_page">
      <PublicListOverview />
      <PublicListTabs />
      { rightPanelStatus === "approve" && (
        <ApprovePanel userIsValid={ userIsValid } accessCodeRestriction={ accessCodeRestriction } />
      ) }
      { rightPanelStatus === "feedback" && (
        <FeedbackPanel userIsValid={ userIsValid } accessCodeRestriction={ accessCodeRestriction } />
      ) }
      <ModalInfoRequired
        codeRestriction={ accessCodeRestriction }
      />
    </div>
  )
}

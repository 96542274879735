/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-plusplus */
import React, { useMemo } from "react"
import { GridColDef, GridRowsProp } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp"
import CancelIcon from "@mui/icons-material/Cancel"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { setCampaignReportUpdates } from "../../../../state/campaignSlice"

import DataGrid from "../../../DataGrid"
import Switch from "../../../Switch"
import {
  metricsNativeTabularData,
  totalSectionTogglesActive,
  flattenSectionToggles,
  updateAllSectionToggles,
  processActiveToggles,
} from "./reportingHelper"
import Checkbox from "../../../Checkbox"
import EmptyElement from "../../../EmptyElement"

export default function MetricsNativeSection() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReporting" })
  const flattenedToggles = useMemo(() => flattenSectionToggles(metricsNativeTabularData), [ metricsNativeTabularData ])
  const dispatch = useDispatch()
  const reportUpdates = useSelector(({ campaignPage }) => campaignPage.campaignReportSettingsUpdates)
  const { toggleSettings } = reportUpdates
  const allTogglesSwitched = flattenedToggles.length === totalSectionTogglesActive(toggleSettings, flattenedToggles)

  const handleActiveToggles = (toggle: string) => {
    const currUpdates = processActiveToggles(toggle, toggleSettings, reportUpdates)
    dispatch(setCampaignReportUpdates(currUpdates))
  }

  const isToggleActive = (toggle: string) => {
    const currToggles = { ...toggleSettings }
    return currToggles[toggle].enabled
  }

  const handleAllTogglesChange = () => {
    const currUpdates = { ...reportUpdates }
    if (allTogglesSwitched) {
      currUpdates.toggleSettings = updateAllSectionToggles(toggleSettings, flattenedToggles, false)
      dispatch(setCampaignReportUpdates(currUpdates))
      return
    }

    currUpdates.toggleSettings = updateAllSectionToggles(toggleSettings, flattenedToggles, true)
    dispatch(setCampaignReportUpdates(currUpdates))
  }

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "metric",
      sortable: false,
      headerName: translate("Metric"),
      renderCell: (params) => (
        <p className="cp_native-metric">{ translate(params.row.metric) }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "facebook",
      headerName: translate("Facebook"),
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        if (row.facebook === null) return null
        return (
          <Switch
            color="primary"
            hasIcon={ true }
            icon={ <CancelIcon /> }
            checkedIcon={ <CheckCircleSharpIcon /> }
            isChecked={ isToggleActive(row.facebook) }
            handleChange={ () => { handleActiveToggles(row.facebook) } }
          />
        )
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "instagram",
      headerName: translate("Instagram"),
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        if (row.instagram === null) return null
        return (
          <Switch
            color="primary"
            hasIcon={ true }
            icon={ <CancelIcon /> }
            checkedIcon={ <CheckCircleSharpIcon /> }
            isChecked={ isToggleActive(row.instagram) }
            handleChange={ () => { handleActiveToggles(row.instagram) } }
          />
        )
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "tikTok",
      headerName: translate("TikTok"),
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        if (row.tikTok === null) return null
        return (
          <Switch
            color="primary"
            hasIcon={ true }
            icon={ <CancelIcon /> }
            checkedIcon={ <CheckCircleSharpIcon /> }
            isChecked={ isToggleActive(row.tikTok) }
            handleChange={ () => { handleActiveToggles(row.tikTok) } }
          />
        )
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "youTube",
      headerName: translate("YouTube"),
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        if (row.youTube === null) return null
        return (
          <Switch
            color="primary"
            hasIcon={ true }
            icon={ <CancelIcon /> }
            checkedIcon={ <CheckCircleSharpIcon /> }
            isChecked={ isToggleActive(row.youTube) }
            handleChange={ () => { handleActiveToggles(row.youTube) } }
          />
        )
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
  ], [ toggleSettings ])

  function generateSelectVisualRows(): GridRowsProp {
    const rows = metricsNativeTabularData.map((metricArr, indx) => ({
      id: indx,
      metric: metricArr[0],
      facebook: metricArr[1],
      instagram: metricArr[2],
      tikTok: metricArr[3],
      youTube: metricArr[4],
    }))
    return rows
  }

  return (
    <Accordion className="cp_campaign_reporting_tab-accordion_select-visuals" variant="outlined">
      <AccordionSummary
        expandIcon={ <ArrowForwardIosIcon /> }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3 className="cp_component_accordion-heading">
          { translate("Metrics Native Visuals") }
        </h3>
        <div className="cp_component_accordion-toggle-details">
          <Checkbox
            checked={ allTogglesSwitched }
            onClick={ (e) => { e.stopPropagation() } }
            onChange={ handleAllTogglesChange }
          />
          <span className="cp_accordion-toggle-totals">
            {
            `${ totalSectionTogglesActive(toggleSettings, flattenedToggles) }/${ flattenedToggles.length } 
            | ${ translate("Enabled") } `
            }
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          getRowId={ (row) => row.id }
          disableRowSelectionOnClick={ true }
          rowHeight={ 90 }
          columnHeaderHeight={ 40 }
          columns={ COLUMNS }
          disableColumnReorder={ true }
          hideFooter={ true }
          rows={ generateSelectVisualRows() }
          slots={ {
            noRowsOverlay: EmptyElement,
            noResultsOverlay: EmptyElement,
          } }
        />
      </AccordionDetails>
    </Accordion>
  )
}

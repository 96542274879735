import React from "react"
import { useTranslation } from "react-i18next"
import "./thread-preview.sass"
import dayjs from "dayjs"
import * as Constant from "../../../../util/constant"

type Props = {
  created: number
  subject: string
  content: string
  id?: string
}

export default function ThreadPreview({
  content, created, subject, id,
}: Props) {
  return (
    <div id={ id } className="cp_component_unassigned-thread-preview">
      <p className="created-label">
        { dayjs(created * 1000)
          .format(Constant.MONTH_DAY_ABBR_FORMAT).toUpperCase() }
      </p>
      <p className="subject">
        { subject }
      </p>
      <p className="content">
        { content }
      </p>
    </div>
  )
}

import { Timezone } from "./types"

export const timezones: Timezone[] = [
  [ "New Zealand Standard (UTC+12)", 12 ],
  [ "Solomon Islands (UTC+11)", 11 ],
  [ "Australia Eastern Standard (UTC+10)", 10 ],
  [ "Japan Standard (UTC+9)", 9 ],
  [ "China Standard (UTC+8)", 8 ],
  [ "Western Indonesian (UTC+7)", 7 ],
  [ "Bangladesh Standard (UTC+6)", 6 ],
  [ "Uzbekistan (UTC+5)", 5 ],
  [ "Gulf Standard (UTC+4)", 4 ],
  [ "Eastern African (UTC+3)", 3 ],
  [ "Eastern European (UTC+2)", 2 ],
  [ "Central European (UTC+1)", 1 ],
  [ "Greenwich Mean (UTC+0)", 0 ],
  [ "Cape Verde (UTC-1)", -1 ],
  [ "South Georgia (UTC-2)", -2 ],
  [ "Argentina (UTC-3)", -3 ],
  [ "Venezuela (UTC-4)", -4 ],
  [ "Eastern Standard (UTC-5)", -5 ],
  [ "Central Standard (UTC-6)", -6 ],
  [ "Mountain Standard (UTC-7)", -7 ],
  [ "Pacific Standard (UTC-8)", -8 ],
  [ "Alaska Standard (UTC-9)", -9 ],
  [ "Hawaii Standard (UTC-10)", -10 ],
  [ "Niue (UTC-11)", -11 ],
  [ "Anywhere On Earth (UTC-12)", -12 ],
]

export default timezones

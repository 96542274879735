import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { Autocomplete, debounce } from "@mui/material"
import * as API from "../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import { Vertical } from "../../util/types"
import Input, { Props as InputProps } from "../Input"
import LoadingIndicator from "../LoadingIndicator"

interface Props {
  setSelectedVerticals: (selectedTags: Vertical[]) => void
  selectedVerticals: Vertical[],
  inputLabel: string,
  notFoundLabel: string,
}

function TagsAutocomplete({
  setSelectedVerticals,
  selectedVerticals,
  inputLabel,
  notFoundLabel,
}:Props) {
  const [ verticalTags, setVerticalTags ] = useState<Vertical[]>([])

  // Verticals
  const searchVerticals = async () => {
    const result = await API.fetchVerticals()

    if (API.isSuccess(result)) {
      const { getAllSuggestionListVerticals } = result.payload
      // const tags = rows.map((row) => row.name)
      setVerticalTags(getAllSuggestionListVerticals)
    } else {
      setVerticalTags([ {
        id: "",
        name: notFoundLabel,
      } ])
    }
  }

  const debouncedSearchVerticals = useCallback(debounce(() => {
    searchVerticals()
  }, DEFAULT_DEBOUNCE_WAIT), [ searchVerticals ])

  const onAutoCompleteChange = async (event: React.SyntheticEvent, verticals: (string | Vertical)[]) => {
    const savedVerticals: Vertical[] = await Promise.all(
      verticals.map(async (vertical) => {
        // Vertical doesn't exist yet. Create it.
        if (typeof vertical === "string") {
          const newVertical = await API.createSuggestionListVerticalType(vertical)
          if (API.isSuccess(newVertical)) {
            return {
              id: newVertical.payload.createSuggestionListVerticalType.id,
              name: newVertical.payload.createSuggestionListVerticalType.name,
            }
          }

          return null
        }

        return vertical
      }).filter((vertical): vertical is Promise<Vertical> => vertical != null),
    )

    setSelectedVerticals(savedVerticals)
  }

  useEffect(() => {
    searchVerticals()
  }, [])
  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedVerticals }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (vertical) => {
        if (typeof vertical === "string") {
          return vertical
        }
        return vertical.name
      } }
      ListboxProps={ {
        className: "cp_component_autocomplete_tags-list",
      } }
      onInputChange={ debouncedSearchVerticals }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="autocomplete-tags"
      options={ [ ...verticalTags ].sort() }
      loading={ false }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, vertical, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ vertical.id }
          >
            { vertical.name }
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          label={ inputLabel }
        />
      )
}
    />
  )
}

export default TagsAutocomplete

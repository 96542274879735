import * as React from "react"
import Chip, { ChipProps } from "@mui/material/Chip"

import "./style.sass"

interface Props extends ChipProps {
  className?: string
  handleClick?: () => void

}

export default function Pill(props: Props) {
  const {
    handleClick,
    className,
    ...restProps
  } = props

  const classes = [ "cp-pill" ]
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")

  return (
    <Chip
      className={ newClasses }
      onClick={ handleClick }
      { ...restProps }
    />
  )
}

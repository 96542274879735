import { Avatar, Typography } from "@mui/material"
import React, { JSX } from "react"
import "./modal-header.sass"

type Props = {
    avatarUrl: string,
    title: string,
    subTitle: JSX.Element | string
}

export default function ModalHeader({
  avatarUrl,
  title,
  subTitle,
}: Props) {
  return (
    <div className="cp_component_modal-brand-details-header">
      <Avatar src={ avatarUrl } />
      <div>
        <Typography variant="h5" className="cp_component_modal-title">
          { title }
        </Typography>
        <Typography className="cp_component_modal-subtitle">
          { subTitle }
        </Typography>
      </div>
    </div>
  )
}

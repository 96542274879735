import * as React from "react"
import SwitchMui, { SwitchProps as SwitchPropsMui } from "@mui/material/Switch"

import "./style.sass"

interface Props extends SwitchPropsMui {
  handleChange: SwitchPropsMui["onChange"]
  isChecked: boolean
  hasIcon?: boolean
}

export default function Switch(props: Props) {
  const {
    handleChange,
    isChecked,
    hasIcon,
    ...restProps
  } = props

  const classes = hasIcon ? "cp-switch cp-switch_with-icon" : "cp-switch"

  return (
    <SwitchMui
      className={ classes }
      checked={ isChecked }
      onChange={ handleChange }
      inputProps={ { "aria-label": "controlled" } }
      { ...restProps }
    />
  )
}

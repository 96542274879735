import React, { JSX, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../util/searchHelper"
import { Scope } from "../../util/types"
import {
  fetchSearchResults,
  setSearchInput,
  setSelectedLocations,
} from "../../state/searchSlice"
import { useDispatch, useSelector } from "../../state/hooks"

/* NOTE:
**
** Why does this component exist? In short, to outsource any search input
** logic from the Search Page functional component. The justification
** is twofold:
**
** 1. Ensure that the initial search isn't kicked off until user details
** are loaded
**
** 2. Ensure there are no references to search input within the search slice
** that might cause the virtual DOM for the entire search page to be rerendered
** when something within said input changes
*/

export default function SearchInitiator(): JSX.Element {
  const dispatch = useDispatch()
  const [ searchParams ] = useSearchParams()
  const searchInput = useSelector(({ search }) => search.searchInput)
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  const user = useSelector(({ user: userSlice }) => userSlice.user)

  // Upon mounting the search page, parse the query parameters in the URL
  // and apply them to the search input state.
  useEffect(() => {
    // Do nothing if user hasn't loaded yet.
    if (!API.isSuccess(user) || scopes.length === 0) return

    const searchInputQueryString = searchParams.get(SearchHelper.QUERY_PARAM_Q)

    const influencerInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_INFLUENCER,
    )

    const audienceInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_AUDIENCE,
    )

    const locationInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_LOCATION,
    )

    const contentInputQueryString = searchParams.get(
      SearchHelper.QUERY_PARAM_CONTENT,
    )

    let newInput = SearchHelper.initialSearchState()

    // Base Query Parameters
    if (searchInputQueryString != null) {
      newInput = {
        ...searchInput,
        ...SearchHelper.baseQueryToSearchInput(searchInputQueryString),
      }
    }

    // Influencer Query Parameters
    if (influencerInputQueryString != null) {
      const newInfluencerParams = {
        ...searchInput.influencerParams,
        ...SearchHelper.baseQueryToInfluencerInput(influencerInputQueryString),
      }

      newInput.influencerParams = newInfluencerParams
    }

    // Audience Query Parameters
    if (audienceInputQueryString != null) {
      const newAudienceParams = {
        ...searchInput.audienceParams,
        ...SearchHelper.baseQueryToAudienceInput(audienceInputQueryString),
      }

      newInput.audienceParams = newAudienceParams
    }

    // Location Query Parameters
    if (locationInputQueryString != null) {
      dispatch(setSelectedLocations(JSON.parse(locationInputQueryString)))
    }

    // Content Query Parameters
    if (contentInputQueryString != null) {
      const newContentParams = {
        ...searchInput.contentParams,
        ...SearchHelper.baseQueryToContentInput(contentInputQueryString),
      }

      newInput.contentParams = newContentParams
    }

    // If user is not scoped for Account search, default to Content search
    if (
      !scopes.includes(Scope.FEATURE_SEARCH_INFLUENCER)
      && !scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE)
    ) {
      newInput.resultType = GraphQL.GodSearchType.Post
    }

    // Only dispatch new search input if some change in input has occurred
    if (
      searchInputQueryString != null
      || influencerInputQueryString != null
      || audienceInputQueryString != null
      || contentInputQueryString != null
      || newInput.resultType !== GraphQL.GodSearchType.Social
    ) {
      dispatch(setSearchInput(newInput))
    }

    dispatch(fetchSearchResults(true))
  }, [])

  return <></>
}

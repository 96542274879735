import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../../../util/apiClient"
import EngagementMap from "../../AccountInsights/EngagementMap"
import LoadingIndicator from "../../../LoadingIndicator"
import { getDailyHourlyEngagementRate } from "../../../../state/listSocialProfileSlice"
import { getLocalTZOffset } from "../../../../util/miscHelper"
import { useDispatch, useSelector } from "../../../../state/hooks"

export default function EngagementMapWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { listProfile, dailyEngagementRate } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const dispatch = useDispatch()
  const [ timeZone, setTimeZone ] = useState(getLocalTZOffset() || -8)

  useEffect(() => {
    if (API.isSuccess(listProfile)) {
      const variables = {
        socialAccountId: listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount.id,
        timeZoneOffset: timeZone,
        limit: 10,
      }
      dispatch(getDailyHourlyEngagementRate(variables))
    }
  }, [ timeZone, listProfile ])

  if (
    listProfile === "loading"
    || listProfile === "init"
    || dailyEngagementRate === "loading"
    || dailyEngagementRate === "init"
  ) {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (listProfile.status === "error" || dailyEngagementRate.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <EngagementMap
      dailyEngagementRate={ dailyEngagementRate.payload.getDailyHourlyEngagementSummary }
      timeZone={ timeZone }
      setTimeZone={ setTimeZone }
    />
  )
}

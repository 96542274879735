import React from "react"
import { useTranslation } from "react-i18next"
import "./placeholder-empty-splash.sass"
import EmptySplash from "../../EmptySplash"

export default function PlaceholderEmptySplash() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })

  return (
    <div className="cp_component_placeholder-empty-splash">
      <EmptySplash bodyText={ translate("Placeholder accounts do not have data to display") } />
    </div>
  )
}

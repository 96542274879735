import * as React from "react"
import "./style.sass"
import { NotificationStatus } from "../../util/types"

export interface StatusDotProps extends
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  status: NotificationStatus
  /**
   * @default 'md'
   */
  size?: "sm" | "md"
}

export default function StatusDot({
  size = "md",
  status,
  className = "",
  ...props
}: StatusDotProps) {
  const defaultClassNames = [ "cp_component_status", `cp_component_status_${ status }`, `cp_component_status_${ size }` ]
  const finalClassName = defaultClassNames.join(" ") + className
  return (
    <span className={ finalClassName } { ...props } />
  )
}

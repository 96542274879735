import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import Affinities from "../../Audience/Affinities"

export default function AffinitiesWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.Affinities" })
  const { audience, profile } = useSelector(({ socialProfile }) => socialProfile)
  if (audience === "loading" || audience === "init" || profile === "loading" || profile === "init") {
    return (
      <LoadingIndicatorCard />
    )
  }
  if (audience.status === "error" || profile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <Affinities
      audienceDemographics={ audience.payload.socialAccount.audienceDemographics }
      socialProfile={ profile.payload.socialAccount }
    />
  )
}

import * as React from "react"
import { CircularProgressProps as MuiProps } from "@mui/material/CircularProgress"
import "./style.sass"
import LoadingIndicator from "../LoadingIndicator"

export interface LoadingIndicatorCardProps extends MuiProps {}

export default function LoadingIndicatorCard(props :LoadingIndicatorCardProps) {
  return (
    <div className="cp_loading-indicator-card_component">
      <LoadingIndicator size={ props.size || 30 } { ...props } />
    </div>
  )
}

import React from "react"
import { TFunction } from "i18next"
import {
  GridColDef,
  GridValidRowModel,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro"

import * as GraphQL from "../../graphql"
import * as MiscHelper from "../../util/miscHelper"
import * as SearchHelper from "../../util/searchHelper"
import * as TableHelper from "./tableHelper"
import AccountEllipsisMenuCell from "./AccountEllipsisMenuCell"
import Pill from "../Pill"
import Tooltip from "../Tooltip"
import SocialAvatar from "../SocialAvatar"
import { scoreModalTypes } from "../../util/constant"
import { Scope, ScoreBreakDown } from "../../util/types"

type Params = GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
interface ScorePill {
  params: Params;
  scoreType: string;
  modalType: string;
  isAdCouncilScore: boolean;
}

export function generateAccountResultsColumns(
  translate: TFunction,
  scopes: string[],
  handleScoreModal: (scoreBreakDown: ScoreBreakDown, modalType: string, isAdCouncilScore?: boolean) => void,
  handleNSFWUpdate: (
    updateID: string,
    updateFrom: string,
  ) => Promise<void>,
): GridColDef[] {
  function renderScorePill({
    params, scoreType, modalType, isAdCouncilScore,
  }: ScorePill) {
    const { scoreBreakDown } = params.row
    const score = params.row[scoreType]
    return (
      <Pill
        label={ score }
        handleClick={
          (scoreBreakDown && score)
            ? () => { handleScoreModal(scoreBreakDown, modalType, isAdCouncilScore) }
            : () => {}
         }
      />
    )
  }

  const columns: GridColDef[] = [
    {
      field: SearchHelper.SearchColumn.Account,
      headerName: translate("Account"),
      sortable: false,
      renderCell: (params) => (
        <SocialAvatar { ...params.row.account } />
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 300,
    },
    {
      field: SearchHelper.SearchColumn.Followers,
      description: translate("Tooltip Followers"),
      headerName: translate("Followers"),
      sortable: true,
      renderCell: (params) => (
        <p>{ params.row.followers }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.EngagementRate,
      description: translate("Tooltip Engagement Rate"),
      headerName: translate("Engagement Rate"),
      sortable: false,
      renderCell: (params) => (
        <p>{ params.row.engagementRate }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.EngagementScore,
      description: translate("Tooltip Engagement Score"),
      headerName: translate("Engagement Score"),
      sortable: true,
      renderCell: (params) => (
        renderScorePill({
          params,
          scoreType: "engagementScore",
          modalType: scoreModalTypes.ENGAGEMENT,
          isAdCouncilScore: false,
        })
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.OrganicEngagementRate,
      description: translate("Tooltip Organic Engagement Rate"),
      headerName: translate("Organic Engagement Rate"),
      sortable: true,
      renderCell: (params) => (
        <p>{ params.row.organicEngagementRate }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.SponsoredEngagementRate,
      description: translate("Tooltip Sponsored Engagement Rate"),
      headerName: translate("Sponsored Engagement Rate"),
      sortable: true,
      renderCell: (params) => (
        <p>{ params.row.sponsoredEngagementRate }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.AudienceQualityScore,
      description: translate("Tooltip Audience Quality Score"),
      headerName: translate("Audience Quality Score"),
      sortable: true,
      renderCell: (params) => (
        renderScorePill({
          params,
          scoreType: "audienceQualityScore",
          modalType: scoreModalTypes.AUDIENCE_QUALITY,
          isAdCouncilScore: false,
        })
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.DemographicScore,
      description: translate("Tooltip Demographic Score"),
      headerName: translate("Demographic Score"),
      headerClassName:
        "cp_component_search-results-table_demographic-column-header",
      sortable: true,
      renderCell: (params) => (
        <Pill
          label={ params.row.demographicScore }
          handleClick={ () => {} }
        />
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.InDemoPercentage,
      description: translate("Tooltip In Demo Percentage"),
      headerName: translate("In-Demo Percentage"),
      sortable: true,
      renderCell: (params) => (
        <Pill
          label={ params.row.inDemoPercentage }
          handleClick={ () => {} }
        />
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.AdCouncilScore,
      description: translate("Tooltip Ad Council Score"),
      headerName: translate("Ad Council Score"),
      sortable: true,
      renderCell: (params) => (
        renderScorePill({
          params,
          scoreType: "adCouncilScore",
          modalType: scoreModalTypes.I_SCORE,
          isAdCouncilScore: true,
        })
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.IScore,
      description: translate("Tooltip I-Score"),
      headerName: translate("I-Score"),
      sortable: true,
      renderCell: (params) => (
        renderScorePill({
          params,
          scoreType: "iScore",
          modalType: scoreModalTypes.I_SCORE,
          isAdCouncilScore: false,
        })
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.Biography,
      description: translate("Tooltip Biography"),
      headerName: translate("Biography"),
      sortable: false,
      renderCell: (params) => (
        <Tooltip
          arrow={ true }
          title={ params.row.biography }
        >
          <p>{ params.row.biography }</p>
        </Tooltip>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.Matches,
      description: translate("Tooltip Matches"),
      headerName: translate("Matches"),
      sortable: true,
      renderCell: (params) => (
        <p>{ params.row.matches }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.EllipsisMenu,
      headerName: "", // This column should be pinned and doesn't need a header
      sortable: false,
      renderCell: (params) => (
        <AccountEllipsisMenuCell
          handleNSFWUpdate={ handleNSFWUpdate }
          hasPersonality={ params.row.account.hasPersonality }
          network={ params.row.account.network }
          socialAccountId={ params.row.id }
          socialAccountUrl={ params.row.account.profileNetworkUrl }
        />
      ),
      disableColumnMenu: true,
      width: 50,
    },
  ]

  return columns
    // Hide or display IScore column depending on scope...
    .filter((column) => {
      if (column.field !== SearchHelper.SearchColumn.IScore) return true
      return scopes.includes(Scope.FEATURE_SEARCH_ISCORE)
    })

    // Hide or display In Demo column depending on scope...
    .filter((column) => {
      if (column.field !== SearchHelper.SearchColumn.InDemoPercentage) return true
      return scopes.includes(Scope.FEATURE_SEARCH_IN_DEMO)
    })

    // Hide or display Ad Council column depending on scope...
    .filter((column) => {
      if (column.field !== SearchHelper.SearchColumn.AdCouncilScore) return true
      return scopes.includes(Scope.SCORE_AD_COUNCIL)
    })

    // Hide or display Demographic Score column depending on scope...
    .filter((column) => {
      if (column.field !== SearchHelper.SearchColumn.DemographicScore) return true
      return scopes.includes(Scope.FEATURE_LIST_DEMOGRAPHIC_SCORE)
    })
}

export function generateAccountResultsRow(
  row: { [ k: string ]: any },
  translateCommon: TFunction,
): GridValidRowModel | null {
  if (!TableHelper.isSocialAccount(row)) return null
  const { scores, socialAccount } = row
  const { socialAccountStatistics: stats } = socialAccount
  const { organic, sponsored } = stats.accountEngagementRates

  return {
    id: socialAccount.id,
    account: {
      followers: stats.followers,
      fullName: socialAccount.name || "",
      isBlacklisted: socialAccount.personality?.blacklist || false,
      isNSFW: socialAccount.isNsfw,
      isPlaceholder: socialAccount.isPlaceholder,
      isUnsubscribed: [
        ...socialAccount.emails,
        ...socialAccount.emailsSourcedFromTeam,
      ].some(({ unsubscribedTags }) => unsubscribedTags.length > 0),
      lastPostedDate: socialAccount.lastPostedDate,
      network: socialAccount.network,
      profilePictureUrl: socialAccount.profilePictureUrl,
      profileNetworkUrl: socialAccount.networkUrl,
      username: socialAccount.userName,
      hasPersonality: socialAccount.personality != null,
      oauthed: socialAccount.oauthed,
    },
    followers: translateCommon("intlNumber", { val: stats.followers }),
    engagementRate: `${ MiscHelper.prettyPrintDecimal(stats.engagementRate) }%`,
    engagementScore: Math.floor(TableHelper.getScore(
      scores,
      GraphQL.ScoreType.Engagement,
    )),
    organicEngagementRate: `${ MiscHelper.prettyPrintDecimal(organic) }%`,
    sponsoredEngagementRate: `${ MiscHelper.prettyPrintDecimal(sponsored) }%`,
    audienceQualityScore: Math.round(TableHelper.getScore(
      scores,
      GraphQL.ScoreType.AudienceQuality,
    )),
    demographicScore: Math.round(TableHelper.getScore(
      scores,
      GraphQL.ScoreType.Demographic,
    )) || "",
    inDemoPercentage: row.inDemo == null
      ? null
      : `${ MiscHelper.prettyPrintDecimal(row.inDemo) }%`,
    adCouncilScore: Math.round(TableHelper.getScore(
      scores,
      GraphQL.ScoreType.IScore, // Same as I-Score for now
    )),
    iScore: Math.round(TableHelper.getScore(
      scores,
      GraphQL.ScoreType.IScore,
    )),
    biography: socialAccount.bio,
    matches: row.numMatchingCippus,
    scoreBreakDown: { socialAccount, scores },
  }
}

import React, {
  useState,
  ChangeEvent,
  JSX,
} from "react"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { SelectChangeEvent } from "@mui/material/Select"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import AddIcon from "@mui/icons-material/Add"
import {
  Container,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  FormGroup,
  IconButton,
  InputAdornment,
} from "@mui/material"

import * as ComponentHelper from "../../util/componentHelper"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../util/searchHelper"
import Radio from "../Radio"
import Checkbox from "../Checkbox"
import Select from "../Select"
import Input from "../Input"
import Pill from "../Pill"
import Button from "../Button"
import Tooltip from "../Tooltip"
import { isNumeric } from "../../util/miscHelper"
import { Scope } from "../../util/types"
import { setSearchInput, setSelectedLocations } from "../../state/searchSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

function getNumberDisplayValue(
  n: string | null | undefined,
  translate: TFunction,
): string {
  if (n == null) return ""
  return translate("intlNumber", { val: n })
}

function getMinEngagementRateValue(
  minEngagement: number | null | undefined,
  isDecimal: boolean,
): string {
  if (minEngagement == null) return ""
  const percentValue = (minEngagement * 100).toString()
  if (isDecimal) return `${ percentValue }.`.slice(0, 5)
  return percentValue
}

function renderBioKeywordsPills(
  searchInput: SearchHelper.SearchInput,
  updateSearchInput: (si: SearchHelper.SearchInput) => void,
): JSX.Element[] {
  const existingKeywords: string[] = []
  searchInput.influencerParams.bioKeywords
    .map((bk) => bk.tags)
    .forEach((bk) => {
      bk.forEach((k) => {
        existingKeywords.push(k)
      })
    })

  const handleRemoveKeyword = (keyword: string): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const filteredKeywords = searchInput.influencerParams.bioKeywords
      .filter((bk: GraphQL.KeywordInput) => (!bk.tags.includes(keyword)))

    newInput.influencerParams.bioKeywords = filteredKeywords
    updateSearchInput(newInput)
  }

  return existingKeywords.map((keyword) => (
    <Pill
      key={ keyword }
      label={ keyword }
      onDelete={ () => handleRemoveKeyword(keyword) }
    />
  ))
}

function SearchProfileForm(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchFilterTabs" })
  const dispatch = useDispatch()
  const searchInput = useSelector(({ search }) => search.searchInput)
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  const [ searchParams, setSearchParams ] = useSearchParams()

  const [ bioInputValue, setBioInputValue ] = useState("")
  const [ isDecimal, setIsDecimal ] = useState<boolean>(false)

  // NOTE: This list may become dynamic at some point in the future when
  // search is reworked
  const influencerVerticals = [
    translate("None"),
    translate("Art"),
    translate("Automotive"),
    translate("Beauty"),
    translate("Books"),
    translate("Business"),
    translate("Cannabis"),
    translate("Family"),
    translate("Fashion"),
    translate("Film"),
    translate("Fitness"),
    translate("Food"),
    translate("Gaming"),
    translate("LGBTQIA+"),
    translate("Liquor"),
    translate("Luxury"),
    translate("Military"),
    translate("Music"),
    translate("Outdoors"),
    translate("Pets"),
    translate("Photography"),
    translate("Real Estate"),
    translate("Religion"),
    translate("Sports"),
    translate("Tattoos"),
    translate("Tech"),
    translate("Travel"),
  ]

  // Event handler for Type
  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    let newBrandFilter: boolean | null

    switch (value) {
      case "influencer":
        newBrandFilter = false
        break

      case "brand":
        newBrandFilter = true
        break

      default:
        newBrandFilter = null
        break
    }

    const newInput = SearchHelper.cloneSearchInput(searchInput)
    newInput.influencerParams.brand = newBrandFilter
    dispatch(setSearchInput(newInput))
    SearchHelper.setInfluencerInputQueryParams(
      newInput.influencerParams,
      searchParams,
      setSearchParams,
    )
  }

  // Event handler for MIN FOLLOWERS
  const handleMinFollowersChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const { maxFollowers } = searchInput.influencerParams
    const inputValue = event.target.value
      .replace(/,/g, "")
      .replace(/\./g, "")

    const newMinFollowers = parseInt(inputValue, 10)

    // Check if the input value is empty or if it's not a number
    if (inputValue === "" || Number.isNaN(newMinFollowers)) {
      newInput.influencerParams.minFollowers = null
      dispatch(setSearchInput(newInput))
      SearchHelper.setInfluencerInputQueryParams(
        newInput.influencerParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    // Check if new min followers is a greater number than current max followers
    if (maxFollowers != null && parseInt(maxFollowers, 10) < newMinFollowers) {
      newInput.influencerParams.maxFollowers = null
      dispatch(setSearchInput(newInput))
      SearchHelper.setInfluencerInputQueryParams(
        newInput.influencerParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    newInput.influencerParams.minFollowers = newMinFollowers.toString()
    dispatch(setSearchInput(newInput))
    SearchHelper.setInfluencerInputQueryParams(
      newInput.influencerParams,
      searchParams,
      setSearchParams,
    )
  }

  // Event handler for MAX FOLLOWERS
  const handleMaxFollowersChange = (
    event:
    React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>
    | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    | React.KeyboardEvent<HTMLDivElement>,
    isDone?: boolean,
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const { minFollowers } = searchInput.influencerParams
    const target = event.target as HTMLInputElement | HTMLTextAreaElement
    const inputValue = target.value
      .replace(/,/g, "")
      .replace(/\./g, "")

    const newMaxFollowers = parseInt(inputValue, 10)

    // Check if the input value is empty or if it's not a number
    if (inputValue === "" || Number.isNaN(newMaxFollowers)) {
      newInput.influencerParams.maxFollowers = null
      dispatch(setSearchInput(newInput))
      SearchHelper.setInfluencerInputQueryParams(
        newInput.influencerParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    if (isDone) {
      // Check if new max followers is a smaller number than current min followers
      if (minFollowers != null && parseInt(minFollowers, 10) > newMaxFollowers) {
        newInput.influencerParams.minFollowers = null
        dispatch(setSearchInput(newInput))
        SearchHelper.setInfluencerInputQueryParams(
          newInput.influencerParams,
          searchParams,
          setSearchParams,
        )
        return
      }
    }

    newInput.influencerParams.maxFollowers = newMaxFollowers.toString()
    dispatch(setSearchInput(newInput))
    SearchHelper.setInfluencerInputQueryParams(
      newInput.influencerParams,
      searchParams,
      setSearchParams,
    )
  }

  const handleMaxFollwerKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleMaxFollowersChange(event, true)
    }
  }

  // Event handler for Bio Keywords
  const handleAddKeywords = (): void => {
    // Split the input by commas, trim whitespace, and filter out any empty strings
    const newKeywords = bioInputValue.split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)

    const existingKeywords: string[] = []
    searchInput.influencerParams.bioKeywords
      .map(({ tags }) => tags)
      .forEach((tags) => {
        tags.forEach((tag) => {
          existingKeywords.push(tag)
        })
      })

    const filteredKeywords = newKeywords
      .filter((keyword) => !existingKeywords.includes(keyword))

    if (filteredKeywords.length > 0) {
      const newInput = SearchHelper.cloneSearchInput(searchInput)
      const combinedKeywords = [ ...filteredKeywords, ...existingKeywords ]
        .map((k): GraphQL.KeywordInput => ({ tags: [ k ], inclusive: false }))

      newInput.influencerParams.bioKeywords = combinedKeywords
      dispatch(setSearchInput(newInput))
      SearchHelper.setInfluencerInputQueryParams(
        newInput.influencerParams,
        searchParams,
        setSearchParams,
      )
    }

    setBioInputValue("") // Clear the input field after adding keywords
  }

  // Event handler Bio keywords field on enter
  const handleBioKeyDown = (event: React.KeyboardEvent): void => {
    // When the user presses Enter, add the keywords
    if (event.key === "Enter") {
      event.preventDefault() // Prevent form submission and other default actions
      handleAddKeywords()
    }
  }

  // Event handler Engagment field
  const handleEngmntFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    if (newValue === "") {
      newInput.influencerParams.minEngagement = null
      dispatch(setSearchInput(newInput))
      SearchHelper.setInfluencerInputQueryParams(
        newInput.influencerParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    if (isNumeric(newValue) && parseFloat(newValue) <= 100) {
      newInput.influencerParams.minEngagement = parseFloat(newValue) * 0.01
      dispatch(setSearchInput(newInput))
      SearchHelper.setInfluencerInputQueryParams(
        newInput.influencerParams,
        searchParams,
        setSearchParams,
      )
    }

    if (newValue.slice(-1) === ".") {
      setIsDecimal(true)
      return
    }

    setIsDecimal(false)
  }

  // Event handler Vertical
  const handleVerticalChange = (event: SelectChangeEvent<string>): void => {
    const newVertical = event.target.value
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    if (newVertical === translate("None")) {
      newInput.influencerParams.verticals = [ "None" ]
      dispatch(setSearchInput(newInput))
      SearchHelper.setInfluencerInputQueryParams(
        newInput.influencerParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    newInput.influencerParams.verticals = [ newVertical ]
    dispatch(setSearchInput(newInput))
    SearchHelper.setInfluencerInputQueryParams(
      newInput.influencerParams,
      searchParams,
      setSearchParams,
    )
  }

  return (
    <Container className="cp_component_search-profile cp_component_search-form-container">
      <form>
        { /* Type section */ }
        { scopes.includes(Scope.PROFILE_TYPE) && (
        <FormControl className="cp_component_search-profile-type" component="fieldset" fullWidth={ true }>
          <FormLabel component="legend">
            { translate("Type") }
            <Tooltip arrow={ true } title={ translate("Brand tooltip mssg") }>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </FormLabel>
          <RadioGroup
            row={ true }
            aria-label="type"
            name="type"
            value={ SearchHelper.brandValueToString(
              searchInput.influencerParams.brand,
            ) }
            onChange={ handleTypeChange }
          >
            <FormControlLabel
              value="influencer"
              control={ <Radio /> }
              label={ translate("Influencer") }
            />
            <FormControlLabel
              value="brand"
              control={ <Radio /> }
              label={ translate("Brand") }
            />
            <FormControlLabel
              value="both"
              control={ <Radio /> }
              label={ translate("Both") }
            />
          </RadioGroup>
        </FormControl>
        ) }
        { /* Followers section */ }
        <FormControl
          className="cp_component_search-profile-followers"
          component="fieldset"
          fullWidth={ true }
        >
          <div className="cp_component_search-profile-followers_wrapper min-followers">
            <FormLabel component="legend">
              { translate("MIN FOLLOWERS") }
            </FormLabel>

            <Input
              label=""
              value={ getNumberDisplayValue(
                searchInput.influencerParams.minFollowers,
                translate,
              ) }
              onChange={ handleMinFollowersChange }
              placeholder={ translate("Min") }
              type="text"
            />
          </div>
          <div className="cp_component_search-profile-followers_wrapper max-followers">
            <FormLabel component="legend">
              { translate("MAX FOLLOWERS") }
            </FormLabel>

            <Input
              label=""
              value={ getNumberDisplayValue(
                searchInput.influencerParams.maxFollowers,
                translate,
              ) }
              onChange={ (e) => { handleMaxFollowersChange(e) } }
              onBlur={ (e) => { handleMaxFollowersChange(e, true) } }
              onKeyDown={ (e) => { handleMaxFollwerKeydown(e) } }
              placeholder={ translate("Max") }
              type="text"
              InputProps={ {
                inputProps: {
                  min: 0,
                },
              } }
            />
          </div>
        </FormControl>
        { /* Bio Keywords section */ }
        <FormControl
          className="cp_component_search-profile-biokeywords"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("Bio Keywords") }
            <Tooltip arrow={ true } title={ translate("Comma Separated") }>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </FormLabel>
          <Input
            label=""
            value={ bioInputValue }
            placeholder={ translate("Bio Keywords") }
            onChange={ (e) => setBioInputValue(e.target.value) }
            onKeyDown={ handleBioKeyDown }
            InputProps={ {
              endAdornment: (
                <IconButton onClick={ handleAddKeywords }>
                  <AddIcon />
                </IconButton>
              ),
            } }
          />
          <div className="cp_component_search-profile-biokeywords-pills">
            {
              renderBioKeywordsPills(
                searchInput,
                (i: SearchHelper.SearchInput) => dispatch(setSearchInput(i)),
              )
            }
          </div>
        </FormControl>
        { /* Engagement section */ }
        <FormControl
          className="cp_component_search-profile-engagement"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("MINIMUM ENGAGEMENT RATE (AVG)") }
            <Tooltip arrow={ true } title={ translate("engagement tooltip mssg") }>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </FormLabel>
          <Input
            label=""
            placeholder={ translate("Enter engagement rate") }
            value={ getMinEngagementRateValue(
              searchInput.influencerParams.minEngagement,
              isDecimal,
            ) }
            onChange={ handleEngmntFieldChange }
            type="text"
            InputProps={ {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: {
                min: 0,
                max: 100,
                step: "0.01",
                maxLength: 5,
              },
            } }
          />
        </FormControl>
        { /* Vertical section */ }
        <FormControl
          className="cp_component_search-profile-vertical"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("Vertical") }
            <Tooltip arrow={ true } title={ translate("Vertical tooltip mssg") }>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </FormLabel>
          <Select
            value={
              searchInput.influencerParams.verticals == null
              || searchInput.influencerParams.verticals.length === 0
              || searchInput.influencerParams.verticals[0] == null
              || searchInput.influencerParams.verticals[0] === ""
                ? translate("None")
                : translate(searchInput.influencerParams.verticals[0])
            }
            onChange={ handleVerticalChange }
            id="cp_component_search-profile-vertical-select"
            label=""
            displayEmpty={ false }
            labelId="cp_component_search-profile-vertical-label"
            menuItems={
              ComponentHelper.selectionOptions(influencerVerticals, true)
            }
          />
        </FormControl>

        { /* Checkbox section */ }
        <FormControl
          className="cp_component_search-profile-traits"
          component="fieldset"
          fullWidth={ true }
        >
          <FormGroup>
            { scopes.includes(Scope.VIP_PROFILES) && (
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={
                      searchInput.influencerParams.vipAccountsOnly || false
                    }
                    onChange={ () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      const { influencerParams: inf } = searchInput
                      newInput.influencerParams.vipAccountsOnly = !inf.vipAccountsOnly
                      dispatch(setSearchInput(newInput))
                      SearchHelper.setInfluencerInputQueryParams(
                        newInput.influencerParams,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                    name={ translate("Marked as VIP") }
                  />
                ) }
                label={ translate("Marked as VIP") }
              />
            ) }
            { scopes.includes(Scope.WORKED_WITH) && (
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={
                      searchInput.influencerParams.usedInCampaign || false
                    }
                    onChange={ () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      const { influencerParams: inf } = searchInput
                      newInput.influencerParams.usedInCampaign = !inf.usedInCampaign
                      dispatch(setSearchInput(newInput))
                      SearchHelper.setInfluencerInputQueryParams(
                        newInput.influencerParams,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                    name={ translate("Worked With") }
                  />
                ) }
                label={ translate("Worked With") }
              />
            ) }
            { scopes.includes(Scope.FEATURE_SEARCH_CONTACT_INFO) && (
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={
                    searchInput.influencerParams.contactProvided || false
                  }
                  onChange={ () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const { influencerParams: inf } = searchInput
                    newInput.influencerParams.contactProvided = !inf.contactProvided
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setInfluencerInputQueryParams(
                      newInput.influencerParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  name={ translate("Contact Information Provided") }
                />
              ) }
              label={ translate("Contact Information Provided") }
            />
            ) }
            { scopes.includes(Scope.BLOCKLISTED_PROFILES) && (
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={
                      searchInput.influencerParams.hideBlacklistedAccounts || false
                    }
                    onChange={ () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      const { influencerParams: inf } = searchInput
                      newInput.influencerParams.hideBlacklistedAccounts = !inf.hideBlacklistedAccounts
                      dispatch(setSearchInput(newInput))
                      SearchHelper.setInfluencerInputQueryParams(
                        newInput.influencerParams,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                    name={ translate("Hide Blocklisted Social Accounts") }
                  />
                ) }
                label={ translate("Hide Blocklisted Social Accounts") }
              />
            ) }
            { scopes.includes(Scope.NSFW_FLAG) && (
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={
                    searchInput.influencerParams.excludeFlaggedAccounts || false
                  }
                  onChange={ () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const { influencerParams: inf } = searchInput
                    newInput.influencerParams.excludeFlaggedAccounts = !inf.excludeFlaggedAccounts
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setInfluencerInputQueryParams(
                      newInput.influencerParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  name={ translate("Hide Not Safe For Work Social Accounts") }
                />
              ) }
              label={ translate("Hide Not Safe For Work Social Accounts") }
            />
            ) }
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={
                    searchInput.influencerParams.activeAccountsOnly || false
                  }
                  onChange={ () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const { influencerParams: inf } = searchInput
                    newInput.influencerParams.activeAccountsOnly = !inf.activeAccountsOnly
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setInfluencerInputQueryParams(
                      newInput.influencerParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  name={ translate("Hide Inactive Social Accounts") }
                />
              ) }
              label={ translate("Hide Inactive Social Accounts") }
            />
            { scopes.includes(Scope.OAUTH_ACCOUNTS) && (
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={
                    searchInput.influencerParams.oauthAccountsOnly || false
                  }
                  onChange={ () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const { influencerParams: inf } = searchInput
                    newInput.influencerParams.oauthAccountsOnly = !inf.oauthAccountsOnly
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setInfluencerInputQueryParams(
                      newInput.influencerParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  name={ translate("Oauth Accounts") }
                />
              ) }
              label={ translate("Oauth Accounts") }
            />
            ) }
          </FormGroup>

        </FormControl>
        { /* Reset filters button */ }
        <div className="cp_component_search-profile-footer">
          <Button
            className="cp_component_search-profile-reset_button"
            isEnabled={ true }
            isPrimary={ false }
            label={ translate("RESET FILTERS") }
            onClick={ () => {
              dispatch(setSearchInput(SearchHelper.initialSearchState()))
              dispatch(setSelectedLocations([]))
              searchParams.delete(SearchHelper.QUERY_PARAM_Q)
              searchParams.delete(SearchHelper.QUERY_PARAM_INFLUENCER)
              searchParams.delete(SearchHelper.QUERY_PARAM_AUDIENCE)
              searchParams.delete(SearchHelper.QUERY_PARAM_CONTENT)
              searchParams.delete(SearchHelper.QUERY_PARAM_LOCATION)
              setSearchParams(searchParams)
            } }
          />
        </div>
      </form>
    </Container>
  )
}

export default SearchProfileForm

import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"

// Campaign Modal Slice Interface and Initial State
export interface CampaignModalState {
  isEditingCampaign: boolean
  openCampaignModal: boolean
  campaignToEdit?: GraphQL.CampaignQuery["campaign"]
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Modal.
   */
  updateCallback?: () => any
  campaignManagers: Status<GraphQL.GetCampaignManagersQuery>
}

const initialState: CampaignModalState = {
  isEditingCampaign: false,
  openCampaignModal: false,
  campaignToEdit: undefined,
  updateCallback: undefined,
  campaignManagers: "init",
}

// Campaign Modal Slice
export const CampaignModalSlice = createSlice({
  name: "CampaignModalSlice",
  initialState,
  reducers: {
    setCampaign: (
      state,
      action: PayloadAction<GraphQL.CampaignQuery>,
    ) => ({
      ...state,
      campaign: action.payload,
    }),
    setCampaignManagers: (
      state,
      action: PayloadAction<Status<GraphQL.GetCampaignManagersQuery>>,
    ) => ({
      ...state,
      campaignManagers: action.payload,
    }),
    openNewCampaignModal: (
      state,
    ) => ({
      ...state,
      isEditingCampaign: false,
      openCampaignModal: true,
    }),
    openEditCampaignModal: (
      state,
      action: PayloadAction<{
        campaign: GraphQL.CampaignQuery["campaign"],
        updateCallback?: () => any,
      }>,
    ) => ({
      ...state,
      campaignToEdit: action.payload.campaign,
      updateCallback: action.payload.updateCallback,
      isEditingCampaign: true,
      openCampaignModal: true,
    }),
    closeCampaignModal: (
      state,
    ) => ({
      ...state,
      openCampaignModal: false,
      isEditingCampaign: false,
      campaignToEdit: undefined,
    }),
  },
})

export const {
  setCampaign,
  openEditCampaignModal,
  openNewCampaignModal,
  closeCampaignModal,
  setCampaignManagers,
} = CampaignModalSlice.actions
export default CampaignModalSlice.reducer

// Profile Slice Thunks
export const updateCampaign = (
  params: GraphQL.UpdateCampaignMutationVariables,
) => async () => {
  const result = await API.updateCampaign(params)
  return result
}

export const fetchCampaignManagers = () => async (
  dispatch: Dispatch,
) => {
  dispatch(setCampaignManagers("loading"))
  const result = await API.fetchCampaignManagers()
  dispatch(setCampaignManagers(result))
}

import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import "./social-tracker-card.sass"
import {
  Delete,
  Download,
  MoreVert,
  Paid,
} from "@mui/icons-material"
import {
  ListItemText,
  Menu,
  MenuList,
  ButtonBase,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { store } from "../../../../state/store"
import { ContentAlertFragment } from "../../../../graphql"
import NetworkIcons from "./NetworkIcons"
import IconButton from "../../../IconButton"
import Divider from "../../../Divider"
import MenuItem from "../../../MenuItem"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { openConfirmationModal } from "../../../../state/confirmationModalSlice"
import { Scope } from "../../../../util/types"
import { deleteSocialTracker, getSocialTrackers } from "../../../../state/slidingPanelSlice/socialTracker"
import {
  setSocialTrackers,
  PanelState,
  togglePanel,
  setSocialTrackerSearchInput,
} from "../../../../state/slidingPanelSlice"
import { openSocialTrackerDownloadCSVModal } from "../../../../state/socialTrackerDownloadCSVModalSlice"
import { fetchSocialTracker } from "../../../../state/socialTrackerPage"

type Props = {
  socialTracker: ContentAlertFragment
  id?: string
}

export default function SocialTrackerCard({ socialTracker, id }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.SocialTrackerCard" })
  const dispatch = useDispatch()
  const { vanity, trackerID } = useParams()
  const navigate = useNavigate()
  const { scopes } = useSelector(({ user }) => user)
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)

  const onClose = () => {
    setAnchorEl(null)
  }

  const onSuccess = () => {
    dispatch(setSocialTrackerSearchInput(""))
    dispatch(getSocialTrackers(""))
    if (trackerID) {
      dispatch(fetchSocialTracker(trackerID))
    }
  }

  const handleSocialTrackerClick = () => {
    store.dispatch(togglePanel(PanelState.SOCIAL_TRACKER))
    navigate(`/${ vanity }/social-tracker/${ socialTracker.xref }`)
  }

  return (
    <>
      <ButtonBase onClick={ handleSocialTrackerClick }>
        <div id={ id } className="cp_component_social-tracker-card">
          <div className="header">
            <p>{ socialTracker.name }</p>
            { socialTracker.params?.contentParams?.sponsored && <Paid /> }
          </div>
          <Divider />
          <div className="footer">
            <NetworkIcons availableNetworks={ socialTracker.params?.socialNetworks || [] } />
            <IconButton
              id="cp_component_social-tracker-menu-button"
              onClick={ (event) => {
                event.stopPropagation()
                setAnchorEl(event.currentTarget)
              } }
            >
              <MoreVert />
            </IconButton>
          </div>
        </div>
      </ButtonBase>
      <Menu
        className="cp_component_social-tracker-menu"
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        transformOrigin={ {
          horizontal: "left",
          vertical: "top",
        } }
        anchorOrigin={ {
          horizontal: "left",
          vertical: "bottom",
        } }
      >
        <MenuList dense={ true }>
          { scopes.includes(Scope.SOCIAL_TRACKER_DOWNLOAD) && (
          <MenuItem
            id="cp_component_social-tracker-menu-download"
            className="menu-item"
            onClick={ () => {
              dispatch(openSocialTrackerDownloadCSVModal(socialTracker))
              onClose()
            } }
          >
            <ListItemText className="menu-item-label">
              { translate("Download") }
              { " " }
              <Download />
            </ListItemText>
          </MenuItem>
          ) }
          <MenuItem
            id="cp_component_social-tracker-menu-delete"
            className="menu-item"
            onClick={ (() => {
              dispatch(openConfirmationModal({
                title: translate("Delete Tracker"),
                subtext: (
                  <p>
                    { translate("Are you sure you want to delete") }
                    { " " }
                    <b>{ socialTracker.name }</b>
                    ?
                  </p>),
                primaryLabel: translate("Delete"),
                secondaryLabel: translate("Cancel"),
                onConfirm: () => {
                  dispatch(deleteSocialTracker({
                    variables: { id: socialTracker.id },
                    onSuccess,
                    onError: () => dispatch(setSocialTrackers({ status: "error" })),
                  }))
                },
              }))
              onClose()
            }
            ) }
          >
            <ListItemText className="menu-item-label">
              { translate("Delete Social Tracker") }
              { " " }
              <Delete />
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

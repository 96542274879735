import React from "react"
import { useTranslation } from "react-i18next"
import LoadingIndicator from "../../../LoadingIndicator"
import AccountInsightsDetails from "."
import { useSelector } from "../../../../state/hooks"
import PlaceholderCard from "../../AccountInsights/PlaceholderCard"
import PlaceholderEmptySplash from "../../AccountInsights/PlaceholderEmptySplash"

export default function AccountInsightsDetailsToggleWrapper() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const { listProfileToggles, listProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)

  if (
    listProfileToggles === "loading"
    || listProfileToggles === "init"
    || listProfile === "init"
    || listProfile === "loading"
  ) {
    return (
      <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
    )
  }
  if (listProfileToggles.status === "error" || listProfile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  const {
    isPlaceholder,
  } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount

  if (isPlaceholder) {
    return (
      <div>
        <PlaceholderCard />
        <PlaceholderEmptySplash />
      </div>
    )
  }

  return (
    <AccountInsightsDetails
      toggles={ listProfileToggles.payload.suggestionListSocialAccountByListIdSocialAccountId.suggestionList.toggles }
    />
  )
}

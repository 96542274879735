import React, { JSX, useState } from "react"

import EnterTeamUrlForm from "./EnterTeamUrlForm"
import FindTeamForm from "./FindTeamForm"

import "./style.sass"

export default function Team(): JSX.Element {
  const [ findTeamActive, setFindTeamActive ] = useState<boolean>(false)

  return (
    <div className="cp_page_team">
      <div>
        { findTeamActive
          ? <FindTeamForm setFindTeamActive={ setFindTeamActive } />
          : <EnterTeamUrlForm setFindTeamActive={ setFindTeamActive } />
        }
      </div>
    </div>
  )
}

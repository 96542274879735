import React from "react"
import { useTranslation } from "react-i18next"

import "./media-list.sass"
import { DeliverableMediaFragment } from "../../graphql"
import DeliverableMediaCard from "./MediaCard"

interface DeliverableMediaListProps {
  deliverableMedia: DeliverableMediaFragment[]
}

function DeliverableMediaList({ deliverableMedia }: DeliverableMediaListProps): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })
  if (deliverableMedia.length === 0) return <> </>
  return (
    <div className="cp_campaign-deliverable-media-list_component">
      <p className="body_large">
        { translate("Media*") }
      </p>
      <div className="cp_campaign-deliverable-media-list_component-body">
        {
          deliverableMedia.map((media) => (
            <DeliverableMediaCard
              key={ media.id }
              deliverableMedia={ media }
            />
          ))
        }
      </div>
    </div>
  )
}

export default DeliverableMediaList

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../util/searchHelper"
import { setSelectedAccounts as setListSelectedAccounts } from "../listAddAccount"
import { RootState } from "../store"
import { Status } from "../../util/types"
import { SearchFieldString } from "../../util/searchHelper"

// Search Slice Interface and Initial State
export interface SearchState {
  audienceLocations: Status<GraphQL.SearchAudienceLocationsQuery>,
  brands: Status<GraphQL.SearchBrandsQuery>,
  imageTags: Status<GraphQL.SearchImageTagsQuery>,
  locations: Status<GraphQL.SearchLocationsQuery>,
  searchInput: SearchHelper.SearchInput,
  searchResultsAbortController: AbortController,
  searchResultsAccounts: GraphQL.GodSearchSocialAccount[],
  searchResultsContent: GraphQL.Post[],
  searchStatus: Status<GraphQL.SearchInfluencerContentQuery>,
  selectedAccounts: string[],
  selectedLocations: SearchHelper.LocationWithMatch[],
}

const initialState: SearchState = {
  audienceLocations: "init",
  brands: "init",
  imageTags: "init",
  locations: "init",
  searchInput: SearchHelper.initialSearchState(),
  searchResultsAbortController: new AbortController(),
  searchResultsAccounts: [],
  searchResultsContent: [],
  searchStatus: "init",
  selectedAccounts: [],
  selectedLocations: [],
}

// Search Slice
export const searchSlice = createSlice({
  name: "Search",
  initialState,
  reducers: {
    setAudienceLocations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchAudienceLocationsQuery>>,
    ) => ({
      ...state,
      audienceLocations: action.payload,
    }),

    setBrands: (
      state,
      action: PayloadAction<Status<GraphQL.SearchBrandsQuery>>,
    ) => ({
      ...state,
      brands: action.payload,
    }),

    setImageTags: (
      state,
      action: PayloadAction<Status<GraphQL.SearchImageTagsQuery>>,
    ) => ({
      ...state,
      imageTags: action.payload,
    }),

    setLocations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchLocationsQuery>>,
    ) => ({
      ...state,
      locations: action.payload,
    }),

    setSearchInput: (
      state,
      action: PayloadAction<SearchHelper.SearchInput>,
    ) => ({
      ...state,
      searchInput: action.payload,
    }),

    setSearchResultsAbortController: (
      state,
      action: PayloadAction<AbortController>,
    ) => ({
      ...state,
      searchResultsAbortController: action.payload,
    }),

    setSearchResultsAccounts: (
      state,
      action: PayloadAction<GraphQL.GodSearchSocialAccount[]>,
    ) => ({
      ...state,
      searchResultsAccounts: action.payload,
    }),

    setSearchResultsContent: (
      state,
      action: PayloadAction<GraphQL.Post[]>,
    ) => ({
      ...state,
      searchResultsContent: action.payload,
    }),

    setSearchStatus: (
      state,
      action: PayloadAction<Status<GraphQL.SearchInfluencerContentQuery>>,
    ) => ({
      ...state,
      searchStatus: action.payload,
    }),
    setSelectedAccounts: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedAccounts: action.payload,
    }),
    setSelectedLocations: (
      state,
      action: PayloadAction<SearchHelper.LocationWithMatch[]>,
    ) => ({
      ...state,
      selectedLocations: action.payload,
    }),
  },
})

export const {
  setAudienceLocations,
  setBrands,
  setImageTags,
  setLocations,
  setSearchInput,
  setSearchResultsAbortController,
  setSearchResultsAccounts,
  setSearchResultsContent,
  setSearchStatus,
  setSelectedAccounts,
  setSelectedLocations,
} = searchSlice.actions

export default searchSlice.reducer

// Type Helpers
function isAccountsResult(
  r: { [ k: string ]: any },
): r is GraphQL.GodSearchSocialOutput {
  // eslint-disable-next-line no-underscore-dangle
  return r?.__typename === "GodSearchSocialOutput"
}

function isContentResult(
  r: { [ k: string ]: any },
): r is GraphQL.GodSearchPostOutput {
  // eslint-disable-next-line no-underscore-dangle
  return r?.__typename === "GodSearchPostOutput"
}

// Search Slice Thunks
export const clearSearchState = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setSearchInput(SearchHelper.initialSearchState()))
  dispatch(setSearchResultsAccounts([]))
  dispatch(setSearchResultsContent([]))
  dispatch(setSelectedAccounts([]))
  dispatch(setListSelectedAccounts([]))
}

export const fetchAudienceLocations = (
  startsWith: string,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setAudienceLocations("loading"))
  const audienceLocationsResult = await API.fetchAudienceLocations(startsWith)
  dispatch(setAudienceLocations(audienceLocationsResult))
}

export const fetchBrands = (
  startsWith: string | null,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setBrands("loading"))
  const brandsResults = await API.fetchBrands(startsWith)
  dispatch(setBrands(brandsResults))
}

export const fetchImageTags = (
  startsWith: string | null,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setImageTags("loading"))
  const imageTagResults = await API.fetchImageTags(startsWith)
  dispatch(setImageTags(imageTagResults))
}

export const fetchLocations = (
  startsWith: string,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setLocations("loading"))
  const locationsResult = await API.fetchLocations(startsWith)
  dispatch(setLocations(locationsResult))
}

export const fetchSearchResults = (
  clearResults: boolean,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { search: initialSearchState } = await getState()

  // Abort any current search requests...
  if (initialSearchState.searchStatus === "loading") {
    initialSearchState.searchResultsAbortController.abort()
    dispatch(setSearchResultsAbortController(new AbortController()))
  }

  // Reset search results if results need to be cleared
  if (clearResults) {
    const newInput = SearchHelper
      .cloneSearchInput(initialSearchState.searchInput)

    newInput.page = 1
    dispatch(setSearchInput(newInput))
    dispatch(setSearchResultsAccounts([]))
    dispatch(setSearchResultsContent([]))
    dispatch(setSelectedAccounts([]))
    dispatch(setListSelectedAccounts([]))
  }

  // Refetch fresh state after clearing results... prevents stale state
  const { search } = await getState()

  const searchInput = JSON.parse(JSON.stringify(search.searchInput))

  // If the sort by is AdCouncilScore, we need to change it to IScore
  // because thie backend doesn't know what AdCouncilScore is
  if (search.searchInput.sortBy === SearchFieldString.AdCouncilScore) searchInput.sortBy = SearchFieldString.IScore

  // Search!
  dispatch(setSearchStatus("loading"))

  const searchResult = await API.query<
    GraphQL.SearchInfluencerContentQuery,
    GraphQL.SearchInfluencerContentQueryVariables
  >(
    GraphQL.SearchInfluencerContentDocument,
    { params: searchInput },
    undefined,
    {
      fetchOptions: {
        signal: search.searchResultsAbortController.signal,
      },
    },
  )

  // Result was aborted in lieu of a new search request. Do nothing, allow
  // new request to manage setting result state...
  if (API.isError(searchResult) && searchResult.statusCode === 499) {
    return
  }

  // Result was for accounts, set account results
  if (
    API.isSuccess(searchResult)
    && isAccountsResult(searchResult.payload.searchInfluencerContent.result)
  ) {
    const accountsResultsAggregate = [
      ...search.searchResultsAccounts,
      ...searchResult.payload.searchInfluencerContent.result.rows,
    ]

    dispatch(setSearchResultsAccounts(accountsResultsAggregate))
  }

  // Result was for content, set content results
  if (
    API.isSuccess(searchResult)
    && isContentResult(searchResult.payload.searchInfluencerContent.result)
  ) {
    const contentResultsAggregate = [
      ...search.searchResultsContent,
      ...searchResult.payload.searchInfluencerContent.result.rows,
    ]

    dispatch(setSearchResultsContent(contentResultsAggregate))
  }

  // Call was a success, queue up the next page
  if (API.isSuccess(searchResult)) {
    // Grab fresh search state, otherwise stale form input data may recur.
    const { search: postSearchState } = await getState()
    const newInput = SearchHelper.cloneSearchInput(postSearchState.searchInput)
    newInput.page = postSearchState.searchInput.page + 1
    dispatch(setSearchInput(newInput))
  }

  // Save raw search result
  dispatch(setSearchStatus(searchResult))
}

export const markPostNsfw = (
  postId: string,
  contentSearchUpdate: GraphQL.Post[],
) => async (
  dispatch: Dispatch,
): Promise<Status<GraphQL.PostMarkNsfwMutationMutation | null>> => {
  const mutationResult = await API.mutate<
    GraphQL.PostMarkNsfwMutationMutation,
    GraphQL.PostMarkNsfwMutationMutationVariables
  >(
    GraphQL.PostMarkNsfwMutationDocument,
    { postId },
  )

  if (API.isSuccess(mutationResult)) {
    dispatch(setSearchResultsContent(contentSearchUpdate))
  }

  return mutationResult
}

export const markSocialAccountNsfw = (
  socialAccountId: string,
  contentSearchUpdate: GraphQL.GodSearchSocialAccount[],
) => async (
  dispatch: Dispatch,
): Promise<Status<GraphQL.SocialAccountMarkNsfwMutationMutation | null>> => {
  const mutationResult = await API.mutate<
    GraphQL.SocialAccountMarkNsfwMutationMutation,
    GraphQL.SocialAccountMarkNsfwMutationMutationVariables
  >(
    GraphQL.SocialAccountMarkNsfwMutationDocument,
    { socialAccountId },
  )

  if (API.isSuccess(mutationResult)) {
    dispatch(setSearchResultsAccounts(contentSearchUpdate))
  }

  return mutationResult
}

/* eslint-disable max-len */
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import RelevantPostsPreview from "../../Content/RelevantPostsPreview"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import {
  getPostsByKeyword,
  setActiveKeywordId,
  setPostKeywordTags,
} from "../../../../state/publicListSlice"
import { isSuccess } from "../../../../util/apiClient"

function RelevantPostsByKeywords() {
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const { listCode } = useParams()
  const {
    postsByKeyword, loadedListSocialAccount, activeKeywordId, postKeywordTags,
  } = useSelector(({ publicList }) => publicList)
  const dispatch = useDispatch()

  useEffect(() => {
    if (postsByKeyword === "init") {
      if (isSuccess(loadedListSocialAccount) && listCode) {
        const {
          socialAccount: {
            id,
          },
          keywordsWithPosts,
        } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
        const alphabetizedPosts = [ ...keywordsWithPosts ].sort((a, b) => a.name.localeCompare(b.name))
        dispatch(setPostKeywordTags(alphabetizedPosts))
        dispatch(setActiveKeywordId(alphabetizedPosts[0].id))
        dispatch(getPostsByKeyword({
          code: listCode,
          socialAccountId: id,
          keywordId: alphabetizedPosts[0].id,
        }))
      }
    }
  }, [ postsByKeyword ])

  const handleKeywordChange = async (keywordId: string) => {
    if (keywordId !== activeKeywordId) {
      dispatch(setActiveKeywordId(keywordId))
      if (listCode && isSuccess(loadedListSocialAccount)) {
        await dispatch(getPostsByKeyword({
          code: listCode,
          socialAccountId: loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId.socialAccount.id,
          keywordId,
        }))
      }
    }
  }

  if (
    loadedListSocialAccount === "init"
    || loadedListSocialAccount === "loading"
    || postsByKeyword === "init"
    || postsByKeyword === "loading"
  ) {
    return <LoadingIndicatorCard />
  }

  if (postsByKeyword.status === "error") {
    return <p>{ translateCommon("An unexpected error occurred!") }</p>
  }

  const {
    cippusRelevant: keywordPosts,
  } = postsByKeyword.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId

  return (
    <RelevantPostsPreview
      classes={ [ "keyword-preview" ] }
      tags={ postKeywordTags }
      activeTag={ activeKeywordId || "" }
      posts={ keywordPosts.map(({ post }) => post) }
      dropdownSelectionAction={ (keywordId: string) => handleKeywordChange(keywordId) }
    />
  )
}

export default RelevantPostsByKeywords

import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"

import "./campaign-account-row-context-menu.sass"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import { CampaignNetworkAccountFragment } from "../../../graphql"
import { removeCampaignNetworkAccount, setCampaignDeliverablesStartsWithFilter } from "../../../state/campaignSlice"
import AccountDialog from "./AccountDialog"
import IconButton from "../../IconButton"
import { Scope } from "../../../util/types"

interface CampaignAccountRowContextMenuProps {
  campaignNetworkAccount: CampaignNetworkAccountFragment
  onAddDeliverable: () => any
}

export default function CampaignAccountRowContextMenu({
  campaignNetworkAccount,
  onAddDeliverable,
}: CampaignAccountRowContextMenuProps) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignAccountRowContextMenu" })
  const [ isDialogOpen, setIsDialogOpen ] = React.useState(false)
  const { deleteNetworkAccount } = useSelector(({ campaignPage }) => campaignPage)
  const { scopes } = useSelector(({ user }) => user)
  const { campaignID } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const networkAccountId = campaignNetworkAccount.socialAccount.id

  const handleDeleteAccount = () => {
    if (campaignID && networkAccountId) {
      dispatch(removeCampaignNetworkAccount({ campaignId: campaignID, networkAccountId }, translate))
    }
  }

  const handleCloseSuccessDialog = () => {
    setIsDialogOpen(false)
  }

  React.useEffect(() => {
    if (API.isSuccess(deleteNetworkAccount) || API.isError(deleteNetworkAccount)) {
      setIsDialogOpen(false)
    }
  }, [ deleteNetworkAccount ])

  return (
    <>
      <IconButton
        className="cp_campaign-account-row-context-menu_component-button"
        onClick={ ({ currentTarget }) => setAnchorEl(currentTarget) }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_campaign-account-row-context-menu_component-menu-popover"
      >
        <MenuList
          dense={ true }
          className="cp_campaign-account-row-context-menu_component-menu"
        >
          <MenuItem
            className="cp_campaign-account-row-context-menu_component-menu-item"
            onClick={ () => {
              dispatch(setCampaignDeliverablesStartsWithFilter(campaignNetworkAccount.socialAccount.userName))
              navigate("../deliverables", { relative: "path" })
            } }
          >
            <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
              { translate("View Deliverable") }
            </ListItemText>
          </MenuItem>
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <>
            <MenuItem
              className="cp_campaign-account-row-context-menu_component-menu-item"
              onClick={ onAddDeliverable }
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Add Deliverable") }
              </ListItemText>
            </MenuItem>
            <MenuItem
              className="cp_campaign-account-row-context-menu_component-menu-item"
              onClick={ () => setIsDialogOpen(true) }
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Delete") }
              </ListItemText>
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </MenuItem>
          </>
          ) }
        </MenuList>
      </Menu>
      <AccountDialog
        isOpen={ isDialogOpen }
        handleClose={ handleCloseSuccessDialog }
        dialogAction={ handleDeleteAccount }
        dialogTitle={ translate("Delete Account") }
        dialogContextText={ translate("Are you sure you want to delete the selected account(s)?") }
        buttonLabel={ translate("YES, DELETE") }
        secondaryButtonLabel={ translate("NO") }
      />
    </>
  )
}

import React from "react"
import { useParams } from "react-router-dom"
import CampaignAdsDetails from "./CampaignAdsDetails"
import CampaignAdSets from "./CampaignAdSets"

export default function CampaignAds() {
  const { adID } = useParams()

  if (!adID) {
    return <CampaignAdsDetails />
  }

  return <CampaignAdSets adID={ adID } />
}

import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react"
import ClearIcon from "@mui/icons-material/Clear"
import { ChatBubbleOutlineRounded, FavoriteBorder } from "@mui/icons-material"
import { Chip } from "@mui/material"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import LoadingIndicator from "../../LoadingIndicator"
import ModalPostDetails from "../../ModalPostDetails"
import Pill from "../../Pill"
import { SUGGESTED_POSTS_QTY } from "../../../util/constant"
import { fetchSocialProfile } from "../../../state/socialProfileSlice"
import { fetchTopPosts } from "../../../state/listSocialProfileSlice"
import { getSocialAvatarProps } from "../../../util/componentHelper"
import { useSelector, useDispatch } from "../../../state/hooks"
import {
  postDateFormatter,
  prettyPrintDecimal,
  shorthandNumber,
} from "../../../util/miscHelper"

import "./top-posts-subtab.sass"

interface Props {
  topPostsOverviewOn: boolean
}
type PostOrEmptyMessage = GraphQL.SuggestionsPostFragment | { emptyMssg: string, indexId: number }

export default function TopPostsSubTab({ topPostsOverviewOn }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSocialSubTabs" })
  const { listID, socialProfileID } = useParams<{ listID: string, socialProfileID: string }>()
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.socialProfile.profile)
  const fetchedTopPosts = useSelector(({ listSocialProfile }) => listSocialProfile.fetchedTopPosts)
  // const [ fetchedTopPosts, setFetchedTopPosts ] = useState<GraphQL.SuggestionsPostFragment[]>([])
  const [ selectedPost, selectPost ] = useState<GraphQL.SuggestionsPostFragment | undefined>()
  const [ showOverlay, setShowOverlay ] = useState(false)
  const showOverlayRef = useRef(showOverlay)
  showOverlayRef.current = showOverlay
  const [ postInRemovalProcess, setPostInRemovalProcess ] = useState("")
  const postsListRef = useRef<HTMLUListElement>(null)

  const searchTopPosts = async () => {
    if (listID && socialProfileID) {
      await dispatch(fetchTopPosts({
        listId: listID,
        socialAccountId: socialProfileID,
      }))
    }
  }

  const PostsStrip = useMemo(() => Array.from({ length: SUGGESTED_POSTS_QTY }, (_, index): PostOrEmptyMessage => {
    const postInfo = fetchedTopPosts[index]
    return postInfo ?? { emptyMssg: "No Post", indexId: index }
  }), [ fetchedTopPosts ])

  const handleScroll = (e: Event) => {
    const target = e.target as HTMLDivElement
    // Determine if at the end of the scroll area
    const atEnd = target.scrollWidth - Math.ceil(target.scrollLeft + target.clientWidth) <= 1
    // Only update state if different to minimize re-renders
    if (showOverlayRef.current && atEnd) {
      setShowOverlay(false)
    }
    if (!showOverlayRef.current && !atEnd) {
      setShowOverlay(true)
    }
  }

  const handleClickRemovePost = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    postId: string,
  ) => {
    e.stopPropagation()
    setPostInRemovalProcess(postId)
  }

  const removeTopPost = async (
    postId: string,
  ) => {
    if (socialProfileID && listID) {
      const input: GraphQL.SuggestionListSocialAccountCippusInput = {
        kind: GraphQL.SuggestionListSocialAccountCippusType.Featured,
        postId,
        socialAccountId: socialProfileID,
        suggestionListId: listID,
      }

      const removedPostId = await API.removeListTopPost({ input })
      if (API.isSuccess(removedPostId)) {
        searchTopPosts() // Added to refresh posts after deletion
      }
    }
  }

  useEffect(() => {
    if (postInRemovalProcess.length) {
      removeTopPost(postInRemovalProcess)
    }
  }, [ postInRemovalProcess ])

  useEffect(() => {
    // after the post has been removed and the
    // updated posts data state is set remove loading component
    if (postInRemovalProcess.length) {
      setPostInRemovalProcess("")
    }
  }, [ fetchedTopPosts ])

  useEffect(() => {
    searchTopPosts()
    if (socialProfileID) {
      dispatch(fetchSocialProfile(socialProfileID))
    }
  }, [])

  useEffect(() => {
    const checkOverflow = () => {
      if (postsListRef.current) {
        const isOverflowing = postsListRef.current.scrollWidth > postsListRef.current.clientWidth
        // setHasOverflow(isOverflowing)
        if (isOverflowing) {
          setShowOverlay(true)
          postsListRef.current.addEventListener("scroll", handleScroll)
        }
      }
    }

    checkOverflow()
    window.addEventListener("resize", checkOverflow)

    return () => {
      window.removeEventListener("resize", checkOverflow)
      if (postsListRef.current) {
        postsListRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <div className={ `cp_list_top-post ${ showOverlay ? "has-gradient" : "" }` }>
      <ul ref={ postsListRef } className={ `cp_list_top-post-list ${ topPostsOverviewOn ? "overview-display-on" : "" }` }>
        { PostsStrip.map((post) => ("id" in post ? (
          <li
            key={ post.id }
            className={ `cp_list_top-post-item ${ post.postMedia.length === 0 ? "has-no-media" : "" }` }
            onClick={ () => {
              selectPost(post)
            } }
            tabIndex={ 0 }
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            onKeyDown={ (e) => (e.key === "Enter" || e.key === "Space") && selectPost(post) }
            aria-label="Select post"
          >
            {
              (post.id !== postInRemovalProcess) ? (
                <>
                  <button
                    type="button"
                    aria-label="remove"
                    className="cp_list_top-post-item-remove"
                    onClick={ (e) => handleClickRemovePost(e, post.id) }
                  >
                    <ClearIcon fontSize="small" className="cp_list_top-post-item-remove-icon" />
                  </button>
                  { (post.postMedia.length !== 0)
                    ? (
                      <img src={ `${ post.postMedia[0].thumbnailUrl }?auto=format` } alt="Post thumbnail" loading="lazy" />
                    )
                    : (
                      <span>No media</span>
                    )
                }
                </>
              ) : (
                <LoadingIndicator />
              )
            }
            <div className="cp_list_top-post-item-info pif">
              <div className="cp_pif-date">
                <time>{ postDateFormatter(post.postedTime) }</time>
                { post.isReel && (<Chip className="cp_pif-reel-chip" label={ translate("REEL") } />) }
              </div>
              <p className="cp_pif-content">{ post.postContent }</p>
              <div className="cp_pif-social-data">
                <span className="cp_pif-rate">
                  { post.engagementRate && (<Pill label={ `${ prettyPrintDecimal(post.engagementRate, 2) }%` } />) }
                </span>
                <span className="cp_pif-comments">
                  { post.postComments
                          && (
                            <>
                              <ChatBubbleOutlineRounded />
                              { `${ shorthandNumber(post.postComments) }` }
                            </>
                          )
                        }
                </span>
                <span className="cp_pif-likes">
                  { post.postComments
                          && (
                            <>
                              <FavoriteBorder />
                              { `${ shorthandNumber(post.postLikes) }` }
                            </>
                          )
                        }
                </span>
              </div>
            </div>
          </li>
        ) : (
          <li key={ post.indexId } className="cp_list_top-post-removed">
            <span>{ post.emptyMssg }</span>
          </li>
        ))) }
      </ul>
      { API.isSuccess(profile) && (
      <ModalPostDetails
        closeAction={ () => selectPost(undefined) }
        engagementRate={ `${ prettyPrintDecimal(selectedPost?.engagementRate, 2) }%` || "" }
        isReel={ Boolean(selectedPost?.isReel) }
        open={ Boolean(selectedPost) }
        isStory={ Boolean(selectedPost?.isStory) }
        media={ selectedPost?.postMedia || [] }
        permalink={ selectedPost?.permalink || "" }
        postComments={ selectedPost?.postComments || 0 }
        postContent={ selectedPost?.postContent || "" }
        postDate={ selectedPost?.created || 0 }
        postExits={ selectedPost?.postExits || 0 }
        postImpressions={ selectedPost?.postImpressions || 0 }
        postLikes={ selectedPost?.postLikes || 0 }
        postPlays={ selectedPost?.postPlays || 0 }
        postReach={ selectedPost?.postReach || 0 }
        postReachEstimated={ selectedPost?.estimatedPostReach?.value || 0 }
        postSaves={ selectedPost?.postSaves || 0 }
        postShares={ selectedPost?.postShares || 0 }
        postScreenshots={ selectedPost?.screenshots || 0 }
        postSwipeUps={ selectedPost?.swipeUps || 0 }
        postHighlighted={ Boolean(selectedPost?.highlighted) }
        postSaved={ Boolean(selectedPost?.saved) }
        postTapsBackward={ selectedPost?.postTapsBackward || 0 }
        postTapsForward={ selectedPost?.postTapsForward || 0 }
        postViews={ selectedPost?.postViews || 0 }
        socialAvatarProps={ getSocialAvatarProps(profile.payload.socialAccount as GraphQL.SocialAccount) }
      />
      ) }
    </div>
  )
}

import React from "react"
import "./style.sass"
import { Network } from "../../graphql"

interface Props {
  network: Network;
  isActive: boolean
  additionalClasses?: string;
}

export default function NetworkIcon(props: Props) {
  const {
    network,
    isActive,
    additionalClasses,
  } = props

  const classes: string = [
    "cp-network-icon",
    `cp-network-icon_${ network.toLowerCase() }`,
    `cp-network-icon_${ isActive ? "active" : "inactive" }`,
    additionalClasses,
  ].filter(Boolean).join(" ")

  return (
    <span
      className={ classes }
      aria-label={ `${ network.toLowerCase() } icon` }
      role="img"
    >
      <i className="cp-network-icon-svg" />
    </span>
  )
}

import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs, { Dayjs } from "dayjs"

import DatePicker from "../../../DatePicker"
import Input from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"
import Modal from "../../../Modal"
// eslint-disable-next-line max-len
import { closeSocialTrackerDownloadCSVModal, downloadSocialTrackerCSV } from "../../../../state/socialTrackerDownloadCSVModalSlice"
import { pushToast } from "../../../../state/toastSlice"
import { useDispatch, useSelector } from "../../../../state/hooks"

import "./social-tracker-download-csv-modal.sass"

export default function SocialTrackerDownloadCSVModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.SocialTrackerDownloadCSVModal" })
  const dispatch = useDispatch()
  const { open, socialTracker } = useSelector(({ socialTrackerDownloadCSVModal }) => socialTrackerDownloadCSVModal)

  const [ fileName, setFileName ] = useState("")
  const [ postStart, setPostStart ] = useState<Dayjs | string | null>(null)
  const [ postEnd, setPostEnd ] = useState<Dayjs | string | null>(null)
  const [ isLoading, setIsLoading ] = useState(false)

  const downloadDisabled = postStart === null || postEnd === null || fileName.length === 0

  useEffect(() => {
    if (open && socialTracker) {
      setFileName(socialTracker.name)
      setPostStart(null)
      setPostEnd(null)
    }
  }, [ open, socialTracker ])

  const onSubmit = () => {
    if (postStart && postEnd && socialTracker) {
      setIsLoading(true)
      dispatch(downloadSocialTrackerCSV({
        variables: {
          fileName,
          postEnd: typeof postEnd === "string" ? dayjs(postEnd).unix() : postEnd.unix(),
          postStart: typeof postStart === "string" ? dayjs(postStart).unix() : postStart.unix(),
          xref: socialTracker.xref,
        },
        onSuccess: () => {
          setIsLoading(false)
          dispatch(closeSocialTrackerDownloadCSVModal())
        },
        onError: () => {
          dispatch(pushToast({
            type: "error",
            message: translate("Error Downloading Tracker. Please Verify Input"),
          }))
          setIsLoading(false)
        },

      }))
    }
  }

  return (
    <Modal
      className="cp_component_socila-tracker-download-csv-modal"
      open={ open }
      closeAction={ () => dispatch(closeSocialTrackerDownloadCSVModal()) }
      title={ translate("Download Tracker CSV") }
      subtitle={ translate("Download a CSV of posts matching tracker criteria (1000 rows max)") }
      primaryLabel={ isLoading ? <LoadingIndicator /> : translate("Download") }
      hasSecondaryButton={ false }
      maxWidth="lg"
      disabled={ downloadDisabled }
      primaryAction={ onSubmit }
    >
      <div className="input-container">
        <Input
          label={ translate("File Name") }
          placeholder={ translate("Social Tracker Name/Description") }
          value={ fileName }
          onChange={ (e) => setFileName(e.target.value) }
        />
        <div className="date-pickers-container">
          <span>
            <p className="label_small-caps-bold">{ translate("Post Start") }</p>
            <DatePicker
              className="date-picker"
              value={ dayjs(postStart) }
              onDateChange={ (d) => setPostStart(d) }
              onAccept={ (d) => setPostStart(d) }
            />
          </span>
          <span>
            <p className="label_small-caps-bold">{ translate("Post End") }</p>
            <DatePicker
              className="date-picker"
              value={ dayjs(postEnd) }
              onDateChange={ (d) => setPostEnd(d) }
              onAccept={ (d) => setPostEnd(d) }
            />
          </span>
        </div>
      </div>
    </Modal>
  )
}

import React, { JSX } from "react"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import WarningIcon from "@mui/icons-material/Warning"

import { Toast } from "../../util/types"

import "./style.sass"
import { InternalToast } from "../../state/toastSlice"

type Props = {
  toasts: InternalToast[],
}

function getToastClassName(toast: Toast): string {
  switch (toast.type) {
    case "warning":
      return "cp_component_toaster_warning"

    case "error":
      return "cp_component_toaster_error"

    default:
      return "cp_component_toaster_success"
  }
}

function getToastIcon(toast: Toast): JSX.Element {
  switch (toast.type) {
    case "warning":
      return <WarningIcon />

    case "error":
      return <ErrorIcon />

    default:
      return <CheckCircleIcon />
  }
}

function renderToast(toast: InternalToast): JSX.Element {
  return (
    <aside key={ toast.message } className={ getToastClassName(toast) }>
      { getToastIcon(toast) }
      <article>
        <p>{ toast.message }</p>
        { toast.additionalInfo == null
          ? null
          : (<p className="body_small">{ toast.additionalInfo }</p>)
        }
      </article>
    </aside>
  )
}

export default function Template(props: Props): JSX.Element {
  return (
    <div className="cp_component_toaster">
      { props.toasts.map(renderToast) }
    </div>
  )
}

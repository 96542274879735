import React, { useState } from "react"
import dayjs from "dayjs"
import { Close } from "@mui/icons-material"
import { useCookies } from "react-cookie"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import Button from "../../component/Button"
import EntityInfoRow from "../../component/EntityInfoRow"
import FeedbackCard from "./FeedbackCard"
import IconButton from "../../component/IconButton"
import Input from "../../component/Input"
import LoadingIndicator from "../../component/LoadingIndicator"
import SquareChip from "../../component/SquareChip"
import { SuggestionAccessCodeRestriction } from "../../graphql"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  createFeedback,
  fetchPublicListSocialProfile,
  setInfoRequiredModalOpen,
  setInfoRequiredSubmitCallback,
  setLeftPanelStatus,
  setRightPanelStatus,
  userCanEdit,
} from "../../state/publicListSlice"

import "./feedback-panel.sass"

type Props = {
  accessCodeRestriction: SuggestionAccessCodeRestriction | undefined | null
  userIsValid: boolean
}

export default function FeedbackPanel({ accessCodeRestriction, userIsValid }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.FeedbackPanel" })
  const dispatch = useDispatch()

  const { listCode } = useParams()

  const {
    loadedListSocialAccount, nameCookieKey, companyCookieKey,
  } = useSelector(({ publicList }) => publicList)

  const [ cookies ] = useCookies([ nameCookieKey, companyCookieKey ])
  const [ feedbackInput, setFeedbackInput ] = useState("")

  if (loadedListSocialAccount === "init" || loadedListSocialAccount === "loading") {
    return (
      <div className="cp_component_feedback-panel">
        <LoadingIndicator flexWrapperEnabled={ true } />
      </div>
    )
  }

  if (API.isError(loadedListSocialAccount)) {
    return (
      <div className="cp_component_feedback-panel">
        { translate("There was an error. Please try again!") }
      </div>
    )
  }

  const onClose = () => {
    dispatch(setRightPanelStatus("closed"))
    dispatch(setLeftPanelStatus("default"))
  }

  const handleSubmit = async () => {
    if (API.isSuccess(loadedListSocialAccount) && listCode) {
      const {
        id,
        socialAccount,
      } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
      if (cookies[nameCookieKey] && cookies[companyCookieKey] && userCanEdit(accessCodeRestriction, userIsValid)) {
        dispatch(
          createFeedback(
            listCode,
            id,
            feedbackInput,
            () => dispatch(fetchPublicListSocialProfile({ listId: listCode, socialAccountId: socialAccount.id })),
          ),
        )
        setFeedbackInput("")
      } else {
        dispatch(setInfoRequiredSubmitCallback(() => {
          dispatch(
            createFeedback(
              listCode,
              id,
              feedbackInput,
              () => dispatch(fetchPublicListSocialProfile({ listId: listCode, socialAccountId: socialAccount.id })),
            ),
          )
          setFeedbackInput("")
        }))
        dispatch(setInfoRequiredModalOpen(true))
      }
    }
  }

  const {
    socialAccount: {
      profilePictureUrl,
      network,
      userName,
    },
    comments,
  } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId

  return (
    <div className="cp_component_feedback-panel">
      <div className="cp_component_feedback-panel-username-container">
        <EntityInfoRow avatarSize="lg" avatarSrc={ profilePictureUrl } network={ network } name={ userName } />
        <IconButton onClick={ onClose } variant="outlined">
          <Close />
        </IconButton>
      </div>
      <div className="cp_component_feedback-panel-header">
        <h5>{ translate("Feedback") }</h5>
        <SquareChip label={ comments.length } />
      </div>
      <div className="cp_component_feedback-panel-feedback">
        { comments.map((comment) => {
          let author: string = ""
          let company: string = ""
          // eslint-disable-next-line no-underscore-dangle
          if (comment.author.__typename === "AnonymousUser") {
            author = comment.author.name
            company = comment.author.company
          // eslint-disable-next-line no-underscore-dangle
          } else if (comment.author.__typename === "User") {
            author = comment.author.contact.name
            company = comment.author.customer.company?.name || ""
          }
          return (
            <FeedbackCard
              key={ comment.id }
              body={ comment.content }
              time={ dayjs(comment.created, "X").format(Constant.LONGFORM_DATE_TIME) }
              author={ author }
              teamName={ company }
            />
          )
        }) }
      </div>
      <div className="cp_component_feedback-panel-footer">
        <Input
          value={ feedbackInput }
          onChange={ (e) => setFeedbackInput(e.target.value) }
          multiline={ true }
          maxRows={ 2 }
          placeholder={ translate("Leave Feedback...") }
        />
        <Button onClick={ handleSubmit } label={ translate("SUBMIT") } />
      </div>
    </div>
  )
}

import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  LinearProgress,
  Typography,
} from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-pro"
import "./communicationsSlidingPanel.sass"

import "./unassigned-content.sass"
import DataGrid from "../../../DataGrid"
import EmptyElement from "../../../EmptyElement"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { getMoreUnassignedThreads, getUnassignedThreads } from "../../../../state/slidingPanelSlice/communications"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import ThreadPreview from "./ThreadPreview"
import ContextMenu from "./UnassignedContentContextMenu"

export default function UnassignedContent(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.CommunicationsTab" })
  const dispatch = useDispatch()

  const {
    unassignedThreadStatus, unassignedThreadContent,
  } = useSelector(({ slidingPanels }) => slidingPanels)

  const [ page, setPage ] = useState(1)
  const [ isLoading, setIsLoading ] = useState(false)

  const loadQuery = async () => {
    setIsLoading(true)
    await dispatch(getUnassignedThreads({
      startsWith: "",
      limit: 50,
      page: 1,
    }))
    setPage(1)
    setIsLoading(false)
  }

  const loadMoreQuery = async () => {
    const newPage = page + 1
    setIsLoading(true)
    await dispatch(getMoreUnassignedThreads({ page: newPage, limit: 50 }))
    setPage(newPage)
    setIsLoading(false)
  }

  useEffect(() => {
    loadQuery()
  }, [ ])

  const COLUMNS: GridColDef[] = [
    {
      field: "from",
      headerName: translate("From"),
      sortable: true,
      renderCell: (params) => (
        <Typography
          id="cp_component-unassigned-content_from-row"
          className="from-label-cell"
        >
          { params.row.from }
        </Typography>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "content",
      headerName: translate("Content"),
      sortable: false,
      renderCell: (params) => (
        <ThreadPreview
          id="cp_component-unassigned-content_content-row"
          created={ params.row.created }
          subject={ params.row.subject }
          content={ params.row.content }
        />
      ),
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ContextMenu id="cp_component-unassigned-content_context-menu-row" />
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ]

  const renderContent = () => {
    if (unassignedThreadStatus === "init" || unassignedThreadStatus === "loading") {
      return (
        <LoadingIndicatorCard />
      )
    }
    if (unassignedThreadStatus.status === "error") {
      return <Typography>Error!</Typography>
    }

    return (
      <DataGrid
        className="cp_component_unassigned-table"
        rowHeight={ 110 }
        columnHeaderHeight={ 40 }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        rows={ unassignedThreadContent.map((row) => ({
          id: row.id,
          from: row.firstMessage.from.address,
          created: row.created,
          subject: row.subject,
          content: row.firstMessage.text,
        })) }
        onRowsScrollEnd={ () => {
          if (unassignedThreadStatus.payload.searchConversationThreads.totalCount > unassignedThreadContent.length) {
            loadMoreQuery()
          }
        } }
        loading={ isLoading }
        scrollEndThreshold={ 200 }
        slots={ {
          loadingOverlay: LinearProgress,
          noRowsOverlay: EmptyElement,
          noResultsOverlay: EmptyElement,
        } }
        rowSelection={ false }
      />
    )
  }

  return (
    <>
      <Container id="cp_component_unassigned-content-container" className="cp_component_navigation-bar-templates-container">
        { renderContent() }
      </Container>
    </>
  )
}

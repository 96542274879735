import React from "react"
import InfluentialLogo from "../InfluentialLogo"
import "./style.sass"
import Divider from "../Divider"

type Props = {
    className?: string
    header?: string
}
export default function LogoHeader({ className, header }: Props) {
  const classes = [ "cp_component_logo-header" ]
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")
  return (
    <div className={ newClasses }>
      <InfluentialLogo hasTag={ true } />
      { header && (
        <>
          <Divider orientation="vertical" />
          <h2>{ header }</h2>
        </>
      )
      }
    </div>
  )
}

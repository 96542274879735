import * as React from "react"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers"
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker"
import { Dayjs } from "dayjs"

import "./style.sass"

interface Props extends DatePickerProps<Dayjs> {
  onDateChange?: (event: string) => void
}

export default function DatePicker(props: Props) {
  return (
    <LocalizationProvider dateAdapter={ AdapterDayjs }>
      <DemoContainer components={ [ "MuiDatePicker" ] }>
        <MuiDatePicker
          className="cp_datePicker"
          { ...props }
          slotProps={ {
            textField: {
              onKeyUp: (event: React.KeyboardEvent) => {
                if (event.key === "Enter" && props.onDateChange) {
                  const { target } = event
                  if (target instanceof HTMLInputElement) {
                    props.onDateChange(target.value)
                  }
                }
              },
              onBlur: (event) => {
                if (props.onDateChange) {
                  props.onDateChange(event.target.value)
                }
              },
            },
          } }
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}

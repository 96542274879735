import React from "react"
import { Navigate } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"

import LoadingPage from "../Loading"

const QUERY = gql`
  query AppLandingPage {
    currentUser {
      id
      customer {
        id
        vanity
      }
    }
  }
`

const useCurrentUserState = () => {
  const user$ = useQuery<{ currentUser: { id: string; customer: { id: string; vanity: string } } }>(QUERY)

  const customerVanity = user$.data?.currentUser?.customer.vanity

  return {
    loading: user$.loading,
    isLoggedIn: !!user$.data?.currentUser,
    customerVanity,
  }
}

export default function AppLandingPage() {
  const currentUserState = useCurrentUserState()

  if (currentUserState.loading) {
    return <LoadingPage />
  }

  if (currentUserState.isLoggedIn) {
    return <Navigate to={ `/${ currentUserState.customerVanity }` } replace={ true } />
  }

  return <Navigate to="/find-team" replace={ true } />
}

import React, { useMemo } from "react"
import dayjs from "dayjs"
import { EditOutlined, Launch } from "@mui/icons-material"
import { GridColDef } from "@mui/x-data-grid"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../../util/apiClient"
import * as Constant from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import ContextMenuButton from "../../DataGrid/RenderCellComponents/ContextMenuButton"
import DataGrid from "../../DataGrid"
import EmptyElement from "../../EmptyElement"
import EmptySplash from "../../EmptySplash"
import EntityInfoRow from "../../EntityInfoRow"
import MenuItem from "../../MenuItem"
import Timestamp from "../../DataGrid/RenderCellComponents/Timestamp"
import { ALL_NETWORKS } from "../../../util/types"
import { getCampaignsForProfile } from "../../../state/socialProfileSlice"
import { openEditCampaignModal } from "../../../state/campaignModalSlice"
import { useDispatch, useSelector } from "../../../state/hooks"

import "./campaigns-table.sass"
import "./style.sass"

type Props = {
    campaigns: Array<GraphQL.CampaignPageFragment>
}

export default function CampaignsTable({ campaigns }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabCampaignsTable" })
  const { vanity } = useParams()
  const dispatch = useDispatch()
  const { profile } = useSelector(({ socialProfile }) => socialProfile)

  const updateCallback = () => {
    if (API.isSuccess(profile)) {
      const accountIds = profile.payload.socialAccount.personality?.socialAccounts.map((a) => a.id)
      || [ profile.payload.socialAccount.id ]
      dispatch(getCampaignsForProfile(accountIds, ALL_NETWORKS))
    }
  }

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 3,
      headerName: translate("Name"),
      renderCell: (params) => (
        <EntityInfoRow
          avatarSize="md"
          avatarSrc={ params.row.thumbnail?.url?.address || "" }
          name={ params.row.name }
        />
      ),
    },
    {
      field: "deliverables",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerName: translate("Deliverables"),
      renderCell: (params) => (
        <p>{ params.row.deliverables.length }</p>
      ),
    },
    {
      field: "numberOfAccounts",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerName: translate("No. of Accounts"),
      renderCell: (params) => (
        <p>{ params.row.numberOfCampaignNetworkAccounts }</p>
      ),
    },
    {
      field: "created",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerAlign: "left",
      align: "left",
      headerName: translate("Created"),
      renderCell: (params) => (
        <Timestamp
          time={ dayjs(params.row.created * 1000)
            .format(Constant.LONGFORM_DATE).toUpperCase() }
        />
      ),
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ContextMenuButton
          transformOrigin={ { horizontal: "right", vertical: "top" } }
          anchorOrigin={ { horizontal: "center", vertical: "center" } }
        >
          <MenuItem onClick={ () => window.open(`/${ vanity }/campaigns/${ params.id }`, "_blank") }>
            <p>{ translate("View Campaign") }</p>
            <Launch />
          </MenuItem>
          <MenuItem onClick={ () => {
            dispatch(openEditCampaignModal({
              campaign: params.row as GraphQL.CampaignPageFragment,
              updateCallback,
            }))
          }
           }
          >
            <p>{ translate("Edit Campaign") }</p>
            <EditOutlined />
          </MenuItem>
        </ContextMenuButton>
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 90,
    },
  ], [ translate ])

  return (
    <>
      <DataGrid
        className="cp_profile-tab_campaigns-table"
        getRowId={ (row) => row.id }
        disableRowSelectionOnClick={ true }
        rowHeight={ 90 }
        columnHeaderHeight={ 40 }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        pinnedColumns={ {
          right: [ "ellipsisMenu" ],
        } }
        rows={ campaigns }
        slots={ {
          noResultsOverlay: EmptyElement,
          noRowsOverlay: EmptyElement,
        } }
      />
      { campaigns.length === 0 && (
        <EmptySplash bodyText={ translate("No campaigns found") } />
      ) }
    </>
  )
}

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import CancelIcon from "@mui/icons-material/Cancel"

import RelevantPostsByImageTag from "../Content/RelevantPostsByImageTag"
import RelevantPostsByKeywords from "../Content/RelevantPostsByKeywords"
import Search from "../Content/Search"
import Switch from "../../Switch"
import Tabs, { TabLabelProps } from "../../Tabs"
import TopPostsSubTab from "../Content/TopPostsSubTab"
import { CONTENT_DISPLAY_TOGGLES } from "../../../util/constant"
import { ToggleFragment } from "../../../graphql"

import "./list-suggestions-social.sass"
import { SuggestionListContentTabPathsEnum } from "../../../util/types"

interface SuggestionsTabLabelProps extends TabLabelProps {
  path: SuggestionListContentTabPathsEnum.TOP_POSTS
  | SuggestionListContentTabPathsEnum.KEYWORDS
  | SuggestionListContentTabPathsEnum.IMAGE_TAGS
}

type Props = {
  toggles: ToggleFragment[]
}

export default function ListSuggestionSocialContent({ toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSuggestionSocialContent" })
  const navigate = useNavigate()
  const {
    vanity,
    contentTabPath,
    listID,
    socialProfileID,
    tabPath,
  } = useParams()
  const [ topPostsOverviewOn, setTopPostsOverviewOn ] = React.useState(false)

  const [ displayTopPosts, displayKeywords ] = React.useMemo(() => [
    toggles.some(({ name }) => name === CONTENT_DISPLAY_TOGGLES.TOP_POSTS),
    toggles.some(({ name }) => name === CONTENT_DISPLAY_TOGGLES.KEYWORDS),
  ], [ toggles ])

  const tabs: SuggestionsTabLabelProps[] = React.useMemo(() => {
    const t = []
    if (displayTopPosts) t.push({ label: translate("Top Posts"), path: SuggestionListContentTabPathsEnum.TOP_POSTS })
    if (displayKeywords) {
      t.push({ label: translate("Relevant Keywords"), path: SuggestionListContentTabPathsEnum.KEYWORDS })
      t.push({ label: translate("Relevant Image Tags"), path: SuggestionListContentTabPathsEnum.IMAGE_TAGS })
    }
    return t
  }, [ translate ])

  const setSubTab = (tabIndex: number) => {
    if (tabs.length > 0) {
      const newTabPath = tabs[tabIndex].path
      navigate(`/${ vanity }/lists/${ listID }/social-account/${ socialProfileID }/${ tabPath }/${ newTabPath }`)
    }
  }

  useEffect(() => {
    setSubTab(0)
  }, [ tabs ])

  const tabContent = () => {
    switch (contentTabPath) {
      case SuggestionListContentTabPathsEnum.KEYWORDS:
        if (!displayKeywords) return null
        return (
          <div className="cp_list_suggestions-social-content-key-words">
            <RelevantPostsByKeywords topPostsOverviewOn={ topPostsOverviewOn } />
          </div>

        )
      case SuggestionListContentTabPathsEnum.IMAGE_TAGS:
        if (!displayKeywords) return null
        return (
          <div className="cp_list_suggestions-social-content-image-tags">
            <RelevantPostsByImageTag topPostsOverviewOn={ topPostsOverviewOn } />
          </div>
        )
      case SuggestionListContentTabPathsEnum.TOP_POSTS:
        if (!displayTopPosts) return null
        return <TopPostsSubTab topPostsOverviewOn={ topPostsOverviewOn } />
      case undefined:
      default:
        return (
          <div className="cp_list_suggestions-social-content-top-posts">
            <TopPostsSubTab topPostsOverviewOn={ topPostsOverviewOn } />
          </div>
        )
    }
  }

  const tabIndex = React.useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === contentTabPath)
    return foundTab ? tabs.indexOf(foundTab) : 0
  }, [ contentTabPath ])

  return (
    <>
      <div className="cp_profile_component-list-tabs-container sub-tabs">
        <Tabs
          handleChange={ setSubTab }
          tabs={ tabs }
          defaultValue={ tabIndex }
          controlledValue={ tabIndex }
          controls={ (
            <div className="cp_profile_component-list-tabs-actions">
              <p>{ translate("Preview") }</p>
              <Switch
                handleChange={ () => setTopPostsOverviewOn(!topPostsOverviewOn) }
                isChecked={ topPostsOverviewOn }
                hasIcon={ true }
                icon={ <CancelIcon /> }
                checkedIcon={ <CancelIcon /> }
              />
            </div>
          ) }
        />
        <div className="cp_profile_component-list-tabs-content">
          { tabContent() }
        </div>
      </div>
      <Search
        mountContext="listSocialAccount"
        contentContextMenu={ {
          showAddPostButton: true,
          showViewMatchingPostsButton: false,
          showViewProfileButton: false,
        } }
      />
    </>
  )
}

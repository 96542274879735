import React from "react"
import { Container } from "@mui/material"
import { ChevronRight } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import Modal from "../../Modal"
import NetworkAvatar from "../../NetworkAvatar"
import "./style.sass"
import * as GraphQL from "../../../graphql"
import LoadingIndicatorCard from "../../LoadingIndicatorCard"

type Props = {
    title: string
    subtitle: string
    open: boolean
    closeAction: () => void
    onRowClick: (socialAccount: GraphQL.SocialAccountRowFragment) => void
    socialProfile: GraphQL.SocialProfileFragment | null
}

export default function ModalSelectAccount({
  title, subtitle, open, closeAction, onRowClick, socialProfile,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })

  const renderContent = () => {
    if (!socialProfile) {
      return <LoadingIndicatorCard />
    }
    const socialAccounts = socialProfile.personality?.socialAccounts || []
    // TODO: Remove filter once twitter is out of our data base
    return socialAccounts.filter((account) => account.network !== GraphQL.Network.Twitter).map((socialAccount) => (
      <Container
        key={ socialAccount.id }
        className="cp_component-profile-tab-social-account-row"
        onClick={ () => onRowClick(socialAccount) }
      >
        <NetworkAvatar size="lg" network={ socialAccount.network } src={ socialAccount.profilePictureUrl }>
          { socialAccount.userName.charAt(0) }
        </NetworkAvatar>
        <p>{ socialAccount.userName }</p>
        <ChevronRight className="cp_component-profile-tab-chevron-right" />
      </Container>
    ))
  }

  return (
    <Modal
      className="cp_component_modal-select-account"
      title={ title }
      subtitle={ subtitle }
      primaryAction={ () => {} }
      secondaryAction={ closeAction }
      closeAction={ closeAction }
      secondaryLabel={ translate("Cancel") }
      hasPrimaryButton={ false }
      hasSecondaryButton={ true }
      hasFooter={ true }
      open={ open }
    >
      <Container className="cp_component_profile-tab-account-container">
        { renderContent() }
      </Container>
    </Modal>
  )
}

import { Visibility, VisibilityOffOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import React from "react"
import "./visibility-icon.sass"

type Props = {
    visible: boolean
    onClick?: () => void
}

export default function VisibilityIcon({ visible, onClick }: Props) {
  const classes = [ "cp_component-data-grid-visible" ]
  if (visible) {
    classes.push("cp_component-data-grid-visible_active")
  } else {
    classes.push("cp_component-data-grid-visible_inactive")
  }
  const newClasses = classes.join(" ")
  return (
    <IconButton
      size="small"
      className={ newClasses }
      onClick={ onClick }
    >
      { visible
        ? <Visibility />
        : <VisibilityOffOutlined />
              }
    </IconButton>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import type { Dispatch, PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import { RootState } from "../store"

// Deliverable Content Modal Slice Interface and Initial State
export interface CampaignDeliverableContentModalState {
  campaign?: GraphQL.CampaignPageFragment
  captionFeedback?: GraphQL.DeliverableCaptionFragment
  captionToApprove?: GraphQL.DeliverableCaptionFragment
  confirmFinalize: boolean
  deliverable?: GraphQL.DeliverableFragment
  edited: boolean
  edittingUrl: boolean
  history: Status<GraphQL.DeliverableHistoryQuery>
  mediaFeedback?: GraphQL.DeliverableMediaFragment
  mediaToApprove?: GraphQL.DeliverableMediaFragment
  onClose?: (edited: boolean) => Promise<void>
  /**
   * This property is used to hide the modal when the user navigates away from the modal to a nested modal.
   * This will preserve the state of the modal when the user navigates back to the parent modal.
   */
  hidden: boolean
}

const initialState: CampaignDeliverableContentModalState = {
  campaign: undefined,
  confirmFinalize: false,
  deliverable: undefined,
  edited: false,
  edittingUrl: false,
  history: "init",
  mediaFeedback: undefined,
  mediaToApprove: undefined,
  hidden: false,
}

// Deliverable Content Modal Slice
export const CampaignDeliverableContentModalSlice = createSlice({
  name: "CampaignDeliverableContentModalSlice",
  initialState,
  reducers: {
    openFinalizeConfirmModal: (
      state,
    ) => ({
      ...state,
      confirmFinalize: true,
    }),
    closeFinalizeConfirmModal: (
      state,
    ) => ({
      ...state,
      confirmFinalize: false,
    }),
    openDeliverableContentModal: (
      state,
      action: PayloadAction<{
        deliverable: GraphQL.DeliverableFragment,
        campaign: GraphQL.CampaignPageFragment,
        onClose?: (edited: boolean) => Promise<void>
      }>,
    ) => ({
      ...state,
      deliverable: action.payload.deliverable,
      campaign: action.payload.campaign,
      onClose: action.payload.onClose,
      hidden: false,
    }),
    setDeliverable: (
      state,
      action: PayloadAction<{
        deliverable: GraphQL.DeliverableFragment,
      }>,
    ) => ({
      ...state,
      deliverable: action.payload.deliverable,
    }),
    closeDeliverableContentModal: (
      state,
    ) => ({
      ...state,
      captionFeedback: undefined,
      captionToApprove: undefined,
      deliverable: undefined,
      edited: false,
      history: "init",
      mediaFeedback: undefined,
      mediaToApprove: undefined,
      onClose: undefined,
      hidden: false,
    }),
    hideDeliverableContentModal: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      hidden: action.payload,
    }),
    setMediaToApprove: (
      state,
      action: PayloadAction<{
        media?: GraphQL.DeliverableMediaFragment,
      }>,
    ) => ({
      ...state,
      mediaToApprove: action.payload.media,
    }),
    setCaptionToApprove: (
      state,
      action: PayloadAction<{
        caption?: GraphQL.DeliverableCaptionFragment,
      }>,
    ) => ({
      ...state,
      captionToApprove: action.payload.caption,
    }),
    setMediaFeedback: (
      state,
      action: PayloadAction<{
        media?: GraphQL.DeliverableMediaFragment,
      }>,
    ) => ({
      ...state,
      mediaFeedback: action.payload.media,
    }),
    setCaptionFeedback: (
      state,
      action: PayloadAction<{
        caption?: GraphQL.DeliverableCaptionFragment,
      }>,
    ) => ({
      ...state,
      captionFeedback: action.payload.caption,
    }),
    setDeliverableHistory: (
      state,
      action: PayloadAction<Status<GraphQL.DeliverableHistoryQuery>>,
    ) => ({
      ...state,
      history: action.payload,
    }),
    setEdittingUrl: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      edittingUrl: action.payload,
    }),
    setEdited: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      edited: action.payload,
    }),
  },
})

export const {
  closeDeliverableContentModal,
  closeFinalizeConfirmModal,
  openDeliverableContentModal,
  openFinalizeConfirmModal,
  setCaptionFeedback,
  setCaptionToApprove,
  setDeliverable,
  setDeliverableHistory,
  setEdited,
  setEdittingUrl,
  setMediaFeedback,
  setMediaToApprove,
  hideDeliverableContentModal,
} = CampaignDeliverableContentModalSlice.actions
export default CampaignDeliverableContentModalSlice.reducer

export const approveDeliverableItem = (
  params: GraphQL.AcceptDeliverableItemMutationVariables,
) => async () => {
  const result = await API.approveDeliverableItem(params)
  return result
}

export const addFeedback = (
  params: GraphQL.CreateDeliverableItemFeedbackMutationVariables,
) => async () => {
  const result = await API.createDeliverableItemFeedback(params)
  return result
}

export const getHistory = (
  params: GraphQL.DeliverableHistoryQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDeliverableHistory("loading"))
  const result = await API.getDeliverableHistory(params)
  dispatch(setDeliverableHistory(result))
}

export const updateDeliverableUrl = (
  params: {
    variables: GraphQL.UpdateDeliverableLiveStatusUrlMutationVariables,
    onSuccess: () => any
    onError: () => any
  },
) => async () => {
  const result = await API.updateDeliverableUrl(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}

export const refreshDeliverable = (
  params: {
    deliverableId: string
  },
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    campaignPage: {
      campaign,
    },
  } = getState()
  if (API.isSuccess(campaign)) {
    const {
      deliverables,
    } = campaign.payload.campaign
    const deliverable = deliverables.find((d) => d.id === params.deliverableId)
    if (deliverable) {
      dispatch(setDeliverable({ deliverable }))
    }
  }
}
export const finalizeDeliverable = (
  params: GraphQL.FinalizeDeliverableMutationVariables,
) => async () => {
  const result = await API.finalizeDeliverable(params)
  return result
}

export const updateliverableLiveStatus = (
  params: GraphQL.UpdateDeliverableLiveStatusMutationVariables,
) => async () => {
  const result = await API.updateDeliverableLiveStatus(params)
  return result
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import * as Type from "../../util/types"
import { Status } from "../../util/types"

// Add Accounts to Lists Modal Slice Interface and Initial State
interface ModalAddToLists {
  addAccountsLoading: boolean,
  lists: Status<GraphQL.SearchListsQuery>
  selectedAccountIds: string[],
  selectedListIds: string[],
  selectedNetwork: GraphQL.Network | null,
}

const initialState: ModalAddToLists = {
  addAccountsLoading: false,
  lists: "init",
  selectedAccountIds: [],
  selectedListIds: [],
  selectedNetwork: null,
}

// Add Accounts to Lists Modal Slice
export const ModalAddToListsSlice = createSlice({
  name: "modalAddToLists",
  initialState,
  reducers: {
    setAddAccountsLoading: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      addAccountsLoading: action.payload,
    }),
    setLists: (
      state,
      action: PayloadAction<Status<GraphQL.SearchListsQuery>>,
    ) => ({
      ...state,
      lists: action.payload,
    }),
    setSelectedAccountIds: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedAccountIds: action.payload,
    }),
    setSelectedListIds: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedListIds: action.payload,
    }),
    setSelectedNetwork: (
      state,
      action: PayloadAction<GraphQL.Network | null>,
    ) => ({
      ...state,
      selectedNetwork: action.payload,
    }),
  },
})

export const {
  setAddAccountsLoading,
  setLists,
  setSelectedAccountIds,
  setSelectedListIds,
  setSelectedNetwork,
} = ModalAddToListsSlice.actions
export default ModalAddToListsSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchLists = (
  network: GraphQL.Network,
  startsWith?: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setLists("loading"))

  const listResults = await API.fetchLists({
    startsWith,
    networkFilter: network,
    limit: 20,
  })

  dispatch(setLists(listResults))
}

type ListAddResult = Type.Err
  | Type.Success<GraphQL.AddSocialAccountToSuggestionListMutation | null>

export const addAccountsToLists = async (
  hoarderAccountIds: string[],
  listIds: string[],
): Promise<ListAddResult[]> => Promise.all(
  listIds.map((suggestionListId) => API.addSocialAccountToSuggestionList({
    suggestionListId,
    hoarderAccountIds,
  })),
)

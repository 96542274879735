import React from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import * as API from "../../../util/apiClient"
import * as Constant from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import EntityInfoRow from "../../EntityInfoRow"
import LoadingIndicator from "../../LoadingIndicator"
import ModalAddTo from "../../ModalAddTo"
import { Status } from "../../../util/types"

type Props = {
    onSubmit: () => void
    campaigns: Status<GraphQL.SearchCampaignsQuery>
    campaignsForAccount: Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>,
    selectedCampaignIDs: string[]
    modalPhase: null | 1 | 2,
    getCampaigns: (id: string) => any,
    setAddToModalPhase: (phase: null | 1 | 2) => any
    toggleSelectedCampaign: (id: string) => any
    profileNetwork?: GraphQL.Network
}

export default function ModalAddToCampaigns({
  onSubmit,
  modalPhase,
  campaigns,
  campaignsForAccount,
  selectedCampaignIDs,
  getCampaigns,
  setAddToModalPhase,
  toggleSelectedCampaign,
  profileNetwork,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })

  const renderContent = () => {
    if (campaigns === "init" || campaigns === "loading" || campaignsForAccount === "init" || campaignsForAccount === "loading") {
      return (
        <div className="cp_component-modal-add-to-buffer-container">
          <LoadingIndicator />
        </div>
      )
    }
    if (API.isSuccess(campaigns) && API.isSuccess(campaignsForAccount)) {
      const idsAlreadyAdded = campaignsForAccount.payload.searchCampaignsByNetworkAccountIds.rows.map((row) => row.id)
      let filteredAccounts = campaigns.payload.searchCampaign.rows
      if (profileNetwork === GraphQL.Network.Tiktok) {
        filteredAccounts = filteredAccounts.filter((row) => row.enableTiktok)
      }
      return filteredAccounts.map((row) => {
        const alreadyAdded = idsAlreadyAdded.includes(row.id)
        const checked = alreadyAdded || selectedCampaignIDs.includes(row.id)
        const disabled = alreadyAdded || (!checked && selectedCampaignIDs.length >= 10)
        const className = [ "cp_component-modal-add-to-entity-row" ]
        if (alreadyAdded) className.push("row-disabled")
        return (
          <EntityInfoRow
            key={ row.id }
            onClick={ () => {
              if (!disabled) toggleSelectedCampaign(row.id)
            } }
            checkboxEnabled={ true }
            checkboxDisabled={ disabled }
            checkboxValue={ checked }
            name={ row.name }
            avatarSrc={ row.thumbnail?.url.address }
            subInfo={ `${ row.creator.username } | ${ dayjs(row.created, "X")
              .format(Constant.LONGFORM_DATE) }` }
          />
        )
      })
    }
    return <p className="cp_component-modal-add-to-error">Error!</p>
  }
  return (
    <ModalAddTo
      title={ translate("Add Influencers to a Campaign") }
      // eslint-disable-next-line max-len
      subtitle={ translate("Choose a campaign from the list below. If you can't find what you're looking for, make sure you're set as a campaign manager") }
      selectedEntityCount={ selectedCampaignIDs.length }
      open={ modalPhase === 2 }
      closeAction={ () => setAddToModalPhase(null) }
      primaryAction={ onSubmit }
      fetchData={ (e) => getCampaigns(e.target.value) }
      disabled={ selectedCampaignIDs.length >= 10 }
    >
      { renderContent() }
    </ModalAddTo>
  )
}

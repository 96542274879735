import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Campaign Modal Slice Interface and Initial State
export interface CampaignDeliverableModalState {
  isEditingCampaignDeliverable: boolean
  openDeliverableModal: boolean
  campaignDeliverableToEdit?: GraphQL.DeliverableFragment
  availableSocialAccounts: GraphQL.CampaignNetworkAccountFragment[]
  preSelectedSocialAccount?: GraphQL.CampaignNetworkAccountFragment

  /**
   * This callback should be defined on the page that is opening the Campaign Deliverable Modal.
   */
  closeCallback?: (edited: boolean) => void
  /**
   * This callback should be defined on the page that is opening the Edit Campaign Deliverable Modal.
   */
  updateCallback?: (edited: boolean) => void
  /**
   * This callback should be defined on the page that is opening the New Campaign Deliverable Modal.
   */
  createCallback?: () => void
}

const initialState: CampaignDeliverableModalState = {
  isEditingCampaignDeliverable: false,
  openDeliverableModal: false,
  campaignDeliverableToEdit: undefined,
  updateCallback: undefined,
  closeCallback: undefined,
  availableSocialAccounts: [],
  preSelectedSocialAccount: undefined,
}

// Deliverable Modal Slice
export const CampaignDeliverableModalSlice = createSlice({
  name: "CampaignDeliverableModalSlice",
  initialState,
  reducers: {
    openNewDeliverableModal: (
      state,
      action: PayloadAction<{
        socialAccounts: GraphQL.CampaignNetworkAccountFragment[],
        createCallback?: () => void,
        closeCallback?: (edited: boolean) => void,
        preSelectedSocialAccount?: GraphQL.CampaignNetworkAccountFragment
      }>,
    ) => ({
      ...state,
      isEditingCampaignDeliverable: false,
      campaignDeliverableToEdit: undefined,
      openDeliverableModal: true,
      availableSocialAccounts: action.payload.socialAccounts,
      createCallback: action.payload.createCallback,
      closeCallback: action.payload.closeCallback,
      preSelectedSocialAccount: action.payload.preSelectedSocialAccount,
    }),
    openEditDeliverableModal: (
      state,
      action: PayloadAction<{
        deliverable: GraphQL.DeliverableFragment,
        updateCallback?: (edited: boolean) => void,
        closeCallback?: (edited: boolean) => void,
        socialAccounts?: GraphQL.CampaignNetworkAccountFragment[],
      }>,
    ) => {
      const newState = {
        ...state,
        campaignDeliverableToEdit: action.payload.deliverable,
        updateCallback: action.payload.updateCallback,
        closeCallback: action.payload.closeCallback,
        availableSocialAccounts: action.payload.socialAccounts || [],
        isEditingCampaignDeliverable: true,
        openDeliverableModal: true,
      }
      return newState
    },
    setUpdateCallback: (
      state,
      action: PayloadAction<(edited: boolean) => void>,
    ) => ({
      ...state,
      updateCallback: action.payload,
    }),
    setAvailableAccounts: (
      state,
      action: PayloadAction<GraphQL.CampaignNetworkAccountFragment[]>,
    ) => ({
      ...state,
      availableSocialAccounts: action.payload,
    }),
    closeDeliverableModal: (
      state,
    ) => ({
      ...state,
      openDeliverableModal: false,
      isEditingCampaignDeliverable: false,
      campaignDeliverableToEdit: undefined,
      closeCallback: undefined,
      updateCallback: undefined,
    }),
  },
})

export const {
  openEditDeliverableModal,
  openNewDeliverableModal,
  closeDeliverableModal,
  setUpdateCallback,
  setAvailableAccounts,
} = CampaignDeliverableModalSlice.actions
export default CampaignDeliverableModalSlice.reducer

// Profile Slice Thunks
export const createDeliverable = (
  params: GraphQL.CreateDeliverableMutationVariables,
) => async () => {
  const result = await API.createDeliverable(params)
  return result
}

export const updateDeliverable = (
  params: GraphQL.UpdateDeliverableMutationVariables,
) => async () => {
  const result = await API.updateDeliverable(params)
  return result
}

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"

import { useParams } from "react-router-dom"
import ToastController from "../../controller/Toast"
import PageShell from "../../component/PageShell"
import CustomersOverview from "../../component/CustomersOverview"
import CustomerOverview from "../../component/CustomerOverview"
import { useDispatch } from "../../state/hooks"
import { getCustomer } from "../../state/customerSlice"

export default function Customers() {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Customers" })

  const { customerID } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    if (customerID) {
      dispatch(getCustomer({ customerId: customerID }))
    }
  }, [ customerID ])

  return (
    <div className="cp_customers_page">
      <ToastController />
      <PageShell title={ translate("Customers") } />
      <div className="cp_customers_page-container">
        { !customerID ? <CustomersOverview /> : <CustomerOverview /> }
      </div>
    </div>
  )
}

import React from "react"
import "./feedback-card.sass"
import { Divider } from "@mui/material"
import Timestamp from "../../component/DataGrid/RenderCellComponents/Timestamp"

type Props = {
  author: string
  teamName: string
  time: string
  body: string
}

export default function FeedbackCard({
  author, teamName, body, time,
}: Props) {
  return (
    <div className="cp_component_feedback-card">
      <div className="cp_component_feedback-card-header">
        <h5>{ author }</h5>
        <p>{ `  - ${ teamName }` }</p>
      </div>
      <Divider />
      <div className="cp_component_feedback-card-body">
        <p>{ body }</p>
        <Timestamp className="cp_component_feedback-card-body-timestamp" time={ time } />
      </div>
    </div>
  )
}

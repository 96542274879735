import React from "react"
import { useTranslation } from "react-i18next"

import "./style.sass"
import ModalSelectAccount from "../ModalSelectAccount"
import {
  getLists,
  getListsForAccount,
  getListsForProfile,
  setAddToModalPhase,
  setSelectedListIDs,
  setSelectedSocialAccountID,
  setSelectedSocialAccountNetwork,
  submitSocialAccountsToList,
} from "../../../state/socialProfileSlice"
import * as API from "../../../util/apiClient"
import { pushToast } from "../../../state/toastSlice"
import * as GraphQL from "../../../graphql"
import { useDispatch, useSelector } from "../../../state/hooks"
import ModalAddToLists from "./ModalAddToLists"
import ListsTabContent from "./ListsTabContent"
import { ALL_NETWORKS } from "../../../util/types"

export default function Lists() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })

  const {
    profile,
    addToModalPhase: modalPhase,
    selectedListIDs,
    selectedSocialAccountID,
  } = useSelector(({ socialProfile }) => socialProfile)

  const dispatch = useDispatch()

  const resetFields = () => {
    dispatch(setSelectedListIDs([]))
    dispatch(setSelectedSocialAccountID(""))
    dispatch(setSelectedSocialAccountNetwork(null))
  }

  const onAccountRowClick = (socialAccount: GraphQL.SocialAccountRowFragment) => {
    dispatch(setSelectedSocialAccountID(socialAccount.id))
    dispatch(setSelectedSocialAccountNetwork(socialAccount.network))
    dispatch(getLists("", socialAccount.network))
    dispatch(getListsForAccount(socialAccount.id, [ socialAccount.network ]))
    dispatch(setAddToModalPhase(2))
  }

  const openModalFlow = () => {
    if (
      API.isSuccess(profile)
    ) {
      resetFields()
      const socialAccounts = profile.payload.socialAccount.personality?.socialAccounts
      if (socialAccounts && socialAccounts.length > 1) {
        dispatch(setAddToModalPhase(1))
      } else {
        dispatch(setSelectedSocialAccountID(profile.payload.socialAccount.id))
        dispatch(setSelectedSocialAccountNetwork(profile.payload.socialAccount.network))
        dispatch(setAddToModalPhase(2))
        dispatch(getLists("", profile.payload.socialAccount.network))
        dispatch(getListsForAccount(profile.payload.socialAccount.id, [ profile.payload.socialAccount.network ]))
      }
    }
  }

  const onSubmit = async () => {
    dispatch(setAddToModalPhase(null))
    const results = await submitSocialAccountsToList(
      selectedSocialAccountID,
      selectedListIDs,
    )
    resetFields()
    if (results.some((result) => API.isError(result))) {
      dispatch(pushToast({
        message: translate("There was an error. Please try again."),
        type: "error",
      }))
    } else {
      dispatch(pushToast({
        message: translate("Account has successfully been added!"),
        type: "success",
      }))
      if (API.isSuccess(profile)) {
        const accountIds = profile.payload.socialAccount.personality?.socialAccounts.map((a) => a.id)
        || [ profile.payload.socialAccount.id ]
        dispatch(getListsForProfile(accountIds, ALL_NETWORKS))
      }
    }
  }

  return (
    <div className="cp_component_profile-tabs_lists">
      <ListsTabContent onButtonClick={ openModalFlow } />
      <ModalSelectAccount
        socialProfile={ API.isSuccess(profile) ? profile.payload.socialAccount : null }
        open={ modalPhase === 1 }
        closeAction={ () => dispatch(setAddToModalPhase(null)) }
        onRowClick={ onAccountRowClick }
        title={ translate("Select An Account") }
        subtitle={ translate("Select an account to add to a list below.") }
      />
      <ModalAddToLists onSubmit={ onSubmit } />
    </div>
  )
}

import React from "react"
import "./note-card.sass"
import Timestamp from "../../DataGrid/RenderCellComponents/Timestamp"
import Divider from "../../Divider"

type Props = {
  title: string
  time: string
  body: string
}

export default function NoteCard({
  title, body, time,
}: Props) {
  return (
    <div className="cp_component_note-card">
      <div className="cp_component_note-card-header">
        <h5>{ title }</h5>
        <Timestamp time={ time } />
      </div>
      <Divider />
      <div className="cp_component_note-card-body">
        { body }
      </div>
    </div>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import "./modal-remove-list-account.sass"
import { useSelector, useDispatch } from "../../state/hooks"
import { closeRemoveAccountModal, removeSocialAccount } from "../../state/listSlice"
import LoadingIndicator from "../LoadingIndicator"

function ModalRemoveListAccount(): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    removeAccount,
  } = useSelector(({ list }) => list)
  const [ loading, setLoading ] = React.useState(false)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalRemoveListAccount" })

  const removeAccountHandler = async () => {
    if (!removeAccount) return
    setLoading(true)

    await dispatch(removeSocialAccount({
      suggestionListSocialAccountRemoveId: removeAccount.id,
    }))

    setLoading(false)
    dispatch(closeRemoveAccountModal())
  }

  return (
    <Modal
      title={ translate("Remove From List") }
      primaryLabel={ loading ? <LoadingIndicator /> : translate("Remove") }
      secondaryLabel={ translate("Cancel") }
      disabled={ loading }
      open={ Boolean(removeAccount) }
      primaryAction={ removeAccountHandler }
      closeAction={ () => dispatch(closeRemoveAccountModal()) }
      secondaryAction={ () => dispatch(closeRemoveAccountModal()) }
      maxWidth="lg"
    >
      <div className="cp_modal-remove-list-account_component">
        <p>
          { translate("Are you sure you want to remove this account?") }
        </p>
      </div>
    </Modal>
  )
}

export default ModalRemoveListAccount

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useParams } from "react-router-dom"

import * as API from "../../../../util/apiClient"
import Modal from "../../../Modal"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  fetchCurrentAdCampaign,
  linkDeliverables,
  setLinkDeliverableModalOpen,
  setSelectedAdIds,
} from "../../../../state/campaignSlice"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import ErrorHandler from "../../../ErrorHandler"
import CampaignDeliverablesTable from "./Tables/CampaignDeliverablesTable"
import SearchBar from "../../../SearchBar"
import LoadingIndicator from "../../../LoadingIndicator"

export default function ModalLinkDeliverables() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalLinkDeliverable" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()

  const {
    linkDeliverableModalOpen: open, currentAdCampaign, selectedAdIds,
  } = useSelector(({ campaignPage }) => campaignPage)
  const { adID } = useParams()

  const [ searchInput, setSearchInput ] = useState("")
  const [ selectedDeliverableId, setSelectedDeliverableId ] = useState<string>()
  const [ submitLoading, setSubmitLoading ] = useState(false)

  const renderContent = () => {
    if (currentAdCampaign === "init" || currentAdCampaign === "loading") {
      return <LoadingIndicatorCard size={ 30 } />
    }

    if (API.isError(currentAdCampaign)) {
      return <ErrorHandler err={ currentAdCampaign } />
    }

    const deliverables = currentAdCampaign.payload.adCampaign.campaign?.deliverables || []
    const filteredDeliverables = deliverables.filter(
      (deliverable) => searchInput.length === 0
      || deliverable.campaignNetworkAccount.socialAccount.userName.includes(searchInput),
    )
    return (
      <>
        <SearchBar
          onChange={ (e) => setSearchInput(e.target.value) }
          lastSubmittedSearch={ searchInput }
          searchPlaceholderText={ translate("Search Account Name") }
        />
        <CampaignDeliverablesTable
          rows={ filteredDeliverables }
          selectedId={ selectedDeliverableId }
          setSelectedId={ setSelectedDeliverableId }
        />
      </>
    )
  }

  const onClose = () => {
    dispatch(setLinkDeliverableModalOpen(false))
    setSearchInput("")
    setSelectedDeliverableId(undefined)
  }

  const onSubmit = () => {
    if (adID && selectedDeliverableId) {
      dispatch(linkDeliverables({
        variables: {
          adIds: selectedAdIds,
          deliverableId: selectedDeliverableId,
        },
        onSuccess: async () => {
          setSubmitLoading(true)
          await dispatch(fetchCurrentAdCampaign({
            id: adID,
          }))
          setSubmitLoading(false)
          onClose()
          dispatch(setSelectedAdIds([]))
        },
      }))
    }
  }

  return (
    <Modal
      open={ open }
      title={ translate("Link Deliverable") }
      subtitle={ translate("Select the Deliverable you would like to link to this Ad.") }
      closeAction={ onClose }
      primaryAction={ onSubmit }
      disabled={ selectedDeliverableId === undefined }
      secondaryAction={ onClose }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : translate("Link") }
      secondaryLabel={ translateCommon("Cancel") }
      maxWidth="lg"
    >
      { renderContent() }
    </Modal>
  )
}

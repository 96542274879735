import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export type Breadcrumb = {
  header: string,
  urlpath: string
}

export interface BreadcrumbState {
  breadcrumbs: Breadcrumb[]
}

// Initialize state
const initialState: BreadcrumbState = {
  breadcrumbs: [],
}

export const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    pushBreadcrumb: (state, action: PayloadAction<Breadcrumb>) => ({
      ...state,
      breadcrumbs: [ ...state.breadcrumbs, action.payload ],
    }),
    popBreadcrumb: (state) => {
      const newBreadcrumbs = [ ...state.breadcrumbs ]
      newBreadcrumbs.pop()
      return {
        ...state,
        breadcrumbs: newBreadcrumbs,
      }
    },
    removeBreadcrumb: (state, action: PayloadAction<Breadcrumb>) => {
      const newBreadcrumbs = [ ...state.breadcrumbs ]
        .filter((crumb) => crumb.header !== action.payload.header || crumb.urlpath !== action.payload.urlpath)

      return {
        ...state,
        breadcrumbs: newBreadcrumbs,
      }
    },
  },
})

export const {
  pushBreadcrumb, popBreadcrumb, removeBreadcrumb,
} = breadcrumbSlice.actions
export default breadcrumbSlice.reducer

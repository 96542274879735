import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import "./style.sass"
import NotesInputCard from "./NotesInputCard"
import NotesContent from "./NotesContent"
import { useDispatch, useSelector } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import LoadingIndicator from "../../LoadingIndicator"
import { fetchSocialProfileForNotes } from "../../../state/socialProfileSlice"

export default function Notes() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.Notes" })
  const dispatch = useDispatch()
  const { profileForNotes } = useSelector(({ socialProfile }) => socialProfile)
  const { socialAccountId } = useParams()

  useEffect(() => {
    if (!socialAccountId) return
    dispatch(fetchSocialProfileForNotes(socialAccountId))
  }, [ socialAccountId ])

  const renderContent = () => {
    if (profileForNotes === "init" || profileForNotes === "loading") {
      return <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
    }
    if (API.isSuccess(profileForNotes)) {
      return (
        <>
          <NotesInputCard personalityId={ profileForNotes.payload.socialAccount.personality?.id || "" } />
          <NotesContent personalityNotes={ profileForNotes.payload.socialAccount.personality?.notes || [] } />
        </>
      )
    }
    return <p>{ translate("There was an error! Please try again.") }</p>
  }
  return (
    <div className="cp_component_profile-tabs_notes">
      { renderContent() }
    </div>
  )
}

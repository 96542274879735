import React, { useState } from "react"
import { IconButton, Menu } from "@mui/material"
import { UnfoldMore } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import MenuItem from "../../../MenuItem"
import { NETWORKS_AVAILABLE } from "../../../../util/searchHelper"
import "./network-filter-menu.sass"

type Props = {
  setSelectedNetwork: (network: null | string) => void
}

export default function NetworkFilterMenu({ setSelectedNetwork }: Props) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "common" })

  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuItemClick = (
    network: null | string,
  ) => {
    setSelectedNetwork(network)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton className="cp_component-network-filter-menu_icon-button" onClick={ handleButtonClick }>
        <UnfoldMore className="unfold-icon" />
      </IconButton>
      <Menu
        className="cp_component-network-filter-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
      >
        { NETWORKS_AVAILABLE.map((network) => (
          <MenuItem
            key={ `campaign-ad-table-network-${ network }` }
            onClick={ () => handleMenuItemClick(network) }
          >
            { network }
          </MenuItem>
        )) }
        <MenuItem
          key="campaign-ad-table-network-no-filter"
          onClick={ () => handleMenuItemClick(null) }
        >
          { translate("None") }
        </MenuItem>
      </Menu>
    </>
  )
}

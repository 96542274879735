import React from "react"
import { useTranslation } from "react-i18next"

import Button from "../../Button"
import "./campaigns-tab-content.sass"
import CampaignsTable from "./CampaignsTable"
import * as API from "../../../util/apiClient"
import LoadingIndicator from "../../LoadingIndicator"
import { Status } from "../../../util/types"
import * as GraphQL from "../../../graphql"

type Props = {
    onButtonClick: () => void
    campaignsForProfile: Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>
}

export default function CampaignsTabContent({ onButtonClick, campaignsForProfile }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })

  if (campaignsForProfile === "init" || campaignsForProfile === "loading") {
    return (<LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />)
  }

  if (API.isError(campaignsForProfile)) {
    return (
      <div>
        <p>There was an error! Please try again</p>
      </div>
    )
  }

  return (
    <div className="cp_profile_component-campaigns-content">
      <div className="cp_profile_component-campaigns-content-header">
        <h5>{ translate("Campaigns") }</h5>
        <Button
          label={ translate("Add to campaigns") }
          isEnabled={ true }
          isPrimary={ true }
          onClick={ onButtonClick }
        />
      </div>
      <CampaignsTable campaigns={ campaignsForProfile.payload.searchCampaignsByNetworkAccountIds.rows } />
    </div>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { MoreHoriz } from "@mui/icons-material"
import { Menu } from "@mui/material"
import MenuItem from "../MenuItem"
import { SuggestionListRowFragment } from "../../graphql"
import { openConfirmationModal } from "../../state/confirmationModalSlice"
import { useDispatch } from "../../state/hooks"
import { refreshListGroup, removeSuggestionListFromGroup } from "../../state/listGroupSlice"
import { getListCsv, submitArchiveSuggestionList } from "../../state/listSlice"
import { pushToast } from "../../state/toastSlice"
import { setListModalMode, setListModalOpen } from "../../state/listModalFormSlice"

import "./list-group-context-menu.sass"
import IconButton from "../IconButton"

type Props = {
  listGroupId: string
  suggestionList: SuggestionListRowFragment
}

export default function ListGroupContextMenu({ listGroupId, suggestionList }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListGroupContextMenu" })

  const navigate = useNavigate()
  const { vanity } = useParams()
  const dispatch = useDispatch()

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleViewList = () => {
    navigate(`/${ vanity }/lists/${ suggestionList.id }`)
  }

  const handleViewPublicList = () => {
    window.open(suggestionList.publicUrl, "_blank")
    handleClose()
  }

  const handleArchiveList = () => {
    dispatch(openConfirmationModal({
      title: translate("Archive List"),
      subtitle: translate("Archived lists can be found using the filter in the Lists panel."),
      subtext: `${ translate("Are you sure you want to archive") } ${ suggestionList.name }?`,
      primaryLabel: translate("Archive"),
      onConfirm: () => {
        dispatch(submitArchiveSuggestionList({
          variables: {
            id: suggestionList.id,
          },
          onSuccess: () => {
            dispatch(removeSuggestionListFromGroup({
              variables: {
                id: listGroupId,
                listIds: [ suggestionList.id ],
              },
              onSuccess: () => {
                dispatch(pushToast({
                  type: "success",
                  message: translate("Archive List"),
                  additionalInfo: translate("The selected list is successfully archived!"),
                }))
                dispatch(refreshListGroup())
              },
            }))
          },
          onError: () => dispatch(pushToast({
            type: "error",
            message: translate("There was an error. Please try again!"),
          })),
        }))
      },
    }))
    handleClose()
  }

  const handleEditList = () => {
    dispatch(setListModalMode({ isEditMode: true, listId: suggestionList.id }))
    dispatch(setListModalOpen(true))
    handleClose()
  }

  const handleRemoveList = () => {
    dispatch(openConfirmationModal({
      title: translate("Are you sure you want to remove from this list?"),
      subtext: (`${ suggestionList.name } ${ translate("will be removed from this group") }`),
      primaryLabel: translate("Remove"),
      onConfirm: () => dispatch(removeSuggestionListFromGroup({
        variables: {
          id: listGroupId,
          listIds: [ suggestionList.id ],
        },
        onSuccess: () => dispatch(refreshListGroup()),
      })),
    }))
    handleClose()
  }

  const handleDownloadListCsv = () => {
    dispatch(pushToast({
      type: "warning",
      message: translate("CSV download started..."),
    }))

    dispatch(getListCsv({
      variables: {
        id: suggestionList.id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Success!"),
          additionalInfo: translate("Your download is complete"),
        }))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: translate("There was an error. Please try again!"),
        }))
      },
    }))
    handleClose()
  }

  return (
    <>
      <IconButton
        className="cp_component_list-group-context-menu-button"
        onClick={ handleClick }
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        className="cp_component_list-group-context-menu"
        open={ open }
        anchorEl={ anchorEl }
        transformOrigin={ {
          horizontal: "center",
          vertical: "top",
        } }
        onClose={ handleClose }
      >
        <MenuItem onClick={ handleViewList }>{ translate("View List") }</MenuItem>
        <MenuItem onClick={ handleViewPublicList }>{ translate("View Public List") }</MenuItem>
        <MenuItem onClick={ handleArchiveList }>{ translate("Archive List") }</MenuItem>
        <MenuItem onClick={ handleEditList }>{ translate("Edit List") }</MenuItem>
        <MenuItem onClick={ handleRemoveList }>{ translate("Remove List") }</MenuItem>
        <MenuItem onClick={ handleDownloadListCsv }>{ translate("Download List as CSV") }</MenuItem>
      </Menu>
    </>
  )
}

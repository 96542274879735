import React from "react"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import "./modal-remove-category.sass"
import { useSelector, useDispatch } from "../../state/hooks"
import { closeRemoveCategoryModal, deleteCategory } from "../../state/listSlice"
import LoadingIndicator from "../LoadingIndicator"

function ModalRemoveCategory(): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    removeCategory,
  } = useSelector(({ list }) => list)
  const [ loading, setLoading ] = React.useState(false)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalRemoveCategory" })

  const removeAccountHandler = async () => {
    if (!removeCategory) return
    setLoading(true)

    await dispatch(deleteCategory({
      suggestionListCategoryDeleteId: removeCategory.id,
    }))

    setLoading(false)
    dispatch(closeRemoveCategoryModal())
  }

  return (
    <Modal
      title={ translate("Delete Category") }
      primaryLabel={ loading ? <LoadingIndicator /> : translate("Delete") }
      secondaryLabel={ translate("Cancel") }
      disabled={ loading }
      open={ Boolean(removeCategory) }
      primaryAction={ removeAccountHandler }
      closeAction={ () => dispatch(closeRemoveCategoryModal()) }
      secondaryAction={ () => dispatch(closeRemoveCategoryModal()) }
      maxWidth="lg"
    >
      <div className="cp_modal-remove-list-category_component">
        <p>
          { translate("Are you sure you want to delete table?", { table: removeCategory?.name || "" }) }
        </p>
      </div>
    </Modal>
  )
}

export default ModalRemoveCategory

import React, { JSX } from "react"

import * as Constant from "../util/constant"
import * as Type from "../util/types"

const useFeatureFlagIsEnabled = (flagName: Type.FeatureFlag): boolean => Constant.FEATURE_FLAGS[flagName]

export default function FeatureFlagIsEnabledGuard(props: {
  flagName: Type.FeatureFlag;
  children: React.ReactNode;
  fallback: React.ReactNode;
}): JSX.Element {
  const featureEnabled = useFeatureFlagIsEnabled(props.flagName)

  if (featureEnabled) {
    return <>{ props.children }</>
  }

  return <>{ props.fallback }</>
}

import React from "react"
import "./style.sass"
import { Chip, ChipProps } from "@mui/material"

interface Props extends ChipProps {
}

export default function SquareChip({ ...props }: Props) {
  const classes = [ "cp_component_square-chip", props.className ]
  return (
    <Chip { ...props } className={ classes.join(" ") } />
  )
}

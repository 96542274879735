import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import Button from "../../Button"
import { useDispatch, useSelector } from "../../../state/hooks"
import LoadingIndicator from "../../LoadingIndicator"
import * as API from "../../../util/apiClient"
import { getListsForProfile } from "../../../state/socialProfileSlice"

import "./lists-tab-content.sass"
import { ALL_NETWORKS } from "../../../util/types"
import ListsTable from "./ListsTable"

type Props = {
    onButtonClick: () => void
}

export default function ListsTabContent({ onButtonClick }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })
  const { listsForProfile, profile } = useSelector(({ socialProfile }) => socialProfile)
  const dispatch = useDispatch()

  useEffect(() => {
    if (API.isSuccess(profile)) {
      const accountIds = profile.payload.socialAccount.personality?.socialAccounts.map((a) => a.id)
      || [ profile.payload.socialAccount.id ]
      dispatch(getListsForProfile(accountIds, ALL_NETWORKS))
    }
  }, [])

  if (listsForProfile === "init" || listsForProfile === "loading") {
    return (<LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />)
  }
  if (API.isError(listsForProfile)) {
    return (
      <div>
        <p>{ translate("There was an error. Please try again.") }</p>
      </div>
    )
  }

  return (
    <div className="cp_profile_component-lists-content">
      <div className="cp_profile_component-lists-content-header">
        <h5>Lists</h5>
        <Button
          label={ translate("Add to List") }
          isEnabled={ true }
          isPrimary={ true }
          onClick={ onButtonClick }
        />
      </div>
      <ListsTable lists={ listsForProfile.payload.getSuggestionListsBySocialAccountIds.rows } />
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"

import { CAMPAIGN_REPORTING_TEMPLATES } from "../../../../util/constant"
import * as API from "../../../../util/apiClient"
import { CampaignReportUpdate, MapAllToggles } from "../../../../util/types"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  setCampaignReportUpdates,
  updateCampaignReport,
  setIntialCampaignReportUpdates,
  setInitialCampaignReportSettings,
  fetchCampaignReport,
  refreshCampaign,
} from "../../../../state/campaignSlice"
import Input from "../../../Input"
import Radio from "../../../Radio"
import MetricsNativeSection from "./MetricsNativeSection"
import MetricsPaidSection from "./MetricsPaidSection"
import MetricsConversionSection from "./MetricConversionSection"
import MediaPostsPaidSection from "./MediaPostsPaidSection"
import MediaPostsNativeSection from "./MediaPostsNativeSection"
import MetricsTrendsSection from "./MetricsTrendsSection"
import Checkbox from "../../../Checkbox"

import "./campaign-reporting.sass"
import Button from "../../../Button"
import { convertMapToSettings, mapAllToggles } from "./reportingHelper"

interface FlexibleReportUpdates extends CampaignReportUpdate {
  [key: string]: string | boolean | MapAllToggles;
}

type Props = {
  handleEditActive: () => void
}

export default function CampaignReportingSettings({ handleEditActive }:Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReporting" })
  const dispatch = useDispatch()
  const { campaignID } = useParams()
  const campaign = useSelector(({ campaignPage }) => campaignPage.campaign)
  const reportUpdates = useSelector(({ campaignPage }) => campaignPage.campaignReportSettingsUpdates)
  const campaignReport = useSelector(({ campaignPage }) => campaignPage.manageCampaignReportSettings)
  const campaignName = API.isSuccess(campaign) ? campaign.payload.campaign.name : ""
  const reportDefaultName = reportUpdates.name || campaignName

  const [ reportName, setReportName ] = useState(reportDefaultName)

  const handleActiveTemplateChange = (template: string) => {
    const currUpdates = { ...reportUpdates }
    currUpdates.template = template
    currUpdates.toggleSettings = mapAllToggles(template)
    dispatch(setCampaignReportUpdates(currUpdates))
  }

  const setReportTitle = () => {
    const currUpdates = { ...reportUpdates }
    currUpdates.name = reportName
    dispatch(setCampaignReportUpdates(currUpdates))
  }

  const handleTitleChange = (event: React.KeyboardEvent | null, source: string | null): void => {
    if (event && event.key === "Enter") {
      event.preventDefault()
      setReportTitle()
    } else if (source === "blur") {
      setReportTitle()
    }
  }

  const handleReportNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReportName(e.target.value)
  }

  const handleSettingUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currUpdates: FlexibleReportUpdates = { ...reportUpdates }
    currUpdates[e.target.name] = !currUpdates[e.target.name]
    dispatch(setCampaignReportUpdates(currUpdates))
  }

  const handleSaveReport = () => {
    let currCampId = ""
    if (campaignID) currCampId = campaignID
    const reportSettings = {
      campaignId: reportUpdates.campaignId || currCampId,
      template: reportUpdates.template,
      name: reportUpdates.name || reportName,
      enabled: reportUpdates.enabled,
      clientEnabled: reportUpdates.clientEnabled,
      clientBoosted: reportUpdates.clientBoosted,
      toggleSettings: convertMapToSettings(reportUpdates.toggleSettings),
    }
    dispatch(updateCampaignReport(reportSettings, translate, () => dispatch(refreshCampaign())))
  }

  useEffect(() => {
    if (campaignID) {
      const currUpdates = { ...reportUpdates }
      currUpdates.campaignId = campaignID
      currUpdates.name = reportDefaultName
      dispatch(setCampaignReportUpdates(currUpdates))
    }

    if (reportUpdates.name) {
      setReportName(reportUpdates.name)
    }

    return () => {
      dispatch(setIntialCampaignReportUpdates())
    }
  }, [])

  useEffect(() => {
    if (API.isSuccess(campaignReport) && campaignID) {
      dispatch(fetchCampaignReport(campaignID))
      handleEditActive()
    }

    return () => {
      dispatch(setInitialCampaignReportSettings())
    }
  }, [ campaignReport ])

  return (
    <div className="cp_campaign_reporting_tab settings">
      <div className="cp_campaign_reporting_tab-actions">
        <Button
          isPrimary={ true }
          isEnabled={ !!reportName }
          label={ translate("SAVE & REVIEW") }
          onClick={ handleSaveReport }
        />
      </div>
      <Accordion defaultExpanded={ true } className="cp_campaign_reporting_tab-accordion_template" variant="outlined">
        <AccordionSummary
          expandIcon={ <ArrowForwardIosIcon /> }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3 className="cp_component_accordion-heading">
            { translate("Choose Template") }
            <span>{ translate("Select a reporting template, name your report, and configure report settings.") }</span>
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          <Input
            className="cp_component_accordion-report-name"
            label={ `${ translate("REPORT NAME") }*` }
            onKeyDown={ (e) => handleTitleChange(e, null) }
            onBlur={ () => handleTitleChange(null, "blur") }
            placeholder={ translate("Name your report") }
            onChange={ (e) => handleReportNameChange(e) }
            value={ reportName }
          />
          <div className="cp_component_accordion-radiogroup">
            <h4 className="headline-small">{ translate("Choose a reporting Template") }</h4>
            <FormControl className="cp_component_accordion-form-control">
              <RadioGroup
                onChange={ (_, selected) => handleActiveTemplateChange(selected) }
                className="cp_component_accordion_radio-options"
                value={ reportUpdates.template }
              >
                <FormControlLabel
                  className="cp_radio-label"
                  value={ CAMPAIGN_REPORTING_TEMPLATES.AWARENESS }
                  control={ <Radio /> }
                  label={ translate("Awareness/Traffic") }
                  checked={ CAMPAIGN_REPORTING_TEMPLATES.AWARENESS === reportUpdates.template }
                />
                <FormControlLabel
                  className="cp_radio-label"
                  value={ CAMPAIGN_REPORTING_TEMPLATES.NATIVE }
                  control={ <Radio /> }
                  label={ translate("Native") }
                  checked={ CAMPAIGN_REPORTING_TEMPLATES.NATIVE === reportUpdates.template }
                />
                <FormControlLabel
                  className="cp_radio-label"
                  value={ CAMPAIGN_REPORTING_TEMPLATES.CONVERSION }
                  control={ <Radio /> }
                  label={ translate("Conversion") }
                  checked={ CAMPAIGN_REPORTING_TEMPLATES.CONVERSION === reportUpdates.template }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>
      <MetricsNativeSection />
      { (reportUpdates.template !== CAMPAIGN_REPORTING_TEMPLATES.NATIVE) && (
        <MetricsPaidSection />
      ) }
      { (reportUpdates.template === CAMPAIGN_REPORTING_TEMPLATES.CONVERSION) && <MetricsConversionSection /> }
      <MediaPostsNativeSection />
      { (reportUpdates.template !== CAMPAIGN_REPORTING_TEMPLATES.NATIVE) && (
        <MediaPostsPaidSection />
      ) }
      <MetricsTrendsSection activeTemplate={ reportUpdates.template } />
      <Accordion className="cp_campaign_reporting_tab-accordion_settings" variant="outlined">
        <AccordionSummary
          expandIcon={ <ArrowForwardIosIcon /> }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3 className="cp_component_accordion-heading">
            { translate("Report Settings") }
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          { /* Settings section */ }
          <FormControl className="cp_formcontrol-settings" component="fieldset" fullWidth={ true }>
            <FormLabel component="legend">
              { translate("REPORT SETTINGS") }
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={ reportUpdates.enabled }
                    onChange={ (e) => { handleSettingUpdate(e) } }
                    name="enabled"
                  />
                ) }
                label={ translate("Enable Public Link") }
              />
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={ reportUpdates.clientEnabled }
                    onChange={ (e) => { handleSettingUpdate(e) } }
                    name="clientEnabled"
                  />
                ) }
                label={ translate("Enable Reporting Tab for Brand Managers") }
              />
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={ reportUpdates.clientBoosted }
                    onChange={ (e) => { handleSettingUpdate(e) } }
                    name="clientBoosted"
                  />
                ) }
                label={ translate("Native TikTok Boosted with Spark Ads") }
              />
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

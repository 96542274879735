import React, { useMemo } from "react"
import { GridColDef } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { EditOutlined, Launch } from "@mui/icons-material"
import DataGrid from "../../DataGrid"
import * as GraphQL from "../../../graphql"
import EntityInfoRow from "../../EntityInfoRow"

import "./lists-table.sass"
import Pill from "../../Pill"
import ContextMenuButton from "../../DataGrid/RenderCellComponents/ContextMenuButton"
import MenuItem from "../../MenuItem"
import EmptySplash from "../../EmptySplash"
import EmptyElement from "../../EmptyElement"
import { useDispatch } from "../../../state/hooks"
import { setListModalMode, setListModalOpen } from "../../../state/listModalFormSlice"

type Props = {
    lists: GraphQL.SuggestionListRowFragment[]
}

export default function ListsTable({ lists }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabListsTable" })
  const navigate = useNavigate()
  const { vanity } = useParams()
  const dispatch = useDispatch()

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "list",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerName: translate("List"),
      renderCell: (params) => (
        <EntityInfoRow avatarSize="md" networkAvatarOnly={ true } network={ params.row.network } name={ params.row.name } />
      ),
    },
    {
      field: "reach",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerName: translate("Reach"),
      renderCell: (params) => (
        <p>{ parseInt(params.row.reach, 10).toLocaleString() }</p>
      ),
    },
    {
      field: "demographic",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerName: translate("Demographic"),
      renderCell: (params) => {
        const {
          minAge,
          maxAge,
          sexes,
        } = params.row
        // Render pill only if all values are not null or undefined
        if ([ minAge, maxAge, sexes[0] ].every((v) => !!v)) {
          return <Pill label={ `${ minAge }-${ maxAge } | ${ sexes[0] }` } />
        }
        return null
      },
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ContextMenuButton
          transformOrigin={ { horizontal: "right", vertical: "top" } }
          anchorOrigin={ { horizontal: "center", vertical: "center" } }
        >
          <MenuItem onClick={ () => window.open(`/${ vanity }/lists/${ params.id }`, "_blank") }>
            <p>{ translate("View List") }</p>
            <Launch />
          </MenuItem>
          <MenuItem onClick={ () => {
            dispatch(setListModalOpen(true))
            dispatch(setListModalMode({ isEditMode: true, listId: params.id.toString() }))
          } }
          >
            <p>{ translate("Edit List") }</p>
            <EditOutlined />
          </MenuItem>
        </ContextMenuButton>
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 90,
    },
  ], [ translate ])

  return (
    <>
      <DataGrid
        className="cp_profile-tab_lists-table"
        getRowId={ (row) => row.id }
        onRowClick={ (row) => navigate(`/${ vanity }/lists/${ row.id }`) }
        disableRowSelectionOnClick={ true }
        rowHeight={ 90 }
        columnHeaderHeight={ 40 }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        rows={ lists }
        slots={ {
          noResultsOverlay: EmptyElement,
          noRowsOverlay: EmptyElement,
        } }
      />
      { lists.length === 0 && (
        <EmptySplash bodyText={ translate("No lists found") } />
      ) }
    </>
  )
}

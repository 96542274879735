/* eslint-disable max-len */
import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"

import "./list-management-row-context-menu.sass"

import { useLocation, useNavigate } from "react-router-dom"
import { ListCategorySocialAccount, Scope } from "../../util/types"
import { isError } from "../../util/apiClient"
import { openCreateFeedbackModal, openRemoveAccountModal } from "../../state/listSlice"
import { setEditModalOpen, fetchListSocialProfile } from "../../state/listSocialProfileSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import { SuggestionListMode } from "../../graphql"

interface ListManagementRowContextMenuProps {
  suggestionListSocialAccount: ListCategorySocialAccount
}

export default function ListManagementRowContextMenu({
  suggestionListSocialAccount,
}: ListManagementRowContextMenuProps) {
  const {
    list,
  } = useSelector(({ list: listState }) => listState)

  const { scopes } = useSelector(({ user }) => user)

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListManagementRowContextMenu" })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  if (list === "loading" || list === "init" || isError(list)) return null
  return (
    <>
      <IconButton
        className="cp_list-management-row-context-menu_component-button"
        onClick={ ({ currentTarget }) => setAnchorEl(currentTarget) }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_list-management-row-context-menu_component-menu-popover"
      >
        <MenuList
          dense={ true }
          className="cp_list-management-row-context-menu_component-menu"
        >
          <MenuItem
            className="cp_list-management-row-context-menu_component-menu-item"
            onClick={ () => navigate(`${ location.pathname }/social-account/${ suggestionListSocialAccount.socialAccount.id }`) }
          >
            <ListItemText className="cp_list-management-row-context-menu_component-menu-item-label">
              { translate("View Details") }
            </ListItemText>
          </MenuItem>
          <MenuItem
            className="cp_list-management-row-context-menu_component-menu-item"
            onClick={ () => {
              window.open(`${ location.pathname }/social-account/${ suggestionListSocialAccount.socialAccount.id }`, "_blank")
            } }
          >
            <ListItemText className="cp_list-management-row-context-menu_component-menu-item-label">
              { translate("View Details") }
            </ListItemText>
            <LaunchOutlinedIcon fontSize="small" />
          </MenuItem>
          { scopes.includes(Scope.INFLUENCERS_READ) && scopes.includes(Scope.INFLUENCERS_UPDATE) ? (
            <MenuItem
              className="cp_list-management-row-context-menu_component-menu-item"
              onClick={ async () => {
                dispatch(fetchListSocialProfile({
                  listId: list.payload.suggestionListById.id,
                  socialAccountId: suggestionListSocialAccount.socialAccount.id,
                }))
                dispatch(setEditModalOpen(true))
                setAnchorEl(null)
              } }
            >
              <ListItemText className="cp_list-management-row-context-menu_component-menu-item-label">
                { translate("Edit Account") }
              </ListItemText>
              <EditOutlinedIcon fontSize="small" />
            </MenuItem>
          ) : null }
          <MenuItem
            className="cp_list-management-row-context-menu_component-menu-item"
            onClick={ () => setAnchorEl(null) }
          >
            <ListItemText className="cp_list-management-row-context-menu_component-menu-item-label">
              <Link
                color="inherit"
                underline="none"
                target="_blank"
                href={ suggestionListSocialAccount?.socialAccount?.networkUrl || "#" }
              >
                { translate("View Network Profile") }
              </Link>
            </ListItemText>
            <LaunchOutlinedIcon fontSize="small" />
          </MenuItem>
          <MenuItem
            disabled={ list.payload.suggestionListById.suggestionListMode !== SuggestionListMode.Campaign }
            className="cp_list-management-row-context-menu_component-menu-item"
            onClick={ () => {
              dispatch(openCreateFeedbackModal(suggestionListSocialAccount))
              setAnchorEl(null)
            } }
          >
            <ListItemText className="cp_list-management-row-context-menu_component-menu-item-label">
              { translate("View Status") }
            </ListItemText>
            <LaunchOutlinedIcon fontSize="small" />
          </MenuItem>
          <MenuItem
            className="cp_list-management-row-context-menu_component-menu-item"
            onClick={ () => {
              dispatch(openRemoveAccountModal(suggestionListSocialAccount))
              setAnchorEl(null)
            } }
          >
            <ListItemText className="cp_list-management-row-context-menu_component-menu-item-label">
              { translate("Remove From List") }
            </ListItemText>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

import React from "react"
import { MenuItemProps, MenuItem as MuiMenuItem } from "@mui/material"
import "./style.sass"

interface Props extends MenuItemProps {
}

export default function MenuItem(props: Props) {
  const classes = [ "cp_component_menu-item" ]
  if (props.className) props.className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")
  return (
    <MuiMenuItem
      { ...props }
      className={ newClasses }
    />
  )
}

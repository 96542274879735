import React, { JSX, useState } from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import * as MiscHelper from "../../util/miscHelper"
import Button from "../../component/Button"
import Input from "../../component/Input"
import LoadingIndicator from "../../component/LoadingIndicator"
import { sendTeamEmail, setTeamEmail } from "../../state/teamSlice"
import { useDispatch, useSelector } from "../../state/hooks"

type Props = {
  setFindTeamActive: (active: boolean) => void,
}

export default function FindTeamForm(props: Props): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Team" })
  const dispatch = useDispatch()
  const teamEmail = useSelector(({ team }) => team.teamEmail)
  const [ email, setEmail ] = useState<string>("")

  if (API.isSuccess(teamEmail)) {
    return (
      <section className="card">
        <article />
        <h1 className="headline">
          { translate("Check your email") }
        </h1>
        <p className="label_medium">
          { translate("We've sent you a list of teams", { email }) }
        </p>
        { /* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */ }
        <a
          id="cp_page_team-wrong-email-button"
          onClick={ () => {
            dispatch(setTeamEmail("init"))
            setEmail("")
          } }
          role="button"
          tabIndex={ -1 }
        >
          { translate("Wrong email? Enter a different address") }
        </a>
      </section>
    )
  }

  return (
    <section className="card">
      <article />
      <h1 className="headline">
        { translate("Find Your Team") }
      </h1>
      <p className="label_medium">
        { translate("We'll send you an email") }
      </p>
      <aside className="email-container">
        <Input
          error={ API.isError(teamEmail) }
          InputProps={ {
            id: "cp_page_team-email-input",
            inputProps: { maxLength: 60 },
          } }
          onChange={ (e) => setEmail(e.target.value) }
          onKeyDown={ (e) => {
            if (
              e.key === "Enter"
              && MiscHelper.emailRegex.test(email)
              && teamEmail !== "loading"
            ) {
              dispatch(sendTeamEmail(email))
            }
          } }
          placeholder={ translate("Email") }
          value={ email }
        />
      </aside>
      { API.isError(teamEmail) && (
        <span>
          { translate("There was an issue sending an email to that address") }
        </span>
      ) }
      <Button
        className="primary"
        disabled={ email.length === 0
          || !MiscHelper.emailRegex.test(email)
          || teamEmail === "loading"
        }
        id="cp_page_team-find-your-team-confirm-button"
        label={
          teamEmail === "loading"
            ? <LoadingIndicator size={ 24 } />
            : translate("Confirm")
        }
        onClick={ () => dispatch(sendTeamEmail(email)) }
        tabIndex={ 0 }
        type="submit"
      />
      { /* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */ }
      <a
        id="cp_page_team-already-know-team-url-link"
        onClick={ () => props.setFindTeamActive(false) }
        role="button"
        tabIndex={ -1 }
      >
        { translate("Already know your team URL? Click here to login") }
      </a>
    </section>
  )
}

import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import { RootState } from "../store"
import {
  setCustomersByUserStatus,
  setCustomersContent,
  setCustomersSearchInput,
  setCustomersSortDirection,
  setCustomersStatus,
  setMoreCustomersIsLoading,
} from "."

// Campaign Interface and Initial State
export interface CustomersState {
  customersStatus: Status<GraphQL.SearchCustomersQuery>
  customersByUserStatus: Status<GraphQL.SearchCustomersByUserQuery>
  moreCustomersIsLoading: boolean
  customersContent: Array<GraphQL.CustomerFragment>
  customersSearchInput: string
  customersSearchToggle: "customer" | "user"
  customersSortDirection: GraphQL.SortDirection.Asc | GraphQL.SortDirection.Desc
}

export const initialState: CustomersState = {
  customersStatus: "init",
  customersByUserStatus: "init",
  moreCustomersIsLoading: false,
  customersContent: [],
  customersSearchInput: "",
  customersSearchToggle: "customer",
  customersSortDirection: GraphQL.SortDirection.Asc,
}

// Reducer actions
export const reducers = {
  setCustomersStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchCustomersQuery>>) => ({
    ...state,
    customersStatus: action.payload,
  }),
  setCustomersByUserStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchCustomersByUserQuery>>) => ({
    ...state,
    customersByUserStatus: action.payload,
  }),
  setMoreCustomersIsLoading: (state: any, action: PayloadAction<boolean>) => ({
    ...state,
    moreCustomersIsLoading: action.payload,
  }),
  setCustomersContent: (state: any, action: PayloadAction<Array<GraphQL.CustomerFragment>>) => ({
    ...state,
    customersContent: action.payload,
  }),
  setCustomersSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    customersSearchInput: action.payload,
  }),
  setCustomersSearchToggle: (state: any, action: PayloadAction<string>) => ({
    ...state,
    customersSearchToggle: action.payload,
  }),
  setCustomersSortDirection: (state: any, action: PayloadAction<GraphQL.SortDirection>) => ({
    ...state,
    customersSortDirection: action.payload,
  }),
}

export const getCustomers = (
  searchInput: string,
  sortDirection: GraphQL.SortDirection,
  page: number,
) => async (dispatch: Dispatch) => {
  dispatch(setCustomersStatus("loading"))

  const result = await API.fetchCustomers({
    startsWith: searchInput,
    page,
    limit: 50,
    column: GraphQL.SearchCustomerSort.Name,
    direction: sortDirection,
  })

  dispatch(setCustomersStatus(result))
  if (API.isSuccess(result)) {
    dispatch(setCustomersContent(result.payload.searchCustomers.rows))
  }
  dispatch(setCustomersSearchInput(searchInput))
}

export const getCustomersByUser = (
  searchInput: string,
  sortDirection: GraphQL.SortDirection,
  page: number,
) => async (dispatch: Dispatch) => {
  dispatch(setCustomersStatus("loading"))

  const result = await API.fetchCustomersByUser({
    startsWith: searchInput,
    page,
    limit: 50,
    column: GraphQL.SearchCustomerSort.Name,
    direction: sortDirection,
  })

  dispatch(setCustomersByUserStatus(result))
  if (API.isSuccess(result)) {
    dispatch(setCustomersContent(result.payload.searchCustomersByUser.rows))
  }
  dispatch(setCustomersSearchInput(searchInput))
}

export const getMoreCustomers = (
  searchInput: string,
  sortDirection: GraphQL.SortDirection,
  page: number,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { customersStatus: currentCustomersStatus, customersContent: currentCustomersContent } } = getState()
  dispatch(setMoreCustomersIsLoading(true))
  const result = await API.fetchCustomers({
    startsWith: searchInput,
    page,
    limit: 50,
    column: GraphQL.SearchCustomerSort.Name,
    direction: sortDirection,
  })
  if (API.isSuccess(currentCustomersStatus) && API.isSuccess(result)) {
    dispatch(setCustomersContent([ ...currentCustomersContent, ...result.payload.searchCustomers.rows ]))
  } else {
    dispatch(setCustomersStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
  dispatch(setCustomersSearchInput(searchInput))
  dispatch(setMoreCustomersIsLoading(false))
}

export const getMoreCustomersByUser = (
  searchInput: string,
  sortDirection: GraphQL.SortDirection,
  page: number,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const {
    slidingPanels: {
      customersByUserStatus: currentCustomersByUserStatus,
      customersContent: currentCustomersContent,
    },
  } = getState()
  dispatch(setMoreCustomersIsLoading(true))
  const result = await API.fetchCustomersByUser({
    startsWith: searchInput,
    page,
    limit: 50,
    column: GraphQL.SearchCustomerSort.Name,
    direction: sortDirection,
  })
  if (API.isSuccess(currentCustomersByUserStatus) && API.isSuccess(result)) {
    dispatch(setCustomersContent([ ...currentCustomersContent, ...result.payload.searchCustomersByUser.rows ]))
  } else {
    dispatch(setCustomersStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
  dispatch(setCustomersSearchInput(searchInput))
  dispatch(setMoreCustomersIsLoading(false))
}

export const toggleCustomersSortDirection = () => (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { customersSortDirection } } = getState()
  if (customersSortDirection === GraphQL.SortDirection.Asc) {
    dispatch(setCustomersSortDirection(GraphQL.SortDirection.Desc))
  } else {
    dispatch(setCustomersSortDirection(GraphQL.SortDirection.Asc))
  }
}

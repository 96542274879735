import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import Modal from "../../../Modal"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  fetchCurrentAdCampaign,
  setSelectedAdSetIds,
  setUpdateAudienceTargettingModalOpen,
  updateAdSet,
} from "../../../../state/campaignSlice"
import Input from "../../../Input"
import { isSuccess } from "../../../../util/apiClient"
import LoadingIndicator from "../../../LoadingIndicator"

export default function UpdateAudienceTargettingModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalUpdateAudienceTargetting" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })

  const dispatch = useDispatch()
  const [ audienceTargetingInput, setAudienceTargetingInput ] = useState("")
  const [ submitLoading, setSubmitLoading ] = useState(false)

  const {
    updateAudienceTargettingModalOpen: open, selectedAdSetIds, currentAdCampaign,
  } = useSelector(({ campaignPage }) => campaignPage)

  const onClose = () => {
    dispatch(setUpdateAudienceTargettingModalOpen(false))
  }

  const onSubmit = async () => {
    if (isSuccess(currentAdCampaign)) {
      const { id } = currentAdCampaign.payload.adCampaign
      setSubmitLoading(true)
      dispatch(updateAdSet({
        variables: {
          adIds: selectedAdSetIds,
          audienceTargeting: audienceTargetingInput,
        },
        onSuccess: () => {
          dispatch(fetchCurrentAdCampaign({ id }))
        },
      }))
      setSubmitLoading(false)
      dispatch(setSelectedAdSetIds([]))
      onClose()
    }
  }

  return (
    <Modal
      open={ open }
      title={ translate("Update Audience Targeting") }
      subtitle={ translate("Enter a new descriptor for the targeted audience.") }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : translate("Update") }
      secondaryLabel={ translateCommon("Cancel") }
      primaryAction={ onSubmit }
      secondaryAction={ onClose }
      closeAction={ onClose }
    >
      <Input
        value={ audienceTargetingInput }
        onChange={ (e) => setAudienceTargetingInput(e.target.value) }
        placeholder={ translate("Audience Targeting") }
      />
    </Modal>
  )
}

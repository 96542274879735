import React, { JSX, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import ListProfileOverview from "../../component/ProfileOverview/ListProfileOverview"
import ListProfileTabs from "../../component/ProfileTabs/ListSocialProfileTabs"
import PageShell from "../../component/PageShell"
import ProfileModal from "../../component/ModalProfile"
import ToastController from "../../controller/Toast"
import { fetchListSocialProfile } from "../../state/listSocialProfileSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export default function ListSocialProfile(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.ListSocialProfile" })
  const dispatch = useDispatch()
  const { socialProfileID, listID } = useParams()
  const { user } = useSelector(({ user: userSlice }) => userSlice)

  useEffect(() => {
    if (!socialProfileID || !listID) return
    dispatch(fetchListSocialProfile({ listId: listID, socialAccountId: socialProfileID }))
  }, [ socialProfileID, listID ])

  // User state is loading... show minimal page frame without results...
  if (!API.isSuccess(user)) {
    return (
      <div className="cp_list-profile_page">
        <ToastController />
        <PageShell
          title={ translate("Account") }
          showTopNav={ false }
        />
      </div>
    )
  }

  return (
    <div className="cp_list-profile_page">
      <ToastController />
      <PageShell title={ translate("Account") } />
      <div className="cp_list-profile_page-container">
        <section className="cp_list-profile_page-overview">
          <ListProfileOverview />
        </section>
        <section className="cp_list-profile_page-metrics">
          <ListProfileTabs />
        </section>
      </div>
      <ProfileModal />
    </div>
  )
}

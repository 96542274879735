import React, { JSX } from "react"
import { useSearchParams } from "react-router-dom"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useTranslation } from "react-i18next"

import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../util/searchHelper"
import { setSearchInput } from "../../state/searchSlice"
import { useDispatch, useSelector } from "../../state/hooks"

export default function TargetFilter(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "controller.SearchFilter" })
  const dispatch = useDispatch()
  const searchInput = useSelector(({ search }) => search.searchInput)
  const [ searchParams, setSearchParams ] = useSearchParams()

  const handleSelectChange = (event: SelectChangeEvent) => {
    const newTarget = event.target.value
    if (!SearchHelper.isGodSearchTargetType(newTarget)) return

    const newInput = SearchHelper.cloneSearchInput(searchInput)
    newInput.target = newTarget
    dispatch(setSearchInput(newInput))
    SearchHelper.setSearchInputQueryParams(
      newInput,
      searchParams,
      setSearchParams,
    )
  }

  return (
    <div className="cp_component_search-filter-target">
      <p className="label_small-caps-bold">{ translate("Search Target") }</p>
      <FormControl
        className="cp_component_search-filter-target-select-control"
        variant="standard"
      >
        <InputLabel
          className="cp_component_search-filter-target-input-label"
          disableAnimation={ true }
        >
          { translate("Show All") }
        </InputLabel>
        <Select
          labelId="cp_component_search-filter-target-select"
          className="cp_component_search-filter-target-select"
          onChange={ handleSelectChange }
          value={ searchInput.target || "" }
        >
          <MenuItem
            className="cp_component_search-filter-target-select-item"
            value={ GraphQL.GodSearchTargetType.Influential }
          >
            { translate("Show All") }
          </MenuItem>
          <MenuItem
            className="cp_component_search-filter-target-select-item"
            value={ GraphQL.GodSearchTargetType.Own }
          >
            { translate("My Profiles Only") }
          </MenuItem>
          <MenuItem
            className="cp_component_search-filter-target-select-item"
            value={ GraphQL.GodSearchTargetType.InfluentialMinusOwn }
          >
            { translate("Exclude My Profiles") }
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

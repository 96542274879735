import React from "react"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import "./style.sass"

export type ToggleMenuValue = {
  value: string, label: string, keyId: string,
}

type Props = {
    toggleOptions: ToggleMenuValue[]
    value: any
    setValue: (value: any) => void
    className?: string
    id?: string
}

const classes = [ "cp_component_toggle" ]

export default function Toggle({
  toggleOptions, value, setValue, className = "", id,
}: Props) {
  const classNamesProp = className?.split(" ") || []
  const classNames = classes.concat(classNamesProp).join(" ")
  return (

    <ToggleButtonGroup id={ id } className={ classNames } value={ value } exclusive={ true }>
      { toggleOptions.map((option) => (
        <ToggleButton
          key={ option.keyId }
          value={ option.value }
          onClick={ () => setValue(option.value) }
        >
          { option.label }
        </ToggleButton>
      )) }
    </ToggleButtonGroup>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

import { TOAST_DEFAULT_TIMEOUT_MS } from "../../util/constant"
import { Toast } from "../../util/types"

export interface InternalToast extends Toast {
  id: string,
}

export interface ToastState {
  toasts: InternalToast[],
}

// Initialize state
const initialState: ToastState = {
  toasts: [],
}

export const toastSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    pushNewToast: (state, action: PayloadAction<InternalToast>) => ({
      ...state,
      toasts: [ ...state.toasts, action.payload ],
    }),

    popToastById: (state, action: PayloadAction<string>) => ({
      ...state,
      toasts: [ ...state.toasts.filter((t) => t.id !== action.payload) ],
    }),
  },
})

export const { pushNewToast, popToastById } = toastSlice.actions
export default toastSlice.reducer

// Toast Slice Thunks
export const pushToast = (toast: Toast) => (dispatch: Dispatch): void => {
  const toastId = uuidv4()
  const internalToast: InternalToast = {
    id: toastId,
    ...toast,
  }

  dispatch(pushNewToast(internalToast))

  setTimeout(() => {
    dispatch(popToastById(toastId))
  }, toast.timeout || TOAST_DEFAULT_TIMEOUT_MS)
}

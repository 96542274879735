import React, { JSX } from "react"

import "./style.sass"
import { useTranslation } from "react-i18next"

export default function ComingSoonSplash(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ComingSoonSplash" })

  return (
    <div className="cp_component_coming-soon-splash">
      <article>
        <h4>{ translate("NEW WIDGETS") }</h4>
        <h2 className="body_large">{ translate("Coming Soon") }</h2>
      </article>
    </div>
  )
}

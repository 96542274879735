import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import { fetchSocialTrackerCsv } from "../../util/apiClient/non-graphql"

// Social Tracker Download CSV Modal Slice Interface and Initial State
export interface SocialTrackerDownloadCSVModalState {
  open: boolean
  socialTracker?: GraphQL.ContentAlertFragment
}

const initialState: SocialTrackerDownloadCSVModalState = {
  open: false,
}

// Deliverable Modal Slice
export const SocialTrackerDownloadCSVModalSlice = createSlice({
  name: "SocialTrackerDownloadCSVModalSlice",
  initialState,
  reducers: {
    openSocialTrackerDownloadCSVModal: (
      state,
      action: PayloadAction<GraphQL.ContentAlertFragment>,
    ) => ({
      ...state,
      open: true,
      socialTracker: action.payload,
    }),
    closeSocialTrackerDownloadCSVModal: (
      state,
    ) => ({
      ...state,
      socialTracker: undefined,
      open: false,
    }),
  },
})

export const {
  closeSocialTrackerDownloadCSVModal,
  openSocialTrackerDownloadCSVModal,
} = SocialTrackerDownloadCSVModalSlice.actions
export default SocialTrackerDownloadCSVModalSlice.reducer

// Profile Slice Thunks
export const downloadSocialTrackerCSV = (params: {
  variables: {
    fileName: string,
    postStart: number,
    postEnd: number,
    xref: string
  },
  onSuccess: () => void,
  onError: () => void
}) => async () => {
  const result = await fetchSocialTrackerCsv(params.variables)
  if (
    result.status === 200
  ) {
    const blob = await result.blob()
    const file = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = file
    a.download = params.variables.fileName
    a.click()
    window.URL.revokeObjectURL(file)
    a.remove()

    params.onSuccess()
  } else {
    params.onError()
  }
}

import React, { JSX } from "react"
import { MenuItem } from "@mui/material"
import { Props as SocialAvatarProps } from "../component/SocialAvatar"
import * as GraphQL from "../graphql"

// Generate Select options
// TODO: When more exports exist, take out eslint disable below
// eslint-disable-next-line import/prefer-default-export
export function selectionOptions(
  options: string[],
  noPlaceholder?: boolean,
): JSX.Element[] {
  const selectOptions = options.map((option, indx) => {
    if (!noPlaceholder && indx === 0) {
      return (
        <MenuItem key={ option } value="" disabled={ true }>
          { option }
        </MenuItem>
      )
    }

    return (
      <MenuItem key={ option } value={ option }>{ option }</MenuItem>
    )
  })

  return selectOptions
}

export const getSocialAvatarProps = (socialAccount: GraphQL.SocialAccount): SocialAvatarProps => {
  const {
    userName,
    isNsfw,
    socialAccountStatistics: { followers },
    network,
    name,
    isPlaceholder,
    profilePictureUrl,
    personality,
    emails,
    emailsSourcedFromTeam,
  } = socialAccount

  const isUnsubscribed = [ ...emails, ...emailsSourcedFromTeam ].some((email) => email.unsubscribedTags.length > 0)

  return {
    username: userName,
    isNSFW: isNsfw,
    followers,
    fullName: name || "",
    isBlacklisted: personality?.blacklist || false,
    isPlaceholder,
    isUnsubscribed,
    network,
    profilePictureUrl,
  }
}

import React from "react"
import { useTranslation } from "react-i18next"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import {
  IconButton,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material"
import * as GraphQL from "../../../graphql"
import Select from "../../Select"

import "./relevant-posts-controls.sass"

interface Props {
  dropdownSelections: GraphQL.SuggestionListKeyword[] | GraphQL.SuggestionListImageTag[]
  dropdownSelectionAction: (keywordId: string) => void
  deleteAllAction: (suggestionListKeywordId: string) => Promise<void>
  activeKeyword: string
}

function RelevantPostsTopControls({
  dropdownSelections, dropdownSelectionAction, deleteAllAction, activeKeyword,
}:Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSocialSubTabs" })

  const handleKeywordSelection = (event: SelectChangeEvent) => {
    dropdownSelectionAction(event.target.value)
  }

  return (
    <div className="cp_relevant_posts-top-controls">
      <Select
        id="KeywordsSelect"
        label=""
        value={ activeKeyword }
        displayEmpty={ true }
        labelId="cp_relevant_posts-top-controls-select"
        menuItems={ dropdownSelections.map((option) => (
          <MenuItem
            key={ option.id }
            value={ option.id }
          >
            { option.name }
          </MenuItem>
        )) }
        onChange={ handleKeywordSelection }
      />
      <div className="cp_relevant_posts-top-controls-remove">
        <IconButton onClick={ () => deleteAllAction(activeKeyword) }>
          <DeleteOutlineIcon />
          <p>{ translate("DELETE POSTS") }</p>
        </IconButton>
      </div>
    </div>
  )
}

export default RelevantPostsTopControls

/* eslint-disable max-len */
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { GridColDef, GridRowsProp } from "@mui/x-data-grid"
import { isNumber } from "highcharts"
import * as GraphQL from "../../../../graphql"

import "./native-posts.sass"
import {
  IsToggleEnabled,
  commifyNumber,
  getPaidMediaTableTitle,
  getToggleSettings,
} from "./reportingHelper"
import DataGrid from "../../../DataGrid"
import EmptyElement from "../../../EmptyElement"

interface PaidMediaProps {
  deliverables: GraphQL.DeliverableOverviewFragment[]
  network: string
  toggles: GraphQL.CampaignReportToggleSetting[]
}

type AdInsights = {
  __typename?: "AdInsights",
  group: string,
  impressions: number,
  videoViews?: number | null,
  videoViews2Sec?: number | null,
  videoViews3Sec?: number | null,
  videoViews6Sec?: number | null,
  videoViews30Sec?: number | null,
  linkClicks: number,
  ctr?: number | null,
  vcr?: number | null,
  vtr2Sec?: number | null,
  vtr3Sec?: number | null,
  vtr6Sec?: number | null,
  vtr30Sec?: number | null,
  likes?: number | null,
  comments?: number | null,
  shares?: number | null,
  totalEngagements?: number | null,
  engagementRate?: number | null,
  reach?: number | null,
  text?: string | null
};

function flattenDeliverableRows(deliverables: GraphQL.DeliverableOverviewFragment[]): [GraphQL.DeliverableOverviewFragment, AdInsights][] {
  return deliverables.flatMap((deliverable) => deliverable.adInsights.map((adInsight) => [ deliverable, adInsight ] as [GraphQL.DeliverableOverviewFragment, AdInsights]))
}

export default function RenderPaidMediaTable({
  deliverables, network, toggles,
}: PaidMediaProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReporting" })
  const mappedSettings = getToggleSettings(toggles)
  const isToggleEnabled = new IsToggleEnabled(mappedSettings, network)
  const currNetwork: string = network.toLowerCase()
  const sectionTitle = getPaidMediaTableTitle(currNetwork)

  const deliverableInsights = flattenDeliverableRows(deliverables)

  function isYouTube() {
    return deliverables[0].campaignNetworkAccount.socialAccount.network === GraphQL.Network.Youtube
  }

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "username",
      sortable: false,
      headerName: translate("Username"),
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        const { row } = params
        const { userName } = row.deliverable.campaignNetworkAccount.socialAccount
        return (
          <p className="cp_story-row-username">{ userName }</p>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 190,
    },
    {
      field: "caption",
      headerName: translate("Caption"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { text } = row.insights
        return (
          <p className="cp_cell-paid-media-caption">{ text }</p>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 240,
    },
    {
      field: "targeting",
      headerName: translate("Targeting"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { group } = row.insights
        return (
          <span>{ group }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "description",
      headerName: translate("Description"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        return (
          <span>{ row.deliverable.name }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "impressions",
      headerName: translate("Impressions"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { impressions } = row.insights
        return (
          <span>{ commifyNumber(impressions) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 110,
    },
    {
      field: "video_views__paid_2_sec",
      headerName: translate("Video Views (2s)"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { videoViews2Sec } = row.insights
        if (!isNumber(videoViews2Sec)) return ""
        return (
          <span>{ commifyNumber(videoViews2Sec) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "video_views__paid_3_sec",
      headerName: translate("Video Views (3s)"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { videoViews3Sec } = row.insights
        if (!isNumber(videoViews3Sec)) return ""
        return (
          <span>{ commifyNumber(videoViews3Sec) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "video_views__paid_6_sec",
      headerName: translate("Video Views (6s)"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { videoViews6Sec } = row.insights
        if (!isNumber(videoViews6Sec)) return ""
        return (
          <span>{ commifyNumber(videoViews6Sec) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "video_views__paid_30_sec",
      headerName: translate("Video Views (30s)"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { videoViews30Sec } = row.insights
        if (!isNumber(videoViews30Sec)) return ""
        return (
          <span>{ commifyNumber(videoViews30Sec) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "link_clicks",
      headerName: translate("Link Clicks"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { linkClicks } = row.insights
        if (!isNumber(linkClicks)) return ""
        return (
          <span>{ commifyNumber(linkClicks) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "ctr",
      headerName: translate("CTR"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { ctr } = row.insights
        if (!isNumber(ctr)) return ""
        return (
          <span>{ `${ (ctr * 100).toFixed(2) }%` }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 100,
    },
    {
      field: "vcr",
      headerName: translate("VCR"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { vcr } = row.insights
        if (!isNumber(vcr)) return ""
        return (
          <span>{ `${ (vcr * 100).toFixed(2) }%` }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 100,
    },
    {
      field: "vtr2sec",
      headerName: translate("VTR (2s)"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { vtr2Sec } = row.insights
        if (!isNumber(vtr2Sec)) return ""
        return (
          <span>{ `${ (vtr2Sec * 100).toFixed(2) }%` }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 100,
    },
    {
      field: "vtr3sec",
      headerName: translate("VTR (3s)"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { vtr3Sec } = row.insights
        if (!isNumber(vtr3Sec)) return ""
        return (
          <span>{ `${ (vtr3Sec * 100).toFixed(2) }%` }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 100,
    },
    {
      field: "vtr6sec",
      headerName: translate("FVTR (6s)"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { vtr6Sec } = row.insights
        if (!isNumber(vtr6Sec)) return ""
        return (
          <span>{ `${ (vtr6Sec * 100).toFixed(2) }%` }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 100,
    },
    {
      field: "vtr30Sec",
      headerName: translate("VTR (30s)"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { vtr30Sec } = row.insights
        if (!isNumber(vtr30Sec)) return ""
        return (
          <span>{ `${ (vtr30Sec * 100).toFixed(2) }%` }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 120,
    },
    {
      field: "likes",
      headerName: translate("Likes"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { likes } = row.insights
        if (!isNumber(likes)) return ""
        return (
          <span>{ commifyNumber(likes) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 100,
    },
    {
      field: "comments",
      headerName: translate("Comments"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { comments } = row.insights
        if (!isNumber(comments)) return ""
        return (
          <span>{ commifyNumber(comments) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "shares",
      headerName: translate("Shares"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { shares } = row.insights
        if (!isNumber(shares)) return ""
        return (
          <span>{ commifyNumber(shares) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 100,
    },
    {
      field: "total_engagements",
      headerName: translate("Total Engagements"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { totalEngagements } = row.insights
        if (!isNumber(totalEngagements)) return ""
        return (
          <span>{ commifyNumber(totalEngagements) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 200,
    },
    {
      field: "engagement_rate",
      headerName: translate("Engagement Rate"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { engagementRate } = row.insights
        if (!isNumber(engagementRate)) return ""
        return (
          <span>{ `${ (engagementRate * 100).toFixed(2) }%` }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 180,
    },
  ], [ deliverables ])

  function generateSelectVisualRows(): GridRowsProp {
    const rows = deliverableInsights.map((delArr, indx) => ({
      id: `${ delArr[1].group }_${ indx }`, deliverable: delArr[0], insights: delArr[1],
    }))
    return rows
  }

  if (!deliverables || deliverables.length === 0) return null

  return (
    <div className="cp_report-paid-media-section-content">
      <h2 className="section-heading">{ `${ translate(`${ sectionTitle }`) }` }</h2>
      <div className="cp_report-paid-media-table">
        <DataGrid
          getRowId={ (row) => row.id }
          disableRowSelectionOnClick={ true }
          pinnedColumns={ {
            left: [
              "username",
              "caption",
              "targeting",
            ],
          } }
          rowHeight={ 50 }
          columnHeaderHeight={ 40 }
          columns={ COLUMNS }
          disableColumnReorder={ true }
          hideFooter={ true }
          rows={ generateSelectVisualRows() }
          slots={ {
            noRowsOverlay: EmptyElement,
            noResultsOverlay: EmptyElement,
          } }
          columnVisibilityModel={ {
            caption: !isYouTube(),
            description: isYouTube(),
            impressions: isToggleEnabled.showPaidTotalImpressions() && isToggleEnabled.isMetadataEnabled(),
            video_views__paid_2_sec: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidVideoViews("_2_sec"),
            video_views__paid_3_sec: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidVideoViews("_3_sec"),
            video_views__paid_6_sec: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidVideoViews("_6_sec"),
            video_views__paid_30_sec: isToggleEnabled.isMetadataEnabled() && isYouTube() && isToggleEnabled.showPaidVideoViews("_30_sec"),
            link_clicks: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidMetric("link_clicks"),
            ctr: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidMetric("click_through_rate"),
            vcr: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidMetric("video_completion_rate"),
            vtr2sec: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidMetric("view_through_rate__paid_2_sec"),
            vtr3sec: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidMetric("view_through_rate__paid_3_sec"),
            vtr6sec: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidMetric("view_through_rate__paid_6_sec"),
            vtr30Sec: isToggleEnabled.isMetadataEnabled() && isToggleEnabled.showPaidMetric("view_through_rate__paid_30_sec"),
            likes: isToggleEnabled.isMetadataEnabled() && !isYouTube() && isToggleEnabled.showPaidMetric("likes"),
            comments: isToggleEnabled.isMetadataEnabled() && !isYouTube() && isToggleEnabled.showPaidMetric("comments"),
            shares: isToggleEnabled.isMetadataEnabled() && !isYouTube() && isToggleEnabled.showPaidMetric("shares"),
            total_engagements: isToggleEnabled.isMetadataEnabled() && !isYouTube() && isToggleEnabled.showPaidMetric("total_engagements"),
            engagement_rate: isToggleEnabled.isMetadataEnabled() && !isYouTube() && isToggleEnabled.showPaidMetric("engagement_rate"),
          } }
        />
      </div>
    </div>
  )
}

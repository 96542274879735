import React, { JSX } from "react"

import * as API from "../../util/apiClient"
import AccountDropdown, { AccountDropdownProps } from "./AccountDropdown"
import BreadcrumbHeader from "./BreadcrumbHeader"
import LoadingIndicator from "../LoadingIndicator"
import NewDropdown from "./NewDropdown"
import { Scope } from "../../util/types"
import { useSelector } from "../../state/hooks"

const requiredNewDropdownScopes: string[] = [
  Scope.INFLUENCERS_CREATE,
  Scope.FEATURE_INFLUENCER_LIST,
  Scope.CAMPAIGN_MANAGEMENT,
  Scope.CAMPAIGN_TYPE_OPTIONS,
  Scope.FEATURE_CUSTOMER_MANAGEMENT,
  Scope.COMMUNICATIONS_MANAGEMENT,
  Scope.COMMUNICATIONS_TEMPLATE_MANAGEMENT,
]

export interface TopNavigationProps extends
  Omit<AccountDropdownProps, "user"> {
    title: React.ReactNode
    hasNotifications: boolean
    onNotificationsClick: () => void
  }

function TopNavigation({
  hasNotifications,
  logout,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onNotificationsClick,
  title,
  theme,
  onSupportClick,
  onSettingsClick,
}: TopNavigationProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const badgeVariant: "standard" | "dot" = hasNotifications ? "dot" : "standard"
  const { currentUser, scopes } = useSelector(({ user }) => ({
    currentUser: user.user,
    scopes: user.scopes,
  }))

  function renderUsername(): JSX.Element | null {
    if (!API.isSuccess(currentUser)) return null

    return (
      <p className="cp-component_top-nav-name body_small">
        { currentUser.payload.currentUser?.username }
      </p>
    )
  }

  function renderAccountDropdown(): JSX.Element {
    if (!API.isSuccess(currentUser)) {
      return <div><LoadingIndicator /></div>
    }

    return (
      <AccountDropdown
        user={ {
          userName: currentUser.payload.currentUser?.username || "",
          companyName: currentUser
            .payload.currentUser?.customer.company?.name || "",
          profileImageUrl: currentUser
            .payload.currentUser?.contact.avatar.url.address,
        } }
        theme={ theme }
        logout={ logout }
        onSettingsClick={ onSettingsClick }
        onSupportClick={ onSupportClick }
      />
    )
  }

  return (
    <section className="cp_component_top-nav">
      <BreadcrumbHeader title={ title } />
      <div className="cp-component_top-nav-actions">
        { requiredNewDropdownScopes.some((scope) => scopes.includes(scope)) && (
          <NewDropdown />
        ) }
        { /* NOTE: Notifications are not yet set up. Commenting out for now. */ }
        { /* <IconButton onClick={ onNotificationsClick }>
          <Badge variant={ badgeVariant } className="cp-component_top-nav-notifications">
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton> */ }
        { renderUsername() }
        { renderAccountDropdown() }
      </div>
    </section>
  )
}

export default TopNavigation

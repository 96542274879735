import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { RootState } from "../store"

// Customers Page Slice Interface and Initial State
export interface CustomersPageState {
  customersStatus: Status<GraphQL.SearchCustomersQuery>
  customersByUserStatus: Status<GraphQL.SearchCustomersByUserQuery>
  customersContent: Array<GraphQL.CustomerFragment>
  searchToggle: string
  searchInput: string
  sortDirection: GraphQL.SortDirection.Asc | GraphQL.SortDirection.Desc
  columnSort: GraphQL.SearchCustomerSort
  customersLoading: boolean
}

const initialState: CustomersPageState = {
  customersStatus: "init",
  customersByUserStatus: "init",
  customersContent: [],
  searchToggle: "customer",
  searchInput: "",
  sortDirection: GraphQL.SortDirection.Asc,
  columnSort: GraphQL.SearchCustomerSort.Name,
  customersLoading: false,
}

// Campaign Page Slice
export const CustomersPageSlice = createSlice({
  name: "CustomersSlice",
  initialState,
  reducers: {
    setSearchToggle: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      searchToggle: action.payload,
    }),
    setSearchInput: (state, action: PayloadAction<string>) => ({
      ...state,
      searchInput: action.payload,
    }),
    setCustomersStatus: (state, action: PayloadAction<Status<GraphQL.SearchCustomersQuery>>) => ({
      ...state,
      customersStatus: action.payload,
    }),
    setCustomersByUserStatus: (state, action: PayloadAction<Status<GraphQL.SearchCustomersByUserQuery>>) => ({
      ...state,
      customersByUserStatus: action.payload,
    }),
    setCustomersContent: (state, action: PayloadAction<Array<GraphQL.CustomerFragment>>) => ({
      ...state,
      customersContent: action.payload,
    }),
    setSortDirection: (state, action: PayloadAction<GraphQL.SortDirection>) => ({
      ...state,
      sortDirection: action.payload,
    }),
    setCustomersLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      customersLoading: action.payload,
    }),
    setColumnSort: (state, action: PayloadAction<GraphQL.SearchCustomerSort>) => ({
      ...state,
      columnSort: action.payload,
    }),
  },
})

export const {
  setSearchToggle,
  setSearchInput,
  setCustomersByUserStatus,
  setCustomersContent,
  setCustomersStatus,
  setSortDirection,
  setCustomersLoading,
  setColumnSort,
} = CustomersPageSlice.actions
export default CustomersPageSlice.reducer

export const getCustomers = (
  params: GraphQL.SearchCustomersQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCustomersStatus("loading"))

  const result = await API.fetchCustomers(params)

  dispatch(setCustomersStatus(result))
  if (API.isSuccess(result)) {
    dispatch(setCustomersContent(result.payload.searchCustomers.rows))
  }
}

export const getCustomersByUser = (
  params: GraphQL.SearchCustomersByUserQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCustomersStatus("loading"))

  const result = await API.fetchCustomersByUser(params)

  dispatch(setCustomersByUserStatus(result))
  if (API.isSuccess(result)) {
    dispatch(setCustomersContent(result.payload.searchCustomersByUser.rows))
  }
}

export const getMoreCustomers = (
  params: GraphQL.SearchCustomersByUserQueryVariables,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const {
    customers: {
      customersStatus: currentCustomersStatus,
      customersContent: currentCustomersContent,
    },
  } = getState()
  const result = await API.fetchCustomers(params)
  if (API.isSuccess(currentCustomersStatus) && API.isSuccess(result)) {
    dispatch(setCustomersContent([ ...currentCustomersContent, ...result.payload.searchCustomers.rows ]))
  } else {
    dispatch(setCustomersStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
}

export const getMoreCustomersByUser = (
  params: GraphQL.SearchCustomersByUserQueryVariables,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const {
    customers: {
      customersByUserStatus: currentCustomersByUserStatus,
      customersContent: currentCustomersContent,
    },
  } = getState()
  const result = await API.fetchCustomersByUser(params)
  if (API.isSuccess(currentCustomersByUserStatus) && API.isSuccess(result)) {
    dispatch(setCustomersContent([ ...currentCustomersContent, ...result.payload.searchCustomersByUser.rows ]))
  } else {
    dispatch(setCustomersStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
}

export const toggleColumnSort = (
  selectedColumn: GraphQL.SearchCustomerSort,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const { customers: { columnSort, sortDirection } } = getState()

  if (columnSort === selectedColumn) {
    if (sortDirection === GraphQL.SortDirection.Asc) {
      dispatch(setSortDirection(GraphQL.SortDirection.Desc))
    } else {
      dispatch(setSortDirection(GraphQL.SortDirection.Asc))
    }
  } else {
    dispatch(setColumnSort(selectedColumn))
    dispatch(setSortDirection(GraphQL.SortDirection.Desc))
  }
}

import React, { JSX, useState } from "react"
import { TFunction } from "i18next"
import { useRouteError } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Button from "../../component/Button"
import Modal from "../../component/Modal"
import PageShell from "../../component/PageShell"
import ToastController from "../../controller/Toast"
import { ErrorStatusCode, Scope } from "../../util/types"
import { useSelector } from "../../state/hooks"

import "./style.sass"

type Props = {
  message?: string,
  statusCode?: ErrorStatusCode,
}

function getSplashElement(statusCode?: ErrorStatusCode): JSX.Element {
  switch (statusCode) {
    case 404:
      return <h1 className="headline_splash">404</h1>

    case 500:
      return <h1 className="headline_splash">500</h1>

    default:
      return <div className="cp_page_error-icon-mag" />
  }
}

function getSubtextElement(
  translate: TFunction,
  setErrorMessageModal: (open: boolean) => void,
  statusCode?: ErrorStatusCode,
): JSX.Element {
  switch (statusCode) {
    case 404:
      return (
        <p className="body_large">
          { translate("The page you were looking for doesn't exist") }
        </p>
      )

    case 500:
      return (
        <p className="body_large">
          { translate("There was an issue with your request") }
        </p>
      )

    default:
      return (
        <Button
          label={ translate("More Info") }
          onClick={ () => setErrorMessageModal(true) }
          size="large"
        />
      )
  }
}

function renderError(
  translate: TFunction,
  setErrorMessageModal: (open: boolean) => void,
  statusCode?: ErrorStatusCode,
): JSX.Element {
  return (
    <div className="cp_page_error-content">
      <article>{ getSplashElement(statusCode) }</article>
      <aside>
        <h4>{ translate("Whoops! Something went wrong!") }</h4>
        { getSubtextElement(translate, setErrorMessageModal, statusCode) }
      </aside>
    </div>
  )
}

export default function Error({ statusCode, message }: Props): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Error" })
  const [ errorMessageModal, setErrorMessageModal ] = useState<boolean>(false)
  const routerError: any = useRouteError()
  const { scopes } = useSelector(({ user }) => user)

  return (
    <div className="cp_page_error">
      <ToastController />
      <PageShell
        avoidRedirect={ true }
        showSideNav={ scopes.includes(Scope.REACT_PLATFORM) }
        showTopNav={ false }
        title={ translate("Error") }
      />
      <section className="cp_page_error-container">
        { renderError(translate, setErrorMessageModal, statusCode) }
      </section>
      <Modal
        closeAction={ () => setErrorMessageModal(false) }
        disablePortal={ true }
        hasFooter={ false }
        open={ errorMessageModal }
      >
        <p className="cp_page_error-message">
          {
            message
            || routerError?.message
            || translate("No additional details were provided")
          }
        </p>
      </Modal>
    </div>
  )
}

import React, {
  useEffect,
  useState,
  JSX,
} from "react"
import { useTranslation } from "react-i18next"

import {
  Paper,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { ListMode } from "."
import Divider from "../Divider"
import Select from "../Select"
import * as ComponentHelper from "../../util/componentHelper"
import { DEFAULT_MAX_AGE, DEFAULT_MIN_AGE } from "../../util/constant"
import {
  handleCheckboxUpdate,
  incomeOptions,
  genderOptions,
  ethnictyOptions,
  familyOptions,
  religionOptions,
} from "./displayOptionsHelper"

import "./demographic-score.sass"
import LocationAutocomplete from "./LocationAutocomplete"
import Pill from "../Pill"
import OccupationsAutocomplete, { Occupations } from "./OccupationsAutocomplete"
import AffinitiesAutocomplete, { Affinities } from "./AffinitiesAutocomplete"
import {
  setDemographicScoreEthnicity,
  setDemographicScoreGender,
  setDemographicScoreFamily,
  setDemographicScoreReligion,
  setDemographicScoreIncome,
  setDemographicScoreMinAge,
  setDemographicScoreMaxAge,
  setDemographicScoreLocations,
  setDemographicScoreOccupations,
  setDemographicScoreAffinities,
} from "../../state/listModalFormSlice"

interface Props {
  handleClose: () => void
  mode: ListMode
}

function DemographicScoreForm({
  handleClose, mode,
}: Props) {
  const { t } = useTranslation("common")
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ListFormModal" })

  const dispatch = useDispatch()
  const listModalFormState = useSelector((state) => state.listModalForm)
  const listManagerState = useSelector((state) => state.listFormManager)

  const [ minAgeOptions, setMinAgeOptions ] = useState<string[]>([])
  const [ maxAgeOptions, setMaxAgeOptions ] = useState<string[]>([])
  const [ selectedLocations, setSelectedLocations ] = useState<GraphQL.AudienceLocation[]>([])
  const [ selectedOccupations, setSelectedOccupations ] = useState<Occupations[]>([])
  const [ selectedAffinities, setSelectedAffinities ] = useState<Affinities[]>([])

  useEffect(() => {
    // Populate Affinities for updates
    if (mode.isEditMode && API.isSuccess(listManagerState.originalList)) {
      const {
        demographicScoreAffinities,
        demographicScoreLocations,
        demographicScoreOccupations,
      } = listManagerState.originalList.payload.suggestionListById

      if ((demographicScoreAffinities && demographicScoreAffinities.length)) {
        const existingAffinities = demographicScoreAffinities.map((affinity) => ({
          id: affinity.id, name: affinity.name, code: affinity.code,
        }))
        setSelectedAffinities(existingAffinities)
      }
      if ((demographicScoreLocations && demographicScoreLocations.length)) {
        const prevSelectedLocations = demographicScoreLocations.map((location) => ({
          code: location.code, name: location.name, type: location.type,
        }))
        setSelectedLocations(prevSelectedLocations)
      }
      if ((demographicScoreOccupations && demographicScoreOccupations.length)) {
        const prevSelectedOccupations = demographicScoreOccupations.map(
          (occupation) => ({
            id: occupation.id, name: occupation.name, code: occupation.code,
          }),
        )
        setSelectedOccupations(prevSelectedOccupations)
      }
    }
  }, [ listManagerState.originalList ])

  const generateMinAgeOptions = (upToAge: number) => {
    const minOptions = Array.from(
      { length: upToAge - DEFAULT_MIN_AGE + 1 },
      (_, index) => (DEFAULT_MIN_AGE + index).toString(),
    )
    minOptions.unshift(translate("Min Age"))

    setMinAgeOptions(minOptions)
  }

  const generateMaxAgeOptions = (fromAge: number) => {
    const maxOptions = Array.from(
      { length: DEFAULT_MAX_AGE - fromAge + 1 },
      (_, index) => (fromAge + index).toString(),
    )
    maxOptions.unshift(translate("Max Age"))

    setMaxAgeOptions(maxOptions)
  }

  const handleRemoveLocationTags = (tagName: string): void => {
    const filteredKeywords = selectedLocations
      .filter((tag: GraphQL.AudienceLocation) => (tag.name !== tagName))
    const locationCodes = filteredKeywords.map((location) => location.code)
    dispatch(setDemographicScoreLocations(locationCodes))
    setSelectedLocations(filteredKeywords)
  }

  function renderLocationTagPills(): JSX.Element[] {
    return selectedLocations.map((tag) => (
      <Pill
        key={ tag.name }
        label={ `${ tag.type }: ${ tag.name }` }
        onDelete={ () => handleRemoveLocationTags(tag.name) }
      />
    ))
  }

  const handleLocationUpdates = (locations: GraphQL.AudienceLocation[]) => {
    const locationCodes = locations.map((location) => location.code)
    dispatch(setDemographicScoreLocations(locationCodes))
    setSelectedLocations(locations)
  }

  const handleRemoveOccupationTags = (tagName: string): void => {
    const filteredKeywords = selectedOccupations
      .filter((tag: Occupations) => (tag.name !== tagName))
    const occupationIds = filteredKeywords.map((occupation) => occupation.code)
    dispatch(setDemographicScoreOccupations(occupationIds))

    setSelectedOccupations(filteredKeywords)
  }

  function renderOccupationPills(): JSX.Element[] {
    return selectedOccupations.map((tag) => (
      <Pill
        key={ tag.name }
        label={ tag.name }
        onDelete={ () => handleRemoveOccupationTags(tag.name) }
      />
    ))
  }

  const handleOccupationUpdates = (occupations: Occupations[]) => {
    const occupationIds = occupations.map((occupation) => occupation.code)
    dispatch(setDemographicScoreOccupations(occupationIds))
    setSelectedOccupations(occupations)
  }

  const handleRemoveAffinityTags = (tagName: string): void => {
    const filteredKeywords = selectedAffinities
      .filter((tag: Occupations) => (tag.name !== tagName))
    const affinityIds = filteredKeywords.map((affinity) => affinity.code)
    dispatch(setDemographicScoreAffinities(affinityIds))

    setSelectedAffinities(filteredKeywords)
  }

  function renderAffinityPills(): JSX.Element[] {
    return selectedAffinities.map((tag) => (
      <Pill
        key={ tag.name }
        label={ tag.name }
        onDelete={ () => handleRemoveAffinityTags(tag.name) }
      />
    ))
  }

  const handleAffinitiesUpdate = (affinities: Affinities[]) => {
    const affinityCodes = affinities.map((affinity) => affinity.code)
    dispatch(setDemographicScoreAffinities(affinityCodes))
    setSelectedAffinities(affinities)
  }

  // Min - Max age
  useEffect(() => {
    generateMinAgeOptions(listModalFormState.demographicScoreMaxAge ?? DEFAULT_MIN_AGE)
    generateMaxAgeOptions(listModalFormState.demographicScoreMinAge ?? DEFAULT_MAX_AGE)
  }, [])

  useEffect(() => {
    generateMaxAgeOptions(listModalFormState.demographicScoreMinAge ?? DEFAULT_MIN_AGE)
  }, [ listModalFormState.demographicScoreMinAge ])

  useEffect(() => {
    generateMinAgeOptions(listModalFormState.demographicScoreMaxAge ?? DEFAULT_MAX_AGE)
  }, [ listModalFormState.demographicScoreMaxAge ])

  return (
    <Paper className="cp_demographic_score">
      <div className="cp_demographic_score-wrapper">
        <Button
          className="cp_demographic_score-back_button"
          variant="text"
          onClick={ handleClose }
        >
          { `${ translate("SCORES AND ENGAGEMENT RATE") } /` }
        </Button>
        <h4 className="cp_demographic_score-heading">
          { translate("Demographic Score") }
          <sup className="cp_asterisk">*</sup>
        </h4>
        <p className="cp_demographic_score-additional-info">
          { translate("Demographic score description") }
          <span className="cp_warning-message">
            <sup className="cp_asterisk">*</sup>
            { translate("Demographic score warning") }
          </span>
        </p>
        <Divider />
        <div className="cp_demographic_score-inputs">
          <div className="cp_component_modal-create-list_demographic-wrapper">
            <div className="cp_component_modal-create-list_gender">
              { /* Gender section */ }
              <FormControl component="fieldset" fullWidth={ true }>
                <FormLabel component="legend" className="section-label">
                  { t("Gender") }
                </FormLabel>
                <FormGroup>
                  { genderOptions && (
                    genderOptions.map((option) => (
                      <FormControlLabel
                        key={ option.flag }
                        control={ (
                          <Checkbox
                            checked={ listModalFormState.demographicScoreGender.includes(option.flag) }
                            onChange={ () => {
                              handleCheckboxUpdate<GraphQL.Sex>(
                                option,
                                listModalFormState.demographicScoreGender,
                                (newState) => dispatch(setDemographicScoreGender(newState)),
                              )
                            } }
                            name={ option.flag }
                          />
                    ) }
                        label={ t(`${ option.label }`) }
                      />
                    ))
                  ) }
                </FormGroup>
              </FormControl>
            </div>
            <div className="cp_component_modal-create-list_age">
              { /* Min & Max age section */ }
              <FormControl
                className="age-range"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend" className="section-label">
                  { t("AGE RANGE") }
                </FormLabel>
                <div className="cp_component_modal-create-list_age-fields">
                  <div className="cp_component_modal-create-list_min-age">
                    <FormLabel component="legend">
                      { t("From") }
                    </FormLabel>
                    <Select
                      value={ listModalFormState.demographicScoreMinAge
                        ? `${ listModalFormState.demographicScoreMinAge }`
                        : `${ minAgeOptions[0] }`
                      }
                      onChange={ (e) => dispatch(setDemographicScoreMinAge(Number(e.target.value))) }
                      id="minAge"
                      name="minAge"
                      label=""
                      labelId="minAge"
                      menuItems={
                        ComponentHelper.selectionOptions(minAgeOptions, true)
                      }
                    />
                  </div>
                  <div className="cp_component_modal-create-list_max-age">
                    <FormLabel component="legend">
                      { t("To") }
                    </FormLabel>
                    <Select
                      value={ listModalFormState.demographicScoreMaxAge
                        ? `${ listModalFormState.demographicScoreMaxAge }`
                        : `${ maxAgeOptions[0] }`
                      }
                      onChange={ (e) => dispatch(setDemographicScoreMaxAge(Number(e.target.value))) }
                      id="maxAge"
                      name="maxAge"
                      label=""
                      labelId="maxAge"
                      menuItems={
                        ComponentHelper.selectionOptions(maxAgeOptions, true)
                      }
                    />
                  </div>
                </div>
              </FormControl>
            </div>
          </div>
          <div className="cp_demographic_score-inputs-ethnicity">
            { /* Ethnicity section */ }
            <FormControl component="fieldset" fullWidth={ true }>
              <FormLabel component="legend" className="section-label">
                { t("Ethnicity") }
              </FormLabel>
              <FormGroup>
                { ethnictyOptions && (
                  ethnictyOptions.map((option) => (
                    <FormControlLabel
                      key={ option.flag }
                      control={ (
                        <Checkbox
                          checked={ listModalFormState.demographicScoreEthnicity.includes(option.flag) }
                          onChange={ () => {
                            handleCheckboxUpdate<GraphQL.Ethnicity>(
                              option,
                              listModalFormState.demographicScoreEthnicity,
                              (newState) => dispatch(setDemographicScoreEthnicity(newState)),
                            )
                          } }
                          name={ option.flag }
                        />
                    ) }
                      label={ translate(`${ option.label }`) }
                    />
                  ))
                ) }
              </FormGroup>
            </FormControl>
          </div>
          <div className="cp_demographic_score-inputs-family">
            { /* Ethnicity section */ }
            <FormControl component="fieldset" fullWidth={ true }>
              <FormLabel component="legend" className="section-label">
                { t("Family") }
              </FormLabel>
              <FormGroup>
                { familyOptions && (
                  familyOptions.map((option) => (
                    <FormControlLabel
                      key={ option.flag }
                      control={ (
                        <Checkbox
                          checked={ listModalFormState.demographicScoreFamily.includes(option.flag) }
                          onChange={ () => {
                            handleCheckboxUpdate<GraphQL.Family>(
                              option,
                              listModalFormState.demographicScoreFamily,
                              (newState) => dispatch(setDemographicScoreFamily(newState)),
                            )
                          } }
                          name={ option.flag }
                        />
                    ) }
                      label={ t(`${ option.label }`) }
                    />
                  ))
                ) }
              </FormGroup>
            </FormControl>
          </div>
          <div className="cp_demographic_score-inputs-religion">
            { /* Ethnicity section */ }
            <FormControl component="fieldset" fullWidth={ true }>
              <FormLabel component="legend" className="section-label">
                { t("Religion") }
              </FormLabel>
              <FormGroup>
                { religionOptions && (
                  religionOptions.map((option) => (
                    <FormControlLabel
                      key={ option.flag }
                      control={ (
                        <Checkbox
                          checked={ listModalFormState.demographicScoreReligion.includes(option.flag) }
                          onChange={ () => {
                            handleCheckboxUpdate<GraphQL.Religion>(
                              option,
                              listModalFormState.demographicScoreReligion,
                              (newState) => dispatch(setDemographicScoreReligion(newState)),
                            )
                          } }
                          name={ option.flag }
                        />
                    ) }
                      label={ t(`${ option.label }`) }
                    />
                  ))
                ) }
              </FormGroup>
            </FormControl>
          </div>
          <div className="cp_demographic_score-inputs-income">
            { /* Income section */ }
            <FormControl component="fieldset" fullWidth={ true }>
              <FormLabel component="legend" className="section-label">
                { t("Income") }
              </FormLabel>
              <FormGroup>
                { incomeOptions && (
                  incomeOptions.map((option, indx) => (
                    <FormControlLabel
                      className={ `cp_list-item ${ indx % 3 === 0 ? "cp_list-column" : "" }` }
                      key={ option.flag }
                      control={ (
                        <Checkbox
                          checked={ listModalFormState.demographicScoreIncome.includes(option.flag) }
                          onChange={ () => {
                            handleCheckboxUpdate<GraphQL.IncomeBrackets>(
                              option,
                              listModalFormState.demographicScoreIncome,
                              (newState) => dispatch(setDemographicScoreIncome(newState)),
                            )
                          } }
                          name={ option.flag }
                        />
                    ) }
                      label={ t(`${ option.label }`) }
                    />
                  ))
                ) }
              </FormGroup>
            </FormControl>
          </div>
          <div className="cp_demographic_score-inputs-location">
            <LocationAutocomplete
              selectedLocations={ selectedLocations }
              setSelectedLocations={ handleLocationUpdates }
              inputLabel={ t("Location") }
              notFoundLabel={ translate("Location not found") }
            />
            <div className="cp_component_modal-create-list-switch-pills">
              {
                renderLocationTagPills()
              }
            </div>
          </div>
          <div className="cp_demographic_score-inputs-occupations">
            <OccupationsAutocomplete
              selectedOccupations={ selectedOccupations }
              setSelectedOccupations={ handleOccupationUpdates }
              inputLabel={ t("Occupations") }
              notFoundLabel={ translate("Occupation not found") }
            />
            <div className="cp_component_modal-create-list-switch-pills">
              {
                renderOccupationPills()
              }
            </div>
          </div>
          <div className="cp_demographic_score-inputs-affinities">
            <AffinitiesAutocomplete
              selectedAffinities={ selectedAffinities }
              setSelectedAffinities={ handleAffinitiesUpdate }
              inputLabel={ t("Affinities") }
              notFoundLabel={ translate("Affinity not found") }
            />
            <div className="cp_component_modal-create-list-switch-pills">
              {
                renderAffinityPills()
              }
            </div>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default DemographicScoreForm

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import {
  Err,
  Status,
  Success,
} from "../../util/types"
import { RootState } from "../store"
import { FetchMentionedPostParams } from "../../component/ProfileTabs/AccountInsights/ModalBrandDetails"
import { SearchCampaignFilter, SearchCampaignsQueryVariables } from "../../graphql"

// Profile Slice Interface and Initial State
export interface SocialProfileState {
  profile: Status<GraphQL.GetSocialProfileQuery>,
  audience: Status<GraphQL.AudienceDemographicsQuery>
  mentionedBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  workedWithBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  detectedBrands: Status<GraphQL.GetMentionedSocialAccountsQuery>,
  mentionedPostsStatus: Status<GraphQL.GetMentionedPostsQuery>
  mentionedPostsContent: Array<GraphQL.MentionedPostFragment>
  dailyEngagementRate: Status<GraphQL.GetDailyHourlyEngagementSummaryQuery>,
  campaigns: Status<GraphQL.SearchCampaignsQuery>
  campaignsForAccount: Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>,
  campaignsForProfile: Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>,
  lists: Status<GraphQL.SearchListsQuery>
  listsForAccount: Status<GraphQL.SearchListsBySocialAccountIdsQuery>
  listsForProfile: Status<GraphQL.SearchListsBySocialAccountIdsQuery>
  comms: Status<GraphQL.SearchCommunicationGroupsQuery>
  commsForAccount: Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>
  commsForProfile: Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>
  selectedSocialAccountID: string
  selectedSocialAccountNetwork: GraphQL.Network | null
  selectedListIDs: string[]
  selectedCampaignIDs: string[]
  selectedCommIDs: string[]
  addToModalPhase: null | 1 | 2
  noteInput: string
  profileForNotes: Status<GraphQL.SearchSocialProfileForNotesQuery>
  audienceQualityScore: Status<GraphQL.SocialAccountQuery>
}

const initialState: SocialProfileState = {
  profile: "init",
  audience: "init",
  mentionedBrands: "init",
  workedWithBrands: "init",
  detectedBrands: "init",
  mentionedPostsStatus: "init",
  mentionedPostsContent: [],
  dailyEngagementRate: "init",
  campaigns: "init",
  campaignsForAccount: "init",
  campaignsForProfile: "init",
  lists: "init",
  listsForAccount: "init",
  listsForProfile: "init",
  comms: "init",
  commsForAccount: "init",
  commsForProfile: "init",
  selectedListIDs: [],
  selectedCampaignIDs: [],
  selectedCommIDs: [],
  // TODO: Move state below to more generalized slice.
  // State variables below are shared across List, Campaign, Search,
  // and Comm modal flows
  selectedSocialAccountID: "",
  selectedSocialAccountNetwork: null,
  addToModalPhase: null,
  noteInput: "",
  profileForNotes: "init",
  audienceQualityScore: "init",
}

// Profile Slice
export const socialProfileSlice = createSlice({
  name: "SocialProfile",
  initialState,
  reducers: {
    setSocialProfile: (
      state,
      action: PayloadAction<Status<GraphQL.GetSocialProfileQuery>>,
    ) => ({
      ...state,
      profile: action.payload,
    }),
    setAudience: (
      state,
      action: PayloadAction<Status<GraphQL.AudienceDemographicsQuery>>,
    ) => ({
      ...state,
      audience: action.payload,
    }),
    setMentionedBrands: (
      state,
      action: PayloadAction<Status<GraphQL.GetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      mentionedBrands: action.payload,
    }),
    setWorkedWithBrands: (
      state,
      action: PayloadAction<Status<GraphQL.GetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      workedWithBrands: action.payload,
    }),
    setDetectedBrands: (
      state,
      action: PayloadAction<Status<GraphQL.GetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      detectedBrands: action.payload,
    }),
    setMentionedPostsStatus: (
      state,
      action: PayloadAction<Status<GraphQL.GetMentionedPostsQuery>>,
    ) => ({
      ...state,
      mentionedPostsStatus: action.payload,
    }),
    setMentionedPostsContent: (
      state,
      action: PayloadAction<Array<GraphQL.MentionedPostFragment>>,
    ) => ({
      ...state,
      mentionedPostsContent: action.payload,
    }),
    setDailyEngagementRate: (
      state,
      action: PayloadAction<Status<GraphQL.GetDailyHourlyEngagementSummaryQuery>>,
    ) => ({
      ...state,
      dailyEngagementRate: action.payload,
    }),
    setSelectedSocialAccountID: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      selectedSocialAccountID: action.payload,
    }),
    setSelectedSocialAccountNetwork: (
      state,
      action: PayloadAction<GraphQL.Network | null>,
    ) => ({
      ...state,
      selectedSocialAccountNetwork: action.payload,
    }),
    setCampaigns: (state, action: PayloadAction<Status<GraphQL.SearchCampaignsQuery>>) => ({
      ...state,
      campaigns: action.payload,
    }),
    setCampaignsForAccount: (state, action: PayloadAction<Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>>) => ({
      ...state,
      campaignsForAccount: action.payload,
    }),
    setCampaignsForProfile: (state, action: PayloadAction<Status<GraphQL.SearchCampaignsByNetworkAccountIdsQuery>>) => ({
      ...state,
      campaignsForProfile: action.payload,
    }),
    setLists: (state, action: PayloadAction<Status<GraphQL.SearchListsQuery>>) => ({
      ...state,
      lists: action.payload,
    }),
    setListsForAccount: (state, action: PayloadAction<Status<GraphQL.SearchListsBySocialAccountIdsQuery>>) => ({
      ...state,
      listsForAccount: action.payload,
    }),
    setListsForProfile: (state, action: PayloadAction<Status<GraphQL.SearchListsBySocialAccountIdsQuery>>) => ({
      ...state,
      listsForProfile: action.payload,
    }),
    setComms: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsQuery>>) => ({
      ...state,
      comms: action.payload,
    }),
    setCommsForAccount: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>>) => ({
      ...state,
      commsForAccount: action.payload,
    }),
    setCommsForProfile: (state, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery>>) => ({
      ...state,
      commsForProfile: action.payload,
    }),
    setSelectedListIDs: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedListIDs: action.payload,
    }),
    setSelectedCommIDs: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedCommIDs: action.payload,
    }),
    setSelectedCampaignIDs: (
      state,
      action: PayloadAction<string[]>,
    ) => ({
      ...state,
      selectedCampaignIDs: action.payload,
    }),
    setAddToModalPhase: (
      state,
      action: PayloadAction<null | 1 | 2>,
    ) => ({
      ...state,
      addToModalPhase: action.payload,
    }),
    toggleSelectedList: (
      state,
      action: PayloadAction<string>,
    ) => {
      const indexOfID = state.selectedListIDs.indexOf(action.payload)
      // Remove if already selected
      if (indexOfID !== -1) {
        const newIDs = [ ...state.selectedListIDs ]
        newIDs.splice(indexOfID, 1)
        return {
          ...state,
          selectedListIDs: newIDs,
        }
      }
      // Only add to if not selected and length is < 10
      if (state.selectedListIDs.length < 10) {
        return {
          ...state,
          selectedListIDs: [ ...state.selectedListIDs, action.payload ],
        }
      }
      return state
    },
    toggleSelectedComm: (
      state,
      action: PayloadAction<string>,
    ) => {
      const indexOfID = state.selectedCommIDs.indexOf(action.payload)
      // Remove if already selected
      if (indexOfID !== -1) {
        const newIDs = [ ...state.selectedCommIDs ]
        newIDs.splice(indexOfID, 1)
        return {
          ...state,
          selectedCommIDs: newIDs,
        }
      }
      // Only add to if not selected and length is < 10
      if (state.selectedCommIDs.length < 10) {
        return {
          ...state,
          selectedCommIDs: [ ...state.selectedCommIDs, action.payload ],
        }
      }
      return state
    },
    toggleSelectedCampaign: (
      state,
      action: PayloadAction<string>,
    ) => {
      const indexOfID = state.selectedCampaignIDs.indexOf(action.payload)
      // Remove if already selected
      if (indexOfID !== -1) {
        const newIDs = [ ...state.selectedCampaignIDs ]
        newIDs.splice(indexOfID, 1)
        return {
          ...state,
          selectedCampaignIDs: newIDs,
        }
      }
      if (state.selectedCampaignIDs.length < 10) {
        return {
          ...state,
          selectedCampaignIDs: [ ...state.selectedCampaignIDs, action.payload ],
        }
      }
      return state
    },
    setNoteInput: (state, action: PayloadAction<string>) => ({
      ...state,
      noteInput: action.payload,
    }),
    setSocialProfileForNotes: (state, action: PayloadAction<Status<GraphQL.SearchSocialProfileForNotesQuery>>) => ({
      ...state,
      profileForNotes: action.payload,
    }),
    setAudienceQualityScore: (
      state,
      action: PayloadAction<Status<GraphQL.SocialAccountQuery>>,
    ) => ({
      ...state,
      audienceQualityScore: action.payload,
    }),
    resetAudienceQualityScore: (
      state,
    ) => ({
      ...state,
      audienceQualityScore: "init",
    }),
  },

})

export const {
  setSocialProfile,
  setAudience,
  setMentionedBrands,
  setWorkedWithBrands,
  setDetectedBrands,
  setMentionedPostsStatus,
  setMentionedPostsContent,
  setDailyEngagementRate,
  setCampaigns,
  setLists,
  setComms,
  setSelectedSocialAccountID,
  setSelectedSocialAccountNetwork,
  setSelectedCampaignIDs,
  setSelectedCommIDs,
  setSelectedListIDs,
  setAddToModalPhase,
  toggleSelectedList,
  toggleSelectedCampaign,
  toggleSelectedComm,
  setCampaignsForAccount,
  setCommsForAccount,
  setCommsForProfile,
  setListsForAccount,
  setListsForProfile,
  setCampaignsForProfile,
  setNoteInput,
  setSocialProfileForNotes,
  setAudienceQualityScore,
  resetAudienceQualityScore,
} = socialProfileSlice.actions
export default socialProfileSlice.reducer

// Profile Slice Thunks
export const fetchSocialProfile = (id: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(setSocialProfile("loading"))

  const profileResult = await API.query<
    GraphQL.GetSocialProfileQuery,
    GraphQL.GetSocialProfileQueryVariables
  >(GraphQL.GetSocialProfileDocument, { id })

  dispatch(setSocialProfile(profileResult))
}

export const fetchSocialProfileForNotes = (id: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(setSocialProfileForNotes("loading"))

  const result = await API.fetchSocialProfileForNotes(id)

  dispatch(setSocialProfileForNotes(result))
}

export const fetchAudience = (
  params: Omit<GraphQL.AudienceDemographicsQueryVariables, "type">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setAudience("loading"))

  const result = await API.fetchSocialProfileAudience(params)
  dispatch(setAudience(result))
}

export const setPrimaryEmail = (
  params: GraphQL.SetNetworkAccountPrimaryEmailMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { socialProfile } = getState()

  if (socialProfile.profile === "loading" || socialProfile.profile === "init" || API.isError(socialProfile.profile)) return

  await API.setPrimaryNetworkAccountEmail(params)

  const profileResult = await API.query<
    GraphQL.GetSocialProfileQuery,
    GraphQL.GetSocialProfileQueryVariables
  >(GraphQL.GetSocialProfileDocument, { id: socialProfile.profile.payload.socialAccount.id })

  dispatch(setSocialProfile(profileResult))
}

export const fetchMentionedBrands = (
  params: Omit<GraphQL.GetMentionedSocialAccountsQueryVariables, "type">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setMentionedBrands("loading"))

  const result = await API.getMentionedSocialAccounts({
    ...params,
    type: GraphQL.MentionType.Brand,
  })

  dispatch(setMentionedBrands(result))
}

export const fetchWorkedWithBrands = (
  params: Omit<GraphQL.GetMentionedSocialAccountsQueryVariables, "type">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setWorkedWithBrands("loading"))

  const result = await API.getMentionedSocialAccounts({
    ...params,
    type: GraphQL.MentionType.BrandWorkedWith,
  })

  dispatch(setWorkedWithBrands(result))
}

export const fetchDetectedBrands = (
  params: Omit<GraphQL.GetMentionedSocialAccountsQueryVariables, "type">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDetectedBrands("loading"))

  const result = await API.getMentionedSocialAccounts({
    ...params,
    type: GraphQL.MentionType.BrandLogoDetection,
  })

  dispatch(setDetectedBrands(result))
}

export const getDailyHourlyEngagementRate = (
  variables: GraphQL.GetDailyHourlyEngagementSummaryQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDailyEngagementRate("loading"))

  const result = await API.getDailyHourlyEngagementSummary(variables)

  dispatch(setDailyEngagementRate(result))
}

export const getCampaigns = (
  searchInput: string,
  filter?: SearchCampaignFilter,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCampaigns("loading"))

  const input: SearchCampaignsQueryVariables = {
    startsWith: searchInput,
    column: GraphQL.SearchCampaignSort.CampaignName,
    direction: GraphQL.SortDirection.Desc,
    limit: 200,
  }

  if (filter) input.filter = filter

  const campaignsResults = await API.fetchCampaigns(input)

  dispatch(setCampaigns(campaignsResults))
}

export const getCampaignsForAccount = (
  networkAccountIds: string[],
  networkFilter: GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setCampaignsForAccount("loading"))
  if (!networkFilter) {
    dispatch(setCampaignsForAccount({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCampaignsByNetworkIds({
      networkAccountIds,
      networkFilter,
      limit: 200,
    })

    dispatch(setCampaignsForAccount(results))
  }
}

export const getCampaignsForProfile = (
  networkAccountIds: string[],
  networkFilter: GraphQL.Network | GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setCampaignsForProfile("loading"))
  if (!networkFilter) {
    dispatch(setCampaignsForProfile({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCampaignsByNetworkIds({
      networkAccountIds,
      networkFilter,
      limit: 200,
    })

    dispatch(setCampaignsForProfile(results))
  }
}

export const getLists = (
  searchInput: string,
  network: GraphQL.Network | null,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setLists("loading"))
  if (network === null) {
    dispatch(setLists({ status: "error", message: "Error! Network cannot be null for fetchLists" }))
  } else {
    const listResults = await API.fetchLists({
      startsWith: searchInput,
      networkFilter: [ network ],
    })

    dispatch(setLists(listResults))
  }
}

export const getListsForAccount = (
  socialAccountId: string,
  networkFilter: GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setListsForAccount("loading"))
  if (!networkFilter) {
    dispatch(setListsForAccount({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchListsBySocialAccountIds({
      socialAccountIds: socialAccountId,
      networkFilter,
      page: 1,
      limit: 200,
    })

    dispatch(setListsForAccount(results))
  }
}

export const getListsForProfile = (
  socialAccountIds: string[],
  networkFilter: GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setListsForProfile("loading"))
  if (!networkFilter) {
    dispatch(setListsForProfile({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchListsBySocialAccountIds({
      socialAccountIds,
      networkFilter,
      page: 1,
      limit: 200,
    })

    dispatch(setListsForProfile(results))
  }
}

export const getMentionedPosts = (
  params: FetchMentionedPostParams,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setMentionedPostsStatus("loading"))
  const result = await API.fetchMentionedPosts({
    mentionAccountId: params.socialAccountId,
    networkAccountId: params.socialProfileId,
    type: params.mentionType,
    limit: 50,
    page: params.page,
  })

  if (API.isSuccess(result)) {
    dispatch(setMentionedPostsStatus(result))
    dispatch(setMentionedPostsContent(result.payload.getMentionedCippus.rows))
  } else {
    dispatch(setMentionedPostsStatus({ status: "error", message: "There was an issue with the API request" }))
  }
}

export const getMoreMentionedPosts = (
  params: FetchMentionedPostParams,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const result = await API.fetchMentionedPosts({
    mentionAccountId: params.socialAccountId,
    networkAccountId: params.socialProfileId,
    type: params.mentionType,
    limit: 50,
    page: params.page,
  })

  if (API.isSuccess(result)) {
    const { listSocialProfile: { mentionedPostsContent: currentContent } } = getState()
    dispatch(setMentionedPostsContent([ ...currentContent, ...result.payload.getMentionedCippus.rows ]))
  } else {
    dispatch(setMentionedPostsStatus({ status: "error", message: "There was an issue with the API request" }))
  }
}

export const getComms = (searchInput: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setComms("loading"))

  const commsResults = await API.query<
      GraphQL.SearchCommunicationGroupsQuery,
      GraphQL.SearchCommunicationGroupsQueryVariables
    >(GraphQL.SearchCommunicationGroupsDocument, {
      startsWith: searchInput,
    })
  dispatch(setComms(commsResults))
}

export const getCommsForAccount = (
  networkAccountId: string,
  networkFilter: GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setCommsForAccount("loading"))
  if (!networkFilter) {
    dispatch(setCommsForAccount({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCommunicationsByNetworkAccountIds({
      networkAccountIds: networkAccountId,
      networkFilter,
      page: 1,
      limit: 200,
    })

    dispatch(setCommsForAccount(results))
  }
}

export const getCommsForProfile = (
  networkAccountIds: string[],
  networkFilter: GraphQL.Network[] | null,
) => async (dispatch: Dispatch) => {
  dispatch(setCommsForProfile("loading"))
  if (!networkFilter) {
    dispatch(setCommsForProfile({ status: "error", message: "Invalid network filter!" }))
  } else {
    const results = await API.fetchCommunicationsByNetworkAccountIds({
      networkAccountIds,
      networkFilter,
      page: 1,
      limit: 200,
    })

    dispatch(setCommsForProfile(results))
  }
}

export const submitSocialAccountsToCampaign = async (
  socialAccountID: string,
  campaignIDs: string[],
  onSuccess: () => any,
  onError: () => any,
) => {
  const results = await Promise.all(campaignIDs
    .map((id) => API.createCampaignNetworkAccount({ campaignId: id, networkAccountId: socialAccountID })))

  if (results.some((result) => API.isError(result))) {
    onError()
  } else {
    onSuccess()
  }
}

export const submitSocialAccountsToList = async (
  socialAccountID: string,
  listIDs: string[],
) => Promise.all(
  listIDs.map(
    (id) => API.addSocialAccountToSuggestionList({ suggestionListId: id, hoarderAccountIds: [ socialAccountID ] }),
  ),
)

export const submitSocialAccountsToComms = async (
  socialAccountID: string,
  commIDs: string[],
): Promise<(
  Err |
  Success<GraphQL.CreateCommunicationGroupNetworkAccountsAddModalMutation |
  null>
  )[]> => Promise.all(commIDs.map((id) => API.mutate<
    GraphQL.CreateCommunicationGroupNetworkAccountsAddModalMutation,
    GraphQL.CreateCommunicationGroupNetworkAccountsAddModalMutationVariables
    >(GraphQL.CreateCommunicationGroupNetworkAccountsAddModalDocument, {
      communicationGroupId: id, networkAccountIds: [ socialAccountID ],
    })))

export const createPersonalityNote = async (
  params: GraphQL.CreatePersonalityNoteMutationVariables,
) => (API.createPersonalityNote(params))

export const getAudienceQualityScores = (
  id: string,
) => async (dispatch: Dispatch) => {
  dispatch(setAudienceQualityScore("loading"))
  const response = await API.fetchQualityAudienceScoresSocialProfile(id)
  dispatch(setAudienceQualityScore(response))
}

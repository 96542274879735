/* eslint-disable max-len */
import React from "react"
import { useTranslation } from "react-i18next"
import {
  Typography,
  Card,
  CardMedia,
  IconButton,
} from "@mui/material"
import {
  ChatBubbleOutlineRounded,
  FavoriteBorder,
  OpenInNew,
} from "@mui/icons-material"
import * as GraphQL from "../../../graphql"
import Pill from "../../Pill"
import {
  postDateFormatter,
  prettyPrintDecimal,
  shorthandNumber,
} from "../../../util/miscHelper"

import "./relevant-post-preview.sass"
import SocialAvatar from "../../SocialAvatar"

interface Props {
  classes?: string[]
  tags: GraphQL.SuggestionListKeyword[] | GraphQL.SuggestionListImageTag[]
  activeTag: string
  posts: GraphQL.PostFragment[]
  dropdownSelectionAction: (keywordId: string) => void
}

function RelevantPostsPreview({
  classes, tags, activeTag, posts, dropdownSelectionAction,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSocialSubTabs" })
  const wrapperClasses = `cp_relevant_posts_preview${ classes?.length ? ` ${ classes.join(" ") }` : "" }`
  const hasPosts = (Array.isArray(posts) && posts.length)

  return (
    <div className={ wrapperClasses }>
      <Typography>
        { translate("Posts Relevant to") }
      </Typography>
      <div className="cp_relevant_posts_preview-pills">
        { tags && (
          tags.map((tag) => (
            <Pill
              key={ tag.id }
              className={ `${ tag.id === activeTag ? "active-pill" : "" }` }
              label={ tag.name }
              onClick={ () => dropdownSelectionAction(tag.id) }
            />
          ))
        ) }
      </div>
      { hasPosts ? (
        posts.map((post) => {
          const { socialAccount } = post
          const socialStats = { ...socialAccount.socialAccountStatistics }
          const { emails, emailsSourcedFromTeam } = socialAccount
          const thumbnailUrl = post.postMedia[0]?.thumbnailUrl || ""
          const mediaUrl = post.postMedia[0]?.mediaUrl || ""
          const isUnsubscribed = [ ...emails, ...emailsSourcedFromTeam ].some((email) => email.unsubscribedTags.length > 0)
          return (
            <article key={ post.id } className="cp_relevant_posts_preview-post">
              <section className="cp_relevant_posts_preview-post-header">
                <SocialAvatar
                  profilePictureUrl={ socialAccount.profilePictureUrl }
                  network={ socialAccount.network }
                  followers={ socialStats.followers }
                  fullName={ socialAccount.name || "" }
                  isBlacklisted={ socialAccount.personality?.blacklist || false }
                  isNSFW={ socialAccount.isNsfw }
                  isPlaceholder={ socialAccount.isPlaceholder }
                  isUnsubscribed={ isUnsubscribed }
                  username={ socialAccount.userName }
                />
                <IconButton onClick={ () => window.open(`${ post.permalink }`, "_blank") }>
                  <OpenInNew />
                </IconButton>
              </section>
              <section className="cp_relevant_posts_preview-post-media">
                <Card className="cp_card-media" elevation={ 0 }>
                  <CardMedia
                    image={ mediaUrl || thumbnailUrl }
                  />
                </Card>
                <aside className="additional-info">
                  <Typography className="posted-time">{ `${ postDateFormatter(post.postedTime) }` }</Typography>
                  <Typography className="post-content">
                    { post.postContent }
                  </Typography>
                </aside>
              </section>
              <section className="cp_relevant_posts_preview-post-socials-stats">
                <div>
                  <span className="likes">
                    { post.postComments
                        && (
                          <>
                            <FavoriteBorder />
                            { `${ shorthandNumber(post.postLikes) }` }
                          </>
                        )
                      }
                  </span>
                  <span className="comments">
                    { post.postComments
                        && (
                          <>
                            <ChatBubbleOutlineRounded />
                            { `${ shorthandNumber(post.postComments) }` }
                          </>
                        )
                      }
                  </span>
                </div>
                <Typography>
                  <span>{ `${ translate("ENGAGEMENT RATE") }: ` }</span>
                  { `${ prettyPrintDecimal(post.engagementRate, 2) }%` }
                </Typography>
              </section>
            </article>
          )
        })
      ) : null }
    </div>
  )
}

export default RelevantPostsPreview

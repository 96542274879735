import React, { JSX } from "react"

import LoadingIndicator from "../../component/LoadingIndicator"

import "./style.sass"

export default function Loading(): JSX.Element {
  return (
    <div className="cp_page_loading">
      <LoadingIndicator size={ 62 } />
    </div>
  )
}

import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import {
  Autocomplete,
  debounce,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material"
import * as API from "../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import Input, { Props as InputProps } from "../Input"
import LoadingIndicator from "../LoadingIndicator"

interface Props {
  setSelectedImageTags: (imageTag: string[]) => void
  selectedImageTags: string[],
  inputLabel: string,
  notFoundLabel: string,
}

function ImageTagsAutocomplete({
  setSelectedImageTags,
  selectedImageTags,
  inputLabel,
  notFoundLabel,
}:Props) {
  const [ imageTags, setImageTags ] = useState<string[]>([])

  // Image Tags
  const searchImageTags = async (startsWith: string | null) => {
    const result = await API.fetchImageTags(startsWith)

    if (API.isSuccess(result)) {
      setImageTags(result.payload.searchImageTags.rows)
    } else {
      setImageTags([ notFoundLabel ])
    }
  }

  const debouncedSearchVerticals = useCallback(debounce((e, inputValue) => {
    searchImageTags(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchImageTags ])

  const onAutoCompleteChange = (event: React.SyntheticEvent, changeImageTags: string[]) => {
    // remove the add keyword in filtered options
    const cleanedImageTags = changeImageTags.map((imageTag) => {
      // eslint-disable-next-line quotes
      const newVerticalSubstring = `Add: "`
      if (!imageTag.includes(newVerticalSubstring)) return imageTag
      const cleanedVerticalName = imageTag.replace(newVerticalSubstring, "").slice(0, -1)
      return cleanedVerticalName
    })
    setSelectedImageTags(cleanedImageTags)
  }

  const filter = createFilterOptions<string>()

  const autoCompleteFilter = (autoCompleteImageTags: string[], params: FilterOptionsState<string>) => {
    const { inputValue } = params

    // Suggest the creation of a new value
    const isExisting = autoCompleteImageTags.some((tag) => inputValue.toLowerCase() === tag.toLowerCase())

    const filtered = filter(autoCompleteImageTags, params)
      .filter((tag) => !selectedImageTags.some((selectedTag) => tag === selectedTag))

    if (inputValue && !isExisting) filtered.push(`Add: "${ inputValue }"`)

    return filtered
  }

  useEffect(() => {
    searchImageTags(null)
  }, [])
  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedImageTags }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (imagetag) => imagetag }
      ListboxProps={ {
        className: "cp_component_autocomplete_imagetags-list",
      } }
      onInputChange={ debouncedSearchVerticals }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      filterOptions={ autoCompleteFilter }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="autocomplete-imagetags"
      options={ [ ...imageTags ].sort() }
      loading={ false }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, imageTag, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ imageTag }
          >
            { imageTag }
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          label={ inputLabel }
        />
      )
}
    />
  )
}

export default ImageTagsAutocomplete

/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { TFunction } from "i18next"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"
import { pushToast } from "../toastSlice"

// List Group Slice Interface and Initial State
export interface ListAddAccount {
  showAccountSearch: boolean
  selectedAccounts: string[]
  addAccountStatus: Status<GraphQL.SuggestionListSocialAccountAddMutation | null>
}

const initialState: ListAddAccount = {
  showAccountSearch: false,
  selectedAccounts: [],
  addAccountStatus: "init",
}

// List Group Slice
export const ListAddAccountSlice = createSlice({
  name: "ListAddAccount",
  initialState,
  reducers: {
    toggleShowAcountSearch: (
      state,
    ) => {
      state.showAccountSearch = !state.showAccountSearch
    },
    setSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.selectedAccounts = action.payload
    },
    setAddAccountStatus: (state, action: PayloadAction<Status<GraphQL.SuggestionListSocialAccountAddMutation | null>>) => {
      state.addAccountStatus = action.payload
    },
  },
})

export const {
  toggleShowAcountSearch, setSelectedAccounts, setAddAccountStatus,
} = ListAddAccountSlice.actions
export default ListAddAccountSlice.reducer

export const submitAccountsToList = async (
  suggestionListId: string,
  suggestionListCategoryId: GraphQL.InputMaybe<string> | undefined,
  hoarderAccountIds: string | string[],
  translate: TFunction,
) => async (dispatch: Dispatch) => {
  dispatch(setAddAccountStatus("loading"))
  const response = await API.addAccountToSuggestionList({
    suggestionListId, suggestionListCategoryId, hoarderAccountIds,
  })
  if (API.isSuccess(response)) {
    dispatch(setAddAccountStatus(response))
    pushToast({
      type: "success",
      message: translate("Success!"),
      additionalInfo: translate("The selected account(s) were added to the list!"),
    })(dispatch)
  } else if (API.isError(response)) {
    dispatch(setAddAccountStatus(response))
    pushToast({
      type: "error",
      message: translate("Error"),
      additionalInfo: translate("The selected account(s) have not been added to the list!"),
    })(dispatch)
  }
}

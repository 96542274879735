import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import ArrowBack from "@mui/icons-material/ArrowBackIos"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import Button from "../../component/Button"
import Input from "../../component/Input"
import LoadingIndicator from "../../component/LoadingIndicator"
import LoginFlowCustomer from "../../component/LoginFlowCustomer"
import LoginFlowLogo from "../../component/LoginFlowLogo"
import { fetchAlternateLoginFlowsForVanity } from "../../util/apiClient"
import { fetchTeamVanityWithRedirect, setTeam } from "../../state/teamSlice"
import { login } from "../../state/userSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

const vanity = "influential"

export default function AdminLogin(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Login" })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loginAttempt = useSelector(({ user }) => user.loginAttempt)
  const team = useSelector(({ team: t }) => t.team)

  const [ username, setUsername ] = useState<string>("")
  const [ password, setPassword ] = useState<string>("")
  const [ targetCustomerCode, setTargetCustomerCode ] = useState<string>(vanity)

  const loading = !API.isSuccess(team) && !API.isError(team)

  const loginFlows = API.isSuccess(team) ? team.payload.vanity.loginFlows : undefined

  const legacyEnabled = !!loginFlows?.legacyEnabled
  const ssoEnabled = !!loginFlows?.ssoRedirectUrl
  const hostedUiEnabled = !!loginFlows?.hostedUiRedirectUrl

  const onlySsoEnabled = ssoEnabled && !legacyEnabled && !hostedUiEnabled
  const onlyHostedUiEnabled = hostedUiEnabled && !legacyEnabled && !ssoEnabled

  useEffect(() => {
    if (!API.isSuccess(team)) {
      dispatch(fetchTeamVanityWithRedirect(vanity || "", navigate))
    }
  }, [])

  const handleSignInWithSsoClick = async () => {
    const result = await fetchAlternateLoginFlowsForVanity(vanity, targetCustomerCode)

    if (API.isSuccess(result)) {
      const { ssoRedirectUrl } = result.payload.vanity.loginFlowsForAlternateCustomer!

      window.location.href = ssoRedirectUrl!
    }
  }

  const handleSignInWithHostedUiClick = async () => {
    const result = await fetchAlternateLoginFlowsForVanity(vanity, targetCustomerCode)

    if (API.isSuccess(result)) {
      const { hostedUiRedirectUrl } = result.payload.vanity.loginFlowsForAlternateCustomer!

      window.location.href = hostedUiRedirectUrl!
    }
  }

  return (
    <div className="cp_page_admin-login">
      <div>
        <section className="card">
          <div className="back">
            <ArrowBack />
            <Link className="large" onClick={ () => dispatch(setTeam("init")) } to="/find-team">
              { translate("Enter Team URL") }
            </Link>
          </div>

          <LoginFlowLogo />

          <h1 className="headline">{ translate("Welcome Back") }</h1>
          <p className="label_medium">
            { translate("Welcome back! Enter the customer you'd like to sign into as well as your credentials below") }
          </p>

          <Input
            className="targetCustomerCode"
            onChange={ (e) => setTargetCustomerCode(e.target.value) }
            placeholder={ translate("Which customer do you want to sign in to?") }
            value={ targetCustomerCode }
          />

          { !loading && legacyEnabled && (
            <>
              <LoginFlowCustomer />
              <Input
                className="username"
                InputProps={ {
                  inputProps: { maxLength: Constant.USERNAME_MAX_LENGTH },
                } }
                onChange={ (e) => setUsername(e.target.value) }
                placeholder={ translate("Email or Username") }
                value={ username }
              />
              <Input
                InputProps={ {
                  inputProps: { maxLength: Constant.PASSWORD_MAX_LENGTH },
                } }
                onChange={ (e) => setPassword(e.target.value) }
                placeholder={ translate("Password") }
                type="password"
                value={ password }
              />
              { API.isError(loginAttempt) && <span>{ translate("Incorrect login information") }</span> }
              <aside>
                <Link to={ `/${ vanity }/forgot-password` }>{ translate("Forgot Password") }</Link>
                <Link to={ `/${ vanity }/login/email` }>{ translate("Sign in with Email") }</Link>
              </aside>
              <Button
                disabled={
                  username.length === 0
                  || password.length === 0
                  || targetCustomerCode.length === 0
                  || loginAttempt === "loading"
                }
                label={ loginAttempt === "loading" ? <LoadingIndicator size={ 24 } /> : translate("Sign In") }
                onClick={ () => dispatch(login(targetCustomerCode, username, password, navigate)) }
                tabIndex={ 0 }
              />
            </>
          ) }

          { !loading && ssoEnabled && !onlySsoEnabled && (
            <Button label={ translate("Sign In with SSO") } onClick={ handleSignInWithSsoClick } />
          ) }
          { !loading && hostedUiEnabled && !onlyHostedUiEnabled && (
            <Button label={ translate("Sign In with Username and Password") } onClick={ handleSignInWithHostedUiClick } />
          ) }
        </section>
      </div>
    </div>
  )
}

import React from "react"
import {
  Popper,
  PopperProps,
  AutocompleteProps as MuiAutocompleteProps,
  Autocomplete as MuiAutocomplete,
} from "@mui/material"
import "./style.sass"

export interface AutocompleteProps<T> extends MuiAutocompleteProps<T, boolean, boolean, boolean>{}

// Custom Popper component with a custom class
function CustomPopper(props: PopperProps) {
  return <Popper { ...props } className="cp_autocomplete-dropdown" />
}

function Autocomplete<T>(props: AutocompleteProps<T>): React.JSX.Element {
  const classNames = [ "cp_autocomplete", props.className ].filter(Boolean).join(" ")

  return (
    <MuiAutocomplete
      PopperComponent={ CustomPopper }
      { ...props }
      className={ classNames }
    />
  )
}

export default Autocomplete

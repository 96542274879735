import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

// Campaign Modal Slice Interface and Initial State
export interface CopyLinkModalState {
  open: boolean,
  link?: string,
  title?: string,
  subtitle?: string
}

const initialState: CopyLinkModalState = {
  open: false,
}

// Deliverable Modal Slice
export const CopyLinkModalSlice = createSlice({
  name: "CopyLinkModalSlice",
  initialState,
  reducers: {
    openCopyLinkModal: (
      state,
      action: PayloadAction<{
        link: string,
        title: string,
        subtitle?: string
      }>,
    ) => ({
      ...state,
      open: true,
      link: action.payload.link,
      title: action.payload.title,
      subtitle: action.payload.subtitle,
    }),
    closeCopyLinkModal: (
      state,
    ) => ({
      ...state,
      open: false,
      link: undefined,
      title: undefined,
      subtitle: undefined,
    }),
  },
})

export const {
  closeCopyLinkModal,
  openCopyLinkModal,
} = CopyLinkModalSlice.actions
export default CopyLinkModalSlice.reducer

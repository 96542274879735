import * as React from "react"
import SettingsIcon from "@mui/icons-material/Settings"
import { useTranslation } from "react-i18next"
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import LoadingIndicator from "../LoadingIndicator"
import { ListCategory } from "../../util/types"
import { useDispatch } from "../../state/hooks"
import {
  openRenameCategoryModal,
  openRemoveCategoryModal,
  reorderCategory,
} from "../../state/listSlice"

import "./category-management-toolbar.sass"

interface CategoryManagementToolbarProps {
  category: ListCategory,
  categories: ListCategory[],
  index: number,
}

export default function CategoryManagementToolbar({
  category, index, categories,
}: CategoryManagementToolbarProps) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const [ loading, setLoading ] = React.useState(false)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CategoryManagementToolbar" })
  const dispatch = useDispatch()

  const moveCategory = async (newIndex: number) => {
    setAnchorEl(null)
    setLoading(true)
    await dispatch(reorderCategory({
      suggestionListCategoryReorderOrdinalId: category.id,
      index: newIndex,
    }, index))
    setLoading(false)
  }

  const showMoveUpOption = index > 1
  const showMoveDownOption = index !== categories.length - 1

  return (
    <div className="cp_category-management-toolbar_component">
      <div className="cp_category-management-toolbar_component-header">
        <p className="cp_category-management-toolbar_component-header-title">
          { category.name }
        </p>
        <div className="cp_category-management-toolbar_component-header-controls">
          <IconButton size="small" onClick={ ({ currentTarget }) => setAnchorEl(currentTarget) }>
            { loading ? <LoadingIndicator /> : <SettingsIcon /> }
          </IconButton>
          <Menu
            anchorEl={ anchorEl }
            open={ Boolean(anchorEl) }
            onClose={ () => setAnchorEl(null) }
            className="cp_category-management-toolbar_component-menu-popover"
          >
            <MenuList
              dense={ true }
              className="cp_category-management-toolbar_component-menu"
            >
              <MenuItem
                className="cp_category-management-toolbar_component-menu-item"
                onClick={ () => dispatch(openRenameCategoryModal(category)) }
              >
                <ListItemText className="cp_category-management-toolbar_component-menu-item-label">
                  { translate("Rename Category") }
                </ListItemText>
                <EditOutlinedIcon fontSize="small" />
              </MenuItem>
              { showMoveUpOption && (
              <MenuItem
                className="cp_category-management-toolbar_component-menu-item"
                onClick={ () => moveCategory(index - 1) }
              >
                <ListItemText className="cp_category-management-toolbar_component-menu-item-label">
                  { translate("Move Category Up") }
                </ListItemText>
              </MenuItem>
              ) }
              { showMoveDownOption && (
              <MenuItem
                className="cp_category-management-toolbar_component-menu-item"
                onClick={ () => moveCategory(index + 1) }
              >
                <ListItemText className="cp_category-management-toolbar_component-menu-item-label">
                  { translate("Move Category Down") }
                </ListItemText>
              </MenuItem>
              ) }
              <MenuItem
                className="cp_category-management-toolbar_component-menu-item"
                onClick={ () => {
                  dispatch(openRemoveCategoryModal(category))
                  setAnchorEl(null)
                } }
              >
                <ListItemText className="cp_category-management-toolbar_component-menu-item-label">
                  { translate("Delete Category") }
                </ListItemText>
                <DeleteOutlineOutlinedIcon fontSize="small" />
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
    </div>
  )
}

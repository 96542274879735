import * as GraphQL from "../../../../graphql"
import { CAMPAIGN_REPORTING_TEMPLATES } from "../../../../util/constant"
import { MapAllToggles, CampaignReportUpdate } from "../../../../util/types"

/*
*
*** Editing Report ***
*
*/

export const metricsNativeTabularData = [
  [ "Total Gross Impressions",
    "facebook-metrics_native-total_impressions__gross",
    "instagram-metrics_native-total_impressions__gross",
    "tiktok-metrics_native-total_impressions__gross",
    "youtube-metrics_native-total_impressions__gross",
  ],
  [ "True Impressions",
    "facebook-metrics_native-total_impressions__true",
    "instagram-metrics_native-total_impressions__true",
    null,
    null,
  ],
  [ "Reach (Posts)",
    "facebook-metrics_native-reach",
    "instagram-metrics_native-reach",
    "tiktok-metrics_native-reach",
    null,
  ],
  [ "Likes (Posts)",
    "facebook-metrics_native-likes",
    "instagram-metrics_native-likes",
    "tiktok-metrics_native-likes",
    "youtube-metrics_native-likes",
  ],
  [ "Shares (Posts)",
    "facebook-metrics_native-shares",
    "instagram-metrics_native-shares",
    "tiktok-metrics_native-shares",
    "youtube-metrics_native-shares",
  ],
  [ "Saves (Posts)",
    "facebook-metrics_native-saves",
    "instagram-metrics_native-saves",
    "tiktok-metrics_native-saves",
    "youtube-metrics_native-saves",
  ],
  [ "Comments (Posts)",
    "facebook-metrics_native-comments",
    "instagram-metrics_native-comments",
    "tiktok-metrics_native-comments",
    "youtube-metrics_native-comments",
  ],
  [ "Total Engagements",
    "facebook-metrics_native-total_engagements",
    "instagram-metrics_native-total_engagements",
    "tiktok-metrics_native-total_engagements",
    "youtube-metrics_native-total_engagements",
  ],
  [ "Engagement Rate (Over Gross Imp.)",
    "facebook-metrics_native-engagement_rate",
    "instagram-metrics_native-engagement_rate",
    "tiktok-metrics_native-engagement_rate",
    "youtube-metrics_native-engagement_rate",
  ],
  [ "Video Engagement Rate (Over Views)",
    "facebook-metrics_native-view_engagement_rate",
    "instagram-metrics_native-view_engagement_rate",
    "tiktok-metrics_native-view_engagement_rate",
    "youtube-metrics_native-view_engagement_rate",
  ],
  [ "Static Engagement Rate (Over True Imp.)",
    "facebook-metrics_native-engagement_rate__static",
    "instagram-metrics_native-engagement_rate__static",
    null,
    null,
  ],
  [ "Video Views (Paid)",
    null,
    null,
    "tiktok-metrics_native-video_views__paid",
    null,
  ],
  [ "Video Views (Organic)",
    null,
    null,
    "tiktok-metrics_native-video_views__native",
    null,
  ],
  [ "Video Views",
    "facebook-metrics_native-video_views",
    "instagram-metrics_native-video_views",
    "tiktok-metrics_native-video_views",
    "youtube-metrics_native-video_views",
  ],
  [ "Story Views",
    null,
    "instagram-metrics_native-story_views",
    null,
    null,
  ],
  [ "Story Sticker Taps",
    null,
    "instagram-metrics_native-story_sticker_taps",
    null,
    null,
  ],
  [ "Story Link Clicks",
    null,
    "instagram-metrics_native-story_link_clicks",
    null,
    null,
  ],
  [ "Story Likes",
    null,
    "instagram-metrics_native-story_likes",
    null,
    null,
  ],
  [ "Story Shares",
    null,
    "instagram-metrics_native-story_shares",
    null,
    null,
  ],
  [ "Story Replies",
    null,
    "instagram-metrics_native-story_replies",
    null,
    null,
  ],
  [ "Story Click-Through Rate",
    null,
    "instagram-metrics_native-story_click_through_rate",
    null,
    null,
  ],

]

export const metricsPaidTabularData = [
  [ "TOTAL IMPRESSIONS",
    "meta-metrics_paid-total_impressions",
    "tiktok-metrics_paid-total_impressions",
    "youtube-metrics_paid-total_impressions",
  ],
  [ "TOTAL ENGAGEMENTS",
    "meta-metrics_paid-total_engagements",
    "tiktok-metrics_paid-total_engagements",
    null,
  ],
  [ "LIKES",
    "meta-metrics_paid-likes",
    "tiktok-metrics_paid-likes",
    null,
  ],
  [ "COMMENTS",
    "meta-metrics_paid-comments",
    "tiktok-metrics_paid-comments",
    null,
  ],
  [ "SHARES",
    "meta-metrics_paid-shares",
    "tiktok-metrics_paid-shares",
    null,
  ],
  [ "ENGAGEMENT RATE",
    "meta-metrics_paid-engagement_rate",
    "tiktok-metrics_paid-engagement_rate",
    null,
  ],
  [ "VIDEO VIEWS (3 SECONDS)",
    "meta-metrics_paid-video_views__paid_3_sec",
    null,
    null,
  ],
  [ "VIDEO VIEWS (2 SECONDS)",
    null,
    "tiktok-metrics_paid-video_views__paid_2_sec",
    null,
  ],
  [ "VIDEO VIEWS (6 SECONDS - FOCUSED)",
    null,
    "tiktok-metrics_paid-video_views__paid_6_sec",
    null,
  ],
  [ "VIDEO VIEWS (30 SECONDS)",
    null,
    null,
    "youtube-metrics_paid-video_views__paid_30_sec",
  ],
  [ "VIDEO COMPLETION RATE",
    "meta-metrics_paid-video_completion_rate",
    "tiktok-metrics_paid-video_completion_rate",
    "youtube-metrics_paid-video_completion_rate",
  ],
  [ "VIEW-THROUGH RATE (30 SEC)",
    null,
    null,
    "youtube-metrics_paid-view_through_rate__paid_30_sec",
  ],
  [ "VIEW-THROUGH RATE (3 SEC)",
    "meta-metrics_paid-view_through_rate__paid_3_sec",
    null,
    null,
  ],
  [ "VIEW-THROUGH RATE (2 SEC)",
    null,
    "tiktok-metrics_paid-view_through_rate__paid_2_sec",
    null,
  ],
  [ "FOCUSED VIEW-THROUGH RATE (6 SEC)",
    null,
    "tiktok-metrics_paid-view_through_rate__paid_6_sec",
    null,
  ],
  [ "LINK CLICKS",
    "meta-metrics_paid-link_clicks",
    "tiktok-metrics_paid-link_clicks",
    "youtube-metrics_paid-link_clicks",
  ],
  [ "CLICK-THROUGH RATE",
    "meta-metrics_paid-click_through_rate",
    "tiktok-metrics_paid-click_through_rate",
    "youtube-metrics_paid-click_through_rate",
  ],
]

export const metricsConversionTabularData = [
  [ "PURCHASES",
    "meta-metrics_conversion-purchases",
    "tiktok-metrics_conversion-purchases",
  ],
  [ "PURCHASE RATE",
    "meta-metrics_conversion-purchase_rate",
    "tiktok-metrics_conversion-purchase_rate",
  ],
  [ "PURCHASE REVENUE",
    "meta-metrics_conversion-purchase_revenue",
    "tiktok-metrics_conversion-purchase_revenue",
  ],
  [ "SUBSCRIPTIONS",
    "meta-metrics_conversion-subscription",
    "tiktok-metrics_conversion-subscription",
  ],
  [ "SUBSCRIPTION RATE",
    "meta-metrics_conversion-subscription_rate",
    "tiktok-metrics_conversion-subscription_rate",
  ],
  [ "REGISTRATION COMPLETED",
    "meta-metrics_conversion-registration_completed",
    "tiktok-metrics_conversion-registration_completed",
  ],
  [ "REGISTRATION COMPLETED RATE",
    "meta-metrics_conversion-registration_completed_rate",
    "tiktok-metrics_conversion-registration_completed_rate",
  ],
  [ "ADD TO CART",
    "meta-metrics_conversion-add_to_cart",
    "tiktok-metrics_conversion-add_to_cart",
  ],
  [ "ADD TO CART RATE",
    "meta-metrics_conversion-add_to_cart_rate",
    "tiktok-metrics_conversion-add_to_cart_rate",
  ],
  [ "LANDING PAGE VIEWS",
    "meta-metrics_conversion-landing_page_views",
    "tiktok-metrics_conversion-landing_page_views",
  ],
  [ "LANDING PAGE VIEW RATE",
    "meta-metrics_conversion-landing_page_view_rate",
    "tiktok-metrics_conversion-landing_page_view_rate",
  ],
  [ "TRIALS STARTED",
    "meta-metrics_conversion-trials_started",
    "tiktok-metrics_conversion-trials_started",
  ],
  [ "TRIALS STARTED RATE",
    "meta-metrics_conversion-trials_started_rate",
    "tiktok-metrics_conversion-trials_started_rate",
  ],
  [ "APP INSTALLS",
    "meta-metrics_conversion-app_installs",
    "tiktok-metrics_conversion-app_installs",
  ],
  [ "APP INSTALL RATE",
    "meta-metrics_conversion-app_install_rate",
    "tiktok-metrics_conversion-app_install_rate",
  ],
  [ "CHECKOUT INITIATED",
    "meta-metrics_conversion-checkout_initiated",
    "tiktok-metrics_conversion-checkout_initiated",
  ],
  [ "CHECKOUT INITIATED RATE",
    "meta-metrics_conversion-checkout_initiated_rate",
    "tiktok-metrics_conversion-checkout_initiated_rate",
  ],
  [ "LEADS",
    "meta-metrics_conversion-leads",
    "tiktok-metrics_conversion-leads",
  ],
  [ "ROAS (RETURN ON AD SPEND)",
    "meta-metrics_conversion-roas",
    "tiktok-metrics_conversion-roas",
  ],
  [ "CPA (COST PER ACQUISITION)",
    "meta-metrics_conversion-cpa",
    "tiktok-metrics_conversion-cpa",
  ],
  [ "VIEW CONTENT",
    "meta-metrics_conversion-view_content",
    "tiktok-metrics_conversion-view_content",
  ],
  [ "VIEW CONTENT RATE",
    "meta-metrics_conversion-view_content_rate",
    "tiktok-metrics_conversion-view_content_rate",
  ],
  [ "COMPLETE PAYMENT",
    null,
    "tiktok-metrics_conversion-complete_payment",
  ],
  [ "COMPLETE PAYMENT RATE",
    null,
    "tiktok-metrics_conversion-complete_payment_rate",
  ],
]

export const metricsMediaPostsPaidTabularData = [
  [ "SHOW POSTS",
    "meta-media_posts_paid-show_posts",
    "tiktok-media_posts_paid-show_posts",
    "youtube-media_posts_paid-show_posts",
  ],
  [ "SHOW POST METRICS",
    "meta-media_posts_paid-show_post_metadata",
    "tiktok-media_posts_paid-show_post_metadata",
    "youtube-media_posts_paid-show_post_metadata",
  ],
]

export const metricsMediaPostsNativeTabularData = [
  [ "SHOW POSTS",
    "facebook-media_posts_native-show_posts",
    "instagram-media_posts_native-show_posts",
    "tiktok-media_posts_native-show_posts",
    "youtube-media_posts_native-show_posts",
  ],
  [ "SHOW POST METRICS",
    "facebook-media_posts_native-show_post_metadata",
    "instagram-media_posts_native-show_post_metadata",
    "tiktok-media_posts_native-show_post_metadata",
    "youtube-media_posts_native-show_post_metadata",
  ],
]

export const metricsTrendsTabularData = [
  [ "By Influencer",
    "all-trends_by_influencer-enabled",
  ],
  [ "By Deliverable",
    "all-trends_by_deliverable-enabled",
  ],
  [ "By Creative Theme",
    "all-trends_by_creative_theme-enabled",
  ],
  [ "By Objective",
    "all-trends_by_objective-enabled",
  ],
  [ "By Audience",
    "all-trends_by_audience_targeting-enabled",
  ],
]

export function processReportToggle(toggle: string) {
  let processed: GraphQL.CampaignReportToggleSettingInput = {
    platform: "",
    section: "",
    toggle: "",
    enabled: false,
  }
  const toggleSections = toggle.split("-")
  if (toggleSections.length === 3) { // Check if the format is valid
    processed = {
      platform: toggleSections[0],
      section: toggleSections[1],
      toggle: toggleSections[2],
      enabled: false,
    }
  }
  return processed
}

export function flattenSectionToggles(sectionSettings: (string | null)[][]): string[] {
  return sectionSettings.flatMap((section): string[] => section
    .slice(1) // Skip the first element - it is the section title
    .filter((option): option is string => option !== null),
  // eslint-disable-next-line function-paren-newline
  )
}

export function mapAllToggles(template: string = CAMPAIGN_REPORTING_TEMPLATES.AWARENESS) {
  let allSections = [
    metricsNativeTabularData,
    metricsTrendsTabularData,
  ]

  if (template === CAMPAIGN_REPORTING_TEMPLATES.AWARENESS) {
    allSections = [
      metricsPaidTabularData,
      metricsMediaPostsPaidTabularData,
      metricsMediaPostsNativeTabularData,
      ...allSections,
    ]
  }

  if (template === CAMPAIGN_REPORTING_TEMPLATES.CONVERSION) {
    allSections = [
      metricsPaidTabularData,
      metricsMediaPostsPaidTabularData,
      metricsMediaPostsNativeTabularData,
      metricsConversionTabularData,
      ...allSections,
    ]
  }

  if (template === CAMPAIGN_REPORTING_TEMPLATES.NATIVE) {
    allSections = [
      metricsMediaPostsNativeTabularData,
      ...allSections,
    ]
  }

  const togglesMap: MapAllToggles = {}

  allSections.forEach((sectionToggles) => {
    sectionToggles.forEach((section) => {
      section.slice(1).forEach((toggle) => {
        if (toggle !== null) {
          togglesMap[toggle] = processReportToggle(toggle)
        }
      })
    })
  })
  return togglesMap
}

export function convertMapToSettings(
  togglesMap: { [key: string]: GraphQL.CampaignReportToggleSettingInput },
): GraphQL.CampaignReportToggleSettingInput[] {
  return Object.values(togglesMap)
}

export function responseToTogglesMap(togglesArray: GraphQL.CampaignReportToggleSettingInput[]): MapAllToggles {
  const togglesObject: { [key: string]: GraphQL.CampaignReportToggleSettingInput } = {}

  togglesArray.forEach((toggle) => {
    // Construct the key from the toggle's properties
    const key = `${ toggle.platform }-${ toggle.section }-${ toggle.toggle }`
    // Add the toggle object to the resulting object using the constructed key
    togglesObject[key] = toggle
  })

  return togglesObject
}

export function updateToggleSettings(
  updateToggles: GraphQL.CampaignReportToggleSettingInput[],
  currentToggleSettings: MapAllToggles,
) {
  const workingSettings = { ...currentToggleSettings }
  const updateMap = responseToTogglesMap(updateToggles)
  Object.entries(updateMap).forEach(([ key, value ]) => {
    workingSettings[key] = value
  })
  return workingSettings
}

export function getToggleSettings(
  updateToggles: GraphQL.CampaignReportToggleSettingInput[],
) {
  const workingSettings: MapAllToggles = {}
  const updateMap = responseToTogglesMap(updateToggles)
  Object.entries(updateMap).forEach(([ key, value ]) => {
    workingSettings[key] = value
  })
  return workingSettings
}

export function isToggleSelected(
  selectedToggles: GraphQL.CampaignReportToggleSettingInput[],
  currentToggle: GraphQL.CampaignReportToggleSettingInput,
) {
  return selectedToggles.some((obj) => obj.platform === currentToggle.platform
    && obj.section === currentToggle.section
    && obj.toggle === currentToggle.toggle
    && obj.enabled === currentToggle.enabled)
}

export function removeToggle(
  selectedToggles: GraphQL.CampaignReportToggleSettingInput[],
  toggleToRemove: GraphQL.CampaignReportToggleSettingInput,
) {
  return selectedToggles.filter((obj) => obj.platform !== toggleToRemove.platform
    || obj.section !== toggleToRemove.section
    || obj.toggle !== toggleToRemove.toggle
    || obj.enabled !== toggleToRemove.enabled)
}

export function totalSectionTogglesActive(
  selectedToggles: MapAllToggles,
  sectionToggles: string[],
) {
  return sectionToggles.reduce((acc, toggle) => {
    const testToggel = selectedToggles[toggle]
    if (testToggel && testToggel.enabled) {
      // eslint-disable-next-line no-param-reassign
      acc += 1
    }
    return acc
  }, 0)
}

export function prossesAllToggles(
  sectionToggles: string[],
) {
  return sectionToggles.map((toggle) => processReportToggle(toggle))
}

export function updateAllSectionToggles(
  selectedToggles: MapAllToggles,
  togglesToRemove: string[],
  update: boolean,
) {
  const workingToggles = { ...selectedToggles }
  togglesToRemove.forEach((toggle) => {
    const currToggle = { ...workingToggles[toggle] }
    currToggle.enabled = update
    workingToggles[toggle] = currToggle
  })
  return workingToggles
}

export function processActiveToggles(toggle: string, toggleSettings: MapAllToggles, reportUpdates: CampaignReportUpdate) {
  const currToggles = { ...toggleSettings }
  const currToggle = { ...currToggles[toggle] }
  currToggle.enabled = !currToggle.enabled
  currToggles[toggle] = currToggle
  const currUpdates = { ...reportUpdates }
  currUpdates.toggleSettings = currToggles
  return currUpdates
}

/*
*
*** Reporting ***
*
*/

interface CampaignOverview {
  [platform: string]: {
    [section: string]: string | ((clientBoosted: boolean) => string)
  }
}

interface MetricItem {
  title: string
  format: string
  icon: string
}

export interface InsightSection {
  toggle: string
  value?: GraphQL.Maybe<number> | undefined
}

export interface InsightGroup {
  [section: string]: InsightSection[]
}

export interface Metrics {
  [group: string]: InsightGroup
}

export interface MetricData extends MetricItem {
  value: number | string | undefined
}

export interface Insight{
  title: string
  metrics: MetricData[]
}

interface ToggleSettings {
  [x: string]: GraphQL.CampaignReportToggleSettingInput
}

const campaignOverviewMap: CampaignOverview = {
  instagram: {
    metrics_native: "Campaign Overview Instagram (Native)",
  },
  facebook: {
    metrics_native: "Campaign Overview Facebook (Native)",
  },
  tiktok: {
    metrics_native: (clientBoosted: boolean) => clientBoosted
      ? "Campaign Overview TikTok (Native boosted with Spark Ads)"
      : "Campaign Overview TikTok (Native)",
    metrics_paid: "Campaign Overview TikTok (Paid)",
    metrics_conversion: "Campaign Overview TikTok (Conversion)",
  },
  youtube: {
    metrics_native: "Campaign Overview YouTube (Native)",
    metrics_paid: "Campaign Overview YouTube (Paid)",
  },
  meta: {
    metrics_paid: "Campaign Overview Meta (Paid)",
    metrics_conversion: "Campaign Overview Meta (Conversion)",
  },
}

function organicSuffix(title: string, platform: string): string {
  return platform === "instagram" ? `${ title } (Organic)` : title
}

const createMetricsConfig = (metric: string, platform: string) => {
  const metricOptions: {
    [key: string]: {
      title: string,
      format: string,
      icon: string
    }
  } = {
    total_impressions: {
      title: "Total Impressions",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    total_impressions__gross: {
      title: "Total Gross Impressions",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    total_impressions__true: {
      title: organicSuffix("True Impressions", platform),
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    total_engagements: {
      title: organicSuffix("Total Engagements", platform),
      format: "commifyNumber",
      icon: "ThumbUpIcon",
    },
    engagement_rate: {
      title: "Engagement Rate (Over Gross Imp.)",
      format: "formatPercentUnclamped2",
      icon: "ThumbUpIcon",
    },
    view_engagement_rate: {
      title: "Video Engagement Rate (Over Views)",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    engagement_rate__static: {
      title: "Static Engagement Rate (Over True Imp.)",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    story_views: {
      title: "Story Views",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    story_click_through_rate: {
      title: "Story Click-Through Rate",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    video_views: {
      title: "Video Views",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    video_views__paid_3_sec: {
      title: "Video Views (3 Seconds)",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    video_views__paid_2_sec: {
      title: "Video Views (2 Seconds)",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    video_views__paid_6_sec: {
      title: "Video Views (6 Seconds - Focused)",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    video_views__paid_30_sec: {
      title: "Video Views (30 Seconds)",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    video_views__paid: {
      title: "Video Views (Paid)",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    video_views__native: {
      title: "Video Views (Organic)",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    view_through_rate__paid_2_sec: {
      title: "View-Through Rate (2s)",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    view_through_rate__paid_3_sec: {
      title: "View-Through Rate (3s)",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    view_through_rate__paid_6_sec: {
      title: "Focused View-Through Rate (6s)",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    view_through_rate__paid_30_sec: {
      title: "View-Through Rate (30s)",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    link_clicks: {
      title: "Link Clicks",
      format: "commifyNumber",
      icon: "ThumbUpIcon",
    },
    click_through_rate: {
      title: "Click-Through Rate",
      format: "formatPercentUnclamped2",
      icon: "TouchAppIcon",
    },
    purchases: {
      title: "Purchases",
      format: "commifyNumber",
      icon: "PaidIcon",
    },
    purchase_rate: {
      title: "Purchase Rate",
      format: "formatPercentUnclamped2",
      icon: "PaidIcon",
    },
    purchase_revenue: {
      title: "Purchase Revenue",
      format: "formatUSD",
      icon: "PaidIcon",
    },
    subscription: {
      title: "Subscriptions",
      format: "commifyNumber",
      icon: "PaidIcon",
    },
    subscription_rate: {
      title: "Subscription Rate",
      format: "formatPercentUnclamped2",
      icon: "PaidIcon",
    },
    registration_completed: {
      title: "Registrations Completed",
      format: "commifyNumber",
      icon: "TouchAppIcon",
    },
    registration_completed_rate: {
      title: "Registration Completed Rate",
      format: "formatPercentUnclamped2",
      icon: "TouchAppIcon",
    },
    add_to_cart: {
      title: "Add To Cart",
      format: "commifyNumber",
      icon: "PaidIcon",
    },
    add_to_cart_rate: {
      title: "Add To Cart Rate",
      format: "formatPercentUnclamped2",
      icon: "PaidIcon",
    },
    landing_page_views: {
      title: "Landing Page Views",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    landing_page_view_rate: {
      title: "Landing Page View Rate",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    trials_started: {
      title: "Trials Started",
      format: "commifyNumber",
      icon: "VisibilityIcon",
    },
    trials_started_rate: {
      title: "Trials Started Rate",
      format: "formatPercentUnclamped2",
      icon: "TouchAppIcon",
    },
    app_installs: {
      title: "App Installs",
      format: "commifyNumber",
      icon: "PhoneIphoneIcon",
    },
    app_install_rate: {
      title: "App Install Rate",
      format: "formatPercentUnclamped2",
      icon: "PhoneIphoneIcon",
    },
    checkout_initiated_rate: {
      title: "Checkout Initiated Rate",
      format: "formatPercentUnclamped2",
      icon: "PaidIcon",
    },
    leads: {
      title: "Leads",
      format: "commifyNumber",
      icon: "GroupIcon",
    },
    roas: {
      title: "ROAS (Return On Ad Spend)",
      format: "formatUSD",
      icon: "PaidIcon",
    },
    cpa: {
      title: "CPA (Cost Per Acquisition)",
      format: "formatUSD",
      icon: "PaidIcon",
    },
    video_completion_rate: {
      title: "Video Completion Rate",
      format: "formatPercentUnclamped2",
      icon: "TouchAppIcon",
    },
    view_content: {
      title: "View Content",
      format: "commifyNumber_Basics.round",
      icon: "VisibilityIcon",
    },
    view_content_rate: {
      title: "View Content Rate",
      format: "formatPercentUnclamped2",
      icon: "VisibilityIcon",
    },
    complete_payment: {
      title: "Complete Payment",
      format: "commifyNumber",
      icon: "PhoneIphoneIcon",
    },
    complete_payment_rate: {
      title: "Complete Payment Rate",
      format: "formatPercentUnclamped2",
      icon: "PhoneIphoneIcon",
    },
  }
  return metricOptions[metric]
}

export function commifyNumber(value: number) {
  const absNum = Math.abs(value).toString()
  const parts = absNum.split(".")
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  const formattedNumber = parts.length > 1 ? `${ integerPart }.${ parts[1] }` : integerPart
  return value < 0 ? `-${ formattedNumber }` : formattedNumber
}

export function formatMetricValue(value: number, format: string) {
  switch (format) {
    case "formatPercentUnclamped2": {
      const scaledValue = value * 100
      const roundedValue = scaledValue.toFixed(2)
      return `${ roundedValue }%`
    }
    case "commifyNumber": {
      return commifyNumber(value)
    }
    case "commifyNumber_Basics.round": {
      const roundedValue = Math.round(value)
      return commifyNumber(roundedValue)
    }
    case "formatUSD": {
      const roundedAbsPrice = Math.abs(value).toFixed(2)
      const parts = roundedAbsPrice.split(".")
      const integerPartWithCommas = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      const formattedPrice = `$${ integerPartWithCommas }.${ parts[1] }`
      return value < 0 ? `-${ formattedPrice }` : formattedPrice
    }
    default:
      return value
  }
}

function condenseInsightsByPlatforms(platformInsights: GraphQL.CampaignReportInsight[]):Metrics {
  const metrics: Metrics = {}
  platformInsights.forEach((stat) => {
    if (!metrics[stat.platform]) {
      metrics[stat.platform] = {}
    }
    if (!metrics[stat.platform][stat.section]) {
      metrics[stat.platform][stat.section] = []
    }
    metrics[stat.platform][stat.section].push({ toggle: stat.toggle, value: stat.value })
  })
  return metrics
}

export function platformInsightSections(platformInsights: GraphQL.CampaignReportInsight[]): Insight[] {
  const metrics: Metrics = condenseInsightsByPlatforms(platformInsights)
  const sectionsData:Insight[] = []
  const platforms = Object.keys(metrics)
  platforms.forEach((platform) => {
    const currPlatform = metrics[platform]
    const platformKeys = Object.keys(currPlatform)
    platformKeys.forEach((metricKey) => {
      if (!campaignOverviewMap[platform]) return
      const sectionData: Insight = { title: "", metrics: [] }
      const sectionTitle = campaignOverviewMap[platform][metricKey]
      const sectionCardsData:MetricData[] = []
      const sectionMetrics = metrics[platform][metricKey]
      if (typeof sectionTitle === "function") {
        sectionData.title = sectionTitle(false) // TODO: update arg to be dynamic
      } else {
        sectionData.title = sectionTitle
      }
      sectionMetrics.forEach((metric) => {
        if (metric.value) {
          const metricConfig = createMetricsConfig(metric.toggle, platform)
          if (metricConfig) {
            const metricValue = formatMetricValue(metric.value, metricConfig.format)
            const metricData = { ...metricConfig, value: metricValue }
            sectionCardsData.push(metricData)
          }
        }
      })
      sectionData.metrics = sectionCardsData
      sectionsData.push(sectionData)
    })
  })

  return sectionsData
}

export class IsToggleEnabled {
  toggleSettings: ToggleSettings

  network: string

  constructor(toggleSettings: ToggleSettings, network: string) {
    this.toggleSettings = toggleSettings
    this.network = network.toLowerCase()
  }

  toggleCheck(toggle: string) {
    return this.toggleSettings[toggle] ? this.toggleSettings[toggle].enabled : false
  }

  showMetaData() {
    const toggle = `${ this.network }-media_posts_native-show_posts_metadata`
    return this.toggleCheck(toggle)
  }

  showGrossImpressions() {
    const toggle = `${ this.network }-metrics_native-total_impressions__gross`
    return this.toggleCheck(toggle)
  }

  showViews() {
    const toggle = `${ this.network }-metrics_native-video_views`
    return this.toggleCheck(toggle)
  }

  showStoryViews() {
    const toggle = `${ this.network }-metrics_native-story_views`
    return this.toggleCheck(toggle)
  }

  showCTR() {
    const toggle = `${ this.network }-metrics_native-story_click_through_rate`
    return this.toggleCheck(toggle)
  }

  showStickerTaps() {
    const toggle = `${ this.network }-metrics_native-story_sticker_taps`
    return this.toggleCheck(toggle)
  }

  showLinkClicks() {
    const toggle = `${ this.network }-metrics_native-story_link_clicks`
    return this.toggleCheck(toggle)
  }

  showStoryLikes() {
    const toggle = `${ this.network }-metrics_native-story_likes`
    return this.toggleCheck(toggle)
  }

  showStoryShares() {
    const toggle = `${ this.network }-metrics_native-story_shares`
    return this.toggleCheck(toggle)
  }

  showStoryReplies() {
    const toggle = `${ this.network }-metrics_native-story_replies`
    return this.toggleCheck(toggle)
  }

  showStoryTotalEngagements() {
    const toggle = `${ this.network }-metrics_native-total_engagements`
    return this.toggleCheck(toggle)
  }

  showViewsOrganic() {
    const toggle = `${ this.network }-metrics_native-video_views__native`
    return this.toggleCheck(toggle)
  }

  showViewsPaid() {
    const toggle = `${ this.network }-metrics_native-video_views__paid`
    return this.toggleCheck(toggle)
  }

  showReach() {
    const toggle = `${ this.network }-metrics_native-reach`
    return this.toggleCheck(toggle)
  }

  showComments() {
    const toggle = `${ this.network }-metrics_native-comments`
    return this.toggleCheck(toggle)
  }

  showLikes() {
    const toggle = `${ this.network }-metrics_native-likes`
    return this.toggleCheck(toggle)
  }

  showShares() {
    const toggle = `${ this.network }-metrics_native-shares`
    return this.toggleCheck(toggle)
  }

  showSaves() {
    const toggle = `${ this.network }-metrics_native-saves`
    return this.toggleCheck(toggle)
  }

  showImpressions() {
    const toggle = `${ this.network }-metrics_native-total_impressions__true`
    return this.toggleCheck(toggle)
  }

  showEr() {
    const toggle = `${ this.network }-metrics_native-engagement_rate`
    return this.toggleCheck(toggle)
  }

  showVer() {
    const toggle = `${ this.network }-metrics_native-view_engagement_rate`
    return this.toggleCheck(toggle)
  }

  showSer() {
    const toggle = `${ this.network }-metrics_native-engagement_rate__static`
    return this.toggleCheck(toggle)
  }

  showTotalEngagements() {
    const toggle = `${ this.network }-metrics_native-total_engagements`
    return this.toggleCheck(toggle)
  }

  isMetadataEnabled() {
    const toggle = `${ this.network }-media_posts_paid-show_post_metadata`
    return this.toggleCheck(toggle)
  }

  showPaidTotalImpressions() {
    const toggle = `${ this.network }-metrics_paid-total_impressions`
    return this.toggleCheck(toggle)
  }

  showPaidVideoViews(timelapse: string) {
    const toggle = `${ this.network }-metrics_paid-video_views__paid${ timelapse }`
    return this.toggleCheck(toggle)
  }

  showPaidMetric(metric: string) {
    const toggle = `${ this.network }-metrics_paid-${ metric }`
    return this.toggleCheck(toggle)
  }
}

export function getPaidMediaTableTitle(network: string) {
  switch (network) {
    case "meta":
      return "Meta Optimized Paid Media"
    case "instagram":
      return "Instagram Optimized Paid Media"
    case "facebook":
      return "Facebook Optimized Paid Media"
    case "tiktok":
      return "TikTok Optimized Paid Media"
    case "youtube":
      return "YouTube Optimized Paid Media"
    default:
      return null
  }
}

/*
*
*** utility ***
*
*/

export function groupBy<T, K extends string | number>(
  items: T[],
  keySelector: (item: T) => K,
): Record<K, T[]> {
  return items.reduce((accumulator, item) => {
    const key = keySelector(item)
    if (!accumulator[key]) {
      accumulator[key] = [ item ]
    } else {
      accumulator[key].push(item)
    }
    return accumulator
  }, {} as Record<K, T[]>)
}

export interface MetaCombinedObj {
  [group: string]: GraphQL.DeliverableOverviewFragment[]
}

export function combineMetaFamily(keys: string[], groupedByNetwork: Record<string, GraphQL.DeliverableOverviewFragment[]>) {
  const metaCombined: MetaCombinedObj = {}
  keys.forEach((network) => {
    const currNetwork = network.toLowerCase()
    const networkgroup = (
      network === GraphQL.Network.Facebook
      || network === GraphQL.Network.Instagram
    ) ? "meta" : currNetwork

    metaCombined[networkgroup] = groupedByNetwork[network]
  })
  return metaCombined
}

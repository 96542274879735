import React, { useState, JSX } from "react"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { SelectChangeEvent } from "@mui/material/Select"
import {
  AutocompleteRenderInputParams,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  debounce,
} from "@mui/material"

import { DEFAULT_MIN_AGE, DEFAULT_MAX_AGE } from "../../util/constant"
import {
  fetchAudienceLocations,
  setAudienceLocations,
  setSearchInput,
  setSelectedLocations,
} from "../../state/searchSlice"
import { Scope } from "../../util/types"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as ComponentHelper from "../../util/componentHelper"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../util/searchHelper"
import Autocomplete from "../Autocomplete"
import Button from "../Button"
import Checkbox from "../Checkbox"
import Input from "../Input"
import MinResultInput from "./MinResultsInput"
import Pill from "../Pill"
import Select from "../Select"

import "./style.sass"

function SearchAudienceForm() {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchFilterTabs" })
  const dispatch = useDispatch()
  const locationOptions = useSelector(({ search }) => search.audienceLocations)
  const searchInput = useSelector(({ search }) => search.searchInput)
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  const selectedLocations = useSelector(({ search }) => search.selectedLocations)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ activeLocation, setActiveLocation ] = useState<GraphQL.AudienceLocation | null>(null)
  const [ locationMinResults, setLocationMinResults ] = useState<number | null>()

  const ageSelectionElements: React.JSX.Element[] = (() => {
    const options: string[] = Array.from(
      { length: DEFAULT_MAX_AGE - DEFAULT_MIN_AGE + 1 },
      (_, index) => (DEFAULT_MIN_AGE + index).toString(),
    )
    return ComponentHelper.selectionOptions(options, true)
  })()

  // Event Handler Min Age
  const handleMinAgeChange = (event: SelectChangeEvent<string>): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    if (event.target.value == null || event.target.value === "") {
      newInput.audienceParams.minAge = null
      dispatch(setSearchInput(newInput))
      SearchHelper.setAudienceInputQueryParams(
        newInput.audienceParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    const newMinAge = parseInt(event.target.value, 10)
    if (Number.isNaN(newMinAge)) return

    if (newMinAge < DEFAULT_MIN_AGE) {
      newInput.audienceParams.minAge = DEFAULT_MIN_AGE
      dispatch(setSearchInput(newInput))
      SearchHelper.setAudienceInputQueryParams(
        newInput.audienceParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    if (newMinAge > DEFAULT_MAX_AGE) {
      newInput.audienceParams.minAge = DEFAULT_MAX_AGE
      dispatch(setSearchInput(newInput))
      SearchHelper.setAudienceInputQueryParams(
        newInput.audienceParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    if (
      searchInput.audienceParams.maxAge != null
      && newMinAge > searchInput.audienceParams.maxAge
    ) {
      newInput.audienceParams.maxAge = newMinAge
    }

    newInput.audienceParams.minAge = newMinAge
    dispatch(setSearchInput(newInput))
    SearchHelper.setAudienceInputQueryParams(
      newInput.audienceParams,
      searchParams,
      setSearchParams,
    )
  }

  // Event Handler Max Age
  const handleMaxAgeChange = (event: SelectChangeEvent<string>): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    if (event.target.value == null || event.target.value === "") {
      newInput.audienceParams.maxAge = null
      dispatch(setSearchInput(newInput))
      SearchHelper.setAudienceInputQueryParams(
        newInput.audienceParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    const newMaxAge = parseInt(event.target.value, 10)
    if (Number.isNaN(newMaxAge)) return

    if (newMaxAge < DEFAULT_MIN_AGE) {
      newInput.audienceParams.maxAge = DEFAULT_MIN_AGE
      dispatch(setSearchInput(newInput))
      SearchHelper.setAudienceInputQueryParams(
        newInput.audienceParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    if (newMaxAge > DEFAULT_MAX_AGE) {
      newInput.audienceParams.maxAge = DEFAULT_MAX_AGE
      dispatch(setSearchInput(newInput))
      SearchHelper.setAudienceInputQueryParams(
        newInput.audienceParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    if (
      searchInput.audienceParams.minAge != null
      && newMaxAge < searchInput.audienceParams.minAge
    ) {
      newInput.audienceParams.minAge = newMaxAge
    }

    newInput.audienceParams.maxAge = newMaxAge
    dispatch(setSearchInput(newInput))
    SearchHelper.setAudienceInputQueryParams(
      newInput.audienceParams,
      searchParams,
      setSearchParams,
    )
  }

  // Event Handler for Gender
  const handleGenderCheckboxChange = (
    gender: GraphQL.Sex,
    genderIsActive: boolean,
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newGenders = [ ...searchInput.audienceParams.genders ]

    if (genderIsActive) {
      newGenders.push(gender)
      newInput.audienceParams.genders = newGenders
    } else {
      const filteredGenders = newGenders.filter((g) => g !== gender)
      newInput.audienceParams.genders = filteredGenders
    }

    dispatch(setSearchInput(newInput))
    SearchHelper.setAudienceInputQueryParams(
      newInput.audienceParams,
      searchParams,
      setSearchParams,
    )
  }

  // Event Handler for Ethnicity
  const handleEthnicityCheckboxChange = (
    ethnicity: GraphQL.Ethnicity,
    ethnicityIsActive: boolean,
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newEthnicities = [ ...searchInput.audienceParams.ethnicities ]

    if (ethnicityIsActive) {
      newEthnicities.push(ethnicity)
      newInput.audienceParams.ethnicities = newEthnicities
    } else {
      const filteredEthnicities = newEthnicities
        .filter((e) => e.toLowerCase() !== ethnicity.toLowerCase())

      newInput.audienceParams.ethnicities = filteredEthnicities
    }

    dispatch(setSearchInput(newInput))
    SearchHelper.setAudienceInputQueryParams(
      newInput.audienceParams,
      searchParams,
      setSearchParams,
    )
  }

  // Event Handler for Family
  const handleFamilyCheckboxChange = (
    family: GraphQL.Family,
    familyIsActive: boolean,
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newFamily = [ ...searchInput.audienceParams.family ]

    if (familyIsActive) {
      newFamily.push(family)
      newInput.audienceParams.family = newFamily
    } else {
      const filteredFamily = newFamily
        .filter((f) => f.toLowerCase() !== family.toLowerCase())

      newInput.audienceParams.family = filteredFamily
    }

    dispatch(setSearchInput(newInput))
    SearchHelper.setAudienceInputQueryParams(
      newInput.audienceParams,
      searchParams,
      setSearchParams,
    )
  }

  // Event Handler for Income
  const handleIncomeCheckboxChange = (
    income: GraphQL.IncomeBrackets,
    incomeIsActive: boolean,
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newIncome = [ ...searchInput.audienceParams.income ]

    if (incomeIsActive) {
      newIncome.push(income)
      newInput.audienceParams.income = newIncome
    } else {
      const filteredIncome = newIncome
        .filter((i) => i.toLowerCase() !== income.toLowerCase())

      newInput.audienceParams.income = filteredIncome
    }

    dispatch(setSearchInput(newInput))
    SearchHelper.setAudienceInputQueryParams(
      newInput.audienceParams,
      searchParams,
      setSearchParams,
    )
  }

  // Values set booleans
  const checkedGenderFemale = searchInput.audienceParams.genders
    .some((g) => g === GraphQL.Sex.Female)

  const checkedGenderMale = searchInput.audienceParams.genders
    .some((g) => g === GraphQL.Sex.Male)

  function checkedEthnicity(ethnicity: GraphQL.Ethnicity): boolean {
    return searchInput.audienceParams.ethnicities
      .some((e) => e.toLowerCase() === ethnicity.toLowerCase())
  }

  function checkedFamily(family: GraphQL.Family): boolean {
    return searchInput.audienceParams.family
      .some((f) => f.toLowerCase() === family.toLowerCase())
  }

  function checkedIncome(income: GraphQL.IncomeBrackets): boolean {
    return searchInput.audienceParams.income
      .some((i) => i.toLowerCase() === income.toLowerCase())
  }

  const setMinMaxAge = searchInput.audienceParams.minAge != null
    || searchInput.audienceParams.maxAge != null

  // Location Helpers
  function fetchLocationsByStartsWithValue(startsWith: string): void {
    if (startsWith === "") {
      setActiveLocation(null)
      dispatch(setAudienceLocations("init"))
      return
    }

    dispatch(fetchAudienceLocations(startsWith))
  }

  function getAutocompleteOptionLabel(
    option: string | GraphQL.AudienceLocation,
  ): string {
    if (typeof option === "string") return option
    return `${ option.type }: ${ option.name }`
  }

  function getLocationOptions(): GraphQL.AudienceLocation[] {
    if (
      locationOptions === "init"
      || locationOptions === "loading"
      || API.isError(locationOptions)
    ) {
      return []
    }

    return locationOptions.payload.searchAudienceLocations.rows
  }

  function handleLocationSave(): void {
    if (activeLocation == null) return
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
      .audienceParams.minimumMatch == null
      ? SearchHelper.initialMinimumMatch()
      : SearchHelper.cloneMinimumMatch(
        searchInput.audienceParams.minimumMatch,
      )

    // Remove stale locations and minimum matches with same location ID
    const newLocations = [ ...searchInput.audienceParams.locations ]
      .filter((l) => l !== activeLocation.code)

    const newSelectedLocations = [ ...selectedLocations ]
      .filter(({ location }) => location.code !== activeLocation.code)

    const newLocationMinimumMatches = [ ...newMinimumMatch.location ]
      .filter((m) => m.location !== activeLocation.code)

    // Add fresh values to lists
    newLocations.push(activeLocation.code)
    newSelectedLocations.push({
      location: activeLocation,
      match: locationMinResults || 1,
    })
    newLocationMinimumMatches.push({
      location: activeLocation.code,
      match: locationMinResults || 1,
    })

    // Set new locations and minimum matches
    newInput.audienceParams.locations = newLocations
    newMinimumMatch.location = newLocationMinimumMatches
    newInput.audienceParams.minimumMatch = newMinimumMatch
    dispatch(setSelectedLocations(newSelectedLocations))
    dispatch(setSearchInput(newInput))
    SearchHelper.setAudienceInputQueryParams(
      newInput.audienceParams,
      searchParams,
      setSearchParams,
    )

    SearchHelper.setLocationQueryParams(
      newSelectedLocations,
      searchParams,
      setSearchParams,
    )

    // Reset location autocomplete form
    setActiveLocation(null)
    setLocationMinResults(null)
    dispatch(setAudienceLocations("init"))
  }

  function handleLocationRemove(locationCode: string): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
      .audienceParams.minimumMatch == null
      ? SearchHelper.initialMinimumMatch()
      : SearchHelper.cloneMinimumMatch(
        searchInput.audienceParams.minimumMatch,
      )

    // Remove locations and minimum matches with matching location ID
    const newLocations = [ ...searchInput.audienceParams.locations ]
      .filter((l) => l !== locationCode)

    const newSelectedLocations = [ ...selectedLocations ]
      .filter(({ location }) => location.code !== locationCode)

    const newLocationMinimumMatches = [ ...newMinimumMatch.location ]
      .filter((m) => m.location !== locationCode)

    // Set new locations and minimum matches
    newInput.audienceParams.locations = newLocations
    newMinimumMatch.location = newLocationMinimumMatches
    newInput.audienceParams.minimumMatch = newMinimumMatch
    dispatch(setSelectedLocations(newSelectedLocations))
    dispatch(setSearchInput(newInput))
    SearchHelper.setAudienceInputQueryParams(
      newInput.audienceParams,
      searchParams,
      setSearchParams,
    )

    SearchHelper.setLocationQueryParams(
      newSelectedLocations,
      searchParams,
      setSearchParams,
    )
  }

  function renderLocationInput(params: AutocompleteRenderInputParams): JSX.Element {
    return (
      <Input
        { ...params }
        label=""
        placeholder={ translate("Select Location") }
        InputLabelProps={ {} }
      />
    )
  }

  function setSelectedLocation(
    location: string
    | GraphQL.AudienceLocation
    | (string | GraphQL.AudienceLocation)[]
    | null,
  ): void {
    if (
      location == null
      || typeof location === "string"
      || Array.isArray(location)
    ) {
      return
    }

    setActiveLocation(location)
  }

  // Translation Helpers
  function mapIncomeBracketToString(income: GraphQL.IncomeBrackets): string {
    switch (income) {
      case GraphQL.IncomeBrackets["10000_19999"]:
        return translate("$10,000-$19,999")

      case GraphQL.IncomeBrackets["20000_29999"]:
        return translate("$20,000-$29,999")

      case GraphQL.IncomeBrackets["30000_39999"]:
        return translate("$30,000-$39,999")

      case GraphQL.IncomeBrackets["40000_49999"]:
        return translate("$40,000-$49,999")

      case GraphQL.IncomeBrackets["50000_74999"]:
        return translate("$50,000-$74,999")

      case GraphQL.IncomeBrackets["75000_100000"]:
        return translate("$75,000-$99,999")

      case GraphQL.IncomeBrackets["100000Above"]:
        return translate("$100,000 +")

      default:
        return translate("$0-$9,999")
    }
  }

  // Render function
  return (
    <Container className="cp_component_search-audience cp_component_search-form-container">
      <form>
        { /* Gender section */ }
        <FormControl className="cp_component_search-audience-gender" component="fieldset" fullWidth={ true }>
          <FormLabel component="legend">
            { translate("Gender") }
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              className={ checkedGenderFemale ? "cp_has-min-results" : "" }
              control={ (
                <Checkbox
                  checked={ checkedGenderFemale }
                  onChange={ () => {
                    const genderIsActive = searchInput.audienceParams
                      .genders.some((g) => g === GraphQL.Sex.Female)

                    handleGenderCheckboxChange(
                      GraphQL.Sex.Female,
                      !genderIsActive,
                    )
                  } }
                  name={ translate("Female") }
                />
                ) }
              label={ translate("Female") }
            />
            { checkedGenderFemale && (
              <MinResultInput
                customClass="gender"
                handleChange={ (v: number | null) => {
                  const newInput = SearchHelper.cloneSearchInput(searchInput)
                  const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                    .audienceParams.minimumMatch == null
                    ? SearchHelper.initialMinimumMatch()
                    : SearchHelper.cloneMinimumMatch(
                      searchInput.audienceParams.minimumMatch,
                    )

                  newMinimumMatch.genderFemale = v
                  newInput.audienceParams.minimumMatch = newMinimumMatch
                  dispatch(setSearchInput(newInput))
                  SearchHelper.setAudienceInputQueryParams(
                    newInput.audienceParams,
                    searchParams,
                    setSearchParams,
                  )
                } }
                resultValue={
                  searchInput.audienceParams
                    .minimumMatch?.genderFemale?.toString() || ""
                }
              />
            ) }
            <FormControlLabel
              className={ checkedGenderMale ? "cp_has-min-results" : "" }
              control={ (
                <Checkbox
                  checked={ checkedGenderMale }
                  onChange={ () => {
                    const genderIsActive = searchInput.audienceParams
                      .genders.some((g) => g === GraphQL.Sex.Male)

                    handleGenderCheckboxChange(
                      GraphQL.Sex.Male,
                      !genderIsActive,
                    )
                  } }
                  name={ translate("Male") }
                />
                ) }
              label={ translate("Male") }
            />
            { checkedGenderMale && (
              <MinResultInput
                customClass="gender"
                handleChange={ (v: number | null) => {
                  const newInput = SearchHelper.cloneSearchInput(searchInput)
                  const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                    .audienceParams.minimumMatch == null
                    ? SearchHelper.initialMinimumMatch()
                    : SearchHelper.cloneMinimumMatch(
                      searchInput.audienceParams.minimumMatch,
                    )

                  newMinimumMatch.genderMale = v
                  newInput.audienceParams.minimumMatch = newMinimumMatch
                  dispatch(setSearchInput(newInput))
                  SearchHelper.setAudienceInputQueryParams(
                    newInput.audienceParams,
                    searchParams,
                    setSearchParams,
                  )
                } }
                resultValue={
                  searchInput.audienceParams
                    .minimumMatch?.genderMale?.toString() || ""
                }
              />
            ) }
          </FormGroup>
        </FormControl>

        { /* Min & Max age section */ }
        <FormControl
          className="cp_component_search-audience-age"
          component="fieldset"
          fullWidth={ true }
        >
          <div className="cp_component_search-audience-age_wrapper min-age">
            <FormLabel component="legend">
              { translate("MIN AGE") }
            </FormLabel>
            <Select
              value={
                searchInput.audienceParams.minAge == null
                  ? ""
                  : searchInput.audienceParams.minAge.toString()
              }
              onChange={ handleMinAgeChange }
              id="minAge"
              name="minAge"
              label=""
              labelId="minAge"
              menuItems={ ageSelectionElements }
            />
          </div>
          <div className="cp_component_search-audience-age_wrapper max-age">
            <FormLabel component="legend">
              { translate("MAX AGE") }
            </FormLabel>
            <Select
              value={
                searchInput.audienceParams.maxAge == null
                  ? ""
                  : searchInput.audienceParams.maxAge.toString()
              }
              onChange={ handleMaxAgeChange }
              id="maxAge"
              name="maxAge"
              label=""
              labelId="maxAge"
              menuItems={ ageSelectionElements }
            />
          </div>
          { setMinMaxAge && (
          <MinResultInput
            customClass="age-range"
            handleChange={ (v: number | null) => {
              const newInput = SearchHelper.cloneSearchInput(searchInput)
              const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                .audienceParams.minimumMatch == null
                ? SearchHelper.initialMinimumMatch()
                : SearchHelper.cloneMinimumMatch(
                  searchInput.audienceParams.minimumMatch,
                )

              newMinimumMatch.age = v
              newInput.audienceParams.minimumMatch = newMinimumMatch
              dispatch(setSearchInput(newInput))
              SearchHelper.setAudienceInputQueryParams(
                newInput.audienceParams,
                searchParams,
                setSearchParams,
              )
            } }
            resultValue={
              searchInput.audienceParams
                .minimumMatch?.age?.toString() || ""
            }
          />
          ) }
        </FormControl>

        { /* Ethnicity section */ }
        { scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE_ETHNICITY) && (
          <FormControl
            className="cp_component_search-audience-ethnicity"
            component="fieldset"
            fullWidth={ true }
          >
            <FormLabel component="legend">
              { translate("Ethnicity") }
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={ checkedEthnicity(GraphQL.Ethnicity.AfricanAmerican) }
                    onChange={ () => {
                      handleEthnicityCheckboxChange(
                        GraphQL.Ethnicity.AfricanAmerican,
                        !checkedEthnicity(GraphQL.Ethnicity.AfricanAmerican),
                      )
                    } }
                    name={ translate("AFRICAN AMERICAN") }
                  />
                  ) }
                label={ translate("AFRICAN AMERICAN") }
              />
              { checkedEthnicity(GraphQL.Ethnicity.AfricanAmerican) && (
                <MinResultInput
                  customClass="ethnicities"
                  handleChange={ (v: number | null) => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                      .audienceParams.minimumMatch == null
                      ? SearchHelper.initialMinimumMatch()
                      : SearchHelper.cloneMinimumMatch(
                        searchInput.audienceParams.minimumMatch,
                      )

                    newMinimumMatch.ethnicityAfricanAmerican = v
                    newInput.audienceParams.minimumMatch = newMinimumMatch
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setAudienceInputQueryParams(
                      newInput.audienceParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  resultValue={
                    searchInput.audienceParams
                      .minimumMatch?.ethnicityAfricanAmerican?.toString() || ""
                  }
                />
              ) }
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={ checkedEthnicity(GraphQL.Ethnicity.AsianPacificIslander) }
                    onChange={ () => {
                      handleEthnicityCheckboxChange(
                        GraphQL.Ethnicity.AsianPacificIslander,
                        !checkedEthnicity(GraphQL.Ethnicity.AsianPacificIslander),
                      )
                    } }
                    name={ translate("ASIAN / PACIFIC ISLANDER") }
                  />
                  ) }
                label={ translate("ASIAN / PACIFIC ISLANDER") }
              />
              { checkedEthnicity(GraphQL.Ethnicity.AsianPacificIslander) && (
                <MinResultInput
                  customClass="ethnicities"
                  handleChange={ (v: number | null) => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                      .audienceParams.minimumMatch == null
                      ? SearchHelper.initialMinimumMatch()
                      : SearchHelper.cloneMinimumMatch(
                        searchInput.audienceParams.minimumMatch,
                      )

                    newMinimumMatch.ethnicityAsianPacificIslander = v
                    newInput.audienceParams.minimumMatch = newMinimumMatch
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setAudienceInputQueryParams(
                      newInput.audienceParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  resultValue={
                    searchInput.audienceParams
                      .minimumMatch?.ethnicityAsianPacificIslander?.toString() || ""
                  }
                />
              ) }
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={ checkedEthnicity(GraphQL.Ethnicity.HispanicLatino) }
                    onChange={ () => {
                      handleEthnicityCheckboxChange(
                        GraphQL.Ethnicity.HispanicLatino,
                        !checkedEthnicity(GraphQL.Ethnicity.HispanicLatino),
                      )
                    } }
                    name={ translate("HISPANIC / LATINO") }
                  />
                  ) }
                label={ translate("HISPANIC / LATINO") }
              />
              { checkedEthnicity(GraphQL.Ethnicity.HispanicLatino) && (
                <MinResultInput
                  customClass="ethnicities"
                  handleChange={ (v: number | null) => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                      .audienceParams.minimumMatch == null
                      ? SearchHelper.initialMinimumMatch()
                      : SearchHelper.cloneMinimumMatch(
                        searchInput.audienceParams.minimumMatch,
                      )

                    newMinimumMatch.ethnicityHispanicLatino = v
                    newInput.audienceParams.minimumMatch = newMinimumMatch
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setAudienceInputQueryParams(
                      newInput.audienceParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  resultValue={
                    searchInput.audienceParams
                      .minimumMatch?.ethnicityHispanicLatino?.toString() || ""
                  }
                />
              ) }
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={ checkedEthnicity(GraphQL.Ethnicity.WhiteCaucasian) }
                    onChange={ () => {
                      handleEthnicityCheckboxChange(
                        GraphQL.Ethnicity.WhiteCaucasian,
                        !checkedEthnicity(GraphQL.Ethnicity.WhiteCaucasian),
                      )
                    } }
                    name={ translate("WHITE / CAUCASIAN") }
                  />
                  ) }
                label={ translate("WHITE / CAUCASIAN") }
              />
              { checkedEthnicity(GraphQL.Ethnicity.WhiteCaucasian) && (
                <MinResultInput
                  customClass="ethnicities"
                  handleChange={ (v: number | null) => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                      .audienceParams.minimumMatch == null
                      ? SearchHelper.initialMinimumMatch()
                      : SearchHelper.cloneMinimumMatch(
                        searchInput.audienceParams.minimumMatch,
                      )

                    newMinimumMatch.ethnicityWhiteCaucasian = v
                    newInput.audienceParams.minimumMatch = newMinimumMatch
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setAudienceInputQueryParams(
                      newInput.audienceParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  resultValue={
                    searchInput.audienceParams
                      .minimumMatch?.ethnicityWhiteCaucasian?.toString() || ""
                  }
                />
              ) }
            </FormGroup>
          </FormControl>
        ) }

        { /* Location section */ }
        { scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE_LOCATION) && (
          <FormControl
            className="cp_component_search-audience-location"
            component="fieldset"
            fullWidth={ true }
          >
            <div className="cp_component_search-content-audience_header">
              <FormLabel component="legend">
                { translate("LOCATION") }
              </FormLabel>
            </div>
            <Autocomplete
              disablePortal={ true }
              filterOptions={ (x) => x }
              filterSelectedOptions={ true }
              getOptionLabel={ (o) => getAutocompleteOptionLabel(o) }
              isOptionEqualToValue={ ({ code }) => code === activeLocation?.code }
              loading={ locationOptions === "loading" }
              loadingText={ translate("Loading locations...") }
              noOptionsText={ translate("Search for a location") }
              onInputChange={ debounce((_, newValue) => {
                fetchLocationsByStartsWithValue(newValue)
              }, Constant.DEFAULT_DEBOUNCE_WAIT) }
              onChange={ (_, newValue) => setSelectedLocation(newValue) }
              options={ getLocationOptions() }
              renderInput={ (params) => renderLocationInput(params) }
              value={
                activeLocation == null
                  ? null
                  : `${ activeLocation.type }: ${ activeLocation.name }`
              }
            />
            { activeLocation && (
              <MinResultInput
                customClass="location"
                handleChange={ setLocationMinResults }
                resultValue={ locationMinResults?.toString() || "" }
              />
            ) }
            { activeLocation && (
            <div className="cp_component_search-audience-location-action">
              <Button
                isPrimary={ false }
                label={ translate("Save Location") }
                onClick={ () => handleLocationSave() }
              />
            </div>
            ) }
            <div className="cp_component_search-audience-location-pills">
              { selectedLocations.map((l) => (
                <Pill
                  key={ `cp_component-search-audience-location-pill-${ l.location.code }` }
                  label={ `${ l.location.name } | ${ l.match }%` }
                  onDelete={ () => handleLocationRemove(l.location.code) }
                />
              )) }
            </div>
          </FormControl>
        ) }

        { /* Family status section */ }
        <FormControl
          className="cp_component_search-audience-family"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("Family") }
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={ checkedFamily(GraphQL.Family.Married) }
                  onChange={ () => {
                    handleFamilyCheckboxChange(
                      GraphQL.Family.Married,
                      !checkedFamily(GraphQL.Family.Married),
                    )
                  } }
                  name={ translate("MARRIED") }
                />
                ) }
              label={ translate("MARRIED") }
            />
            { checkedFamily(GraphQL.Family.Married) && (
              <MinResultInput
                customClass="family-status"
                handleChange={ (v: number | null) => {
                  const newInput = SearchHelper.cloneSearchInput(searchInput)
                  const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                    .audienceParams.minimumMatch == null
                    ? SearchHelper.initialMinimumMatch()
                    : SearchHelper.cloneMinimumMatch(
                      searchInput.audienceParams.minimumMatch,
                    )

                  newMinimumMatch.familyMarried = v
                  newInput.audienceParams.minimumMatch = newMinimumMatch
                  dispatch(setSearchInput(newInput))
                  SearchHelper.setAudienceInputQueryParams(
                    newInput.audienceParams,
                    searchParams,
                    setSearchParams,
                  )
                } }
                resultValue={
                  searchInput.audienceParams
                    .minimumMatch?.familyMarried?.toString() || ""
                }
              />
            ) }
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={ checkedFamily(GraphQL.Family.Single) }
                  onChange={ () => {
                    handleFamilyCheckboxChange(
                      GraphQL.Family.Single,
                      !checkedFamily(GraphQL.Family.Single),
                    )
                  } }
                  name={ translate("SINGLE") }
                />
                ) }
              label={ translate("SINGLE") }
            />
            { checkedFamily(GraphQL.Family.Single) && (
              <MinResultInput
                customClass="family-status"
                handleChange={ (v: number | null) => {
                  const newInput = SearchHelper.cloneSearchInput(searchInput)
                  const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                    .audienceParams.minimumMatch == null
                    ? SearchHelper.initialMinimumMatch()
                    : SearchHelper.cloneMinimumMatch(
                      searchInput.audienceParams.minimumMatch,
                    )

                  newMinimumMatch.familySingle = v
                  newInput.audienceParams.minimumMatch = newMinimumMatch
                  dispatch(setSearchInput(newInput))
                  SearchHelper.setAudienceInputQueryParams(
                    newInput.audienceParams,
                    searchParams,
                    setSearchParams,
                  )
                } }
                resultValue={
                  searchInput.audienceParams
                    .minimumMatch?.familySingle?.toString() || ""
                }
              />
            ) }
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={ checkedFamily(GraphQL.Family.Parent) }
                  onChange={ () => {
                    handleFamilyCheckboxChange(
                      GraphQL.Family.Parent,
                      !checkedFamily(GraphQL.Family.Parent),
                    )
                  } }
                  name={ translate("PARENTS") }
                />
                ) }
              label={ translate("PARENTS") }
            />
            { checkedFamily(GraphQL.Family.Parent) && (
              <MinResultInput
                customClass="family-status"
                handleChange={ (v: number | null) => {
                  const newInput = SearchHelper.cloneSearchInput(searchInput)
                  const newMinimumMatch: GraphQL.AudienceMinimumMatch = searchInput
                    .audienceParams.minimumMatch == null
                    ? SearchHelper.initialMinimumMatch()
                    : SearchHelper.cloneMinimumMatch(
                      searchInput.audienceParams.minimumMatch,
                    )

                  newMinimumMatch.familyParents = v
                  newInput.audienceParams.minimumMatch = newMinimumMatch
                  dispatch(setSearchInput(newInput))
                  SearchHelper.setAudienceInputQueryParams(
                    newInput.audienceParams,
                    searchParams,
                    setSearchParams,
                  )
                } }
                resultValue={
                  searchInput.audienceParams
                    .minimumMatch?.familyParents?.toString() || ""
                }
              />
            ) }
          </FormGroup>
        </FormControl>

        { /* Income section */ }
        <FormControl
          className="cp_component_search-audience-income"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("Income") }
          </FormLabel>
          <FormGroup className="cp_component_search-audience-income-options">
            { SearchHelper.INCOME_BRACKETS.map((i: GraphQL.IncomeBrackets) => (
              <FormControlLabel
                key={ `cp_component_search-audience-form-income-${ i }` }
                control={ (
                  <Checkbox
                    checked={ checkedIncome(i) }
                    onChange={ () => {
                      handleIncomeCheckboxChange(i, !checkedIncome(i))
                    } }
                    name={ mapIncomeBracketToString(i) }
                  />
                ) }
                label={ mapIncomeBracketToString(i) }
              />
            )) }
          </FormGroup>
        </FormControl>
        { /* Reset filters button */ }
        <div className="cp_component_search-audience-footer">
          <Button
            className="cp_component_search-audience-reset_button"
            isEnabled={ true }
            isPrimary={ false }
            label={ translate("RESET FILTERS") }
            onClick={ () => {
              dispatch(setSearchInput(SearchHelper.initialSearchState()))
              dispatch(setSelectedLocations([]))
              searchParams.delete(SearchHelper.QUERY_PARAM_Q)
              searchParams.delete(SearchHelper.QUERY_PARAM_INFLUENCER)
              searchParams.delete(SearchHelper.QUERY_PARAM_AUDIENCE)
              searchParams.delete(SearchHelper.QUERY_PARAM_CONTENT)
              searchParams.delete(SearchHelper.QUERY_PARAM_LOCATION)
              setSearchParams(searchParams)
            } }
          />
        </div>
      </form>
    </Container>
  )
}

export default SearchAudienceForm

import React from "react"
import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface ConfirmationModalState {
  confirmationModalOpen: boolean
  title?: string
  subtitle?: string
  subtext?: string | React.JSX.Element
  onConfirm?: () => any
  primaryLabel?: string
  secondaryLabel?: string
}

const initialState: ConfirmationModalState = {
  confirmationModalOpen: false,
}

export const ConfirmationModal = createSlice({
  name: "ConfirmationModal",
  initialState,
  reducers: {
    openConfirmationModal: (
      state,
      action: PayloadAction<{
        onConfirm: () => any,
        title: string,
        subtitle?: string,
        subtext?: string | React.JSX.Element,
        primaryLabel?: string
        secondaryLabel?: string
      }>,
    ) => ({
      ...state,
      confirmationModalOpen: true,
      onConfirm: action.payload.onConfirm,
      subtext: action.payload.subtext,
      title: action.payload.title,
      primaryLabel: action.payload.primaryLabel,
      secondaryLabel: action.payload.secondaryLabel,
    }),
    closeConfirmationModal: (
      state,
    ) => ({
      ...state,
      confirmationModalOpen: false,
      onConfirm: undefined,
      title: undefined,
      subtext: undefined,
    }),
  },
})

export const {
  closeConfirmationModal,
  openConfirmationModal,
} = ConfirmationModal.actions
export default ConfirmationModal.reducer

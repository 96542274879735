import React from "react"
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro"
import "./style.sass"

interface Props extends DataGridProProps {
    className?: string
    checkboxEnabled?: boolean
    visibleIconEnabled?: boolean
    contextMenuEnabled?: boolean
    rowIconButtonProps?: any
    rowMenuProps?: any
}

export default function DataGrid({
  className,
  ...props
}: Props) {
  const classes = [ "cp_component-data-grid" ]
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")
  return (
    <div
      className={ newClasses }
    >
      <DataGridPro
        { ...props }
      />
    </div>
  )
}

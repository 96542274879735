import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { v4 as uuidv4 } from "uuid"
import { Add } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import SearchBar from "../SearchBar"
import Toggle, { ToggleMenuValue } from "../Toggle"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import CustomerHeader from "./CustomerHeader"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import CustomerTable from "./CustomerTable"
import Button from "../Button"
import { Scope } from "../../util/types"
import {
  getMoreTeamMembers,
  getMoreTeamMembersByTitle,
  getTeamMembers,
  getTeamMembersByTitle,
  resetAllFields,
  setTeamMembersFilterValue,
  setTeamMembersLoading,
  setTeamMembersSearchInput,
  setTeamMembersSearchToggle,
} from "../../state/customerSlice"
import {
  SearchUserByTitleSortColumn,
  SortDirection,
  TeamMemberSortColumn,
  UserStatus2,
} from "../../graphql"
import { FilterMenuOption } from "../FilterMenu"
import { openNewCustomerUserModal } from "../../state/customerUserModalSlice"

export default function CustomerOverview() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerOverview" })
  const dispatch = useDispatch()

  const { customerID } = useParams()

  const { scopes } = useSelector(({ user }) => user)

  const {
    customerStatus,
    teamMembers,
    teamMembersByTitle,
    teamMembersContent,
    teamMembersLoading,
    teamMembersSearchInput: searchInput,
    teamMembersSearchToggle: searchToggle,
    teamMembersColumnn,
    teamMembersByTitleColumn,
    teamMembersDirection: direction,
    teamMembersFilterValue: filterValue,
  } = useSelector(({ customer }) => customer)

  const [ page, setPage ] = useState(1)

  const filterQueryValue = useMemo(() => {
    if (filterValue.value === "active") {
      return UserStatus2.Active
    }
    if (filterValue.value === "disabled") {
      return UserStatus2.Disabled
    }
    return undefined
  }, [ filterValue ])

  useEffect(() => () => {
    dispatch(resetAllFields())
  }, [])

  const loadQuery = async () => {
    dispatch(setTeamMembersLoading(true))
    if (searchToggle === "name") {
      await dispatch(getTeamMembers({
        customerId: customerID,
        startsWith: searchInput,
        column: teamMembersColumnn,
        direction,
        userStatus: filterQueryValue,
        page: 1,
        limit: 50,
      }))
    } else {
      await dispatch(getTeamMembersByTitle({
        customerId: customerID,
        includes: searchInput,
        column: teamMembersByTitleColumn,
        userStatus: filterQueryValue,
        direction,
        page: 1,
        limit: 50,
      }))
    }
    setPage(1)
    dispatch(setTeamMembersLoading(false))
  }

  useEffect(() => {
    loadQuery()
  }, [ searchInput, searchToggle, teamMembersColumnn, teamMembersByTitleColumn, direction, filterQueryValue ])

  const fetchMore = useMemo(() => {
    if (searchToggle === "name"
      && API.isSuccess(teamMembers)
      && teamMembersContent.length < teamMembers.payload.searchUsers.totalCount) {
      return async () => {
        dispatch(setTeamMembersLoading(true))
        const nextPage = page + 1
        await dispatch(getMoreTeamMembers({
          startsWith: searchInput,
          column: TeamMemberSortColumn.Name,
          direction: SortDirection.Asc,
          limit: 50,
          page: nextPage,
        }))
        setPage(nextPage)
        dispatch(setTeamMembersLoading(false))
      }
    }
    if (searchToggle === "position"
      && API.isSuccess(teamMembersByTitle)
      && teamMembersContent.length < teamMembersByTitle.payload.searchUserByTitle.totalCount) {
      return async () => {
        dispatch(setTeamMembersLoading(true))
        const nextPage = page + 1
        await dispatch(getMoreTeamMembersByTitle({
          includes: searchInput,
          column: SearchUserByTitleSortColumn.ContactName,
          direction: SortDirection.Asc,
          limit: 50,
          page: nextPage,
        }))
        setPage(nextPage)
        dispatch(setTeamMembersLoading(false))
      }
    }
    return () => {}
  }, [
    searchToggle,
    page,
    teamMembers,
    teamMembersByTitle,
    teamMembersColumnn,
    teamMembersByTitleColumn,
    direction,
    filterQueryValue,
  ])

  const toggleOptions: ToggleMenuValue[] = useMemo(
    () => [
      {
        value: "name", label: translate("Name"), keyId: uuidv4(),
      },
      {
        value: "position", label: translate("Position"), keyId: uuidv4(),
      },
    ],
    [ translate ],
  )

  const filterOptions: FilterMenuOption[] = [ {
    label: translate("Filter"),
    values: [
      {
        label: translate("Active"), value: "active", keyId: uuidv4(),
      },
      {
        label: translate("Disabled"), value: "disabled", keyId: uuidv4(),
      },
    ],
  } ]

  const renderHeaderContent = () => {
    if (customerStatus === "loading" || customerStatus === "init") {
      return <LoadingIndicatorCard size={ 50 } />
    }

    if (customerStatus.status === "error") {
      return <p>There was an error!</p>
    }

    const { customer } = customerStatus.payload
    return <CustomerHeader customer={ customer } />
  }

  return (
    <div id="cp_customer-details_container" className="cp_customer-details_container">
      { renderHeaderContent() }
      <div className="cp_customer-table-container">
        <SearchBar
          id="cp_customer-table-search-bar"
          className="cp_customer-table-search-bar"
          searchPlaceholderText={ translate("Search") }
          onChange={ (e) => dispatch(setTeamMembersSearchInput(e.target.value)) }
          lastSubmittedSearch={ searchInput }
          filterOptions={ filterOptions }
          filterValue={ filterValue }
          setFilterValue={ (value) => dispatch(setTeamMembersFilterValue(value)) }
        />
        <Toggle
          id="cp_customer-table-toggle"
          toggleOptions={ toggleOptions }
          value={ searchToggle }
          setValue={ (option) => dispatch(setTeamMembersSearchToggle(option)) }
        />
        {
          scopes.includes(Scope.DEVELOPMENT)
          && scopes.includes(Scope.FEATURE_CUSTOMER_MANAGEMENT)
          && (
          <Button
            className="cp_customer-table-create-user-button"
            id="cp_customer-table-create-user-button"
            label={ (
              <div className="cp_customer-table_create-user-button-label">
                <Add />
                <p>{ translate("Create user") }</p>
              </div>
            ) }
            disabled={ !customerID }
            onClick={ () => {
              if (!customerID) return
              dispatch(openNewCustomerUserModal({
                customerId: customerID,
                createCallback: loadQuery,
              }))
            } }
          />
          )
    }
      </div>
      <CustomerTable
        teamMembers={ teamMembersContent }
        fetchMore={ fetchMore }
        refresh={ loadQuery }
        isLoading={ teamMembersLoading }
      />
    </div>
  )
}

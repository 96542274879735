import React from "react"
import { useTranslation } from "react-i18next"
import "./placeholder-card.sass"
import { PendingActions } from "@mui/icons-material"

export default function PlaceholderCard() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PlaceholderCard" })

  return (
    <div className="cp_component_placeholder-card">
      <div className="content">
        <PendingActions />
        <p>
          { translate("This is a placeholder for an account that is not available in the social network's API.") }
          <br />
          { translate("No data will be processed for this account until it meets the API requirements.") }
        </p>
      </div>
    </div>
  )
}

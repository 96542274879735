import React, { JSX } from "react"

import * as API from "../../util/apiClient"
import LoadingIndicator from "../LoadingIndicator"
import { useSelector } from "../../state/hooks"

import "./style.sass"

export default function LoginFlowLogo(): JSX.Element | null {
  const team = useSelector(({ team: t }) => t.team)

  if (!API.isSuccess(team)) {
    return (
      <article className="cp_component_login-flow-customer">
        <LoadingIndicator size={ 32 } />
      </article>
    )
  }

  // When the customer is whitelabeled, hide team logo and name
  if (team.payload.vanity.customerLogo?.url.address != null) return null

  const teamLogo = team.payload.vanity.logo?.url.address

  return (
    <article className="cp_component_login-flow-customer">
      { teamLogo && (
        <div style={ { backgroundImage: `url(${ teamLogo })` } } />
      ) }
      <h5>{ team.payload.vanity.name }</h5>
    </article>
  )
}

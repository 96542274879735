import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import * as Misc from "../../util/miscHelper"
import { FilterMenuValue } from "../../component/FilterMenu"
import {
  ALL_NETWORKS,
  LIST_SEARCH_TYPE_FILTERS,
  Status,
} from "../../util/types"
import { RootState } from "../store"
import {
  setListNetworkFilter,
  setListSearchInput,
  setListTypeFilter,
  setListsByTagStatus,
  setListsContent,
  setListsStatus,
} from "."

// List Interface and Initial State
export interface ListsState {
  listsStatus: Status<GraphQL.SearchListsAndListGroupsQuery>
  listsByTagStatus: Status<GraphQL.SearchListsAndListGroupsByVerticalQuery>
  listsContent: Array<GraphQL.SuggestionListRowFragment | GraphQL.SuggestionListGroupRowFragment>
  listSearchInput: string
  listNetworkFilters: FilterMenuValue[]
  listTypeFilter: FilterMenuValue
  listSearchToggle: "list" | "tag"
}

export const initialState: ListsState = {
  listsStatus: "init",
  listsByTagStatus: "init",
  listsContent: [],
  listSearchInput: "",
  listNetworkFilters: [],
  listTypeFilter: {
    label: "", value: null, keyId: "",
  },
  listSearchToggle: "list",
}

// Reducer actions
export const reducers = {
  setListsStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchListsAndListGroupsQuery>>) => ({
    ...state,
    listsStatus: action.payload,
  }),
  setListsByTagStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchListsAndListGroupsByVerticalQuery>>) => ({
    ...state,
    listsByTagStatus: action.payload,
  }),
  setListsContent: (
    state: any,
    action: PayloadAction<Array<GraphQL.SuggestionListRowFragment | GraphQL.SuggestionListGroupRowFragment>>,
  ) => ({
    ...state,
    listsContent: action.payload,
  }),
  setListSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    listSearchInput: action.payload,
  }),
  setListNetworkFilter: (state: any, action: PayloadAction<FilterMenuValue[]>) => ({
    ...state,
    listNetworkFilters: action.payload,
  }),
  setListTypeFilter: (state: any, action: PayloadAction<FilterMenuValue>) => ({
    ...state,
    listTypeFilter: action.payload,
  }),
  setListSearchToggle: (state: any, action: PayloadAction<string>) => ({
    ...state,
    listSearchToggle: action.payload,
  }),
}

export const getLists = (
  searchInput: string,
  networkFilters: FilterMenuValue[],
  typeFilter: FilterMenuValue,
  page: number,
  scopes: string[],
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setListsStatus("loading"))

  const networks = []
  const networkFiltersToString = networkFilters.map((fv) => fv.value)
  for (let i = 0; i < ALL_NETWORKS.length; i += 1) {
    if (networkFiltersToString.includes(ALL_NETWORKS[i].toString())) {
      networks.push(ALL_NETWORKS[i])
    }
  }

  const j = LIST_SEARCH_TYPE_FILTERS.findIndex((f) => f === typeFilter.value)
  if ((j === -1 && typeFilter.value !== null)) {
    dispatch(setListsStatus({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchListsAndListGroups(
      GraphQL.SortDirection.Desc,
      GraphQL.SuggestionListAndGroupColumn.Created,
      searchInput,
      // NOTE: Pushing custom list instead of empty list because custom list
      // excludes Twitter and includes everything else within user scopes.
      // When Twitter is truly removed from the back end, we can simplify
      // the logic below.
      networks.length === 0 ? Misc.buildNetworkListByScope(scopes) : networks,
      page,
      50,
      typeFilter.value ? LIST_SEARCH_TYPE_FILTERS[j] : null,
    )
    dispatch(setListsStatus(result))

    if (API.isSuccess(result)) {
      dispatch(setListsContent(result.payload.searchSuggestionListAndSuggestionListGroup.rows))
    } else {
      dispatch(setListsStatus({ status: "error", message: "Something went wrong with the request!" }))
    }
  }
  dispatch(setListSearchInput(searchInput))
}

export const getMoreLists = (
  searchInput: string,
  networkFilters: FilterMenuValue[],
  typeFilter: FilterMenuValue,
  page: number,
  scopes: string[],
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { listsStatus: currentListsStatus, listsContent: currentListsContent } } = getState()

  const networks = []
  const networkFiltersToString = networkFilters.map((fv) => fv.value)
  for (let i = 0; i < ALL_NETWORKS.length; i += 1) {
    if (networkFiltersToString.includes(ALL_NETWORKS[i].toString())) {
      networks.push(ALL_NETWORKS[i])
    }
  }
  const j = LIST_SEARCH_TYPE_FILTERS.findIndex((f) => f === typeFilter.value)
  if ((j === -1 && typeFilter.value !== null)) {
    dispatch(setListsStatus({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchListsAndListGroups(
      GraphQL.SortDirection.Desc,
      GraphQL.SuggestionListAndGroupColumn.Created,
      searchInput,
      // NOTE: Pushing custom list instead of empty list because custom list
      // excludes Twitter and includes everything else within user scopes.
      // When Twitter is truly removed from the back end, we can simplify
      // the logic below.
      networks.length === 0 ? Misc.buildNetworkListByScope(scopes) : networks,
      page,
      50,
      typeFilter.value ? LIST_SEARCH_TYPE_FILTERS[j] : null,
    )
    // At this point, currentListsStatus should already have been successful
    if (API.isSuccess(currentListsStatus) && API.isSuccess(result)) {
      dispatch(setListsContent([ ...currentListsContent, ...result.payload.searchSuggestionListAndSuggestionListGroup.rows ]))
    } else {
      dispatch(setListsStatus({ status: "error", message: "Invalid filter value!" }))
    }
  }
}

export const getListsByTag = (
  searchInput: string,
  networkFilters: FilterMenuValue[],
  typeFilter: FilterMenuValue,
  page: number,
  scopes: string[],
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setListsByTagStatus("loading"))

  const networks = []
  const networkFiltersToString = networkFilters.map((fv) => fv.value)
  for (let i = 0; i < ALL_NETWORKS.length; i += 1) {
    if (networkFiltersToString.includes(ALL_NETWORKS[i].toString())) {
      networks.push(ALL_NETWORKS[i])
    }
  }
  const j = LIST_SEARCH_TYPE_FILTERS.findIndex((f) => f === typeFilter.value)
  if ((j === -1 && typeFilter.value !== null)) {
    dispatch(setListsStatus({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchListsAndListGroupsByTag({
      direction: GraphQL.SortDirection.Desc,
      column: GraphQL.SuggestionListAndGroupColumn.Created,
      suggestionListVerticalNameContains: searchInput,
      // NOTE: Pushing custom list instead of empty list because custom list
      // excludes Twitter and includes everything else within user scopes.
      // When Twitter is truly removed from the back end, we can simplify
      // the logic below.
      networkFilter: networks.length === 0 ? Misc.buildNetworkListByScope(scopes) : networks,
      page,
      limit: 50,
      typeFilter: typeFilter.value ? LIST_SEARCH_TYPE_FILTERS[j] : null,
    })

    dispatch(setListsByTagStatus(result))

    if (API.isSuccess(result)) {
      dispatch(setListsContent(result.payload.searchSuggestionListAndSuggestionListGroupBySuggesitonListVerticalName.rows))
    }
  }
  dispatch(setListSearchInput(searchInput))
}

export const getMoreListsByTag = (
  searchInput: string,
  networkFilters: FilterMenuValue[],
  typeFilter: FilterMenuValue,
  page: number,
  scopes: string[],
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { listsStatus: currentListsStatus, listsContent: currentListsContent } } = getState()

  const networks = []
  const networkFiltersToString = networkFilters.map((fv) => fv.value)
  for (let i = 0; i < ALL_NETWORKS.length; i += 1) {
    if (networkFiltersToString.includes(ALL_NETWORKS[i].toString())) {
      networks.push(ALL_NETWORKS[i])
    }
  }
  const j = LIST_SEARCH_TYPE_FILTERS.findIndex((f) => f === typeFilter.value)
  if ((j === -1 && typeFilter.value !== null)) {
    dispatch(setListsStatus({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchListsAndListGroupsByTag({
      direction: GraphQL.SortDirection.Desc,
      column: GraphQL.SuggestionListAndGroupColumn.Created,
      suggestionListVerticalNameContains: searchInput,
      // NOTE: Pushing custom list instead of empty list because custom list
      // excludes Twitter and includes everything else within user scopes.
      // When Twitter is truly removed from the back end, we can simplify
      // the logic below.
      networkFilter: networks.length === 0 ? Misc.buildNetworkListByScope(scopes) : networks,
      page,
      limit: 50,
      typeFilter: typeFilter.value ? LIST_SEARCH_TYPE_FILTERS[j] : null,
    })
    // At this point, currentListsStatus should already have been successful
    if (API.isSuccess(currentListsStatus) && API.isSuccess(result)) {
      const newListsContent = [
        ...currentListsContent,
        ...result.payload.searchSuggestionListAndSuggestionListGroupBySuggesitonListVerticalName.rows,
      ]
      dispatch(setListsContent(newListsContent))
    }
    if (API.isError(result)) {
      dispatch(setListsByTagStatus({ status: "error", message: "Invalid filter value!" }))
    }
  }
}

export const handleListNetworkFilterChange = (filter: FilterMenuValue) => (dispatch: Dispatch, getState: () => RootState) => {
  if (filter.value === null) return
  const {
    slidingPanels: {
      listNetworkFilters,
    },
  } = getState()
  const newNetworkFilters = [ ...listNetworkFilters ]
  const i = listNetworkFilters.findIndex((f) => f.value === filter.value)
  // Filter value is already selected
  if (i !== -1) {
    newNetworkFilters.splice(i, 1)
  } else {
    newNetworkFilters.push(filter)
  }
  dispatch(setListNetworkFilter(newNetworkFilters))
}

export const handleListTypeFilterChange = (filter: FilterMenuValue) => (dispatch: Dispatch, getState: () => RootState) => {
  const {
    slidingPanels: {
      listTypeFilter,
    },
  } = getState()
  let newListTypeFilter: FilterMenuValue
  if (filter.value === null || filter.value === listTypeFilter.value) {
    newListTypeFilter = {
      label: "",
      value: null,
      keyId: "",
    }
  } else {
    newListTypeFilter = filter
  }
  dispatch(setListTypeFilter(newListTypeFilter))
}

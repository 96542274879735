import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import "./notes-input-card.sass"
import Input from "../../Input"
import Button from "../../Button"
import * as API from "../../../util/apiClient"
import { useDispatch, useSelector } from "../../../state/hooks"
import {
  createPersonalityNote,
  fetchSocialProfileForNotes,
  setNoteInput,
} from "../../../state/socialProfileSlice"

type Props = {
  personalityId: string
}

export default function NotesInputCard({ personalityId }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.NotesInputCard" })
  const dispatch = useDispatch()
  const { socialAccountId } = useParams()
  const { noteInput } = useSelector(({ socialProfile }) => socialProfile)

  const onButtonSubmit = async () => {
    const res = await createPersonalityNote({
      personalityId,
      text: noteInput,
    })

    if (API.isSuccess(res) && socialAccountId) {
      dispatch(fetchSocialProfileForNotes(socialAccountId))
      dispatch(setNoteInput(""))
    }
  }

  return (
    <div className="cp_component_profile-tabs_notes-input-card">
      <Input
        className="cp_component_profile-tabs_notes-input-card-input"
        label={ translate("Leave a note") }
        placeholder={ `${ translate("Leave a note") }...` }
        onChange={ (e) => dispatch(setNoteInput(e.target.value)) }
        value={ noteInput }
        multiline={ true }
        maxRows={ 4 }
      />
      <Button
        className="cp_component_profile-tabs_notes-input-card-button"
        label={ translate("Submit") }
        disabled={ noteInput.length === 0 }
        onClick={ onButtonSubmit }
      />
    </div>
  )
}

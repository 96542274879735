import React, { useMemo } from "react"
import { Link, useParams } from "react-router-dom"

import "./image-analysis.sass"
import * as GraphQL from "../../../graphql"
import { useDispatch } from "../../../state/hooks"
import { setSearchInput } from "../../../state/searchSlice"
import { initialSearchState } from "../../../util/searchHelper"

type Props = {
  socialProfile: GraphQL.SocialProfileFragment
}

export default function ImageAnalysis({ socialProfile }: Props) {
  const { imageAnalysisOccurences } = socialProfile
  const dispatch = useDispatch()
  const { vanity } = useParams()

  const content = useMemo(() => imageAnalysisOccurences.slice(0, 15).map(({ name, count }) => {
    const qParam = encodeURIComponent(JSON.stringify({ resultType: "POST" }))
    const contentParam = encodeURIComponent(JSON.stringify({ optionalImageTags: [ { tags: [ name ], inclusive: false } ] }))
    return (
      <div className="cp_image-analysis_component-occurence" key={ name }>
        <Link
          className="cp_image-analysis_component-occurence-name"
          to={ {
            pathname: `/${ vanity }/profile/${ socialProfile.id }/content`,
            search: `?q=${ qParam }&content=${ contentParam }`,
          } }
          onClick={ () => {
            const newInput = initialSearchState()
            newInput.contentParams = {
              ...newInput.contentParams,
              optionalImageTags: [ { tags: [ name ], inclusive: false } ],
            }
            dispatch(setSearchInput(newInput))
          } }
          relative="path"
        >
          { name }
        </Link>
        <p className="cp_image-analysis_component-occurence-count">
          { count }
        </p>
      </div>
    )
  }), [ imageAnalysisOccurences, socialProfile.id ])

  return (
    <div className="cp_image-analysis_component">
      { content }
    </div>
  )
}

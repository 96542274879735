import * as GraphQL from "../../graphql"

export function getScore(
  scores: GraphQL.Score[],
  type: GraphQL.ScoreType,
): number {
  return scores.find((s) => s.type === type)?.value || 0
}

export function isSocialAccount(
  row: { [ k: string ]: any },
): row is GraphQL.GodSearchSocialAccount {
  // eslint-disable-next-line no-underscore-dangle
  if (row?.__typename === "GodSearchSocialAccount") return true
  return false
}

export function isPost(
  row: { [ k: string ]: any },
): row is GraphQL.Post {
  // eslint-disable-next-line no-underscore-dangle
  if (row?.__typename === "Post") return true
  return false
}

import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import * as API from "../../util/apiClient"
import Button from "../../component/Button"
import Input from "../../component/Input"
import LoadingIndicator from "../../component/LoadingIndicator"
import { fetchTeamVanity } from "../../state/teamSlice"
import { useDispatch, useSelector } from "../../state/hooks"

type Props = {
  setFindTeamActive: (active: boolean) => void,
}

export default function EnterTeamUrlForm(props: Props): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Team" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const team = useSelector(({ team: teamVanity }) => teamVanity.team)
  const [ teamUrl, setTeamUrl ] = useState<string>("")

  useEffect(() => {
    if (API.isSuccess(team)) {
      navigate(`/${ teamUrl }/login`)
    }
  }, [ team ])

  return (
    <section className="card">
      <article />
      <h1 className="headline">
        { translate("Enter your team URL") }
      </h1>
      <p className="label_medium">
        { translate("Enter your team’s Influential URL") }
      </p>
      <aside>
        <p className="label_large-bold">{ tCommon("Base URL") }</p>
        <Input
          error={ API.isError(team) }
          InputProps={ {
            id: "cp_page_team-team-input",
            inputProps: { maxLength: 60 },
          } }
          onChange={ (e) => setTeamUrl(e.target.value) }
          onKeyDown={ (e) => {
            if (e.key === "Enter" && teamUrl.length > 0 && team !== "loading") {
              dispatch(fetchTeamVanity(teamUrl))
            }
          } }
          placeholder={ translate("team-url") }
          value={ teamUrl }
        />
      </aside>
      { API.isError(team) && (
        <span>{ translate("Invalid team URL") }</span>
      ) }
      <Button
        className="primary"
        disabled={ teamUrl.length === 0 || team === "loading" }
        id="cp_page_team-confirm-button"
        label={
          team === "loading"
            ? <LoadingIndicator size={ 24 } />
            : translate("Confirm")
        }
        onClick={ () => dispatch(fetchTeamVanity(teamUrl)) }
        tabIndex={ 0 }
        type="submit"
      />
      { /* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */ }
      <a
        id="cp_page_team-dont-know-url-link"
        onClick={ () => props.setFindTeamActive(true) }
        role="button"
        tabIndex={ -1 }
      >
        { translate("Don't know your team URL? Click here to find your team") }
      </a>
    </section>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import type { Dispatch, PayloadAction } from "@reduxjs/toolkit"
import { CreateTcmOrderMutationVariables, DeliverableFragment } from "../../graphql"
import * as API from "../../util/apiClient"
import { RootState } from "../store"

export interface inviteTikTokModalState {
  tikTokInviteLinkOpen: boolean
  deliverable?: DeliverableFragment
  onGenerateLink?: () => any
  onClose?: () => any
}

const initialState: inviteTikTokModalState = {
  tikTokInviteLinkOpen: false,
}

export const tikTokInviteModal = createSlice({
  name: "TikTokInviteModal",
  initialState,
  reducers: {
    openTikTokInviteModal: (
      state,
      action: PayloadAction<{
        onGenerateLink?: () => any,
        onClose?: () => any,
        deliverable: DeliverableFragment,
      }>,
    ) => ({
      ...state,
      tikTokInviteLinkOpen: true,
      onGenerateLink: action.payload.onGenerateLink,
      onClose: action.payload.onClose,
      deliverable: action.payload.deliverable,
    }),
    closeTikTokInviteModal: (
      state,
    ) => ({
      ...state,
      tikTokInviteLinkOpen: false,
      onGenerateLink: undefined,
      deliverable: undefined,
    }),
  },
})

export const {
  closeTikTokInviteModal,
  openTikTokInviteModal,
} = tikTokInviteModal.actions
export default tikTokInviteModal.reducer

export const generateLink = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState()
  const deliverableId = state.tikTokInviteModalSlice.deliverable?.id
  if (!deliverableId) return null
  const params: CreateTcmOrderMutationVariables = {
    deliverableId,
  }
  const result = await API.createTctmOrder(params)
  return result
}

import React from "react"
import "./stat-card.sass"

type Props = {
  stat: number
  title: string
}

export default function StatCard({ stat, title }: Props) {
  return (
    <div className="cp_component-campaign-stat-card">
      <h2 className="statistic">{ stat }</h2>
      <p className="title">{ title }</p>
    </div>
  )
}

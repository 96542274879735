import React from "react"
import "./style.sass"
import { Box } from "@mui/material"

type Props = {
  label: string
  onClick: () => any
  className?: string
}

export default function ReturnToTag({
  onClick, label, className,
}: Props) {
  const classes = [ "cp_component_return-to-tag", className || "" ].join(" ")
  return (
    <Box
      className={ classes }
      onClick={ onClick }
    >
      { label }
    </Box>
  )
}

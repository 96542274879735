import React, {
  JSX,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  LinearProgress,
  Typography,
} from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import { GridColDef } from "@mui/x-data-grid-pro"
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import SlidingPanel from "../../SlidingPanel"
import "./customersSlidingPanel.sass"
import SearchBar from "../../SearchBar"
import LoadingIndicator from "../../LoadingIndicator"
import * as API from "../../../util/apiClient"
import { RootState, store } from "../../../state/store"
import {
  getCustomers,
  getCustomersByUser,
  getMoreCustomers,
  getMoreCustomersByUser,
  toggleCustomersSortDirection,
} from "../../../state/slidingPanelSlice/customers"
import { setCustomersSearchInput, setCustomersSearchToggle } from "../../../state/slidingPanelSlice"
import DataGrid from "../../DataGrid"
import EntityInfoRow from "../../EntityInfoRow"
import EmptyElement from "../../EmptyElement"
import { ToggleMenuValue } from "../../Toggle"

type Props = {
    open: boolean
    onClose: () => void
}

function CustomersSlidingPanel({
  open,
  onClose,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })

  const navigate = useNavigate()
  const { vanity } = useParams()
  const dispatch = useDispatch()
  const [ page, setPage ] = useState(1)

  const {
    customersContent,
    customersSearchInput,
    customersSearchToggle,
    customersStatus,
    customersByUserStatus,
    moreCustomersIsLoading,
    customersSortDirection,
  } = useSelector((root: RootState) => root.slidingPanels)

  useEffect(() => {
    if (customersSearchToggle === "customer") {
      store.dispatch(getCustomers(customersSearchInput, customersSortDirection, 1))
    } else {
      store.dispatch(getCustomersByUser(customersSearchInput, customersSortDirection, 1))
    }
    setPage(1)
  }, [ customersSearchInput, customersSearchToggle, customersSortDirection ])

  const toggleOptions: ToggleMenuValue[] = useMemo(
    () => [
      {
        value: "customer", label: translate("Customer"), keyId: uuidv4(),
      },
      {
        value: "user", label: translate("User"), keyId: uuidv4(),
      },
    ],
    [ translate ],
  )

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "name",
      headerName: translate("Customer"),
      sortable: false,
      renderHeader: () => (
        <Typography
          className="cp_component_customers-table-custom-header"
          onClick={ () => store.dispatch(toggleCustomersSortDirection()) }
        >
          { translate("Name") }
          { " " }
          <UnfoldMoreIcon className="cp_component_customers-table-unfold-icon" />
        </Typography>
      ),
      renderCell: (params) => (
        <EntityInfoRow
          avatarSrc={ params.row.url }
          name={ params.row.name }
          key={ params.row.id }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 4,
    },
    {
      field: "users",
      sortable: false,
      renderCell: (params) => (
        <Typography>{ params.row.userCount }</Typography>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
  ], [ translate ])

  const renderContent = () => {
    if ((customersSearchToggle === "customer" && (customersStatus === "init" || customersStatus === "loading"))
    || ((customersSearchToggle === "user" && (customersByUserStatus === "init" || customersByUserStatus === "loading")))) {
      return (
        <LoadingIndicator flexWrapperEnabled={ true } />
      )
    }
    if ((customersSearchToggle === "customer"
      && API.isSuccess(customersStatus)) || (customersSearchToggle === "user" && API.isSuccess(customersByUserStatus))) {
      let fetchMore: () => void
      if (customersSearchToggle === "customer") {
        fetchMore = () => {
          // Should be true at this point
          if (API.isSuccess(customersStatus) && customersStatus.payload.searchCustomers.totalCount > customersContent.length) {
            store.dispatch(getMoreCustomers(customersSearchInput, customersSortDirection, page + 1))
            setPage((prev) => prev + 1)
          }
        }
      } else {
        fetchMore = () => {
          if (API.isSuccess(customersByUserStatus)
            && customersByUserStatus.payload.searchCustomersByUser.totalCount > customersContent.length) {
            store.dispatch(getMoreCustomersByUser(customersSearchInput, customersSortDirection, page + 1))
            setPage((prev) => prev + 1)
          }
        }
      }
      return (
        <DataGrid
          className="cp_component_customers-table"
          rowHeight={ 50 }
          columnHeaderHeight={ 40 }
          columns={ COLUMNS }
          disableColumnReorder={ true }
          hideFooter={ true }
          onRowClick={ (params) => navigate(`/${ vanity }/customers/${ params.row.id }`) }
          rows={ customersContent.map((row) => ({
            id: row.id,
            url: row.company?.logo?.url.address,
            name: row.vanity,
            userCount: row.userCount,
          })) }
          onRowsScrollEnd={ () => {
            fetchMore()
          } }
          loading={ moreCustomersIsLoading }
          scrollEndThreshold={ 200 }
          slots={ {
            loadingOverlay: LinearProgress,
            noRowsOverlay: EmptyElement,
            noResultsOverlay: EmptyElement,
          } }
        />
      )
    }
    return <Typography>Error!</Typography>
  }

  return (
    <SlidingPanel
      className="cp_component_navigation-bar-customers-panel"
      title={ translate("Customers") }
      toggleOptions={ toggleOptions }
      toggleValue={ customersSearchToggle }
      setToggleValue={ (value) => dispatch(setCustomersSearchToggle(value)) }
      open={ open }
      onClose={ onClose }
      disablePortal={ true }
    >
      <SearchBar
        onChange={ (e) => dispatch(setCustomersSearchInput(e.target.value)) }
        lastSubmittedSearch={ customersSearchInput }
      />
      <Container className="cp_component_navigation-bar-customers-container">
        { renderContent() }
      </Container>
    </SlidingPanel>
  )
}

export default CustomersSlidingPanel

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import * as Locale from "./locale"

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: Locale.en,
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  })

import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

import "./style.sass"
import "./add-account-campaign.sass"

import { useDispatch, useSelector } from "../../../state/hooks"
import SearchFilter from "../../SearchFilter"
import SearchFilterTabs from "../../SearchFilterTabs"
import Button from "../../Button"
import SearchResults from "../../SearchResults"

import { fetchSearchResults, setSearchInput } from "../../../state/searchSlice"
import { Network } from "../../../graphql"
import * as SearchHelper from "../../../util/searchHelper"

interface Props {
  addAccountCallback: () => any,
  isTikTokEnabled: boolean,
}

export default function AddAccountToCampaign({
  addAccountCallback,
  isTikTokEnabled,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Search" })
  const dispatch = useDispatch()
  const searchInput = useSelector(({ search }) => search.searchInput)
  const [ searchParams, setSearchParams ] = useSearchParams()

  useEffect(() => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    newInput.socialNetworks = [
      Network.Facebook,
      Network.Instagram,
      Network.Youtube,
    ]
    if (isTikTokEnabled) newInput.socialNetworks.push(Network.Tiktok)
    dispatch(setSearchInput(newInput))
    SearchHelper.setSearchInputQueryParams(
      newInput,
      searchParams,
      setSearchParams,
    )
    dispatch(fetchSearchResults(true))
  }, [])

  const disabledNetworks = useMemo(() => {
    if (isTikTokEnabled) return []
    return [ Network.Tiktok ]
  }, [ isTikTokEnabled ])

  return (
    <div className="cp_campaign_page-campaign-add-account-search cp_page_search">
      <div className="cp_page_search-container">
        <section className="cp_page_search-form">
          <SearchFilter disabledNetworks={ disabledNetworks } />
          <SearchFilterTabs />
          <aside className="cp_page_search-button">
            <Button
              isEnabled={ true }
              isPrimary={ true }
              label={ translate("Search") }
              onClick={ () => dispatch(fetchSearchResults(true)) }
            />
          </aside>
        </section>
        <section className="cp_page_search-results">
          <SearchResults
            mountContext="campaign"
            addButtonCallback={ addAccountCallback }
            contentContextMenu={ {
              showAddPostButton: true,
              showViewMatchingPostsButton: true,
              showViewProfileButton: true,
            } }
          />
        </section>
      </div>
    </div>
  )
}

import React from "react"
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material"
import { Check } from "@mui/icons-material"
import "./style.sass"

export type FilterMenuValue = {
  label: string
  value: string | null
  keyId: string
}

export type FilterMenuOption = {
label: string,
filterCategory?: string
values: FilterMenuValue[]
}

export type Props = {
    filterOptions: Array<FilterMenuOption>,
    filterValue: FilterMenuValue
    setFilterValue: (value: FilterMenuValue) => void
}

const FilterMenu = React.forwardRef(({
  filterOptions, filterValue, setFilterValue,
} : Props, ref) => {
  // Wrapped with Box and ForwardRef so that component complies with ClickAwayListener
  const handleFilterToggle = (v: FilterMenuValue) => {
    if (v.value === null || v.value === filterValue.value) {
      setFilterValue({
        label: "",
        value: null,
        keyId: "",
      })
    } else {
      setFilterValue(v)
    }
  }
  return (
    <Box ref={ ref }>
      <MenuList className="cp_component_filter-menu-list" dense={ true }>
        { filterOptions.map((section) => [

          <ListItemText className="cp_component_filter-menu-title">{ section.label }</ListItemText>,
          ...section.values.map((v) => {
            const selected = v.value === filterValue?.value
            let menuItemClassName = "cp_component_filter-menu-item"
            if (selected) menuItemClassName += " cp_component_menu-item_selected"
            return (
              <MenuItem
                className={ menuItemClassName }
                onClick={ () => handleFilterToggle(v) }
                key={ `filter-menu-option-${ v.value }-${ v.keyId }` }
              >
                { selected && <ListItemIcon className="cp_component_checked-icon"><Check /></ListItemIcon> }
                <ListItemText
                  inset={ !selected }
                >
                  { v.label }
                </ListItemText>
              </MenuItem>
            )
          }),
        ]) }

      </MenuList>
    </Box>
  )
})

export default FilterMenu

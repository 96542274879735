import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import EmptySplash from "../EmptySplash"

export default function AccountsEmptySplash(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchResultsTable" })

  return (
    <EmptySplash
      bodyText={ translate("Your search may be too specific profiles") }
      headlineText={ translate("No matching profiles!") }
    />
  )
}

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { GridColDef } from "@mui/x-data-grid"
import DataGrid from "../../../../DataGrid"
import { AdSetFragment } from "../../../../../graphql"
import EmptyElement from "../../../../EmptyElement"

type Props = {
  rows: AdSetFragment[]
  setSelectedIds: (adIds: string[]) => void
  selectedIds: string[]
}

export default function CampaignAdSetsTable({
  rows, setSelectedIds, selectedIds,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignAdSetsTable" })

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "ad-set",
      headerName: translate("Ad Set"),
      sortable: true,
      renderCell: (params) => (
        <p className="ad-set-name">{ params.row.name }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      minWidth: 350,
    },
    {
      field: "audience-targeting",
      headerName: translate("Audience Targeting"),
      sortable: false,
      renderCell: (params) => (
        <p>{ params.row.audienceTarget }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 5,
    },
  ], [ translate ])

  const filteredRows = rows.map((row) => ({
    id: row.id,
    name: row.name,
    audienceTarget: row.audienceTargeting,
  }))

  return (
    <DataGrid
      className="cp_component-campaign-ads-table"
      getRowId={ (row) => row.id }
      checkboxSelection={ true }
      onRowSelectionModelChange={ (ids) => setSelectedIds(ids.map((id) => id.toString())) }
      rowSelectionModel={ selectedIds }
      disableRowSelectionOnClick={ true }
      rowHeight={ 90 }
      columnHeaderHeight={ 40 }
      columns={ COLUMNS }
      disableColumnReorder={ true }
      hideFooter={ true }
      pinnedColumns={
            {
              left: [ "__check__", "ad-set" ],
            }
        }
      rows={ filteredRows }
      slots={ {
        noRowsOverlay: EmptyElement,
        noResultsOverlay: EmptyElement,
      } }
    />
  )
}

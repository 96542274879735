import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import { ErrorStatusCode } from "../../util/types"

import "./style.sass"

type Props = {
  errorMessage: string,
  statusCode: ErrorStatusCode,
}

export default function ErrorPublic(props: Props): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.ErrorPublic" })

  return (
    <div className="cp_page_error-public">
      <div>
        <section className="card">
          <div className="error-content">
            <article>
              <h1 className="headline_splash">{ props.statusCode }</h1>
            </article>
            <aside>
              <h4>{ translate("Whoops! Something went wrong!") }</h4>
              <p className="body_large">{ props.errorMessage }</p>
            </aside>
          </div>
        </section>
      </div>
    </div>
  )
}

/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams, useSearchParams } from "react-router-dom"
import ClearIcon from "@mui/icons-material/Clear"
import { setSearchInput, fetchSearchResults } from "../../../state/searchSlice"
import { useSelector, useDispatch } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import * as SearchHelper from "../../../util/searchHelper"
import RelevantPostsTopControls from "./RelevantPostsTopControls"
import LoadingIndicator from "../../LoadingIndicator"
import { KEYWORDS_POSTS_QTY } from "../../../util/constant"
import ModalPostDetails from "../../ModalPostDetails"
import { fetchSocialProfile } from "../../../state/socialProfileSlice"

import "./relevant-posts-keywords.sass"
import RelevantPostsPreview from "./RelevantPostsPreview"
import { getSocialAvatarProps } from "../../../util/componentHelper"
import {
  getSuggestionListPostsByKeyword,
  setActiveTagId,
  setToggleFetchingKeywords,
} from "../../../state/listSocialProfileSlice"

interface Props {
  topPostsOverviewOn: boolean
}

type PostOrEmptyMessage = GraphQL.PostFragment | { emptyMssg: string, indexId: number }
type RemovePostArgs = {postId: string, keywordId: string} | null

function RelevantPostsByKeywords({ topPostsOverviewOn }:Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSocialSubTabs" })
  const { listID, socialProfileID } = useParams<{ listID: string, socialProfileID: string }>()
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.socialProfile.profile)
  const {
    keywordsWithPosts, keywordPosts, toggleFetchingKeywords,
  } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const { searchInput } = useSelector(({ search }) => search)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ relevantKeywords, setRelevantKeywords ] = useState<GraphQL.SuggestionListKeyword[] | null>(null)
  const [ selectedPost, selectPost ] = useState<GraphQL.PostFragment | undefined>()
  const [ postInRemovalProcess, setPostInRemovalProcess ] = useState<RemovePostArgs>(null)
  const activeKeywordId = useRef("")

  if (!listID || !socialProfileID) return null

  const getRelatedPostsByKeword = async (keywordId: string) => {
    await dispatch(getSuggestionListPostsByKeyword({
      listId: listID,
      socialAccountId: socialProfileID,
      keywordId,
    }))
  }

  const handleDeleteAllPosts = async (suggestionListKeywordId: string) => {
    dispatch(setToggleFetchingKeywords(true))
    const response = await API.removeListContentKeywordPosts({
      suggestionListId: listID,
      socialAccountId: socialProfileID,
      suggestionListKeywordId,
    })
    if (API.isSuccess(response)) {
      getRelatedPostsByKeword(suggestionListKeywordId)
    }
  }

  const handleChangeOfKeyword = (keywordId: string) => {
    activeKeywordId.current = keywordId
    dispatch(setActiveTagId(keywordId))
    getRelatedPostsByKeword(keywordId)
  }

  const handleClickRemovePost = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    postId: string,
    keywordId: string,
  ) => {
    e.stopPropagation()
    setPostInRemovalProcess({ postId, keywordId })
  }

  const removeTopPost = async (
    postId: string,
    keywordId: string,
  ) => {
    if (socialProfileID && listID && keywordId) {
      const input: GraphQL.SuggestionListSocialAccountCippusInput = {
        kind: GraphQL.SuggestionListSocialAccountCippusType.Relevant,
        postId,
        keywordId,
        socialAccountId: socialProfileID,
        suggestionListId: listID,
      }

      const removedPostId = await API.removeListTopPost({ input })
      if (API.isSuccess(removedPostId)) {
        getRelatedPostsByKeword(keywordId) // Added to refresh posts after deletion
      }
    }
  }

  const activeTagName = useMemo(() => {
    if (relevantKeywords && relevantKeywords.length) {
      const active = relevantKeywords.find((key) => key.id === activeKeywordId.current)
      if (active) dispatch(setActiveTagId(active.id))
      return active ? active.name : null
    }
    return null
  }, [ relevantKeywords, activeKeywordId.current ])

  function addKeywordsToSearch(): void {
    if (activeTagName == null || activeTagName === "") return
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    newInput.contentParams.keywords = [ { tags: [ activeTagName ], inclusive: false } ]
    newInput.contentParams.optionalImageTags = []
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    dispatch(fetchSearchResults(true))
  }

  useEffect(() => {
    if (topPostsOverviewOn && keywordsWithPosts.length) {
      const keywordId = keywordsWithPosts[0].id
      handleChangeOfKeyword(keywordId)
    }
  }, [ topPostsOverviewOn ])

  useEffect(() => {
    async function fetchKeywords() {
      if (listID && socialProfileID) {
        dispatch(setToggleFetchingKeywords(true))
        const fetchedKeywords = await API.getSuggestionListKeywords({ listId: listID, socialAccountId: socialProfileID })
        if (API.isSuccess(fetchedKeywords)) {
          setRelevantKeywords(fetchedKeywords.payload.suggestionListSocialAccountByListIdSocialAccountId.suggestionList.keywords)
          dispatch(setToggleFetchingKeywords(false))
        }
        if (API.isError(fetchedKeywords)) {
          // TODO: handle error cases
          // eslint-disable-next-line no-console
          console.warn("Error fetching keywords: ", fetchedKeywords)
          dispatch(setToggleFetchingKeywords(false))
        }
      }
    }
    fetchKeywords()
  }, [ listID, socialProfileID ])

  useEffect(() => {
    if (Array.isArray(relevantKeywords) && relevantKeywords.length) {
      const keyword = relevantKeywords[0]
      const keywordId = keyword.id
      activeKeywordId.current = keywordId
      getRelatedPostsByKeword(keywordId)
    }
  }, [ relevantKeywords ])

  useEffect(() => {
    // after the post has been removed and the
    // updated posts data state is set remove loading component
    if (postInRemovalProcess?.keywordId.length) {
      setPostInRemovalProcess(null)
    }
  }, [ keywordPosts ])

  useEffect(() => {
    if (postInRemovalProcess?.keywordId.length) {
      removeTopPost(
        postInRemovalProcess.postId,
        postInRemovalProcess.keywordId,
      )
    }
  }, [ postInRemovalProcess ])

  useEffect(() => {
    if (socialProfileID) {
      dispatch(fetchSocialProfile(socialProfileID))
    }
    return () => {
      // revert content params back to default
      (async () => {
        const newInput = SearchHelper.cloneSearchInput(searchInput)
        newInput.contentParams = SearchHelper.initialContentInput()
        await dispatch(setSearchInput(newInput))
      })()
    }
  }, [])

  useEffect(() => {
    addKeywordsToSearch()
  }, [ activeTagName ])

  const allPosts = useMemo(() => Array.from({ length: KEYWORDS_POSTS_QTY }, (_, index): PostOrEmptyMessage => {
    const postInfo = keywordPosts[index] ? keywordPosts[index] : null
    if (postInfo && postInfo.postMedia.length) {
      return postInfo
    }
    return { emptyMssg: "No Post", indexId: index }
  }), [ keywordPosts ])

  if (relevantKeywords === null || !API.isSuccess(profile) || toggleFetchingKeywords) {
    return (
      <div className="cp_relevant_post-keywords-fetching">
        <LoadingIndicator size={ 40 } />
      </div>
    )
  }

  if (Array.isArray(relevantKeywords) && !relevantKeywords.length) {
    // TODO: handle no keywords empty state
    return (
      <div className="cp_component_empty-state">
        <article>
          <div className="cp_component_empty-state-mag" />
        </article>
        <aside>
          <h2>{ translate("No Posts Available!") }</h2>
          <p>{ translate("Relevant posts require keywords and it appears that this list does not have any keywords.") }</p>
        </aside>
      </div>
    )
  }

  if (topPostsOverviewOn) {
    return (
      <RelevantPostsPreview
        classes={ [ "keyword-preview" ] }
        tags={ keywordsWithPosts }
        activeTag={ activeKeywordId.current }
        posts={ keywordPosts }
        dropdownSelectionAction={ handleChangeOfKeyword }
      />
    )
  }

  return (
    <div className="cp_relevant_posts-keywords">
      <RelevantPostsTopControls
        dropdownSelections={ relevantKeywords }
        deleteAllAction={ handleDeleteAllPosts }
        dropdownSelectionAction={ handleChangeOfKeyword }
        activeKeyword={ activeKeywordId.current || "" }
      />
      <div className="cp_relevant_posts-keywords-wrapper">
        <ul className="cp_relevant_posts-keywords-list">
          { allPosts.map((post) => ("id" in post ? (
            <li
              key={ post.id }
              className={ `cp_relevant_posts-keywords-item ${ postInRemovalProcess?.postId ? "removal-in-process" : "" }` }
              onClick={ () => selectPost(post) }
              tabIndex={ 0 }
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              onKeyDown={ (e) => (e.key === "Enter" || e.key === "Space") && selectPost(post) }
              aria-label="Select post"
            >
              {
            (post.id !== postInRemovalProcess?.postId) ? (
              <>
                <button
                  type="button"
                  aria-label="remove"
                  className="cp_relevant_posts-keywords-item-remove"
                  onClick={ (e) => handleClickRemovePost(e, post.id, activeKeywordId.current) }
                >
                  <ClearIcon fontSize="small" className="cp_relevant_posts-keywords-item-remove-icon" />
                </button>
                <img src={ `${ post.postMedia[0].thumbnailUrl }?auto=format` } alt="Post thumbnail" loading="lazy" />
              </>
            ) : (
              <LoadingIndicator size={ 30 } />
            )
          }
            </li>
          ) : (
            <li key={ post.indexId } className="cp_relevant_posts-keywords-empty">
              <span>{ post.emptyMssg }</span>
            </li>
          ))) }
        </ul>
        { API.isSuccess(profile) && (
          <ModalPostDetails
            closeAction={ () => selectPost(undefined) }
            engagementRate={ `${ selectedPost?.engagementRate }` || "" }
            isReel={ Boolean(selectedPost?.isReel) }
            open={ Boolean(selectedPost) }
            isStory={ Boolean(selectedPost?.isStory) }
            media={ selectedPost?.postMedia || [] }
            permalink={ selectedPost?.permalink || "" }
            postComments={ selectedPost?.postComments || 0 }
            postContent={ selectedPost?.postContent || "" }
            postDate={ selectedPost?.created || 0 }
            postExits={ selectedPost?.postExits || 0 }
            postImpressions={ selectedPost?.postImpressions || 0 }
            postLikes={ selectedPost?.postLikes || 0 }
            postPlays={ selectedPost?.postPlays || 0 }
            postReach={ selectedPost?.postReach || 0 }
            postReachEstimated={ selectedPost?.estimatedPostReach?.value || 0 }
            postSaves={ selectedPost?.postSaves || 0 }
            postShares={ selectedPost?.postShares || 0 }
            postScreenshots={ selectedPost?.screenshots || 0 }
            postSwipeUps={ selectedPost?.swipeUps || 0 }
            postHighlighted={ Boolean(selectedPost?.highlighted) }
            postSaved={ Boolean(selectedPost?.saved) }
            postTapsBackward={ selectedPost?.postTapsBackward || 0 }
            postTapsForward={ selectedPost?.postTapsForward || 0 }
            postViews={ selectedPost?.postViews || 0 }
            socialAvatarProps={ getSocialAvatarProps(profile.payload.socialAccount as GraphQL.SocialAccount) }
          />
        ) }
      </div>
    </div>
  )
}

export default RelevantPostsByKeywords
